import React from 'react'
import { PLACEHOLDER, STRING } from '../../../../utils/Constant'
import DatePicker from 'react-datepicker';
import moment from 'moment'
import Intallment from '../../../../images/rewampimages/transaction-history.png'
import IntallmentPopup from '../../../presentationals/Popup/installmentPopup'
import NoData from '../../../../images/nodata.png'
const InstallmentDetails = ({ addPopup, editInstallHandler, saveInstallHandler, installDateState,
    installmentDetail, installDetails, editIntallmentDetail, deleteIntallmentDetail, close, addPopupOpen }) => (
    <div className=" details-new" style={{ border: '1.5px solid #DBE9FF', borderRadius: '5px', padding: '10px', marginTop: '20px', minHeight: '400px' }}>
        {/* <div className="row">
                <h3 className="text-center blue-text">Installment Details</h3>
            </div> */}
        {/* <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">INSTALLMENT AMOUNT</label>
                    <input
                        type="number"
                        value={installmentDetail.installAmountState}
                        className="form-control"
                        placeholder="ENTER AMOUNT"
                        onChange={(e) => editInstallHandler(e, 'installAmount')}
                    />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">INSTALLMENT DATE</label>
                    <DatePicker
                        value={installmentDetail.installDateState}
                        selected={installDateState}
                        onChange={(e) => editInstallHandler(e, 'installDate')}
                        className="date-picker form-control"
                        dateFormat="DD-MM-YYYY"
                        placeholderText="ENTER INSTALMENT DATE"
                    />
                </div>
            </div>

            <div className="row text-center form-group">
                <button className="profile-save-btn" id="transSubmitBtn" onClick={e => saveInstallHandler()}>
                    <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
            </button>
            </div> */}
        {addPopup ?
            <IntallmentPopup close={close} installDateState={installDateState} saveInstallHandler={saveInstallHandler} installmentDetail={installmentDetail} editInstallHandler={editInstallHandler} />
            : ""}
        <div className='row' style={{ marginTop: '20px' }}>
            <div className='col-sm-6 col-xs-6'>
                <h3 className=""><img src={Intallment} width={'27px'} />&nbsp;Installments</h3>
            </div>
            <div className='col-sm-6 col-xs-6 text-right'>
                <button className='btn btn-primary' onClick={() => addPopupOpen()}><i className='fa fa-plus-circle'></i>&nbsp;&nbsp;Add Installment</button>
            </div>
        </div>
        {installDetails != null && installDetails != '' && installDetails.length > 0 ?
            <div className="overflow-x-auto phocket-table-new text-center" style={{ marginBottom: '20px' }}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>ID</th>
                            <th style={{ textAlign: 'center' }}>DATE</th>
                            <th style={{ textAlign: 'center' }}>Amount</th>
                            <th style={{ textAlign: 'center' }}>Edit</th>
                            <th style={{ textAlign: 'center' }}>Delete</th>
                        </tr>
                    </thead>

                    <tbody>
                        {installDetails != null && installDetails != '' && installDetails.length > 0 ? installDetails.map((detail, i) => {
                            return (
                                <tr key={i}>
                                    <td>{detail.applicationId}</td>
                                    <td>{moment(new Date(detail.date)).format('DD-MM-YYYY')}</td>
                                    <td>{detail.emiAmount}</td>
                                    <td><span className=" fa fa-edit" style={{ cursor: 'pointer' }} onClick={e => editIntallmentDetail(detail)} /></td>
                                    <td><span className=" fa fa-trash" style={{ cursor: 'pointer' }} onClick={e => deleteIntallmentDetail(detail)} /></td>
                                </tr>
                            )
                        })
                            : null}
                    </tbody>
                </table>
            </div> : <div className='text-center'><img style={{ width: '30%' }} src={NoData} /></div>}
    </div>
)

export default InstallmentDetails;