import React from 'react';
import { STRING, ROLE } from '../../../../utils/Constant'
import DotLoader from '../../Loader/layerLoader.component'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import Workbook from 'react-excel-workbook'


const PhocketCustomerDetails = ({countType, dataFilter, recoveryComunicateHandler, ignoreHandler,
    forwordpage, backpage, allUserDetail, loadarState, refreshpage, chequeNoticeShowHandler,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow, nashHandler, nachCheckbox,
    searchByAppNo, searchByemail, searchBymobile, disburseLoanId, saveDisburseLoanId,
    addLoanId, admin, phocketTablePagination, tableMinPagination, tableMaxPagination,
    userCommentChange, userCommentHandler, paymentDeductHandler, commentState, userCommentSelect }) => (
    <div className="container-fluid details-new">
        <div className="row">
            <div className="col-sm-12 col-xs-12">
                <h3 className="text-center blue-text">Collection Leads</h3>
            </div>
        </div>
        <div className="row form-group">
            <ul className="loan-lead-search-new">
                <li>
                    <label className="font-12px"><span className="fa fa-search" /> Application No.</label>
                    <input type="number" className="form-control" value={searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
                </li>
                <li>
                    <label className="font-12px"><span className="fa fa-search" /> Email ID</label>
                    <input type="email" className="form-control" value={searchByemail} onChange={e => dataFilter(e, "email")} />
                </li>
                <li>
                    <label className="font-12px" ><span className="fa fa-search" /> Mobile No.</label>
                    <input type="number" value={searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
                </li>
                <li>
                    <label className="font-12px" ><span className="fa fa-search" /> Nach</label>
                    <select className="form-control" onChange={e => dataFilter(e, "nach")}>
                        <option value="">Select Nach</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                    </select>
                </li>
                {/* <li>
                    <label className="font-12px" ><span className="fa fa-search" /> Assignment Category</label>
                    <select className="form-control" onChange={e => dataFilter(e, "category")}>
                        <option value="">Select Category</option>
                        <option value='Complete'>Complete</option>
                        <option value='Incomplete/ Partial Complete'>Incomplete/ Partial Complete</option>
                        <option value='Repeat'>Repeat</option>
                        <option value='Legal'>Legal</option>

                    </select>
                </li> */}
                <li>
                    {/* <Workbook filename="ApplicationStatus.xlsx" element={<button className="margin-top-20px profile-save-btn margin-0"><span className="fa fa-download"></span>&nbsp;&nbsp;Excel</button>}>
                        <Workbook.Sheet data={allUserDetail} name="Sheet A">
                            <Workbook.Column label="Loan Id" value="applicationLoanId" />
                            <Workbook.Column label="Application Id" value="applicationId" />
                            <Workbook.Column label="UserName" value="userName" />
                            <Workbook.Column label="Email" value="emailId" />
                            <Workbook.Column label="Installment" value="loanEmi" />
                            <Workbook.Column label="Payback Date" value="loanPaybackDate" />
                            <Workbook.Column label="Payback Amount" value="loanPaybackAmount" />
                            <Workbook.Column label="Pay ON Today" value="payOnToday" />
                            <Workbook.Column label="Overdue Days" value="count" />
                        </Workbook.Sheet>
                    </Workbook> */}
                    &nbsp;
                    {/* <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh fa-spin" /></button> */}
                </li>
            </ul>
        </div>
        <div className=" text-center">
            {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                <div>
                    <div className="overflow-x-auto phocket-table-new">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Assigned</th>
                                    <th>Send Mail</th>
                                    <th>Nach</th>
                                    <th>Comment</th>
                                    <th>Loan Id</th>
                                    <th>Application Id</th>
                                    <th>Name</th>
                                    <th>Mobile No.</th>
                                    <th>Email ID</th>
                                    <th>Installment</th>
                                    <th>PayBack Date</th>
                                    <th>PayBack Amount</th>
                                    <th>Pay on Today</th>
                                    <th>Overdue Days</th>
                                    <th>Communicate</th>
                                    <th>Cheque &amp; Notice</th>
                                    {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                            <th>Deduct Payment</th>
                                            : "" : ""}
                                </tr>

                            </thead>
                            <tbody>
                                {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    return (
                                        
                                        <tr key={i} style={{ backgroundColor: data.completeMarked ? '#c3fcca' : data.incompleteMarked ? '#e4b9c0' : "" ,visibility: data.followUpStatusCode == STRING.PAYMENT_OVERDUE || data.followUpStatusCode == STRING.PAYMENT_ONGOING ? 'visible' : 'hidden' }}>
                                            <td>{data.assignUser}</td>
                                            <td>
                                                <input className="phocket-checkbox" type="checkbox" id={"ignore_" + data.loanId} onChange={(e) => ignoreHandler(e, data.loanId)} checked={data.extensionTime} />
                                                <label htmlFor={"ignore_" + data.loanId}><span></span></label>
                                            </td>
                                            <td>
                                                {admin != null ? admin.rolelist.indexOf(ROLE.NACH_CHANGE) >= 0 ?
                                                    <div>
                                                        <input className="phocket-checkbox" type="checkbox" id={"nash_" + data.loanId} onChange={(e) => nashHandler(e, data)} checked={data.nachStatus} />
                                                        <label htmlFor={"nash_" + data.loanId}><span></span></label>
                                                    </div>
                                                    : data.nachStatus : null}
                                            </td>

                                            <td>
                                                <select onChange={e => userCommentSelect(e)} className="form-control" placeholder='' >
                                                    <option value=''>Select categorization</option>
                                                    <option value='Response'>Response</option>
                                                    <option value='No Response'>No Response</option>
                                                    <option value='Non Contactable'>Non Contactable</option>
                                                </select>
                                                <div className="display-flex">
                                                    <input type="text" className="app-comment-box" onChange={e => userCommentChange(e)} />
                                                    <span className="fa fa-save loan-id-save-btn" onClick={e => userCommentHandler(data)} />
                                                </div>
                                            </td>
                                            <td>{data.applicationLoanId}</td>
                                            <td>{data.applicationId}</td>
                                            <td style={{whiteSpace:'break-spaces'}}>{data.userName}</td>
                                            <td>{data.mobileNumber}</td>
                                            <td>{data.emailId}</td>
                                            <td>{data.loanEmi}</td>
                                            <td>{data.loanPaybackDate}</td>
                                            <td>{data.loanPaybackAmount}</td>
                                            <td>{data.payOnToday}</td>
                                            <td>{data.count}</td>
                                            <td>
                                                <button className="background-none-btn" onClick={e => recoveryComunicateHandler(data)}><span className="fa fa-envelope-o" /></button>
                                            </td>
                                            <td>
                                                <button className="background-none-btn" onClick={e => chequeNoticeShowHandler(data)}><span className="fa fa-gg" /></button>
                                            </td>

                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                                    <td>
                                                        <button className="background-none-btn" onClick={e => paymentDeductHandler(data)}><span className="fa fa-credit-card" /></button>
                                                    </td>
                                                    : "" : ""}

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="phocket-pagination">
                                <button className="fa fa-chevron-left background-none-btn display-inline" onClick={e => phocketTablePagination(allUserDetail, 'back')}></button>
                                <div className="display-inline">{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                                <button className="fa fa-chevron-right background-none-btn display-inline" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button>
                            </div> */}
                    {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block grey  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                                    <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                                    <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block grey" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                                </div>
                            </div> */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button></div>
                            {/* <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                                    <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                                    <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div> */}
                            <div className="col-sm-4 col-xs-12  phoc" >{allUserDetail.length} of  {(tableMinPagination + 1) + ' - ' + (tableMaxPagination)}</div>
                            <div className="col-sm-4 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button></div> </div>
                    </div>
                </div>
                : ""}
            {/* {loadarState == true ?
                <DotLoader />
                : ""} */}
        </div>
    </div>
)

export default PhocketCustomerDetails;
