import React, { useState } from "react";
import MainExpenses from "./main";
import MainCategory from "./category/main";
import MainSubCategory from "./subcategory/main";
import MainDescription from "./description/main";
import MainTarget from "./Targets/main";

const TabsExpense = () => {
    const [viewState, setViewState] = useState('businessPlan')
    return (
        <>
            <div className="row fixed-top" style={{ marginTop: '0px', display: 'flex', width: '101%' }}>
                <div className="" onClick={() => setViewState('businessPlan')} style={{ borderBottom: viewState === 'businessPlan' ? '2px solid #267DFF' : '', background: viewState === 'businessPlan' ? '#267DFF' : '#DBE9FF', padding: viewState === 'businessPlan' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businessPlan' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Business Plan

                </div>
                <div className="" onClick={() => setViewState('businessReturn')} style={{ borderBottom: viewState === 'businessReturn' ? '2px solid #267DFF' : '', background: viewState === 'businessReturn' ? '#267DFF' : '#DBE9FF', padding: viewState === 'businessReturn' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businessReturn' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Business Return

                </div>
                <div className="" onClick={() => setViewState('businessTargetcategory')} style={{ borderBottom: viewState === 'businessTargetcategory' ? '2px solid #267DFF' : '', background: viewState === 'businessTargetcategory' ? '#267DFF' : '#DBE9FF', padding: viewState === 'businessTargetcategory' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businessTargetcategory' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Business Target Category

                </div>
                <div className="" onClick={() => setViewState('businessTargetSubcategory')} style={{ borderBottom: viewState === 'businessTargetSubcategory' ? '2px solid #267DFF' : '', background: viewState === 'businessTargetSubcategory' ? '#267DFF' : '#DBE9FF', padding: viewState === 'businessTargetSubcategory' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businessTargetSubcategory' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Business Target Subcategory

                </div>
                <div className="" onClick={() => setViewState('businesstarget')} style={{ borderBottom: viewState === 'businesstarget' ? '2px solid #267DFF' : '', background: viewState === 'businesstarget' ? '#267DFF' : '#DBE9FF', padding: viewState === 'businesstarget' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businesstarget' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Business Target

                </div>

            </div>
            {viewState === 'businessPlan' ?
                <MainExpenses />
                : ""}
            {viewState === 'businessReturn' ?
                <MainCategory />
                : ""}
            {viewState === 'businessTargetcategory' ?
                <MainSubCategory />
                : ""}
            {viewState === 'businessTargetSubcategory' ?
                <MainDescription />
                : ""}
            {viewState === 'businesstarget' ?
                <MainTarget />
                : ""}
        </>
    )
}
export default TabsExpense