import moment from 'moment';
import React from 'react'
import Ui from '../../../presentationals/CardsUtility/editProfile.component'
import { _formatDate, _formatDateInDash } from '../../../../utils/validation';
import { getBankDetailsByUserIdApi, getAddressDetailsByUserIdApi, ediBankApi, editAddressApi, editBasicApi } from './actionCreator'
import Loader from '../../../presentationals/Loader/Loader.component'
import SidePopup from '../../../presentationals/Popup/Popup.component'
import { basicFormValidationCards, addressFormValidationCards, bankFormValidationCards } from '../../../../utils/validation'
import {zipAndBranchDetail} from '../../Admin/AdminActionCreator.component'
class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bankData: '',
            addressData: '',
            basicData: '',
            dobState: '',
            loaderState: false,
            popupState: false,
            popupStatus: '',
            basicValidationMsg: "",
            addressValidationMsg: "",
            bankValidationMsg: ""

        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    saveHandler(data, type) {
        if (type === 'bank') {
            let bankValidation = bankFormValidationCards(
                this.state.bankData
            )
            if (bankValidation.status) {
                this.setState({ bankValidationMsg: '' })
                ediBankApi(this.props.userDetailsByUserId, this.state.bankData, callback => {
                    
                    if (callback === 'success') {
                        this.setState({ popupState: true, popupStatus: 'Updated Successfully !', loaderState: false }, () => this.removePopup())

                    } else {
                        this.setState({ popupState: true, popupStatus: 'Please Try Again Later !', loaderState: false }, () => this.removePopup())

                    }

                })
            } else {
                this.setState({
                    bankValidationMsg: bankValidation
                })
            }
        }
        if (type === 'address') {
            let addressValidation = addressFormValidationCards(
                this.state.addressData
            )
            if (addressValidation.status) {
                this.setState({ addressValidationMsg: '' })
                this.setState({ loaderState: true })
                editAddressApi(this.props.userDetailsByUserId, this.state.addressData, callback => {
                    
                    if (callback === 'success') {
                        this.setState({ popupState: true, popupStatus: 'Updated Successfully !', loaderState: false }, () => this.removePopup())

                    } else {
                        this.setState({ popupState: true, popupStatus: 'Please Try Again Later !', loaderState: false }, () => this.removePopup())

                    }

                })
            } else {
                this.setState({
                    addressValidationMsg: addressValidation
                })
            }
        }
        if (type === 'basic') {
            let basicValidation = basicFormValidationCards(
                this.state.basicData
            )
            if (basicValidation.status) {
                this.setState({ loaderState: true })
                this.setState({ basicValidationMsg: '' })

                editBasicApi(this.props.userDetailsByUserId, this.state.basicData, callback => {
                    
                    if (callback.userId !== null && callback.userId !== '') {
                        this.setState({ popupState: true, popupStatus: 'Updated Successfully !', loaderState: false }, () => this.removePopup())

                    } else {
                        this.setState({ popupState: true, popupStatus: 'Please Try Again Later !', loaderState: false }, () => this.removePopup())

                    }

                })
            } else {
                this.setState({
                    basicValidationMsg: basicValidation
                })
            }
        }
    }
    onChangeHandlerBank(e, type) {
        let bankData = Object.assign({}, this.state.bankData)
        if (type === 'accountNo') {
            bankData['accountNo'] = e.target.value
        }
        if (type === 'nameOnBank') {
            bankData['nameOnBank'] = e.target.value
        }
        if (type === 'bankName') {
            bankData['bankName'] = e.target.value
        }
        if (type === 'branch') {
            bankData['branch'] = e.target.value
        }
        if (type === 'ifscCode') {
            bankData['ifscCode'] = e.target.value
            if((e.target.value).length===11){
            zipAndBranchDetail(e.target.value, 'branch', res => {
                this.setState({loaderState:true})
                if (res.status === 'success') 
                {
                    bankData['bankName']=res.branchName
                    bankData['branch']=res.branchCode
                    document.getElementById('bankName').value = res.branchName
                    document.getElementById('branch').value = res.branchCode
              this.setState({loaderState:false})
                //   userDetail.PermanentCityState = res.destrict
                //   userDetail.PermanentStateState = res.state
                //   this.setState({ userDetail })
                }
              })
            }
        }
        this.setState({ bankData })
    }
    onChangeHandlerAddress(e, type) {
        let addressData = Object.assign({}, this.state.addressData)

        if (type === 'address1') {
            addressData['address1'] = e.target.value
        }
        if (type === 'address2') {

            addressData['address2'] = e.target.value
        }
        if (type === 'address3') {

            addressData['address3'] = e.target.value
        }
        if (type === 'city') {

            addressData['city'] = e.target.value
        }
        if (type === 'state') {

            addressData['state'] = e.target.value
        }
        if (type === 'pinCode') {
            addressData['pinCode'] = e.target.value
        }
        if (type === 'deliveryAddress1') {

            addressData['deliveryAddress1'] = e.target.value
        }
        if (type === 'deliveryAddress2') {

            addressData['deliveryAddress2'] = e.target.value
        }
        if (type === 'deliveryAddress3') {
            addressData['deliveryAddress3'] = e.target.value
        }
        if (type === 'deliveryCity') {
            addressData['deliveryCity'] = e.target.value
        }
        if (type === 'deliveryState') {
            addressData['deliveryState'] = e.target.value
        }
        if (type === 'deliveryPinCode') {
            addressData['deliveryPinCode'] = e.target.value
        }

        this.setState({ addressData })

    }
    onChangeHandlerBasic(e, type) {
        let basicData = Object.assign({}, this.state.basicData)
        if (type === 'firstName') {
            basicData['firstName'] = e.target.value
        }
        if (type === 'lastName') {
            basicData['lastName'] = e.target.value

        } if (type === 'dateOfBirth') {
            basicData['dateOfBirth'] = _formatDateInDash(e)
            this.setState({ dobState: e })


        } if (type === 'gender') {
            basicData['gender'] = e.target.value

        } if (type === 'mobileNumber') {
            basicData['mobileNumber'] = e.target.value

        } if (type === 'panNo') {
            basicData['panNo'] = e.target.value

        } if (type === 'aadhaarNo') {
            basicData['aadhaarNo'] = e.target.value

        } if (type === 'email') {
            basicData['email'] = e.target.value

        }
        this.setState({ basicData })
    }
    componentDidMount() {
        this.setState({ basicData: this.props.userDetailsByUserId })
        this.bankDetails()
        this.addressDetails()
    }
    bankDetails() {
        getBankDetailsByUserIdApi(this.props.userDetailsByUserId.userId, callback => {
            this.setState({ bankData: callback })
        })
    }
    addressDetails() {
        getAddressDetailsByUserIdApi(this.props.userDetailsByUserId.userId, callback => {
            
            this.setState({ addressData: callback })

        })
    }
    render() {
        return (
            <div>
                <Ui
                    dobState={this.state.dobState}
                    onChangeHandlerBasic={this.onChangeHandlerBasic.bind(this)}
                    onChangeHandlerAddress={this.onChangeHandlerAddress.bind(this)}
                    onChangeHandlerBank={this.onChangeHandlerBank.bind(this)}
                    state={this.state}
                    saveHandler={this.saveHandler.bind(this)} />
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupState ?
                    <SidePopup popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)} />
                    : ""}
            </div>
        )
    }
}
export default Edit