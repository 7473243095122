import React from 'react'
import Ui from '../../../presentationals/Admin/Recovery/IncommingPaymentReport.component'
import { _preFormatDate } from '../../../../utils/validation'
import { APIS } from '../../../../utils/api-factory'
import {
  getistByNumber, getlistByEmail, getlistByApplication, getIncomingPaymentByWeek,
  getIncomingPaymentByDate, getUserDetailByLoanId, saveCHequeNoticeApi,
  deductUserPaymentApi, getSourceOfEnach, deductUserPaymentByTechProcess
} from '../AdminActionCreator.component';
class Incoming extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: _preFormatDate(new Date()),
            startDateToShow: '',
            endDate: _preFormatDate(new Date()),
            endDateToShow: ''
        }
    }
    downloadFile() {
      let url= APIS.DOWNLOAD_INCOMING_PAYMENT_REPORT + this.state.startDate + '&dateTo=' + this.state.endDate
        window.location.href = url
    }
    dateFilter(e, type) {
        if (type == 'start') {
            this.setState({
                startDate: _preFormatDate(e),

            })
        }
        if (type == 'end') {
            this.setState({
                endDate: _preFormatDate(e),

            })
        }
    }

    deductionAmountHandler(type){
        
        if (type == 'yes') {
          this.setState({
            disabled: true
          })
          if (this.state.source != 'techProcess') {
            deductUserPaymentApi(
              this.state.loanIdForDeduction,
              this.state.amountForDeduction,
              '',
              callBack => {
                if (callBack == 'success') {
                  this.setState({
                    popupState: true,
                    popupStatus: 'success',
                    amountDeductionPopupState: false,
                    disabled: false
                  })
                  this.removePopup()
                } else {
                  this.setState({
                    popupState: true,
                    popupStatus: 'Please try again',
                    amountDeductionPopupState: false,
                    disabled: false
                  })
                  this.removePopup()
                }
              }
            )
          } else {
            deductUserPaymentByTechProcess(
              this.state.loanIdForDeduction,
              this.state.amountForDeduction,
              this.state.dateTechProcess,
              callBack => {
                if (callBack == 'success') {
                  this.setState({
                    popupState: true,
                    popupStatus: 'success',
                    amountDeductionPopupState: false,
                    disabled: false
                  })
                  this.removePopup()
                } else {
                  this.setState({
                    popupState: true,
                    popupStatus: 'Please try again',
                    amountDeductionPopupState: false,
                    disabled: false
                  })
                  this.removePopup()
                }
              }
            )
          }
        } else if (type == 'no') {
          this.setState({ amountDeductionPopupState: false })
        }
        // if (type == 'yes') {
        //     this.setState({
        //         disabled: true
        //     })
        //     deductUserPaymentApi(this.state.loanIdForDeduction, this.state.amountForDeduction,
        //         (callBack) => {
        //             if (callBack == 'success') {
        //                 this.setState({
        //                     popupState: true,
        //                     popupStatus: 'success',
        //                     amountDeductionPopupState: false,
        //                     disabled: false
        //                 })
        //                 this.removePopup();
        //             } else {
        //                 this.setState({
        //                     popupState: true,
        //                     popupStatus: 'Please try again',
        //                     amountDeductionPopupState: false,
        //                     disabled: false
        //                 })
        //                 this.removePopup();
        //             }
        //         })
        // } else if (type == 'no') {
        //     this.setState({ amountDeductionPopupState: false })
        // }
    }

    render() {
        return (
            <div>
                <Ui
                    startDate={this.state.startDate}
                    startDateToShow={this.state.startDateToShow}
                    endDate={this.state.endDate}
                    endDateToShow={this.state.endDateToShow}
                    downloadFile={this.downloadFile.bind(this)}
                    dateFilter={this.dateFilter.bind(this)}
                />
            </div>
        )
    }
}
export default Incoming