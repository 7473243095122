import React, { useState, useEffect } from 'react'
import PopUp from "../../../../presentationals/Popup/Popup.component";
import Loader from '../../../../presentationals/Loader/Loader.component';
import { SaveUpdateAutomatedProcess } from '../../AdminActionCreator.component'
import { ArrowBack } from '@mui/icons-material';
export default function AddEditExecutor({ logsData, processName, handleSavebtn }) {




    function millisecondsToMinutes(seconds) {
        const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const hoursFormatted = String(hours).padStart(2, '0');
    const minutesFormatted = String(minutes).padStart(2, '0');
    const secondsFormatted = String(remainingSeconds).padStart(2, '0');

    return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
    
    }
    

    function camelToSentence(camelStr) {
        // Insert space before each uppercase letter, except at the start
        const sentence = camelStr.replace(/([a-z])([A-Z])/g, '$1 $2');
        // Capitalize the first letter of the sentence
        return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
      }
    return (
        <>
            <div className="AddEditExecutor">
                {console.log(logsData)}
                <div className='savebtn'>
                    {/* <ArrowBack onClick={() => handleSavebtn('back')} /> */}
                </div>


                {
                    logsData?.length > 0 ?
                        <div className='' style={{ background: '#f2f2f2', padding: '5px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px', overflow: 'auto' }}>
                                       <ArrowBack  style={{cursor:'pointer'}} onClick={() => handleSavebtn('back')} />      <h3 className='heading' style={{textTransform:'full-size-kana'}}>{`Logs Details of ${camelToSentence(processName)}`}</h3>

                            <div className="phocket-table-new">
                                <table className='' style={{ marginBottom: '10px' }}>
                                    <thead>
                                        <tr >
                                            {/* <th>Executor Name</th> */}
                                            <th className='text-center'>Initiated By</th>
                                            <th className='text-center'>Triggered By </th>
                                            <th className='text-center'>Start Date </th>
                                            <th className='text-center'>Status</th>
                                            <th className='text-center'>Time Difference</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logsData !== '' && logsData?.length > 0 ? logsData.map((data, index) => (
                                            <>
                                                <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px', }} key={index}>
                                                    {/* <td>{data.executorName}</td> */}
                                                    <td className='text-center'>{data.initiatedBy}</td>
                                                    <td className='text-center'>{data.triggeredBy}</td>
                                                    <td className='text-center'>{data.startDate}</td>
                                                    <td className='text-center' style={{color:data.status==='success'?'green':'red'}}>{data.status}</td>
                                                    <td className='text-center'>{millisecondsToMinutes(data.timeDiff)}</td>

                                                </tr>
                                                <div style={{ marginTop: '5px' }}></div>
                                            </>
                                        )) : ""}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        :
                        <p style={{ marginTop: '100px' }} className="text-center">
                            {/* <img src={NodataImage} style={{ width: '30%' }} /> */}
                        </p>
                }



            </div>

        </>
    )
}
