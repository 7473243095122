import React from 'react'

const DBR = ({ internalScoring,finalScore, internalHandler, submit, validationMessages ,setcategoriesData}) => (
    <div style={{ padding: '10px' }}>
        <div style={{ background: '#DBE9FF', padding: '10px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}><b>Score Card </b>
        {/* <span style={{float:'right'}}><button className='btn btn-default' style={{marginTop:'-7px',background:'#267DFF',color:'#fff',fontWeight:'700'}} onClick={()=>setcategoriesData(true)}>Categorized Scoring</button></span> */}
        </div>

        <table className="scoring-table">
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>  Stability</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.stability}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Debts</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.debts}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>KYC</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.kyc}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>NTC</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.ntc}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Bureau</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.bureau}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Residence</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.residence}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Overdue Msg</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.overDueMsgsTrend}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Access</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.access}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Corporate</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.corporate}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Multiple Pay Day</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{internalScoring.multiplePayday}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderBottomLeftRadius: '10px', borderRight: 'none' }}>Final Score</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right', borderBottomRightRadius: '10px' }}>{finalScore}</td>
            </tr>
        </table>



    </div >
)

export default DBR