import React, { Component } from 'react'
import AdminNotification from '../../../presentationals/Admin/CustomerPages/CommentAndNotification.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { pushNotification, notificationImageUploadApi } from '../AdminActionCreator.component'

let File = '';

class MessageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            title: '',
            message: '',
            notificationImage: '',
            url: '',
            typeOfFile:'',
            comment:''
        }
    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AdminNotification
                comment={this.state.comment}
                    sendHandler={this.sendHandler.bind(this)}
                    messageHandler={this.messageHandler.bind(this)}
                    createLink={this.createLink.bind(this)}
                    commentHandler={this.commentHandler.bind(this)}
                    uploadNotificationImage={this.uploadNotificationImage.bind(this)}
                    notificationImage={this.state.notificationImage}
                />
            </div>
        )
    }

    uploadNotificationImage(e){
        this.setState({typeOfFile:e.target.files[0].type})
        let val = e.target.value.split(/[\\\/]/)[2];
        this.setState({ notificationImage: val })
        File = e.target.files[0];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        // if (fileType == "jpeg" ||fileType == "jpg" || fileType == "png") {
            notificationImageUploadApi(File, val,e.target.files[0].type,
                (callBack) => {
                    if(callBack!='failure'){
                    this.setState({
                        popupState:true,popupStatus:'File Uploaded',
                        notificationImage: callBack
                    },()=>this.removePopup())
                }else{
                    this.setState({popupState:true,popupStatus:'Failed To upload file'},()=>this.removePopup())
                }
                });
        // }
    
    // else{
    //     this.setState({popupState:true,popupStatus:'File Size should less than 5 Mb'})
    //     this.removePopup()
    // }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    commentHandler(e,type) {
        if(type == 'text'){
        this.setState({ comment: e.target.value });
        }
        if(type == 'select'){
            this.setState({ comment: e.target.value });
        }
    }

    messageHandler(e, type) {
        if (type === "title") {
            this.setState({ title: e.target.value });
        } else if (type === "url") {
            this.setState({ url: e.target.value });
        }
        // else {
        //     this.setState({ message: e.target.value });
        // }
    }

    sendHandler(type) {
        const { allDetail, admin } = this.props;
        if (type == 'notification') {
            let message = document.getElementById("appNotification").innerHTML
            pushNotification(allDetail, message, this.state.title, admin.emailId, 'notification', '', this.state.notificationImage, '', '','',
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Notification Sent Successfully',
                        notificationImage: ''
                    })
                    this.removePopup();
                })
        } else if (type == 'comment') {
            pushNotification(allDetail, this.state.comment, this.state.title, admin.emailId, "comment", '', this.state.notificationImage, '', '','',
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Comment Saved Successfully',
                        notificationImage: ''
                    })
                    this.removePopup();
                })
        }
    }

    createLink() {
        var selection = document.getSelection();
        document.execCommand('createLink', true, this.state.url);
        selection.anchorNode.parentElement.target = '_blank';
    }

    // componentDidMount() {
    //     $(function () {
    //         $('#appNotification').on('selectstart', function () {
    //             $(document).one('mouseup', function () {
    //             })
    //         })
    //     })
    // }

}

export default MessageContainer;