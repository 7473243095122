import React, { useEffect, useState } from "react";
import { getKycFlagsApi, startStopKtcApi } from "../AdminActionCreator.component";
import Loader from '../../../presentationals/Loader/DotLoader.component'
import SidePopup from '../../../presentationals/Popup/Popup.component'
const StartStopKyc = () => {
    const [loaderState, setLoaderState] = useState(false)
    const [popupStatus, setpopupStatus] = useState('')
    const [popupState, setpopupState] = useState(false)
    const [flagsData, setFlags] = useState('')


    const handlekyc = (type, flag) => {
        setLoaderState(true)
        startStopKtcApi(!flag, type, callback => {
            console.log(callback)
            if (callback === 'success') {
                getKycFlagsApi(callback => {
                    setLoaderState(false)

                    setFlags(callback)

                })
                setpopupState(true)
                setpopupStatus('Done Successfully!')
                removePopup()
            } else {
                setLoaderState(false)

                setpopupState(true)
                setpopupStatus('Please Try again!')
                removePopup()
            }
        })

    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
            setpopupStatus('')
        }, 5000);
    }
    const closePopup = () => {
        setpopupState(false)
    }
    useEffect(() => {
        getKycFlagsApi(callback => {
            console.log(callback)
            setFlags(callback)
        })
    }, [])
    return (
        <div className="container-fluid text-center">
            {popupState ? <SidePopup popupStatus={popupStatus} closePopup={closePopup} /> : ""}
            <h1>Enable/Disabled KYC</h1>

            <div className="row">
                <div className="col-sm-4 col-xs-4"></div>
                <div className="col-sm-4 col-xs-4">
                    {flagsData !== '' && flagsData.message !== undefined && flagsData.message === 'success' ?
                        <div className="card-bank check-new" style={{ borderRadius: '10px', marginTop: '40px' }}>
                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col-sm-6 col-xs-6" style={{ marginTop: '20px' }}>Repeat Kyc</div>
                                <div className="col-sm-6 col-xs-6 ">
                                    <div class="box_4">
                                        <div class="input_wrapper">
                                            <input type="checkbox" class="switch_4" onChange={() => handlekyc('repeatKyc', flagsData.repeatKyc)} checked={flagsData.repeatKyc} />
                                            <svg class="is_checked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 426.67">
                                                <path d="M153.504 366.84c-8.657 0-17.323-3.303-23.927-9.912L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.387c13.218-13.214 34.65-13.218 47.86 0 13.22 13.218 13.22 34.65 0 47.863L177.435 356.928c-6.61 6.605-15.27 9.91-23.932 9.91z" />
                                            </svg>
                                            <svg class="is_unchecked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.982 212.982">
                                                <path d="M131.804 106.49l75.936-75.935c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0L106.49 81.18 30.555 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.99 6.99-6.99 18.323 0 25.312L81.18 106.49 5.24 182.427c-6.99 6.99-6.99 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0L106.49 131.8l75.938 75.937c6.99 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.323 0-25.313l-75.936-75.936z" fill-rule="evenodd" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col-sm-6 col-xs-6" style={{ marginTop: '20px' }}>New Kyc</div>
                                <div className="col-sm-6 col-xs-6">
                                    <div class="switch_box box_4">
                                        <div class="input_wrapper">
                                            <input type="checkbox" class="switch_4" onChange={() => handlekyc('newKyc', flagsData.newKyc)} checked={flagsData.newKyc} />
                                            <svg class="is_checked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 426.67">
                                                <path d="M153.504 366.84c-8.657 0-17.323-3.303-23.927-9.912L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.387c13.218-13.214 34.65-13.218 47.86 0 13.22 13.218 13.22 34.65 0 47.863L177.435 356.928c-6.61 6.605-15.27 9.91-23.932 9.91z" />
                                            </svg>
                                            <svg class="is_unchecked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.982 212.982">
                                                <path d="M131.804 106.49l75.936-75.935c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0L106.49 81.18 30.555 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.99 6.99-6.99 18.323 0 25.312L81.18 106.49 5.24 182.427c-6.99 6.99-6.99 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0L106.49 131.8l75.938 75.937c6.99 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.323 0-25.313l-75.936-75.936z" fill-rule="evenodd" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col-sm-6 col-xs-6" style={{ marginTop: '20px' }}>Re-Kyc-New</div>
                                <div className="col-sm-6 col-xs-6">
                                    <div class="switch_box box_4">
                                        <div class="input_wrapper">
                                            <input type="checkbox" class="switch_4" onChange={() => handlekyc('reKycNew', flagsData.reKycNew)} checked={flagsData.reKycNew} />
                                            <svg class="is_checked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 426.67">
                                                <path d="M153.504 366.84c-8.657 0-17.323-3.303-23.927-9.912L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.387c13.218-13.214 34.65-13.218 47.86 0 13.22 13.218 13.22 34.65 0 47.863L177.435 356.928c-6.61 6.605-15.27 9.91-23.932 9.91z" />
                                            </svg>
                                            <svg class="is_unchecked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.982 212.982">
                                                <path d="M131.804 106.49l75.936-75.935c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0L106.49 81.18 30.555 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.99 6.99-6.99 18.323 0 25.312L81.18 106.49 5.24 182.427c-6.99 6.99-6.99 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0L106.49 131.8l75.938 75.937c6.99 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.323 0-25.313l-75.936-75.936z" fill-rule="evenodd" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <div className="col-sm-6 col-xs-6" style={{ marginTop: '20px' }}>Re-Kyc-Repeat</div>
                                <div className="col-sm-6 col-xs-6">
                                    <div class="switch_box box_4">
                                        <div class="input_wrapper">
                                            <input type="checkbox" class="switch_4" onChange={() => handlekyc('reKycRepeat', flagsData.reKycRepeat)} checked={flagsData.reKycRepeat} />
                                            <svg class="is_checked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 426.67">
                                                <path d="M153.504 366.84c-8.657 0-17.323-3.303-23.927-9.912L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.387c13.218-13.214 34.65-13.218 47.86 0 13.22 13.218 13.22 34.65 0 47.863L177.435 356.928c-6.61 6.605-15.27 9.91-23.932 9.91z" />
                                            </svg>
                                            <svg class="is_unchecked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.982 212.982">
                                                <path d="M131.804 106.49l75.936-75.935c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0L106.49 81.18 30.555 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.99 6.99-6.99 18.323 0 25.312L81.18 106.49 5.24 182.427c-6.99 6.99-6.99 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0L106.49 131.8l75.938 75.937c6.99 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.323 0-25.313l-75.936-75.936z" fill-rule="evenodd" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        : ""}
                </div>

                <div className="col-sm-4 col-xs-4"></div>

            </div>
            {loaderState ?
                <Loader />
                : ""}
        </div>
    )
}
export default StartStopKyc