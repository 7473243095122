// import '../components/presentationals/Login/css/Registration.scss';

import { TYPE, PLACEHOLDER, EMPLOYER_TYPE, STRING } from './Constant'
import moment from 'moment';

var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
var pattern = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
var specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
// var passCheck = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
var passCheck = /^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&.])[\w!@#$%&.]{8,40}$/;
var IfscCheck = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/


export const ReferenceValidation = (referenceDetails) => {
    if (referenceDetails.firstName === '') {
        return { status: false, msg: 'Please enter First Name', field: 'firstname' };
    }
    else if (referenceDetails.firstNumber.length > 10 || referenceDetails.firstNumber.length < 10) {
        return { status: false, msg: 'Please enter First valid 10 digit Mobile No.', field: 'first' };
    }
    else if (referenceDetails.secondName === '') {
        return { status: false, msg: 'Please enter Second Name', field: 'secondname' };
    }
    else if (referenceDetails.secondNumber.length > 10 || referenceDetails.secondNumber.length < 10) {
        return { status: false, msg: 'Please enter Second valid 10 digit Mobile No.', field: 'second' };
    }

    else if (referenceDetails.thirdName === '') {
        return { status: false, msg: 'Please enter Third Name', field: 'thirdname' };
    }
    else if (referenceDetails.thirdNumber.length > 10 || referenceDetails.thirdNumber.length < 10) {
        return { status: false, msg: 'Please enter Third valid 10 digit Mobile No.', field: 'third' };
    }
    return { status: true, msg: '' };
}
export const ReferenceValidationAdmin = (referenceDetails) => {
    if (referenceDetails.name1 === '') {
        return { status: false, msg: 'Please enter First Name', field: 'name1' };
    }
    else if (referenceDetails.number1.length > 10 || referenceDetails.number1.length < 10) {
        return { status: false, msg: 'Please enter First valid 10 digit Mobile No.', field: 'number1' };
    }

    let number = JSON.stringify(referenceDetails.number1)
    if (number.includes('.') || number.includes('-') || number.includes('+')) {
        return { status: false, msg: 'Special Characters Not Allowed !', field: 'number1' };
    }

    else if (referenceDetails.relationShip1 === '') {
        return { status: false, msg: 'Please Select First  Relationship', field: 'relationShip1' };
    }
    else if (referenceDetails.name2 === '') {
        return { status: false, msg: 'Please enter Second Name', field: 'name2' };
    }


    else if (referenceDetails.number2.length > 10 || referenceDetails.number2.length < 10) {
        return { status: false, msg: 'Please enter Second valid 10 digit Mobile No.', field: 'number2' };
    }
    let number2 = JSON.stringify(referenceDetails.number2)
    if (number2.includes('.') || number2.includes('-') || number2.includes('+')) {
        return { status: false, msg: 'Special Characters Not Allowed !', field: 'number2' };
    }

    else if (referenceDetails.relationShip2 === '') {
        return { status: false, msg: 'Please Select Second Relationship', field: 'relationShip2' };
    }
    else if (referenceDetails.name3 === '') {
        return { status: false, msg: 'Please enter Third Name', field: 'name3' };
    }
    else if (referenceDetails.number3.length > 10 || referenceDetails.number3.length < 10) {
        return { status: false, msg: 'Please enter Third valid 10 digit Mobile No.', field: 'number3' };
    }
    let number3 = JSON.stringify(referenceDetails.number3)
    if (number3.includes('.') || number3.includes('-') || number3.includes('+')) {
        return { status: false, msg: 'Special Characters Not Allowed !', field: 'number3' };
    }


    else if (referenceDetails.relationShip3 === '') {
        return { status: false, msg: 'Please Select Third  Relationship', field: 'relationShip3' };
    }
    if (referenceDetails.name1 !== '' && referenceDetails.name2 !== '' && referenceDetails.name3 !== '') {
        if (referenceDetails.name1 === referenceDetails.name2) {
            return { status: false, msg: 'Please enter Unique Name', field: 'name3' };
        }
        if (referenceDetails.name2 === referenceDetails.name3) {
            return { status: false, msg: 'Please enter Unique Name', field: 'name3' };
        }
        if (referenceDetails.name3 === referenceDetails.name1) {
            return { status: false, msg: 'Please enter Unique Name', field: 'name3' };
        }
        if (referenceDetails.name1 == referenceDetails.name3) {
            return { status: false, msg: 'Please enter Unique name', field: 'name3' };
        }
        if (referenceDetails.name2 == referenceDetails.name1) {
            return { status: false, msg: 'Please enter Unique name', field: 'name3' };
        }
        if (referenceDetails.name3 == referenceDetails.name1) {
            return { status: false, msg: 'Please enter Unique name', field: 'name3' };
        }
    }
    if (referenceDetails.number1 !== '' && referenceDetails.number2 !== '' && referenceDetails.number3 !== '') {
        if (referenceDetails.number1 == referenceDetails.number2) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number2 == referenceDetails.number3) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number3 == referenceDetails.number2) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }

        if (referenceDetails.number1 == referenceDetails.number3) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number2 == referenceDetails.number1) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number3 == referenceDetails.number1) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }


        // if (referenceDetails.number1 === referenceDetails.number2 || referenceDetails.number2 === referenceDetails.number1 || referenceDetails.number3 === referenceDetails.number1) {
        //     return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        // }
        // if (referenceDetails.number2 === referenceDetails.number3 || referenceDetails.number3 === referenceDetails.number2|| referenceDetails.number2 === referenceDetails.number1) {
        //     return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        // }
        // if (referenceDetails.number3 === referenceDetails.number1 || referenceDetails.number2 === referenceDetails.number3|| referenceDetails.number1 === referenceDetails.number3) {
        //     return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        // }
    }
    return { status: true, msg: '' };
}

export const Emailvalidate = (userEmailInput) => {
    if (userEmailInput == '') {
        return { status: false, msg: 'Please enter your Email', field: 'emailId' };
    } else if (!filter.test(userEmailInput)) {
        return { status: false, msg: 'Please enter a valid Email', field: 'emailId' };
    }
    return { status: true, msg: '' };
}

export const basicFormValidation = (userDetail) => {
    if (userDetail.firstNameState === "" || userDetail.firstNameState === null) {
        return { status: false, msg: 'Please enter First Name', field: "firstName" };
    }
    else if (specialChar.test(userDetail.firstNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'firstName' };
    }
    if (specialChar.test(userDetail.MiddleNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'middleName' };
    }
    if (userDetail.lastNameState === "" || userDetail.lastNameState === null) {
        return { status: false, msg: 'Please enter Last Name', field: "lastName" };
    } else if (specialChar.test(userDetail.lastNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'lastName' };
    }
    if (userDetail.BasicDOB === "" || userDetail.BasicDOB === null) {
        return { status: false, msg: 'Please enter Date of Birth', field: "BasicDOB" };
    }
    if (moment(new Date()).diff(moment(userDetail.BasicDOB), 'year') <= 19) {
        return { status: false, msg: 'Minimum age should be 20 Years or above', field: "BasicDOB" };
    }
    if (userDetail.GenderState === "" || userDetail.GenderState === null) {
        return { status: false, msg: 'Please Select Gender', field: "Gender" };
    }
    // if (userDetail.HowToKnowState === "" || userDetail.HowToKnowState === null) {
    //     return { status: false, msg: 'Select how did you found about us.', field: "howToKnow" };
    // }
    if (userDetail.alternateMobileState == "" || userDetail.alternateMobileState == null) {
        return { status: false, msg: 'Please enter alternate Mobile No.', field: 'alternateMobile' };
    } else if (userDetail.alternateMobileState.length > 10 || userDetail.alternateMobileState.length < 10) {
        return { status: false, msg: 'Please enter a valid 10 digit Mobile No.', field: 'alternateMobile' };
    } else if (userDetail.alternateMobileState == userDetail.mobileNumber) {
        return { status: false, msg: 'This number already registered', field: 'c' };
    }
    if (userDetail.EmailIDState == "") {
        return { status: false, msg: 'Please enter your Email Id', field: 'emailId' };
    } else if (!filter.test(userDetail.EmailIDState)) {
        return { status: false, msg: 'Please enter a valid Email Id', field: 'emailId' };
    }
    return { status: true, msg: ' ' };
}
export const basicFormValidationbadaBuisness = (userDetail) => {
    if (userDetail.firstNameState === "" || userDetail.firstNameState === null) {
        return { status: false, msg: 'Please enter First Name', field: "firstName" };
    }
    else if (specialChar.test(userDetail.firstNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'firstName' };
    }

    if (userDetail.BasicDOB === "" || userDetail.BasicDOB === null) {
        return { status: false, msg: 'Please enter Date of Birth', field: "BasicDOB" };
    }
    if (moment(new Date()).diff(moment(userDetail.BasicDOB), 'year') <= 19) {
        return { status: false, msg: 'Minimum age should be 20 Years or above', field: "BasicDOB" };
    }

    if (userDetail.EmailIDState == "") {
        return { status: false, msg: 'Please enter your Email Id', field: 'emailId' };
    } else if (!filter.test(userDetail.EmailIDState)) {
        return { status: false, msg: 'Please enter a valid Email Id', field: 'emailId' };
    }
    return { status: true, msg: ' ' };
}
export const ResidenceFormValidation = (userDetail) => {
    if (userDetail.CurrentAddressState === "" || userDetail.CurrentAddressState === null) {
        return { status: false, msg: 'Please enter your Current Address', field: "CurrentAddress" };
    }
    if (userDetail.CurrentPinCodeState === "" || userDetail.CurrentPinCodeState === null) {
        return { status: false, msg: 'Please enter your Current PinCode', field: "CurrentPin" };
    } else if (userDetail.CurrentPinCodeState.length != 6) {
        return { status: false, msg: 'Please enter 6 Digit PinCode', field: "CurrentPin" };
    } else if (specialChar.test(userDetail.CurrentPinCodeState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'CurrentPin' };
    }
    if (userDetail.CurrentStateState === "" || userDetail.CurrentStateState === null) {
        return { status: false, msg: 'We are not Providing loan for this location', field: "CurrentPin" };
    }
    // if (userDetail.CurrentResidenceTypeValue === "" || userDetail.CurrentResidenceTypeValue === null) {
    //     return { status: false, msg: 'Please select your Current Residence Type', field: "CurrentResidenceType" };
    // }
    // if (userDetail.CurrentResidenceTypeValue != "Own") {
    //     if (userDetail.CurrentDurationState === "" || userDetail.CurrentDurationState === null) {
    //         return { status: false, msg: 'Please Enter Current Residence Duration', field: "CurrentDuration" };
    //     }
    // }

    if (userDetail.PermanentAddressState === "" || userDetail.PermanentAddressState === null) {
        return { status: false, msg: 'Please enter your Permanent Address', field: "PermanentAddress" };
    }
    if (userDetail.PermanentPinCodeState === "" || userDetail.PermanentPinCodeState === null) {
        return { status: false, msg: 'Please enter your Permanent PinCode', field: "PermanentPin" };
    } else if (userDetail.PermanentPinCodeState.length != 6) {
        return { status: false, msg: 'Please enter 6 Digit PinCode', field: "PermanentPin" };
    } else if (specialChar.test(userDetail.PermanentPinCodeState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'PermanentPin' };
    }
    // if (userDetail.PermanentResidenceTypeValue === "" || userDetail.PermanentResidenceTypeValue === null) {
    //     return { status: false, msg: 'Please select your Permanent Residence Type', field: "PermanentResidenceType" };
    // }
    // if (userDetail.PermanentResidenceTypeValue != "Own") {
    //     if (userDetail.PermanentDurationState === "" || userDetail.PermanentDurationState === null) {
    //         return { status: false, msg: 'Please Enter Permanent Residential Duration', field: "PermanentDuration" };
    //     }
    // }
    return { status: true, msg: ' ' };
}
export const BadaBuisnessResidenceFormValidation = (userDetail) => {
    if (userDetail.CurrentAddressState === "" || userDetail.CurrentAddressState === null) {
        return { status: false, msg: 'Please enter your Current Address', field: "CurrentAddress" };
    }
    if (userDetail.CurrentPinCodeState === "" || userDetail.CurrentPinCodeState === null) {
        return { status: false, msg: 'Please enter your Current PinCode', field: "CurrentPin" };
    } else if (userDetail.CurrentPinCodeState.length != 6) {
        return { status: false, msg: 'Please enter 6 Digit PinCode', field: "CurrentPin" };
    } else if (specialChar.test(userDetail.CurrentPinCodeState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'CurrentPin' };
    }
    if (userDetail.CurrentStateState === "" || userDetail.CurrentStateState === null) {
        return { status: false, msg: 'We are not Providing loan for this location', field: "CurrentPin" };
    }


    return { status: true, msg: ' ' };
}


export const occupationFormValidation = (userDetail, salaryCheck) => {
    console.log(userDetail,salaryCheck)
    if (userDetail.HighQualificationState === "" || userDetail.HighQualificationState === null) {
        return { status: false, msg: 'Please select your Highest Qualification', field: "HighQualification" };
    }
    // if (userDetail.fullPartTimeState === "" || userDetail.fullPartTimeState === null) {
    //     return { status: false, msg: 'Please select your Job Type', field: "fullPartTime" };
    // }
    if (userDetail.EmployeeTypeValue === "" || userDetail.EmployeeTypeValue === null) {
        return { status: false, msg: 'Please select your Employee Type', field: "EmployeeTypeValue" };
    }
    if (userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SALARY) {
        if (userDetail.OrganizationNameState === "" || userDetail.OrganizationNameState === null) {
            return { status: false, msg: 'Please enter your Organization\'s Name', field: "OrganizationName" };
        } else if (specialChar.test(userDetail.OrganizationNameState)) {
            return { status: false, msg: 'Special characters not Allowed', field: 'OrganizationName' };
        }

        if (userDetail.designationState === "" || userDetail.designationState === null) {
            return { status: false, msg: 'Please enter your Designation', field: "designation" };
        } else if (specialChar.test(userDetail.designationState)) {
            return { status: false, msg: 'Special characters not Allowed', field: 'designation' };
        }
        // if (userDetail.noOfEmployeeState === "" || userDetail.noOfEmployeeState === null) {
        //     return { status: false, msg: 'Please enter No. of Employees in your organisation', field: "noOfEmployee" };
        // } else if (specialChar.test(userDetail.noOfEmployeeState)) {
        //     return { status: false, msg: 'Special characters not Allowed', field: 'noOfEmployee' };
        // }
        if (userDetail.HomeSalaryState === "" || userDetail.HomeSalaryState === null) {
            return { status: false, msg: 'Please enter your current Salary', field: "HomeSalary" };
        } else if (userDetail.HomeSalaryState < salaryCheck.minimumSalary) {
            return { status: false, msg: salaryCheck.userSalaryMsg, field: "HomeSalary" };
        }
        // if (userDetail.officepincode === "" || userDetail.officepincode === null) {
        //     return { status: false, msg: 'Please enter your Office Pincode', field: "officepincode" };
        // }
        // if (userDetail.WorkExperienceCurrentState === "" || userDetail.WorkExperienceCurrentState === null) {
        //     return { status: false, msg: 'Please select your Work Experience(in current organization)', field: "WorkExperienceCurrent" };
        // }
        // if (userDetail.IsFirstJobState === "" || userDetail.IsFirstJobState === null) {
        //     return { status: false, msg: 'Is This Your First Job?', field: "IsFirstJob" };
        // }
        // if (userDetail.IsFirstJobState == STRING.NO) {
        //     if (userDetail.WorkExperiencePreviousState === "" || userDetail.WorkExperiencePreviousState === null) {
        //         return { status: false, msg: 'Please select your Work Experience(in previous organization)', field: "PreviousWork" };
        //     }
        //     if (userDetail.WorkExperienceTotalState === "" || userDetail.WorkExperienceTotalState === null) {
        //         return { status: false, msg: 'Please enter your Total Experience', field: "WorkExperienceTotal" };
        //     }
        // }
    } else if (userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SELF_EMPLOYED) {
        if (localStorage.getItem('uniqueId') === '' || localStorage.getItem('uniqueId') === 'null') {
            if (userDetail.EmployeeTypeState === "" || userDetail.EmployeeTypeState === null) {
                return { status: false, msg: 'Please enter Self Employee Type', field: "SelfEmployeeType" };
            }
            if (userDetail.IndustrialTypeState === "" || userDetail.IndustrialTypeState === null) {
                return { status: false, msg: 'Please enter Industry Type', field: "IndustrialType" };
            }
            if (userDetail.IndustrialTypeState == STRING.OTHERS) {
                if (userDetail.otherIndustrialTypeState === "" || userDetail.otherIndustrialTypeState === null) {
                    return { status: false, msg: 'Please enter Other Industry Type', field: "IndustrialType" };
                } else if (specialChar.test(userDetail.otherIndustrialTypeState)) {
                    return { status: false, msg: 'Special characters not Allowed', field: 'IndustrialType' };
                }
            }

        }

        if (userDetail.OrganizationNameState === "" || userDetail.OrganizationNameState === null) {
            return { status: false, msg: 'Please enter your Organization\'s Name', field: "OrganizationName" };
        } else if (specialChar.test(userDetail.OrganizationNameState)) {
            return { status: false, msg: 'Special characters not Allowed', field: 'OrganizationName' };
        }
        if (userDetail.MonthlyIncomeState === "" || userDetail.MonthlyIncomeState === null) {
            return { status: false, msg: 'Please enter Monthly Income', field: "MonthlyIncome" };
        } else if (userDetail.MonthlyIncomeState <= 15000) {
            return { status: false, msg: 'Minimum Income is 15000', field: "MonthlyIncome" };
        }
        // if (userDetail.WorkExperienceTotalState === "" || userDetail.WorkExperienceTotalState === null) {
        //     return { status: false, msg: 'Please enter your Total Experience', field: "WorkExperienceTotal" };
        // }
        // if (userDetail.officepincode === "" || userDetail.officepincode === null) {
        //     return { status: false, msg: 'Please enter your Office Pincode', field: "officepincode" };
        // }

    }
    return { status: true, msg: ' ' };
}

export const BankFormValidation = (userDetail) => {
    if (userDetail.BankUserNameState === "" || userDetail.BankUserNameState === null) {
        return { status: false, msg: 'Please enter Name as per the Bank Account', field: "BankUserName" };
    }
     else if (specialChar.test(userDetail.BankUserNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'BankUserName' };
    }
    if (userDetail.AccountNoState === "" || userDetail.AccountNoState === null) {
        return { status: false, msg: 'Please enter your Bank Account Number', field: "AccountNo" };
    } else if (userDetail.AccountNoState.length > 25) {
        return { status: false, msg: 'Invalid Account Number', field: "AccountNo" };
    }
    if (userDetail.IFSCCodeState === "" || userDetail.IFSCCodeState === null) {
        return { status: false, msg: 'Please enter IFSC Code', field: "IFSCCode" };
    } else if (userDetail.IFSCCodeState.length < 11 || userDetail.IFSCCodeState.length > 11) {
        return { status: false, msg: 'Please enter 11 Digit IFSC Code', field: "IFSCCode" };
    }
    // if (userDetail.IfSalriedAccountState === "" || userDetail.IfSalriedAccountState === null) {
    //     return { status: false, msg: 'Is this your Salary Account?', field: "IfSalriedAccount" };
    // }
    if (userDetail.PanNoState === "" || userDetail.PanNoState === null) {
        return { status: false, msg: 'Please enter your PAN Number', field: "PanNo" };
    } else if (userDetail.PanNoState.length < 10 || userDetail.PanNoState.length > 10) {
        return { status: false, msg: 'Please enter valid 10 digit PAN Number', field: "PanNo" };
    } else if (!pattern.test(userDetail.PanNoState)) {
        return { status: false, msg: 'Please enter valid PAN Number(eg- ABCDE1234F)', field: "PanNo" };
    }
    if (userDetail.panNameVerify === "" || userDetail.panNameVerify === null) {
        return { status: false, msg: 'Please enter your Name as on Pancard', field: "PanName" };
    }
    if (userDetail.aadharNoState === "" || userDetail.aadharNoState === null) {
        return { status: false, msg: 'Please enter your Aadhaar Number', field: "aadharNo" };
    } else if (userDetail.aadharNoState.length > 12 || userDetail.aadharNoState.length < 12) {
        return { status: false, msg: 'Please enter valid 12 digit Aadhaar No', field: "aadharNo" };
    }


    return { status: true, msg: ' ' };
}
export const BadaBuisnessBankFormValidation = (userDetail) => {
    if (userDetail.BankUserNameState === "" || userDetail.BankUserNameState === null) {
        return { status: false, msg: 'Please enter Name as per the Bank Account', field: "BankUserName" };
    } else if (specialChar.test(userDetail.BankUserNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'BankUserName' };
    }
    if (userDetail.AccountNoState === "" || userDetail.AccountNoState === null) {
        return { status: false, msg: 'Please enter your Bank Account Number', field: "AccountNo" };
    } else if (userDetail.AccountNoState.length > 25) {
        return { status: false, msg: 'Invalid Account Number', field: "AccountNo" };
    }

    if (userDetail.IFSCCodeState === "" || userDetail.IFSCCodeState === null) {
        return { status: false, msg: 'Please enter IFSC Code', field: "IFSCCode" };
    } else if (userDetail.IFSCCodeState.length < 11 || userDetail.IFSCCodeState.length > 11) {
        return { status: false, msg: 'Please enter 11 Digit IFSC Code', field: "IFSCCode" };
    }
    // if (userDetail.IfSalriedAccountState === "" || userDetail.IfSalriedAccountState === null) {
    //     return { status: false, msg: 'Is this your Salary Account?', field: "IfSalriedAccount" };
    // }
    if (userDetail.PanNoState === "" || userDetail.PanNoState === null) {
        return { status: false, msg: 'Please enter your PAN Number', field: "PanNo" };
    } else if (userDetail.PanNoState.length < 10 || userDetail.PanNoState.length > 10) {
        return { status: false, msg: 'Please enter valid 10 digit PAN Number', field: "PanNo" };
    } else if (!pattern.test(userDetail.PanNoState)) {
        return { status: false, msg: 'Please enter valid PAN Number(eg- ABCDE1234F)', field: "PanNo" };
    }
    if (userDetail.panNameVerify === "" || userDetail.panNameVerify === null) {
        return { status: false, msg: 'Please enter your Name as on Pancard', field: "PanName" };
    }
    if (userDetail.aadharNoState === "" || userDetail.aadharNoState === null) {
        return { status: false, msg: 'Please enter your Aadhaar Number', field: "aadharNo" };
    } else if (userDetail.aadharNoState.length > 12 || userDetail.aadharNoState.length < 12) {
        return { status: false, msg: 'Please enter valid 12 digit Aadhaar No', field: "aadharNo" };
    }


    return { status: true, msg: ' ' };
}

export const BankUpdateValidation = (userDetail) => {
    if (userDetail.BankUserNameState === "" || userDetail.BankUserNameState === null) {
        return { status: false, msg: 'Please enter Name as per the Bank Account', field: "BankUserName" };
    } else if (specialChar.test(userDetail.BankUserNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'BankUserName' };
    }
    if (userDetail.AccountNoState === "" || userDetail.AccountNoState === null) {
        return { status: false, msg: 'Please enter your Bank Account Number', field: "AccountNo" };
    } else if (userDetail.AccountNoState.length > 25) {
        return { status: false, msg: 'Invalid Account Number', field: "AccountNo" };
    }
    if (userDetail.IFSCCodeState === "" || userDetail.IFSCCodeState === null) {
        return { status: false, msg: 'Please enter IFSC Code', field: "IFSCCode" };
    } else if (userDetail.IFSCCodeState.length < 11 || userDetail.IFSCCodeState.length > 11) {
        return { status: false, msg: 'Please enter 11 Digit IFSC Code', field: "IFSCCode" };
    }
    if (userDetail.IfSalriedAccountState === "" || userDetail.IfSalriedAccountState === null) {
        return { status: false, msg: 'Is this your Salary Account?', field: "IfSalriedAccount" };
    }

    return { status: true, msg: ' ' };
}


export const LoanFormValidation = (userDetail, loanType, loanRange, installmentList, loanText) => {
    if (loanText != undefined) {
        if (loanType == Object.keys(loanText)[0]) {
            if (userDetail.loanAmountState === "" || userDetail.loanAmountState === null) {

                return { status: false, msg: 'Please enter desired Loan Amount', field: "loanAmount" };
            } else if (userDetail.loanAmountState % 1000 != 0 && (localStorage.getItem('uniqueId') === '' || localStorage.getItem('uniqueId') === 'null')) {
                return { status: false, msg: 'Please enter Amount in denomination of Thousands', field: "loanAmount" };
            } else if (userDetail.selectEMI === '1' && (Number(userDetail.loanAmountState) > Number(installmentList[0].maxAmount) || Number(userDetail.loanAmountState) < Number(installmentList[0].minAmount))) {


                return { status: false, msg: 'Loan Amount should be between ' + installmentList[0].minAmount + '-' + installmentList[0].maxAmount, field: "loanAmount" };

            } else if (userDetail.selectEMI === '2' && (Number(userDetail.loanAmountState) > Number(installmentList[1].maxAmount) || Number(userDetail.loanAmountState) < Number(installmentList[1].minAmount))) {

                return { status: false, msg: 'Loan Amount should be between ' + installmentList[1].minAmount + '-' + installmentList[1].maxAmount, field: "loanAmount" };

            } else if (userDetail.selectEMI === '3' && (Number(userDetail.loanAmountState) > installmentList[2].maxAmount || Number(userDetail.loanAmountState) < installmentList[1].minAmount)) {
                return { status: false, msg: 'Loan Amount should be between ' + installmentList[2].minAmount + '-' + installmentList[2].maxAmount, field: "loanAmount" };

            } else if (userDetail.selectEMI === '6' && (Number(userDetail.loanAmountState) > Number(installmentList[3].maxAmount) || Number(userDetail.loanAmountState) < Number(installmentList[3].minAmount))) {
                return { status: false, msg: 'Loan Amount should be between ' + installmentList[3].minAmount + '-' + installmentList[3].maxAmount, field: "loanAmount" };

            } else if (userDetail.selectEMI === '12' && (Number(userDetail.loanAmountState) > Number(installmentList[4].maxAmount) || Number(userDetail.loanAmountState) < Number(installmentList[4].minAmount))) {
                return { status: false, msg: 'Loan Amount should be between ' + installmentList[4].minAmount + '-' + installmentList[4].maxAmount, field: "loanAmount" };

            }


            //  else if (Number(userDetail.loanAmountState) > Number(loanRange.Phocket[1]) || Number(userDetail.loanAmountState) < Number(loanRange.Phocket[0])) {
            //     return { status: false, msg: 'Loan Amount should be between ' + loanRange.Phocket[0] + '-' + loanRange.Phocket[1], field: "loanAmount" };
            // }
        } else if (loanType == Object.keys(loanText)[1]) {
            if (userDetail.loanAmountState === "" || userDetail.loanAmountState === null) {
                return { status: false, msg: 'Please enter desired Loan Amount', field: "loanAmount" };
            } else if (userDetail.loanAmountState % 1000 != 0 && (localStorage.getItem('uniqueId') === '' || localStorage.getItem('uniqueId') === 'null')) {
                return { status: false, msg: 'Please enter Amount in denomination of Thousands', field: "loanAmount" };
            } else if (Number(userDetail.loanAmountState) > Number(loanRange.Retra[1]) || Number(userDetail.loanAmountState) < Number(loanRange.Retra[0])) {
                return { status: false, msg: 'Loan Amount should be between ' + loanRange.Retra[0] + '-' + loanRange.Retra[1], field: "loanAmount" };
            }
        }
    }
    // if (userDetail.loanDaysState === "" || userDetail.loanDaysState === null) {
    //     return { status: false, msg: 'Please enter desired Loan Tenure', field: "loanDays" };
    // } else if (userDetail.loanDaysState > 60 || userDetail.loanDaysState < 7) {
    //     return { status: false, msg: 'Days should be between 7-60', field: "loanDays" };
    // }
    if (userDetail.loanReasonState === "" || userDetail.loanReasonState === null) {
        return { status: false, msg: 'Please enter the Loan Reason', field: "loanReason" };
    } else if (specialChar.test(userDetail.loanReasonState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'loanReason' };
    }
    // if (userDetail.PanNoState === "" || userDetail.PanNoState === null) {
    //     return { status: false, msg: 'Please enter your PAN Number', field: "PanNo" };
    // } else if (userDetail.PanNoState.length < 10 || userDetail.PanNoState.length > 10) {
    //     return { status: false, msg: 'Please enter valid 10 digit PAN Number', field: "PanNo" };
    // } else if (!pattern.test(userDetail.PanNoState)) {
    //     return { status: false, msg: 'Please enter valid PAN Number(eg- ABCDE1234F)', field: "PanNo" };
    // }
    // if (userDetail.aadharNoState === "" || userDetail.aadharNoState === null) {
    //     return { status: false, msg: 'Please enter your Aadhaar Number', field: "aadharNo" };
    // } else if (userDetail.aadharNoState.length > 12 || userDetail.aadharNoState.length < 12) {
    //     return { status: false, msg: 'Please enter valid 12 digit Aadhaar No', field: "aadharNo" };
    // }
    // if (userDetail.obligationState === "" || userDetail.obligationState === null) {
    //     return { status: false, msg: 'Please select obligation type', field: "obligationType" };
    // }
    // if (userDetail.obligationState == 'Yes') {
    //     if (userDetail.obligationTextState === "" || userDetail.obligationTextState === null) {
    //         return { status: false, msg: 'Please enter the Obligation', field: "obligation" };
    //     }
    // }
    return { status: true, msg: ' ' };
}

export const documentFormValidation = (userDetail, userData) => {
    if (localStorage.getItem('employmentType') !== 'Self Employed') {
        if (userDetail.PhotoState === "" || userDetail.PhotoState === null || userDetail.PhotoState === "No File Chosen") {
            return { status: false, msg: 'Please upload your Office Id', field: "Photo" };
        }
    }
    if (userDetail.IDProofState === "" || userDetail.IDProofState === null || userDetail.IDProofState === "No File Chosen") {
        return { status: false, msg: 'Please upload your Aadhaar card', field: "IDProof" };
    }
    if (userDetail.panProofState === "" || userDetail.panProofState === null || userDetail.panProofState === "No File Chosen") {
        return { status: false, msg: 'Please upload your Pan Card', field: "panProof" };
    }
    if (userDetail.BankStatementState === "" || userDetail.BankStatementState === null || userDetail.BankStatementState === "No File Chosen") {
        return { status: false, msg: 'Please upload the Bank Statements', field: "BankStatement" };
    }
    if (userData.employmentDetailsPO.employerType == EMPLOYER_TYPE.SALARY) {
        if (userDetail.SalarySlipState === "" || userDetail.SalarySlipState === null || userDetail.SalarySlipState === "No File Chosen") {
            return { status: false, msg: 'Please upload Salary slips', field: "SalarySlip" };
        }
    }
    // else if (userData.employmentDetailsPO.employerType == EMPLOYER_TYPE.SELF_EMPLOYED) {
    //     if (userDetail.ITRProofState === "" || userDetail.ITRProofState === null || userDetail.ITRProofState === "No File Chosen") {
    //         return { status: false, msg: 'Please upload ITR', field: "itrProof" };
    //     }
    // }
    if (userDetail.currnetAddSameAdharAdd === 2) {
        if (userDetail.currentAddressProofState === "" || userDetail.currentAddressProofState === null || userDetail.currentAddressProofState === "No File Chosen") {
            return { status: false, msg: 'Please upload the Current Address Proof', field: "currentAddProof" };
        }
    }
    return { status: true, msg: ' ' };
}

export const _formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
}

export const _preFormatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
}

export const _formatDateInDash = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
}

export const _formatDateInYMD = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export const LoanApplyValidation = (userDetail, employerType, userData, loanType, loanRange, installmentList, loanText) => {
    if (loanText != undefined) {
        if (loanType == Object.keys(loanText)[0]) {
            if (userDetail.loanAmountState === "" || userDetail.loanAmountState === null) {
                return { status: false, msg: 'Please enter desired Loan Amount', field: "loanAmount" };
            } else if (userDetail.loanAmountState % 1000 != 0 && (localStorage.getItem('uniqueId') === '' || localStorage.getItem('uniqueId') === 'null')) {
                return { status: false, msg: 'Please enter Amount in denomination of Thousands', field: "loanAmount" };
            } else if (userDetail.selectEMI === '1' && (Number(userDetail.loanAmountState) > Number(installmentList[0].maxAmount) || Number(userDetail.loanAmountState) < Number(installmentList[0].minAmount))) {


                return { status: false, msg: 'Loan Amount should be between ' + installmentList[0].minAmount + '-' + installmentList[0].maxAmount, field: "loanAmount" };

            } else if (userDetail.selectEMI === '2' && (Number(userDetail.loanAmountState) > Number(installmentList[1].maxAmount) || Number(userDetail.loanAmountState) < Number(installmentList[1].minAmount))) {

                return { status: false, msg: 'Loan Amount should be between ' + installmentList[1].minAmount + '-' + installmentList[1].maxAmount, field: "loanAmount" };

            } else if (userDetail.selectEMI === '3' && (Number(userDetail.loanAmountState) > installmentList[2].maxAmount || Number(userDetail.loanAmountState) < installmentList[1].minAmount)) {
                return { status: false, msg: 'Loan Amount should be between ' + installmentList[2].minAmount + '-' + installmentList[2].maxAmount, field: "loanAmount" };

            } else if (userDetail.selectEMI === '6' && (Number(userDetail.loanAmountState) > Number(installmentList[3].maxAmount) || Number(userDetail.loanAmountState) < Number(installmentList[3].minAmount))) {
                return { status: false, msg: 'Loan Amount should be between ' + installmentList[3].minAmount + '-' + installmentList[3].maxAmount, field: "loanAmount" };

            } else if (userDetail.selectEMI === '12' && (Number(userDetail.loanAmountState) > Number(installmentList[4].maxAmount) || Number(userDetail.loanAmountState) < Number(installmentList[4].minAmount))) {
                return { status: false, msg: 'Loan Amount should be between ' + installmentList[4].minAmount + '-' + installmentList[4].maxAmount, field: "loanAmount" };

            }
            //  else if (Number(userDetail.loanAmountState) > Number(loanRange.Phocket[1]) || Number(userDetail.loanAmountState) < Number(loanRange.Phocket[0])) {
            //     return { status: false, msg: 'Loan Amount should be between ' + loanRange.Phocket[0] + '-' + loanRange.Phocket[1], field: "loanAmount" };
            // }
        } else if (loanType == Object.keys(loanText)[1]) {
            if (userDetail.loanAmountState === "" || userDetail.loanAmountState === null) {
                return { status: false, msg: 'Please enter desired Loan Amount', field: "loanAmount" };
            } else if (userDetail.loanAmountState % 1000 != 0 && (localStorage.getItem('uniqueId') === '' || localStorage.getItem('uniqueId') === 'null')) {
                return { status: false, msg: 'Please enter Amount in denomination of Thousands', field: "loanAmount" };
            } else if (Number(userDetail.loanAmountState) > Number(loanRange.Retra[1]) || Number(userDetail.loanAmountState) < Number(loanRange.Retra[0])) {
                return { status: false, msg: 'Loan Amount should be between ' + loanRange.Retra[0] + '-' + loanRange.Retra[1], field: "loanAmount" };
            }
        }
    }
    // if (userDetail.loanAmountState === "" || userDetail.loanAmountState === null) {
    //     return { status: false, msg: 'Please enter desired Loan Amount' };
    // } else if (userDetail.loanAmountState > 100000 || userDetail.loanAmountState < 5000) {
    //     return { status: false, msg: 'Loan Amount should be between 5000-100000' };
    // }
    // if (userDetail.loanDaysState === "" || userDetail.loanDaysState === null) {
    //     return { status: false, msg: 'Please enter desired Loan Tenure' };
    // } else if (userDetail.loanDaysState > 60 || userDetail.loanDaysState < 7) {
    //     return { status: false, msg: 'Days should be between 7-60' };
    // }
    if (userDetail.loanReasonState === "" || userDetail.loanReasonState === null) {
        return { status: false, msg: 'Please enter the Loan Reason' };
    } else if (specialChar.test(userDetail.loanReasonState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'loanReason' };
    }
    if (userDetail.obligationState === "" || userDetail.obligationState === null) {
        return { status: false, msg: 'Please select obligation type', field: "obligationType" };
    }
    if (userDetail.obligationState == 'Yes') {
        if (userDetail.obligationTextState === "" || userDetail.obligationTextState === null) {
            return { status: false, msg: 'Please enter the Obligation', field: "obligation" };
        }
    }
    // if (userDetail.IDProofState === "No File Chosen" || userDetail.IDProofState === null) {
    //     return { status: false, msg: 'Please upload your Aadhaar card' };
    // }
    if (userDetail.BankStatementState === "No File Chosen" || userDetail.BankStatementState === null) {
        return { status: false, msg: 'Please upload the Bank Statements' };
    }
    if (employerType == EMPLOYER_TYPE.SALARY) {
        if (userDetail.SalarySlipState === "No File Chosen" || userDetail.SalarySlipState === null) {
            return { status: false, msg: 'Please upload Salary slips' };
        }
    } else if (employerType == EMPLOYER_TYPE.SELF_EMPLOYED) {
        if (userDetail.ITRProofSelfState === "No File Chosen" || userDetail.ITRProofSelfState === null) {
            return { status: false, msg: 'Please upload ITR proof' };
        }
    }
    if (userDetail.currnetAddSameAdharAdd === 2) {
        if (userData.documentList != null && userData.documentList.currentAddressUrl == null) {
            if (userDetail.currentAddressProofState === "" || userDetail.currentAddressProofState === null || userDetail.currentAddressProofState === "No File Chosen") {
                return { status: false, msg: 'Please upload the Current Address Proof', field: "currentAddProof" };
            }
        }
    }
    return { status: true, msg: ' ' };
}

export const loginValidation = (number) => {
    if (number == "" || number == null) {
        return { status: false, msg: 'Please enter your Mobile No.', field: 'MobileNo' };
    } else if (number.length > 10 || number.length < 10) {
        return { status: false, msg: 'Please enter a valid 10 digit Mobile No.', field: 'MobileNo' };
    }
    return { status: true, msg: '' };
}

export const loginOTPValidation = (otp) => {
    if (otp == "" || otp == null) {
        return { status: false, msg: 'Please enter the valid OTP', field: 'otp' };
    }
    return { status: true, msg: '' };

}

export const LoanUpdateValidation = (editedUserLoanDetail) => {
    if (editedUserLoanDetail.loanAmountState === "" || editedUserLoanDetail.loanAmountState === null) {
        return { status: false, msg: 'Please enter desired Loan Amount', field: "loanAmount" };
    } else if (editedUserLoanDetail.loanAmountState > 200000 || editedUserLoanDetail.loanAmountState < 5000) {
        return { status: false, msg: 'Loan Amount should be between 5000-200000', field: "loanAmount" };
    } else if (editedUserLoanDetail.loanAmountState % 1000 != 0 && (localStorage.getItem('uniqueId') === '' || localStorage.getItem('uniqueId') === 'null')) {
        return { status: false, msg: 'Please enter Amount in denomination of Thousands', field: "loanAmount" };
    }
    // if (editedUserLoanDetail.loanDaysState === "" || editedUserLoanDetail.loanDaysState === null) {
    //     return { status: false, msg: 'Please enter desired Loan Tenure', field: "loanDays" };
    // } else if (editedUserLoanDetail.loanDaysState > 60 || editedUserLoanDetail.loanDaysState < 7) {
    //     return { status: false, msg: 'Days should be between 7-60', field: "loanDays" };
    // }
    if (editedUserLoanDetail.loanReasonState === "" || editedUserLoanDetail.loanReasonState === null) {
        return { status: false, msg: 'Please enter the Loan Reason', field: "loanReason" };
    } else if (specialChar.test(editedUserLoanDetail.loanReasonState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'loanReason' };
    }
    return { status: true, msg: ' ' };
}

export const chngPasswordValidation = (roleDetail) => {
    if (roleDetail.passwordState.length < 8) {
        return { status: false, msg: 'Password length must be of 8-40', field: "newPassword" };
    } else if (roleDetail.passwordState.length > 40) {
        return { status: false, msg: 'Password length must be of 8-40', field: "newPassword" };
    }
    else if (!passCheck.test(roleDetail.passwordState)) {
        return { status: false, msg: 'Your password must contain at least one uppercase letter,one lowercase letter,one digit and one special Character(!@#$%&.)', field: "newPassword" };
    }
    return { status: true, msg: ' ' };
}

export const getSum = (array) => {
    let countTotal = 0;
    for (let i = 0; i < array.length; i++) {
        countTotal += Number(array[i]);
    }
    return countTotal;
}

export const creditScoreValidation = (creditDetail) => {
    if (creditDetail.CamorethanoneyearState > 0.5) {
        return { status: false, msg: 'Max value is 0.5', field: "Camorethanoneyear" };
    }
    if (creditDetail.AddressOwnState > 0.5) {
        return { status: false, msg: 'Max value is 0.5', field: "AddressOwn" };
    }
    if (creditDetail.CAsameAsPaState > 0.5) {
        return { status: false, msg: 'Max value is 0.5', field: "CAsameAsPa" };
    }
    if (creditDetail.CAsameAdhaarAddressState > 0.5) {
        return { status: false, msg: 'Max value is 0.5', field: "CAsameAdhaarAddress" };
    }
    if (creditDetail.EXPMorethanthreeyearState > 0.5) {
        return { status: false, msg: 'Max value is 0.5', field: "EXPMorethanthreeyear" };
    }
    if (creditDetail.CurrentAddressmorethanoneyearState > 0.5) {
        return { status: false, msg: 'Max value is 0.5', field: "CurrentAddressmorethanoneyear" };
    }
    if (creditDetail.companyCategoryState > 2) {
        return { status: false, msg: 'Max value is 2', field: "companyCategory" };
    }
    if (creditDetail.salaryOnTimeState > 1) {
        return { status: false, msg: 'Max value is 1', field: "salaryOnTime" };
    }
    if (creditDetail.EMIRatioState > 1) {
        return { status: false, msg: 'Max value is 1', field: "EMIRatio" };
    }
    if (creditDetail.existingLoanState > 1) {
        return { status: false, msg: 'Max value is 1', field: "existingLoan" };
    }
    if (creditDetail.defaultEvidentState > 1) {
        return { status: false, msg: 'Max value is 1', field: "defaultEvident" };
    }
    if (creditDetail.openingClosingBalanceState > 0.5) {
        return { status: false, msg: 'Max value is 0.5', field: "openingClosingBalance" };
    }
    if (creditDetail.highValueTransactionState > 0.5) {
        return { status: false, msg: 'Max value is 0.5', field: "highValueTransaction" };
    }
    return { status: true, msg: ' ' };
}
// export const MerchantFormValidation = (type, merchantData) => {
//     if (type == "addLocation") {
//         if (merchantData.location == "") {
//             return { status: false, msg: 'Location can not be blank', field: "highValueTransaction" };
//         }
//     } else if (type == "merchantAdd") {
//         if (merchantData.merchantName == "") {
//             return { status: false, msg: 'Merchant name can not be blank', field: "highValueTransaction" };
//         }
//         if (merchantData.merchantMobile == "") {
//             return { status: false, msg: 'Mobile number can not be blank', field: "highValueTransaction" };
//         }
//         if (merchantData.SelectMerchentStaus == "") {
//             return { status: false, msg: 'select merchant status', field: "highValueTransaction" };
//         }
//     } else if (type == "promoCategoryAdd") {
//         if (merchantData.promoCatName == "") {
//             return { status: false, msg: 'Promo catagory name can not be blank', field: "highValueTransaction" };
//         }
//         // if (merchantData.promoCatStatus == "") {
//         //     return { status: false, msg: 'select promo status', field: "highValueTransaction" };
//         // }
//     } else if (type == "subCategoryAdd") {
//         if (merchantData.subCatName == "") {
//             return { status: false, msg: 'sub category can not be blank', field: "highValueTransaction" };
//         }
//         //   if(merchantData.selectSubCatPromoCatName==""){
//         //         return { status: false, msg: 'select sub category promo ca', field: "highValueTransaction" };
//         //   }
//         // if (merchantData.selectSubCatStatus == "" || merchantData.selectSubCatStatus != true) {
//         //     return { status: false, msg: 'Select sub category status', field: "highValueTransaction" };
//         // }
//     } else if (type == "discountAdd") {
//         if (merchantData.selectDiscMerchantName == "") {
//             return { status: false, msg: 'select merchant name', field: "highValueTransaction" };
//         }
//         if (merchantData.selectDiscSubCatName == "") {
//             return { status: false, msg: 'Select sub Category name', field: "highValueTransaction" };
//         }
//         if (merchantData.selectDiscLocationName == "") {
//             return { status: false, msg: 'Select location ', field: "highValueTransaction" };
//         }
//         if (merchantData.discountTitle == "") {
//             return { status: false, msg: 'Discount title can not be blank', field: "highValueTransaction" };
//         }
//         if (merchantData.discountDescription == "") {
//             return { status: false, msg: 'Description can not be blank', field: "highValueTransaction" };
//         }
//         if (merchantData.selectDate == "") {
//             return { status: false, msg: 'Select date', field: "highValueTransaction" };
//         }
//         // if (merchantData.discountFirstAddress == "") {
//         //     return { status: false, msg: 'First address can not be blank', field: "highValueTransaction" };
//         // }
//         if (merchantData.discountAmount == "") {
//             return { status: false, msg: 'Discount amount can not be blank', field: "highValueTransaction" };
//         }
//         if (merchantData.promoMainCatName == "") {
//             return { status: false, msg: 'Select Category', field: "highValueTransaction" };
//         } if (merchantData.discountStatus == "") {
//             return { status: false, msg: 'Select Status', field: "highValueTransaction" };
//         }

//     }
//     return { status: true, msg: ' ' };
// }

export const merchantFormValidation = (merchantDetail) => {
    if (merchantDetail.outletNameState === "" || merchantDetail.outletNameState === null) {
        return { status: false, msg: 'Please enter Outlet Name', field: "outletNameState" };
    }
    if (merchantDetail.contactPersonNameState === "" || merchantDetail.contactPersonNameState === null) {
        return { status: false, msg: 'Please enter contact person Name', field: "contactPersonNameState" };
    }
    if (merchantDetail.contactPersonEmailState === "" || merchantDetail.contactPersonEmailState === null) {
        return { status: false, msg: 'Please  enter contact person Email Id', field: "contactPersonEmailState" };
    } else if (!filter.test(merchantDetail.contactPersonEmailState)) {
        return { status: false, msg: 'Please enter a valid Email', field: 'contactPersonEmailState' };
    }
    if (merchantDetail.contactNumberState === "" || merchantDetail.contactNumberState === null) {
        return { status: false, msg: 'Please  enter contact number', field: "contactNumberState" };
    } else if (merchantDetail.contactNumberState.length > 10 || merchantDetail.contactNumberState.length < 10) {
        return { status: false, msg: 'Please enter a valid 10 digit Mobile No.', field: 'contactNumberState' };
    }
    if (merchantDetail.companyWebsiteState === "" || merchantDetail.companyWebsiteState === null) {
        return { status: false, msg: 'Please  enter Company Website', field: "companyWebsiteState" };
    }
    if (merchantDetail.registeredAddressState === "" || merchantDetail.registeredAddressState === null) {
        return { status: false, msg: 'Please  enter Address', field: "registeredAddressState" };
    }
    // if (merchantDetail.userIdState === "" || merchantDetail.userIdState === null) {
    //     return { status: false, msg: 'Please  enter User Id', field: "userIdState" };
    // }
    if (merchantDetail.passwordState === "" || merchantDetail.passwordState === null) {
        return { status: false, msg: 'Please  enter Password', field: "passwordState" };
    }
    // if (merchantDetail.agreementState === "" || merchantDetail.agreementState === null || merchantDetail.agreementState === "No File Chosen") {
    //     return { status: false, msg: 'Please upload Agreement', field: "agreementState" };
    // }
    return { status: true, msg: ' ' };
}

export const applicantbasicFormValidation = (userDetail) => {
    if (userDetail.firstNameState === "" || userDetail.firstNameState === null) {
        return { status: false, msg: 'Please enter First Name', field: "firstName" };
    } else if (specialChar.test(userDetail.firstNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'firstName' };
    }
    if (specialChar.test(userDetail.MiddleNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'middleName' };
    }
    if (userDetail.lastNameState === "" || userDetail.lastNameState === null) {
        return { status: false, msg: 'Please enter Last Name', field: "lastName" };
    } else if (specialChar.test(userDetail.lastNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'lastName' };
    }
    if (userDetail.BasicDOB === "" || userDetail.BasicDOB === null) {
        return { status: false, msg: 'Please enter Date of Birth', field: "BasicDOB" };
    }
    if (moment(new Date()).diff(moment(userDetail.BasicDOB), 'year') <= 19) {
        return { status: false, msg: 'Minimum age should be 20 Years or above', field: "BasicDOB" };
    }
    if (userDetail.GenderState === "" || userDetail.GenderState === null) {
        return { status: false, msg: 'Please Select Gender', field: "Gender" };
    }
    // if (userDetail.HowToKnowState === "" || userDetail.HowToKnowState === null) {
    //     return { status: false, msg: 'Select how did you found about us.', field: "howToKnow" };
    // }
    if (userDetail.alternateMobileState == "" || userDetail.alternateMobileState == null) {
        return { status: false, msg: 'Please enter alternate Mobile No.', field: 'alternateMobile' };
    } else if (userDetail.alternateMobileState.length > 10 || userDetail.alternateMobileState.length < 10) {
        return { status: false, msg: 'Please enter a valid 10 digit Mobile No.', field: 'alternateMobile' };
    }
    // else if(userDetail.alternateMobileState == userDetail.mobileNumber){
    //     return { status: false, msg: 'This number already registered', field: 'alternateMobile' };
    // }
    if (userDetail.EmailIDState == "") {
        return { status: false, msg: 'Please enter your Email Id', field: 'emailId' };
    } else if (!filter.test(userDetail.EmailIDState)) {
        return { status: false, msg: 'Please enter a valid Email Id', field: 'emailId' };
    }
    return { status: true, msg: ' ' };
}

export const ApplicantResidenceFormValidation = (userDetail) => {
    if (userDetail.CurrentAddressState === "" || userDetail.CurrentAddressState === null) {
        return { status: false, msg: 'Please enter your Current Address', field: "CurrentAddress" };
    }
    if (userDetail.CurrentPinCodeState === "" || userDetail.CurrentPinCodeState === null) {
        return { status: false, msg: 'Please enter your Current PinCode', field: "CurrentPin" };
    } else if (userDetail.CurrentPinCodeState.length != 6) {
        return { status: false, msg: 'Please enter 6 Digit PinCode', field: "CurrentPin" };
    } else if (specialChar.test(userDetail.CurrentPinCodeState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'CurrentPin' };
    }
    if (userDetail.CurrentStateState === "" || userDetail.CurrentStateState === null) {
        return { status: false, msg: 'We are not Providing loan for this location', field: "CurrentPin" };
    }
    if (userDetail.CurrentResidenceTypeValue === "" || userDetail.CurrentResidenceTypeValue === null) {
        return { status: false, msg: 'Please select your Current Residence Type', field: "CurrentResidenceType" };
    }
    if (userDetail.CurrentResidenceTypeValue != "Own") {
        if (userDetail.CurrentDurationState === "" || userDetail.CurrentDurationState === null) {
            return { status: false, msg: 'Please Enter Current Residence Duration', field: "CurrentDuration" };
        }
    }

    if (userDetail.PermanentAddressState === "" || userDetail.PermanentAddressState === null) {
        return { status: false, msg: 'Please enter your Permanent Address', field: "PermanentAddress" };
    }
    if (userDetail.PermanentPinCodeState === "" || userDetail.PermanentPinCodeState === null) {
        return { status: false, msg: 'Please enter your Permanent PinCode', field: "PermanentPin" };
    } else if (userDetail.PermanentPinCodeState.length != 6) {
        return { status: false, msg: 'Please enter 6 Digit PinCode', field: "PermanentPin" };
    } else if (specialChar.test(userDetail.PermanentPinCodeState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'PermanentPin' };
    }
    if (userDetail.PermanentResidenceTypeValue === "" || userDetail.PermanentResidenceTypeValue === null) {
        return { status: false, msg: 'Please select your Permanent Residence Type', field: "PermanentResidenceType" };
    }
    if (userDetail.PermanentResidenceTypeValue != "Own") {
        if (userDetail.PermanentDurationState === "" || userDetail.PermanentDurationState === null) {
            return { status: false, msg: 'Please Enter Permanent Residential Duration', field: "PermanentDuration" };
        }
    }
    return { status: true, msg: ' ' };
}


export const applicantdocumentFormValidation = (userDetail, userData) => {
    if (userDetail.PhotoState === "" || userDetail.PhotoState === null || userDetail.PhotoState === "No File Chosen") {
        return { status: false, msg: 'Please upload your Photograph', field: "Photo" };
    }
    if (userDetail.IDProofState === "" || userDetail.IDProofState === null || userDetail.IDProofState === "No File Chosen") {
        return { status: false, msg: 'Please upload your Aadhaar card', field: "IDProof" };
    }
    if (userDetail.panProofState === "" || userDetail.panProofState === null || userDetail.panProofState === "No File Chosen") {
        return { status: false, msg: 'Please upload your Pan Card', field: "panProof" };
    }
    if (userDetail.BankStatementState === "" || userDetail.BankStatementState === null || userDetail.BankStatementState === "No File Chosen") {
        return { status: false, msg: 'Please upload the Bank Statements', field: "BankStatement" };
    }
    if (userData.employmentDetailsPO.employerType == EMPLOYER_TYPE.SALARY) {
        if (userDetail.SalarySlipState === "" || userDetail.SalarySlipState === null || userDetail.SalarySlipState === "No File Chosen") {
            return { status: false, msg: 'Please upload Salary slips', field: "SalarySlip" };
        }
    } else if (userData.employmentDetailsPO.employerType == EMPLOYER_TYPE.SELF_EMPLOYED) {
        if (userDetail.ITRProofState === "" || userDetail.ITRProofState === null || userDetail.ITRProofState === "No File Chosen") {
            return { status: false, msg: 'Please upload ITR', field: "itrProof" };
        }
    }
    return { status: true, msg: ' ' };
}

export const ApplicantBankFormValidation = (userDetail) => {
    if (userDetail.BankUserNameState === "" || userDetail.BankUserNameState === null) {
        return { status: false, msg: 'Please enter Name as per the Bank Account', field: "BankUserName" };
    } else if (specialChar.test(userDetail.BankUserNameState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'BankUserName' };
    }
    if (userDetail.AccountNoState === "" || userDetail.AccountNoState === null) {
        return { status: false, msg: 'Please enter your Bank Account Number', field: "AccountNo" };
    } else if (userDetail.AccountNoState.length > 25) {
        return { status: false, msg: 'Invalid Account Number', field: "AccountNo" };
    }
    if (userDetail.IFSCCodeState === "" || userDetail.IFSCCodeState === null) {
        return { status: false, msg: 'Please enter IFSC Code', field: "IFSCCode" };
    } else if (userDetail.IFSCCodeState.length < 11 || userDetail.IFSCCodeState.length > 11) {
        return { status: false, msg: 'Please enter 11 Digit IFSC Code', field: "IFSCCode" };
    }
    if (userDetail.IfSalriedAccountState === "" || userDetail.IfSalriedAccountState === null) {
        return { status: false, msg: 'Is this your Salary Account?', field: "IfSalriedAccount" };
    }
    if (userDetail.PanNoState === "" || userDetail.PanNoState === null) {
        return { status: false, msg: 'Please enter your PAN Number', field: "PanNo" };
    } else if (userDetail.PanNoState.length < 10 || userDetail.PanNoState.length > 10) {
        return { status: false, msg: 'Please enter valid 10 digit PAN Number', field: "PanNo" };
    } else if (!pattern.test(userDetail.PanNoState)) {
        return { status: false, msg: 'Please enter valid PAN Number(eg- ABCDE1234F)', field: "PanNo" };
    }
    if (userDetail.aadharNoState === "" || userDetail.aadharNoState === null) {
        return { status: false, msg: 'Please enter your Aadhaar Number', field: "aadharNo" };
    } else if (userDetail.aadharNoState.length > 12 || userDetail.aadharNoState.length < 12) {
        return { status: false, msg: 'Please enter valid 12 digit Aadhaar No', field: "aadharNo" };
    }

    return { status: true, msg: ' ' };
}

export const applicantoccupationFormValidation = (userDetail) => {
    if (userDetail.HighQualificationState === "" || userDetail.HighQualificationState === null) {
        return { status: false, msg: 'Please select your Highest Qualification', field: "HighQualification" };
    }
    if (userDetail.fullPartTimeState === "" || userDetail.fullPartTimeState === null) {
        return { status: false, msg: 'Please select your Job Type', field: "fullPartTime" };
    }
    if (userDetail.EmployeeTypeValue === "" || userDetail.EmployeeTypeValue === null) {
        return { status: false, msg: 'Please select your Employee Type', field: "EmployeeTypeValue" };
    }
    if (userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SALARY) {
        if (userDetail.OrganizationNameState === "" || userDetail.OrganizationNameState === null) {
            return { status: false, msg: 'Please enter your Organization\'s Name', field: "OrganizationName" };
        } else if (specialChar.test(userDetail.OrganizationNameState)) {
            return { status: false, msg: 'Special characters not Allowed', field: 'OrganizationName' };
        }

        // if (userDetail.designationState === "" || userDetail.designationState === null) {
        //     return { status: false, msg: 'Please enter your Designation', field: "designation" };
        // } else if (specialChar.test(userDetail.designationState)) {
        //     return { status: false, msg: 'Special characters not Allowed', field: 'designation' };
        // }
        if (userDetail.HomeSalaryState === "" || userDetail.HomeSalaryState === null) {
            return { status: false, msg: 'Please enter your current Salary', field: "HomeSalary" };
        } else if (userDetail.HomeSalaryState <= 20000) {
            return { status: false, msg: 'Minimum Salary should be 20000', field: "HomeSalary" };
        }
        if (userDetail.WorkExperienceCurrentState === "" || userDetail.WorkExperienceCurrentState === null) {
            return { status: false, msg: 'Please select your Work Experience(in current organization)', field: "WorkExperienceCurrent" };
        }
        if (userDetail.IsFirstJobState === "" || userDetail.IsFirstJobState === null) {
            return { status: false, msg: 'Is This Your First Job?', field: "IsFirstJob" };
        }
        if (userDetail.IsFirstJobState == STRING.NO) {
            if (userDetail.WorkExperiencePreviousState === "" || userDetail.WorkExperiencePreviousState === null) {
                return { status: false, msg: 'Please select your Work Experience(in previous organization)', field: "PreviousWork" };
            }
            if (userDetail.WorkExperienceTotalState === "" || userDetail.WorkExperienceTotalState === null) {
                return { status: false, msg: 'Please enter your Total Experience', field: "WorkExperienceTotal" };
            }
        }
    } else if (userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SELF_EMPLOYED) {
        if (localStorage.getItem('uniqueId') === '' || localStorage.getItem('uniqueId') === 'null') {
            if (userDetail.EmployeeTypeState === "" || userDetail.EmployeeTypeState === null) {
                return { status: false, msg: 'Please enter Self Employee Type', field: "SelfEmployeeType" };
            }
            if (userDetail.IndustrialTypeState === "" || userDetail.IndustrialTypeState === null) {
                return { status: false, msg: 'Please enter Industry Type', field: "IndustrialType" };
            }
            if (userDetail.IndustrialTypeState == STRING.OTHERS) {
                if (userDetail.otherIndustrialTypeState === "" || userDetail.otherIndustrialTypeState === null) {
                    return { status: false, msg: 'Please enter Other Industry Type', field: "IndustrialType" };
                } else if (specialChar.test(userDetail.otherIndustrialTypeState)) {
                    return { status: false, msg: 'Special characters not Allowed', field: 'IndustrialType' };
                }
            }
        }
        if (userDetail.MonthlyIncomeState === "" || userDetail.MonthlyIncomeState === null) {
            return { status: false, msg: 'Please enter Monthly Income', field: "MonthlyIncome" };
        } else if (userDetail.MonthlyIncomeState <= 20000) {
            return { status: false, msg: 'Minimum Income is 20000', field: "MonthlyIncome" };
        }
    }
    return { status: true, msg: ' ' };
}


export const ApplicantLoanFormValidation = (userDetail) => {
    if (userDetail.loanAmountState === "" || userDetail.loanAmountState === null) {
        return { status: false, msg: 'Please enter desired Loan Amount', field: "loanAmount" };
    } else if (userDetail.loanAmountState > 200000 || userDetail.loanAmountState < 5000) {
        return { status: false, msg: 'Loan Amount should be between 5000-200000', field: "loanAmount" };
    } else if (userDetail.loanAmountState % 1000 != 0 && (localStorage.getItem('uniqueId') === '' || localStorage.getItem('uniqueId') === 'null')) {
        return { status: false, msg: 'Please enter Amount in denomination of Thousands', field: "loanAmount" };
    }
    // if (userDetail.loanDaysState === "" || userDetail.loanDaysState === null) {
    //     return { status: false, msg: 'Please enter desired Loan Tenure', field: "loanDays" };
    // } else if (userDetail.loanDaysState > 60 || userDetail.loanDaysState < 7) {
    //     return { status: false, msg: 'Days should be between 7-60', field: "loanDays" };
    // }
    // if (userDetail.PanNoState === "" || userDetail.PanNoState === null) {
    //     return { status: false, msg: 'Please enter your PAN Number', field: "PanNo" };
    // } else if (userDetail.PanNoState.length < 10 || userDetail.PanNoState.length > 10) {
    //     return { status: false, msg: 'Please enter valid 10 digit PAN Number', field: "PanNo" };
    // } else if (!pattern.test(userDetail.PanNoState)) {
    //     return { status: false, msg: 'Please enter valid PAN Number(eg- ABCDE1234F)', field: "PanNo" };
    // }
    // if (userDetail.aadharNoState === "" || userDetail.aadharNoState === null) {
    //     return { status: false, msg: 'Please enter your Aadhaar Number', field: "aadharNo" };
    // } else if (userDetail.aadharNoState.length > 12 || userDetail.aadharNoState.length < 12) {
    //     return { status: false, msg: 'Please enter valid 12 digit Aadhaar No', field: "aadharNo" };
    // }
    if (userDetail.loanReasonState === "" || userDetail.loanReasonState === null) {
        return { status: false, msg: 'Please enter the Loan Reason', field: "loanReason" };
    } else if (specialChar.test(userDetail.loanReasonState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'loanReason' };
    }
    return { status: true, msg: ' ' };
}


export const basicFormValidationCards = (userDetail) => {
    if (userDetail.firstName === "" || userDetail.firstName === null) {
        return { status: false, msg: 'Please enter First Name', field: "firstName" };
    }
    else if (specialChar.test(userDetail.firstName)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'firstName' };
    }
    if (specialChar.test(userDetail.lastName)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'lastName' };
    }
    if (userDetail.lastName === "" || userDetail.lastName === null) {
        return { status: false, msg: 'Please enter Last Name', field: "lastName" };
    } else if (specialChar.test(userDetail.lastName)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'lastName' };
    }
    if (userDetail.gender === "" || userDetail.gender === null) {
        return { status: false, msg: 'Please Select Gender', field: "gender" };
    }
    if (userDetail.mobileNumber == "" || userDetail.mobileNumber == null) {
        return { status: false, msg: 'Please enter  Mobile No.', field: 'mobileNumber' };
    } else if (userDetail.mobileNumber.length > 10 || userDetail.mobileNumber.length < 10) {
        return { status: false, msg: 'Please enter a valid 10 digit Mobile No.', field: 'mobileNumber' };
    }
    if (userDetail.dateOfBirth === "" || userDetail.dateOfBirth === null) {
        return { status: false, msg: 'Please enter Date of Birth', field: "dateOfBirth" };
    }
    // if (moment(new Date()).diff(moment(userDetail.dateOfBirth), 'year') <= 19) {
    //     return { status: false, msg: 'Minimum age should be 20 Years or above', field: "dateOfBirth" };
    // }

    if (userDetail.panNo === "" || userDetail.panNo === null) {
        return { status: false, msg: 'Please enter your PAN Number', field: "PanNo" };
    } else if (userDetail.panNo.length < 10 || userDetail.panNo.length > 10) {
        return { status: false, msg: 'Please enter valid 10 digit PAN Number', field: "PanNo" };
    } else if (!pattern.test(userDetail.panNo)) {
        return { status: false, msg: 'Please enter valid PAN Number(eg- ABCDE1234F)', field: "PanNo" };
    }
    if (userDetail.aadhaarNo === "" || userDetail.aadhaarNo === null) {
        return { status: false, msg: 'Please enter your Aadhaar Number', field: "aadharNo" };
    } else if (userDetail.aadhaarNo.length > 12 || userDetail.aadhaarNo.length < 12) {
        return { status: false, msg: 'Please enter valid 12 digit Aadhaar No', field: "aadharNo" };
    }
    if (userDetail.email == "") {
        return { status: false, msg: 'Please enter your Email Id', field: 'email' };
    } else if (!filter.test(userDetail.email)) {
        return { status: false, msg: 'Please enter a valid Email Id', field: 'email' };
    }

    return { status: true, msg: ' ' };
}
export const addressFormValidationCards = (userDetail) => {
    if (userDetail.address1 === "" || userDetail.address1 === null) {
        return { status: false, msg: 'Please enter address1', field: "address1" };
    }
    if (userDetail.address2 === "" || userDetail.address2 === null) {
        return { status: false, msg: 'Please enter address2', field: "address2" };
    }
    if (userDetail.address3 === "" || userDetail.address3 === null) {
        return { status: false, msg: 'Please enter address3', field: "address3" };
    }
    if (userDetail.city === "" || userDetail.city === null) {
        return { status: false, msg: 'Please Enter City', field: "city" };
    }
    if (userDetail.state === "" || userDetail.state === null) {
        return { status: false, msg: 'Please Enter State', field: "state" };
    }
    if (userDetail.pinCode === "" || userDetail.pinCode === null) {
        return { status: false, msg: 'Please Enter PinCode', field: "pinCode" };
    }
    ///deliveryaddress
    if (userDetail.deliveryAddress1 === "" || userDetail.deliveryAddress1 === null) {
        return { status: false, msg: 'Please enter deliveryAddress 1', field: "deliveryAddress1" };
    }
    if (userDetail.deliveryAddress2 === "" || userDetail.deliveryAddress2 === null) {
        return { status: false, msg: 'Please enter deliveryAddress 2', field: "deliveryAddress2" };
    }
    if (userDetail.deliveryAddress3 === "" || userDetail.deliveryAddress3 === null) {
        return { status: false, msg: 'Please enter deliveryAddress 3', field: "deliveryAddress3" };
    }
    if (userDetail.deliveryCity === "" || userDetail.deliveryCity === null) {
        return { status: false, msg: 'Please Enter City', field: "deliveryCity" };
    }
    if (userDetail.deliveryState === "" || userDetail.deliveryState === null) {
        return { status: false, msg: 'Please Enter State', field: "deliveryState" };
    }
    if (userDetail.deliveryPinCode === "" || userDetail.deliveryPinCode === null) {
        return { status: false, msg: 'Please Enter PinCode', field: "deliveryPinCode" };
    }
    return { status: true, msg: ' ' };
}
export const bankFormValidationCards = (userDetail) => {
    if (userDetail.accountNo === "" || userDetail.accountNo === null) {
        return { status: false, msg: 'Please enter account Number', field: "accountNo" };
    }

    if (userDetail.ifscCode === "" || userDetail.ifscCode === null) {
        return { status: false, msg: 'Please enter IFSC Code', field: "ifscCode" };
    } else if (!IfscCheck.test(userDetail.ifscCode)) {
        return { status: false, msg: 'Please enter a valid IFSC Code', field: 'ifscCode' };
    }
    if (userDetail.nameOnBank === "" || userDetail.nameOnBank === null) {
        return { status: false, msg: 'Please Enter Name On Bank', field: "nameOnBank" };
    }




    return { status: true, msg: ' ' };
}
export const staffLoanValidation = (userDetail) => {
    if (userDetail.loanReason === "" || userDetail.loanReason === null) {
        return { status: false, msg: 'Please Select loan reason', field: "loanReason" };
    }
    if (userDetail.loanEMI === "" || userDetail.loanEMI === null) {
        return { status: false, msg: 'Please Select Emi', field: "loanEMI" };
    }
    if (userDetail.loanAmount === "" || userDetail.loanAmount === null || userDetail.loanAmount === 0) {
        return { status: false, msg: 'Please Enter Amount', field: "loanAmount" };
    }

    return { status: true, msg: ' ' };
}
export const staffGiftValidation = (userDetail) => {
    //     console.log(userDetail)
    if (userDetail.giftCardType === "" || userDetail.giftCardType === null || userDetail.giftCardType === undefined) {
        return { status: false, msg: 'Please Select giftcard Type', field: "giftCardType" };
    }
    if (userDetail.tenure === "" || userDetail.tenure === null || userDetail.tenure === undefined) {
        return { status: false, msg: 'Please Select Emi', field: "loanEMI" };
    }
    if (userDetail.loanAmount === "" || userDetail.loanAmount === null || Number(userDetail.loanAmount) === 0 || userDetail.loanAmount === undefined) {
        return { status: false, msg: 'Please Enter Amount', field: "loanAmount" };
    }

    return { status: true, msg: ' ' };
}
export const dbrValidation = (userDetail) => {
    //     console.log(userDetail)
    if (userDetail.stability === "" || userDetail.stability === null || userDetail.stability === undefined) {
        return { status: false, msg: 'Please enter stability', field: "stability" };
    }
    if (userDetail.overDueMsgsTrend === "" || userDetail.overDueMsgsTrend === null || userDetail.overDueMsgsTrend === undefined) {
        return { status: false, msg: 'Please enter overdue messages trend', field: "overDueMsgsTrend" };
    }
    if (userDetail.bureau === "" || userDetail.bureau === null || userDetail.bureau === undefined) {
        return { status: false, msg: 'Please enter bureau', field: "bureau" };
    }
    if (userDetail.residence === "" || userDetail.residence === null || userDetail.residence === undefined) {
        return { status: false, msg: 'Please enter residence', field: "residence" };
    }
    if (userDetail.debts === "" || userDetail.debts === null || userDetail.debts === undefined) {
        return { status: false, msg: 'Please enter debts', field: "debts" };
    }
    if (userDetail.ntc === "" || userDetail.ntc === null || userDetail.ntc === undefined) {
        return { status: false, msg: 'Please enter NTC', field: "ntc" };
    }
    if (userDetail.corporate === "" || userDetail.corporate === null || userDetail.corporate === undefined) {
        return { status: false, msg: 'Please enter Corporate', field: "corporate" };
    }
    if (userDetail.access === "" || userDetail.access === null || userDetail.access === undefined) {
        return { status: false, msg: 'Please enter Access', field: "access" };
    }
    if (userDetail.multiplePayday === "" || userDetail.multiplePayday === null || userDetail.multiplePayday === undefined) {
        return { status: false, msg: 'Please enter Multiple Payday', field: "multiplePayday" };
    }
    if (userDetail.kyc === "" || userDetail.kyc === null || userDetail.kyc === undefined) {
        return { status: false, msg: 'Please enter KYC', field: "kyc" };
    }

    return { status: true, msg: ' ' };
}

export const otherTeaGardenValidation = (userDetail, teaGardenName) => {
    //     console.log(userDetail)
    if (teaGardenName === "" || teaGardenName === null || teaGardenName === undefined) {
        return { status: false, msg: 'Please enter Teagarden Name', field: "teaGardenName" };
    }
    if (userDetail.panNumber === "" || userDetail.panNumber === null) {
        return { status: false, msg: 'Please enter your PAN Number', field: "panNumber" };
    } else if (userDetail.panNumber.length !== 10) {
        return { status: false, msg: 'Please enter valid 10 digit PAN Number', field: "panNumber" };
    } else if (!pattern.test(userDetail.panNumber)) {
        return { status: false, msg: 'Please enter valid PAN Number(eg- ABCDE1234F)', field: "panNumber" };
    }
    if (userDetail.aadharNumber === "" || userDetail.aadharNumber === null || userDetail.aadharNumber === undefined) {
        return { status: false, msg: 'Please enter aadhar Number', field: "aadharNumber" };
    }
    if (userDetail.aadharNumber.length !== 12) {
        return { status: false, msg: 'Please enter valid aadhar Number', field: "aadharNumber" };
    }


    return { status: true, msg: ' ' };
}

export const BankUpdateValidationIframe = (userDetail) => {
    if (userDetail.nameAsBankAccount === "" || userDetail.nameAsBankAccount === null) {
        return { status: false, msg: 'Please enter Name as per the Bank Account', field: "nameAsBankAccount" };
    } else if (specialChar.test(userDetail.nameAsBankAccount)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'nameAsBankAccount' };
    }
    if (userDetail.accountNumber === "" || userDetail.accountNumber === null) {
        return { status: false, msg: 'Please enter your Bank Account Number', field: "AccountNo" };
    } else if (userDetail.accountNumber.length > 25) {
        return { status: false, msg: 'Invalid Account Number', field: "AccountNo" };
    }
    if (userDetail.ifscCode === "" || userDetail.ifscCode === null) {
        return { status: false, msg: 'Please enter IFSC Code', field: "IFSCCode" };
    } else if (userDetail.ifscCode.length < 11 || userDetail.ifscCode.length > 11) {
        return { status: false, msg: 'Please enter 11 Digit IFSC Code', field: "IFSCCode" };
    }


    return { status: true, msg: ' ' };
}
export const nbfcValidation = (nbfcData) => {
    if (nbfcData.name === '') {
        return { status: false, msg: 'Please enter Name', field: 'name' };
    }
    if (nbfcData.maxDays === '') {
        return { status: false, msg: 'Please enter Max Days', field: 'maxDays' };
    }
    if (nbfcData.maxMonth === '') {
        return { status: false, msg: 'Please enter Max Month', field: 'maxMonth' };
    }
    if (nbfcData.interestRate === '') {
        return { status: false, msg: 'Please enter Interest Rate', field: 'interestRate' };
    }
    if (nbfcData.apiKey === '') {
        return { status: false, msg: 'Please enter apiKey', field: 'apiKey' };
    }
    if (nbfcData.dynamicEmi === '') {
        return { status: false, msg: 'Please enter Dynamic Emi', field: 'dynamicEmi' };
    }
    if (nbfcData.contactPersonName === '') {
        return { status: false, msg: 'Please enter Contact Person Name', field: 'contactPersonName' };
    }
    if (nbfcData.contactPersonNumber === '') {
        return { status: false, msg: 'Please enter Contact Person Number', field: 'contactPersonNumber' };
    }
    if (nbfcData.city === '') {
        return { status: false, msg: 'Please enter Dynamic City', field: 'city' };
    }
    if (nbfcData.pinCode === '') {
        return { status: false, msg: 'Please enter Dynamic Pincode', field: 'pinCode' };
    }
    if (nbfcData.state === '') {
        return { status: false, msg: 'Please enter State', field: 'state' };
    }
    if (nbfcData.address === '') {
        return { status: false, msg: 'Please enter Address', field: 'address' };
    }

    return { status: true, msg: '' };
}


export const nbfcProductConfigurationValidation = (nbfcData) => {
    if (nbfcData.roiReflectDate === '') {
        return { status: false, msg: 'Please Select ROI Reflect Date', field: 'roiReflectDate' };
    }
    if (nbfcData.previouslyAffect === '') {
        return { status: false, msg: 'Please Select is Previously Affect', field: 'previouslyAffect' };
    }
    if (nbfcData.stampDutyCharge === '') {
        return { status: false, msg: 'Please enter Stamp Duty Charge', field: 'stampDutyCharge' };
    }
    if (nbfcData.penaltyInterest === '') {
        return { status: false, msg: 'Please enter Penalty Interest ', field: 'penaltyInterest' };
    }
    if (nbfcData.penaltyAmount === '') {
        return { status: false, msg: 'Please enter Penalty Amount', field: 'penaltyAmount' };
    }
    if (nbfcData.normalInterest === '') {
        return { status: false, msg: 'Please enter Normal Interest', field: 'normalInterest' };
    }
    if (nbfcData.emiPenaltyInterest === '') {
        return { status: false, msg: 'Please enter EMI Penalty Interest', field: 'emiPenaltyInterest' };
    }
    if (nbfcData.gstInterest === '') {
        return { status: false, msg: 'Please enter GST Interest', field: 'gstInterest' };
    }
    if (nbfcData.chequeBounceCharge === '') {
        return { status: false, msg: 'Please enter Cheque Bounce Charge', field: 'chequeBounceCharge' };
    }
    if (nbfcData.mandateBounceCharge === '') {
        return { status: false, msg: 'Please enter Mandate Bounce Charge', field: 'mandateBounceCharge' };
    }
    if (nbfcData.interestType === '') {
        return { status: false, msg: 'Please Select Interest Type', field: 'interestType' };
    }
    if (nbfcData.gstIncluded === '') {
        return { status: false, msg: 'Please Select is GST Included', field: 'gstIncluded' };
    }


    return { status: true, msg: '' };
}

export const nbfcChargesConfigurationValidation = (nbfcData) => {
    if (nbfcData.processingFee === '') {
        return { status: false, msg: 'Please enter Processing Fee', field: 'processingFee' };
    }
    if (nbfcData.minLoanAmount === '') {
        return { status: false, msg: 'Please enter Min Loan Amount', field: 'minLoanAmount' };
    }
    if (nbfcData.maxLoanAmount === '') {
        return { status: false, msg: 'Please enter Max Loan Amount', field: 'maxLoanAmount' };
    }
    if (nbfcData.effectingFrom === '') {
        return { status: false, msg: 'Please enter Effecting From ', field: 'effectingFrom' };
    }
    if (nbfcData.noOfEmi === '') {
        return { status: false, msg: 'Please enter number of emis', field: 'noOfEmi' };
    }
    if (nbfcData.processingType === '') {
        return { status: false, msg: 'Please select Processing Type', field: 'processingType' };
    }
    if (nbfcData.oldEffected === '') {
        return { status: false, msg: 'Please select Old Effected', field: 'oldEffected' };
    }

    if (nbfcData.gstIncluded === '') {
        return { status: false, msg: 'Please Select is GST Included', field: 'gstIncluded' };
    }


    return { status: true, msg: '' };
}
export const nbfcEsignConfigurationValidation = (nbfcData) => {
    if (nbfcData.eSignRequestUrl === '') {
        return { status: false, msg: 'Please enter E-Sign Request Url', field: 'eSignRequestUrl' };
    }
    if (nbfcData.widgetTokenUrl === '') {
        return { status: false, msg: 'Please enter Widget Token Url', field: 'widgetTokenUrl' };
    }
    if (nbfcData.docketInfoUrl === '') {
        return { status: false, msg: 'Please enter Docket Info Url', field: 'docketInfoUrl' };
    }
    if (nbfcData.signatureStatusUrl === '') {
        return { status: false, msg: 'Please enter signature Status Url', field: 'signatureStatusUrl' };
    }
    if (nbfcData.apiKey === '') {
        return { status: false, msg: 'Please enter apiKey', field: 'apiKey' };
    }

    if (nbfcData.appId === '') {
        return { status: false, msg: 'Please select App Id', field: 'appId' };
    }

    if (nbfcData.status === '') {
        return { status: false, msg: 'Please Select is GST Included', field: 'status' };
    }


    return { status: true, msg: '' };
}
export const nbfcEnachConfigurationValidation = (nbfcData) => {

    if (nbfcData.apiKey === '') {
        return { status: false, msg: 'Please enter apiKey', field: 'apiKey' };
    }

    if (nbfcData.apiSecret === '') {
        return { status: false, msg: 'Please enter Api Secret', field: 'apiSecret' };
    }


    return { status: true, msg: '' };
}
export const ReferenceValidationAdminNew = (referenceDetails, userData) => {
    if (referenceDetails.name1 === '') {
        return { status: false, msg: 'Please enter First Name', field: 'name1' };
    }
    else if (referenceDetails.number1.length > 10 || referenceDetails.number1.length < 10) {
        return { status: false, msg: 'Please enter First valid 10 digit Mobile No.', field: 'number1' };
    }

    let number = JSON.stringify(referenceDetails.number1)
    if (number.includes('.') || number.includes('-') || number.includes('+')) {
        return { status: false, msg: 'Special Characters Not Allowed !', field: 'number1' };
    }

    else if (referenceDetails.relationShip1 === '') {
        return { status: false, msg: 'Please Select First  Relationship', field: 'relationShip1' };
    }
    else if (referenceDetails.name2 === '') {
        return { status: false, msg: 'Please enter Second Name', field: 'name2' };
    }


    else if (referenceDetails.number2.length > 10 || referenceDetails.number2.length < 10) {
        return { status: false, msg: 'Please enter Second valid 10 digit Mobile No.', field: 'number2' };
    }
    let number2 = JSON.stringify(referenceDetails.number2)
    if (number2.includes('.') || number2.includes('-') || number2.includes('+')) {
        return { status: false, msg: 'Special Characters Not Allowed !', field: 'number2' };
    }

    else if (referenceDetails.relationShip2 === '') {
        return { status: false, msg: 'Please Select Second Relationship', field: 'relationShip2' };
    }
    else if (referenceDetails.name3 === '') {
        return { status: false, msg: 'Please enter Third Name', field: 'name3' };
    }
    else if (referenceDetails.number3.length > 10 || referenceDetails.number3.length < 10) {
        return { status: false, msg: 'Please enter Third valid 10 digit Mobile No.', field: 'number3' };
    }
    let number3 = JSON.stringify(referenceDetails.number3)
    if (number3.includes('.') || number3.includes('-') || number3.includes('+')) {
        return { status: false, msg: 'Special Characters Not Allowed !', field: 'number3' };
    }
    else if (referenceDetails.relationShip3 === '') {
        return { status: false, msg: 'Please Select Third  Relationship', field: 'relationShip3' };
    }
    if (referenceDetails.name1 !== '' && referenceDetails.name2 !== '' && referenceDetails.name3 !== '') {
        if (referenceDetails.name1 === referenceDetails.name2) {
            return { status: false, msg: 'Please enter Unique Name', field: 'name3' };
        }
        if (referenceDetails.name2 === referenceDetails.name3) {
            return { status: false, msg: 'Please enter Unique Name', field: 'name3' };
        }
        if (referenceDetails.name3 === referenceDetails.name1) {
            return { status: false, msg: 'Please enter Unique Name', field: 'name3' };
        }
        if (referenceDetails.name1 == referenceDetails.name3) {
            return { status: false, msg: 'Please enter Unique name', field: 'name3' };
        }
        if (referenceDetails.name2 == referenceDetails.name1) {
            return { status: false, msg: 'Please enter Unique name', field: 'name3' };
        }
        if (referenceDetails.name3 == referenceDetails.name1) {
            return { status: false, msg: 'Please enter Unique name', field: 'name3' };
        }
    }
    if (referenceDetails.number1 !== '' && referenceDetails.number2 !== '' && referenceDetails.number3 !== '') {
        if (referenceDetails.number1 == referenceDetails.number2) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number2 == referenceDetails.number3) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number3 == referenceDetails.number2) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }

        if (referenceDetails.number1 == referenceDetails.number3) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number2 == referenceDetails.number1) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number3 == referenceDetails.number1) {
            return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        }
        if (referenceDetails.number3 == userData.mobileNumber || referenceDetails.number3 == userData.alternativeNo) {
            return { status: false, msg: 'Your registered number or alternate number is not allowed', field: 'number3' };
        }
        if (referenceDetails.number2 == userData.mobileNumber || referenceDetails.number2 == userData.alternativeNo) {
            return { status: false, msg: 'Your registered number or alternate number is not allowed', field: 'number2' };
        }
        if (referenceDetails.number1 == userData.mobileNumber || referenceDetails.number1 == userData.alternativeNo) {
            return { status: false, msg: 'Your registered number or alternate number is not allowed', field: 'number1' };
        }


        // if (referenceDetails.number1 === referenceDetails.number2 || referenceDetails.number2 === referenceDetails.number1 || referenceDetails.number3 === referenceDetails.number1) {
        //     return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        // }
        // if (referenceDetails.number2 === referenceDetails.number3 || referenceDetails.number3 === referenceDetails.number2|| referenceDetails.number2 === referenceDetails.number1) {
        //     return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        // }
        // if (referenceDetails.number3 === referenceDetails.number1 || referenceDetails.number2 === referenceDetails.number3|| referenceDetails.number1 === referenceDetails.number3) {
        //     return { status: false, msg: 'Please enter Unique number', field: 'number3' };
        // }
    }
    return { status: true, msg: '' };
}
export const doctorProductConfigValidation = (userData) => {
    // if (userData.nbfcId === '' || userData.nbfcId === null || userData.nbfcId === undefined) {
    //     return { status: false, msg: 'Please Select Nbfc', field: 'nbfcId' };
    // }
    if (userData.productName === '' || userData.productName === null || userData.productName === undefined) {
        return { status: false, msg: 'Please enter Product Name', field: 'productName' };
    }
    if (userData.totalEmi === '' || userData.totalEmi === null || userData.totalEmi === undefined) {
        return { status: false, msg: 'Please enter total emi', field: 'totalEmi' };
    }

    if (userData.advanceEmi === '' || userData.advanceEmi === null || userData.advanceEmi === undefined) {
        return { status: false, msg: 'Please enter advance Emi', field: 'advanceEmi' };
    }
    if (userData.processingFesIncludingGSTINR === '' || userData.processingFesIncludingGSTINR === null || userData.processingFesIncludingGSTINR === undefined) {
        return { status: false, msg: 'Please enter processing fees including GST INR', field: 'processingFesIncludingGSTINR' };
    }
    if (userData.processingFesIncludingGSTRate === '' || userData.processingFesIncludingGSTRate === null || userData.processingFesIncludingGSTRate === undefined) {
        return { status: false, msg: 'Please enter processing fees including GST Rate', field: 'processingFesIncludingGSTRate' };
    }
    if (userData.subventionRate === '' || userData.subventionRate === null || userData.subventionRate === undefined) {
        return { status: false, msg: 'Please enter subvention Rate', field: 'subventionRate' };
    }

    if (userData.interest === '' || userData.interest === null || userData.interest === undefined) {
        return { status: false, msg: 'Please enter interest', field: 'interest' };
    }
    if (userData.nbfcTakePercent === '' || userData.nbfcTakePercent === null || userData.nbfcTakePercent === undefined) {
        return { status: false, msg: 'Please enter Nbfc Take At Rate', field: 'nbfcTakePercent' };
    }







    return { status: true, msg: '' };
}