import React from 'react'

const ProductConfigUi = ({ changeHandler, submit, productConfig, validationMsg, editHadler }) => (
    <div className='container'>

        <h3 className='text-center' style={{ marginTop: '20px', marginBottom: '20px' }}><b>Charges Configuration</b></h3>
        <div className='row'>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Processing Fee</label>
                <input type="number" placeholder='Processing Fee' value={productConfig.processingFee} className="form-control" onChange={e => changeHandler(e, 'processingFee')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'processingFee' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Min Loan Amount</label>
                <input type="number" placeholder='Min Loan Amount' value={productConfig.minLoanAmount} className="form-control" onChange={e => changeHandler(e, 'minLoanAmount')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'minLoanAmount' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Max Loan Amount</label>
                <input type="text" placeholder='Max Loan Amount' value={productConfig.maxLoanAmount} className="form-control" onChange={e => changeHandler(e, 'maxLoanAmount')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'maxLoanAmount' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Effecting From</label>
                <input type="date" placeholder='Effecting From' value={productConfig.effectingFrom} className="form-control" onChange={e => changeHandler(e, 'effectingFrom')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'effectingFrom' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
        </div>
        <div className='row'>
        <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">No. of EMI</label>
                <input type="number" placeholder='Number Of Emi' value={productConfig.noOfEmi} className="form-control" onChange={e => changeHandler(e, 'noOfEmi')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'noOfEmi' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Processing Type</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'processingType')} value={productConfig.processingType}>
                    <option value=''>Select Value</option>
                    <option value='fixed'>Fixed</option>
                    <option value='percentage'>Percentage</option>

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'processingType' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>

            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Old Effected</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'oldEffected')} value={productConfig.oldEffected}>
                    <option value=''>Select Value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'oldEffected' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Is GST Included?</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'gstIncluded')} value={productConfig.gstIncluded}>
                    <option value=''>Select Value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'gstIncluded' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
        </div>

        <div className='row text-center'>
            <button className='profile-save-btn' onClick={() => submit()}>Save</button>
        </div>


    </div>
)

export default ProductConfigUi