
import React from 'react'
import newApplication from '../../../../images/rewampimages/Frame 13.png';
import repeatApplication from '../../../../images/rewampimages/Frame 13 (1).png';
import assignedtoCS from '../../../../images/rewampimages/Frame 13 (2).png';
import assignedtoCF from '../../../../images/rewampimages/Frame 13 (3).png';
import assignedtooperation from '../../../../images/rewampimages/Frame 13 (4).png';
import analysis_chart from '../../../../images/rewampimages/analysis-chart.png';
import Ops from '../../../../images/rewampimages/Vector (26).png';
import ReactApexChart from 'react-apexcharts';



const DashboardUi = ({ chartOptions, mainId, tabledata, emailIdToShow, downloadBydepartmentFunction, datadownload, departmentToSend, admin, todaysApplicationNo, userStatusData, performanceData, leadDashFilterData, filterDataChange, filterDataHandler, performanceLoader,
    stateWiseData, agentWiseData, statusWiseData, errorMsg, todayData, agentStatusWiseData, graphState, statusWiseReportingHandler, ReportViewState }) => (
    <>
        <div className='container-fluid lm-dashboard'>
            <div className='row' style={{ marginLeft: '-7px' }}>
                <div className='col-sm-2 col-xs-2'>
                    <label className="font-12px">From</label>
                    <input onChange={(e) => filterDataChange(e, "startDate")} value={leadDashFilterData.startDateToShow} className='date-input-lm' type='date' style={{ background: '#fff' }} />
                </div>
                <div className='col-sm-2 col-xs-2'>
                    <label className="font-12px"> To</label>

                    <input onChange={(e) => filterDataChange(e, "endDate")} value={leadDashFilterData.endDateToShow} className='date-input-lm' type='date' style={{ background: '#fff' }} />
                </div>
                <div className="col-xs-12 col-sm-1">
                    <button className="reset-btn" style={{ padding: '10px', marginTop: '22px' }} onClick={e => filterDataHandler()}>
                        Filter
                    </button>
                </div>
            </div>
            {todayData ?
                <ul className='lm-card-main lm-card'>
                    <li><h5>New Application <img src={newApplication} width={'35px'} style={{ float: 'right', marginTop: '-10px' }} /></h5>
                        <h2><b>{todaysApplicationNo.newUserCount}</b></h2>
                    </li>
                    <li><h5>Repeat Application <img src={repeatApplication} width={'35px'} style={{ float: 'right', marginTop: '-10px' }} /></h5>
                        <h2><b>{todaysApplicationNo.repeatUserCount}</b></h2>
                    </li>
                    <li><h5>Assigned to<br />
                        Credit/Finance  <img src={assignedtoCS} width={'35px'} style={{ float: 'right', marginTop: '-25px' }} /></h5>
                        {todayData && todayData != "" ? <h2><b>{todayData['Finance / Credit']}</b></h2> : ""}</li>
                    <li><h5>Assigned to <br />
                        Customer Service  <img src={assignedtoCF} width={'35px'} style={{ float: 'right', marginTop: '-25px' }} /></h5>
                        {todayData && todayData != "" ? <h2><b>{todayData['Customer Service']}</b></h2> : ""}</li>


                    <li><h5>Assigned to <br />
                        Operations  <img src={assignedtooperation} width={'35px'} style={{ float: 'right', marginTop: '-25px' }} /></h5>
                        <h2><b>{todayData.Legal}</b></h2>
                    </li>
                </ul>
                : ""}
            <div className='lm-second-card'>

                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h5 style={{ marginLeft: '10px' }}><b><img src={analysis_chart} width={'20px'} />&nbsp;&nbsp;Operation Analysis</b></h5>
                        <div className='' style={{ marginTop: '40px' }}>
                            <ReactApexChart options={chartOptions} series={chartOptions.series} type="donut" height={350} width="100%" />
                        </div>
                    </div>
                    {admin && admin.department === 'Legal' ?
                        <div className='col-sm-6 col-xs-12'>
                            <h5 style={{}}><b><img src={Ops} width={'20x'} />&nbsp;&nbsp; Analysis Table</b></h5>
                            <table class="lm-dashboard  tableFirst">
                                <tbody>

                                    <tr>
                                        <th style={{ borderTopLeftRadius: '5px' }} >Employee ID</th>
                                        <td style={{ borderTopLeftRadius: '5px' }}>{admin && admin.emailId ? admin.emailId : ''}</td>
                                    </tr>
                                    <tr>
                                        <th >Total Assigned</th>
                                        <td >{tabledata.totalAssignmentDone}</td>
                                    </tr>
                                    <tr>
                                        <th >Mark RFD</th>
                                        <td >{tabledata.markAsRfd}</td>
                                    </tr>
                                    <tr>
                                        <th >Expired</th>
                                        <td >{tabledata.expired}</td>

                                    </tr>
                                    <tr >
                                        <th style={{ borderBottom: 'none', borderBottomLeftRadius: '5px' }}>Pending</th>
                                        <td style={{ borderBottom: 'none', borderBottomRightRadius: '5px' }}>{tabledata.totalPending}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        : ""}
                            {admin && admin.department === 'Customer Service' ?
                        <div className='col-sm-6 col-xs-12'>
                            <h5 style={{}}><b><img src={Ops} width={'20x'} />&nbsp;&nbsp; Analysis Table</b></h5>
                            <table class="lm-dashboard  tableFirst">
                                <tbody>

                                    <tr>
                                        <th style={{ borderTopLeftRadius: '5px' }} >Employee ID</th>
                                        <td style={{ borderTopLeftRadius: '5px' }}>{admin && admin.emailId ? admin.emailId : ''}</td>
                                    </tr>
                                    <tr>
                                        <th >Approved</th>
                                        <td >{tabledata.approved}</td>
                                    </tr>
                                    <tr>
                                        <th >Communicated</th>
                                        <td >{tabledata.communicated}</td>
                                    </tr>
                                    <tr>
                                        <th >Mark As Complete</th>
                                        <td >{tabledata.markAsComplete}</td>

                                    </tr>
                                    <tr >
                                        <th style={{ }}>Rejected</th>
                                        <td style={{  }}>{tabledata.rejected}</td>
                                    </tr>
                                    <tr >
                                        <th style={{ }}>Total Assignment Done</th>
                                        <td style={{  }}>{tabledata.totalAssignmentDone}</td>
                                    </tr>
                                    <tr >
                                        <th style={{ borderBottom: 'none', borderBottomLeftRadius: '5px' }}>Total Pending</th>
                                        <td style={{ borderBottom: 'none', borderBottomRightRadius: '5px' }}>{tabledata.totalPending}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        : ""}
                            {admin && admin.department === 'Finance / Credit' ?
                        <div className='col-sm-6 col-xs-12'>
                            <h5 style={{}}><b><img src={Ops} width={'20x'} />&nbsp;&nbsp; Analysis Table</b></h5>
                            <table class="lm-dashboard  tableFirst">
                                <tbody>

                                    <tr>
                                        <th style={{ borderTopLeftRadius: '5px' }} >Employee ID</th>
                                        <td style={{ borderTopLeftRadius: '5px' }}>{admin && admin.emailId ? admin.emailId : ''}</td>
                                    </tr>
                                    <tr>
                                        <th >Approved</th>
                                        <td >{tabledata.approved}</td>
                                    </tr>
                                    <tr>
                                        <th >Expired</th>
                                        <td >{tabledata.expired}</td>
                                    </tr>
                                   
                                    <tr >
                                        <th style={{ }}>Rejected</th>
                                        <td style={{  }}>{tabledata.rejected}</td>
                                    </tr>
                                    <tr >
                                        <th style={{ }}>Total Assignment Done</th>
                                        <td style={{  }}>{tabledata.totalAssignmentDone}</td>
                                    </tr>
                                    <tr >
                                        <th style={{ borderBottom: 'none', borderBottomLeftRadius: '5px' }}>Total Pending</th>
                                        <td style={{ borderBottom: 'none', borderBottomRightRadius: '5px' }}>{tabledata.totalPending}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        : ""}
                </div>
            </div>
        </div>
    </>
)
export default DashboardUi
