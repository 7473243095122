import React, { useEffect, useState } from "react";
import { getAllBusinessTarget } from "../../targetactioncreator";

const TargetList = ({ editHandler,ActiveMonthFilter }) => {
    // const expenseData = {
    //     "categoryId": "123",
    //     "categoryName": "Utilities",
    //     "subCategoryId": "456",
    //     "subCategoryName": "Electricity",
    //     "targetAmount": 500.0,
    //     "targetPercentageASPerAUM": 5.0,
    //     "currentAmount": 450.0,
    //     "currentPercentageASPerAUM": 4.5,
    //     "deviationAmount": 50.0,
    //     "deviationPercentageASPerAUM": 0.5,
    //     "comment": "Regular monthly payment",
    //     "active": true,
    //     id: "2"
    // };
    const [expenseData, setExepensedata] = useState({})
    useEffect(() => {
        getAllBusinessTarget(callback => {
            setExepensedata(callback.data)
        })
    }, [ActiveMonthFilter])
    const handleDelete = () => {
        // Implement delete logic here
    };

    return (
        <div className="container Executor">
            <div
                style={{
                    background: '#f2f2f2',
                    padding: '10px',
                    margin: '50px auto 0',
                    borderRadius: '5px',
                    overflow: 'auto',
                    maxWidth: '1000px'
                }}
            >
                <div>
                    <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Sub Category Name</th>
                                <th>Target Application</th>
                                <th>Target Application Fresh</th>
                                <th>Target Application Repeat</th>
                                <th>comment</th>
                                <th>is Active</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                <td>{expenseData.categoryName}</td>
                                <td>{expenseData.subCategoryName}</td>
                                <td>{expenseData.targetApplication}</td>
                                <td>{expenseData.targetApplicationFresh}</td>
                                <td>{expenseData.targetApplicationRepeat}</td>
                                <td>{expenseData.comment}</td>
                                <td>{expenseData.active}</td>
                                <td>
                                    <i
                                        className="fa fa-pencil-square-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => editHandler(expenseData)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Edit Expense"
                                        title="Edit Expense"
                                    ></i>
                                </td>
                                <td>
                                    <i
                                        className="fa fa-trash-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => handleDelete(expenseData)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Delete Expense"
                                        title="Delete Expense"
                                    ></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TargetList;
