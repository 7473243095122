import React, { useEffect, useState } from "react";
import Graph2 from "../../components/Graph2";
import Table2 from "../../components/Table2";
function RejectionStats({ data }) {
  const [showtable, setshowtable] = useState(false);
  const [showgraph, setshowgraph] = useState(true);
  const [buttontext, setbuttontext] = useState({
    text: "Table",
    image: "../Images/Table.png",
  });

  const totalCount = data.reduce((acc, item) => acc + item.count, 0);

  const itemsWithPercentage = data.map(item => ({
    ...item,
    percentage: (item.count / totalCount) * 100,
  }));

  const handleClick = () => {
    setshowtable(!showtable);
    setshowgraph(!showgraph);
    setbuttontext({
      text: showgraph ? "Graph" : "Table",
      image: showgraph ? "../Images/Graph.png" : "../Images/Table.png",
    });
  };
  useEffect(() => {

  }, [])
  return (
    <>
      <div
        className="bg-white  shadow "
        style={{ marginTop: '20px', height: '450px', overflowY: 'scroll', overflowX: 'hidden' }}
      >

        <div className="row">
          <div className="col-sm-4 col-xs-6">
            <h5 className="totalcount1 card-heading">
              <b>Rejection Stats </b>
            </h5>
          </div>
          <div className="col-sm-5 col-xs-12">
          </div>
          <div className="col-sm-3 col-xs-6">
            <button
              type="button"
              class="btn btnTable"
              onClick={(e) => handleClick(e)}
              style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px' }}
            >
              {buttontext.text === 'Table' ? <i className="fa fa-table"></i> : <i className="fa fa-bar-chart"></i>}&nbsp;{buttontext.text}
            </button>
          </div>
        </div>

        <div className={showgraph ? "totalcount5" : ""}>
          {showgraph && <Graph2 data={data} />}
          {showtable && <Table2 itemsWithPercentage={itemsWithPercentage} />}

          <div></div>
        </div>
      </div>
    </>
  );
}

export default RejectionStats;
