import React from 'react'

const AMBdash = ({ ambasadorDetails }) => (
    <div className="container-fluid crm-user-detail blue-text">
        <div className="row">
            <div className="crm-user-head">Personal Information</div>
        </div>
        <div className="row">
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Total Application</label>
                <p>{ambasadorDetails != null ? ambasadorDetails.totalApplication != null ? ambasadorDetails.totalApplication : "" : ""}</p>
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Total Commission Earned</label>
                <p>{ambasadorDetails != null ? ambasadorDetails.totalAmountEarn != null ? ambasadorDetails.totalAmountEarn : "" : ""}</p>
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Total Disbursed Applications</label>
                <p>{ambasadorDetails != null ? ambasadorDetails.disbursedApplication != null ? ambasadorDetails.disbursedApplication : "" : ""}</p>
            </div>
        </div>
        <div className="row">
            {/* <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Total Complete Applications</label>
                <p>{ambasadorDetails != null ? ambasadorDetails.newApplication != null ? ambasadorDetails.newApplication : "" : ""}</p>
            </div> */}
            {/* <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Commission on Complete Applications</label>
                <p>{ambasadorDetails != null ? ambasadorDetails.totalNewApplicationAmount != null ? ambasadorDetails.totalNewApplicationAmount : "" : ""}</p>
            </div> */}
            {/* <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Commission on Approved Application</label>
                <p>{ambasadorDetails != null ? ambasadorDetails.totalDisbursedApplicationAmount != null ? ambasadorDetails.totalDisbursedApplicationAmount : "" : ""}</p>
            </div> */}
        </div>
        <div className="row">
            {/* <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Convert Ratio</label>
                <p>{ambasadorDetails != null ? ambasadorDetails.convertRatio != null ? ambasadorDetails.convertRatio : "" : ""}</p>
            </div> */}
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Total Disbursed Amount</label>
                <p>{ambasadorDetails != null ? ambasadorDetails.totalDisbursedAmount != null ? ambasadorDetails.totalDisbursedAmount : "" : ""}</p>
            </div>
        </div>
        <div className="row from-group overflow-auto">
            <div className="col-xs-12">
                {ambasadorDetails != null ? ambasadorDetails.ambassadorUserLoanPOList != null && ambasadorDetails.ambassadorUserLoanPOList.length > 0 ?
                    <table className="text-center loan-detail-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th>Email Id</th>
                                <th>Apply Date</th>
                                <th>Amount</th>
                                <th>Tenure</th>
                                <th>Approve Date</th>
                                <th>Disberse Date</th>
                                <th>Payback Date</th>
                                <th>Loan Status</th>
                                <th>Payback Amount</th>

                            </tr>
                        </thead>
                        <tbody>
                            {ambasadorDetails.ambassadorUserLoanPOList.map((loan, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{loan.userName}</td>
                                        <td>{loan.userMobile}</td>
                                        <td>{loan.userEmail}</td>
                                        <td className="nowrap">{loan.loanApplyDate != null ? loan.loanApplyDate.split(' ')[0] : ""}</td>
                                        <td>{loan.loanAmount}</td>
                                        <td>{loan.paybackDuration}</td>
                                        <td className="nowrap">{loan.loanApproveDate != null ? loan.loanApproveDate.split(' ')[0] : ""}</td>
                                        <td className="nowrap">{loan.disberseDate != null ? loan.disberseDate.split(' ')[0] : ""}</td>
                                        <td className="nowrap">{loan.loanPaybackDate != null ? loan.loanPaybackDate.split(' ')[0] : ""}</td>
                                        <td>{loan.loanSanctionStatus}</td>
                                        <td>{loan.totalPaybackAmount}</td>
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                    : "" : ""}
            </div>
        </div>
    </div>
)

export default AMBdash;