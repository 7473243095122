import React, { Component } from 'react'
import CovertToPaid from '../../../presentationals/Admin/BulkActions/ConverToPaid.component'
import { convertToPaidApi } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import { csvReader } from '../../../../utils/commonUtility'
import Loader from '../../../presentationals/Loader/DotLoader.component'

let paginationNumber = 10

class UpdateUsingCSV extends Component {
  constructor (props) {
    super(props)
    this.state = {
      csvDataArray: [],
      csvFile: '',
      allData: '',
      loaderState: false,
      popupState: false,
      popupStatus: ''
    }
  }
  closePopup () {
    this.setState({ popupState: false })
  }

  render () {
    const { admin } = this.props
    return (
      <div className='full-height'>
                  {this.state.loaderState == true ? <Loader /> : ''}

        <CovertToPaid
          handleForce={this.handleForce.bind(this)}
          saveCsvData={this.saveCsvData.bind(this)}
          csvDataArray={this.state.csvDataArray}
          handleForceNew={this.handleForceNew.bind(this)}
        />
        {this.state.popupState == true ? (
          <Popup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
        ) : (
          ''
        )}
      </div>
    )
  }

  saveCsvData () {
    this.setState({
        loaderState: true
      })
    const { admin } = this.props
    
    if (this.state.csvFile != null && this.state.csvFile != '') {
      convertToPaidApi(this.state.csvFile, this.state.type, admin, callBack => {
        // console.log(callBack)
        if (callBack == 'success') {
          this.setState({
            popupStatus: 'Status Changed to Paid Successfully',
            popupState: true,
            loaderState: false
          })
        } else {
          this.setState({ popupState: true, popupStatus: 'Please Try Again',loaderState:false },()=>this.removePopup())
        }
      })
    } else {
      this.setState({ popupState: true, popupStatus: 'Select File First' },()=>this.removePopup())
    }
  }
  handleForceNew (e) {
    this.setState({
      csvFile: e.target.files[0],
      type: e.target.files[0].type
    })
  }
removePopup(){
  setTimeout(() => {
    this.setState({popupState:false})
  }, 5000);
}
  handleForce (data) {
    this.setState({ loaderState: true })
    this.setState({
      csvDataArray: csvReader(data),
      loaderState: false
    })
  }
}

export default UpdateUsingCSV
