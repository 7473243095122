import React, { Component } from 'react'
import ApprovedToDisburseUi from '../../../presentationals/Admin/BulkActions/ApprovedToDisbursed.component'
import { approveToDisburseUploadApi } from '../AdminActionCreator.component'
import { csvReader } from '../../../../utils/commonUtility'
import { APIS } from '../../../../utils/api-factory';
import Loader from '../../../presentationals/Loader/DotLoader.component'
import Popup from '../../../presentationals/Popup/Popup.component'

let paginationNumber = 10;

class UpdateUsingCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvDataArray: [],
            file: '',
            allData: '',
            loaderState: false,
            popupState: false,
            popupStatus: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,


        }
    }
    componentDidMount() {

    }

    render() {
        const { admin } = this.props;
        return (
            <div className="full-height">

                <ApprovedToDisburseUi
                    handleForce={this.handleForce.bind(this)}
                    saveCsvData={this.saveCsvData.bind(this)}
                    allData={this.state.allData}
                    saveApproveToDisbure={this.saveApproveToDisbure.bind(this)}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                />
                {this.state.loaderState == true ?
                    <Loader />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
            </div>
        )
    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forword') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1,
            pageIndexToShow: 0
        }, () => this.getLeads())

    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                pageIndexToShow: 0
            }, () => this.getLeads())
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }


    saveCsvData() {
        this.setState({
            loaderState: true
        })
        if (this.state.file != null) {
            approveToDisburseUploadApi(this.state.file, callBack => {
                // console.log(callBack)
                callBack = JSON.parse(callBack)
                this.setState({
                    allData: callBack,
                    loaderState: false
                })
                // console.log(callBack)
            })
        }

    }

    saveApproveToDisbure(data) {
        // console.log(data)
        const { admin } = this.props;
        fetch(APIS.DISBERSE_API + data.loanId + '&date=' + data.disburseDate + '&empId=' + admin.emailId + '&mail=' + data.mailSend + '&nbfcName=' + data.nbfcName)
            .then(json => json.text())
            .then(res => {
                if (res == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Successfully Saved'
                    })
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please Try Again'
                    })
                } this.removePopup()
            })
    }

    handleForce(e) {
        // console.log(e.target.files[0])
        this.setState({

            file: e.target.files[0]
        })
        // this.setState({
        //     csvDataArray: csvReader(data)
        // })
    };

}

export default UpdateUsingCSV;