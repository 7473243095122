import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { saveDoctorProducts } from "./actionCreator";
// import routes from "../../../Layout/Routes";
import { useNavigate } from "react-router-dom";
import { APIS } from "../../../../utils/api-factory";
import { doctorProductConfigValidation } from "../../../../utils/validation";
import Input from "./common/InputMUI";
const DocProductConfig = ({ productData,closePopup }) => {
    const [objectData, setObjectData] = useState({})
    const [nbfcData, setNbfcData] = useState('')
    const [nbfcForId, setNbfcs] = useState('')
    const [errorMsg, setErrorMsg] = useState({})
    const navigate = useNavigate();

    useEffect(() => {
        getNbfcList()
        console.log(typeof(productData),'productData')
        if (productData !== undefined && productData !== '') {
            let chng = Object.assign({}, objectData)
            chng.advanceEmi = productData.advanceEmi
            chng.id = productData.id
            chng.interest = productData.interest
            chng.nbfcTakePercent = productData.nbfcTakePercent

            chng.processingFesIncludingGSTINR = productData.processingFesIncludingGSTINR
            chng.processingFesIncludingGSTRate = productData.processingFesIncludingGSTRate
            chng.productId = productData.productId
            chng.productName = productData.productName
            chng.subventionRate = productData.subventionRate
            chng.totalEmi = productData.totalEmi
            setObjectData(chng)
        }
    }, [])
    const back = () => {
        // navigate(routes.DOCTOR_PRODUCT_LIST)

    }
    const onChangeHandler = (e, type) => {
        let data = Object.assign({}, objectData)
        data[type] = e
        setObjectData(data)
    }
    const getNbfcList = (data) => {
        fetch(APIS.GET_LIST_OF_NBFC + '')
            .then(res => res.json())
            .then(res => {
                setNbfcData(res)
                const nbfcIdArr = []

                res && res.map((data, i) => {
                    nbfcIdArr.push({ name: data.name, code: data.nbfcId })
                })
                setNbfcs(nbfcIdArr)

            })
    }
    const submit = () => {
        let validation = doctorProductConfigValidation(objectData)
        if (validation.status) {
            setErrorMsg({})
            saveDoctorProducts(objectData, callback => {
                if (callback.message === 'success') {
                    // navigate(routes.DOCTOR_PRODUCT_LIST)
                    closePopup()
                }
            })
        } else {
            setErrorMsg(validation)
        }
    }

    return (
        <div className="conatiner-fluid">
            {console.log(objectData,'objectData')}
            {/* <div style={{ marginBottom: '20px', marginLeft: '10px' }}>
                <i className="fa fa-arrow-left" onClick={() => back()} style={{ fontSize: '18px', color: '#0c3953', cursor: 'pointer' }}></i>
            </div> */}
            <div className="" style={{ padding: '20px', borderRadius: '5px', minHeight: '400px',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                {/* <h4 className="mt-3 mb-3"><strong>Configuration</strong></h4> */}

                <div className="row">
                    {/* <div className="col-sm-4 col-xs-12 " style={{ marginTop: '20px' }}>
                        <SelectField
                            label={'Nbfc'}
                            onChange={e => onChangeHandler(e, 'nbfcId')}
                            id="nbfcId"
                            value={objectData.nbfcId}
                            name="nbfcId"
                            data={nbfcForId}
                            size={'small'}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'nbfcId' ? <span className="text-danger">{errorMsg.msg}</span> : ""}
                    </div> */}
                    <div className="col-sm-4 col-xs-12 mt-3" style={{marginTop:'15px'}}>
                        <Input
                            label={'Product Name'}
                            type={'text'}
                            value={objectData.productName}
                            name={'productName'}
                            id={'productName'}
                            onChange={e => onChangeHandler(e, 'productName')}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'productName' ? <span className="text-danger">{errorMsg.msg}</span> : ""}

                    </div>
                    <div className="col-sm-4 col-xs-12 mt-3" style={{marginTop:'15px'}}>
                        <Input
                            label={'Total Emi'}
                            type={'number'}
                            value={objectData.totalEmi}
                            name={'totalEmi'}
                            id={'totalEmi'}
                            onChange={e => onChangeHandler(e, 'totalEmi')}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'totalEmi' ? <span className="text-danger">{errorMsg.msg}</span> : ""}

                    </div>


                    <div className="col-sm-4 col-xs-12 mt-3" style={{marginTop:'15px'}}>
                        <Input
                            label={'Advance Emi'}
                            type={'number'}
                            value={objectData.advanceEmi}
                            name={'advanceEmi'}
                            id={'advanceEmi'}
                            onChange={e => onChangeHandler(e, 'advanceEmi')}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'advanceEmi' ? <span className="text-danger">{errorMsg.msg}</span> : ""}

                    </div>
                </div>
                <div className="row" >
                    <div className="col-sm-4 col-xs-12 mt-3" style={{marginTop:'15px'}}>
                        <Input
                            label={'Processing Fes Including GST INR'}
                            type={'number'}
                            value={objectData.processingFesIncludingGSTINR}
                            name={'processingFesIncludingGSTINR'}
                            id={'processingFesIncludingGSTINR'}
                            onChange={e => onChangeHandler(e, 'processingFesIncludingGSTINR')}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'processingFesIncludingGSTINR' ? <span className="text-danger">{errorMsg.msg}</span> : ""}

                    </div>
                    <div className="col-sm-4 col-xs-12 mt-3" style={{marginTop:'15px'}}>
                        <Input
                            label={'Processing Fees Including GST Rate'}
                            type={'number'}
                            value={objectData.processingFesIncludingGSTRate}
                            name={'processingFesIncludingGSTRate'}
                            id={'processingFesIncludingGSTRate'}
                            onChange={e => onChangeHandler(e, 'processingFesIncludingGSTRate')}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'processingFesIncludingGSTRate' ? <span className="text-danger">{errorMsg.msg}</span> : ""}

                    </div>

                    <div className="col-sm-4 col-xs-12 mt-3" style={{marginTop:'15px'}}>
                        <Input
                            label={'SubVention Rate'}
                            type={'number'}
                            value={objectData.subventionRate}
                            name={'subventionRate'}
                            id={'subventionRate'}
                            onChange={e => onChangeHandler(e, 'subventionRate')}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'subventionRate' ? <span className="text-danger">{errorMsg.msg}</span> : ""}

                    </div>
                </div>

                <div className="row" >
                    <div className="col-sm-4 col-xs-12 mt-3" style={{marginTop:'15px'}}>
                        <Input
                            label={'Interest'}
                            type={'text'}
                            value={objectData.interest}
                            name={'interest'}
                            id={'interest'}
                            onChange={e => onChangeHandler(e, 'interest')}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'interest' ? <span className="text-danger">{errorMsg.msg}</span> : ""}

                    </div>
                    <div className="col-sm-4 col-xs-12 mt-3" style={{marginTop:'15px'}}>
                          {/* revert */}
                        <Input
                            label={'Nbfc Take Percentage'}
                            type={'number'}
                            value={objectData.nbfcTakePercent}
                            name={'nbfcTakePercent'}
                            id={'nbfcTakePercent'}
                            onChange={e => onChangeHandler(e, 'nbfcTakePercent')}
                            required={true}
                        />
                        {errorMsg && errorMsg.field === 'nbfcTakePercent' ? <span className="text-danger">{errorMsg.msg}</span> : ""}

                    </div>

                </div>
                <div className="row" >

                    <div className="row text-center mt-5" style={{marginTop:'20px'}}>
                        {/* <div className="col-sm-3"></div> */}
                        {/* <div className="col-sm-3"><button className="btn btn-primary" onClick={() => back()} style={{ width: '150px', marginLeft: 'auto', marginRight: 'auto' }}>Back To Products</button></div> */}
                        <div className="">
                            <button className="btn btn-primary" onClick={() => submit()} style={{ width: '150px', marginLeft: 'auto', marginRight: 'auto' }}>Save</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
export default DocProductConfig