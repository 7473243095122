import React, { Component } from 'react'
import Menu from '../../presentationals/Admin/CRMSideNav.component'
//rewamp
import Losmenu from '../../presentationals/Admin/losMenu'
import LmsMenu from '../../presentationals/Admin/lmsMenu'
import FinanceMenu from '../../presentationals/Admin/financeMenu'
import BuisnessMenu from '../../presentationals/Admin/buisnessMenu'
import SuperAdminMenu from '../../presentationals/Admin/superAdminMenu'

class CRMMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpenState: '',
            generateReportState: true,
            losSubHandle: '',
            pageViewState: '',
            lmsSubTab: false
        }
    }
    render() {
        const { admin, menuType } = this.props
        return (
            <>
            {/* //rewamp */}
                {menuType === 'LOS' ?
                    <Losmenu
                        pageViewState={this.state.pageViewState}
                        losSubHandle={this.state.losSubHandle}
                        lmsSubTab={this.state.lmsSubTab}
                        subHandleTab={this.subHandleTab.bind(this)}
                        generateReportState={this.state.generateReportState}
                        navItemClick={this.navItemClick.bind(this)}
                        pageViewHandler={this.pageViewHandler.bind(this)}
                        menuOpenState={'los'}
                        admin={admin}
                    />
                    : ""}
                    {/* <Menu
                        pageViewState={this.state.pageViewState}
                        losSubHandle={this.state.losSubHandle}
                        lmsSubTab={this.state.lmsSubTab}
                        subHandleTab={this.subHandleTab.bind(this)}
                        generateReportState={this.state.generateReportState}
                        navItemClick={this.navItemClick.bind(this)}
                        pageViewHandler={this.pageViewHandler.bind(this)}
                        menuOpenState={this.state.menuOpenState}
                        admin={admin}
                    /> */}
                    
                {menuType === 'LMS' ?
                    <LmsMenu
                        pageViewState={this.state.pageViewState}
                        losSubHandle={this.state.losSubHandle}
                        lmsSubTab={this.state.lmsSubTab}
                        subHandleTab={this.subHandleTab.bind(this)}
                        generateReportState={this.state.generateReportState}
                        navItemClick={this.navItemClick.bind(this)}
                        pageViewHandler={this.pageViewHandler.bind(this)}
                        menuOpenState={'lms'}
                        admin={admin}
                    /> : ""}
                {menuType === 'FINANCE' ?
                    <FinanceMenu
                        pageViewState={this.state.pageViewState}
                        losSubHandle={this.state.losSubHandle}
                        lmsSubTab={this.state.lmsSubTab}
                        subHandleTab={this.subHandleTab.bind(this)}
                        generateReportState={this.state.generateReportState}
                        navItemClick={this.navItemClick.bind(this)}
                        pageViewHandler={this.pageViewHandler.bind(this)}
                        menuOpenState={'finance'}
                        admin={admin}
                    /> : ""}
                {menuType === 'BUSINESS' ?
                    <BuisnessMenu
                        pageViewState={this.state.pageViewState}
                        losSubHandle={this.state.losSubHandle}
                        lmsSubTab={this.state.lmsSubTab}
                        subHandleTab={this.subHandleTab.bind(this)}
                        generateReportState={this.state.generateReportState}
                        navItemClick={this.navItemClick.bind(this)}
                        pageViewHandler={this.pageViewHandler.bind(this)}
                        menuOpenState={'marketing'}
                        admin={admin}
                    /> : ""}
                      {menuType === 'SUPERADMIN' ?
                    <SuperAdminMenu
                        pageViewState={this.state.pageViewState}
                        losSubHandle={this.state.losSubHandle}
                        lmsSubTab={this.state.lmsSubTab}
                        subHandleTab={this.subHandleTab.bind(this)}
                        generateReportState={this.state.generateReportState}
                        navItemClick={this.navItemClick.bind(this)}
                        pageViewHandler={this.pageViewHandler.bind(this)}
                        menuOpenState={this.state.menuOpenState}
                        admin={admin}
                    /> : ""}
            </>


        )
    }
    subHandleTab(first, second) {
        if (second === 'los') {
            this.setState({ losSubHandle: first })
        }
        if (second === 'lms' && first === 'bulkOps') {
            this.setState({ lmsSubTab: !this.state.lmsSubTab })
        }
    }

    componentDidMount() {
        const { admin } = this.props
        if (admin.role != "superuser" && admin.emailId !== 'vishnu.pandey@borrowbuddy.co.in' && admin.emailId !== 'sunil.kumar@borrowbuddy.co.in' && admin.emailId !== 'accounts@borrowbuddy.co.in' && admin.emailId !== 'mohit.bansal@borrowbuddy.co.in' && admin.emailId !== 'priyanka.kumari@borrowbuddy.co.in' && admin.emailId !== 'piyush.jain@borrowbuddy.co.in') {
            var today = new Date().getHours();
            if (today > 19) {
                console.log(today, 'Hour')
            }
            if (today < 9) {
                console.log(today, 'Hours')
            }
            if (today > 9 && today < 19) {
                console.log(today)
                this.setState({ generateReportState: false })
            }
        }
    }

    pageViewHandler(pageView) {
        // console.log(pageView, this.state.pageViewState)
        this.setState({ pageViewState: pageView })

        this.props.getPageState(pageView)
    }

    navItemClick(navType) {
        // console.log(navType, this.state.menuOpenState)
        // if (this.state.menuOpenState != navType) {
        this.setState({ menuOpenState: navType })
        if (this.state.menuOpenState === navType) {
            this.setState({ menuOpenState: '' })
        }
        // } else {
        //     this.setState({ menuOpenState: '' })
        // }
    }
}

export default CRMMenu;