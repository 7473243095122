import React, { Component } from 'react';
import RecoveryLogsUi from '../../../presentationals/Admin/Recovery/RecoveryComLogs.component';
import { recoveryLogsApi } from '../AdminActionCreator.component'


class RecoveryLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recoveryComLogs: '',
            loaderState:false
        }
    }

    componentWillMount() {
        const { dataForCommunication,allDetail } = this.props
        this.setState({loaderState:true})
        recoveryLogsApi(allDetail.userId,
            (callBack) => {
                this.setState({ recoveryComLogs: callBack ,loaderState:false})
            })
    }

    render() {
        return (
            <div className="full-height  ">
                <RecoveryLogsUi
                    recoveryComLogs={this.state.recoveryComLogs}
                    loaderState={this.state.loaderState}
                />
            </div>
        )
    }

}

export default RecoveryLogs;