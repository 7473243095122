import React from 'react'
import DatePicker from 'react-datepicker'
import { ROLE, STRING, PLACEHOLDER, TYPE } from '../../../../../utils/Constant'
import FlipkartLogo from '../../../../../images/flipkart.png'
import LoanLogo from '../../../../../images/LoanFinal.png'
import AmazonLogo from "../../../../../images/amaz.png"
const userFinancial = ({
  getGiftCardDetail,
  company,
  bankData,
  nbfcList,
  nbfcHandler,
  allDetail,
  repeatApplication,
  tentativePopup,
  admin,
  convertLoan,
  tentativePopupState,
  tentativeData,
  tentativeSaveHandler,
  tentativeDetail,
  emiConvertHandler,
  userDetails,
  emiTableData,
  mendateVerification,
  nbfc,
  newAllDetail,
  hideDeactivateButton,
  belowoneLakh,
  belowoneLakhState,
  rangeVreedhi,
  RfdFunction,
  salaryhandler,
  salaryDateToShow
}) => (
  <div className='container-fluid '>
    <div className='row form-group'>
      <br />
      <h4>
        <strong>Loan Information</strong>
      </h4>

      <div className='col-sm-3 col-xs-12'>

        {userDetails.nachStatus == true && hideDeactivateButton == true ?
          <h5 className='text-center' style={{ background: 'yellow', padding: '5px', borderRadius: '5px' }}>{hideDeactivateButton == true ? 'User Requested For NACH Deactivation' : ''}</h5>
          : ""}

        <h3 className='text-center blue-text'>
          NACH:{' '}
          {userDetails.nachStatus != null && userDetails.nachStatus != null ? (
            userDetails.nachStatus == true ? (
              <span className='darkGreen-text'>Active</span>
            ) : (
              <span className='red-text'>Inactive</span>
            )
          ) : (
            ''
          )}
        </h3>
      </div>
      <div className='col-sm-3 col-xs-12'>
        <h3 className='text-center blue-text'>
          {userDetails != null && userDetails.applicationLoanId != null
            ? 'Loan Id: ' + userDetails.applicationLoanId
            : ''}
        </h3>
      </div>
      <div className='col-sm-3 col-xs-12'>
        <h3 className='text-center blue-text'>
          {allDetail != null && allDetail.chequeLimit != null
            ? 'Cheque Limit: ' + allDetail.chequeLimit
            : ''}
        </h3>
      </div>
      <div className='col-sm-3 col-xs-12'>
        {admin.rolelist != undefined && userDetails.loanFrom !== 'vreedhi' ? (
          admin.rolelist.indexOf(ROLE.IVR) >= 0 ? (
            <h3 className='text-center'>
              <button
                className='btn profile-save-btn margin-0'
                onClick={e => tentativePopup('open')}
              >
                Tentative Approval
              </button>
            </h3>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    </div>
    {/* <div className='row form-group'>
      <div className='col-sm-3 col-xs-12'>
        {admin.rolelist != undefined ? (
          admin.rolelist.indexOf(ROLE.ACTION) >= 0 ? (
            <button
              className='btn profile-save-btn margin-0'
              onClick={e => mendateVerification()}
            >
              Mandate Verification
            </button>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
      <div className='col-sm-3 col-xs-12'>
        {admin.rolelist != undefined ? (
          admin.rolelist.indexOf(ROLE.ACTION) >= 0 ? (
            <button
              className='btn profile-save-btn margin-0'
              onClick={e => RfdFunction('202')}
            >
              Mark as RFD
            </button>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
      <div className='col-sm-3 col-xs-12'>
        {admin.rolelist != undefined ? (
          admin.rolelist.indexOf(ROLE.ACTION) >= 0 ? (
            <button
              className='btn profile-save-btn margin-0'
              onClick={e => RfdFunction('201')}
            >
              Mark as PFD
            </button>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    </div> */}
    {/* {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <input className="phocket-checkbox" type="checkbox" id={"convert_EMI"} onChange={(e) => emiConvertHandler(e)} checked={allDetail.emiApply} />
                        <label htmlFor={"convert_EMI"}><span></span></label>&nbsp;&nbsp;Convert to EMI?
                </div>
                </div>
                : null} */}

    <div className='row form-group overflow-auto'>
      <div className='col-xs-12'>
        {allDetail != null ? (
          allDetail.loanDetailList != null &&
            allDetail.loanDetailList.length > 0 ? (
            <table className='text-center phocket-table '>
              <thead>
                <tr>
                  <th>Application Id</th>
                  <th>Applied Date</th>
                  <th>Type</th>
                  <th>Loan From</th>
                  <th>Sub Status</th>
                  <th>Approved Date</th>
                  <th>Disbursed Date</th>
                  {/* {admin.emailId == 'SuperUser' ?  */}
                  {/* <th>Voucher Code</th>  */}
                  {/* : ''} */}
                  {admin.role == 'superuser' && userDetails.typeOfProduct != 'Loan' ? <th>Voucher Details</th> : ''}
                  {/* {admin.emailId == 'SuperUser' ? ( */}
                  {/* <th>Voucher Expiry Date</th> */}
                  {/* ) : (
                    ''
                  )} */}
                  <th>Applied Amount</th>
                  <th>Approved Amount</th>

                  <th>Tenure</th>
                  <th>EMI</th>
                  <th>Tentative Amount</th>
                  <th>Tentative Repayment Date</th>
                  {/* <th>Call Scheduled Date</th> */}
                  <th>Complete Type</th>
                  <th>Incomplete Type</th>
                  <th>IVR Status</th>
                  <th>Loan Status</th>
                  <th>Reject Reason</th>
                  <th>Repayment Amount</th>
                  <th>Pay on Today</th>
                  <th>Repayment Date</th>
                  <th>Payment Date</th>
                  <th>Date Difference</th>

                  {/* <th>Convert to EMI</th> */}
                </tr>
              </thead>
              <tbody>

                {allDetail.loanDetailList.map((loan, i) => {
                  return (
                    <tr key={i}>
                      <td>{loan.applicationId}</td>
                      <td>
                        {loan.loanApplyDate != null && loan.loanApplyDate != ''
                          ? loan.loanApplyDate.split(' ')[0]
                          : null}
                      </td>
                      {loan.giftCardType == 'Amazon' ? (
                        <td>
                          <img src={AmazonLogo} width='21px' />
                        </td>
                      ) : loan.giftCardType == 'Flipkart' ? (
                        <td>
                          <img src={FlipkartLogo} width='21px' />
                        </td>
                      ) : loan.productType == 'Loan' ? (
                        <td>
                          <img src={LoanLogo} width='21px' />
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td>{loan.platFormFrom}</td>
                      {/* {loan.subStatus != '' && loan.subStatus!=null? */}
                      <td>{loan.subStatus}</td>
                      {/* :""} */}
                      <td>
                        {loan.approveDate != null && loan.approveDate != ''
                          ? loan.approveDate.split(' ')[0]
                          : null}
                      </td>
                      <td>{loan.disburseDate}</td>
                      {/* {admin.emailId == 'SuperUser' ? ( */}
                      {/* <td>{loan.voucherCode}</td> */}
                      {/* ) : (
                        ''
                      )} */}
                      {admin.role == 'superuser' && userDetails.typeOfProduct != 'Loan' ? (
                        <td> <i style={{ cursor: 'pointer' }} onClick={() => getGiftCardDetail(loan.giftCardId)} className="fa fa-info-circle" aria-hidden="true"></i></td>
                      ) : (
                        ''
                      )}
                      {/* {admin.emailId == 'SuperUser' ? ( */}
                      {/* <td>{loan.expiryDate}</td> */}
                      {/* ) : (
                        ''
                      )} */}
                      <td>{loan.loanAmount}</td>
                      <td>{loan.approveAmount}</td>
                      {/* <td>{loan.subStatus}</td> */}

                      <td>{loan.paybackDuration}</td>
                      <td>{loan.loanEmi > 0 ? loan.loanEmi : null}</td>
                      <td>{loan.tentativeApproveAmount}</td>
                      <td>
                        {loan.tentativeDuration != 0
                          ? loan.tentativeDuration
                          : null}
                      </td>
                      {/* <td>{loan.callScheduleDate}</td> */}
                      <td>{loan.completeType}</td>
                      <td>{loan.incompleteType}</td>

                      <td
                        style={{
                          backgroundColor:
                            loan.ivrStatus == 1
                              ? '#00800082'
                              : loan.ivrStatus == 2
                                ? '#ff000057'
                                : null
                        }}
                      >
                        {loan.ivrStatus == 1
                          ? 'Confirmed'
                          : loan.ivrStatus == 2
                            ? 'Need Call'
                            : null}
                      </td>
                      <td>{loan.adminLoanStatus}</td>
                      <td>{loan.approveOrRejectReason}</td>
                      <td>{loan.totalPaybackAmount}</td>
                      <td>{loan.todayAmount}</td>
                      <td>
                        {loan.loanPaybackDate != null &&
                          loan.loanPaybackDate != ''
                          ? loan.loanPaybackDate.split(' ')[0]
                          : null}
                      </td>
                      <td>
                        {loan.paymentDate != null && loan.paymentDate != ''
                          ? loan.paymentDate.split(' ')[0]
                          : null}
                      </td>
                      {loan.paymentDate != null ? (
                        <td
                          style={{
                            backgroundColor:
                              loan.delayCount <= 0 ? '#00800082' : '#ff000057'
                          }}
                        >
                          {loan.delayCount}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {/* <td>
                                                {loan.canConvertToEMI ?
                                                    <button className="profile-save-btn" onClick={(e) => convertLoan(loan)}><span className="fa fa-exchange" /></button>
                                                    : null}
                                            </td> */}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    </div>
    <div className='row form-group'>
      <div className='crm-user-head'>Account Information</div>
    </div>
    <div className='row form-group'>
      <div className='col-sm-6 col-xs-12'>
        <p>
          <b>Name As On Bank</b> -
          {bankData != null
            ? bankData.nameOnBank != null
              ? bankData.nameOnBank
              : ''
            : ''}
        </p>
      </div>
      <div className='col-sm-6 col-xs-12'>
        <p>
          <b>Account Number</b> -
          {bankData != null
            ? bankData.accountNo != null
              ? bankData.accountNo
              : ''
            : ''}
        </p>
      </div>
    </div>
    {/* <div className='row form-group'> */}
      {/* <div className='col-sm-6 col-xs-12'>
        <p>
          <b>Bank Name</b> -
          {bankData != null
            ? bankData.bankName != null
              ? bankData.bankName
              : ''
            : ''}
        </p>
      </div> */}
      {/* <div className='col-sm-6 col-xs-12'>
        <p>
          <b>Account Number</b> -
          {bankData != null
            ? bankData.accountNo != null
              ? bankData.accountNo
              : ''
            : ''}
        </p>
      </div> */}
    {/* </div> */}
    <div className='row form-group'>
      <div className='col-sm-6 col-xs-12'>
        <p>
          <b>IFSC Code</b> -
          {bankData != null
            ? bankData.ifscCode != null
              ? bankData.ifscCode
              : ''
            : ''}
        </p>
      </div>
      {/* <div className='col-sm-6 col-xs-12'>
        <p>
          <b>Branch</b> -
          {bankData != null
            ? bankData.branch != null
              ? bankData.branch
              : ''
            : ''}
        </p>
      </div> */}

    </div>
    {bankData.bankNameOld != null && bankData.bankNameOld != '' ?
      <div>
        <hr />
        <div className='row form-group'>
          <div className='col-sm-6 col-xs-12'>
            <p>
              <b>Old Bank Name</b> -
              {bankData != null
                ? bankData.bankNameOld != null
                  ? bankData.bankNameOld
                  : ''
                : ''}
            </p>
          </div>
          <div className='col-sm-6 col-xs-12'>
            <p>
              <b>Old Account Number</b> -
              {bankData != null
                ? bankData.accountNumberOld != null
                  ? bankData.accountNumberOld
                  : ''
                : ''}
            </p>
          </div>
        </div>
        <div className='row form-group'>
          <div className='col-sm-6 col-xs-12'>
            <p>
              <b>Old IFSC Code</b> -
              {bankData != null
                ? bankData.ifscCodeOld != null
                  ? bankData.ifscCodeOld
                  : ''
                : ''}
            </p>
          </div>
          <div className='col-sm-6 col-xs-12'>
            <p>
              <b>Old Branch</b> -
              {bankData != null
                ? bankData.branchOld != null
                  ? bankData.branchOld
                  : ''
                : ''}
            </p>
          </div>
          <hr />
        </div>
      </div>
      : ""}
    {tentativePopupState ? (
      <div>
        <div
          className='background-blur'
          onClick={e => tentativePopup('close')}
        ></div>
        <div className='popup-main small-popup-new container-fluid max-width-500px dbl-border ' style={{ width: '80%', height: '500px' }}>
          <h3 className='text-center blue-text'>Tentative Popup</h3>
          <div className='row from-group'>
            {userDetails.typeOfProduct == 'Loan' && userDetails.loanFrom !== 'vreedhi' ?

              <div className='col-xs-6 col-sm-4 font-12px'>
                <input type="checkbox" onChange={(e) => belowoneLakh(belowoneLakhState)} checked={belowoneLakhState} />&nbsp;<b>Tick here if you want to approve for amount greater than 1Lac</b>
              </div>
              : ""}
            <div className={'col-xs-6 col-sm-4'}>

              {userDetails.typeOfProduct == 'Loan' && belowoneLakhState == false ? (
                <div>
                  <label className='font-12px'>Amount</label>
                  <input
                    type='number'
                    className='form-control'
                    onChange={e => tentativeData(e, 'amount', '')}
                    value={tentativeDetail.tentativeAmount}
                  />
                </div>
              ) : userDetails.typeOfProduct == 'Gift Card'
                // && userDetails.giftCardType == 'Flipkart' 
                ? (
                  <div>
                    <label className='font-12px'>Select Amount</label>
                    <select
                      className='form-control'
                      onChange={e => tentativeData(e, 'amount', '')}
                      value={tentativeDetail.tentativeAmount}
                    >
                      <option value='' disabled>
                        Select Amount
                      </option>
                      <option value='5000'>5000</option>
                      <option value='10000'>10000</option>
                      <option value='15000'>15000</option>
                      <option value='20000'>20000</option>
                      <option value='25000'>25000</option>
                      <option value='30000'>30000</option>
                    </select>
                  </div>
                ) : userDetails.typeOfProduct == 'Loan' && belowoneLakhState == true ?
                  <div>
                    <label className='font-12px'>Select Amount</label>
                    <select
                      required
                      className='form-control'
                      value={tentativeData.tentativeAmount}
                      onChange={e => tentativeData(e, 'amount', '')}
                    >
                      <option value='' disabled selected >
                        Select Amount
                      </option>
                      <option value='110000'>110000</option>
                      <option value='120000'>120000</option>
                      <option value='130000'>130000</option>
                      <option value='140000'>140000</option>
                      <option value='150000'>150000</option>
                      <option value='160000'>160000</option>
                      <option value='170000'>170000</option>
                      <option value='180000'>180000</option>
                      <option value='190000'>190000</option>
                      <option value='200000'>200000</option>

                    </select>
                  </div>
                  : ""}
            </div>
            
            {/* <div className='col-xs-6 col-sm-4'>
              <label className='font-12px'>Shift</label>
              <select
                className='form-control'
                onChange={e => tentativeData(e, 'shift', '')}>
                <option value=''>Select Shift</option>
                <option value='0'>Afternoon</option>
                <option value='1'>Evening</option>
              </select>
            </div> */}
                  <div className='col-xs-6 col-sm-4'>
              {nbfcList != null && nbfcList != '' ? (
                <div className=''>
                  <label className='font-12px'>Select NBFC</label>

                  <select
                    className='form-control'
                    value={nbfc}
                    onChange={e => nbfcHandler(e)}
                  >
                    {/* <option>Select Nbfc</option> */}

                    {nbfcList.map((data, i) => {
                      return (
                        <option key={i} value={data.nbfcId}>
                          {data.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              ) : (
                ''
              )}
            </div>
            {/* <div className='col-xs-6 col-sm-4'>
                {userDetails.typeOfProduct == 'Gift Card'?
                <div>
              <label className='font-12px'>Select Company</label>

              <select
                className='form-control'value={company}
                onChange={e => tentativeData(e, 'company', '')}
              >
                   <option value='' >
                      Select Company
                    </option>
                <option value='Flipkart'>Flipkart</option>
                <option value='Amazon'>Amazon</option>
              </select>
              </div>
              :""}
           
            </div> */}
        
            {userDetails.loanFrom === 'vreedhi' ?
              <div className='col-xs-6 col-sm-4'>
                <label className='font-12px'>Installment(weeks)</label>
                <input
                  type='number'
                  className='form-control'
                  id='phocketEMI'
                  value={tentativeDetail.selectEMI}
                  onChange={e => tentativeData(e, TYPE.SELECT_EMI, '')}

                />
                {rangeVreedhi !== '' ?
                  <div style={{ color: 'red', fontSize: '12px' }}>{rangeVreedhi}</div>
                  : ""}
              </div>
              : ""}
            {userDetails.typeOfProduct == 'Gift Card' ?

              <div className='col-xs-6 col-sm-4'>
                <div>
                  <label className='font-12px'>Select Company</label>

                  <select
                    className='form-control' value={company}
                    onChange={e => tentativeData(e, 'company', '')}
                  >
                    <option value='' >
                      Select Company
                    </option>
                    <option value='Flipkart'>Flipkart</option>
                    <option value='Amazon'>Amazon</option>
                  </select>
                </div>


              </div>
              : ""}

          </div>
          <div className='row from-group'>
            {/* <div className='col-xs-6 col-sm-4'>
              <label className='font-12px'>Shift</label>
              <select
                className='form-control'
                onChange={e => tentativeData(e, 'shift', '')}>
                <option value=''>Select Shift</option>
                <option value='0'>Afternoon</option>
                <option value='1'>Evening</option>
              </select>
            </div> */}
            {userDetails.typeOfProduct === 'Loan' && userDetails.loanFrom !== 'vreedhi' ?
              <div className='col-sm-4 col-xs-12'>
                <label className='font-12px'>Salary Day</label>
                <select
                  required
                  className='form-control'
                  value={salaryDateToShow}
                  onChange={e => salaryhandler(e)}
                  onClick={e => salaryhandler(e)}
                >
                  <option value='' disabled selected>
                    Select Salary Day
                  </option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                  <option value='13'>13</option>
                  <option value='14'>14</option>
                  <option value='15'>15</option>
                  <option value='16'>16</option>
                  <option value='17'>17</option>
                  <option value='18'>18</option>
                  <option value='19'>19</option>
                  <option value='20'>20</option>
                  <option value='21'>21</option>
                  <option value='22'>22</option>
                  <option value='23'>23</option>
                  <option value='24'>24</option>
                  <option value='25'>25</option>
                  <option value='26'>26</option>
                  <option value='27'>27</option>
                  <option value='28'>28</option>
                  <option value='29'>29</option>
                  <option value='30'>30</option>
                  <option value='31'>31</option>

                </select>



              </div>
              : ""}

            {userDetails.loanFrom !== 'vreedhi' ?
              <div className='col-xs-6 col-sm-4'>

                {userDetails.typeOfProduct == 'Loan' ? (
                  <div>
                    <label className='font-12px'>Select EMI</label>
                    <select
                      required
                      className='form-control'
                      id='phocketEMI'
                      value={tentativeDetail.selectEMI}
                      onChange={e => tentativeData(e, TYPE.SELECT_EMI, '')}
                    >
                      <option value='' disabled selected>
                        {PLACEHOLDER.SELECT_EMI}
                      </option>
                      <option value={STRING.FIRST_EMI}>
                        {STRING.FIRST_EMI + ' Month'}
                      </option>
                      {/* {tentativeDetail.tentativeAmount >= 5000 ? ( */}
                      <option value={STRING.SECOND_EMI}>
                        {STRING.SECOND_EMI + ' Months'}
                      </option>
                      {/* ) : null}
                    {tentativeDetail.tentativeAmount >= 11000  ? ( */}
                      <option value={STRING.THIRD_EMI}>
                        {STRING.THIRD_EMI + ' Months'}
                      </option>
                      {/* ) : null}
                      {tentativeDetail.tentativeAmount >= 21000 ? ( */}
                      <option value={STRING.SIXTH_EMI}>
                        {STRING.SIXTH_EMI + ' Months'}
                      </option>
                      <option value='12'>
                        12 Months
                      </option>
                      {/* ) : null} */}
                    </select>
                  </div>
                ) : userDetails.typeOfProduct == 'Gift Card' ? (
                  <div>
                    <label className='font-12px'>Select EMI</label>
                    <select
                      required
                      className='form-control'
                      id='phocketEMI'
                      value={tentativeDetail.selectEMI}
                      onChange={e => tentativeData(e, TYPE.SELECT_EMI, '')}
                    >
                      <option value='' disabled selected>
                        {PLACEHOLDER.SELECT_EMI}
                      </option>

                      <option value={STRING.THIRD_EMI}>
                        {STRING.THIRD_EMI + ' Months'}
                      </option>
                    </select>
                  </div>
                ) : (
                  ''
                )}
              </div>
              : ""}

            
            {emiTableData.length > 0
              ? emiTableData.map((emi, i) => {
                return (
                  <div className='col-xs-6 col-sm-4' key={i}>
                    <label className='font-12px'>
                      {i + 1} Repayment Date
                    </label>
                    <DatePicker
                      disabled={userDetails.loanFrom === 'vreedhi' ? true : false}
                      value={tentativeDetail['tentativeTenure' + i]}
                      selected={tentativeDetail['lastEmiDate' + i]}
                      onChange={e => tentativeData(e, 'tenure', i)}
                      className='form-control'
                      placeholderText='select Date'
                      dateFormat='DD/MM/YYYY'
                      id={'RepaymentDate_' + i}
                      fixedHeight
                    />
                  </div>
                )
              })
              : null}
          </div>
          {/* {userDetails.loanFrom !== 'vreedhi' ? */}
          <div className='row'>
            <div className='col-xs-6 col-sm-4'>
              {nbfcList != null && nbfcList != '' ? (
                <div className=''>
                  <label className='font-12px'>Select NBFC</label>

                  <select
                    className='form-control'
                    value={nbfc}
                    onChange={e => nbfcHandler(e)}
                  >
                    {/* <option>Select Nbfc</option> */}

                    {nbfcList.map((data, i) => {
                      return (
                        <option key={i} value={data.nbfcId}>
                          {data.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              ) : (
                ''
              )}
            </div>
            {/* <div className='col-xs-6 col-sm-4'>
                {userDetails.typeOfProduct == 'Gift Card'?
                <div>
              <label className='font-12px'>Select Company</label>

              <select
                className='form-control'value={company}
                onChange={e => tentativeData(e, 'company', '')}
              >
                   <option value='' >
                      Select Company
                    </option>
                <option value='Flipkart'>Flipkart</option>
                <option value='Amazon'>Amazon</option>
              </select>
              </div>
              :""}
           
            </div> */}
          </div>
          {/* : ""} */}
          <div className='row'>
            <div className='col-xs-12 text-center'>
              <button
                className='btn profile-save-btn center-block'
                onClick={e => tentativeSaveHandler()}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : null}
  </div>
)

export default userFinancial
