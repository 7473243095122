import React from 'react'
import { ROLE } from '../../../utils/Constant'
import HomeImage from '../../../images/generate-lead (1).png'
import SecondLogo from '../../../images/Group (5).png'
import ThirdLogo from '../../../images/Vector (25).png'
import FourthLogo from '../../../images/marketing.png'
import FifthLogo from '../../../images/Layer 4.png'
import SixthLogo from '../../../images/access-control.png'
import SeventhLogo from '../../../images/dashboard (1).png'
import EighthLogo from '../../../images/Digital Key.png'
import NinthLogo from '../../../images/Group (6).png'

const SettingsNavOptions = ({
    navItemClick,
    pageViewHandler,
    admin,
    menuOpenState,
    generateReportState,
    subHandleTab,
    losSubHandle, pageViewState, lmsSubTab
}) => (
    <div className='phocket-crm-menu new-menu animate-crm-menu  ' id='CRMMenu'>
        <ul className='mail-list-new'>

            {/* <div className='mail-list'>
                <li
                    className={menuOpenState === 'finance' ? 'mail-list-item-active' : 'mail-list-item'}
                    onClick={e => navItemClick('finance')}
                >
                    <img src={ThirdLogo} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> Finance </span>{menuOpenState === 'finance' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                </li>
            </div> */}
            {menuOpenState == 'finance' ?
                <>
                    {admin.rolelist != undefined ?
                        admin.rolelist.indexOf(ROLE.Asset_Quality) >= 0 ?
                            <li
                                className={pageViewState === 'assetQuality' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('assetQuality')}
                            >
                                Assets Quality
                            </li>
                            : "" : ""}
                    {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                        admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0 ?
                            <li
                                className={pageViewState === 'razorpay' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('razorpay')}
                            >
                                Razorpay Report
                            </li>
                            : "" : ""}
                    {admin.rolelist != undefined ?
                        admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 ?
                            <li
                                className={pageViewState === 'incomeCollected' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('incomeCollected')}
                            >
                                Income Breakup
                            </li>
                            : "" : ""}
                    {admin.emailId == "SuperUser" || admin.emailId == "priyanka.jain@borrowbuddy.co.in" || admin.emailId == "tapan.kumar@borrowbuddy.co.in" ?
                        <li
                            className={pageViewState === 'razorpayTransfer' ? 'sub-list-item-active' : 'sub-list-item'}
                            onClick={e => pageViewHandler('razorpayTransfer')}
                        >
                            Razorpay Transfer
                        </li>
                        : ""}
                </>
                : ""}

        </ul>
    </div>
)

export default SettingsNavOptions