import React, { useEffect, useState } from "react";
import List from "../../maketingimages/list.png";
import downloadFile from "../../maketingimages/downloadFile.png";
import communication from "../../maketingimages/communication.png";
import Filter from "../../maketingimages/filter.png";
import { getDeviationReport } from "../actioncreator";
import { formatDate, monthStartDate, reverseFormat, todayDate } from "../utils/utils";
const DeviationReport = () => {
  const months = [
    "january", "february", "march", "april",
    "may", "june", "july", "august",
    "september", "october", "november", "december"
  ];
  const [objectData, setObjectData] = useState('')
  const [pageNo, setPage] = useState(1)
  const [noOfEntry, setnoOfEntry] = useState(10)

  const [payload, setPayload] = useState({
    fromDate: monthStartDate(),
    toDate: todayDate(),
  });
  const getData = () => {
    getDeviationReport(payload, pageNo, noOfEntry, callback => {
      setObjectData(callback)
    })
  }
  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]:
        e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
    });
  };
  const submitFilter = () => {
    getData()
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="container-fluid marketing-main-css">
      <h3><strong>Deviation Report</strong></h3>
      <div className="row" style={{ marginTop: '20px' }}>
        <div className="col-sm-2 col-xs-12">
          <img src={Filter} width={20} height={20} alt="" />
          <span className="ms-3 filterHeaders">Filter</span>
          <select
            name=""
            id=""
            // value={payload.filter}
            className="form-select"
            style={{ width: '100%', padding: '7px' }}
          >
            <option value="daily">Daily</option>
          </select>
        </div>
        <div style={{ position: 'absolute', marginLeft: '16.5%', marginTop: '30px' }}>::</div>
        <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
          From
          <input
            type="date"
            id="startDate"
            placeholder="DD/MM/YY"
            name="fromDate"
            value={reverseFormat(payload.fromDate)}
            onChange={handleChange}
            className=" form-control"
            style={{
              border: "1px solid gray",
              borderRadius: "6px",
              padding: "7px",
            }}
          />

        </div>
        <div style={{ position: 'absolute', marginLeft: '33%', marginTop: '30px' }}>-</div>

        <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
          <div className="filterHeaders" >to</div>
          <input
            type="date"
            id="endDate"
            name="toDate"
            value={reverseFormat(payload.toDate)}
            onChange={handleChange}
            placeholder="DD/MM/YY"
            className=" form-control"
            style={{
              border: "1px solid gray",
              borderRadius: "6px",
              padding: "10px",
            }}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <button
            onClick={() => submitFilter()}
            className="btn btn-primary"
            style={{
              color: "#2B78FF",
              borderColor: "#2B78FF",
              backgroundColor: "white",
              marginTop: '27px',
              padding: '5px',
              borderRadius: '5px'

            }}
          >
            Apply Filter
          </button>
        </div>

      </div>
      <hr />
      <div
        className="mt-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="d-inline-flex" style={{ display: 'inline-flex' }}>
          <img src={List} alt="" width={18} height={18} style={{ marginTop: '10px' }} />{" "}
          <h4 className="ms-3 leadBreakDown_Header">
            &nbsp;&nbsp;Monthly stats of Budget Planned & Spent on each month
          </h4>
        </div>
    
      </div>
      <div className='credit-table'>
        <table>
          <thead style={{ background: '#b9d8f9', color: '#000' }}>
            <tr className="thead-class">
              <th>Aggregator</th>
              <th>Budget Divided</th>
              {months.map(month => (
                <th key={month} style={{textTransform:'uppercase'}}>{month}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {objectData !== '' ? objectData.map(item => (

              <tr key={item.partnerId}>

                <td>{item.aggregatorName}</td>
                <td style={{ padding: '0px' }} ><div style={{ background: '#4CC78C', padding: '10px', color: '#fff',fontWeight:'700' }}>Spent</div> <div style={{ background: '#2B78FF', padding: '10px', color: '#fff',fontWeight:'700' }}>Planned </div></td>
                {months.map(month => (
                  <td key={month}>
                    {item.data.find(d => d.month.trim() === month)['spentBudget']}
                    <br />
                    <br />
                    {item.data.find(d => d.month.trim() === month)['plannedBudget']}
                  </td>
                ))}
              </tr>
            )) : ""}
          </tbody>
        </table>
      </div>


    </div >

  );
}

export default DeviationReport;
