import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket: NACH Bounced

const Razormail = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>Hope this mail finds you in best of health and spirits.</div>
        <br />
        <div>In order to ensure paperless and hassle-free procedure BorrowBuddy brings to you e-Sign and e-Mandate feature where e-Sign feature will help you e-sign your Loan Agreement via Aadhaar OTP and e-mandate registration via net banking will automate your EMI repayments.</div>
        <br />
        <div>  Currently your bank is not live with Razorpay's e-mandate facility so the only way we can proceed forward if you agree to courier signed loan agreement, security cheque and NACH mandate form to our Delhi Head Office.</div>
        <br />
        <div>Please acknowledge this mail so that we can proceed for loan processing.</div>
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default Razormail