import React, { Component } from 'react'
import Configuration from '../../../presentationals/Admin/Configuration/config.component'



class Config extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MSG: ""


        }

    }

    render() {
        const { admin } = this.props
        return (
            <div>
                <Configuration
                    sendHandler={this.sendHandler.bind(this)}
                    MSG={this.state.MSG}
                />


            </div>
        )
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ MSG: false });
        }.bind(this), 3000)
    }
    sendHandler() {
        {
            this.setState({ MSG: "saved successfully" })
        }
        this.removePopup();
    }
}

export default Config;