import React, { Component } from 'react'
import TransDetails from '../../../../presentationals/Admin/CustomerPages/Transaction.component'
import { _preFormatDate } from '../../../../../utils/validation';
import { APIS } from '../../../../../utils/api-factory';
import { fetchTransaction } from '../../AdminActionCreator.component';
import UpdateTransaction from '../../CustomerPages/EditTransactionPopup.container'
import Popup from '../../../../presentationals/Popup/Popup.component';
import { getTransactionCards } from '../../AdminActionCreator.component'
import ConfirmationPopup from '../../../../presentationals/Popup/ConfirmationPopup.component'

class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopupState: false,
            confirmationText: '',
            popupState: false,
            popupStatus: '',
            trnsDateState: '',
            details: '',
            updatePopupState: false,
            transactionIdUpdate: '',
            transDetail: {
                trnsIdState: '',
                trnsDateState: '',
                trnsModeState: '',
                trnsAmountState: '',
                
            }
        }
    }

    render() {
        const { allDetail, userDetail } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <TransDetails
                    editTransHandler={this.editTransHandler.bind(this)}
                    saveTransHandler={this.saveTransHandler.bind(this)}
                    transDetail={this.state.transDetail}
                    trnsDateState={this.state.trnsDateState}
                    details={this.state.details}
                    openPopup={this.openPopup.bind(this)}
                    DeleteTransaction={this.DeleteTransaction.bind(this)}
                />
                {this.state.updatePopupState == true ?
                    <UpdateTransaction
                        getPopupState={this.getPopupState.bind(this)}
                        transactionIdUpdate={this.state.transactionIdUpdate}
                        userDetail={userDetail}
                        getConfirmPopupState={this.getConfirmPopupState.bind(this)}
                    />
                    : ""}
                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
            </div>
        )
    }
    sendHandler(type) {
        this.setState({
            pageViewState: type,
        })
    }

    DeleteTransaction(detail) {
        this.setState({
            confirmationText: "Are You Sure To Delete this Transaction",
            confirmationPopupState: true,
            transactionDataForDelete: detail
        })
    }

    cancelLoanConfirm(confirmType) {
        const { allDetail, userDetail, admin } = this.props
        if (confirmType == 'yes') {
            fetch(APIS.DELETE_TRANSACTION + this.state.transactionDataForDelete.transactionId + '&loanId=' + allDetail.loanId + '&empId=' + admin.emailId)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        getTransactionCards(userDetail.userId,
                            (callBack) => {
                                this.setState({
                                    details: callBack,
                                    confirmationPopupState: false,
                                    popupState: true,
                                    popupStatus: 'Transaction Deleted Successfully',
                                },()=>this.props.getData('yes'))
                                this.removePopup();
                            })
                    }
                })
        } else if (confirmType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }

    componentWillMount() {
        const { allDetail, userDetail } = this.props
        getTransactionCards(userDetail.userId,
            (callBack) => {
                this.setState({
                    details: callBack
                })
            })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    getConfirmPopupState(state, msg) {
        this.setState({
            popupState: state,
            popupStatus: msg,
        })
        this.removePopup();
    }

    openPopup(e, transactionId) {
        this.setState({
            updatePopupState: true,
            transactionIdUpdate: transactionId
        })
    }

    getPopupState(state, details) {
        this.setState({
            updatePopupState: state,
            details: details
        })
    }

    editTransHandler(e, transType) {
        let transDetail = Object.assign({}, this.state.transDetail);
        if (transType == 'trnsId') {
            transDetail.trnsIdState = e.target.value
        } else if (transType == 'trnsDate') {
            transDetail.trnsDateState = _preFormatDate(e);
            this.setState({ trnsDateState: e })
        } else if (transType == 'trnsMode') {
            transDetail.trnsModeState = e.target.value
        } else if (transType == 'trnsAmount') {
            transDetail.trnsAmountState = e.target.value
        }
        this.setState({
            transDetail
        })
    }
    saveTransHandler() {
        const { userDetail, admin } = this.props
        fetchTransaction(userDetail.userId, userDetail.loanId, this.state.transDetail.trnsIdState, this.state.transDetail.trnsAmountState, this.state.transDetail.trnsDateState, this.state.transDetail.trnsModeState, admin,
            (callBack) => {
                this.setState({
                    details: [...this.state.details, callBack],
                    popupState: true,
                    popupStatus: 'Transaction Details save successfully',
                },()=>this.props.getData('yes'))
                this.removePopup()
            });
    }
}

export default Transaction;