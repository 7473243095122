import React from 'react';
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const installedApp = ({ getFilterValue, FilteredData, getPageValue, pageIndexToShow,
    refreshpage, backpage, forwordpage, installedAppData }) => (
    <div className="container-fluid">
        <div className="row form-group">
            <div className="col-xs-6 text-left">
                <h3 className="text-center"> Installed App</h3>
            </div>
            <div className="col-xs-6 text-right">
                <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh fa-spin" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button>
                {/* <p>1-100</p> */}
            </div>
        </div>
        <div className="row text-center">
            <div className="col-xs-12 overflow-x-auto no-padding">

                {installedAppData != null && installedAppData != '' && installedAppData.length > 0 ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <td>Date</td>
                                    <td>Email ID</td>

                                </tr>

                            </thead>
                            <tbody>
                                {installedAppData && installedAppData.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td> {data.date}</td>
                                            <td> {data.emailList != null && data.emailList != "" ? data.emailList.slice(1, -1) : ""}</td>


                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : ""}
            </div>
        </div>
    </div>
)

export default installedApp;
