
import React from 'react'
import newApplication from '../../../../images/rewampimages/Frame 13.png';
import repeatApplication from '../../../../images/rewampimages/Frame 13 (1).png';
import assignedtoCS from '../../../../images/rewampimages/Frame 13 (2).png';
import assignedtoCF from '../../../../images/rewampimages/Frame 13 (3).png';
import assignedtooperation from '../../../../images/rewampimages/Frame 13 (4).png';
import analysis_chart from '../../../../images/rewampimages/analysis-chart.png';
import Ops from '../../../../images/rewampimages/Vector (26).png';
import ReactApexChart from 'react-apexcharts';
import { ROLE } from '../../../../utils/Constant';



const DashboardUi = ({ options, mainId, tabledata, emailIdToShow, downloadBydepartmentFunction, datadownload, departmentToSend, admin, todaysApplicationNo, userStatusData, performanceData, leadDashFilterData, filterDataChange, filterDataHandler, performanceLoader,
    stateWiseData, agentWiseData, statusWiseData, errorMsg, todayData, agentStatusWiseData, graphState, statusWiseReportingHandler, ReportViewState }) => (
    <>
        <div className='container-fluid lm-dashboard'>
            <div className='row' style={{ marginLeft: '-7px' }}>
                {admin.rolelist != undefined ? (admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0) ?
                    <div className="col-xs-12 col-sm-3">
                        <label className="font-12px">Select Department</label>
                        <select className="form-control" value={departmentToSend} onChange={e => statusWiseReportingHandler(e)} >
                            <option value=''>Select Department</option>
                            {/* <option value='all'>All</option> */}
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 ?
                                <option value='Customer Service'>Customer Service</option>
                                : "" : ""}
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 ?

                                <option value='Finance / Credit'>Finance / Credit</option>
                                : "" : ""}
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0 ?
                                <option value='Legal'>Legal</option>
                                : "" : ""}


                        </select>
                    </div>
                    : "" : ""}
                <div className='col-sm-2 col-xs-2'>
                    <label className="font-12px">From</label>
                    <input onChange={(e) => filterDataChange(e, "startDate")} value={leadDashFilterData.startDateToShow} className='date-input-lm' type='date' style={{ background: '#fff' }} />
                </div>
                <div className='col-sm-2 col-xs-2'>
                    <label className="font-12px"> To</label>

                    <input onChange={(e) => filterDataChange(e, "endDate")} value={leadDashFilterData.endDateToShow} className='date-input-lm' type='date' style={{ background: '#fff' }} />
                </div>
                <div className="col-xs-12 col-sm-1">
                    <button className="reset-btn" style={{ padding: '10px', marginTop: '22px' }} onClick={e => filterDataHandler()}>
                        Filter
                    </button>
                </div>
            </div>
            {todayData ?
                <ul className='lm-card-main lm-card'>
                    <li><h5>New Application <img src={newApplication} width={'35px'} style={{ float: 'right', marginTop: '-10px' }} /></h5>
                        <h2><b>{todaysApplicationNo.newUserCount}</b></h2>
                    </li>
                    <li><h5>Repeat Application <img src={repeatApplication} width={'35px'} style={{ float: 'right', marginTop: '-10px' }} /></h5>
                        <h2><b>{todaysApplicationNo.repeatUserCount}</b></h2>
                    </li>
                    <li><h5>Assigned to<br />
                        Credit/Finance  <img src={assignedtoCS} width={'35px'} style={{ float: 'right', marginTop: '-25px' }} /></h5>
                        {todayData && todayData != "" ? <h2><b>{todayData['Finance / Credit']}</b></h2> : ""}</li>
                    <li><h5>Assigned to <br />
                        Customer Service  <img src={assignedtoCF} width={'35px'} style={{ float: 'right', marginTop: '-25px' }} /></h5>
                        {todayData && todayData != "" ? <h2><b>{todayData['Customer Service']}</b></h2> : ""}</li>


                    <li><h5>Assigned to <br />
                        Operations  <img src={assignedtooperation} width={'35px'} style={{ float: 'right', marginTop: '-25px' }} /></h5>
                        <h2><b>{todayData.Legal}</b></h2>
                    </li>
                </ul>
                : ""}
            <div className='lm-second-card'>

                <div className='row'>
                    <div className='col-sm-5 col-xs-12'>
                        <h5 style={{ marginLeft: '10px' }}><b><img src={analysis_chart} width={'20px'} />&nbsp;&nbsp;Operation Analysis</b></h5>
                        <div className='' style={{ marginTop: '40px' }}>
                            <ReactApexChart options={options} series={options.series} type="bar" height={350} width="100%" />
                        </div>
                    </div>
                    <div className='col-sm-7 col-xs-12'>
                        <h5 style={{}}><b><img src={Ops} width={'20x'} />&nbsp;&nbsp;Operation Analysis Table</b></h5>
                        <div style={{ overflow: 'scroll' }}>
                            {performanceData && performanceData !== '' && performanceData !== undefined && performanceData !== null && performanceData['Customer Service'] !== undefined ?

                                <table className="lm-dashboard tableFirst" >
                                    <thead>
                                        <tr>
                                            <th>Emplyee Id</th>
                                            <th style={{ padding: '11px' }}>Total Assigned</th>
                                            <th style={{ padding: '11px' }}>Mark As Complete</th>
                                            <th style={{ padding: '11px' }}>Communicated</th>
                                            <th style={{ padding: '11px' }}>Pending</th>
                                            <th style={{ padding: '11px' }}>Approved</th>
                                            <th style={{ padding: '11px' }}>Expired</th>
                                            <th style={{ padding: '11px' }}>Rejected</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {performanceData && performanceData !== '' && performanceData !== undefined && performanceData !== null && performanceData['Customer Service'] !== undefined && Object.entries(performanceData['Customer Service']).map((newdata, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td style={{ padding: '11px' }}>{newdata[0]}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].totalAssignmentDone}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].markAsComplete}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].communicated}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].totalPending}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].approved}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].expired}</td>

                                                    <td style={{ padding: '11px' }}>{newdata[1].rejected}</td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </table>
                                : ""}
                            {performanceData && performanceData !== '' && performanceData !== undefined && performanceData !== null && performanceData['Finance / Credit'] !== undefined ?

                                <table className="lm-dashboard tableFirst" >
                                    <thead>
                                        <tr>
                                            <th>Emplyee Id</th>
                                            <th style={{ padding: '11px' }}>Total Assigned</th>
                                            <th style={{ padding: '11px' }}>Approved</th>
                                            <th style={{ padding: '11px' }}>Tentative Approval</th>

                                            <th style={{ padding: '11px' }}>Rejected</th>
                                            <th style={{ padding: '11px' }}>Expired</th>
                                            <th style={{ padding: '11px' }}>Pending</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {performanceData && performanceData !== '' && performanceData !== undefined && performanceData !== null && performanceData['Finance / Credit'] !== undefined && Object.entries(performanceData['Finance / Credit']).map((newdata, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td style={{ padding: '11px' }}>{newdata[0]}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].totalAssignmentDone}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].approved}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].approvedTentative}</td>

                                                    <td style={{ padding: '11px' }}>{newdata[1].rejected}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].expired}</td>

                                                    <td style={{ padding: '11px' }}>{newdata[1].totalPending}</td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </table>
                                : ""}
                            {performanceData && performanceData !== '' && performanceData !== undefined && performanceData !== null && performanceData['Legal'] !== undefined ?

                                <table className="lm-dashboard tableFirst" >
                                    <thead>
                                        <tr>
                                            <th>Emplyee Id</th>
                                            <th style={{ padding: '11px' }}>Total Assigned</th>
                                            <th style={{ padding: '11px' }}>Mark RFD</th>
                                            <th style={{ padding: '11px' }}>Expired</th>
                                            <th style={{ padding: '11px' }}>Pending</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {performanceData && performanceData !== '' && performanceData !== undefined && performanceData !== null && performanceData['Legal'] !== undefined && Object.entries(performanceData['Legal']).map((newdata, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td style={{ padding: '11px' }}>{newdata[0]}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].totalAssignmentDone}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].markAsRfd}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].expired}</td>
                                                    <td style={{ padding: '11px' }}>{newdata[1].totalPending}</td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </table>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
export default DashboardUi
