import React from 'react';
import DotLoader from '../../Loader/layerLoader.component'
import DatePicker from 'react-datepicker';

const PhocketCustomerDetails = ({ creditVidyaData, loadarState, paginationHandler, refreshLeads,
    phocketTablePagination, tableMinPagination, tableMaxPagination, userDetailHandler, dataFilter, searchByemail, searchBymobile,searchByDate,searchByDateShow }) => (
        <div className="container-fluid">
            <div className="row">
                </div>
            <div className="row text-center">
            <div className="col-xs-12 btn-group form-group">
                <button className="pull-right btn-bg-new" onClick={e => refreshLeads()}><i className="fa fa-refresh fa-spin"/>&nbsp;&nbsp;Refresh</button>
            </div>
                <div className="col-xs-12 no-padding">
                    {loadarState == false ? creditVidyaData != null && creditVidyaData != '' && creditVidyaData.length > 0 ?
                        <div>
                            <div className="overflow-x-auto">
                                <table className="phocket-table">
                                    <thead>
                                        <tr>
                                            <th>Info</th>
                                            <th>Status</th>
                                            <th>Credit Vidya Status</th>
                                            <th>Name</th>
                                            <th>Mobile No.</th>
                                            <th>Email Id</th>
                                            <th>Company</th>
                                            <th>Salary</th>
                                            <th>Designation</th>
                                            <th>No of employee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {creditVidyaData.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <button className="background-none-btn" onClick={e => userDetailHandler(data)}><span className="fa fa-info" /></button>
                                                    </td>

                                                    <td>{data.loanStatus}</td>
                                                    <td>{data.applicationStatus}</td>
                                                    <td>{data.firstName + ' ' + data.lastName}</td>
                                                    <td>{data.mobileNumber}</td>
                                                    <td>{data.emailId}</td>
                                                    <td>{data.employmentDetailsPO.organizationName}</td>
                                                    <td>{data.employmentDetailsPO.netTakeHomeSalary}</td>
                                                    <td>{data.employmentDetailsPO.designation}</td>
                                                    <td>{data.employmentDetailsPO.noOfEmployees}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                                    <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(creditVidyaData, 'back')}></button></div>
                                    <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + creditVidyaData.length}</div>
                                    <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(creditVidyaData, 'forward')}></button></div>
                                    <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
                            </div>
                        </div>
                        : null : <DotLoader />}
                </div>
            </div>
        </div>
    )

export default PhocketCustomerDetails;
