import React from 'react'

const Cards = ({ changeHandler, nameFile, sumbit, staffHandler,isStaff }) => (
    <div className='container-fluid'>
        <div className='col-sm-3 col-xs-12'></div>
        <div className='col-sm-6 col-xs-12'>
            <h3 className='text-center blue-text'>Upload File of Users</h3>
            <div className='row'>
                <div className='col-sm-4 col-xs-12'></div>
                <div className='col-sm-4 col-xs-12'>
                    <label>IS Staff</label>
                    <select value={isStaff} className='form-control' onChange={(e) => staffHandler(e)}>
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>

                    </select>
                </div>
                {/* <div className='col-sm-4 col-xs-12'></div> */}
            </div>
            <div className="image-upload-wrap">
                <input
                    accept='.csv'

                    className="file-upload-input" type='file' onChange={(e) => changeHandler(e)} />
                <div className="drag-text">
                    <h5> {nameFile !== '' ? nameFile : 'Drag & drop or select CSV File'} </h5>
                </div>
            </div>
            <div className='text-center'>
                <button className='profile-save-btn' onClick={() => sumbit()}> Submit</button>
            </div>
        </div>
        <div className='col-sm-3 col-xs-12'></div>

    </div>
)

export default Cards