import React, { Component } from 'react'
import OBRUI from '../../../presentationals/Admin/IVR/OBR.component';
import { APIS } from '../../../../utils/api-factory'
import { getUserDetailApiAdmin } from '../AdminActionCreator.component'
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { _formatDateInYMD } from '../../../../utils/validation'
import moment from 'moment'
import Popup from '../../../presentationals/Popup/Popup.component';

class OBR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OBDData: '',
            tableKey: 'Missed',
            FilteredData: [],
            pageIndexToShow: 0,
            menuExtensionResponse: '',
            actionButtonState: false,
            userState: true,
            viewState: false,
            allDetail: null,
            userDetail: null,
            searchByDateShow: '',
            popupState: false,
            popupStatus: '',
            searchByDate: moment(new Date()).format('YYYY-MM-DD')
        }

    }

    componentWillMount() {
        fetch(APIS.GET_OBD_DETAILS)
            .then(res => res.json())
            .then(json => {
                this.setState({ OBDData: json })
            })
       
    }


    render() {
        const { admin } = this.props;
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.userState == true ?
                    <OBRUI
                        OBDData={this.state.OBDData}
                        tableKeyHandler={this.tableKeyHandler.bind(this)}
                        tableKey={this.state.tableKey}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        menuExtensionResponse={this.state.menuExtensionResponse}
                        getDataByNumber={this.getDataByNumber.bind(this)}
                        dateFilter={this.dateFilter.bind(this)}
                        searchByDateShow={this.state.searchByDateShow}
                    />
                    : null}

                {this.state.viewState == true ?
                    <UserDetail
                        allDetail={this.state.allDetail}
                        userDetail={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                    />
                    : null}
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    backHandler() {
        this.setState({
            userState: true,
            viewState: false,
        })
    }

    getDataByNumber(allDetail) {
        if (allDetail.userID != '' && allDetail.userID != null && allDetail.userID != undefined) {
            getUserDetailApiAdmin(allDetail.userID,
                (callBack) => {
                    this.setState({
                        userState: false,
                        viewState: true,
                        allDetail: callBack,
                        userDetail: allDetail
                    })
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'User not registered',
            })
            this.removePopup();
        }
    }

    dateFilter(e) {
        this.setState({
            searchByDateShow: e,
            searchByDate: _formatDateInYMD(e)
        })
        fetch(APIS.GET_IBD_DETAILS + _formatDateInYMD(e))
            .then(res => res.json())
            .then(json => {
                this.setState({ OBDData: json })
            })
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    tableKeyHandler(e) {
        this.setState({ tableKey: e.target.value })
    }
}

export default OBR;