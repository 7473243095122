import React, { useState } from "react";
import { useEffect } from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { nbfcListNew } from "../../AdminActionCreator.component";
import AddEditLendor from "./AddEditLoanUtility";
import { Pagination } from "@mui/material";
import Nodata from '../../../../../images/nodata.png'
const Projections = ({ }) => {

    const [openAccordion, setOpenAccordion] = useState(null);
    const [dayWiseApiData, setdayWiseData] = useState('');
    const [apiData, setApiData] = useState([]);
    const [editMode, setEditMode] = useState('tableView')
    const [editData, setEditData] = useState({})
    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };
    const handlebtnclick = (type, data) => {
        if (type == 'add' || type == 'edit') {
            setEditMode('Editable')
            setEditData(data)
        } else if (type == 'delete') {
            setconfirmPopup(true)
            setDeleteData(data)
        }
    }
    const handleSavebtn = () => {
        setEditMode('tableView')
        getData()
    }
    const dataFromAPI = [
        { month: 'Jan,2024', value: 1000, achievement: '800', achievementPercentage: '200' },
        { month: 'Feb,2024', value: 1000, achievement: '800', achievementPercentage: '200' },
        { month: 'Feb,2024 (Achieved)', value: '₹ 60,000', achievement: '₹ 60,000', achievementPercentage: '10' },

    ];
    const dataFromAPI2 = [
        { month: 'Jan,2024', value: 1000, achievement: 800, achievementPercentage: '+200%' },
        { month: 'Feb,2024', value: 1000, achievement: 800, achievementPercentage: '+200%' },
        { month: 'Feb,2024 (Achieved)', value: 1000, achievement: 800, achievementPercentage: '+200%' },

    ];
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        nbfcListNew(callback => {
            if (Array.isArray(callback) && callback.every(obj => isEmptyObject(obj))) {
                console.log("Array contains only empty objects. Not updating state.");
                setApiData([]);
            } else if(callback?.status == '404'){
                console.log("Array contains only empty objects. Not updating state.");
                setApiData([]);
            } else {
                setApiData(callback);
            }
        });
    };

    const buttonHandler = (data) => {
        getData(data)
        setfilterType(data)
    }

    const tableDatacheck = (data) => {
        if (data == null || data == undefined || data == '') {
            return '-'
        } else {
            return data
        }
    }


    return (
        <div className="projection-new">
            {
                editMode == 'Editable' ?
                    <div className="Executor">
                        <AddEditLendor editData={editData} handleSavebtn={handleSavebtn} />
                    </div>
                    : ''}
            <div className="container-fluid Projection" style={{ padding: '20px 50px',border:'none' }}>

                {
                    editMode == 'tableView' ?
                        <div className="accords openaccord" style={{ background: '#fff',border:'none' }}>
                            <div className="">
                                {apiData ? Object.entries(apiData).map((item, index) => (
                                    <AccordionItem
                                        key={index} // Using index as a unique key
                                        id={index} // Passing index as id
                                        isOpen={openAccordion === index}
                                        toggleAccordion={toggleAccordion}
                                        item={item} // Pass the entire item object as prop
                                        dayWiseApiData={apiData}
                                        tableDatacheck={tableDatacheck}
                                        handlebtnclick={handlebtnclick}

                                    />
                                )) : ""}

                            </div>

                        </div>
                        : ""}
            </div>


        </div>
    )
}
export default Projections

function AccordionItem({ id, isOpen, toggleAccordion, dayWiseApiData, item, tableDatacheck, handlebtnclick }) {
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; 

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows =item && item[1]?.length > 0 ? item[1]?.slice(indexOfFirstRow, indexOfLastRow) || [] : [];

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div className={`accords accordsub ${isOpen ? 'openaccord' : ''}`}>
            <div className="heading table-responsive">
                <div className="box" onClick={() => toggleAccordion(id)} style={{ backgroundColor: isOpen ? 'black' : 'white' }}>
                    <h5 style={{ color: isOpen ? 'white' : 'black' }}>
                        {isOpen ? <ArrowUpward /> : <ArrowDownward />}
                    </h5>
                </div>
                <table className="table text-center tableheader" style={{ cursor: 'pointer' }} onClick={() => toggleAccordion(id)}>
                    <tbody>
                        <tr>
                            <td className='td1 subtd' style={{ width: '100px', textAlign: 'left', border: 'none' }}>NBFC Name</td>
                            <td><b>{item[0]}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {isOpen && (
                <div className="bottomtable">
                    <div className={`subbottom table-responsive ${isOpen ? 'scrollanimation' : ''}`}>
                        {currentRows.length>0 ? (
                            <div className="tableedit">
                                <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px', marginBottom: '30px' }}>
                                    <table style={{ marginBottom: '10px' }}>
                                        <thead>
                                            <tr>
                                             
                                                <th>ROI Reflect Date</th>
                                                <th>Stamp Duty Charge</th>
                                                <th>Penalty Interest</th>
                                                <th>Penalty Amount</th>
                                                <th>Normal Interest</th>
                                                <th>Min Amount</th>
                                                <th>Max Amount</th>
                                                <th>Tenure</th>
                                                <th>EMI Penalty Interest</th>
                                                <th>GST Interest</th>
                                     
                                                <th>Interest Type</th>
                                                {/* <th>GST Included</th> */}
                                            
                                                <th>Product Name</th>
                                                <th>Is Active</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRows.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{tableDatacheck(data.roiReflectDate.split(' ')[0])}</td>
                                                    <td>{tableDatacheck(data.stampDutyCharge)}</td>
                                                    <td>{tableDatacheck(data.penaltyInterest)}</td>
                                                    <td>{tableDatacheck(data.penaltyAmount)}</td>
                                                    <td>{tableDatacheck(data.normalInterest)}</td>
                                                    <td>{tableDatacheck(data.minAmount)}</td>
                                                    <td>{tableDatacheck(data.maxAmount)}</td>
                                                    <td>{tableDatacheck(data.tenure)}</td>
                                                    <td>{tableDatacheck(data.emiPenaltyInterest)}</td>
                                                    <td>{tableDatacheck(data.gstInterest)}</td>
                                             
                                                    <td>{tableDatacheck(data.interestType)}</td>
                                                    {/* <td>{data.gstIncluded ? 'Yes' : 'No'}</td> */}
                                                    <td>{tableDatacheck(data.productName)}</td>
                                                    <td>{data.active ? 'Yes' : 'No'}</td>
                                                    <td>
                                                        <div className="edits">
                                                            <i className="fa fa-pencil-square-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('edit', data)}></i>&nbsp;&nbsp;
                                                            <i className="fa fa-trash-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('delete', data)}></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                 
                                </div>
                                <Pagination
                                        count={Math.ceil(item[1].length / rowsPerPage)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                        shape="rounded"
                                        style={{ marginTop: '10px', display: 'flex', justifyContent: 'right',marginBottom:'10px' }}
                                    />
                            </div>
                        ) :<div className="text-center" style={{marginTop:'20px',marginBottom:'20px'}}><img width={'30%'} src={Nodata}/></div>}
                    </div>
                </div>
            )}
        </div>   );
}