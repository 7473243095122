import React, { Component } from 'react'
import { APIS } from '../../../../utils/api-factory'
import ActivityUi from '../../../presentationals/Admin/MailActivity/Activity.component'
import ActivityPopup from '../../../presentationals/Admin/MailActivity/ActivityMailPopup.component'
import { getActivityData } from '../AdminActionCreator.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class CRMActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginURL: '',
            loadarState: false,
            activityPopupState: false,
            activityPopupData: '',
            pageViewState: 'mail',
            activityLoaderState:false,
            activityData:[]
        }
    }

    componentWillMount() {
        const { activityData } = this.props
        this.setState({ loadarState: true })
        // this.getActivityData()
        fetch(APIS.ACTIVITY_LOGIN)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    loginURL: json.authUrl,
                })
            })
    }
// getActivityData(){
//     getActivityData(callback=>{
//         console.log(callback)
//         this.setState({
//             activityData:callback,
//             activityLoaderState:false
//         })
//     })
// }
    

    render() {
        const { activityData, activityLoaderState } = this.props
        const { admin } = this.props
        return (
            <div className="full-height">
                <ActivityUi
                    admin={admin}
                    activityLoaderState={this.state.activityLoaderState}
                    loginURL={this.state.loginURL}
                    activityData={this.state.activityData}
                    showMailPopup={this.showMailPopup.bind(this)}
                    sendHandler={this.sendHandler.bind(this)}
                    pageViewState={this.state.pageViewState}
                />
                {this.state.activityPopupState == true ?
                    <ActivityPopup
                        activityPopupViewHandler={this.activityPopupViewHandler.bind(this)}
                        activityPopupData={this.state.activityPopupData} />
                    : ""}
            </div>
        )
    }

    showMailPopup(e, data) {
        this.setState({
            activityPopupState: true,
            activityPopupData: data
        })
    }

    activityPopupViewHandler() {
        this.setState({ activityPopupState: false })
    }

    sendHandler(sendType) {
        if (sendType == 'mail') {
            document.getElementById("sendMail").classList.add('active')
            document.getElementById("sendActivity").classList.remove('active')
        } else if (sendType == 'activity') {
            document.getElementById("sendMail").classList.remove('active')
            document.getElementById("sendActivity").classList.add('active')
        }
        this.setState({ pageViewState: sendType })
    }
}
const mapStateToProps = ({ activityData }) => {
    return {
        activityData: activityData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getActivityData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMActivity);