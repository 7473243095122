import React, { Component } from 'react'
import GenerateReportUi from '../../../presentationals/Admin/DashboardAndReports/GenerateReportLogs.component'
import { APIS } from '../../../../utils/api-factory'
let paginationNumber=20
class ReportLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: '',
            tableFields: '',
            fieldPopup: false,
            FilteredData: [],
            pageIndexToShow: 0,
            subStatusPopup: false,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }
    }

    componentWillMount() {
        fetch(APIS.GENERATE_REPORT_LOGS)
            .then(res => res.json())
            .then(res => {
                this.setState({ reportData: res })
            })
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    render() {
        return (
            <GenerateReportUi
            phocketTablePagination={this.phocketTablePagination.bind(this)}
            tableMaxPagination={this.state.tableMaxPagination}
            tableMinPagination={this.state.tableMinPagination}
                showFieldsSubstatus={this.showFieldsSubstatus.bind(this)}
                reportData={this.state.reportData}
                showFields={this.showFields.bind(this)}
                tableFields={this.state.tableFields}
                closePopup={this.closePopup.bind(this)}
                fieldPopup={this.state.fieldPopup}
                subStatusPopup={this.state.subStatusPopup}
                getFilterValue={this.getFilterValue.bind(this)}
                FilteredData={this.state.FilteredData}
                getPageValue={this.getPageValue.bind(this)}
                pageIndexToShow={this.state.pageIndexToShow}
            />
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    showFields(e, fields) {
        this.setState({ tableFields: fields, fieldPopup: true })
    }
    showFieldsSubstatus(e, fields) {
        this.setState({ tableFields: fields, subStatusPopup: true })

    }

    closePopup() {
        this.setState({ fieldPopup: false, subStatusPopup: false })
    }
}

export default ReportLogs;