import React from 'react'

const AddAmbassador = ({ submit, inputHandler, viewPage, addressHandler, addressData, accountHandler, bankData }) => (
    <div className='container'>
        <h3 className='text-center'>Add Ambassador</h3>
        {viewPage === 'first' ?
            <div>
                <div className='row' style={{ marginTop: '100px' }}>
                    <div className='col-sm-3 col-xs-12'>
                        <label>Email Id</label>
                        <input placeholder='Email Id' className='form-control' onChange={(e) => inputHandler(e)} name='emailId' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>Mobile Number</label>
                        <input placeholder='Mobile Number' className='form-control' onChange={(e) => inputHandler(e)} name='mobileNumber' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>First Name</label>
                        <input placeholder='First Name' className='form-control' onChange={(e) => inputHandler(e)} name='firstName' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>Last Name</label>
                        <input placeholder='Last Name' className='form-control' onChange={(e) => inputHandler(e)} name='lastName' />
                    </div>
                </div>
                <div className='row text-center' style={{ marginTop: '50px' }}>
                    <button className='profile-save-btn' onClick={() => submit('firstPage')}>Continue</button>
                </div>
            </div>
            : ""}
        {viewPage === 'addressPage' ?
            <div >
                <div className='row' style={{ marginTop: '100px' }}>
                    <div className='col-sm-3 col-xs-12'>
                        <label>Address</label>
                        <input placeholder='Address' className='form-control' onChange={(e) => addressHandler(e)} name='address' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>Pincode</label>
                        <input placeholder='Pincode' className='form-control' onChange={(e) => addressHandler(e)} name='pinCode' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>State</label>
                        <input value={addressData.state} placeholder='State' className='form-control' onChange={(e) => addressHandler(e)} name='state' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>City</label>
                        <input value={addressData.city} placeholder='City' className='form-control' onChange={(e) => addressHandler(e)} name='city' />
                    </div>
                </div>
                <div className='row text-center' style={{ marginTop: '50px' }}>
                    <button className='profile-save-btn' onClick={() => submit('addressPage')}>Continue</button>
                </div>
            </div>
            : ""}
        {viewPage === 'bankPage' ?
            <div >

                <div className='row' style={{ marginTop: '100px' }}>
                    <div className='col-sm-3 col-xs-12'>
                        <label>IFSC</label>
                        <input style={{textTransform:'uppercase'}} placeholder='IFSC' className='form-control' onChange={(e) => accountHandler(e)} name='ifscCode' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>Branch</label>
                        <input value={bankData.bankBranch} placeholder='Branch' className='form-control' onChange={(e) => accountHandler(e)} name='bankBranch' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>Bank Name</label>
                        <input placeholder='Bank Name' value={bankData.bankName} className='form-control' onChange={(e) => accountHandler(e)} name='bankName' />
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                        <label>Name as on Bank Account</label>
                        <input placeholder='Name as on Bank Account' className='form-control' onChange={(e) => accountHandler(e)} name='nameAsBankAccount' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3 col-xs-12' style={{ marginTop: '10px' }}>
                        <label>Account Number</label>
                        <input placeholder='Account Number' className='form-control' onChange={(e) => accountHandler(e)} name='accountNumber' />
                    </div>
                </div>

                <div className='row text-center' style={{ marginTop: '50px' }}>
                    <button className='profile-save-btn' onClick={() => submit('bankPage')}>Continue</button>
                </div>
            </div>
            : ""}
        {viewPage === 'successPage' ?
            <div >

                <div className='row' style={{ marginTop: '150px' }}>
                    <h3 className='text-center' style={{ color: 'green' }}>Ambassador Added Successfully !</h3>
                </div>
            </div>
            : ""}
    </div>
)
export default AddAmbassador