import React from 'react'
import Ui from '../../../presentationals/CardsUtility/configCards.component'

class ConfigCards extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    render(){
        return(
            <div>
                <Ui/>
            </div>
        )
    }
}
export default ConfigCards