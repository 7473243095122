import React from 'react'

const MessagePage = ({ sendHandler, messageHandler, createLink, commentHandler,
    uploadNotificationImage, notificationImage ,comment}) => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-5 col-xs-12">
                    <h4 className="text-center blue-text"><strong>Comment</strong></h4>
                    {/* <div className='row'> */}
                    {/* <div className='col-sm-5'>
                    <label>Select categorization</label> */}
                    {/* <select className="form-control" placeholder='' onChange={e => commentHandler(e,'select')}>
                        <option value=''>Select categorization</option>
                        <option value='Response'>Response</option>
                        <option value='No Response'>No Response</option>
                        <option value='Non Contactable'>Non Contactable</option>
                    </select> */}
                    {/* </div> */}
                    {/* <div className='col-sm-7'>
                    <label>Comment</label> */}

                    <textarea value={comment} className="form-control white-bg-transparent" rows="12" placeholder="Comment..." onChange={e => commentHandler(e,'text')} />
                    <button className="profile-save-btn center-block" id="submitDetail" onClick={e => sendHandler('comment')}><span className="fa fa-save" />&nbsp;&nbsp;Save</button>
                   {/* </div> */}
                    {/* </div> */}
                </div>
                <div className="col-sm-7 col-xs-12">
                    <div className="col-xs-12">
                        <h4 className="text-center blue-text"><strong>Send Notification</strong></h4>
                    </div>
                    <div className="col-xs-12 form-group">
                        <input type="text" className="form-control white-bg-transparent" placeholder="Enter Title" onChange={e => messageHandler(e, "title")} />
                    </div>
                    <div className="col-xs-12 form-group">
                        <div className="form-control contentEditable" contentEditable="true" id="appNotification"></div>
                        {/* <textarea className="form-control white-bg-transparent" rows="5" placeholder="Message..." onChange={e => messageHandler(e, "message")} /> */}
                    </div>
                    <div className="row margin-0">
                        <div className="col-xs-12 form-group display-flex">
                            <input type="text" className="form-control white-bg-transparent" placeholder="Link URL" onChange={e => messageHandler(e, "url")} />
                            <button className="profile-save-btn margin-0" id="submitDetail" onClick={e => createLink()}>
                                <span className="fa fa-link"></span>
                            </button>
                        </div>
                        <div className="col-xs-12">
                            <h6 className="margin-0">Upload Image</h6>
                            <div className="display-flex">
                                <input type="text" readOnly value={notificationImage} id="uploadFile" className="form-control height-42px" />
                                <div className="fileUpload btn btn--browse">
                                    <span>Browse</span>
                                    <input accept=".png, .jpg, .jpeg" id="uploadBtn" type="file" className="upload" onChange={e => uploadNotificationImage(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 text-center">
                            <button className="profile-save-btn margin-10px" id="submitDetail" onClick={e => sendHandler('notification')}>
                                <span className="fa fa-send-o"></span>&nbsp;&nbsp;Send
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

export default MessagePage;