import React, { Component } from 'react';
import DashboardAdmin from '../../presentationals/Campaigntrack/CampaignTrackAdminDashboard.Component';
import { getThirdPartyData } from './ThirdPartyActionCreater.component'
import { _formatDateInDash } from '../../../utils/validation'
import { APIS } from '../../../utils/api-factory';
import moment from 'moment'
import Dotloader from '../../presentationals/Loader/DotLoader.component';

export default class DownLoan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loaderstate: false,
            urlType: null,
            graphdata: [],
            leadDashFilterData: {
                startDateToShow: '',
                startDate: null,
                endDateToShow: '',
                endDate: null
            },
            generateReportState: true,
            dateFilterType: null,
            allCampiainName: "",
            getallState: "",
            errorMsg: "",


        }

    }
    componentDidMount() {
        var today = new Date().getHours();
        if (today > 19) {
            console.log(today, 'Hour')
        }
        if (today < 9) {
            console.log(today, 'Hours')

        }
        if (today > 9 && today < 19) {
            this.setState({ generateReportState: false })
        }
    }

    render() {
        return (
            <div>
                <DashboardAdmin
                    generateReportState={this.state.generateReportState}
                    getexceldata={this.getexceldata.bind(this)}
                    graphdata={this.state.graphdata}
                    errorMsg={this.state.errorMsg}
                    loadarState={this.state.loadarState}
                    filterDataChange={this.filterDataChange.bind(this)}
                    leadDashFilterData={this.state.leadDashFilterData}
                    allCampiainName={this.state.allCampiainName}
                    filterDataHandler={this.filterDataHandler.bind(this)}
                    getallState={this.state.getallState}
                    refreshpage={this.refreshpage.bind(this)}
                />

            </div>
        )
    }
    filterDataChange(e, type) {
        let leadDashFilterData = Object.assign({}, this.state.leadDashFilterData)
        if (type == 'fromDate') {
            leadDashFilterData.startDateToShow = e
            leadDashFilterData.startDate = _formatDateInDash(e)
        } else if (type == 'tillDate') {
            leadDashFilterData.endDateToShow = e
            leadDashFilterData.endDate = _formatDateInDash(e)
        } else if (type == "campainUrl") {
            this.setState({ urlType: e.target.value })
        } else if (type == "statewise") {
            this.setState({ dateFilterType: e.target.value })
        }

        this.setState({ leadDashFilterData, errorMsg: '' })
    }

    filterDataHandler() {
        this.setState({ loadarState: true })
        this.getCampaignTrack(this.state.leadDashFilterData.startDate, this.state.leadDashFilterData.endDate)

    }

    componentWillMount() {

        this.getCampaignName()
        this.getCampaignTrack(this.state.leadDashFilterData.startDate, this.state.leadDashFilterData.endDate)
        this.getallState()
    }

    getCampaignTrack(startDate, endDate) {
        this.setState({
            loadarState: true
        });
        this.setState({ graphdata: [] })
        fetch(APIS.GET_CAMPAIGN_TRACK + this.state.urlType + "/" + this.state.dateFilterType + "/" + startDate + "/" + endDate)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    loadarState: false
                });
                let arrayKey = Object.keys(res);
                let arrayvalue = Object.values(res)
                for (var i = 0; i < arrayKey.length; i++) {
                    this.state.graphdata.push({ 'name': arrayKey[i], 'value': parseInt(arrayvalue[i]) })
                }
                this.setState({ loadarState: false })
                this.setState({ graphdata: this.state.graphdata })
            })
    }
    getCampaignName() {
        fetch(APIS.GET_ALL_CAMPAIN_NAME)
            .then(res => res.json())
            .then(res => {
                this.setState({ allCampiainName: res })

            })
    }
    getallState() {
        fetch(APIS.GET_ALL_STATE)
            .then(res => res.json())
            .then(res => {

                this.setState({ getallState: res })

            })
    }
    getexceldata() {
        if (this.state.urlType != null) {
            window.open(APIS.GET_EXCEL_DATA_URL + this.state.urlType)
        } else {
            this.setState({ errorMsg: "Select Company" })
        }
        // fetch(APIS.GET_EXCEL_DATA_URL)
        // .then(res =>res.json())
        // .then( res=>{

        //     this.setState({ getexceldata: res})
        // })
    }

    refreshpage() {
        let leadDashFilterData = Object.assign({}, this.state.leadDashFilterData)
        leadDashFilterData.startDateToShow = ''
        leadDashFilterData.startDate = null
        leadDashFilterData.endDateToShow = ''
        leadDashFilterData.endDate = null
        this.setState({
            leadDashFilterData
        });
        this.getCampaignTrack(null, null)

    }
}