import React, { useState } from "react";
import UploadedHistory from "./uplodedHistory";
import UsageHistory from "./usageHistory";

const StampMain = () => {
    const [viewPage, setViewpage] = useState('Uploaded History')
    const pageHandler = (type) => {
        setViewpage(type)
    }
    return (
        <div className="container-fluid">
            <div className="btn-group-tab " style={{ marginTop: '10px' }}>

                <div className='text-center'>
                    <button className={viewPage == 'Uploaded History' ? 'active' : ''} onClick={(e) => pageHandler('Uploaded History')}>Uploaded History</button>
                </div>
                <div className='text-center'>
                    <button className={viewPage == 'Usage Logs' ? 'active' : ''} onClick={(e) => pageHandler('Usage Logs')}>Usage Logs</button>
                </div>
            </div>
            {viewPage === 'Uploaded History' ?
                <UploadedHistory />
                : ""}
            {viewPage === 'Usage Logs' ?
                <UsageHistory />
                : ""}
        </div>
    )
}
export default StampMain