import React from "react";
import BackLogo from '../../../../images/rewampimages/Chevron.png'
import DashboardInactive from './imagesmarket/dashboard (2).png'
import DashboardActactive from './imagesmarket/dashboardActive.png'
import LiveReportInActive from './imagesmarket/crystal-reports-file (1).png'
import LiveReportActive from './imagesmarket/crystal-reports-file (2).png'
import AgreegatorInactive from './imagesmarket/user.png'
import AgreegatorActive from './imagesmarket/user (1).png'
import ReportInactive from './imagesmarket/report.png'
import ReportActive from './imagesmarket/report (1).png'
import BiActive from './imagesmarket/business-intelligence.png'
import BiInActive from './imagesmarket/business-intelligence (1).png'
import Dashbaord from '../newdesigns/overalldashboardnew'
import Aggregators from '../maketingComponents/screens/tabsagreegator'
import BuddyloanReport from '../../../containers/Admin/DashboardAndReports/BuddyLoanReport.container'
import BranchReport from '../../../containers/Admin/DashboardAndReports/BranchData.container'
import Bi from '../../../containers/BuisnessIntelligence/ApplicatonPipelineAnalysis'
import { useState } from "react";
import LeadParticulars from "./leadParticular";
import StatewiseData from "./statewise";
import RejectionStats from "./RejectReasonStats";
import AgestatusData from "./agestatus";
import SalaryRange from "./salaryRange";
import LoanStatusData from "./loanstatus";
import ConversionRatio from "./conversionRatio";
import DeviationReport from "./deviationchart";
import GenderStats from "./genderStats";
import EmiSats from "./emistats";
import BuisnessAggregator from "./buisnessagreegator";
import Image1Inactive from './imagesmarket/Group (9).png'
import Image1active from './imagesmarket/Group (10).png'
import Image2Inactive from './imagesmarket/stats (2).png'
import Image2active from './imagesmarket/stats (1).png'
import Image3Inactive from './imagesmarket/stats (3).png'
import Image3active from './imagesmarket/Vector (29).png'
import Image4Inactive from './imagesmarket/stats (4).png'
import Image4active from './imagesmarket/stats (5).png'
import Image5Inactive from './imagesmarket/range-slider.png'
import Image5active from './imagesmarket/range-slider (1).png'
import Image7Inactive from './imagesmarket/Vector (30).png'
import Image7active from './imagesmarket/statistic-down.png'
import Image8Inactive from './imagesmarket/business (2).png'
import Image8active from './imagesmarket/business (3).png'
import Formstatus from './formstatus'
import Projections from "./projections";
import ProjectionActive from '../maketingimages/prediction (1).png'
import ProjectionInActive from '../maketingimages/prediction (2).png'

const MainTabsMarketing = ({ admin }) => {
    const [page, setPage] = useState('dashboard')
    const [report, setreport] = useState('')
    const [liveReportTab, setLiveReportTab] = useState('')

    const pageHandler = (data) => {
        setPage(data)
        if (data === 'greport') {
            setreport('buddyloan')
        }
        if (data === 'liveReports') {
            setLiveReportTab('leadParticular')
        }
    }
    return (
        <>
            <div className={page !== 'liveReports' ? "marketing-main-css" : 'marketing-main-css'}>
                <div className="details-new fixed-top">
                    <div className="row details-new-tab-div" >

                        <div className="col-sm-12 col-xs-12" style={{ display: 'flex' }}>
                            <div className="btn-group-new" style={{ marginLeft: 'auto', marginRight: '20px' }}>
                                <button className={page === 'dashboard' ? 'active' : ''} onClick={(e) => pageHandler('dashboard')}><img src={page === 'dashboard' ? DashboardActactive : DashboardInactive} style={{ width: '27px' }} /><br />Dashboard</button>
                                <button className={page === 'liveReports' ? 'active' : ''} onClick={(e) => pageHandler('liveReports')}><img src={page === 'liveReports' ? LiveReportActive : LiveReportInActive} style={{ width: '19px' }} /><br />Live Reports</button>
                                <button className={page === 'projection' ? 'active' : ''} onClick={(e) => pageHandler('projection')}><img src={page === 'projection' ? ProjectionActive : ProjectionInActive} style={{ width: '27px' }} /><br />Projection</button>

                                <button className={page === 'aggregators' ? 'active' : ''} onClick={(e) => pageHandler('aggregators')}><img src={page === 'aggregators' ? AgreegatorActive : AgreegatorInactive} style={{ width: '27px' }} /><br />Aggregators</button>
                                <button className={page === 'greport' ? 'active' : ''} onClick={(e) => pageHandler('greport')}><img src={page === 'greport' ? ReportActive : ReportInactive} style={{ width: '27px' }} /><br />Generate Report</button>
                                {/* <button className={page === 'BI' ? 'active' : ''} onClick={(e) => pageHandler('BI')}><img src={page === 'BI' ? BiActive : BiInActive} style={{ width: '27px' }} /><br />BI</button> */}

                            </div>


                        </div>
                    </div>
                </div>
                {page === 'liveReports' ?
                    <div className="row fixed-top" style={{ marginTop: '76px', fontSize: '14px' }}>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('leadParticular')} style={{ cursor: 'pointer', background: liveReportTab === 'leadParticular' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'leadParticular' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'leadParticular' ? Image1active : Image1Inactive} width={'20px'} />
                            <br />
                            Lead <br />Particulars
                        </div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('stateWise')} style={{ cursor: 'pointer', background: liveReportTab === 'stateWise' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'stateWise' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'stateWise' ? Image2active : Image2Inactive} width={'20px'} />
                            <br />
                            State wise<br /> Stats</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('rejectReason')} style={{ cursor: 'pointer', background: liveReportTab === 'rejectReason' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'rejectReason' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'rejectReason' ? Image3active : Image3Inactive} width={'20px'} />
                            <br />
                            Reject Reason <br />Stats</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('genderStats')} style={{ cursor: 'pointer', background: liveReportTab === 'genderStats' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'genderStats' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'genderStats' ? Image4active : Image4Inactive} width={'20px'} />
                            <br />
                            Gender<br /> Stats</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('ageStats')} style={{ cursor: 'pointer', background: liveReportTab === 'ageStats' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'ageStats' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'ageStats' ? Image4active : Image4Inactive} width={'20px'} />
                            <br />
                            Age <br />Stats</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('salaryRange')} style={{ cursor: 'pointer', background: liveReportTab === 'salaryRange' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'salaryRange' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'salaryRange' ? Image5active : Image5Inactive} width={'20px'} />
                            <br />
                            Salary <br />Range</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('emiStats')} style={{ cursor: 'pointer', background: liveReportTab === 'emiStats' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'emiStats' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'emiStats' ? Image4active : Image4Inactive} width={'20px'} />
                            <br />
                            EMI<br /> Stats</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('loanStatus')} style={{ cursor: 'pointer', background: liveReportTab === 'loanStatus' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'loanStatus' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'loanStatus' ? Image4active : Image4Inactive} width={'20px'} />
                            <br />
                            Loan Status <br />Stats</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('formStatus')} style={{ cursor: 'pointer', background: liveReportTab === 'formStatus' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'formStatus' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'formStatus' ? Image4active : Image4Inactive} width={'20px'} />
                            <br />
                            Form Status <br />Stats</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('conversionRatio')} style={{ cursor: 'pointer', background: liveReportTab === 'conversionRatio' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'conversionRatio' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'conversionRatio' ? Image4active : Image4Inactive} width={'20px'} />
                            <br />
                            Conversion <br />Ratio</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('deviation')} style={{ cursor: 'pointer', background: liveReportTab === 'deviation' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'deviation' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'deviation' ? Image7active : Image7Inactive} width={'20px'} />
                            <br />
                            Deviation <br />Chart</div>
                        <div className="col-sm-1 col-xs-12 marketing-sub-tab" onClick={() => setLiveReportTab('businessAgreegator')} style={{ cursor: 'pointer', background: liveReportTab === 'businessAgreegator' ? '#267DFF' : '#DBE9FF', color: liveReportTab === 'businessAgreegator' ? '#fff' : '#486F88' }}>
                            <img src={liveReportTab === 'businessAgreegator' ? Image8active : Image8Inactive} width={'20px'} />
                            <br />
                            Business from <br />Aggregators</div>
                    </div>
                    : ""}

                {page === 'dashboard' ?
                    <div className='container-fluid' style={{ marginTop: '70px' }}>
                        <Dashbaord />
                    </div>
                    : ""}
                {page === 'aggregators' ?
                    <div className='container-fluid' style={{ marginTop: '70px' }}>
                        <Aggregators admin={admin} setmargin={true} />
                    </div>
                    : ""}
                {page === 'greport' ?
                    <div className="container row fixed-top" style={{ marginTop: '77px' }}>
                        <div className="col-sm-2 col-xs-12 marketing-sub-tab " style={{ background: report === 'buddyloan' ? '#267DFF' : '#DBE9FF', color: report === 'buddyloan' ? '#fff' : '#000', cursor: 'pointer' }} onClick={() => setreport('buddyloan')} >Buddyloan <br />Report</div>
                        <div className="col-sm-2 col-xs-12 marketing-sub-tab " style={{ background: report === 'branch' ? '#267DFF' : '#DBE9FF', color: report === 'branch' ? '#fff' : '#000', cursor: 'pointer' }} onClick={() => setreport('branch')} >Branch<br /> Report</div>
                    </div>
                    : ""}
                {report === 'buddyloan' && page === 'greport' ?
                    <div style={{ marginTop: '100px' }}>
                        <BuddyloanReport admin={admin} />
                    </div>
                    : ""}
                {report === 'branch' && page === 'greport' ?
                    <div style={{ marginTop: '100px' }}>
                        <BranchReport admin={admin} />
                    </div>
                    : ""}
                {page === 'BI' ?
                    <div style={{ marginTop: '100px' }}>
                        <Bi />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'leadParticular' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <LeadParticulars admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'stateWise' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <StatewiseData admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'rejectReason' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <RejectionStats admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'ageStats' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <AgestatusData admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'salaryRange' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <SalaryRange admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'loanStatus' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <LoanStatusData admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'conversionRatio' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <ConversionRatio admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'deviation' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <DeviationReport admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'genderStats' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <GenderStats admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'emiStats' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <EmiSats admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'businessAgreegator' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <BuisnessAggregator admin={admin} />
                    </div>
                    : ""}
                {page === 'liveReports' && liveReportTab === 'formStatus' ?
                    <div className="container" style={{ marginTop: '200px' }}>
                        <Formstatus admin={admin} />
                    </div>
                    : ""}
                {page === 'projection' ?
                    <div style={{ marginTop: '75px', height: '100%', background: window.innerHeight <= 1800 ? '#fff' : '#fff', minHeight: window.innerHeight }}>

                        <Projections />
                    </div>

                    : ""}

            </div>
        </>
    )
}
export default MainTabsMarketing 