import React, { useEffect, useState } from "react";
import { deletePolicyByPolicyId } from "../AdminActionCreator.component";


const PolicyList = ({ objectData, setViewPage, setViewPageMain, saveEditData, deletePolicy }) => {
    // const [objectData, setObject] = useState()
    const [editData, setEditData] = useState('')


    const edit = (data) => {
        let changeData = Object.assign({}, editData)
        changeData.id = data.id
        changeData.name = data.name
        changeData.policyId = data.policyId
        changeData.addedOn = data.addedOn
        changeData.updatedOn = data.updatedOn
        setEditData(changeData)
        saveEditData(changeData)
        setViewPageMain('config')

    }
    const Add = () => {
        saveEditData('')
        setViewPageMain('config')
    }


    return (
        <div className="row">
            <div className="col-sm-3 col-xs-12">
            </div>
            <div className="col-sm-6 col-xs-12">
                <div style={{ marginTop: '20px' }}> <h3>All Policy
                    &nbsp;&nbsp;<i className='fa fa-plus-square' style={{ color: '#417bf6', fontSize: '20px', marginTop: '20px', cursor: 'pointer' }} onClick={() => Add()} ></i>
                </h3></div>

                {objectData ?
                    <div className='credit-table'>
                        <table>
                            <thead style={{ background: '#dde9fa', color: '#000' }}>
                                <tr className='thead-class'>
                                    <th>Name</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {objectData && objectData.map((data, i) => {

                                    return (
                                        <tr key={i}>
                                            <td>{data.name}</td>
                                            <td>{data.policyId ? <i className="fa fa-edit" style={{cursor:'pointer'}} onClick={() => edit(data)}></i> : ""}</td>
                                            <td><i className="fa fa-trash "  style={{cursor:'pointer'}}  onClick={() => deletePolicy(data)}></i></td>
                                        </tr>
                                    )

                                })}

                            </tbody>
                        </table>
                    </div>
                    : ""}
            </div>
            <div className="col-sm-3 col-xs-12">
            </div>

        </div>
    )
}
export default PolicyList