import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const ReportLogs = ({ paginationHandler, phocketTablePagination, tableMaxPagination, tableMinPagination, reportData, showFields, tableFields, closePopup,
    fieldPopup, getFilterValue, FilteredData, getPageValue, pageIndexToShow, showFieldsSubstatus, subStatusPopup }) => (
    <div className="container-fluid">
        <h3 className="text-center blue-text">Generate Report Logs</h3>
        <div className="row">
            <div className="col-xs-12">
                {reportData && reportData.length > 0 ?
                    <React.Fragment>
                        <div className='overflow-auto'>
                            <table className='phocket-table'>
                                <thead>
                                
                                        <tr>
                                            <td>Report Type</td>
                                            <td>Status</td>
                                            <td>Sub Status</td>
                                            <td>Product Type</td>
                                            <td> Date Category</td>
                                            <td>Start Date</td>
                                            <td>End Date</td>
                                            <td>Date</td>
                                            <td>Columns</td>
                                            <td>Emp Id</td>
                                          

                                        </tr>
                               
                                </thead>
                                <tbody>
                                    {reportData && reportData.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{data.reportType}</td>
                                                <td> {data.status}</td>
                                                <td>
                                                    <div >
                                                        {data.subStatus != null && data.subStatus != '' ? JSON.parse(data.subStatus).map((data, i) => {
                                                            return (
                                                                <div>
                                                                    {data.list === "201" ? 'PFD' : 'RFD'}
                                                                </div>
                                                            )
                                                        }) : ''}</div></td>
                                                <td>
                                                    <div >
                                                        {data.productType ? data.productType === '0' ? 'Loan' : 'Gift Card' : ''}</div></td>
                                                <td><div >
                                                    {data.dateCategory ? data.dateCategory === '0' ? 'Apply date' : data.dateCategory === '1' ? 'Disburse date' : data.dateCategory === '2' ? 'Paid date' : data.dateCategory === '3' ? 'Cibil Report' : data.dateCategory === '4' ? 'Payback Date' : "" : ''}</div></td>
                                                <td>{data.startDate}</td>
                                                <td>{data.endDate}</td>
                                                <td>{data.date}</td>
                                                <td>  <div className="background-sky pointer" onClick={e => showFields(e, data.columnList)}>
                                                    {data.columnList != null && data.columnList != '' ? data.columnList.length : ''}</div></td>
                                                <td>{data.empId}</td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(reportData, 'back')}></button></div>
                                <div className="col-sm-4 col-xs-12  phoc text-center" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + reportData.length}</div>
                                <div className="col-sm-4 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(reportData, 'forward')}></button></div>
                            </div>
                        </div>
                    </React.Fragment>

                    : ""}
            

            </div>
        </div>
        {fieldPopup ?
            <div className="">
                <div className="background-blur" onClick={e => closePopup()}></div>
                <div className="popup-main small-popup container-fluid dbl-border overflow-auto">
                    <div className="row">
                        {tableFields != null && tableFields != '' ?
                            <table className="text-center loan-detail-table">
                                <thead>
                                    <tr>
                                        <th>Fields</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {tableFields.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{data}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            : ""}
                    </div>
                </div>
            </div>
            : null}
        {subStatusPopup ?
            <div className="">
                <div className="background-blur" onClick={e => closePopup()}></div>
                <div className="popup-main small-popup container-fluid dbl-border overflow-auto">
                    <div className="row">
                        {tableFields != null && tableFields != '' ?
                            <table className="text-center loan-detail-table">
                                <thead>
                                    <tr>
                                        <th>Sub Status</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {tableFields.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{data.list === "201" ? 'PFD' : 'RFD'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            : ""}
                    </div>
                </div>
            </div>
            : null}
    </div>
)
export default ReportLogs;