import React from 'react'

const ConfirmationPopup = ({ cancelLoanConfirm, confirmationText }) => (
    <div className=''>
        <div className='row'>
            <div className='col-sm-3 col-xs-4'></div>
            <div className='col-sm-6 col-xs-4'>
                <div className="details-new newpopup" style={{marginTop:'-250px'}}>
                    <div className="popup-body ">
                        <div className="row ">
                            <div className="col-xs-12">
                                <h4 className="text-center">{confirmationText}</h4>
                            </div>
                        </div>
                        <div className="row  text-center">
                            <div className="col-xs-6">
                                <button className="btn btn-success" onClick={(e) => cancelLoanConfirm('yes')}>YES</button>
                            </div>
                            <div className="col-xs-6">
                                <button className="btn btn-danger display-inline" onClick={(e) => cancelLoanConfirm('no')}>NO</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        )

        export default ConfirmationPopup;