import React, { Component } from 'react'
import CalcUtilityUi from '../../../presentationals/Admin/Utility/CalcUtility.component';
import { _preFormatDate } from '../../../../utils/validation'
import { fetchCalcUtility } from '../AdminActionCreator.component'

class CalcUtility extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disburseDateState: '',
            repayDateState: '',
            paymentRecievedDate: '',
            calcUtilityData: {
                calcUtilityAmount: '',
                disburseDateState: _preFormatDate(new Date()),
                repayDateState: _preFormatDate(new Date()),
                paymentRecievedDate: _preFormatDate(new Date()),
                chequeStatusState: false,
                stampDutyState: false
            },
            calcUtilityFinalData: '',
        }
    }

    render() {
        return (
            <div>
                <CalcUtilityUi
                    calcUtility={this.calcUtility.bind(this)}
                    calcUtilityHandler={this.calcUtilityHandler.bind(this)}
                    disburseDateState={this.state.disburseDateState}
                    repayDateState={this.state.repayDateState}
                    paymentRecievedDate={this.state.paymentRecievedDate}
                    calcUtilityFinalData={this.state.calcUtilityFinalData}
                    calcUtilityData={this.state.calcUtilityData}
                />
            </div>
        )
    }

    calcUtility(e, calcUtilityType) {
        let calcUtilityData = Object.assign({}, this.state.calcUtilityData);
        if (calcUtilityType == 'amount') {
            calcUtilityData.calcUtilityAmount = e.target.value
        } else if (calcUtilityType == 'paidDate') {
            this.setState({ disburseDateState: e })
            calcUtilityData.disburseDateState = _preFormatDate(e)
        } else if (calcUtilityType == 'repayDate') {
            this.setState({ repayDateState: e })
            calcUtilityData.repayDateState = _preFormatDate(e)
        } else if (calcUtilityType == 'recievedDate') {
            this.setState({ paymentRecievedDate: e })
            calcUtilityData.paymentRecievedDate = _preFormatDate(e)
        } else if (calcUtilityType == 'chequeStatus') {
            calcUtilityData.chequeStatusState = e.target.checked
        } else if (calcUtilityType == 'stampStatus') {
            calcUtilityData.stampDutyState = e.target.checked
        }
        this.setState({ calcUtilityData })
    }
    calcUtilityHandler() {
        fetchCalcUtility(this.state.calcUtilityData,
            (callBack) => {
                this.setState({ calcUtilityFinalData: callBack })
            })
    }

}

export default CalcUtility;