import React from 'react'

const OutletAdd = ({ outLetAddressList, saveOutLetHandler, listingOfCategory,
    getDataHandler, couponList, mappedList, updateOutlet, merchantData,
    deleteMappedCoupon }) => (
        <div className="container-fluid">
            <div className="row">
                <h3 className="text-center">Coupon Available on Outlet</h3>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-4">
                    <label className="font-12px">Select Merchant</label>
                    <select className="form-control" value={merchantData.merchantId} id="subCatStatus" onChange={e => getDataHandler(e, "selectMerchant")}>
                        <optgroup className="display-none">
                            <option value="">Select Merchant</option>
                        </optgroup>
                        {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                            listingOfCategory.map((list,i) => {
                                return (
                                    <optgroup key={i} label="Merchant">
                                        {list.Merchant != null && list.Merchant.length > 0 ?
                                            list.Merchant.map((Merchant, i) => {
                                                return (
                                                    <option key={i} value={Merchant.merchantid}>{Merchant.merchantname}</option>
                                                )
                                            })
                                            : ""}
                                    </optgroup>
                                )
                            })
                            : ""}
                    </select>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <label className="font-12px">Select Coupons</label>
                    <select className="form-control" value={merchantData.couponId} onChange={e => getDataHandler(e, 'selectCoupon')}>
                        <optgroup className="display-none">
                            <option value="">Select Coupons</option>
                        </optgroup>
                        <optgroup label="Coupons">
                            {couponList != null && couponList != '' ?
                                couponList.map((list, i) => {
                                    return (
                                        <option key={i} value={list.couponid}>{list.title}</option>
                                    )
                                })
                                : ""}
                        </optgroup>
                    </select>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <label className="font-12px">Select Location</label>
                    <select className="form-control" value={merchantData.outletId} onChange={e => getDataHandler(e, 'selectLocation')}>
                        <optgroup className="display-none">
                            <option value="">Select Location</option>
                        </optgroup>
                        <optgroup label="Location">
                            {outLetAddressList != null && outLetAddressList != '' ?
                                outLetAddressList.map((list,i) => {
                                    return (
                                        <option key={i} value={list.outletid}>{list.city + '(' + list.address + ')'}</option>
                                    )
                                })
                                : ""}
                        </optgroup>
                    </select>
                </div>
            </div>
            <div className="row">
                <button className="profile-save-btn center-block" onClick={e => saveOutLetHandler()}>SAVE</button>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    {mappedList != null && mappedList != '' ?
                        <table className="text-center loan-detail-table">
                            <thead>
                                <tr className="background-blue text-white">
                                    <th>ID</th>
                                    <th>Address</th>
                                    <th>Location</th>
                                    <th>Coupon Title</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mappedList.map((mapList,i) => {
                                    return (
                                        <tr  key={i} onClick={e => updateOutlet(mapList)}>
                                            <td>{mapList.id}</td>
                                            <td>{mapList.address}</td>
                                            <td>{mapList.location}</td>
                                            <td>{mapList.title}</td>
                                            <td><span className="fa fa-trash" onClick={e => deleteMappedCoupon(mapList)} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        : ""}
                </div>
            </div>
        </div>
    )

export default OutletAdd;