import React from "react";
import BranchUi from "../../../presentationals/Admin/DashboardAndReports/BranchData.component";
import { getBranchDataApi, getBranchDataApiConsolidated } from "../AdminActionCreator.component";
import { _preFormatDate } from '../../../../utils/validation'
import Loader from '../../../presentationals/Loader/Loader.component'

class BranchReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: _preFormatDate(new Date()),
            endDate: _preFormatDate(new Date()),
            type: 'L',
            Start: '',
            End: '',
            loaderState: false
        }
    }
    datehandler(e, listType) {
        if (listType == 'startDate') {
            this.setState({ startDate: _preFormatDate(e), Start: e })
        }
        if (listType == 'endDate') {
            this.setState({ endDate: _preFormatDate(e), End: e })
        }
    }
    selectHandler(e) {
        this.setState({ type: e.target.value })
    }
    submit() {
        if (this.state.type !== '') {
            this.setState({ loaderState: true })

            getBranchDataApi(this.state.startDate, this.state.endDate, this.state.type, callBack => {
                if (callBack !== '') {
                    this.setState({ loaderState: false })
                }
            })
        }
    }
    submitConsolidated() {
        if (this.state.startDate && this.state.endDate) {
            // this.setState({ loaderState: true })

            getBranchDataApiConsolidated(this.state.startDate, this.state.endDate, callBack => {
               
                    // this.setState({ loaderState: false })
            
            })
        }

    }
    render() {
        return (
            <div>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <BranchUi
                submitConsolidated={this.submitConsolidated.bind(this)}
                    Start={this.state.Start}
                    End={this.state.End}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    type={this.state.type}
                    selectHandler={this.selectHandler.bind(this)}
                    datehandler={this.datehandler.bind(this)}
                    submit={this.submit.bind(this)}
                />
            </div>
        )
    }
}
export default BranchReport