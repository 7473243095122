import { APIS } from '../../../utils/api-factory';
import { POST_HEADER } from '../../../utils/apiHeaders'

export const thirdPartyLogin = (emailId, password, callBack) => {
    var data = {
        email: emailId,
        password: password
    }
    fetch(APIS.THIRD_PARTY_LOGIN, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)

    })
        .then(res => res.text())
        .then(json => {
            callBack(json);
        })
}

export const thirdPartyRegistration = (userdetail,status, callBack) => {
    var data = {
        userName: '',
        password: userdetail.password,
        companyName: userdetail.companyName,
        contactPerson: userdetail.contactPersonName,
        mobileNumber: userdetail.contactPersonMobileNumber,
        email: userdetail.contactPersonEmail,
        webAddress: userdetail.companywebaddress,
        registeredAddress: userdetail.companyregisteredaddress,
        status:status
    }
    fetch(APIS.THIRD_PARTY_REGISTRATION, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)

    })
        .then(res => res.text())
        .then(json => {
            callBack(json);
        })


}

export const getThirdPartyData = (url, callBack) => {

    fetch(url)
        .then(res => res.json())
        .then(json => {
            callBack(json)

        })
}
export const getCompanyData = (callBack) => {
    fetch(APIS.GET_REGISTER_DATA)
        .then(res => res.json())
        .then(json => {
            callBack(json)

        })
}
export const saveCampaignData = (userId, campaignUrl, callBack) => {
    var data = {
        userID: userId,
        campaignUrl: campaignUrl,

    }
    fetch(APIS.SAVE_CAMPAIN_DATA, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)

    })
        .then(res => res.text())
        .then(json => {
            callBack(json);
        })


}

export const getUserData = (userId, callBack) => {

    fetch(APIS.GET_USERS_DETAILS)
        .then(res => res.json())
        .then(json => {
            callBack(json)

        })
}
export const getAllCampaign = (callBack) => {

    fetch(APIS.GET_ALL_CAMPAIGN)
        .then(res => res.json())
        .then(json => {
            callBack(json)

        })
}
export const getCampaignByMerchant = (callBack) => {

}

export const getallstate = (callBack) => {
    fetch(APIS.GET_ALL_STATE)
        .then(res => res.json())
        .then(json => {
            callBack(json)

        })
}
export const getexceldata = (callBack) => {
    fetch(APIS.GET_EXCEL_DATA)
        .then(res => res.json())
        .then(json => {
            callBack(json)

        })
}

export const deleteThirdPartyUser = (id, callBack) => {
    let data = {
        id: id
    }
    fetch(APIS.DELETE_CAMPAIGN_URL, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    }).then(res => res.text())
        .then(json => {
            callBack(json)
        })
}
export const changeIdStatus = (status, emailId, callBack) => {
    var data = {
        
    }
    fetch(APIS.THIRD_USER_ACIVE_INACTIVE +status+ '&emailId='+emailId, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)

    })
        .then(res => res.text())
        .then(json => {
            callBack(json);
        })
}