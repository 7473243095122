import React, { useState } from "react";
import AddPolicy from "./addPolicy";
import MainPolicyTab from "./configurationMainTab";
import MappingPolicy from "./mapPolicy";

const PolicyConfig = ({ setViewPageMain, editData, getData }) => {
    const [tab, setTab] = useState('add')
    const back = () => {
        getData()
        setViewPageMain('list')
    }
    return (
        <div className="container-fluid">
            <i className='fa fa-long-arrow-left' style={{ color: '#417bf6', fontSize: '20px', marginTop: '20px', cursor: 'pointer' }} onClick={() => back('list')} ></i>
            <div className="row" style={{ marginTop: '25px' }}>
                <div onClick={() => setTab('add')} className={tab === 'add' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                    <div >
                        <div className="text-center" >{editData && editData.id ? 'Update' : 'Add'} Policy</div>
                    </div>
                </div>
                {editData ?
                    <div onClick={() => setTab('map')} className={tab === 'map' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                        <div>
                            <div className="text-center" > Mapping </div>
                        </div>
                    </div>
                    : ""}
                <div onClick={() => setTab('config')} className={tab === 'config' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                    <div >
                        <div className="text-center" > Configure Policy Points </div>
                    </div>
                </div>
            </div>
            <>

                {tab === 'add' ?
                    <AddPolicy editData={editData} />
                    : ""}
                {tab === 'config' ?
                    <MainPolicyTab  editData={editData}/>
                    : ""}
                {tab === 'map' ?
                    <MappingPolicy editData={editData} />
                    : ""}
            </>
        </div>
    )
}
export default PolicyConfig