import React from 'react'
import { _preFormatDate } from '../../../../utils/validation';
import Ui from '../../../presentationals/Admin/Recovery/RAZORPAYData.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import { getRazorpayData } from '../../Admin/AdminActionCreator.component'


class Razor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Start: '',
            End: '',
            startDate: _preFormatDate(new Date()),
            endDate: _preFormatDate(new Date()),
            loaderState: false
        }
    }
    dateChangeHandler(e, type) {
        if (type === 'start') {
            this.setState({ startDate: _preFormatDate(e), Start: e })
        }
        if (type === 'end') {
            this.setState({ endDate: _preFormatDate(e), End: e })

        }

    }
    downloadreport() {
        this.setState({ loaderState: true })

        getRazorpayData(this.state.startDate, this.state.endDate, callBack => {
            if (callBack !== '') {
                this.setState({ loaderState: false })
            }
        })
    }
    render() {
        return (
            <div>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <Ui
                    downloadreport={this.downloadreport.bind(this)}
                    Start={this.state.Start}
                    End={this.state.End}
                    dateChangeHandler={this.dateChangeHandler.bind(this)}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}

                />
            </div>
        )
    }
}
export default Razor