import React from 'react';



const Scoring = ({ inputRemarkField, inputRemark, deviationValues, openConfirmation, scoreChange, scoreHandler, scoreDetail, dbrValue, flagData }) => (
    <div className="container-fluid">
     

        <div className="row">
            <div className=" col-xs-12">
                <div className="">
                    <h5 ><b>Cibil Scoring</b></h5>
                    <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                    <div className="row">
                        <div className="col-sm-12 col-xs-12 form-group">
                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>No. of overdue accounts/Total accounts&nbsp;</b>
                            <input type="text" className="form-control credit-input" value={scoreDetail.cibilOverdueAccount} onChange={e => scoreChange(e, 'cibilOverdueAccount')}></input>
                        </div>

                    </div>
                    <div className='row'>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>CIBIl score&nbsp;</b>
                            <br />
                            <input type="text" className="form-control credit-input" value={scoreDetail.cibilScore} onChange={e => scoreChange(e, 'cibilScore')}></input>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Income&nbsp;</b>
                            <br />
                            <input type="text" className="form-control credit-input" value={scoreDetail.cibilincome} onChange={e => scoreChange(e, 'cibilincome')}></input>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Obligations&nbsp;</b>
                            <input type="text" className="form-control credit-input" value={scoreDetail.cibilObligations} onChange={e => scoreChange(e, 'cibilObligations')}></input>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Surplus &nbsp;</b>
                            <input type="text" className="form-control credit-input" value={scoreDetail.cibilSurplus} onChange={e => scoreChange(e, 'cibilSurplus')}></input>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>CC/STL &nbsp;</b>
                            <input type="text" className="form-control credit-input" value={scoreDetail.shortTermCreditCard} onChange={e => scoreChange(e, 'shortTermCreditCard')}></input>
                        </div>
                        <div className='col-sm-4 col-xs-12 form-group'>
                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>DBR &nbsp;</b>
                            <input type="text" className="form-control credit-input" value={dbrValue} readOnly ></input>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-xs-12 form-group">
                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Remarks&nbsp;</b>
                            {/* <textarea className="form-control credit-input" value={scoreDetail.cibilRemarks} onChange={e => scoreChange(e, 'cibilRemarks')}></textarea> */}
                            <select onChange={e => scoreChange(e, 'cibilRemarks')} className='form-control credit-input' value={scoreDetail.cibilRemarks}>
                                <option value={''}>Select Remark</option>
                                {deviationValues !== '' && deviationValues !== undefined && deviationValues.length > 0 ? deviationValues.map((data, i) => {
                                    return (
                                        <option value={data.clarification}>{data.clarification}</option>
                                    )
                                }) : ""}
                                <option value={'Others'}>Other</option>
                            </select>
                        </div>
                        {scoreDetail.cibilRemarks === 'Others' ?
                            <div className="col-sm-12 col-xs-12 form-group">
                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Enter Remarks&nbsp;</b>
                                <textarea className="form-control credit-input" onChange={e => inputRemark(e)}></textarea>
                            </div>
                            : ""}

                    </div>
                    <div className="" style={{ marginBottom: '10px',marginTop:'20px' }}>
                        <button className="btn btn-primary text-center" onClick={e => openConfirmation()}>Save</button>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="col-sm-4 col-xs-12">
                <div className="profile-details-box">
                    <div className="crm-user-head">CREDIT</div>
                        <div className="word-break"><b>Stay in CA more than 1 years&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div>
                        <div className="word-break"><b>Current Address=Aadhar Address&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div>
                        <div className="word-break"><b>Current Address=Permanent Address&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div>
                        <div className="word-break"><b>Current Address=owned&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div>
                        <div className="word-break"><b>Current Company Work exp > 1 year&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div>
                        <div className="word-break"><b>Company Category&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="profile-save-btn text-center">Save</button>
                    </div>
                </div>
            </div>
            <div className="col-sm-4 col-xs-12">
                <div className="profile-details-box">
                    <div className="crm-user-head">Expenses</div>
                        <div className="word-break"><b>Salary on time&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div><div className="">
                        <div className="word-break"><b>EMI / Income Ratio&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div><div className="">
                        <div className="word-break"><b>Presence of existing loan&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div><div className="">
                        <div className="word-break"><b>No emi defaults evident&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div><div className="">
                        <div className="word-break"><b>Ability to save/Opening and closing balance&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div>
                        <div className="word-break"><b>Presence of high value transaction&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></input>
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="profile-save-btn text-center">Save</button>
                    </div>
                </div>
            </div> */}
    </div>
)

export default Scoring;
