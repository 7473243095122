import React from 'react'
import Ui from '../../../presentationals/Admin/BulkActions/DisburseByICICI.component'
import {
    getLoanApprovalLead, BulkPaymentICICI, verifyIciciPayments
} from '../AdminActionCreator.component';
import { STRING, ROLE } from '../../../../utils/Constant'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from '../../../presentationals/Loader/Loader.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import { data } from 'jquery';
import RingTone from './success-48018.mp3'
let ids = { "1auHwvWSfNzNETYDEbOGv8g0Vjs8NyhQ": "PENDING", "Nrjuo9ixzrclGEIbMNHPxVGIeXYosXuJ": 'rtyui' }

let dataTosend = []
let ApplicationId = []
let newdataToCheck = []
class Bank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            followUpStatus: STRING.APPROVED_STATUS,
            forwordPageState: 1,
            searchByDate: '',
            tabType: 'Advance',
            searchByEndDate: '',
            allUserDetail: '',
            loaderState: '',
            popupState: false,
            popupStatus: '',
            paymentData: '',
            loanIdData: '',
            playingState: false


        }
    }
    selectAll(e) {
        if (this.state.allUserDetail && e.target.checked) {
            this.state.allUserDetail.map((dataNew, i) => {
                dataTosend.push(dataNew.loanId)
                newdataToCheck.push(i)
                document.getElementById('disburseIcici' + i).checked = true
            })
        } else {
            dataTosend = []
            newdataToCheck.map((newl, i) =>
                document.getElementById('disburseIcici' + newl).checked = false
            )
        }
    }

    closePopup() {
        this.setState({ popupState: false })
    }
    getloanLeads(adminEmail) {
        this.setState({ loaderState: true })
        this.props.getLoanApprovalLead(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, '', this.state.searchByEndDate, this.state.tabType, '', '203', '', '', '',
            (callBack) => {
                this.setState({ loaderState: false, allUserDetail: callBack })
            })
    }
    refresh() {
        const { admin } = this.props
        this.getloanLeads(admin.emailId)
    }
    componentDidMount() {
        const { admin } = this.props
        this.getloanLeads(admin.emailId)
        dataTosend = []
    }
    changehandler(e, data) {
        if (e.target.checked) {
            dataTosend.push(data.loanId)

        } else {
            let check = dataTosend.includes(data.loanId)
            if (check) {
                const index = dataTosend.indexOf(data.loanId);
                if (index > -1) {
                    dataTosend.splice(index, 1);

                }
            }
        }
    }
    paginationHandler(type) {
        if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,

                }, () => this.getloanLeads())
            }
        } if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,

            }, () => this.getloanLeads())
        }
    }
    paymentAndVerificationHandler(type) {
        if (type === 'verify') {
            this.setState({ loaderState: true })
            if (dataTosend.length === 0) {
                this.setState({ popupState: true, popupStatus: 'Please Select Application To verify', loaderState: false }, () => this.removePopup())
            } else {
                verifyIciciPayments(dataTosend, callBack => {
                    this.setState({ paymentData: JSON.parse(callBack) })

                    if (callBack) {
                        this.setState({ popupState: true, popupStatus: 'Check Payment Status Column', loaderState: false, playingState: true }, () => this.removePopup())

                    } else {
                        this.setState({ popupState: true, popupStatus: 'Please try again later', loaderState: false }, () => this.removePopup())
                    }


                })

            }
        }
        if (type === 'payment') {
            if (dataTosend.length !== 0) {
                this.setState({ loaderState: true })
                BulkPaymentICICI(dataTosend, callBack => {
                    if (callBack) {
                        let data = JSON.parse(callBack)

                        this.setState({ paymentData: JSON.parse(callBack) })
                        this.setState({ loanIdData: Object.keys(data) + ',' + Object.values(data) })
                        this.setState({ popupState: true, popupStatus: 'Check Payment Status Column', loaderState: false,playingState: true }, () => this.removePopup())

                    } else {
                        this.setState({ popupState: true, popupStatus: 'Please try again later', loaderState: false }, () => this.removePopup())
                    }
                })
            } else {
                this.setState({ popupState: true, popupStatus: 'Please Select Appliaction for payment', loaderState: false }, () => this.removePopup())

            }
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false, playingState: false });
        }.bind(this), 5000)
    }
    handleSongFinishedPlaying() {
        this.setState({ playingState: false })
    }

    render() {
        return (
            <div>
                <Ui
                    refresh={this.refresh.bind(this)}
                    selectAll={this.selectAll.bind(this)}
                    pageNumber={this.state.forwordPageState}
                    paginationHandler={this.paginationHandler.bind(this)}
                    loanIdData={this.state.loanIdData}
                    ApplicationId={ApplicationId}
                    paymentData={this.state.paymentData}
                    allUserDetail={this.state.allUserDetail}
                    changehandler={this.changehandler.bind(this)}
                    paymentAndVerificationHandler={this.paymentAndVerificationHandler.bind(this)}
                />
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupState == true ? (
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
                {this.state.playingState == true ?
                    <audio autoPlay>
                        <source src={RingTone} type="audio/mpeg" />
                    </audio>
                    : ""}

            </div>
        )
    }
}
const mapStateToProps = ({ allUserDetail, loanApprovalLead }) => {
    return {
        allUserDetail: allUserDetail,
        loanApprovalLead: loanApprovalLead,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getLoanApprovalLead
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Bank);