import React, { Component } from 'react'
// import CreditScoring from '../../../presentationals/Admin/CustomerPages/UserCreditScoring.component'
import CreditScoringPopup from '../../../presentationals/Admin/CustomerPages/CreditScorePopup.component'
import { APIS } from '../../../../utils/api-factory'
import { updateCreditScore } from '../AdminActionCreator.component'
import { creditScoreValidation } from '../../../../utils/validation';
import Poup from '../../../presentationals/Popup/Popup.component'
import Loader from '../../../presentationals/Loader/Loader.component'
class CreditScore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scoreData: '',
            creditScoreSaveBtnState: false,
            creditValidationMsg: '',
            creditDetail: {
                CamorethanoneyearState: '',
                AddressOwnState: '',
                CAsameAsPaState: '',
                CAsameAdhaarAddressState: '',
                EXPMorethanthreeyearState: '',
                CurrentAddressmorethanoneyearState: '',
                companyCategoryState: '',
                salaryOnTimeState: '',
                EMIRatioState: '',
                existingLoanState: '',
                defaultEvidentState: '',
                openingClosingBalanceState: '',
                highValueTransactionState: '',
                totalScore: '',

            },
            popupStatus: '',
            popupState: false,
            msg: '',
            loaderState:false
        }
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    componentDidMount() {
        let creditDetail = Object.assign({}, this.state.creditDetail);
        const { allDetail } = this.props
        fetch(APIS.GET_RATING_FOR_CUSTOMER_INFO + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                this.setState({ scoreData: json })
                if (json.caMorethanOneYear != null && json.caMorethanOneYear != "") {
                    creditDetail.CamorethanoneyearState = json.caMorethanOneYear
                }
                if (json.caOwned != null && json.caOwned != "") {
                    creditDetail.AddressOwnState = json.caOwned
                }
                if (json.caSameAsPa != null && json.caSameAsPa != "") {
                    creditDetail.CAsameAsPaState = json.caSameAsPa
                }
                if (json.caSameAsAdhaarAddress != null && json.caSameAsAdhaarAddress != "") {
                    creditDetail.CAsameAdhaarAddressState = json.caSameAsAdhaarAddress
                }
                if (json.workExpMorethanthreeyear != null && json.workExpMorethanthreeyear != "") {
                    creditDetail.EXPMorethanthreeyearState = json.workExpMorethanthreeyear
                }
                if (json.currentCompanyWorkExpMorethanOneyear != null && json.currentCompanyWorkExpMorethanOneyear != "") {
                    creditDetail.CurrentAddressmorethanoneyearState = json.currentCompanyWorkExpMorethanOneyear
                }
                if (json.company_Category != null && json.company_Category != "") {
                    creditDetail.companyCategoryState = json.company_Category
                }
                if (json.salaryOnTime != null && json.salaryOnTime != "") {
                    creditDetail.salaryOnTimeState = json.salaryOnTime
                }
                if (json.emiratio != null && json.emiratio != "") {
                    creditDetail.EMIRatioState = json.emiratio
                }
                if (json.existingLoan != null && json.existingLoan != "") {
                    creditDetail.existingLoanState = json.existingLoan
                }
                if (json.defaultEvident != null && json.defaultEvident != "") {
                    creditDetail.defaultEvidentState = json.defaultEvident
                }
                if (json.openingClosingBalance != null && json.openingClosingBalance != "") {
                    creditDetail.openingClosingBalanceState = json.openingClosingBalance
                }
                if (json.highValueTransaction != null && json.highValueTransaction != "") {
                    creditDetail.highValueTransactionState = json.highValueTransaction
                }
                if (json.total_rating != null && json.total_rating != "") {
                    creditDetail.totalScore = json.total_rating
                }
                this.setState({ creditDetail })
            })

    }

    render() {
        const { creditScoringPopup, admin } = this.props
        return (
            <div className="full-height">
                {

                    creditScoringPopup == true ?
                        <CreditScoringPopup
                            creditDetail={this.state.creditDetail}
                            creditexport={this.creditexport.bind(this)}
                            creditexportHandler={this.creditexportHandler.bind(this)}
                            creditValidationMsg={this.state.creditValidationMsg}
                            admin={admin}
                        /> : ""}
                {this.state.popupState ?
                    <Poup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                        msg={this.state.msg}
                    />
                    : ""}
                    {this.state.loaderState?
                   <Loader/> 
                :""}

            </div>
        )
    }

    showCreditPopup() {
        this.props.getCreditPopupState(true)
    }

    editCreditScore() {
        this.setState({ creditScoreSaveBtnState: true })
    }

    creditexport(e, datatype) {
        let creditDetail = Object.assign({}, this.state.creditDetail);
        if (datatype == 'Camorethanoneyear') {
            creditDetail.CamorethanoneyearState = e.target.value
        } else if (datatype == 'AddressOwn') {
            creditDetail.AddressOwnState = e.target.value
        } else if (datatype == 'CAsameAsPa') {
            creditDetail.CAsameAsPaState = e.target.value
        } else if (datatype == 'CAsameAdhaarAddress') {
            creditDetail.CAsameAdhaarAddressState = e.target.value
        } else if (datatype == 'EXPMorethanthreeyear') {
            creditDetail.EXPMorethanthreeyearState = e.target.value
        } else if (datatype == 'CurrentAddressmorethanoneyear') {
            creditDetail.CurrentAddressmorethanoneyearState = e.target.value
        } else if (datatype == 'companyCategory') {
            creditDetail.companyCategoryState = e.target.value
        } else if (datatype == 'salaryOnTime') {
            creditDetail.salaryOnTimeState = e.target.value
        } else if (datatype == 'EMIRatio') {
            creditDetail.EMIRatioState = e.target.value
        } else if (datatype == 'existingLoan') {
            creditDetail.existingLoanState = e.target.value
        } else if (datatype == 'defaultEvident') {
            creditDetail.defaultEvidentState = e.target.value
        } else if (datatype == 'openingClosingBalance') {
            creditDetail.openingClosingBalanceState = e.target.value
        } else if (datatype == 'highValueTransaction') {
            creditDetail.highValueTransactionState = e.target.value
        }
        this.setState({ creditDetail, creditValidationMsg: '' })
    }

    creditexportHandler() {
        let creditDetail = Object.assign({}, this.state.creditDetail);
        const { allDetail } = this.props
        let creditValidation = creditScoreValidation(
            this.state.creditDetail
        )
        if (creditValidation.status) {
            this.setState({
                creditValidationMsg: creditValidation,
            })
            this.setState({loaderState:true})
            updateCreditScore(this.state.creditDetail, allDetail.userId,
                (callBack) => {
                    if (callBack.caMorethanOneYear != null && callBack.caMorethanOneYear != "") {

                        if (callBack.caMorethanOneYear != null && callBack.caMorethanOneYear != "") {
                            creditDetail.CamorethanoneyearState = callBack.caMorethanOneYear
                        }
                        if (callBack.caOwned != null && callBack.caOwned != "") {
                            creditDetail.AddressOwnState = callBack.caOwned
                        }
                        if (callBack.caSameAsPa != null && callBack.caSameAsPa != "") {
                            creditDetail.CAsameAsPaState = callBack.caSameAsPa
                        }
                        if (callBack.caSameAsAdhaarAddress != null && callBack.caSameAsAdhaarAddress != "") {
                            creditDetail.CAsameAdhaarAddressState = callBack.caSameAsAdhaarAddress
                        }
                        if (callBack.workExpMorethanthreeyear != null && callBack.workExpMorethanthreeyear != "") {
                            creditDetail.EXPMorethanthreeyearState = callBack.workExpMorethanthreeyear
                        }
                        if (callBack.currentCompanyWorkExpMorethanOneyear != null && callBack.currentCompanyWorkExpMorethanOneyear != "") {
                            creditDetail.CurrentAddressmorethanoneyearState = callBack.currentCompanyWorkExpMorethanOneyear
                        }
                        if (callBack.company_Category != null && callBack.company_Category != "") {
                            creditDetail.companyCategoryState = callBack.company_Category
                        }
                        if (callBack.salaryOnTime != null && callBack.salaryOnTime != "") {
                            creditDetail.salaryOnTimeState = callBack.salaryOnTime
                        }
                        if (callBack.emiratio != null && callBack.emiratio != "") {
                            creditDetail.EMIRatioState = callBack.emiratio
                        }
                        if (callBack.existingLoan != null && callBack.existingLoan != "") {
                            creditDetail.existingLoanState = callBack.existingLoan
                        }
                        if (callBack.defaultEvident != null && callBack.defaultEvident != "") {
                            creditDetail.defaultEvidentState = callBack.defaultEvident
                        }
                        if (callBack.openingClosingBalance != null && callBack.openingClosingBalance != "") {
                            creditDetail.openingClosingBalanceState = callBack.openingClosingBalance
                        }
                        if (callBack.highValueTransaction != null && callBack.highValueTransaction != "") {
                            creditDetail.highValueTransactionState = callBack.highValueTransaction
                        }
                        if (callBack.total_rating != null && callBack.total_rating != "") {
                            creditDetail.totalScore = callBack.total_rating
                        }
                        this.setState({ creditScoreSaveBtnState: false, creditDetail,popupState:true,popupStatus:'Data saved successfully!',loaderState:false },()=>this.removePopup())
                    }else{
                        this.setState({ creditScoreSaveBtnState: false,popupState:true,popupStatus:'please try again later!',loaderState:false },()=>this.removePopup())

                    }
                }
            )
        } else {
            this.setState({
                creditValidationMsg: creditValidation,
            })
        }
    }
 
        removePopup() {
            setTimeout(function () {
                this.setState({ popupState: false });
            }.bind(this), 5000)
        }
    

    componentWillUnmount() {
        this.setState({
            creditDetail: {
                CamorethanoneyearState: '',
                AddressOwnState: '',
                CAsameAsPaState: '',
                CAsameAdhaarAddressState: '',
                EXPMorethanthreeyearState: '',
                CurrentAddressmorethanoneyearState: '',
                companyCategoryState: '',
                salaryOnTimeState: '',
                EMIRatioState: '',
                existingLoanState: '',
                defaultEvidentState: '',
                openingClosingBalanceState: '',
                highValueTransactionState: '',
                totalScore: ''
            },
            creditValidationMsg: ''
        })
    }
}

export default CreditScore;