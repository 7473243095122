import React, { Component } from 'react'
import { saveWebsiteContent } from '../AdminActionCreator.component';
import WebContentEntry from '../../../presentationals/Admin/Utility/WebContent.component';
import Popup from '../../../presentationals/Popup/Popup.component';
import { APIS } from '../../../../utils/api-factory'

class WebCont extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            webContent: {
                loanProcessed: '',
                amountDisbursed: '',
                appDownloads: '',
                repeatUser: '',
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <WebContentEntry
                    webContentChange={this.webContentChange.bind(this)}
                    webContentHandler={this.webContentHandler.bind(this)}
                    webContent={this.state.webContent}
                />
            </div>
        )
    }

    componentDidMount() {
        let webContent = Object.assign({}, this.state.webContent);
        fetch(APIS.GET_WEBSITE_DATA)
            .then(res => res.json())
            .then(res => {
                if (res.loanProcessed != null && res.loanProcessed != '') {
                    webContent.loanProcessed = res.loanProcessed
                }
                if (res.amountDisbursed != null && res.amountDisbursed != '') {
                    webContent.amountDisbursed = res.amountDisbursed
                }
                if (res.appDownload != null && res.appDownload != '') {
                    webContent.appDownloads = res.appDownload
                }
                if (res.repeatUser != null && res.repeatUser != '') {
                    webContent.repeatUser = res.repeatUser
                }
                this.setState({ webContent })
            })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    webContentChange(e, contentType) {
        let webContent = Object.assign({}, this.state.webContent);
        if (contentType == 'loan') {
            webContent.loanProcessed = e.target.value
        } else if (contentType == 'amount') {
            webContent.amountDisbursed = e.target.value
        } else if (contentType == 'apps') {
            webContent.appDownloads = e.target.value
        } else if (contentType == 'repeat') {
            webContent.repeatUser = e.target.value
        }
        this.setState({ webContent })
    }

    webContentHandler() {
        const { admin } = this.props
        saveWebsiteContent(this.state.webContent, admin,
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Data saved successfully',
                    })
                    this.removePopup();
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup();
                }
            })
    }
}

export default WebCont;