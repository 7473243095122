import React from 'react'
import DatePicker from 'react-datepicker'
const CardsUser = ({tableMaxPagination,tableMinPagination, paginationHandler,phocketTablePagination, filterData, userData, allStatus, userDetailsinfo, dataFilter, searchByDateShow, date }) => (
    <div className='container-fluid'>
        <h3 style={{ marginTop: '20px', marginBottom: '20px' }} className="text-center blue-text">All Cards Users</h3>
        <div className='row'>

            <div className='col-sm-3 col-xs-12'>
                <label>Date</label>
                <input
                    type='date'
                    value={date}
                    onChange={e => dataFilter(e, "date-test")}
                    className="form-control "
                    // dateFormat="DD/MM/YYYY"
                    placeholderText="Filter By Date"
                />
                {/* <label>Date</label>
                <DatePicker
                    selected={searchByDateShow}
                    value={date}
                    onChange={e => dataFilter(e, "date")}
                    className="form-control "
                    dateFormat="DD/MM/YYYY"
                    placeholderText="Filter By Date"
                /> */}
            </div>
            <div className='col-sm-3 col-xs-12'>
                <label>Email</label>
                <input className='form-control' placeholder='Enter Email' onChange={(e) => dataFilter(e, 'email')} value={filterData.email} /></div>
            <div className='col-sm-3 col-xs-12'>
                <label>Mobile Number</label>
                <input className='form-control' placeholder='Enter Mobile Number' onChange={(e) => dataFilter(e, 'mobileNo')} /></div>
            <div className='col-sm-3 col-xs-12'>
                <label>Card Status</label>
                <select className='form-control' onChange={(e) => dataFilter(e, 'status')}>
                    <option value=''>Select Status</option>
                    {allStatus && allStatus.map((data, k) => {
                        return (
                            <option value={data.statusId}>{data.adminStatus}</option>

                        )
                    })}
                    {/* <option className='active'>Active</option>
                    <option className='inactive'>Inactive</option> */}
                </select>
            </div>

        </div>
        <div className='row' style={{ marginTop: '20px' }}>

            <div className='col-xs-12 col-sm-12'>
                {userData ?

                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th>Details</th>
                                <th>Name</th>
                                <th>Mobile Number</th>
                                {/* <th>Card No</th> */}
                                <th>Card Status</th>
                                <th>E-Sign</th>
                                <th>E-Nach</th>
                                <th>Email</th>
                                <th>Full Kyc</th>
                                <th>Min Kyc</th>

                            </tr>
                        </thead>
                        <tbody>


                            {userData && userData.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                return (
                                    <tr>
                                        <td><i className='fa fa-info' style={{ cursor: 'pointer' }} onClick={(e) => userDetailsinfo(data)}></i></td>
                                        <td>{data.name}</td>
                                        <td>{data.mobileNo}</td>
                                        {/* <td>{data.cardNo}</td> */}
                                        <td>{data.cardStatus}</td>
                                        <td>{data.enachStatus==='true'?<i className='fa fa-check'></i> : <i className='fa fa-close'></i>}</td>
                                        <td>{data.esignStatus==='true'?<i className='fa fa-check'></i> : <i className='fa fa-close'></i>}</td>
                                        <td>{data.email}</td>
                                        <td>{data.fullKyc === true ? <i className='fa fa-check'></i> : <i className='fa fa-close'></i>}</td>
                                        <td>{data.minKyc === true ? <i className='fa fa-check'></i> : <i className='fa fa-close'></i>}</td>
                                   


                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>
                    : ""
                }
            </div>
        </div>
        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                                <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(userData, 'back')}></button></div>
                                <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + userData.length}</div>
                                <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(userData, 'forward')}></button></div>
                                <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
                        </div>
    </div>
)

export default CardsUser