import React, { Component } from 'react'
import AutoLogs from '../../../presentationals/Admin/BulkActions/AutomationLogs.component'
import AutoDetail from '../../../presentationals/Admin/BulkActions/AutomationDetailPopup.component'
import { APIS } from '../../../../utils/api-factory'
import Loader from '../../../presentationals/Loader/graphloader.component'
class AutomationLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            automationData: '',
            mailValue: '',
            automationDetail: [],
            AutomationDetailPopup: false,
            FilteredData: [],
            pageIndexToShow: 0,
            loaderState: false,
            mailTypeData: ''
        }
    }
    componentDidMount() {
        this.setState({loaderState:true})
        fetch(APIS.AUTOMATION_TYPES)
            .then(res => res.json())
            .then(res => {
                this.setState({ mailTypeData: res,loaderState:false })
            })
    }

    componentWillMount() {
        // this.setState({ loaderState: true })
        // fetch(APIS.AUTOMATION_MAIL_LOGS)
        //     .then(res => res.json())
        //     .then(res => {
        //         this.setState({ automationData: res, loaderState: false })
        //     })
    }

    render() {
        return (
            <div className="" >
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <div style={{ display: this.state.AutomationDetailPopup == true ? 'none' : 'block' }}>
                    <AutoLogs
                        mailTypeData={this.state.mailTypeData}
                        mailType={this.mailType.bind(this)}
                        mailValue={this.state.mailValue}
                        automationData={this.state.automationData}
                        postData={this.postData.bind(this)}
                    />
                </div>
                {this.state.AutomationDetailPopup == true ?
                    <AutoDetail
                        automationDetail={this.state.automationDetail}
                        closeCouponPopup={this.closeCouponPopup.bind(this)}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                    />
                    : ""}

            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    closeCouponPopup() {
        this.setState({ AutomationDetailPopup: false })
    }

    postData(date, mail, mailType) {
        this.state.automationDetail = []
        for (let i = 0; i < mail.length; i++) {
            let automationemailId = mail[i]
            this.state.automationDetail.push({ date: date, mails: automationemailId.emailId, subject: automationemailId.subject, mailType: mailType })
        }
        this.setState({
            automationDetail: this.state.automationDetail,
            AutomationDetailPopup: true
        })
    }

    mailType(e) {
        this.setState({ loaderState: true })
        fetch(APIS.AUTOMATION_MAIL_LOGS_UPDATED + e.target.value)
            .then(res => res.json())
            .then(res => {
                this.setState({ automationData: res, loaderState: false })
            })
        this.setState({ mailValue: e.target.value === '1801' ? 'INCOMPLETEAPPLICATION' : e.target.value === '1802' ? "INCOMINGPAYMENT" : e.target.value === '1803' ? "DUEPAYMENT" : e.target.value === '1804' ? "DOCMAIL" : e.target.value === '1805' ? "APPINSTALLEDMAIL" : e.target.value === '1806' ? "DATEOFBIRTH" : "" })
    }
}

export default AutomationLogs;