import React from 'react'
import UploadUserCsv from './uploadUser.container'
import AttendanceCsv from './AttendanceCsv.container'
import WagesCsv from './wages.conatiner'

class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewState: 'upload-user'
        }

    }
    tabHandler(type) {
        if (type === 'upload-user') {
            this.setState({ viewState: 'upload-user' })
        }
        if (type === 'attendance') {
            this.setState({ viewState: 'attendance' })
        }
        if (type === 'wages') {
            this.setState({ viewState: 'wages' })
        }

    }
    render() {

        return (
            <div style={{width:'98%'}}>
                <div className='row'>
                    <div className={this.state.viewState==='upload-user'?'col-sm-2 col-xs-12 tabs-new-active':"col-sm-2 col-xs-12 tabs-new"}   onClick={() => this.tabHandler('upload-user')}>Upload User</div>
                    <div className={this.state.viewState==='attendance'?'col-sm-2 col-xs-12 tabs-new-active':"col-sm-2 col-xs-12 tabs-new"} onClick={() => this.tabHandler('attendance')}>Upload Attendance</div>
                    <div className={this.state.viewState==='wages'?'col-sm-2 col-xs-12 tabs-new-active':"col-sm-2 col-xs-12 tabs-new"} onClick={() => this.tabHandler('wages')}>Upload Wages</div>

                </div>
                {this.state.viewState === 'upload-user' ?
                    <UploadUserCsv />
                    : ""}
                {this.state.viewState === 'attendance' ?
                    <AttendanceCsv />
                    : ""}
                {this.state.viewState === 'wages' ?
                    <WagesCsv />
                    : ""}
            </div>
        )
    }
}
export default Tabs