import React, { useState } from 'react'
import moment from 'moment'
import CACDashboard from './CACDashboard';
import IncomeExpenses from './IncomeExpenses';
import TicketSize from './TicketSize';
import ApprovedLoans from './ApprovedLoans';
import CollectionEfficiency from './CollectionEfficiency';

const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    const FirstDay = moment().subtract(differenceDate(), 'days');
    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}

export default function main() {
    const [tab, setTab] = useState('cac');
    const [fromDate, setFromDate] = useState(InitalDate('fromDate'))
    const [toDate, setToDate] = useState(InitalDate('toDate'))
    const [applyFilter, setapplyFilter] = useState(false);
    const [clearFilter, setclearFilter] = useState(false);
    const [clearbtnShow, setClearBtnShow] = useState(false);
    const [ActiveMonthFilter, setActiveMonthFilter] = useState('3month')
    const [edit ,setEdit] =useState(false)

    const handleTabs = (type) => {
        setTab(type)
        setEdit(false)
        setActiveMonthFilter('3month')
        setFromDate(InitalDate('fromDate'))
        setToDate(InitalDate('toDate'))
        setclearFilter(true)
        setClearBtnShow(false)
    }

    const handleDate = (e, type) => {
        if (type === 'fromDate') {
            setFromDate(e.target.value)
            setClearBtnShow(true)

        } else if (type === 'toDate') {
            setToDate(e.target.value)
            setClearBtnShow(true)

        } else if (type === 'applyFilter') {
            setapplyFilter(true);
        } else if (type === 'clearFilter') {
            setclearFilter(true)
            setClearBtnShow(false)
            setActiveMonthFilter('3month')
            setFromDate(InitalDate('fromDate'))
            setToDate(InitalDate('toDate'))
        }
    }

    const handleApplyFilter = () => {
        setapplyFilter(false)
    }
    const handleclearFilter = () => {
        setclearFilter(false)
    }

    const handlemonthFilter = (type) => {
        setActiveMonthFilter(ActiveMonthFilter === type ? '' : type)
        setClearBtnShow(true)
    }

    const handleEdit =()=>{
        setEdit(!edit)
    }

    return (
        <div className="MISDashboard">
            <div className="tabs">
                <div className={`subtabss ${tab === 'cac' ? 'active' : ''}`} onClick={() => handleTabs('cac')}>
                    <h5>CAC</h5>
                </div>
                <div className={`subtabss ${tab === 'ticketSize' ? 'active' : ''}`} onClick={() => handleTabs('ticketSize')}>
                    <h5>Ticket Size</h5>
                </div>
                <div className={`subtabss ${tab === 'approvedLoans' ? 'active' : ''}`} onClick={() => handleTabs('approvedLoans')}>
                    <h5>Approved Loans</h5>
                </div>
                <div className={`subtabss ${tab === 'collectionEfficiency' ? 'active' : ''}`} onClick={() => handleTabs('collectionEfficiency')}>
                    <h5>Collection Efficiency</h5>
                </div>
                <div className={`subtabss ${tab === 'incomeExpenses' ? 'active' : ''}`} onClick={() => handleTabs('incomeExpenses')}>
                    <h5>Income & Expenses</h5>
                </div>
            </div>

            <div className="Sections">
                <div className="subSections" style={{position:'relative'}}>
                    <div className="Editbox">
                        <button className='editbtn' onClick={()=>handleEdit()}>{edit ? 'Close' : 'Edit'}</button>
                    </div>
                    <div className="Filterations">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                <div className="inputgrp">
                                    <label htmlFor="fromDate" className='inputlabel'>From</label>
                                    <input type="date" id='fromDate' className='inputtag' value={fromDate} onChange={(e) => handleDate(e, 'fromDate')} disabled={edit ? true:false}/>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                <div className="inputgrp">
                                    <label htmlFor="toDate" className='inputlabel'>To</label>
                                    <input type="date" id='toDate' className='inputtag' value={toDate} min={fromDate} onChange={(e) => handleDate(e, 'toDate')} disabled={edit ? true:false}/>
                                </div>
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12" style={{ marginTop: '20px' }}>
                                <div className="inputgrp">
                                    <label className='inputlabel'>Month Filter</label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button className={`filterbtn2 ${ActiveMonthFilter === '3month' ? 'active' : ''}`} onClick={() => handlemonthFilter('3month')}disabled={edit ? true:false} >last 3 Month</button>
                                        <button className={`filterbtn2 ${ActiveMonthFilter === '6month' ? 'active' : ''}`} onClick={() => handlemonthFilter('6month')}disabled={edit ? true:false} >last 6 Month</button>
                                        <button className={`filterbtn2 ${ActiveMonthFilter === '1year' ? 'active' : ''}`} onClick={() => handlemonthFilter('1year')}disabled={edit ? true:false} >last 1 Year</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginTop: '20px' }}>
                                <div style={{ display: 'flex' }}>
                                    <button className='filterbtn' style={{ marginTop: '48px', marginRight: '20px' }} onClick={(e) => handleDate(e, 'applyFilter')} disabled={edit ? true:false}>Apply Filter</button>
                                    {
                                        clearbtnShow ?
                                            <button className='filterbtnReverse' style={{ marginTop: '48px' }} onClick={(e) => handleDate(e, 'clearFilter')} disabled={edit ? true:false}>Clear Filter</button>
                                            : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    {
                        tab === 'cac' ?
                            <CACDashboard fromDate={fromDate} toDate={toDate} handleApplyFilter={handleApplyFilter} applyFilter={applyFilter} clearFilter={clearFilter} handleclearFilter={handleclearFilter} edit={edit} handleEdit={handleEdit} ActiveMonthFilter={ActiveMonthFilter}/>
                            : ''}
                    {
                        tab === 'ticketSize' ?
                            <TicketSize fromDate={fromDate} toDate={toDate} handleApplyFilter={handleApplyFilter} applyFilter={applyFilter} clearFilter={clearFilter} handleclearFilter={handleclearFilter} edit={edit} handleEdit={handleEdit} ActiveMonthFilter={ActiveMonthFilter}/>
                            : ''}
                    {
                        tab === 'approvedLoans' ?
                            <ApprovedLoans fromDate={fromDate} toDate={toDate} handleApplyFilter={handleApplyFilter} applyFilter={applyFilter} clearFilter={clearFilter} handleclearFilter={handleclearFilter} edit={edit} handleEdit={handleEdit} ActiveMonthFilter={ActiveMonthFilter}/>
                            : ''}
                    {
                        tab === 'collectionEfficiency' ?
                            <CollectionEfficiency fromDate={fromDate} toDate={toDate} handleApplyFilter={handleApplyFilter} applyFilter={applyFilter} clearFilter={clearFilter} handleclearFilter={handleclearFilter} edit={edit} handleEdit={handleEdit} ActiveMonthFilter={ActiveMonthFilter}/>
                            : ''}
                    {
                        tab === 'incomeExpenses' ?
                            <IncomeExpenses fromDate={fromDate} toDate={toDate} handleApplyFilter={handleApplyFilter} applyFilter={applyFilter} clearFilter={clearFilter} handleclearFilter={handleclearFilter} edit={edit} handleEdit={handleEdit} ActiveMonthFilter={ActiveMonthFilter}/>
                            : ''}


                </div>
            </div>
        </div>
    )
}
