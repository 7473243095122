import React, { useState, useEffect } from "react";
import { activeInactiveProductApi, deleteDoctorProduct, getDoctorProducts, getDoctorProductsCount, saveProocessingFeeTypeApi } from "./actionCreator";
import Calulation from "./calculation";
import { useNavigate } from "react-router-dom";
import Edit from './newAddProductConfig'
import { FormControlLabel } from "@mui/material";
import { styled } from '@mui/material/styles';

import Switch, { SwitchProps } from '@mui/material/Switch';
import Loader from '../../../presentationals/Loader/Loader.component'
import { APIS } from "../../../../utils/api-factory";
const DocProduct = () => {
    const [totalCount, setTotalCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [noOfRows, setNoOfRows] = useState(10)
    const [productData, setProductsData] = useState([])
    const [nbfcId, setnbfcId] = useState('')
    const [nbfcForId, setNbfcs] = useState('')
    const [individualData, setIndividualData] = useState('')

    const [showCalc, setCalc] = useState(false)
    const [showEdit, setEditPopup] = useState(false)
    const [processingFeeType, setProcessingFeeType] = useState('')
    const [loaderState, setLoader] = useState(false)

    let navigate = useNavigate()
    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
            },
            '&:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            '&:after': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 12,
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
        },
    }));
    const getData = (page, entry, id) => {
        getDoctorProducts(page, entry, id, callBack => {
            setProductsData(callBack.data)
            getCount(nbfcId)
        })
    }

    useEffect(() => {
        getNbfcList()
        getData(pageNumber, noOfRows, nbfcId)
    }, [])
    const getCount = (id) => {
        getDoctorProductsCount(id, callBack => {
            setTotalCount(callBack.data)
        })
    }
    const paginationHandlerNew = (type, pageNumber) => {
        if (type === 'main-left') {
            if (pageNumber > 1) {
                setPageNumber(pageNumber - 1)
                getData(pageNumber - 1, noOfRows)
            }
        }
        if (type === 'main-right') {
            if (productData.length >= 10) {
                setPageNumber(pageNumber + 1)
                getData(pageNumber + 1, noOfRows)
            }
        }


    }
    const deleteMethod = (data) => {
        deleteDoctorProduct(data.productId, callback => {
            getData(pageNumber, noOfRows)

        })
    }
    const filterData = (e) => {
        getData(pageNumber, noOfRows, e)
        getCount(e)
    }
    const getNbfcList = (data) => {
        fetch(APIS.GET_LIST_OF_NBFC + '')
            .then(res => res.json())
            .then(res => {
                const nbfcIdArr = []
                res && res.map((data, i) => {
                    nbfcIdArr.push({ name: data.name, code: data.nbfcId })
                })
                setNbfcs(nbfcIdArr)

            })
    }
    const ShowCalculator = (data) => {
        setCalc(true)
        setIndividualData(data)
        setEditPopup(false)

    }
    const edit = (data) => {
        setEditPopup(true)
        setIndividualData(data)
        setCalc(false)

    }
    const addProduct = () => {
        // navigate(routes.DOCTOR_PRODUCT_CONFIG)
        setEditPopup(true)
        setIndividualData({})
        setCalc(false)

    }
    const closePopup = () => {
        setCalc(false)
        setEditPopup(false)
        if (showEdit === true) {
            getData(pageNumber, noOfRows, nbfcId)
        }
    }
    const changestatus = (data) => {
        console.log(data)
        let status = data.status

        activeInactiveProductApi(!status, data.productId, callback => {
            console.log(callback)
            getData(pageNumber, noOfRows, nbfcId)
        })
    }
    const saveProductType = (data) => {
        setLoader(true)
        saveProocessingFeeTypeApi(data.productId, processingFeeType, callBack => {
            console.log(callBack)
            setLoader(false)
            getData(pageNumber, noOfRows, nbfcId)
        })

    }

    const commentHandlerInputNew = (e) => {
        setProcessingFeeType(e.target.value)

    }
    return (
        <>
            {showCalc ?
                <Calulation closePopup={closePopup} showCalc={showCalc} productData={individualData} />
                : ""}
            {showEdit ?
                <Edit closePopup={closePopup} showCalc={showCalc} productData={individualData} />
                : ""}
            {loaderState ?
                <Loader />
                : ""}
            <div className="container-fluid">
                <div className="" style={{display:'flex'}}>
<h3 style={{marginLeft:'30px'}}><b>Subvention</b></h3>
                    {/* <div style={{textAlign:'right',marginRight:'50px'}}> */}
                    <div style={{marginTop:'15px',marginLeft:'20px'}}>
                        <button className="btn btn-primary " style={{padding:'5px 10px'}} onClick={() => addProduct()}><i className="fa fa-plus-square"></i></button>
                   </div>
                    {/* </div> */}
                </div>
                <div className='table-responsive full-height '>
                    {productData.length > 0 ?
                        <>

                            <div className='mt-3 phocket-table-new' style={{ overflowX: 'auto' }}>
                                <table className=''>
                                    <thead >
                                        <tr>
                                            <th>Calculate</th>
                                            <th scope="col" > Product Name</th>
                                            <th scope="col" > Total Emi</th>
                                            <th scope="col" > Advance Emi</th>
                                            <th scope="col" > Processing Fes Including GST INR</th>
                                            <th scope="col" >Processing Fees Including GST Rate</th>
                                            <th scope="col"> Processing Fee Type</th>
                                            <th scope="col"> Saved Processing Fee Type</th>
                                            <th scope="col" >SubVention Rate</th>
                                            <th scope="col" >Interest</th>
                                            <th scope="col" >Nbfc Take Percent</th>
                                            {/* revert */}
                                            <th scope="col" >Status</th>


                                            <th scope="col" >Edit</th>

                                            <th scope="col" >Delete </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productData.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td><i className="fa fa-calculator" style={{ cursor: 'pointer' }} onClick={(r) => ShowCalculator(row)}></i></td>
                                                    <td>{row.productName}</td>
                                                    <td>{row.totalEmi}</td>
                                                    <td>{row.advanceEmi}</td>
                                                    <td>{row.processingFesIncludingGSTINR}</td>
                                                    <td>{row.processingFesIncludingGSTRate}</td>
                                                    {/* <td>{row.processingFeeType}</td> */}
                                                    <td> <div style={{ display: 'flex', width: '220px' }} >
                                                        <select className='form-control' style={{ border: '1px solid #0c3953' }} onChange={(e) => commentHandlerInputNew(e, row.productId)}>
                                                            <option value="">Select Type</option>
                                                            <option value="fixed">fixed</option>
                                                            <option value="Rate">Rate</option>

                                                        </select>

                                                        &nbsp; {<><span className="fa fa-save loan-id-save-btn" style={{ fontSize: '20px', marginTop: '10px', cursor: 'pointer' }} onClick={e => saveProductType(row)} /></>}
                                                    </div>
                                                    </td>
                                                    <td>{row.processingFeeType}</td>
                                                    <td>{row.subventionRate}</td>

                                                    <td>{row.interest}</td>
                                                    <td>{row.nbfcTakePercent}</td>
                                                    {/* revert */}
                                                    <td >
                                                        <FormControlLabel
                                                            style={{ marginLeft: '20px' }}
                                                            control={<Android12Switch checked={row.status} onChange={() => changestatus(row)} />}
                                                        // label="Assign"
                                                        /></td>
                                                    <td><i className='fa fa-edit' style={{ cursor: 'pointer' }} onClick={() => edit(row)}></i></td>
                                                    <td><i className='fa fa-trash' style={{ cursor: 'pointer' }} onClick={() => deleteMethod(row)}></i></td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>


                        </>
                        : null}
                </div>
                {productData.length > 0 ?

                    <div className='' style={{ marginTop: '20px' }}>
                        <div className='col-sm-5 col-xs-12 paginationBtn' style={{ background: '#a7c4e5',padding:'10px' }} onClick={() => paginationHandlerNew('main-left', pageNumber)}>
                            <button className='' style={{ width: '100%', color: '#000' }} >
                                <i className='fa fa-angle-double-left'></i>
                            </button>
                        </div>

                        <div className='col-sm-2 col-xs-12 text-center'>
                            <div className=''>
                                <div> Page Number : {pageNumber}</div>

                                <div> Total Counts :{totalCount}</div>
                            </div>
                        </div>

                        <div className='col-sm-5 col-xs-12 paginationBtn' style={{ background: '#a7c4e5',padding:'10px' }} onClick={() => paginationHandlerNew('main-right', pageNumber)}>
                            <button className='' style={{ width: '100%', color: '#000' }} >
                                <i className='fa fa-angle-double-right'></i>
                            </button>
                        </div>
                    </div>
                    : ""}

            </div>
        </>
    )
}
export default DocProduct