import React from 'react'
import Ui from '../../../presentationals/CardsUtility/CardDetails.component';
import { cardUtility } from '../../Admin/AdminActionCreator.component'
import { _formatDateInDash } from '../../../../utils/validation'
import Loader from '../../../presentationals/Loader/Loader.component'
import Sidepopup from '../../../presentationals/Popup/Popup.component'
var same = ''
var validation2 = ''
class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            loaderState: false,
            showTable: false,
            tableData: '',
            creditProvidedDate: '',
            creditUsedData: '',
            currentDate: '',
            emiConversionDate: '',
            data: {
                creditAmount: 0,
                usedAmount: 0,
                creditProvidedDate: '',
                creditUsedData: '',
                currentDate: '',
                convertToEmi: false,
                loanEmi: 0,
                emiConversionDate: "",
                isMinmunDuePaid: false
            }
        }

    }
    closePopup() {
        this.setState({
            popupState: false
        })
    }
    validation1() {
        var d1 = this.state.creditUsedData;
        var d2 = this.state.currentDate;


        validation2 = d1 < d2;
    }
    validation(d1, d2) {
        var d1 = this.state.creditProvidedDate;
        var d2 = this.state.creditUsedData;


        same = d1 < d2;
    }
    onchangeHandler(e, type) {
        let data = Object.assign({}, this.state.data)
        if (type === 'creditAmount') {
            data.creditAmount = e.target.value
        }
        if (type === 'usedAmount') {
            data.usedAmount = e.target.value
        }
        if (type === 'creditProvidedDate') {
            if (e) {
                data.creditProvidedDate = _formatDateInDash(e)
                this.setState({
                    creditProvidedDate: e
                })
            } else {
                data.creditProvidedDate = ''
                this.setState({
                    creditProvidedDate: ''
                })
            }
        }
        if (type === 'creditUsedData') {

            if (e) {
                data.creditUsedData = _formatDateInDash(e)
                this.setState({
                    creditUsedData: e
                })
            } else {
                data.creditUsedData = ''
                this.setState({
                    creditUsedData: ''
                })
            }

        }
        if (type === 'convertToEmi') {
            data.convertToEmi = e.target.value === 'true' ? true : e.target.value === 'false' ? false : ""
        }
        if (type === 'loanEmi') {
            data.loanEmi = e.target.value
        }
        if (type === 'currentDate') {
            if (e) {
                data.currentDate = _formatDateInDash(e)
                this.setState({
                    currentDate: e
                })
            } else {
                data.currentDate = ''
                this.setState({
                    currentDate: ''
                })
            }
        }

        if (type === 'emiConversionDate') {
            if (e) {
                data.emiConversionDate = _formatDateInDash(e)
                this.setState({
                    emiConversionDate: e
                })
            } else {
                data.emiConversionDate = ''
                this.setState({
                    emiConversionDate: ''
                })
            }
        }
        if (type === 'isMinmunDuePaid') {
            data.isMinmunDuePaid = e.target.value === 'true' ? true : e.target.value === 'false' ? false : ""
        }
        this.setState({ data })

    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    submit() {
        let flag = true
        if (this.state.data.creditAmount !== 0 && this.state.data.usedAmount !== 0) {
            if (this.state.creditProvidedDate && this.state.creditUsedData) {
                this.validation()
            }
            if (this.state.currentDate && this.state.creditUsedData) {
                this.validation1()
            }
            if (same === false) {
                // alert('Credit Provided Date cannot be greater then Credit Used Date')
                this.setState({ popupState: true, popupStatus: 'Credit Provided Date cannot be greater then Credit Used Date' }, () => this.removePopup())
            }
            if (validation2 === false) {
                this.setState({ popupState: true, popupStatus: 'Credit used date cannot be greater than current date' }, () => this.removePopup())

                // alert('Credit used date cannot be greater than current date')
            }

            if (this.state.data.convertToEmi === true) {
                if (this.state.data.loanEmi !== 0 && this.state.data.convertToEmi !== '') {

                    flag = true
                } else {
                    flag = false
                    this.setState({ popupState: true, popupStatus: 'Enter Emi and Conversion Date' }, () => this.removePopup())

                    // alert('Enter Emi and Conversion Date')
                }
            }
            if (same === true && validation2 === true && flag === true) {
                this.setState({ loaderState: true })
                cardUtility(this.state.data, callback => {
                    if (callback) {
                        this.setState({ tableData: callback, showTable: true, loaderState: false })
                    } else {
                        this.setState({ showTable: false, loaderState: false })

                    }
                })
            }
        } else {
            this.setState({ popupState: true, popupStatus: 'Enter All Fields' }, () => this.removePopup())

            // alert('Enter All Fields')
        }
    }

    render() {
        return (
            <div>
                <Ui
                    state={this.state}
                    data={this.state.data}
                    onchangeHandler={this.onchangeHandler.bind(this)}
                    submit={this.submit.bind(this)} />
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupState ?
                    <Sidepopup popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)} />
                    : ""}
            </div>
        )
    }
}
export default Cards