import React from 'react'
import ReactTable from "react-table";
import ReactHtmlParser from 'react-html-parser';

const CommunicationBulk = ({ bulkCommunicationLogs, showEmail, getPageValue,
    pageIndexToShow, getFilterValue, FilteredData }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">

                <h3 className="text-center blue-text">BULK COMMUNICATION HISTORY</h3>

                {bulkCommunicationLogs != null && bulkCommunicationLogs != '' && bulkCommunicationLogs.length > 0 ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <td>status</td>
                                    <td>Type</td>
                                    <td>Subject</td>
                                    <td>Body</td>
                                    <td>Date</td>
                                    <td> Send By</td>
                                    <td>Count</td>


                                </tr>

                            </thead>
                            <tbody>
                                {bulkCommunicationLogs && bulkCommunicationLogs.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{data.status}</td>
                                            <td> {data.communicationType}</td>
                                            <td> {data.communicationSubject}</td>
                                            <td>  <div style={{ width: '200px', overflow: 'auto' }}
                                                dangerouslySetInnerHTML={{
                                                    __html: data.communicationMatter
                                                }}
                                            ></div></td>
                                            <td>{data.date}</td>
                                            <td>{data.empId}</td>
                                            <td> <a className="pointer" style={{ color: 'blue', cursor: 'pointer', fontWeight: '700' }} onClick={e => showEmail(data)}>{data.email != null ? data.email.length : ""}</a></td>


                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : ""}
            </div>
        </div>
    </div>
)

export default CommunicationBulk;