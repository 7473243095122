import React from 'react'

const StampDuty = ({fileHandler ,uploadHandler,nameOfFile}) => (
    <div className='container'>
        <div className='row'>
            <h3 className="text-center blue-text">Upload Stamp Duty</h3>
        </div>
        <div className='row'>
            <div className='col-sm-4 col-xs-12'></div>
            <div className='col-sm-4 col-xs-12'>
            <div className="image-upload-wrap">
                <input
                    type='file' onChange={(e)=>fileHandler(e)} accept='.pdf'

                    className="file-upload-input" />
                <div className="drag-text">
                    <h5>{nameOfFile!==''?nameOfFile:'Drag & drop or select Pdf File'}  </h5>
                </div>
            </div>
                <div className='text-center'>
                    <button className='profile-save-btn' onClick={()=>uploadHandler()}>Upload</button>
                </div>
            </div>
            <div className='col-sm-4 col-xs-12'></div>
        </div>

    </div>
)

export default StampDuty