import React from "react";
import Budeget from "../../../../maketingimages/budeget.png";
function BudgetBreakup({dashboardcounts}) {
  return (
    <>
      <div
        className="bg-white  shadow marketing-main-css"
        style={{ borderRadius: "16px", padding: '10px', marginTop: '20px', minHeight: '330px' }}
      >
        <div className="d-flex flex-column justify-content-around">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex align-item-center">
              <h5 className="totalcount1 card-heading">
                <b>Budget Breakup</b>
              </h5>
            </div>
          </div>
        </div>
        <div className="row">

          <div className="col-sm-12 col-xs-12 ">
            <div className="sm-budget" style={{ minHeight: '130px' }}>
              <div className="col-sm-4">
                <img src={Budeget} alt="" style={{ width: '80%' }} />
              </div>
              <div className="col-sm-8">
                <div style={{ display: 'inline-flex', marginTop: '3px' }}>
                  <h2 className="text-center">
                    <strong>&#8377; {dashboardcounts.totalBudget}</strong>
                  </h2>
                  &nbsp;&nbsp;
                  {/* <span className="ms-2" style={{ color: "#377E36", marginTop: '22px', fontSize: '12px' }}>
                    <p
                      style={{
                        backgroundColor: "#E0F0E4",
                        padding: "3px",
                        borderRadius: '5px',
                        fontSize: '12px',
                        marginTop:'3px'

                      }}
                      className="mb-0"
                    >
                      +2.5%
                    </p>
                  </span> */}


                </div>
                <h6 className="" style={{ marginTop: '2px', marginLeft: '27px' }}><strong>Budgeted Amount</strong></h6>
                <h6 style={{ color: 'rgba(0, 0, 0, 0.45)', marginTop: '2px', marginLeft: '22px' }}>(For Current Month)</h6>
              </div>
            </div>
          </div>

        </div>


        {/* <div className="row budget">
          <div className=" position-relative d-flex flex-column justify-content-center bottom-0 left-0">
            <img src={Budeget} alt="" className="position-absolute" />
            <div className="d-inline-flex justify-content-center">
              <h4 className="text-center">
                <b>&#8377; 3,00,000</b>
              </h4>
              <span className="ms-2" style={{ color: "#377E36" }}>
                <p
                  style={{
                    backgroundColor: "#E0F0E4",
                    padding: "5px",
                    width: "50px",
                  }}
                  className="mb-0"
                >
                  +2,5%
                </p>
              </span>
            </div>
            <p className="text-center">
              <b>Budgeted Amount</b>
            </p>
          </div>
        </div> */}

        <div className="row " style={{ marginTop: '10px' }}>
          <div className="col-sm-6 col-xs-12 ">
            <div className="sm-budget" >
              <p className="text-center" style={{ marginTop: '30px' }}>
                <b>Amount Utilized</b>
              </p>
              <h4 className="text-center">
                <b>&#8377; {dashboardcounts.totalUtilizedBudget}</b>
              </h4>
            </div>
          </div>
          <div className="col-sm-6 col-xs-12 ">
            <div className="sm-budget" >
              <p className="text-center" style={{ marginTop: '30px' }}>
                <b>Remaining Amount</b>
              </p>
              <h4 className="text-center">
                <b>&#8377; {dashboardcounts.totalBudgetLeft}</b>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BudgetBreakup;
