import React from 'react';

const Contentblog = ({ loandocument,
    DocumentValidationMsg, userDetail }) => (
        <div className="container-fluid full-height">
            <div className="row">
                <div className="well center-block">
                    <h2 className="text-center blue-text"><strong>Blogs!</strong></h2>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-addon">
                                </div>
                                <input type="text" placeholder="Tittle" name="name" className="form-control" />

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <label htmlFor="identityProof" className="input-field-label-new">
                                <span className="fa fa-paperclip"></span>
                            </label>
                            <span className="font-12px document-upload-ellipsis">{userDetail.IDProofState}</span>
                            <input multiple="multiple" accept="image/png , image/jpeg , video/mp4" type="file" className="form-control display-none" id="identityProof" onChange={(e) => loandocument(TYPE.ADHAR_CARD, e)} />
                            <div className="font-12px margin-left-25px validation-color height-25px">
                                {DocumentValidationMsg.field == "IDProof" ? DocumentValidationMsg.msg : ""}
                                <div className="darkGreen-text hidden" id="adharText"><span className=" fa fa-check-circle-o" />&nbsp;Successfully Uploaded</div>
                            </div>
                            <button className="profile-save-btn text-center"> submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
export default Contentblog;