import React from 'react'

const ConfirmationPopup = ({ cancelLoanConfirm, confirmationText, nameOfUser }) => (
    <div className="background-blur income">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <table className='income-table'>
                        <thead>
                            <tr>
                                <th>Name of user:</th>
                                <td>{nameOfUser.userName}</td>
                            </tr>
                            <tr>
                                <th>Application Id:</th>
                                <td>{nameOfUser.applicationId}</td>
                            </tr>
                            <tr>
                                <th>Mobile Number:</th>
                                <td>{nameOfUser.mobileNumber}</td>
                            </tr>
                        </thead>
                    </table>
                    <h4 className="text-center">{confirmationText}</h4>
                </div>
            </div>
            <div className="row from-group text-center">
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('yes')}>YES</button>
                </div>
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('no')}>NO</button>
                </div>
            </div>
        </div>
    </div>
)

export default ConfirmationPopup;