import React from 'react';

const DocumentUploadFormTwo = ({ merchantdocument, progressBarState, docType,
    progressValue, merchantDetail, listingOfCategory, merchantIdHandler, showCouponImage,
    allImages, offerDetails, couponIdHandler, coupondocument, couponImage }) => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <div className="max-width-500px">
                        <h3 className="text-center">Add Merchant Images</h3>
                        <select className="form-control form-group" onChange={(e) => merchantIdHandler(e)}>
                            <optgroup className="display-none">
                                <option selected="selected">Select Merchant</option>
                            </optgroup>
                            {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                                listingOfCategory.map((listing, i) => {
                                    return (
                                        <optgroup label="Merchant List" key={i}>
                                            {listing.Merchant != null && listing.Merchant.length > 0 ? listing.Merchant.map(data => {
                                                return (
                                                    <option value={data.merchantid}>{data.merchantname}</option>
                                                )
                                            }) : ""}
                                        </optgroup>
                                    )

                                }) : ""}
                        </select>
                        <div className="col-sm-6 col-xs-12">
                            <label className="block bold text-nowrap">Logo</label>
                            <label htmlFor="image1" className="input-field-label-new">
                                <span className="fa fa-paperclip"></span>
                            </label>
                            <span className="font-12px document-upload-ellipsis">{merchantDetail.image1}</span>
                            <input accept="image/png , image/jpeg" type="file" className="form-control display-none" id="image1" onChange={(e) => merchantdocument('image1', e)} />
                            <div className="font-12px margin-left-25px validation-color height-25px">
                                {docType == 'image1' && progressBarState == true ?
                                    <progress value={progressValue} max="100"></progress>
                                    : ""}
                                <div className="darkGreen-text hidden" id="image1Text"><span className=" fa fa-check-circle-o" />&nbsp;Successfully Uploaded</div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                            <label className="block bold">Agreement</label>
                            <label htmlFor="agreement" className="input-field-label-new">
                                <span className="fa fa-paperclip"></span>
                            </label>
                            <span className="font-12px document-upload-ellipsis">{merchantDetail.agreement}</span>
                            <input accept="application/pdf" type="file" className="form-control display-none" id="agreement" onChange={(e) => merchantdocument('agreement', e)} />
                            <div className="font-12px margin-left-25px validation-color height-25px">
                                {docType == 'agreement' && progressBarState == true ?
                                    <progress value={progressValue} max="100"></progress>
                                    : ""}
                                <div className="darkGreen-text hidden" id="agreementText"><span className=" fa fa-check-circle-o" />&nbsp;Successfully Uploaded</div>
                            </div>
                        </div>
                        {allImages != null && allImages != '' ?
                            <div className="upload-docs col-xs-12 col-sm-4 form-group">
                                <h6 className="font-12px text-capitalize">
                                    <span className="relative_position">Image
                                    {/* <button className="close coupon-delete-btn" onClick={e => deleteImage(allImages)} >&times;</button> */}
                                    </span>
                                </h6>
                                <img className="" src={allImages} />
                            </div>
                            : ""}
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <div className="max-width-500px">
                        <h3 className="text-center">Add Coupon Images</h3>
                        <select className="form-control form-group" onChange={(e) => couponIdHandler(e)}>
                            <optgroup className="display-none">
                                <option selected="selected">Select Coupons</option>
                            </optgroup>
                            <optgroup label="Coupons List">
                                {offerDetails != "" && offerDetails != null && offerDetails.length > 0 ?
                                    offerDetails.map((data,i) => {
                                        return (
                                            <option key={i} value={data.couponid}>{data.merchantname + "(" + data.title + ")"}</option>
                                        )
                                    }) : ""}
                            </optgroup>
                        </select>
                        <div className="col-sm-6 col-xs-12">
                            <label className="block bold text-nowrap">Image</label>
                            <label htmlFor="couponImage" className="input-field-label-new">
                                <span className="fa fa-paperclip"></span>
                            </label>
                            <span className="font-12px document-upload-ellipsis">{couponImage}</span>
                            <input accept="image/png , image/jpeg" type="file" className="form-control display-none" id="couponImage" onChange={(e) => coupondocument(e)} />
                            <div className="font-12px margin-left-25px validation-color height-25px">
                                {docType == 'couponImage' && progressBarState == true ?
                                    <progress value={progressValue} max="100"></progress>
                                    : ""}
                                <div className="darkGreen-text hidden" id="couponImageText"><span className=" fa fa-check-circle-o" />&nbsp;Successfully Uploaded</div>
                            </div>
                        </div>
                        {showCouponImage != null && showCouponImage != '' ?
                            <div className="upload-docs col-xs-12 col-sm-4 form-group">
                                <h6 className="font-12px text-capitalize">
                                    <span className="relative_position">Image
                                    {/* <button className="close coupon-delete-btn" >&times;</button> */}
                                    </span>
                                </h6>
                                <img className="" src={showCouponImage} />
                            </div>
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    )

export default DocumentUploadFormTwo;