export const UserDetail = (state = {}, action) => {
    switch (action.type) {
        case 'USER_DETAIL':
            return action.userDetail
        default: return state;
    }
}

export const NotificationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'USER_NOTIFICATION':
            return action.notification;
        default: return state;
    }
}