import React, { Component } from 'react'
import PhocketCouponReporting from '../../../presentationals/Admin/AdminCoupons/CouponReporting.Component'
import { AddmechantCoupon, getOfferDetail, deleteCoupon, getUsedCouponData } from './AddMerchentActionCreator.Component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'
import UsedCouponListing from '../../../presentationals/Admin/AdminCoupons/UsedCouponListing.component'

class CpnRpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationPopup: false,
      confirmationText: '',
      idForDelete: '',
      offerDetails: '',
      allCouponsState: true,
      couponDetailState: false,
      showList: '',
      usedCouponListingState: false,
      usedCouponData: '',
      FilteredData: [],
      pageIndexToShow: 0,
    }
  }

  componentWillMount() {
    this.props.getOfferDetail((callback) => {
      this.setState({ offerDetails: callback })
    })
  }

  render() {
    const { listingOfCategory } = this.props
    return (
      <div>
        <PhocketCouponReporting
          updatedata={this.updatedata.bind(this)}
          offerDetails={this.state.offerDetails}
          deleteCoupon={this.deleteCoupon.bind(this)}
          listToDisplay={this.listToDisplay.bind(this)}
          showList={this.state.showList}
          viewDetails={this.viewDetails.bind(this)}
          listingOfCategory={listingOfCategory}
        />
        {this.state.confirmationPopup == true ?
          <Confirmation
            cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
            confirmationText={this.state.confirmationText} />
          : ""}
        {this.state.usedCouponListingState == true ?
          <UsedCouponListing
            closeCouponPopup={this.closeCouponPopup.bind(this)}
            usedCouponData={this.state.usedCouponData}
            getFilterValue={this.getFilterValue.bind(this)}
            FilteredData={this.state.FilteredData}
            getPageValue={this.getPageValue.bind(this)}
            pageIndexToShow={this.state.pageIndexToShow} />
          : ""}
      </div>
    )
  }

  getFilterValue(filtered) {
    this.setState({ FilteredData: filtered })
  }

  getPageValue(pageIndex) {
    this.setState({ pageIndexToShow: pageIndex })
  }

  viewDetails(e, offer) {
    getUsedCouponData(offer.couponid,
      (callBack) => {
        this.setState({ usedCouponListingState: true, usedCouponData: callBack })
      })
  }

  closeCouponPopup() {
    this.setState({ usedCouponListingState: false })
  }

  listToDisplay(e) {
    this.setState({ showList: e.target.value })
  }

  cancelLoanConfirm(conType) {
    if (conType == 'yes') {
      deleteCoupon(this.state.idForDelete, (callBack) => {
        if (callBack == 'success') {
          this.setState({
            confirmationPopup: false,
          })
          this.props.getOfferDetail((callback) => {
            this.setState({ offerDetails: callback })
          })
        }
      })
    } else if (conType == 'no') {
      this.setState({ confirmationPopup: false })
    }
  }
  deleteCoupon(data) {
    this.setState({
      idForDelete: data.couponid,
      confirmationPopup: true,
      confirmationText: 'Are you sure to Delete ' + data.title
    })
  }
  updatedata(e, data) {
    this.props.updateOffer(data)
  }

}
const mapStateToProps = ({ allStatus }) => {
  return {
    allStatus: allStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    AddmechantCoupon,
    getOfferDetail
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CpnRpt);
;