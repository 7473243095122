import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

const HalfSalary = ({ allDetail }) => (
    <div>
        <div>Dear {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>Thank you for registering with BorrowBuddy, We're happy that you decided to avail our instant loan services.</div>
        <br />
        <div>As per our policy the loan amount we provide cannot exceed more than 100% of the applicant's take-home salary.</div>
        <br />
        <div>If interested please acknowledge this email.</div>
        <br />
        <div>For any further queries, please contact us: {MAIL_STRING.MOBILE_NO + ' or ' + MAIL_STRING.EMAIL_ID}</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default HalfSalary