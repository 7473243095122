import React from 'react'
import Ui from '../../../presentationals/Admin/Tea Garden/uploadUsers.component'
import {uploadByCsv} from './actioncreator'
import Sidepopup from '../../../presentationals/Popup/Popup.component'
import Loader from '../../../presentationals/Loader/Loader.component'
class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            nameFile: '',
            csvFile:'',
            teagardenId:'kCV6VBiWe9FlUQTuiBuBoASA7NsgkRAs',
            loaderState:false,
            isStaff:false
        }
    }
    staffHandler(e){
        this.setState({isStaff:e.target.value})
    }
    changeHandler(e) {
        // console.log(e.target.files)
        if ((e.target.files).length > 0) {
            this.setState({ nameFile: e.target.files[0].name ,csvFile:e.target.files[0]})
        } else {
            this.setState({ nameFile: '' })

        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
closePopup(){
    this.setState({popupState:false})
}
    sumbit(){
        if(this.state.csvFile !==''&& this.state.csvFile !==undefined){
            this.setState({loaderState:true})
        uploadByCsv(this.state.csvFile,this.state.teagardenId,this.state.isStaff,callBack=>{
            // console.log(callBack)
            if(callBack==='success'){
            this.setState({ popupState: true, popupStatus: 'Uploaded Successfully !',csvFile:'',nameFile:'',loaderState:false }, () => this.removePopup())
            }else{
                this.setState({ popupState: true, popupStatus: 'Please try Again Later !',csvFile:'',nameFile:'' ,loaderState:false  }, () => this.removePopup())

            }
        })
    }else{
        this.setState({ popupState: true, popupStatus: 'Select File First' }, () => this.removePopup())

    }
    }



    render() {
        return (
            <div>

                <Ui
                staffHandler={this.staffHandler.bind(this)}
                isStaff={this.state.isStaff} sumbit={this.sumbit.bind(this)} nameFile={this.state.nameFile} changeHandler={this.changeHandler.bind(this)} />
                {this.state.popupState ?
                    <Sidepopup popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)} />
                    : ""}
                    {this.state.loaderState?
                    <Loader/>
                :""}
            </div>
        )
    }
}

export default Cards