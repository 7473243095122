import React, { useEffect, useState } from "react";
import { _preFormatDate } from "../../../../utils/validation";
import moment from "moment";
import { stamuploadedhistoryapi } from "../AdminActionCreator.component";
import NodataImage from '../../../../images/nodata.png'
import Loader from '../../../presentationals/Loader/Loader.component'
import Pagination from '@mui/material/Pagination';
import { APIS } from "../../../../utils/api-factory";

const UploadedHistory = () => {
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [searchByDateShow, setSearchDateToShow] = useState('')
    const [searchByEndDateTOShow, setsearchByEndDateTOShow] = useState('')
    const [objectdata, setObjectdata] = useState('')
    const [loaderstate, setloader] = useState(false)
    const [pageNo, setpageNo] = useState(1)
    const [size, setsize] = useState(20)
    const [totalPages, setTotalPages] = useState(1)

    const dateFilter = (e, type) => {
        if (type === 'dateTo') {
            setStartDate(_preFormatDate(e.target.value))
            setSearchDateToShow(e.target.value)
        }
        if (type === 'dateFrom') {
            setEndDate(_preFormatDate(e.target.value))
            setsearchByEndDateTOShow(e.target.value)
        }
        if (type === 'submit') {
            getData()
        }
    }

    const getData = () => {
        setloader(true)
        stamuploadedhistoryapi(pageNo, endDate, startDate, size, callback => {
            if (callback) {
                setObjectdata(callback.data)
                setTotalPages(callback.pageCount)
            }
            setloader(false)
        })


    }

    const handlePageChange = (event, value) => {
        setpageNo(value)
        getData()
    }

    useEffect(() => {
        // getData()
        fetch(APIS.STAMP_DUTY_UPLOAD_HISTORY + pageNo + '&size=' + size)
            .then(res => res.json())
            .then(json => {
                if (json) {
                    setObjectdata(json.data)
                    setTotalPages(json.pageCount)
                }
                setloader(false)
            })
    }, [pageNo])

    return (
        <div className="container-fluid">
            {loaderstate ? <Loader /> : ""}
            <h4 className="text-center"><b>Uploaded History</b></h4>

            <div className="row">
                <div className="col-sm-2 colxs-12">
                </div>
                <div className="col-sm-8 colxs-12">
                    <div className='row'>
                        <div className='col-sm-2 col-xs-12' style={{ marginTop: '15px' }}>
                            <label>From Date</label>
                            <input type='date' className='date-input-admin' style={{ width: '100%' }} value={searchByDateShow} onChange={(e) => dateFilter(e, 'dateTo')} />
                        </div>
                        <div className='col-sm-2 col-xs-12' style={{ marginTop: '15px' }}>
                            <label>To Date</label>
                            <input type='date' className='date-input-admin' style={{ width: '100%' }} value={searchByEndDateTOShow} onChange={(e) => dateFilter(e, 'dateFrom')} />
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <button className='new-cancle-btn' style={{ marginTop: '35px' }} onClick={(e) => dateFilter(e, 'submit')}>Apply Filter</button>
                        </div>
                    </div>
                    {objectdata !== '' && objectdata !== null && objectdata.length > 0 ?
                        <div className="phocket-table-new" style={{ overflow: 'auto', marginTop: '40px' }}>
                            <table className="">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Added By</th>
                                        <th>Total Uploaded</th>
                                        <th>Used</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {objectdata.map((data, i) => (
                                        <tr key={i}>
                                            <td>{data.date}</td>
                                            <td>{data.addedBy}</td>
                                            <td>{data.count}</td>
                                            <td>{data.used}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ float: 'right' }}>
                                <Pagination count={totalPages}
                                    page={pageNo}

                                    color="primary" onChange={handlePageChange} />
                            </div>
                        </div>
                        : <div style={{ textAlign: 'center', marginTop: '50px' }}> <img src={NodataImage} style={{ width: '30%' }} /></div>
                    }
                </div>
            </div>
        </div>
    )
}
export default UploadedHistory
