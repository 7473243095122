import React from 'react';
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const LoyalityDetails = ({ allAmbassadorData, refreshpage, backpage, forwordpage,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow  }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Loyality Leads</h3>
            </div>
        </div>

        <div className="row form-group">
            <div className="col-xs-12 text-right">
                <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button>

            </div>
        </div>

        <div className="row text-center">
            <div className="col-xs-12 overflow-x-auto no-padding">
                {allAmbassadorData&&allAmbassadorData.map((data,i)=>{
                    return(
                        <tr>
                        </tr>
                    )
                })}
                        </div>
        </div>
    </div>
)
export default LoyalityDetails;
