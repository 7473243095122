import React from 'react';
import Loader from '../../../presentationals/Loader/DotLoader.component'
import NoData from '../../../../images/nodata.png'
const userEmiHistory = ({ emiDetail, applicationChange, selectedValue, historyLoader, userDetail }) => (
    <div className="container-fluid">
        {historyLoader ?
            <Loader />
            : ""}
            
        {historyLoader == false && emiDetail != null && emiDetail != "" ?
            <div>
                <div className="row form-group">
                  
                    <div className="col-sm-3 col-xs-12" style={{marginBottom:'20px'}}>
                        <select className="form-control" onChange={e => applicationChange(e)} value={selectedValue}>
                            <optgroup className="display-none">
                                <option value="">select Application Id</option>
                            </optgroup>
                            <optgroup label="Application Id's">
                                {emiDetail != null && emiDetail != "" ?
                                    Object.keys(emiDetail).map((data, i) => {
                                        return (
                                            <option key={i} value={data}>{data}</option>
                                        )
                                    })
                                    : null}
                            </optgroup>
                        </select>
                    </div>
                </div>
                <div className="row">
                    {userDetail !== undefined && userDetail.loanFrom !== "vreedhi" ?

                        <div className="col-xs-12 phocket-table-new">
                            <table className="">
                                <thead>
                                    <tr>
                                        <th>days</th>
                                        <th>EMI Amount</th>
                                        <th>EMI Date</th>
                                        <th>Paid Date</th>
                                        <th>Date Difference</th>
                                        <th>Overdue Amount</th>
                                        <th>Paid Amount</th>
                                        <th>Salary Day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emiDetail != null && selectedValue != '' && emiDetail[selectedValue] != null && emiDetail[selectedValue].length > 0 ?
                                        emiDetail[selectedValue].map((tableData, j) => {
                                            return (
                                                <tr key={j}>
                                                    <td>{tableData.days}</td>
                                                    <td>{tableData.emiAmount}</td>
                                                    <td>{tableData.emiDate}</td>
                                                    <td>{tableData.paidDate}</td>
                                                    <td style={{ backgroundColor: tableData.dateDiffrenece <= 0 ? '#96ea98' : '#ecaba0' ,textAlign:'center'}}>{tableData.dateDiffrenece}</td>
                                                    <td>{tableData.overdue_amount}</td>
                                                    <td>{tableData.paidAmount}</td>
                                                    <td>{tableData.salaryday}</td>
                                                </tr>
                                            )
                                        }) : null}
                                </tbody>
                            </table>
                        </div>
                        : ""}
                    {userDetail !== undefined && userDetail.loanFrom === "vreedhi" &&( userDetail.applicationStatus === "Disbursed" || userDetail.applicationStatus === "Payment Overdue" || userDetail.applicationStatus === "On Going") ?

                        <div className="col-xs-12">
                            <table className="text-center phocket-table form-group">
                                <thead>
                                    <tr>
                                        <th>days</th>
                                        <th>EWI Amount</th>
                                        <th>EWI Date</th>
                                        <th>Paid Date</th>
                                        <th>Date Difference</th>
                                        <th>Overdue Amount</th>
                                        <th>Paid Amount</th>
                                        <th>Salary Day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emiDetail != null && selectedValue != '' && emiDetail[selectedValue] != null && emiDetail[selectedValue].length > 0 ?
                                        emiDetail[selectedValue].map((tableData, j) => {
                                            return (
                                                <tr key={j}>
                                                    <td>{tableData.days}</td>
                                                    <td>{tableData.emiAmount}</td>
                                                    <td>{tableData.emiDate}</td>
                                                    <td>{tableData.paidDate}</td>
                                                    <td style={{ backgroundColor: tableData.dateDiffrenece <= 0 ? '#96ea98' : '#ecaba0' }}>{tableData.dateDiffrenece}</td>
                                                    <td>{tableData.overdue_amount}</td>
                                                    <td>{tableData.paidAmount}</td>
                                                    <td>{tableData.salaryday}</td>
                                                </tr>
                                            )
                                        }) : null}
                                </tbody>
                            </table>
                        </div>
                        : userDetail.loanFrom === "vreedhi" ? <h3 className='text-center blue-text'>No Data</h3> : ""}
                </div>
            </div>
            : <div className='text-center'><img style={{width:'30%'}} src={NoData}/></div>}
    </div>

)

export default userEmiHistory;