import React, { useState } from 'react'

export default function LoanPortfolioReview() {
    
    const [nbfcFilter, setnbfcFilter] = useState('all')
    const [data, setData] = useState({
        data: [
            {
                overdueDay: 'current',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '678.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '1-7 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '678.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '8-14 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '3.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '15-30 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '3.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '31-60 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '3.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '61-90 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '36.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: 'Over 90 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '3678',
                outstandingBalancePercentage: 97.39
            }
        ],
        Total: {
            borrowerNumber: 1540,
            clientPercentage: 680.82,
            amountLeft: null,
            percentageInAreas: 0.00,
            outstandingBalance: 5083.44,
            outstandingBalancePercentage: 951.73
        }
    })

    const calculateSubtotal = () => {
        const subtotal = data.data.reduce((acc, curr) => {
            if (curr.overdueDay === 'current') return acc;

            acc.borrowerNumber += curr.borrowerNumber;
            acc.clientPercentage += curr.clientPercentage;
            acc.amountLeft += curr.amountLeft ? parseFloat(curr.amountLeft) : 0;
            acc.percentageInAreas += curr.percentageInAreas;
            acc.outstandingBalance += parseFloat(curr.outstandingBalance);
            acc.outstandingBalancePercentage += curr.outstandingBalancePercentage;

            return acc;
        }, {
            borrowerNumber: 0,
            clientPercentage: 0,
            amountLeft: 0,
            percentageInAreas: 0,
            outstandingBalance: 0,
            outstandingBalancePercentage: 0
        });

        subtotal.clientPercentage = subtotal.clientPercentage.toFixed(2);
        // subtotal.clientPercentage /= (data.data.length - 1);
        // subtotal.outstandingBalance = subtotal.outstandingBalance.toFixed(2);

        return subtotal;
    };
    

    const subtotal = calculateSubtotal();

    const textNullfn = (text,type) =>{
        if(text == null || text == undefined || text == ''){
            return '-'
        }else{
            if(type == 'percentage'){
                return text +'%';
            }else{
                return text;
            }
        }
    }
    const handleChange = (e) => {

        setnbfcFilter(e.target.value)

    };
    return (
        <>
            <div className="LoanPortfolio">
            <div className="MonthDateFilter" style={{marginBottom:'30px'}}>
                    <div className="row">

                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={handleChange} style={{ width: '100%' }} >
                                    <option value="all">ALL</option>
                                    <option value="drp">DRP</option>
                                    <option value="citra">Citra</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply'>Apply Filter</button>
                        </div>

                    </div>
                </div>
                <div className="phocket-table-new TableNew">
                    <table>
                        <thead>
                            <tr>
                                <th>OverDue Days</th>
                                <th>Borrower's Number</th>
                                <th>Client %</th>
                                <th>Amount Left</th>
                                <th>% in Areas</th>
                                <th>Outstanding Balance</th>
                                <th>Outstanding Balance %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data.map((data, index) => (
                                <tr key={index}>
                                    <td>{textNullfn(data.overdueDay)}</td>
                                    <td>{textNullfn(data.borrowerNumber)}</td>
                                    <td>{textNullfn(data.clientPercentage,'percentage')}</td>
                                    <td>{textNullfn(data.amountLeft)}</td>
                                    <td>{textNullfn(data.percentageInAreas,'percentage')}</td>
                                    <td>{textNullfn(data.outstandingBalance)}</td>
                                    <td>{textNullfn(data.outstandingBalancePercentage,'percentage')}</td>
                                </tr>
                            ))}
 <tr>
                                    <td>SubTotal</td>
                                    <td>{textNullfn(subtotal.borrowerNumber)}</td>
                                    <td>{textNullfn(subtotal.clientPercentage,'percentage')}</td>
                                    <td>{textNullfn(subtotal.amountLeft)}</td>
                                    <td>{textNullfn(subtotal.percentageInAreas,'percentage')}</td>
                                    <td>{textNullfn(subtotal.outstandingBalance)}</td>
                                    <td>{textNullfn(subtotal.outstandingBalancePercentage,'percentage')}</td>
                                </tr>


                        </tbody>
                        <thead>
                            <tr>
                                <th>Total</th>
                                <th>{textNullfn(data.Total.borrowerNumber)}</th>
                                <th>{textNullfn(data.Total.clientPercentage,'percentage')}</th>
                                <th>{textNullfn(data.Total.amountLeft)}</th>
                                <th>{textNullfn(data.Total.percentageInAreas,'percentage')}</th>
                                <th>{textNullfn(data.Total.outstandingBalance)}</th>
                                <th>{textNullfn(data.Total.outstandingBalancePercentage,'percentage')}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </>
    )
}
