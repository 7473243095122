import React, { Component } from 'react';
import DocumentForm from '../../presentationals/BlogContent/content.component';
import { documentFormValidation } from '../../../utils/validation';
import { uploadImage, uploadPdf, documentPageNext, passwordCheck } from '../../containers/Forms/PhocketActionCreator.component'
import { _formatDate } from '../../../utils/validation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserDetailApi } from '../Dashboard/DashboardActionCreator.component';
import { TYPE, FORM_STATUS } from '../../../utils/Constant';
// var qpdf = require('node-qpdf');

let userData1;
let msg = "We will get back to you soon!";
let File1 = '';
let File2 = '';
let File3 = '';
let File4 = '';
let File5 = '';
let File6 = '';
let File = [];
let interval;

class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            DocumentValidationMsg: "",
            progressBarState: false,
            progressValue: 20,
            docType: '',
            BankPasswordState: false,
            SalaryPasswordState: false,
            docPass: "",
            passwordErrorMsg: "",
            userDetail: {
                PhotoState: "No File Chosen",
                IDProofState: "No File Chosen",
                panProofState: "No File Chosen",
                BankStatementState: "No File Chosen",
                SalarySlipState: "No File Chosen",
                ITRProofState: "No File Chosen",
                // formStatus: FORM_STATUS.THANKS,
                formStatus: FORM_STATUS.LOAN,
            }
        }
    }
    render() {
        const { userData } = this.props
        return (
            <div id="formPage1" className="phocket-form-body animate-right">
                <DocumentForm
                    documentBackHandler={this.documentBackHandler.bind(this)}
                    documentNextHandler={this.documentNextHandler.bind(this)}
                    loandocument={this.loandocument.bind(this)}
                    DocumentValidationMsg={this.state.DocumentValidationMsg}
                    userDetail={this.state.userDetail}
                    userData={userData}
                    progressBarState={this.state.progressBarState}
                    docType={this.state.docType}
                    progressValue={this.state.progressValue}
                    docPassword={this.docPassword.bind(this)}
                    docPasswordHandler={this.docPasswordHandler.bind(this)}
                    BankPasswordState={this.state.BankPasswordState}
                    SalaryPasswordState={this.state.SalaryPasswordState}
                    passwordErrorMsg={this.state.passwordErrorMsg}
                />
            </div>
        )
    }

    docPasswordHandler(e, type) {
        if (type == 'bank') {
            uploadPdf(File, TYPE.BANK_STATMENT + '-' + this.state.docPass, 'val', this.state.docPass,
                (callBack => {
                    if (callBack != null && callBack != '') {
                        document.getElementById("bankText").classList.remove("hidden")
                        this.setState({
                            BankPasswordState: false,
                            progressBarState: false,
                        })
                    } else {
                        this.setState({
                            passwordErrorMsg: "Wrong Password"
                        })
                    }
                })
            );
        } else if (type == 'salary') {
            uploadPdf(File, TYPE.SALARY_PROOF + '-' + this.state.docPass, 'val', this.state.docPass,
                (callBack => {
                    if (callBack != null && callBack != '') {
                        document.getElementById("salaryText").classList.remove("hidden")
                        this.setState({
                            SalaryPasswordState: false,
                            progressBarState: false,
                        })
                    } else {
                        this.setState({
                            passwordErrorMsg: "Wrong Password"
                        })
                    }
                })
            );
        }
    }
    docPassword(e) {
        this.setState({
            docPass: e.target.value,
            passwordErrorMsg: ""
        })
    }

    setInterval1() {
        interval = setInterval(function () {
            this.setState({
                progressValue: this.state.progressValue + 5
            })
        }.bind(this), 500)
    }

    componentWillMount() {
        this.props.getUserDetailApi();
    }

    componentDidMount() {
        const { userData } = this.props;
        let userDetail = Object.assign({}, this.state.userDetail);
        if (userData.documentList != null) {
            if (userData.documentList.photographUrl != null && userData.documentList.photographUrl != "") {
                userDetail.PhotoState = userData.documentList.photographUrl
            }
            if (userData.documentList.adharCardUrl != null && userData.documentList.adharCardUrl != 0) {
                userDetail.IDProofState = userData.documentList.adharCardUrl
            }
            if (userData.documentList.panCardUrl != null && userData.documentList.panCardUrl != "") {
                userDetail.panProofState = userData.documentList.panCardUrl
            }
            if (userData.documentList.bankStatementUrl != null && userData.documentList.bankStatementUrl != 0) {
                userDetail.BankStatementState = userData.documentList.bankStatementUrl
            }
            if (userData.documentList.salaryProofUrl != null && userData.documentList.salaryProofUrl != "") {
                userDetail.SalarySlipState = userData.documentList.salaryProofUrl
            }
            if (userData.documentList.ITRUrl != null && userData.documentList.ITRUrl != 0) {
                userDetail.ITRProofState = userData.documentList.ITRUrl
            }
        }
        this.setState({
            userDetail,
        })
    }

    documentBackHandler() {
        this.props.getState(FORM_STATUS.BANK, msg)
        if (document.getElementById("formStep1") != null) {
            document.getElementById("formStep1").classList.add("active");
        }
        if (document.getElementById("formStep2") != null) {
            document.getElementById("formStep2").classList.add("active");
        }
        if (document.getElementById("formStep3") != null) {
            document.getElementById("formStep3").classList.add("active");
        }
        if (document.getElementById("formStep4") != null) {
            document.getElementById("formStep4").classList.add("active");
        }
        if (document.getElementById("formStep5") != null) {
            document.getElementById("formStep5").classList.add("active");
        }
        if (document.getElementById("formStep6") != null) {
            document.getElementById("formStep6").classList.remove("active");
        }

        if (document.getElementById("RightFormStep1") != null) {
            document.getElementById("RightFormStep1").classList.add("active");
        }
        if (document.getElementById("RightFormStep2") != null) {
            document.getElementById("RightFormStep2").classList.add("active");
        }
        if (document.getElementById("RightFormStep3") != null) {
            document.getElementById("RightFormStep3").classList.add("active");
        }
        if (document.getElementById("RightFormStep4") != null) {
            document.getElementById("RightFormStep4").classList.add("active");
        }
        if (document.getElementById("RightFormStep5") != null) {
            document.getElementById("RightFormStep5").classList.add("active");
        }
        if (document.getElementById("RightFormStep6") != null) {
            document.getElementById("RightFormStep6").classList.remove("active");
        }
    }
    documentNextHandler() {
        let documentValidation = documentFormValidation(
            this.state.userDetail,
            this.props.userData
        )
        if (documentValidation.status) {
            this.setState({
                DocumentValidationMsg: documentValidation,
                stepBarState: false
            })
            this.props.getState(this.state.userDetail.formStatus, msg)
            documentPageNext(this.state.userDetail.formStatus)
        } else {
            this.setState({
                DocumentValidationMsg: documentValidation
            })
        }

    }
    loandocument(DocumentInfo, e) {
        let userDetail = Object.assign({}, this.state.userDetail);
        let valt = e.target.value;
        let val = valt.split(/[\\\/]/)[2];
        this.setInterval1();
        let reader = new FileReader();
        File1 = e.target.files[0];
        File2 = e.target.files[1];
        File3 = e.target.files[2];
        File4 = e.target.files[3];
        File5 = e.target.files[4];
        File6 = e.target.files[5];

        // qpdf.decrypt(localFilePath, 'YOUR_PASSWORD_TO_DECRYPT_PDF', outputFilePath);

        File = [File1, File2, File3, File4, File5, File6];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (DocumentInfo === TYPE.PHOTO) {
            userDetail.PhotoState = val;
            if (fileType === "pdf") {
                uploadPdf(File, DocumentInfo, val, '',
                    (callBack => {
                        document.getElementById("photoText").classList.remove("hidden")
                        // userDetail.PhotoState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            } else {
                uploadImage(File, DocumentInfo, val,
                    (callBack => {
                        document.getElementById("photoText").classList.remove("hidden")
                        // userDetail.PhotoState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            }
        } else if (DocumentInfo === TYPE.ADHAR_CARD) {
            userDetail.IDProofState = val;
            if (fileType === "pdf") {
                uploadPdf(File, DocumentInfo, val, '',
                    (callBack => {
                        document.getElementById("adharText").classList.remove("hidden")
                        // userDetail.IDProofState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            } else {
                uploadImage(File, DocumentInfo, val,
                    (callBack => {
                        document.getElementById("adharText").classList.remove("hidden")
                        // userDetail.IDProofState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            }
        } else if (DocumentInfo === TYPE.PAN_CARD) {
            userDetail.panProofState = val;
            if (fileType === "pdf") {
                uploadPdf(File, DocumentInfo, val, '',
                    (callBack => {
                        document.getElementById("panText").classList.remove("hidden")
                        // userDetail.panProofState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            } else {
                uploadImage(File, DocumentInfo, val,
                    (callBack => {
                        document.getElementById("panText").classList.remove("hidden")
                        // userDetail.panProofState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            }
        } else if (DocumentInfo === TYPE.BANK_STATMENT) {
            userDetail.BankStatementState = val;
            if (fileType === "pdf") {
                passwordCheck(File, callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            BankPasswordState: true
                        })
                    } else {
                        uploadPdf(File, DocumentInfo, val, '',
                            (callBack => {
                                document.getElementById("bankText").classList.remove("hidden")
                                // userDetail.BankStatementState = callBack;
                                this.setState({
                                    progressBarState: false,
                                    userDetail
                                })
                            })
                        );
                    }
                })
            } else {
                uploadImage(File, DocumentInfo, val,
                    (callBack => {
                        document.getElementById("bankText").classList.remove("hidden")
                        // userDetail.BankStatementState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            }
        } else if (DocumentInfo === TYPE.SALARY_PROOF) {
            userDetail.SalarySlipState = val;
            if (fileType === "pdf") {
                passwordCheck(File, callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            SalaryPasswordState: true
                        })
                    } else {
                        uploadPdf(File, DocumentInfo, val, '',
                            (callBack => {
                                document.getElementById("salaryText").classList.remove("hidden")
                                // userDetail.BankStatementState = callBack;
                                this.setState({
                                    progressBarState: false,
                                    userDetail
                                })
                            })
                        );
                    }
                })
            } else {
                uploadImage(File, DocumentInfo, val,
                    (callBack => {
                        document.getElementById("salaryText").classList.remove("hidden")
                        // userDetail.SalarySlipState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            }
        } else if (DocumentInfo === TYPE.ITR_STATEMENT) {
            userDetail.ITRProofState = val;
            if (fileType === "pdf") {
                uploadPdf(File, DocumentInfo, val, '',
                    (callBack => {
                        document.getElementById("itrText").classList.remove("hidden")
                        // userDetail.ITRProofState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            } else {
                uploadImage(File, DocumentInfo, val,
                    (callBack => {
                        document.getElementById("itrText").classList.remove("hidden")
                        // userDetail.ITRProofState = callBack;
                        this.setState({
                            progressBarState: false,
                            userDetail
                        })
                    })
                );
            }
        }
        this.setState({
            userDetail,
            DocumentValidationMsg: "",
            progressBarState: true,
            docType: DocumentInfo,
            progressValue: 20
        });
    }

    componentWillUnmount() {
        clearInterval(interval);
    }

}

const mapStateToProps = ({ userDetail }) => {
    userData1 = userDetail
    return {
        userData: userDetail
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getUserDetailApi, passwordCheck }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);