import React, { Component } from 'react';
import RecoveryMsgUi from '../../../presentationals/Admin/Recovery/RecoveryMsg.component';
import Popup from '../../../presentationals/Popup/Popup.component';
import { sendRecoveryMsgAPI } from '../AdminActionCreator.component'
import { MSG_STRING, MAIL_STRING } from '../../../../utils/Constant'


class RecoveryMsgPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            msgBody: '',
            templateId:''

        }
    }

    componentWillMount() {

    }

    render() {
        const {collectionType} = this.props
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <RecoveryMsgUi
                    recoveryMsgChange={this.recoveryMsgChange.bind(this)}
                    recoveryMsgHandler={this.recoveryMsgHandler.bind(this)}
                    msgBody={this.state.msgBody}
                    collectionType={collectionType}
                    msgTempleteClick={this.msgTempleteClick.bind(this)}
                />
            </div>
        )
    }

    msgTempleteClick(msgType) {
        const { dataForCommunication } = this.props;
        let payBackDate = dataForCommunication != null && dataForCommunication.loanPaybackDate != null ? dataForCommunication.loanPaybackDate.split(' ')[0] : ""
        let payBackAmount = dataForCommunication != null && dataForCommunication.loanPaybackAmount != null ? dataForCommunication.loanPaybackAmount : ""
        let installmentAmount = dataForCommunication != null && dataForCommunication.installmentAmount != null ? dataForCommunication.installmentAmount : ""
        let installmentDate = dataForCommunication != null && dataForCommunication.installmentDate != null ? dataForCommunication.installmentDate : ""
        if (msgType == 'NachBounced') {
            this.setState({ msgBody: MSG_STRING.NACH_BOUNCE ,templateId:'1207161475835597316'})
        } else if (msgType == 'ChequeBounced') {
            this.setState({ msgBody: MSG_STRING.CHEQUE_BOUNCE,templateId:'1207161475840138713' })
        } else if (msgType == 'ChequeDepositAlert') {
            this.setState({ msgBody: MSG_STRING.CHEQUE_DEPOSITE_ALERT ,templateId:'1207161475851821601'})
        } else if (msgType == 'LegalNotice') {
            this.setState({ msgBody: MSG_STRING.LEGAL_NOTICE,templateId:'1207161475857465063' })
        } else if (msgType == 'WithNach') {
            this.setState({ msgBody: 'BORROWBUDDY LOAN: We are initiating your auto debit of INR ' + payBackAmount + ' on ' + payBackDate + '.\n\nMaintain sufficient balance to avoid any inconvenience.' })
        } else if (msgType == 'WithoutNach') {
            this.setState({ msgBody: 'BORROWBUDDY LOAN: Kindly note the repayment amount INR ' + payBackAmount+ ' as on ' + payBackDate + '.\n\nFor details check your Email.\n\nContact Us: ' + MAIL_STRING.MOBILE_NO + ' or recovery@borrowbuddy.co.in' })
        }else if (msgType == 'EmiWithNach') {
            this.setState({ msgBody: 'BORROWBUDDY LOAN EMI: We are initiating your auto debit of INR ' + installmentAmount + ' on ' + installmentDate + '.\n\nMaintain sufficient balance to avoid any inconvenience.' })
        } else if (msgType == 'EmiWithoutNach') {
            this.setState({ msgBody: 'BORROWBUDDY LOAN EMI: Kindly note the repayment amount INR ' + installmentAmount + ' as on ' + installmentDate + '.\n\nFor details check your Email.\n\nContact Us: ' + MAIL_STRING.MOBILE_NO + ' or recovery@borrowbuddy.co.in' })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    recoveryMsgChange(e) {
        this.setState({ msgBody: e.target.value })
    }

    recoveryMsgHandler() {
        const { dataForCommunication, admin } = this.props;
        if (this.state.msgBody != '' ) {
            sendRecoveryMsgAPI(dataForCommunication, this.state.msgBody.replace(/&/g, 'and'), admin,this.state.templateId,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Message Sent Successfully',
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please Try Again',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please Type message first',

            })
            this.removePopup();
        }
    }

}

export default RecoveryMsgPage;