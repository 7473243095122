import moment from 'moment'
import React, { useState } from 'react'
import { downloadKycData } from '../../containers/Admin/AdminActionCreator.component'
import PoupuSide from '../../presentationals/Popup/Popup.component'
const DownloadPopup = ({ downloadReport, allStatus }) => {
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [endDate, setendDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [loanStatus, setLoanStatus] = useState('')
    const [popupStatus, setpopupStatus] = useState('')
    const [popupState, setpopupState] = useState(false)

    const dateHandler = (e, type) => {
        console.log(e.target.value)
        if (type === 'start') {
            setStartDate(e.target.value)
        }
        if (type === 'end') {
            setendDate(e.target.value)
        }

    }
    const download = () => {
        if (loanStatus !== '') {
            const endDateObj = new Date(endDate);
            const startDateObj = new Date(startDate);
            const twoMonthsAgo = moment(endDateObj).subtract(2, 'months').toDate();

            if (startDateObj < twoMonthsAgo) {

                setpopupStatus('Start date cannot be more than 2 months before selected end date.')
                setpopupState(true)
                remove()
                return;
            }

            downloadKycData(startDate, endDate, loanStatus, callback => {
                setpopupStatus('Downloaded Successfully')
                setpopupState(true)
                remove()
                setTimeout(() => {
                    downloadReport()
                }, 5000);
            });
        }
    }

    const todaysdate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    const closePopup = () => {
        setpopupState(true)

    }
    const remove = () => {
        setTimeout(() => {
            setpopupState(false)

        }, 5000);
    }
    return (
        <>
            {popupState ?
                <PoupuSide closePopup={closePopup} popupStatus={popupStatus} />
                : ""}

            <div className="background-blur details-new" >
                <div className="popup-main small-popup container-fluid max-width-500px dbl-border" style={{ marginTop: '3%' }}>
                    <div className='text-right'><i className='fa fa-times' onClick={() => downloadReport()}></i></div>
                    <h4 className='text-center'>Download Report</h4>
                    <div className='row' style={{ marginTop: '20px' }}>
                        <div className='col-sm-4 col-xs-12'>
                            <label>Start Date</label>
                            <input className="form-control" value={startDate} type="date" onChange={(e) => dateHandler(e, 'start')} />
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                        <label>End Date</label>
                            <input className="form-control" value={endDate} type="date" max={todaysdate()} onChange={(e) => dateHandler(e, 'end')} />
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                        <label>Loan Status</label>

                            <select onChange={(e) => setLoanStatus(e.target.value)} className='form-control'>
                                <option value={''}>Select Loan Status</option>
                                {allStatus && allStatus.map((data, i) => {
                                    return (
                                        <option value={data.statusId}>{data.adminStatus}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='text-center' style={{ marginTop: '30px' }}>
                        <button className='btn btn-primary' onClick={() => download()}>Download</button>
                    </div>
                </div>
            </div>
        </>
    )
}



export default DownloadPopup;