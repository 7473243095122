import React, { Component } from 'react'
import LoyalityUi from '../../../presentationals/Admin/Loyality/LoyalityOffers.component'
import { APIS } from '../../../../utils/api-factory'
import Popup from '../../../presentationals/Popup/Popup.component';
import { loyalityOffersApi } from '../AdminActionCreator.component'

class LoyalityOfferContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loyalityOfferState: '',
            loyalityStatusState: '',
            loyalityList: '',
            popupState: false,
            popupStatus: '',
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }

    componentWillMount() {
        fetch(APIS.GET_ALL_LOYALITY_OFFER)
            .then(res => res.json())
            .then(json => {
                this.setState({ loyalityList: json })
            })
    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <LoyalityUi
                    loyalityCode={this.loyalityCode.bind(this)}
                    loyalitySaveHandler={this.loyalitySaveHandler.bind(this)}
                    loyalityList={this.state.loyalityList}
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                />
            </div>
        )
    }
    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    loyalityCode(e, loyalityType) {
        if (loyalityType == 'loyalityOffer') {
            this.setState({ loyalityOfferState: e.target.value })
        } else if (loyalityType == 'loyalityStatus') {
            this.setState({ loyalityStatusState: e.target.value })
        }
    }

    loyalitySaveHandler() {
        if (this.state.loyalityOfferState != '') {
            loyalityOffersApi(this.state.loyalityOfferState, this.state.loyalityStatusState,
                callBack => {
                    if (callBack == 'success') {
                        fetch(APIS.GET_ALL_LOYALITY_OFFER)
                            .then(res => res.json())
                            .then(json => {
                                this.setState({ loyalityList: json })
                            })
                        this.setState({
                            popupState: true,
                            popupStatus: 'Data Saved Successfully',
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please fill offer amount',
            })
            this.removePopup();
        }
    }

}

export default LoyalityOfferContainer;