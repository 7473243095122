export const ambasadorDetailsReducer=(state={},action)=>{
    switch(action.type){
        case 'GET_AMBASADOR_DETAILS':
             return action.status;

        default: return state;
    }
}

export const ambasadorAllDetailsReducer=(state={},action)=>{
    switch(action.type){
        case 'GET_AMBASADOR_ALL_DETAILS':
             return action.ambassadorAllData;

        default: return state;
    }
}