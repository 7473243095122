import React from 'react'
import { STRING } from '../../../../utils/Constant'

const AddStatus = ({ getDataHandler, postDataHandler, couponList, merchatIdForCoupon }) => (
    <div className="container-fluid">
        <div className="row">
            <h3 className="text-center">Map Coupon By Loan Status</h3>
        </div>
        <div className="row form-group">
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Merchant</label>
                <select className="form-control" onChange={e => getDataHandler(e, 'merchantDetail')}>
                    <optgroup className="display-none">
                        <option value="">Select Merchant</option>
                    </optgroup>
                    <optgroup label="Merchants">
                        {couponList != null && couponList != '' ?
                            couponList.map(list => {
                                return (
                                    <option value={list.merchnatid} key={list.merchnatid}>{list.merchantname}</option>
                                )
                            })
                            : ""}
                    </optgroup>
                </select>
            </div>
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Coupons</label>
                <select className="form-control" onChange={e => getDataHandler(e, 'couponsDetail')}>
                    <optgroup className="display-none">
                        <option value="">Select Coupons</option>
                    </optgroup>
                    <optgroup label="Coupons">
                        {merchatIdForCoupon != '' && couponList != null && couponList != '' ?
                            couponList.map(list => {
                                return (
                                    <option style={{ display: merchatIdForCoupon == list.merchnatid ? 'block' : 'none' }} value={list.couponid} key={list.couponid}>{list.title}</option>
                                )
                            })
                            : ""}
                    </optgroup>
                </select>
            </div>
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Status</label>
                <select className="form-control" onChange={e => getDataHandler(e, 'statusDetail')}>
                    <option value="">Select Status</option>
                    <option value={STRING.COMPLETE_APPLICATION}>Complete</option>
                    <option value={STRING.PAID}>Paid</option>
                    <option value={STRING.SELECT_ALL}>For All</option>
                </select>
            </div>
        </div>
        <div className="row">
            <button className="profile-save-btn center-block" onClick={e => postDataHandler()}>SAVE</button>
        </div>
        {/* {outLetAddressList != '' && outLetAddressList != null ?
            <div>
                <h3 className="text-center">OutLet Address</h3>
                <table className="text-center loan-detail-table max-width-500px">
                    <thead>
                        <tr>
                            <th>Coupons</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {outLetAddressList.map(data => {
                            return (
                                <tr>
                                    <td>
                                        <select className="form-control" onChange={e => getDataHandler(e, 'couponsDetail', data)}>
                                            <optgroup className="display-none">
                                                <option value="">Select Coupons</option>
                                            </optgroup>
                                            <optgroup label="Coupons">
                                                {couponList != null && couponList != '' ?
                                                    couponList.map(list => {
                                                        return (
                                                            <option value={list.couponid} key={list.couponid}>{list.title}</option>
                                                        )
                                                    })
                                                    : ""}
                                            </optgroup>
                                        </select>
                                    </td>
                                    <td></td>
                                    <td>
                                        <button className="profile-save-btn margin-0" id="AddLocationBtn" onClick={e => saveOutLetHandler()}>
                                            <span className="fa fa-plus"></span>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            : ""} */}
    </div>
)

export default AddStatus;