import React, { Component } from 'react'
import InstalledAppUi from '../../../presentationals/Admin/Utility/installedApp.component';
import { APIS } from '../../../../utils/api-factory'

class InstalledApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FilteredData: [],
            pageIndexToShow: 0,
            forwordPageState: 1,
            installedAppData: ''
        }
    }

    componentWillMount() {
        fetch(APIS.GET_EMAIL_DEVICE + this.state.forwordPageState)
            .then(res => res.json())
            .then(json => {
                this.setState({ installedAppData: json })
            })
    }

    render() {
        return (
            <div>
                <InstalledAppUi
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                    forwordpage={this.forwordpage.bind(this)}
                    backpage={this.backpage.bind(this)}
                    refreshpage={this.refreshpage.bind(this)}
                    installedAppData={this.state.installedAppData}
                />
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    refreshpage() {
        fetch(APIS.GET_EMAIL_DEVICE + this.state.forwordPageState)
            .then(res => res.json())
            .then(json => {
                this.setState({ installedAppData: json })
            })
    }

    forwordpage() {
        this.setState({
            loadarState: true,
            forwordPageState: this.state.forwordPageState + 1
        })
        fetch(APIS.GET_EMAIL_DEVICE + Number(this.state.forwordPageState + 1))
            .then(res => res.json())
            .then(json => {
                this.setState({ installedAppData: json })
            })

    }

    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                loadarState: true
            })
            fetch(APIS.GET_EMAIL_DEVICE + Number(this.state.forwordPageState - 1))
                .then(res => res.json())
                .then(json => {
                    this.setState({ installedAppData: json })
                })
        }
    }

}

export default InstalledApp;