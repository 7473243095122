import React from 'react'
import { STRING } from '../../../utils/Constant'

const BulletToEmiPopup = ({ emiMonthsChange, emiHandler, closeEmiPopup, emiTableData }) => (
    <div className="">
        <div className="background-blur" onClick={e => closeEmiPopup()}></div>
        <div className="popup-main small-popup container-fluid dbl-border">
            <h3 className="text-center blue-text">EMI</h3>
            <div className="row max-width-500px center-block">
                <div className="col-xs-12">
                    <label className="font-12px">Select Your Installment</label>
                    <select className="form-control" onChange={e => emiMonthsChange(e, 'month')}>
                        <option value="">Select Your Installment</option>
                        <option value={STRING.SECOND_EMI}>{STRING.SECOND_EMI + " Months"}</option>
                        <option value={STRING.THIRD_EMI}>{STRING.THIRD_EMI + " Months"}</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <button className="btn profile-save-btn center-block" onClick={e => emiHandler('check')}>CHECK</button>
                </div>
            </div>
            {emiTableData.length > 0 ?
                <div className="row">
                    <div className="col-xs-12">
                        <span className="no-doc-upload">This is a one time process.<br />
                            After converting your loan to&nbsp;
                            <span className="orange-text">{emiTableData.length}</span>
                            &nbsp;Installment, total repayment amount per installment will be&nbsp;
                            <span className="orange-text">{emiTableData[0].PAYABLEAMOUNT}</span>.
                            &nbsp;Click “Agree” to proceed.
                        </span>
                    </div>
                    <button className="btn profile-save-btn center-block" onClick={e => emiHandler('save')}>Agree</button>
                </div>
                : null}
        </div>
    </div>
)

export default BulletToEmiPopup;