import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import ReactQuill from "react-quill";
import { addEmailTemplateApI, deleteEmailTemplateApi, getSaveEmailTemplates } from "./apiCom";
import ConfirmationPopup from "../../../../presentationals/Popup/ConfirmationPopup.component";
import NodataImage from '../../../../../images/nodata.png'
const EmailCom = ({ admin }) => {
    const [commType, setCommType] = useState([]);
    const [mailText, setMailText] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [subject, setSubject] = useState("");
    const [errorData, setError] = useState("");
    const [viewState, setViewState] = useState("list");
    const [objectData, setObjectData] = useState([]);
    const [id, setId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState("");

    useEffect(() => {
        getSaveEmailTemplates(setObjectData);
    }, []);

    const submit = () => {
        if (!templateName || !subject || !mailText || commType.length === 0) {
            setError("All fields are required.");
            return;
        }

        setError("");
        const apiData = {
            templateName,
            subject,
            body: mailText,
            type: commType.join(", "),
            addedBy: admin.emailId,
            id,
        };

        addEmailTemplateApI(apiData, () => {
            getSaveEmailTemplates(setObjectData);
            setViewState('list');

            setCommType('');
            setMailText('');
            setTemplateName('');
            setSubject('');
            setId('');
        });
    };

    const setEditData = (data) => {
        setViewState('add');
        setCommType(data.type.split(',').map(item => item.trim()));
        setMailText(data.body);
        setTemplateName(data.templateName);
        setSubject(data.subject);
        setId(data.id);
    };

    const handleDelete = (templateName) => {
        setShowModal(true);
        setTemplateToDelete(templateName);
    };

    const confirmDelete = () => {
        deleteEmailTemplateApi(templateToDelete, () => {
            getSaveEmailTemplates(setObjectData);
        });
        setShowModal(false);
    };

    const quillModules = {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            ["blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            [{ align: [] }],
            ["link", "image"],
        ],
    };

    const quillFormats = [
        "header",
        "font",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "background",
        "align",
    ];
    const cancelLoanConfirm = (type) => {
        if (type === 'yes') {
            deleteEmailTemplateApi(templateToDelete, () => {
                getSaveEmailTemplates(callback => {
                    setObjectData(callback)
                })
                    .catch(() => {
                        setObjectData('')
                    })
            });
            setShowModal(false);
        } else {
            setShowModal(false);

        }
    }
    const handlePage = (type) => {
        setViewState(type)
        if(type==='add'){
            setCommType('');
            setMailText('');
            setTemplateName('');
            setSubject('');
            setId('');
        }
    }
    return (
        <div className="container-fluid">
            {showModal ?
                <ConfirmationPopup cancelLoanConfirm={cancelLoanConfirm} confirmationText={'Are you sure you want to delete this template?'} />
                : ""}
            <div className="row">
                <div>
                    {viewState === 'add' ?
                        <button onClick={() => handlePage('list')} className="btn btn-primary">View Templates</button>
                        : ""}
                    {viewState === 'list' ?
                        <button onClick={() => handlePage('add')} className="btn btn-primary">Add Templates</button>
                        : ""}
                </div>
                {viewState === 'add' ?
                    <div className="col-sm-8 col-xs-12">
                        <div className="shadow" style={{ minHeight: '500px' }}>
                            <h5 style={{ padding: '20px 10px 0px', marginTop: '10px' }}><b>Configure Email Template</b></h5>
                            <hr style={{ color: '#c7c8c9', borderTop: '3px solid #c7c8c9' }} />
                            <div style={{ padding: '20px' }}>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <label className="form-label">Template Name </label>
                                        <input value={templateName} style={{ padding: '16px' }} placeholder="Enter Template Name" className="form-control" onChange={(e) => setTemplateName(e.target.value)} />
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <label>Communication Type </label>
                                        <Multiselect
                                            placeholder="Select Communication Type"
                                            className="form-select"
                                            style={{
                                                multiselectContainer: {
                                                    minHeight: "50px",
                                                },
                                                searchBox: {
                                                    border: "none",
                                                    lineHeight: "25px",
                                                    padding: 0,
                                                },
                                            }}
                                            isObject={false}
                                            onKeyPressFn={function noRefCheck() { }}
                                            onRemove={(e) => setCommType(e)}
                                            onSearch={function noRefCheck() { }}
                                            onSelect={(e) => setCommType(e)}
                                            selectedValues={commType}
                                            value={commType}
                                            showCheckbox={true}
                                            options={["One to One Communication", "Bulk Communication"]}
                                            onChange={(e) => console.log("foccussed")}
                                            avoidHighlightFirstOption={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12">
                                        <label style={{ marginTop: '10px' }}>Subject </label>
                                        <input value={subject} style={{ padding: '16px' }} placeholder="Enter Subject" className="form-control" onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '10px' }}>
                                    <div className="col-sm-12 col-xs-12">
                                        <ReactQuill
                                            theme="snow"
                                            value={mailText}
                                            onChange={setMailText}
                                            className="mailBody"
                                            style={{ background: "#F6F6F6CC" }}
                                            modules={quillModules}
                                            formats={quillFormats}
                                        />
                                    </div>
                                </div>
                                <div className="text-center text-danger" style={{ marginTop: '10px' }}>{errorData}</div>
                                <div className="text-center">
                                    <button className="btn btn-primary" style={{ padding: '10px', width: '100px', marginTop: '20px', marginLeft: '10px' }} onClick={submit}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}
                {console.log(objectData)}
                {viewState === 'list' ?
                    <div className="col-sm-12 col-xs-12">
                        {objectData !== '' && objectData.length > 0 ?
                            <div className="phocket-table-new">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Body</th>
                                            <th>Communication Type</th>
                                            <th>AddedBy</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objectData && objectData.length > 0 ? objectData.map((data, i) => (
                                            <tr key={i}>
                                                <td>{data.templateName}</td>
                                                <td>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.body
                                                        }}
                                                    ></div>
                                                </td>
                                                <td>{data.type}</td>
                                                <td>{data.addedBy}</td>
                                                <td><i  style={{cursor:'pointer'}} className="fa fa-edit" onClick={() => setEditData(data)}></i></td>
                                                <td><i  style={{cursor:'pointer'}} className="fa fa-trash" onClick={() => handleDelete(data.templateName)}></i></td>
                                            </tr>
                                        )) : ""}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div style={{ width: '100%' }} className="text-center"><img style={{ width: '30%', marginTop: '50px', marginLeft: 'auto', marginRight: 'auto' }} src={NodataImage} /></div>}


                    </div>
                    : ""}
            </div>


        </div>
    );
};

export default EmailCom;
