import React from 'react'
import SampleCsv from '../../../../images/nachbouncedemo.png'



const BulkUpdate = ({ uploadedCSV,uploadcsvHandler,saveCsvNach,validationMsg,fileName}) => (
    <div>
        <h3 className="text-center blue-text">Bulk Nach Bounce By CSV</h3>
        <div className="max-width-500px">
            <div className="">
                <h6>Upload CSV &nbsp; <a href={SampleCsv} target="_blank">View Sample csv</a></h6>
                <div className="display-flex">
                    <input type="text" readOnly value={uploadedCSV} id="uploadCSVFile" className="form-control height-42px"  />
                    <div className="fileUpload btn btn--browse">
                        <span>Browse</span>
                        <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="uploadCSVBtn" type="file" className="upload" onChange={e => uploadcsvHandler(e)} />
                        
                    </div>
                </div>
                {/* {fileName} */}

                <span style={{color:'red'}}>{validationMsg}</span>

                <div>
                    <button className='profile-save-btn' onClick={saveCsvNach}>Save</button>
                </div>
            </div>
        </div>
    </div>
)

export default BulkUpdate