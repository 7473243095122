import React from "react";
import { TYPE } from "../../../../utils/Constant";

const EditTe = ({verifyPan,applicationLoanId, userData, updateDetails, changeHandler, newEmpIdHandler, changeEmployeeId, teaGardenData, newEmpId,onChangeHandler,onSubmitHandler }) => (
    <div className='container-fluid'>
        <div className='row'>
            <div className="col-sm-4 col-xs-12">
                <h4>Loan Id</h4>
                <div className="display-flex">
                    <input className="form-control" type="text" onChange={(e) => onChangeHandler(e)} value={applicationLoanId} />
                    <button className="profile-save-btn margin-0" onClick={(e) => onSubmitHandler(e, 'LoanId')}><span className="fa fa-save" /></button>
                </div>
            </div>
        </div>
        <h3 style={{ marginTop: '20px' }}>Personal Details</h3>
        <div className='row' style={{ marginTop: '20px' }}>

            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Tea Garden Name</label>
                <input className='form-control' value={userData.teaGardenName} readOnly />
            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Name</label>
                <input className='form-control' value={userData.name} onChange={(e) => changeHandler(e, 'name')} />

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Date Of Birth</label>
                <input className='form-control' type='date' value={userData.dateOfBirth} onChange={(e) => changeHandler(e, 'dateOfBirth')} />

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Gender</label>
                <select
                    value={userData.gender}
                    className='form-control' onChange={(e) => changeHandler(e, 'gender')}>
                    <option value=''>Select Gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>

                </select>

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Date of Joining</label>
                <input className='form-control' type='date' value={userData.dateOfJoining} onChange={(e) => changeHandler(e, 'dateOfJoining')} />

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Age Of Employment</label>
                <input className='form-control' readOnly value={userData.ageOfEmployment} />

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label> PAN Number</label>
                <input className='form-control' value={userData.panNumber} onChange={(e) => changeHandler(e, 'panNumber')} />

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Aadhaar Number</label>
                <input className='form-control' value={userData.aadharNumber} onChange={(e) => changeHandler(e, 'aadharNumber')} />

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Pincode</label>
                <input className='form-control' value={userData.pinCode} onChange={(e) => changeHandler(e, 'pinCode')} />

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Mobile Number</label>
                <input className='form-control' value={userData.mobileNumber} onChange={(e) => changeHandler(e, 'mobileNumber')} />

            </div>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                <label>Address</label>
                <input className='form-control' value={userData.address} onChange={(e) => changeHandler(e, 'address')} />

            </div>


        </div>
        <div className='row text-center'>
            <div className='col-sm-6 col-xs-12'>
            <button className='profile-save-btn' onClick={() => updateDetails()}>Update</button>
</div>
<div className='col-sm-6 col-xs-12'>
            <button className='profile-save-btn' onClick={() => verifyPan()}>Verify PAN</button>
</div>
        </div>
        <div className='row'>
            <h3>EmployeeId Change</h3>
            <div className='col-sm-4 col-xs-12'>
                <label>Old Employee Id</label>
                <input className='form-control' value={teaGardenData.UserToEmpId.empId} readOnly />
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label>New Employee Id</label>
                <input className='form-control' value={newEmpId} onChange={(e) => newEmpIdHandler(e)} />
            </div>
            <div className='col-sm-4 col-xs-12 text-center'>
                <button className='profile-save-btn' onClick={changeEmployeeId}>Update</button>

            </div>

        </div>

    </div>
)
export default EditTe