import React, { Component } from 'react'
import TransDetails from './Transaction.container'
import InstallmentDetails from './Installment.container'

class TransactionEmi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentRender: true
        }
        this.pageRender = this.pageRender.bind(this)

    }

    render() {
        const { allDetail, userDetail, admin } = this.props
        return (
            <div className="container-fluid">
                <div className="btn-group">
                    <button className={this.state.componentRender === true ? "active" : ''} onClick={e => this.pageRender(true)} >Transaction</button>
                    <button className={this.state.componentRender === false ? "active" : ''} onClick={e => this.pageRender(false)}>Installment</button>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        {this.state.componentRender ?
                            <TransDetails
                                allDetail={allDetail}
                                userDetail={userDetail}
                                admin={admin}
                            />
                            :
                            <InstallmentDetails
                                allDetail={allDetail}
                                userDetail={userDetail}
                                admin={admin}
                            />

                        }

                    </div>
                </div>
            </div>

        )
    }
    pageRender(type) {
        this.setState({ componentRender: type })

    }
}

export default TransactionEmi;