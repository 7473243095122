import React, { Component } from 'react'
import AddCouponDetails from '../../../presentationals/Admin/AdminCoupons/CouponAdd.Component'
import { AddmechantCoupon } from './AddMerchentActionCreator.Component'
import moment from 'moment'
import Popup from '../../../presentationals/Popup/Popup.component'
import { AddCouponValidation } from './Validation'

class CpnBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupState: false,
      popupStatus: '',
      MerchantValidationMsg: '',
      listingOfCategory: '',
      outLetAddressList: '',
      saveDataMsg: '',
      saveDataPopup: false,
      popupStatevisible: false,
      MerchentDataList: '',
      categoryList: '',
      responsecouponId: '',
      merchantData: {
        location: '',
        locationId: '',
        merchantName: '',
        merchantMobile: '',
        SelectMerchentStatus: '',
        merchantId: '',
        promoCatName: '',
        promoCatStatus: '',
        subCatName: '',
        selectSubCatStatus: '',
        categoryId: '',
        subCatId: '',
        discountTitle: '',
        discountAmount: '',
        discountDescription: '',
        tlc: '',
        offerTimeing: '',
        selectDate: '',
        discountStatus: '',
        couponid: '',
        couponCode: ''
      },
      datePickerselectDate: '',
    }
  }

  componentDidMount() {
    const { dataForUpdate } = this.props;
    if (dataForUpdate != null && dataForUpdate != '') {
      let merchantData = Object.assign({}, this.state.merchantData);
      merchantData.categoryId = dataForUpdate.categoryid
      merchantData.merchantId = dataForUpdate.merchnatid
      merchantData.subCatId = dataForUpdate.subcategoryid
      merchantData.locationId = dataForUpdate.locationid
      merchantData.discountTitle = dataForUpdate.title
      document.getElementById("couponDescription").innerHTML = dataForUpdate.description
      merchantData.selectDate = moment(new Date(dataForUpdate.expireon)).format('YYYY-MM-DD')
      merchantData.discountAmount = dataForUpdate.discount
      merchantData.discountFirstAddress = dataForUpdate.address
      merchantData.locationPincode = ""
      merchantData.discountStatus = dataForUpdate.status
      merchantData.couponid = dataForUpdate.couponid
      document.getElementById("couponTLC").innerHTML = dataForUpdate.termscondition
      document.getElementById("couponTiming").innerHTML = dataForUpdate.timing
      document.getElementById("seeOfferDetails").innerHTML = dataForUpdate.details
      merchantData.couponCode = dataForUpdate.couponcode
      this.setState({ merchantData })
    }
  }

  render() {
    const { listingOfCategory } = this.props;
    return (
      <div>
        {this.state.popupState ?
          <Popup
            popupStatus={this.state.popupStatus}
            closePopup={this.closePopup.bind(this)}
          />
          : ""}
        <AddCouponDetails
          merchantData={this.state.merchantData}
          listingOfCategory={listingOfCategory}
          selectDate={this.state.datePickerselectDate}
          postDataHandler={this.postDataHandler.bind(this)}
          getDataHandler={this.getDataHandler.bind(this)}
          MerchantValidationMsg={this.state.MerchantValidationMsg}
          createList={this.createList.bind(this)}
        />
      </div>
    )
  }

  createList(event){
    if (event.keyCode == 16) {
      document.execCommand("insertUnorderedList");
    }
  }

  removePopup() {
    setTimeout(function () {
      this.setState({ popupState: false });
    }.bind(this), 5000)
  }

  closePopup() {
    this.setState({ popupState: false });
  }

  getDataHandler(e, type) {
    let merchantData = Object.assign({}, this.state.merchantData);
    if (type == "selectDiscMerchantName") {
      merchantData.merchantId = e.target.value;
    } else if (type == "promoMainCatName") {
      merchantData.categoryId = e.target.value;
    } else if (type == "selectDiscSubCatName") {
      merchantData.subCatId = e.target.value;
    } else if (type == "selectDiscLocationName") {
      merchantData.locationId = e.target.value;
    } else if (type == "discountTitle") {
      merchantData.discountTitle = e.target.value;
    } else if (type == "discountAmount") {
      merchantData.discountAmount = e.target.value;
    }
    else if (type == "selectDate") {
      merchantData.selectDate = moment(e).format('YYYY-MM-DD');
      this.setState({ datePickerselectDate: e })
    } else if (type == "discountStatus") {
      merchantData.discountStatus = e.target.value == 'Active' ? true : false;
    } else if (type == "couponCode") {
      merchantData.couponCode = e.target.value
    }
    this.setState({ merchantData, MerchantValidationMsg: '' })
  }

  postDataHandler() {
    const { admin } = this.props;
    let couponTLC = document.getElementById("couponTLC").innerHTML
    let couponDescription = document.getElementById("couponDescription").innerHTML
    let couponTiming = document.getElementById("couponTiming").innerHTML
    let details = document.getElementById("seeOfferDetails").innerHTML
    let MerchantValidation = AddCouponValidation(
      this.state.merchantData
    )
    if (MerchantValidation.status) {
      this.setState({
        MerchantValidationMsg: MerchantValidation,
      })
      AddmechantCoupon(this.state.merchantData, couponTLC,couponDescription,couponTiming,details, admin, (callBack) => {
        if (callBack == 'success') {
          this.setState({
            popupState: true,
            popupStatus: 'Coupon saved Successfully',
            merchantData: {
              merchantId: '',
              categoryId: '',
              subCatId: '',
              locationId: '',
              discountTitle: '',
              discountAmount: '',
              discountDescription: '',
              tlc: '',
              offerTimeing: '',
              selectDate: '',
              discountStatus: '',
            },
          })
          this.removePopup();
          this.props.getAllData()
        } else {
          this.setState({
            popupState: false,
            popupStatus: 'Please try again',
          })
          this.removePopup();
        }
      })
    } else {
      this.setState({
        MerchantValidationMsg: MerchantValidation,
      })
    }

  }
}

export default CpnBtn;