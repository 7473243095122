import React, { Component } from 'react';
import CreditVidyaLowSalary from './CreditVidyaLeads.container';
import CreditVidyaHighSalary from './CreditVidyaHighSalary.container';
import SearchSelect from '../../../presentationals/Admin/ApplicationLeads/SearchSelect.component';
import { APIS } from '../../../../utils/api-factory';

class IncomingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageToShow: 'low',
            type:'',
            applicationId:'',
            emailId:'',
            creditVidyaData:'',
            mobileNo:'',
            forwordPageState:'1'

        }
    }

    render() {
        const { admin } = this.props
        return (
            <div className="container-fluid">
                            {/* <div>
                <SearchSelect
                    dataFilter={this.dataFilter.bind(this)}
                    filterHandler={this.filterHandler.bind(this)}
                />
            </div> */}
                {/* <div className="row">
                    <div className="col-xs-12 ">
                        <span className="main-search">
                            <select className="form-control mage" onChange={e => { filterHandler(e) }}>
                                <option value="applicationNo">Application no</option>
                                <option value="email">Email</option>
                                <option value="mobileNo">Mobile No.</option>
                            </select>
                        </span>
                        <span className="main-search">
                            <input type="text" className="form-control" onChange={e => dataFilter(e)} placeholder="Search.." />
                        </span>
                    </div>
                </div> */}
                <br/>
                <div className="row">
                    <div className=" col-xs-12">
                        <div className="btn-group">
                            <button className={this.state.pageToShow == 'low' ? 'active' : ''} onClick={e => this.buttonActionHandler("low")}>Report</button>
                            <button className={this.state.pageToShow == 'high' ? 'active' : ''} onClick={e => this.buttonActionHandler("high")}>High Salary</button>
                        </div>
                    </div>
                </div>
                {this.state.pageToShow == 'low' ?
                    <CreditVidyaLowSalary
                        admin={admin} 
                        creditVidyaData={this.state.creditVidyaData}
                        />
                    : this.state.pageToShow == 'high' ?
                        <CreditVidyaHighSalary
                            admin={admin} />
                        : null}
            </div>
        )
    }

    filterHandler(e) {
        this.setState({ type: e.target.value })
    }


    loanLead(e){
        var url=APIS.CREDIT_VIDYA_LEADS_BY_FILTER + this.state.forwordPageState + '&nbfcName=creditvidya';
        if(this.state.emailId!=''){
            url = url + '&emailId='+e.target.value
        }
        fetch(url)
            .then(res => 
                {  
                    res.json()})
            .then(res => {
                this.setState({
                    creditVidyaData: res,
                    
                })
            })
    }

  


    dataFilter(e){
        if (this.state.type == "email") {
            this.setState({ emailId: e.target.value })
            // let email = e.target.value
            if (e.target.value.length > 2) {
                
                this.loanLead(e)
            }
        } else if (this.state.type == "mobileNo") {
            this.setState({ mobileNo: e.target.value })
            // let mobile = e.target.value
            if (e.target.value.length > 9) {

                this.loanLead()
            }
        }
    }

    buttonActionHandler(type) {
        this.setState({
            pageToShow: type
        })
    }

}


export default IncomingContainer;