import moment from "moment";
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const reverseFormat = (date) => {
  var d = date.split("-");

  return [d[2], d[1], d[0]].join("-");
};
export const subtractOneDay = (dateString) => {
  const dateParts = dateString.split("-");

  // Convert DD-MM-YYYY to a Date object
  const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);

  // Subtract one day
  formattedDate.setDate(formattedDate.getDate() - 1);

  // Convert back to DD-MM-YYYY format
  const day = String(formattedDate.getDate()).padStart(2, "0");
  const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
  const year = formattedDate.getFullYear();

  return `${day}-${month}-${year}`; // Return in DD-MM-YYYY format
};



export const todayDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};
export const monthStartDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + 1,
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};
export const monthLastDate = () => {
  var today = new Date();
  // var date = `today.getFullYear(), today.getMonth()+1, 0`;
  var d = new Date(today.getFullYear(), today.getMonth() + 1, 0),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const lastDateOfMonth=(date)=>{
  
  return moment(new Date(date)).endOf('month').format("DD-MM-YYYY");
}
export const firstDateOfMonth = (date) => {
  return  moment(new Date(date)).startOf('month').format('DD-MM-YYYY');
};

export const getStartDateOfPreviousSevenDays = (date) => {
  return moment(date).subtract(6, 'days').startOf('day').format('DD-MM-YYYY');
};
