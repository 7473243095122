import React from 'react'
import DotLoader from '../../Loader/layerLoader.component'

const AddNBFC = ({ nbfcData, logoHandler, allNbfcList, searchHandler, phocketTablePagination, newNbfc,
    tableMinPagination, tableMaxPagination, addNbfcHandler, addNbfcChange, validationMsg, editHandler }) => (
    <div className="container-fluid">
        <div className="row">
            {nbfcData.name !== '' && nbfcData.name !== undefined ?
                <h3 className="text-center"><b>Update NBFC</b></h3>
                : <h3 className="text-center"><b>Add NBFC</b></h3>}
        </div>
        <div className="row">
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Name</label>
                <input type="text" placeholder='Name' value={nbfcData.name} className="form-control" onChange={e => addNbfcChange(e, 'name')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'name' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Maximum Days</label>
                <input type="number" value={nbfcData.maxDays} placeholder='Maximum Days' className="form-control" onChange={e => addNbfcChange(e, 'maxDays')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'maxDays' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Maximum Months</label>
                <input type="number" value={nbfcData.maxMonth} placeholder='Maximum Months' className="form-control" onChange={e => addNbfcChange(e, 'maxMonth')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'maxMonth' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Interest Rate</label>
                <input type="number" value={nbfcData.interestRate} placeholder='Interest Rate' className="form-control" onChange={e => addNbfcChange(e, 'interestRate')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'interestRate' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
           
        </div>
        <div className='row'>

        <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">API Key</label>
                <input type="text" value={nbfcData.apiKey} placeholder='API Key' className="form-control" onChange={e => addNbfcChange(e, 'apiKey')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'apiKey' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Dynamic EMI</label>
                {/* <input type="text" className="form-control" onChange={e => addNbfcChange(e,'dynamicEmi')} /> */}
                <select value={nbfcData.dynamicEmi} className='form-control' onChange={e => addNbfcChange(e, 'dynamicEmi')}>
                    <option value=''>Select Value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>

                </select>
                <div style={{ marginBottom: '10px' }}>{validationMsg && validationMsg.field === 'dynamicEmi' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>

            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Contact Person Name</label>
                <input type="text" value={nbfcData.contactPersonName} placeholder='Contact Person Name' className="form-control" onChange={e => addNbfcChange(e, 'contactPersonName')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'contactPersonName' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Contact Person Number</label>
                <input type="text" value={nbfcData.contactPersonNumber} placeholder='Contact Person Number' className="form-control" onChange={e => addNbfcChange(e, 'contactPersonNumber')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'contactPersonNumber' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>

        </div>
        <div className='row'>

            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">City</label>
                <input type="text" value={nbfcData.city} placeholder='City' className="form-control" onChange={e => addNbfcChange(e, 'city')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'city' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">State</label>
                <input type="text" value={nbfcData.state} placeholder='State' className="form-control" onChange={e => addNbfcChange(e, 'state')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'state' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Pincode</label>
                <input type="number" value={nbfcData.pinCode} placeholder='Pincode' className="form-control" onChange={e => addNbfcChange(e, 'pinCode')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'pinCode' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Address</label>
                <input type="text" value={nbfcData.address} placeholder='Address' className="form-control" onChange={e => addNbfcChange(e, 'address')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'address' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
        </div>
        <div className='row'>

            <div className='col-sm-3 col-xs-12' style={{ marginTop: '20px' }}>
                <label className="font-12px">Logo</label>
                <input disabled={nbfcData.name === '' ? true : false} accept='.png' type='file' onChange={(e) => logoHandler(e)} />
            </div>
            {nbfcData.logoUrl !== '' && nbfcData.logoUrl !== undefined ?

                <div className='col-sm-3 col-xs-12' style={{ marginTop: '20px' }}>
                    <img style={{ width: '100px' }} src={nbfcData.logoUrl} />
                </div>
                : ""}
        </div>

        <div className='row text-center'>
            <button className="btn profile-save-btn center-block" onClick={e => addNbfcHandler()}>Save</button>
        </div>

    </div>
)

export default AddNBFC;