import React, { Component } from 'react'
import MsgAnalysis from '../../../presentationals/Admin/CustomerPages/MessageAnalysisnew.component'
import DotLoader from '../../../presentationals/Loader/layerLoader.component'

let paginationNumber = 5

class Analysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBankName: '',
            msgDataType: 'Credit',
            FilteredData: [],

            pageIndexToShow: 0,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            tableMinPagination2: 0,
            tableMaxPagination2: paginationNumber,
        }
    }

    componentWillReceiveProps() {

    }
    componentDidMount() {
        // console.log(this.props)
    }
    tablePaginationNew(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination2 >= paginationNumber) {
                this.setState({
                    tableMinPagination2: this.state.tableMinPagination2 - paginationNumber,
                    tableMaxPagination2: this.state.tableMaxPagination2 - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination2 < data.length) {
                this.setState({
                    tableMaxPagination2: this.state.tableMaxPagination2 + paginationNumber,
                    tableMinPagination2: this.state.tableMinPagination2 + paginationNumber
                })
            }
        }
    }
    render() {
        const { msgData, loadarState } = this.props
        return (
            <div>
                <MsgAnalysis
                    loadarState={loadarState}
                    tablePaginationNew={this.tablePaginationNew.bind(this)}
                    tableMaxPagination2={this.state.tableMaxPagination2}
                    tableMinPagination2={this.state.tableMinPagination2}
                    msgData={msgData}
                    bankNamesHandler={this.bankNamesHandler.bind(this)}
                    selectedBankName={this.state.selectedBankName}
                    msgDataTypeHandler={this.msgDataTypeHandler.bind(this)}
                    msgDataType={this.state.msgDataType}
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMaxPagination={this.state.tableMaxPagination}
                    tableMinPagination={this.state.tableMinPagination}
                    tablePagination={this.tablePagination.bind(this)} />


            </div>
        )
    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    tablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    bankNamesHandler(e, name) {
        $('#' + e.target.id).addClass('active-msg');
        $("li").not('#' + e.target.id).removeClass('active-msg');
        this.setState({ selectedBankName: name })
    }

    componentDidMount() {
        if (document.getElementById('messageCredit') != null)
            document.getElementById('messageCredit').classList.add('active-msg')
    }

    msgDataTypeHandler(e, dataType) {
        $('#' + e.target.id).addClass('active-msg');
        $("button").not('#' + e.target.id).removeClass('active-msg');
        this.setState({ msgDataType: dataType })
    }
}

export default Analysis;
