import React from 'react'

const AllNbfc = ({ allNbfcList, editHandler, searchHandler ,detailHandler}) => (
    <div className='container-fluid'>
        <div className='row'>
            <div className="col-sm-12 col-xs-12">

                {allNbfcList != null && allNbfcList.length > 0 ?
                    <div>
                        <h3 className='text-center'><b>All NBFC</b></h3>
                        <div className="" style={{ marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto', width: '250px' }}>
                            <input type="text" className="form-control" onChange={e => searchHandler(e)} placeholder="Search" />
                        </div>
                        <table className="phocket-table">
                            <thead>
                                <tr>
                                    <th>Info</th>
                                    <th>NBFC Name</th>
                                    <th>maxDays</th>
                                    <th>maxMonth</th>
                                    <th>interestRate</th>
                                    {/* <th>Image</th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {allNbfcList.map((nbfc, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><i className='fa fa-info' onClick={()=>detailHandler(nbfc)}></i></td>
                                            <td>{nbfc.name}</td>
                                            <td>{nbfc.maxDays}</td>
                                            <td>{nbfc.maxMonth}</td>
                                            <td>{nbfc.interestRate}</td>
                                            {/* <td><img src={nbfc.logoUrl} /></td> */}

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                    </div>
                    : ""}
            </div>
        </div>
    </div>
)
export default AllNbfc