import React, { useEffect, useState } from 'react'
import Listing from './ApiAnalysis'
import ApiConfig from './configapi'
import CreditAnalysisTabContainer from '../ApplicationLeads/CreditAnalysisTab.container'
import { ROLE } from '../../../../utils/Constant'
const ApiTabMain = ({ admin, allStatus }) => {
    const [viewPage, setViewpage] = useState('')
    const pageHandler = (type) => {
        setViewpage(type)
    }
    useEffect(()=>{
        admin.rolelist.indexOf(ROLE.API_TRACKER_LIST) >= 0 ?
        setViewpage('listing')
        :""
    },[])
    return (

        <div>
            <div className="btn-group-tab " style={{ marginTop: '10px' }}>
                {admin.rolelist.indexOf(ROLE.API_TRACKER_LIST) >= 0 ?
                    <div className='text-center'>
                        <button className={viewPage == 'listing' ? 'active' : ''} onClick={(e) => pageHandler('listing')}>Listing</button>
                    </div>
                    : ""}
                {admin.rolelist.indexOf(ROLE.API_TRACKER_CONFIG) >= 0 ?
                    <div className='text-center'>
                        <button className={viewPage == 'config' ? 'active' : ''} onClick={(e) => pageHandler('config')}>Configuration</button>
                    </div>
                    : ""}
                {admin.rolelist.indexOf(ROLE.API_TRACKER_CREDIT_ANALYSIS) >= 0 ?
                    <div className='text-center'>
                        <button className={viewPage == 'analysis' ? 'active' : ''} onClick={(e) => pageHandler('analysis')}>Credit Analysis Leads</button>
                    </div>
                    : ""}
            </div>
            {viewPage === 'listing' ?
                <div>
                    <Listing />
                </div>
                : ""}
            {viewPage === 'config' ?
                <div>
                    <ApiConfig />
                </div>
                : ""}
            {viewPage === 'analysis' ?
                <CreditAnalysisTabContainer
                    admin={admin}
                    allStatus={allStatus}
                // newDataBackground={newDataBackground}
                />
                : ""}
        </div>
    )
}
export default ApiTabMain