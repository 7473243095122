import React from 'react'

const ConfirmationPopup = ({ confirmInitiate }) => (
    <div>
        <div className="background-blur" onClick={(e) => confirmInitiate('No')}></div>
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">Are you sure to initiate Bulk Payment ?<br />
                   </h4>
                </div>
            </div>
            <div className="row from-group text-center">
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => confirmInitiate('yes')}>YES</button>
                </div>
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => confirmInitiate('No')}>NO</button>
                </div>
            </div>
        </div>
    </div>
)

export default ConfirmationPopup;