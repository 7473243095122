import React from "react";
import "../../compo.scss";
export default function Table2({ itemsWithPercentage }) {
  return (
    <div className="totalcount7 collectionTable mt-2 table-other" >
      {/* {console.log(data)}
      <table className="table table-striped align-middle text-center">
        <thead>
          <tr className="align-middle">
            <th className="">
              <b>Rejection Events</b>
            </th>
            <th className="">
              <b>Leads</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ borderBottom: "none" }}>
            <td scope="col">
              <span id="comment">01/08/2023</span>
            </td>
            <td scope="col">
              <span id="comment">100</span>
            </td>
          </tr>
          <tr style={{ borderBottom: "none" }}>
            <td scope="col">
              <span id="comment">01/08/2023</span>
            </td>
            <td scope="col">
              <span id="comment">100</span>
            </td>
          </tr>
          <tr style={{ borderBottom: "none" }}>
            <td scope="col">
              <span id="comment">01/08/2023</span>
            </td>
            <td scope="col">
              <span id="comment">100</span>
            </td>
          </tr>
        </tbody>
      </table> */}
      <div className='credit-table' style={{ padding: '10px', fontSize: '12px', marginTop: '-25px' }}>
        <table style={{
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px'
        }}>
          <thead style={{ background: '#b9d8f9', color: '#000', }}>
            <tr className="thead-class" >
              <th><b>Loan Reject Reason</b></th>
              <th><b>Count</b></th>
              <th><b>Percentage</b></th>
            </tr>
          </thead>
          <tbody>
            {itemsWithPercentage && itemsWithPercentage.map((item, index) => (
              <tr key={index}>
                <td>{item.loanRejectReason}</td>
                <td>{item.count}</td>
                <td>{item.percentage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
