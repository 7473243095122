import React from 'react'
import Ui from '../../../presentationals/Admin/CustomerPages/BadabuisnessTransaction.component'
import {APIS} from '../../../../utils/api-factory'
class Transaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details:''
        }
    }
    componentDidMount(){
        const {allDetail}=this.props
        // fetch(APIS.BADA_BUISNESS_TRANSACTION +'wsqVq8miZ9NNCkjUSdEsPCvb4F32dJZQ')
        fetch(APIS.BADA_BUISNESS_TRANSACTION +allDetail.loanId)

        .then(res => res.json())
        .then(json => {
          this.setState({
            details: json
          })
        })
    }
    render() {
        return (
            <div >
               <Ui details={this.state.details}/>

            </div>
        )
    }
}

export default Transaction