import React, { useEffect, useState } from "react";
import { _preFormatDate } from "../../../../utils/validation";
import moment from "moment";
import { stampUsagehistoryapi } from "../AdminActionCreator.component";
import NodataImage from '../../../../images/nodata.png'
import Loader from '../../../presentationals/Loader/Loader.component'
import { Pagination } from "@mui/material";
const UsageHistory = () => {
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [searchByDateShow, setSearchDateToShow] = useState('')
    const [searchByEndDateTOShow, setsearchByEndDateTOShow] = useState('')
    const [objectdata, setObjectdata] = useState('')
    const [isRepeat, setisRepeat] = useState('')
    const [loaderstate, setloader] = useState(false)
    const [pageNo, setpageNo] = useState(1)
    const [size, setsize] = useState(20)
    const [totalPages, setTotalPages] = useState(0)

    const dateFilter = (e, type) => {
        if (type === 'dateTo') {
            setStartDate(_preFormatDate(e.target.value))
            setSearchDateToShow(e.target.value)
        }
        if (type === 'dateFrom') {
            setEndDate(_preFormatDate(e.target.value))
            setsearchByEndDateTOShow(e.target.value)

        }
        if (type === 'submit') {
            getData()
        }
    }
    const getData = () => {
        setloader(true)
        stampUsagehistoryapi(startDate, endDate, isRepeat, pageNo, size, callback => {
            setObjectdata(callback.data)
            setTotalPages(callback.pageCount)

            setloader(false)
        })
    }
    const handlePageChange = (event, value) => {
        setpageNo(value)
        getData()
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className="container-fluid">
            {loaderstate ?
                <Loader />
                : ""}
            <h4 className="text-center"><b>Usage Logs</b></h4>

            <div className="row">
                <div className="col-sm-2 colxs-12">
                </div>
                <div className="col-sm-8 colxs-12">
                <div className='row'>
                <div className='col-sm-2 col-xs-12  ' style={{ marginTop: '15px' }}>
                    <label>From Date</label>

                    <input type='date' className='date-input-admin' style={{ width: '100%' }} value={searchByDateShow} onChange={(e) => dateFilter(e, 'dateTo')} />
                </div>
                <div className='col-sm-2 col-xs-12  ' style={{ marginTop: '15px' }}>
                    <label>To Date</label>
                    <input type='date' className='date-input-admin' style={{ width: '100%' }} value={searchByEndDateTOShow} onChange={(e) => dateFilter(e, 'dateFrom')} />
                </div>
                <div className='col-sm-2 col-xs-12 ' style={{ marginTop: '15px' }}>
                    <label>Is Repeat?</label>

                    <select className='date-input-admin' value={isRepeat} onChange={(e) => setisRepeat(e.target.value)}>
                        <option value={''}>All</option>
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>


                    </select>
                </div>
                <div className='col-sm-2 col-xs-12 '>
                    <button className='new-cancle-btn' style={{ marginTop: '35px ' }} onClick={(e) => dateFilter(e, 'submit')}>Apply Filter</button>
                </div>
            </div>
                    {objectdata !== '' && objectdata !== null && objectdata.length > 0 ?
                        <div className="phocket-table-new" style={{ overflow: 'auto', marginTop: '40px' }}>
                            <table className="">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Application Id</th>
                                        <th>Esign Status</th>
                                        <th>Added On</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {objectdata.map((data, i) => (
                                        <tr key={i}>
                                            <td>{data.usedOn}</td>
                                            <td>{data.applicationId}</td>
                                            <td>{data.esignStatus ? <i className="fa fa-check" style={{ color: "green" }}></i> : <i className="fa fa-close" style={{ color: "red" }}></i>}</td>
                                            <td>{data.addedOn}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ float: 'right' }}>

                                <Pagination
                                    count={totalPages}
                                    page={pageNo}
                                    onChange={handlePageChange}
                                    color="primary" />
                            </div>
                            {/* <Pagination count={totalPages} page={pageNo} onChange={handlePageChange} /> */}

                        </div>
                        : <div style={{ textAlign: 'center', marginTop: '50px' }}> <img src={NodataImage} style={{ width: '30%' }} /></div>}
                </div>
            </div>
        </div>
    )
}
export default UsageHistory