import React from 'react'
import { TYPE, STRING, PLACEHOLDER } from '../../../utils/Constant'
import DatePicker from 'react-datepicker'

const ApproveDataPopup = ({
  
  sendMail,
  sendMailHandler,
  showCheckBoxEsign,
  esignDisable,
  esignDisableHandler,
  nbfcList,
  approveData,
  approveDataHandler,
  approveDataChange,
  closeApprovePopup,
  emiTableData,
  errorMsg,
  disabled,
  admin,
  nbfcHandler,
  nbfc,
  userDetails,
  repeatUserData,
  belowoneLakh,
  company,
  belowoneLakhState,
  rangeVreedhi,
  salaryhandler,
  sendAttribute,
  isStaff
}) => (
  <div>


    {/* <div className='background-blur'></div>
    <div className='popup-main small-popup-new container-fluid dbl-border' style={{ width: '80%' }}> */}
    <div className='row from-group'>
      {/* <div className='col-xs-12'>
          <button className='close' onClick={e => closeApprovePopup()}>
            &times;
          </button>
          <h3 className='text-center' style={{ height: '18px' }}>
            Kindly confirm the details
            <br />
            &nbsp;
          </h3>
      
        </div> */}

    </div>
    {/* <div className='row'>
        <div className='col-xs-12'>
          <div className='text-center' style={{marginBottom:'5px'}}>
            <input type="checkbox" onChange={(e) => belowoneLakh(belowoneLakhState)} checked={belowoneLakhState} />&nbsp;<b>Tick here if you want to approve for amount greater than 1Lac</b>
              </div>
        </div>
      </div> */}
    <h4 className='text-center' style={{ marginTop: '20px', marginBottom: '20px' }}>Applied Amount : {approveData.appliedAmount}</h4>
    <div className='row form-group' >
      {userDetails.loanFrom === 'vreedhi' ?
        <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Installment(weeks)</label>
          <input
            className='form-control'
            id='phocketEMI'
            min="1" max="100"
            type='number'
            readOnly
            value={approveData.selectEMI}
            onChange={e => approveDataChange(e, TYPE.SELECT_EMI, '')}


          />
          {rangeVreedhi !== '' ?
            <div style={{ color: 'red', fontSize: '12px' }}>{rangeVreedhi}</div>
            : ""}
        </div>
        : ""}
      {userDetails.typeOfProduct == 'Loan' && userDetails.loanFrom !== 'vreedhi' && userDetails.loanFrom !== 'bazarBussiness' && sendAttribute !== true ?

        <div className='col-sm-12 col-xs-12' style={{ marginBottom: '15px' }}>

          <input type="checkbox" onChange={(e) => belowoneLakh(belowoneLakhState)} checked={belowoneLakhState} />&nbsp;<b>Tick here if you want to approve for amount greater than 1Lac</b>
        </div>
        : ""}

      <div className={'col-sm-6 col-xs-12'} >
        {userDetails.typeOfProduct == 'Loan' && belowoneLakhState == false && userDetails.loanFrom !== 'vreedhi' ? (
          <div>
            <label className='font-12px'>Enter Amount</label>
            <input
              type='number'
              placeholder='Enter Amount'
              className='form-control'
              // value={approveData.ApproveAmount}
              onChange={e => approveDataChange(e, TYPE.LOAN_AMOUNT, '')}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        ) : userDetails.typeOfProduct == 'Gift Card' && sendAttribute !== true
          //  &&
          //   userDetails.giftCardType == 'Flipkart' 
          ?
          (
            <div>
              <label className='font-12px'>Select Amount</label>
              <select
                required
                className='form-control'
                // value={approveData.ApproveAmount}
                onChange={e => approveDataChange(e, TYPE.LOAN_AMOUNT, '')}>
                <option value='' disabled selected >
                  Select Amount
                </option>
                <option value='5000'>5000</option>
                <option value='10000'>10000</option>
                <option value='15000'>15000</option>
                <option value='20000'>20000</option>
                <option value='25000'>25000</option>
                <option value='30000'>30000</option>
              </select>
            </div>
          )
          //  : userDetails.typeOfProduct == 'Gift Card' &&
          //   userDetails.giftCardType == 'Amazon' ? (
          //   <div>
          //     <label className='font-12px'>Select Amount</label>
          //     <select
          //       required
          //       className='form-control'
          //       value={approveData.ApproveAmount}
          //       onChange={e => approveDataChange(e, TYPE.LOAN_AMOUNT, '')}
          //     >
          //       <option value='' disabled selected>
          //         Select Amount
          //       </option>
          //       <option value='5000'>5000</option>
          //       <option value='10000'>10000</option>
          //     </select>
          //   </div>
          // ) 
          : userDetails.typeOfProduct == 'Loan' && belowoneLakhState == true ?
            <div>
              <label className='font-12px'>Select Amount</label>
              <select
                required
                className='form-control'
                // value={approveData.ApproveAmount}
                onChange={e => approveDataChange(e, TYPE.LOAN_AMOUNT, '')}>
                <option value='' disabled selected >
                  Select Amount
                </option>
                <option value='110000'>110000</option>
                <option value='120000'>120000</option>
                <option value='130000'>130000</option>
                <option value='140000'>140000</option>
                <option value='150000'>150000</option>
                <option value='160000'>160000</option>
                <option value='170000'>170000</option>
                <option value='180000'>180000</option>
                <option value='190000'>190000</option>
                <option value='200000'>200000</option>

              </select>
            </div>
            : userDetails.loanFrom === 'vreedhi' ?
              <div>
                <label className='font-12px'>Select Amount</label>
                <select
                  required
                  className='form-control'
                  // value={approveData.ApproveAmount}
                  onChange={e => approveDataChange(e, TYPE.LOAN_AMOUNT, '')}>
                  <option value='' disabled selected >
                    Select Amount
                  </option>
                  <option value='50000'>50,000</option>
                  <option value='70000'>70,000</option>
                  <option value='100000'>1,00,000</option>
                  <option value='125000'>1,25,000</option>
                  <option value='150000'>1,50,000</option>
                  <option value='200000'>2,00,000</option>

                </select>
              </div>
              : sendAttribute === true && userDetails.typeOfProduct === 'Gift Card' && isStaff === false ?
                <div>
                  <label className='font-12px'>Select Amount</label>
                  <select
                    required
                    className='form-control'
                    // value={approveData.ApproveAmount}
                    onChange={e => approveDataChange(e, TYPE.LOAN_AMOUNT, '')}>
                    <option value='' disabled selected >
                      Select Amount
                    </option>
                    <option value='2500' >2500</option>
                    <option value='3000' >3000</option>
                    <option value='3500' >3500</option>
                    <option value='4000' >4000</option>
                    <option value='4500' >4500</option>
                    <option value='5000' >5000</option>
                    <option value='6000' >6000</option>
                    <option value='7000' >7000</option>
                    <option value='8000' >8000</option>
                    <option value='9000' >9000</option>
                    <option value='10000' >10000</option>
                    <option value='12000' >12000</option>
                    <option value='14000' >14000</option>
                    <option value='16000' >16000</option>
                    <option value='18000' >18000</option>
                    <option value='20000' >20000</option>
                  </select>
                </div>

                :
                sendAttribute === true && userDetails.typeOfProduct === 'Gift Card' && isStaff === true ?
                  <div>
                    <label className='font-12px'>Approve Amount</label>
                    <input className='form-control' placeholder='Loan Amount' onChange={e => approveDataChange(e, TYPE.LOAN_AMOUNT, '')} />
                  </div>
                  : ""}
      </div>
      {userDetails.loanFrom === 'bazarBussiness' ?
        <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Select instalment</label>
          <input
            required
            className='form-control'
            id='phocketEMI'
            value={approveData.selectEMI}
            onChange={e => approveDataChange(e, TYPE.SELECT_EMI, '')}
          // onClick={e => approveDataChange(e, TYPE.SELECT_EMI, '')}
          />
        </div>
        : ""}

      <div className='col-sm-6 col-xs-12'>
        <label className='font-12px'>Select Date</label>
        <DatePicker
          value={approveData.approveDate}
          // selected={approveData.approveDateToShow}
          onChange={e => approveDataChange(e, TYPE.LOAN_APPROVE_DATE, '')}
          className='form-control'
          placeholderText='select Date'
          dateFormat='DD-MM-YYYY'
          fixedHeight
        />
      </div>
      {userDetails.typeOfProduct == 'Gift Card' ? (
        <div className='col-xs-12 col-sm-6 form-group'>
          <label className='font-12px'>Select company</label>
          <select
            className='form-control'
            value={company}
            onChange={e => approveDataChange(e, 'company', '')}
          >
            <option value='Flipkart'>Flipkart</option>
            <option value='Amazon'>Amazon</option>
          </select>
        </div>
      ) : (
        ''
      )}
    </div>
    <div className='row'>
      {/* <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Select Date</label>
          <DatePicker
            value={approveData.approveDate}
            // selected={approveData.approveDateToShow}
            onChange={e => approveDataChange(e, TYPE.LOAN_APPROVE_DATE, '')}
            className='form-control'
            placeholderText='select Date'
            dateFormat='DD-MM-YYYY'
            fixedHeight
          />
        </div> */}
      {userDetails.typeOfProduct === 'Loan' && userDetails.loanFrom !== 'vreedhi' && sendAttribute !== true ?
        <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Salary Day</label>
          <select
            required
            className='form-control'
            value={approveData.salaryDateToShow}
            onChange={e => salaryhandler(e)}
            onClick={e => salaryhandler(e)}
          >
            <option value='' disabled selected>
              Select Salary Day
            </option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
            <option value='7'>7</option>
            <option value='8'>8</option>
            <option value='9'>9</option>
            <option value='10'>10</option>
            <option value='11'>11</option>
            <option value='12'>12</option>
            <option value='13'>13</option>
            <option value='14'>14</option>
            <option value='15'>15</option>
            <option value='16'>16</option>
            <option value='17'>17</option>
            <option value='18'>18</option>
            <option value='19'>19</option>
            <option value='20'>20</option>
            <option value='21'>21</option>
            <option value='22'>22</option>
            <option value='23'>23</option>
            <option value='24'>24</option>
            <option value='25'>25</option>
            <option value='26'>26</option>
            <option value='27'>27</option>
            <option value='28'>28</option>
            <option value='29'>29</option>
            <option value='30'>30</option>
            <option value='31'>31</option>

          </select>


        </div>
        : ""}
      {/* {admin != null && sendAttribute === true ?
          <div className='col-sm-6 col-xs-12'>
            <label className='font-12px'>Select instalment</label>
            <select
              required
              className='form-control'
              id='phocketEMI'
              value={approveData.selectEMI}
              onChange={e => approveDataChange(e, TYPE.SELECT_EMI, '')}

            >
              <option value='' disabled selected>
                {PLACEHOLDER.SELECT_EMI}
              </option>
            
         
                <option value={STRING.SECOND_EMI}>
                  {STRING.SECOND_EMI + ' Months'}
                </option>
      
                <option value={STRING.THIRD_EMI}>
                  {STRING.THIRD_EMI + ' Months'}
                </option>
      
                <option value={STRING.SIXTH_EMI}>
                  {STRING.SIXTH_EMI + ' Months'}
                </option>
         

                <option value='12'>
                  12 Months
                </option>
      
            </select>
          </div>
          : ""
        }     */}
      {admin != null && sendAttribute !== true && userDetails.loanFrom !== 'vreedhi' && sendAttribute !== true &&
        userDetails.typeOfProduct == 'Loan' &&
        (admin.nbfcName == '' || admin.nbfcName == null) && userDetails.loanFrom !== 'bazarBussiness' ? (
        <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Select instalment</label>
          <select
            required
            className='form-control'
            id='phocketEMI'
            value={approveData.selectEMI}
            onChange={e => approveDataChange(e, TYPE.SELECT_EMI, '')}

          >
            <option value='' disabled selected>
              {PLACEHOLDER.SELECT_EMI}
            </option>
            <option value={STRING.FIRST_EMI}>
              {STRING.FIRST_EMI + ' Month'}
            </option>
            {/* <option value={STRING.SECOND_EMI}>
                  {STRING.SECOND_EMI + ' Months'}
                </option>
                <option value={STRING.THIRD_EMI}>
                  {STRING.THIRD_EMI + ' Months'}
                </option>
                <option value={STRING.SIXTH_EMI}>
                  {STRING.SIXTH_EMI + ' Months'}
                </option> */}
            {approveData.ApproveAmount >= 5000 ? (
              <option value={STRING.SECOND_EMI}>
                {STRING.SECOND_EMI + ' Months'}
              </option>
            ) : null}
            {approveData.ApproveAmount >= 11000 ? (
              <option value={STRING.THIRD_EMI}>
                {STRING.THIRD_EMI + ' Months'}
              </option>
            ) : null}
            {approveData.ApproveAmount >= 21000 ? (
              <option value={STRING.SIXTH_EMI}>
                {STRING.SIXTH_EMI + ' Months'}
              </option>
            ) : ""}
            {approveData.ApproveAmount >= 61000 ? (

              <option value='12'>
                12 Months
              </option>
            ) : null}
          </select>
        </div>
      ) : userDetails.typeOfProduct == 'Gift Card' && sendAttribute !== true ? (
        <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Select instalment</label>
          <select
            disabled
            className='form-control'
            id='phocketEMI'
            value={approveData.selectEMI}
            onChange={e => approveDataChange(e, TYPE.SELECT_EMI, '')}
            onClick={e => approveDataChange(e, TYPE.SELECT_EMI, '')}
          >
            <option value='' disabled selected>
              {PLACEHOLDER.SELECT_EMI}
            </option>

            <option value={STRING.THIRD_EMI}>
              {STRING.THIRD_EMI + ' Months'}
            </option>
          </select>
        </div>
      ) : (
        ''
      )}
      {sendAttribute === true ?
        <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Select instalment</label>
          <select
            required
            className='form-control'
            id='phocketEMI'
            value={approveData.selectEMI}
            onChange={e => approveDataChange(e, TYPE.SELECT_EMI, '')}

          >
            <option value='' disabled selected>
              {PLACEHOLDER.SELECT_EMI}
            </option>
            <option value={3}>
              {STRING.THIRD_EMI + ' Month'}
            </option>
            <option value={6}>
              {STRING.SIXTH_EMI + ' Month'}
            </option>
            <option value={12}>
              {STRING.TWELFTH + ' Month'}
            </option>

          </select>
        </div>
        : ""}



      {emiTableData != null &&
        emiTableData != '' &&
        emiTableData != undefined &&
        emiTableData.length > 0
        && userDetails.loanFrom !== 'vreedhi'
        ? emiTableData.map((emi, i) => {
          return (
            <div
              key={i}
              className={
                'col-sm-6 col-xs-12'
              }
            >
              <label className='font-12px'>{i + 1} Repayment Date</label>
              <DatePicker
                disabled={userDetails.loanFrom === 'vreedhi' ? true : false}
                value={approveData['repaymentDate' + i]}
                selected={approveData['repaymentDateToShow' + i]}
                onChange={e =>
                  approveDataChange(e, TYPE.FIRST_REPAYMENT_DATE, i)
                }
                className='form-control'
                placeholderText='select Date'
                dateFormat='DD/MM/YYYY'
                id={'RepaymentDate_' + i}
                fixedHeight
              />
            </div>
          )
        })
        : null}
      {nbfcList != null && nbfcList != '' ? (
        <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Select NBFC</label>

          <select
            className='form-control'
            value={nbfc}
            onChange={e => nbfcHandler(e)}
          >
            {/* <option>Select Nbfc</option> */}

            {nbfcList.map((data, i) => {
              return (
                <option key={i} value={data.nbfcId}>
                  {data.name}
                </option>
              )
            })}
          </select>
        </div>
      ) : (
        ''
      )}

      {/* {userDetails.typeOfProduct == 'Gift Card' ? (
          <div className='col-xs-12 col-sm-6 form-group'>
            <label className='font-12px'>Select company</label>
            <select
              className='form-control'
              value={company}
              onChange={e => approveDataChange(e, 'company', '')}
            >
              <option value='Flipkart'>Flipkart</option>
              <option value='Amazon'>Amazon</option>
            </select>
          </div>
        ) : (
            ''
          )} */}
    </div>
    {repeatUserData == true ? (
      <div className='row'>
        <div className='col-xs-12 col-sm-6 form-group'>
          <label className='font-12px'>Select Sub Status</label>
          <select
            className='form-control'
            onChange={e => approveDataChange(e, 'substatus', '')}
          >
            <option value=''>Select Sub Status</option>
            <option value='201'>PFD</option>
            <option value='202'>RFD</option>

          </select>
        </div>
      </div>
    ) : (
      ''
    )}
    <p className=' height-20px red-text margin-0'>{errorMsg}</p>
    <div className=' '  >
      {showCheckBoxEsign && repeatUserData === true ? <> <input type='checkbox' style={{ fontSize: '25px', cursor: 'pointer' }} checked={esignDisable} onChange={(e) => esignDisableHandler(e)} />&nbsp; Disable Esign</> : ""}
      <> <input type='checkbox' style={{ fontSize: '25px', cursor: 'pointer' }} checked={sendMail} onChange={(e) => sendMailHandler(e)} />&nbsp; Send Mail</>
      <div className=''>
        <button
          className='btn btn-primary'
          disabled={disabled}
          onClick={e => approveDataHandler()}
          style={{ marginTop: '20px' }}
        >
          SAVE
        </button>
      </div>
    </div>
  </div>
  // </div>
)

export default ApproveDataPopup
