import React, { useState ,useEffect} from 'react'
import reseticonimg from './images/reseticon.png'
import { getEnachDataLeads } from './actionCreator';
import Loader from '../../../presentationals/Loader/DotLoader.component';
export default function MandateReport() {
    const [loaderState, setLoader] = useState(false);
    const [filtertype, setfiltertype] = useState('mandatestatus');
    const [subfiltertype, setsubfiltertype] = useState('all');
    const [fromdate, setfromdate] = useState('');
    const [todate, settodate] = useState('');
    const tableData = [
        {
        paymentId:'P1023',
        raazorPayOrderId:'P1023P1023',
        amount:'80,000',
        emailId:'manik789@gmail.com',
        number:'+91-8765432534',
        createdAt:'04/02/2024 03:30 PM',
        status:'Created',
    },
        {
        paymentId:'P1023',
        raazorPayOrderId:'P1023P1023',
        amount:'80,000',
        emailId:'manik789@gmail.com',
        number:'+91-8765432534',
        createdAt:'04/02/2024 03:30 PM',
        status:'Success',
    },
        {
        paymentId:'P1023',
        raazorPayOrderId:'P1023P1023',
        amount:'80,000',
        emailId:'manik789@gmail.com',
        number:'+91-8765432534',
        createdAt:'04/02/2024 03:30 PM',
        status:'Failed',
    },
];

    const handlefilter = (e) => {
        setfiltertype(e.target.value);
        e.target.value === 'duration' ?  setsubfiltertype('last7days') : e.target.value === 'mandatestatus' ? setsubfiltertype('all') : setsubfiltertype('');
    }
    const handlefiltersecondary = (e) => {
        setsubfiltertype(e.target.value);
    }

    const handleChange = (e) => {
        if (e.target.name === "fromDate") {
            setfromdate(e.target.value);
        } else {
            settodate(e.target.value);
        }
    };
    const getData = (pageNumber,type,date) => {
        getEnachDataLeads(pageNumber, type, date, callback => {
            console.log(callback)
        })
    }

    return (
        <>
            {loaderState ? <Loader /> : ""}
            <div className="MandateReport AccountReconciliaiton-CSS container-fluid full-height">
                <div className="row " style={{ marginTop: '20px', paddingLeft: '15px' }}>
                    <h3 className="mb-0"><b>Mandate Report</b></h3>
                </div>

                <div className="row" style={{ marginTop: '20px' }}>
                    <div className='col-sm-4' >
                        <label style={{ fontSize: '14px', fontWeight: '400' }}>Select Filter</label>
                        <br />
                        <span className="main-search-comp" >
                            <select className="form-control" onChange={(e) => handlefilter(e)}>
                                <option value="mandatestatus">Mandate status</option>
                                <option value="paymentid">Payment Id</option>
                                <option value="duration">Duration</option>
                                <option value="phonenumber">Phone Number</option>
                                <option value="email">Email Id</option>
                            </select>

                            {
                                filtertype === 'mandatestatus' ?
                                    <select className="subsearchform form-control" onChange={(e) => handlefiltersecondary(e)}>
                                        <option value="all">All</option>
                                        <option value="created">Created</option>
                                        <option value="failed">Failed</option>
                                        <option value="success">Success</option>
                                    </select>
                                    : ''
                            }
                            {
                                filtertype === 'duration' ?
                                    <select className="subsearchform form-control" onChange={(e) => handlefiltersecondary(e)}>
                                        <option value="last7days">last 7 Days</option>
                                        <option value="betweendates">between Dates</option>
                                        <option value="last7days">last 7 Days</option>
                                        <option value="last7days">last 7 Days</option>
                                    </select>
                                    : ''
                            }
                            {
                                filtertype === 'paymentid' ?
                                    <span className=" main-search">
                                        <input type="text" className="subsearchform form-control" onChange={(e) => handlefiltersecondary(e)} placeholder="Enter Payment Id" />
                                    </span>
                                    : ''
                            }
                            {
                                filtertype === 'phonenumber' ?
                                    <span className=" main-search">
                                        <input type="number" className="subsearchform form-control" onChange={(e) => handlefiltersecondary(e)} placeholder="Enter Phone Number" />
                                    </span>
                                    : ''
                            }
                            {
                                filtertype === 'email' ?
                                    <span className=" main-search">
                                        <input type="email" className="subsearchform form-control" onChange={(e) => handlefiltersecondary(e)} placeholder="Enter Email Id" />
                                    </span>
                                    : ''
                            }

                        </span>
                    </div>
                    {
                        subfiltertype === 'betweendates' ?

                            <div className="col-sm-3 datecol" style={{ display: 'flex' }} >
                                <div className="dates">
                                    <div className="filterHeaders" style={{ marginBottom: "5px" }}>
                                        From Date
                                    </div>

                                    <input
                                        type="date"
                                        id="startDate"
                                        name="fromDate"
                                        value={fromdate}
                                        onChange={handleChange}
                                        placeholder="DD/MM/YY"
                                        className="dateinput fromdate form-control"
                                        style={{

                                            backgroundColor: 'white',
                                        }}
                                    />
                                </div>
                                <hr className='hrtag' />
                                <div className="dates">
                                    <div className="filterHeaders" style={{ marginBottom: "5px" }}>
                                        To Date
                                    </div>

                                    <input
                                        type="date"
                                        id="endDate"
                                        name="toDate"
                                        max={fromdate}
                                        value={todate}
                                        onChange={handleChange}
                                        placeholder="DD/MM/YY"
                                        className="dateinput todate form-control"
                                        style={{

                                            backgroundColor: 'white',
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            ''
                    }

                    <div className="col-sm-3" style={{ marginTop: '2px',float:'inline-end' }} >
                        <button className="reset-btn"><img src={reseticonimg} width={'25%'} alt="" /> Reset Filter</button>
                    </div>
                </div>

                <div className="row" style={{ padding: '10px', paddingRight: '15px' }}>
                    <hr className='hrtag2' />
                    <div className='MandateTable table-responsive' style={{ fontSize: '12px' }} >
                        <table className='table'>
                            <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                <tr className="thead-class">

                                    <th className="">
                                        Payment Id
                                    </th>
                                    <th className="">
                                        RazorPay Order Id
                                    </th>
                                    <th className="">
                                        Amount
                                    </th>
                                    <th className="">
                                        Email Id
                                    </th>
                                    <th className="">
                                        Contact Number
                                    </th>
                                    <th className="">
                                        Created At
                                    </th>
                                    <th className="">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                tableData && tableData?.length > 0 ? 
                                tableData?.map((data,index)=>{
                                    const [beforespace,afterspace] = data.createdAt.split(/\s+(.+)/);
                                    return(
                                        <tr style={{ borderBottom: "none", fontWeight: '400' }} key={index}>
                                        <td scope="col">{data.paymentId}</td>
                                        <td scope="col">{data.raazorPayOrderId}</td>
                                        <td scope="col">{'₹ ' + data.amount}</td>
                                        <td scope="col">{data.emailId}</td>
                                        <td scope="col">{data.number}</td>
                                        <td scope="col">{beforespace} <br/> {afterspace}</td>
                                        <td scope="col" className='status'>
                                            <div className="box" style={{ backgroundColor: data.status === 'Created' ? 'rgba(38, 125, 255, 1)' : data.status === 'Success' ? 'rgba(76, 199, 140, 1)' :data.status === 'Failed' ? 'rgba(255, 92, 93, 1)' : ''}}>
                                                <h5>{data.status}</h5>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                })
                                :''}
                                {/* <tr style={{ borderBottom: "none", fontWeight: '400' }}>
                                    <td scope="col">
                                        P1023
                                    </td>
                                    <td scope="col">
                                        P1023P1023
                                    </td>
                                    <td scope="col">
                                        ₹ 80,000
                                    </td>
                                    <td scope="col">
                                        manik789@gmail.com
                                    </td>
                                    <td scope="col">
                                        +91-8765432534
                                    </td>
                                    <td scope="col">
                                        04/02/2024 <br /> 03:30 PM
                                    </td>
                                    {
                                        data === 'Created' ?
                                            <td scope="col" className='status' >
                                                <div className="box" style={{ backgroundColor: 'rgba(38, 125, 255, 1)' }}>
                                                    <h5>
                                                        Created
                                                    </h5>
                                                </div>
                                            </td>
                                            :
                                            data === 'Success' ?
                                                <td scope="col" className='status' >
                                                    <div className="box" style={{ backgroundColor: 'rgba(76, 199, 140, 1)' }}>
                                                        <h5>
                                                            Success
                                                        </h5>
                                                    </div>
                                                </td>
                                                :
                                                data === 'Failed' ?
                                                    <td scope="col" className='status' >
                                                        <div className="box" style={{ backgroundColor: 'rgba(255, 92, 93, 1)' }}>
                                                            <h5>
                                                                Failed
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    : ''}
                                </tr> */}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
