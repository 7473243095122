import { APIS } from "../../../utils/api-factory"
import { POST_HEADER } from "../../../utils/apiHeaders"

export const getAllBranchData = (
  startDate, endDate,type,
  callBack
) => {
  fetch(APIS.GET_BRANCH_DATA_NEW + startDate + '&endDate=' + endDate + '&type=' + type)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const getEventListApi = (callBack) => {

  fetch(APIS.GET_EVENTS_LIST, {
    method: 'POST',
    headers: POST_HEADER,
    //   body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(text => {
      callBack(text)
    })
}
