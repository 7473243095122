import React, { useState } from "react";
import Listing from "./listCategory";
import Pointers from "./pointer";

const Category = () => {
    const [page, setpage] = useState('category')
    return (
        <div className="container-fluid" style={{marginTop:'-10px'}}>
            <div className="">
                <div className="row " >
                    <div className="col-sm-6 col-xs-6" onClick={() => setpage('category')} style={{ borderBottom: page === 'configuration' ? '2px solid #267DFF' : '', background: page === 'category' ? '#267DFF' : '#DBE9FF', padding: page === 'category' ? '13px' : '12.2px', textAlign: 'center', color: page === 'category' ? '#fff' : '#000', cursor: 'pointer' }}>
                        <i class="fa fa-list" aria-hidden="true"></i>&nbsp; Category

                    </div>
                    <div className="col-sm-6 col-xs-6" onClick={() => setpage('pointer')} style={{ borderBottom: page === 'list' ? '2px solid #267DFF' : '', background: page === 'pointer' ? '#267DFF' : '#DBE9FF', padding: page === 'pointer' ? '13px' : '12.2px', textAlign: 'center', color: page === 'pointer' ? '#fff' : '#000', cursor: 'pointer' }}>
                        <i class="fa fa-list" aria-hidden="true"></i>&nbsp;  Pointers

                    </div>

                </div>
            </div>
            {page === 'category' ?
                <Listing />
                : ""}
                    {page === 'pointer' ?
                <Pointers />
                : ""}
        </div>
    )
}
export default Category