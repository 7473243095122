import { ACCOUNTDETAIL, MAIL_STRING } from "../../../../../utils/Constant";

const generateEmailContent = (allDetail) => {
    return `
        <div>
            <div>Hi ${allDetail?.firstName || ""},</div>
            <br />
            <div>Kindly note the repayment amount due on ${allDetail?.paybackDate?.split(' ')[0] || ""}</div>
            <br />
            <div>Amount : ${allDetail?.totalPayBackAmount || ""}.</div>
            <br />
            <div>You can pay through Netbanking to this account: </div>
            <br />
            <div>${ACCOUNTDETAIL.COMPANY_NAME}</div>
            <div>${ACCOUNTDETAIL.BANK_NAME}</div>
            <div>${ACCOUNTDETAIL.BRANCH_NAME}</div>
            <div>${ACCOUNTDETAIL.ACCOUNT_NUMBER}</div>
            <div>${ACCOUNTDETAIL.IFSC_CODE}</div>
            <br />
            <div>Best Regards,</div>
            <div>Team BorrowBuddy</div>
            <div>${MAIL_STRING.MOBILE_NO}</div>
        </div>
    `;
};

const generateLoanApprovalEmail = (allDetail, link) => {
    console.log(link,'link')
    return `
        <div>
            <div>Hello ${allDetail?.firstName || ""},</div>
            <br />
            <div>Your loan is approved for INR ${allDetail.loanAmount}. Kindly <a href="${link}">Click Here</a> to share 3 contact numbers for reference purposes to process your disbursement.
            </div>
            <br />
            <div>Best Regards,</div>
            <div>Team BorrowBuddy</div>
        </div>
    `;
};
const generateLoanAgreementEmailContent = (allDetail) => {
    return `
      <div>
        <div>Hello ${allDetail?.firstName || ""},</div>
        <br />
        <div>Greetings for the day!</div>
        <br />
        <div>We have entered into a 3-year agreement with you, and during this period, you can avail loan anytime from us without any physical process.</div>
        <br />
        <div>We have defined an overall limit of ${allDetail?.employmentDetailsPO?.netTakeHomeSalary || ""} equivalent to 100% of your salary.</div>
        <br />
        <div>As your credit history builds with us, we will increase your loan limit from the current amount to ${allDetail?.employmentDetailsPO?.netTakeHomeSalary || ""}.</div>
        <br />
        <div>We collect a cheque only for security purposes. This cheque needs to be undated & in favor of Superline Fininvest Pvt. Ltd</div>
        <br />
        <div>If you still have any concerns, our finance team can get in touch with you.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `;
  };
  const NachRegistrationClarification = (allDetail) => (
    `
      <div>
        <div>Hello ${allDetail?.firstName || ""},</div>
        <br />
        <div>Greetings for the day!</div>
        <br />
        <div>We have entered into a 3-year agreement with you, and during this period, you can avail a loan anytime from us without any physical process.</div>
        <br />
        <div>We have defined an overall limit of ${allDetail?.employmentDetailsPO?.netTakeHomeSalary || ""} equivalent to 100% of your salary.</div>
        <br />
        <div>As your credit history builds with us, we will increase your loan limit from the current amount ${allDetail?.approveAmount || ""} till your current salary.</div>
        <br />
        <div>Be rest assured we will only utilize the NACH only for the amount that is actually due on the repayment date.</div>
        <br />
        <div>If you still have any concerns, our finance team can get in touch with you.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `
  );
  const HalfSalary = (allDetail) => (
    `
      <div>
        <div>Dear ${allDetail?.firstName || ""},</div>
        <br />
        <div>Thank you for registering with BorrowBuddy. We're happy that you decided to avail our instant loan services.</div>
        <br />
        <div>As per our policy, the loan amount we provide cannot exceed more than 100% of the applicant's take-home salary.</div>
        <br />
        <div>If interested, please acknowledge this email.</div>
        <br />
        <div>For any further queries, please contact us: ${MAIL_STRING.MOBILE_NO} or ${MAIL_STRING.EMAIL_ID}</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `
  );
  const ExecutiveContact = (allDetail) => (
    `
      <div>
        <div>Hello ${allDetail?.firstName || ""},</div>
        <br />
        <div>Hope this mail finds you in the best of health and spirits.</div>
        <br />
        <div>Our executive tried calling you. Kindly revert for further processing of your loan application.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `
  );
  const RepeatApplication = (allDetail) => (
    `
      <div>
        <div>Dear ${allDetail?.firstName || ""},</div>
        <br />
        <div>We have noticed that your loan application process isn't complete yet and is missing a few documents.</div>
        <br />
        <div>Below are the essential documents required:</div>
        <br />
        <div>1) Up to date bank statement and the latest salary slip </div>
        <div>Kindly provide the documents via the App or send them via email.</div>
        <br />
        <div>You can also upload the documents here: <a href="${MAIL_STRING.WEB_URL + allDetail?.userId}" target="_blank">click here</a></div>
        <br />
        <div>For Approval confirmation, we will send an App notification. Kindly acknowledge it.</div>
        <br />
        <div>Feel free to contact us if you have any queries.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `
  );
  
  const Razormail = (allDetail) => (
    `
      <div>
        <div>Hello ${allDetail?.firstName || ""},</div>
        <br />
        <div>Hope this mail finds you in the best of health and spirits.</div>
        <br />
        <div>In order to ensure a paperless and hassle-free procedure, BorrowBuddy brings to you e-Sign and e-Mandate features where the e-Sign feature will help you e-sign your Loan Agreement via Aadhaar OTP, and e-mandate registration via net banking will automate your EMI repayments.</div>
        <br />
        <div>Currently, your bank is not live with Razorpay's e-mandate facility, so the only way we can proceed forward is if you agree to courier the signed loan agreement, security cheque, and NACH mandate form to our Delhi Head Office.</div>
        <br />
        <div>Please acknowledge this mail so that we can proceed with loan processing.</div>
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `
  );
  
  const SecurityChequeMail = (allDetail) => (
    `
      <div>
        <div>Hello ${allDetail?.firstName || ""},</div>
        <br />
        <div><a href="${MAIL_STRING.WEB_URL_NACH}" className="blue-text">Click here for NACH Form and Sample Document.</a></div>
        <br />
        <div>Kindly print two copies of the NACH form and sign on both copies.</div>
        <br />
        <div>And we need your Salary A/C Cheque for security purposes. This cheque needs to be duly signed and undated & in favor of Superline Fininvest Pvt. Ltd</div>
        <br />
        <div><b>TO, Superline Fininvest Pvt. Ltd </b></div>
        <br />
        <div>All this you have to post to our office address ASAP. Once we receive it, we will disburse your amount.</div>
        <br />
        <div><u><b>Address:</b></u></div>
        <br />
        <div><b>BorrowBuddy E-Service Pvt. Ltd.</b></div>
        <div><b>M-3 Pratap Bhawan,</b></div>
        <div><b>5 Bahadur Shah Zafar Marg I.P.Estate ,New Delhi</b></div>
        <div><b>Central Delhi- 110002,India</b></div>
        <div><b>CIN:U62099DL2023PTC415259</b></div>
        <br />
        <div>Warm Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `
  );
  
  const LastStep = (allDetail) => (
    `
      <div>
        <div>Hello ${allDetail?.firstName || ""},</div>
        <br />
        <div>Kindly check the notification bell in the app for further process. First, you need to update your 3 reference numbers there. After adding references, you will get two links accordingly (E-Nach & E-sign), which you have to verify to get disbursement. If you are facing any issue with links, kindly let us know.</div>
        <br />
        <div>NOTE - We will call on your references for verification as in KYC verification (for verifying your current company name or current address). Kindly provide the valid contacts to avoid delays in your amount disbursement.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `
  );
  const DocumentMail = (allDetail, adharTextState, panTextState, bankTextState, salarySlipTextState, halfSalaryState, CurrentAddressProofState ) => (
    `
      <div>
        <div>Dear ${allDetail?.firstName || ""}, </div>
        <br />
        <div>Thank you for registering with BorrowBuddy! </div>
        <div>We're so happy to have you as a customer. </div>
        <div>We have noticed that your loan application process isn't complete yet and is missing a few documents. </div>
        <br />
        <div>Below are the essential documents required: </div>
        <br />
        ${adharTextState == true ? '<div>- Aadhar Card </div>' : ""}
        ${panTextState == true ? '<div>- PAN Card </div>' : ""}
        ${bankTextState == true ? '<div>- Last 2 months\' Salary Account bank statement (till date) </div>' : ""}
        ${salarySlipTextState == true ? '<div>- Last Month Salary Slip </div>' : ""}
        ${CurrentAddressProofState == true ? '<div>- Current Address Proof (Utility bill/Rent Agreement/Mobile bill/LPG bill etc) </div>' : ""}
        <br />
        <b>Note:</b>
        <br />
        <div>You can either share PDF or use Digitap Net Banking auto fetch in our App document upload section for Bank Statement.</div>
        <br />
        <div>If your mobile number is not linked to your Aadhar card, please upload your Aadhar card in PDF/JPG/JPEG format.</div>
        <br />
        <div>If your mobile number is linked to your Aadhar Card and your mobile number is active, please upload details via Digilocker/ XML process.</div>
        <br />
        <div>Documents can be shared by any of the following modes:
          <br /><br />
          1. Uploading them in the document section of BorrowBuddy Mobile App.
          <br />
          2. Emailing them to finance@borrowbuddy.co.in
          <br />
          3. <a href="${MAIL_STRING.WEB_URL + allDetail?.userId}" target="_blank">Click here to upload</a>. </div>
        <br />
        ${halfSalaryState == true ? '<div>As per our policy, the loan amount we provide cannot exceed more than 100% of the applicant\'s take-home salary. </div><br />' : ""}
        <div>Feel free to contact us if you have any queries. </div>
        <br />
        <div>Best Regards, </div>
        <div>Team BorrowBuddy </div>
        <div>${MAIL_STRING.MOBILE_NO} </div>
      </div>
    `
  );
  const kycRegistrationMail = (allDetail) => (
    `
      <div>
        <div>Hello ${allDetail?.firstName || ""},</div>
        <br />
        <p>Hope this mail finds you in the best of health and spirits.</p>
       
        <p>Your KYC is pending, please complete your KYC for further process of your loan.</p>
       
        <div> <a href="${MAIL_STRING.KYC_URL + allDetail?.userId}" target="_blank">Click here to Complete your KYC </a></div>
        <br/>
        <div>Best Regards,</div>
        <div>Team Borrowbuddy</div>
        <div>${MAIL_STRING.MOBILE_NO}</div>
      </div>
    `
  );
  
  

export {
    generateEmailContent ,
    generateLoanApprovalEmail,
    generateLoanAgreementEmailContent,
    NachRegistrationClarification,
    HalfSalary,
    ExecutiveContact,
    RepeatApplication,
    Razormail,
    SecurityChequeMail,
    LastStep,
    DocumentMail,kycRegistrationMail
}