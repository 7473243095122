import React from 'react'

const Profile = ({ falconUser, userDetailsByUserId, addressData, bankData, addFalconUser }) => (
    <div className='container-fluid '>
        <div className='row' style={{marginTop:falconUser?'':'30px'}} >
            {/* {falconUser ===false?
                <div className='text-right'>
                    <button className='profile-save-btn' onClick={(e) => addFalconUser(e)}>Add User</button>
                </div>
                : ""} */}
            <div className='col-sm-6 col-xs-12' >
                <div className='card-profile'>
                    {/* <h3 className='text-center'><b>Profile Details</b></h3> */}
                    {/* <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}>First Name</b>
                                <span style={{ float: 'right', color: 'blue' }}> {userDetailsByUserId.firstName}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}> Last Name</b>
                                <span style={{ float: 'right', color: 'blue' }}>{userDetailsByUserId.lastName}</span></div>
                        </div>
                    </div> */}
                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}>Gender</b>
                                <span style={{ float: 'right', color: 'blue' }}>{userDetailsByUserId.gender}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}>Mobile Number</b>
                                <span style={{ float: 'right', color: 'blue' }}>{userDetailsByUserId.mobileNumber}</span></div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}>Date Of Birth</b>
                                <span style={{ float: 'right', color: 'blue' }}>{userDetailsByUserId.dateOfBirth}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}>PAN Number</b>
                                <span style={{ float: 'right', color: 'blue' }}>{userDetailsByUserId.panNumber}</span></div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}>Aadhaar Number</b>
                                <span style={{ float: 'right', color: 'blue' }}>{userDetailsByUserId.aadhaarNo}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}>Email</b>
                                <span style={{ float: 'right', color: 'blue' }}>{userDetailsByUserId.emailId}</span></div>
                        </div>
                    </div>
                    <h3 className='text-center' ><b> Bank Details</b></h3>
                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div>
                                <b style={{ color: 'gray' }}>Account Number</b>
                                <span style={{ float: 'right', color: 'blue' }}> {bankData.accountNo}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>IFSC Code</b>
                                <span style={{ float: 'right', color: 'blue' }}>{bankData.ifscCode}</span></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Name On Bank</b>
                                <span style={{ float: 'right', color: 'blue' }}>{bankData.nameOnBank}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-6 col-xs-12'>
                <div className='card-profile'>
                    <h3 className='text-center'><b>Address Details</b></h3>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12'>
                            <div className='' style={{ marginTop: '30px' }}>
                                <div>
                                    <b style={{ color: 'gray' }}>Address 1</b>
                                    <span style={{ float: 'right', color: 'blue' }}> {addressData.address1}</span></div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-xs-12'>
                            <div className='' style={{ marginTop: '30px' }}>
                                <div>
                                    <b style={{ color: 'gray' }}>Address 2</b>
                                    <span style={{ float: 'right', color: 'blue' }}> {addressData.address2}</span></div>
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Address 3</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.address3}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>City</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.city}</span></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>State</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.state}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Pincode</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.pinCode}</span></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Delivery Address Id</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.deliveryAddressId}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Delivery Address 1</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.deliveryAddress1}</span></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Delivery Address 2</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.deliveryAddress2}</span></div>
                        </div>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Delivery City</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.deliveryCity}</span></div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Delivery State</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.deliveryState}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div >
                                <b style={{ color: 'gray' }}>Delivery Pincode</b>
                                <span style={{ float: 'right', color: 'blue' }}>{addressData.deliveryPinCode}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
)


export default Profile