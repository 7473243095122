import React from 'react'
import ReactTable from "react-table";

const LoyalityDetails = ({ loyalityCode, loyalitySaveHandler, loyalityList,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid Phocket-promotional-details max-width-750px">
        <div className="row">
            <h3 className="text-center blue-text">Loyality Details</h3>
        </div>
        <div className="row form-group">
            <div className="col-sm-6 col-xs-12">
                <label className="font-12px">Offer</label>
                <input type="text" className="form-control" placeholder="Enter Loyality Offer" onChange={(e) => loyalityCode(e, 'loyalityOffer')} />
            </div>
            <div className="col-sm-6 col-xs-12">
                <label className="font-12px">Status</label>
                <select className="form-control" onChange={(e) => loyalityCode(e, 'loyalityStatus')}>
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                </select>
            </div>
        </div>
        <div className="row text-center form-group">
            <button className="profile-save-btn" id="transSubmitBtn" onClick={e => loyalitySaveHandler()}>
                <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
            </button>
        </div>

        <div className="row form-group overflow-auto">
            <h3 className="text-center blue-text">Loyality Code Details</h3>
    
            {loyalityList != null && loyalityList != '' && loyalityList.length > 0 ?
                <div className='overflow-auto'>
                    <table className='phocket-table'>
                        <thead>

                            <tr>
                                <th>Offer Id</th>
                                <th>Discount Amount</th>
                                <th>Status</th>
                                <th> Date</th>

                            </tr>

                        </thead>
                        <tbody>
                            {loyalityList && loyalityList.map((promo, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{promo.offerId}</td>
                                        <td>{promo.amountOffer}</td>
                                        <td>{promo.status}</td>
                                        <td>{promo.date}</td>



                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : ""}

        </div>
    </div>
)

export default LoyalityDetails;