import React from 'react'

const TransactionPage = ({ bankData ,state}) => (
    <div className='container-fluid '>
        <div className='row' >
            <div className='col-sm-3 col-xs-12'></div>
            
            <div className='col-sm-6 col-xs-12 card-profile'>
                <h3 className='text-center'><b>Bank Details</b></h3>
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div>  
                    <b style={{color:'gray'}}>Account Number</b>
                    <span style={{ float: 'right',color:'blue' }}> {bankData.accountNo}</span></div>
            </div>
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>IFSC Code</b>
                    <span style={{ float: 'right',color:'blue' }}>{bankData.ifscCode}</span></div>
            </div>
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Name On Bank</b>
                    <span style={{ float: 'right',color:'blue' }}>{bankData.nameOnBank}</span></div>
            </div>
           
          </div>
          </div>
    </div>
)


export default TransactionPage