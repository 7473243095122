import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EditUserDetailPage from '../../../presentationals/Admin/CustomerPages/EditBadaBuisnessDetails.component'
import {
  fetchUserBasicDetail,
  fetchAddressDetail,
  fetchOccupationAndEmployment,
  fetchBankDetail,
  fetchLoanDetail,
  uploadImage,
  uploadPdf,
  passwordCheck,
  zipAndBranchDetail,
  saveLoanId,
  aadharExistCheck,
  saveOrUpdateBuisnessDetaiom,

} from '../AdminActionCreator.component'
import { FORM_STATUS, TYPE } from '../../../../utils/Constant'
import Popup from '../../../presentationals/Popup/Popup.component'
import { _formatDate } from '../../../../utils/validation'
import {
  basicFormValidationbadaBuisness,
  BadaBuisnessResidenceFormValidation,
  occupationFormValidation,
  BankFormValidation
} from '../../../../utils/validation'
import { LoanFormValidation } from '../validation'
import { APIS } from '../../../../utils/api-factory'
import { getRendomNumber } from '../../../../utils/commonUtility'
import ConfirmPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import Loader from '../../../presentationals/Loader/DotLoader.component'
import PanLoader from '../../../presentationals/Loader/Loader.component'

let File1 = ''
let File2 = ''
let File3 = ''
let File4 = ''
let File5 = ''
let File6 = ''
let File = []
let interval

class EditUserDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      panVerifiedstate: false,
      aadharExist: '',
      loanMsges: '',
      salaryCheck: '',
      suggestedCompanyName: '',
      autoSuggestionState: false,
      basicValidationMsg: '',
      residenceValidationMsg: '',
      occupationValidationMsg: '',
      bankValidationMsg: '',
      docType: '',
      loanValidationMsg: '',
      BankPasswordState: false,
      SalaryPasswordState: false,
      docPass: '',
      BasicDOB: '',
      popupState: false,
      progressStateWidth: 6,
      progressState: false,
      popupStatus: '',
      allDetail: this.props.allDetail,
      userDetail: {
        nameAsOnPan: '',
        userId: '',
        firstNameState: '',
        MiddleNameState: '',
        lastNameState: '',
        BasicDOB: '',
        GenderState: '',
        EmailIDState: '',
        MobileNumberState: '',
        alternateMobileState: '',
        appLoanId: '',

        CurrentResidenceTypeValue: '',
        PermanentResidenceTypeValue: '',
        CurrentPinCodeState: '',
        CurrentDurationState: '',
        CurrentCityState: '',
        CurrentStateState: '',
        CurrentAddressState: '',
        PermanentPinCodeState: '',
        PermanentDurationState: '',
        PermanentCityState: '',
        PermanentStateState: '',
        PermanentAddressState: '',

        HighQualificationState: '',
        fullPartTimeState: '',
        EmployeeTypeValue: '',
        OrganizationNameState: '',
        HomeSalaryState: '',
        WorkExperienceCurrentState: '',
        IsFirstJobState: '',
        WorkExperiencePreviousState: '',
        WorkExperienceTotalState: '',
        EmployeeTypeState: '',
        IndustrialTypeState: '',
        otherIndustrialTypeState: '',
        MonthlyIncomeState: '',
        designationState: '',
        officeAddressState: '',
        officeMailState: '',

        BankNameState: '',
        BankUserNameState: '',
        AccountNoState: '',
        IFSCCodeState: '',
        BankBranchState: '',
        IfSalriedAccountState: '',

        loanAmountState: '',
        chequeLimitState: '',
        loanDaysState: '',
        PanNoState: '',
        aadharNoState: '',
        loanReasonState: '',

        dashPhotoState: 'No File Chosen',
        dashIdProofState: 'No File Chosen',
        dashPanState: 'No File Chosen',
        dashBankStatmentState: 'No File Chosen',
        dashSalProofState: 'No File Chosen',
        dashItrState: 'No File Chosen',
        dashCibilState: 'No File Chosen',
        dashMiscellaneousState: 'No File Chosen',
        currentAddresState: 'No File Chosen',

        formStatus: FORM_STATUS.UPDATE,
        passwordErrorMsg: '',
        ambassadorIdState: '',
        noOfEmployeeState: '',
        confirmPopupState: false,
        confirmationText: '',
        dataChange: this.props.dataChange,
        bankData: '',

        vreedhiData: "",
        vreedhiDataState: {},
      },
      isPenNo: false,
      loaderState: false,
      loaderStateforPan: false
    }
  }
  AddressCheckHendler(e) {
    let userDetail = Object.assign({}, this.state.userDetail);
    let AddressCheckValue = e.target.checked
    let durationID = document.getElementById("phocketPermanentDuration");
    if (AddressCheckValue) {
      userDetail.PermanentAddressState = this.state.userDetail.CurrentAddressState;
      userDetail.PermanentPinCodeState = this.state.userDetail.CurrentPinCodeState;
      userDetail.PermanentStateState = this.state.userDetail.CurrentStateState;
      userDetail.PermanentCityState = this.state.userDetail.CurrentCityState;
      userDetail.PermanentDurationState = this.state.userDetail.CurrentDurationState;
      userDetail.PermanentResidenceTypeValue = this.state.userDetail.CurrentResidenceTypeValue
      this.setState({
        PermanentResidenceTypeValue: this.state.CurrentResidenceTypeValue,
        AddressCheckValue: true,
        userDetail
      })

      if (durationID != null) {
        document.getElementById("phocketPermanentDuration").value = this.state.userDetail.CurrentDurationState;
      }
    } else {

      userDetail.PermanentAddressState = ""
      userDetail.PermanentPinCodeState = ""
      userDetail.PermanentStateState = ""
      userDetail.PermanentCityState = ""
      userDetail.PermanentDurationState = ""
      userDetail.PermanentResidenceTypeValue = ""

      this.setState({
        PermanentResidenceTypeValue: "",
        AddressCheckValue: false,
        userDetail
      })
    }
  }
  saveVerify() {
    const { userDetails } = this.props
    if (this.state.userDetail.nameAsOnPan != '' && this.state.userDetail.nameAsOnPan != null) {
      fetch(APIS.PAN_VERIFY_CUSTOMER + this.state.userDetail.userId + "&pan=" + this.state.userDetail.PanNoState + "&nameOnPan=" + this.state.userDetail.nameAsOnPan)
        .then(res => res.text())
        .then(json => {
          if (json == 'success') {
            this.setState({
              panVerifiedstate: true,
            })

          }
        })

    } else {
      this.setState({
        popupState: true,
        popupStatus: "Enter Name as on Pancard"
      }, () => this.removePopup())
    }
  }
  componentWillMount() {
    this.checkPincode()
    fetch(APIS.USER_DETAIL_BY_ID + this.state.allDetail.userId)
      .then(res => res.json())
      .then(res => {
        this.setState({
          allDetail: res
        })
        fetch(APIS.CHECK_REPEAT_USER + res.userId)
          .then(res => res.json())
          .then(res => {
            this.setState({ salaryCheck: res })
          })
      })
  }
  checkPincode() {
    fetch(APIS.GET_LOAN_PAGE_MSG)
      .then(res => res.json())
      .then(json => {
        this.setState({
          loanMsges: json
        })
      })
  }
  cancelLoanConfirm(type) {
    if (type == 'yes') {
      let bankValidation = BankFormValidation(this.state.userDetail)
      if (bankValidation.status) {
        this.setState({
          bankValidationMsg: bankValidation
        })
        fetchBankDetail(
          this.state.userDetail,
          this.state.allDetail.userId,
          callBack => {
            this.setState({
              popupState: true,
              popupStatus: 'Bank Details Changed Successfully',
              confirmPopupState: false
            })
            this.removePopup()
          }
        )
      } else {
        this.setState({
          bankValidationMsg: bankValidation
        })
      }
    } else if (type == 'no') {
      this.setState({ confirmPopupState: false })
    }

  }
  render() {
    const { admin, userOtherInfo, userDetails } = this.props

    return (
      <div>
        {this.state.popupState == true ? (
          <Popup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
        ) : (
          ''
        )}
        {this.state.confirmPopupState ?
          <ConfirmPopup confirmationText={this.state.confirmationText} cancelLoanConfirm={this.cancelLoanConfirm.bind(this)} />
          : ""}
        <EditUserDetailPage
          AddressCheckHendler={this.AddressCheckHendler.bind(this)}
          onChangeHandlerBuisness={this.onChangeHandlerBuisness.bind(this)}
          userDetails={userDetails}
          onSubmitHandlerVreedhi={this.onSubmitHandlerVreedhi.bind(this)}
          allDetail={this.state.allDetail}
          docType={this.state.docType}
          onChangeHandler={this.onChangeHandler.bind(this)}
          onSubmitHandler={this.onSubmitHandler.bind(this)}
          userDetail={this.state.userDetail}
          BasicDOB={this.state.BasicDOB}
          docPassword={this.docPassword.bind(this)}
          progressStateWidth={this.state.progressStateWidth}
          progressState={this.state.progressState}
          docPasswordHandler={this.docPasswordHandler.bind(this)}
          BankPasswordState={this.state.BankPasswordState}
          SalaryPasswordState={this.state.SalaryPasswordState}
          basicValidationMsg={this.state.basicValidationMsg}
          residenceValidationMsg={this.state.residenceValidationMsg}
          occupationValidationMsg={this.state.occupationValidationMsg}
          bankValidationMsg={this.state.bankValidationMsg}
          loanValidationMsg={this.state.loanValidationMsg}
          passwordErrorMsg={this.state.passwordErrorMsg}
          suggestedCompanyName={this.state.suggestedCompanyName}
          autoSuggestionState={this.state.autoSuggestionState}
          setCompnyValue={this.setCompnyValue.bind(this)}
          autoSuggestionClose={this.autoSuggestionClose.bind(this)}
          admin={admin}
          userOtherInfo={userOtherInfo}
          vreedhiDataState={this.state.vreedhiDataState}
        />
        {this.state.loaderState ?
          <Loader />
          : ""}
        {this.state.loaderStateforPan ?
          <PanLoader />
          : ""}
      </div>
    )
  }

  removePopup() {
    setTimeout(
      function () {
        this.setState({ popupState: false })
      }.bind(this),
      5000
    )
  }

  closePopup() {
    this.setState({ popupState: false })
  }
  getBankDetailData() {
    const { userDetails } = this.props

  }
  onChangeHandlerBuisness(e, type) {
    let vreedhiDataState = Object.assign({}, this.state.vreedhiDataState)

    if (type === 'name') {
      vreedhiDataState['name'] = e.target.value

    }
    if (type === 'address') {
      vreedhiDataState['address'] = e.target.value

    }
    if (type === 'category') {
      vreedhiDataState['category'] = e.target.value

    }
    if (type === 'segment') {
      vreedhiDataState['segment'] = e.target.value

    }
    if (type === 'monthlyTurnover') {
      vreedhiDataState['monthlyTurnover'] = e.target.value

    }
    if (type === 'netBussinessIncome') {
      vreedhiDataState['netBussinessIncome'] = e.target.value

    }
    this.setState({ vreedhiDataState })
  }

  componentDidMount() {
    // this.getBankDetailData()
    const { userDetails } = this.props

    this.setState({ loaderState: true })
    let vreedhiDataState = Object.assign({}, this.state.vreedhiDataState)

    if (userDetails.loanFrom === "vreedhi") {
      fetch(APIS.GET_VREEDHI_DATA + userDetails.userId)
        .then(res => res.json())
        .then(json => {
          vreedhiDataState['name'] = json.name
          vreedhiDataState['address'] = json.address
          vreedhiDataState['category'] = json.category
          vreedhiDataState['segment'] = json.segment
          vreedhiDataState['monthlyTurnover'] = json.monthlyTurnover
          vreedhiDataState['netBussinessIncome'] = json.netBussinessIncome


          this.setState({ vreedhiData: json, vreedhiDataState })
        })
    }
    let userDetail = Object.assign({}, this.state.userDetail)

    fetch(APIS.GET_ACCOUNT_DETAILS_USER_ID + userDetails.userId)
      .then(res => res.json())
      .then(json => {
        if (
          json.nameAsBankAccount != null &&
          json.nameAsBankAccount != ''
        ) {
          userDetail.BankUserNameState = json.nameAsBankAccount
        }
        if (
          json.accountNumber != null &&
          json.accountNumber != 0
        ) {
          userDetail.AccountNoState = json.accountNumber
        }
        if (
          json.ifscCode != null &&
          json.ifscCode != ''
        ) {
          userDetail.IFSCCodeState = json.ifscCode
        }
        if (
          json.branch != null &&
          json.branch != ''
        ) {
          userDetail.BankBranchState = json.branch
        }
        if (
          json.bankName != null &&
          json.bankName != ''
        ) {
          userDetail.BankNameState = json.bankName
        }
        if (
          json.ifSalariedAccount != null &&
          json.ifSalariedAccount != ''
        ) {
          userDetail.IfSalriedAccountState = json.ifSalariedAccount
        }

        if (
          this.state.allDetail.nameAsOnPan != null &&
          this.state.allDetail.nameAsOnPan != ''
        ) {
          userDetail.nameAsOnPan = this.state.allDetail.nameAsOnPan
        }
        if (
          this.state.allDetail.firstName != null &&
          this.state.allDetail.firstName != ''
        ) {
          userDetail.firstNameState = this.state.allDetail.firstName
        }
        if (
          this.state.allDetail.middleName != null &&
          this.state.allDetail.middleName != ''
        ) {
          userDetail.MiddleNameState = this.state.allDetail.middleName
        }
        if (
          this.state.allDetail.lastName != null &&
          this.state.allDetail.lastName != ''
        ) {
          userDetail.lastNameState = this.state.allDetail.lastName
        }
        if (
          this.state.allDetail.dateOfBirth != null &&
          this.state.allDetail.dateOfBirth != ''
        ) {
          userDetail.BasicDOB = this.state.allDetail.dateOfBirth
        }
        if (
          this.state.allDetail.gender != null &&
          this.state.allDetail.gender != ''
        ) {
          userDetail.GenderState = this.state.allDetail.gender
        }
        if (
          this.state.allDetail.emailId != null &&
          this.state.allDetail.emailId != ''
        ) {
          userDetail.EmailIDState = this.state.allDetail.emailId
        }
        if (
          this.state.allDetail.mobileNumber != null &&
          this.state.allDetail.mobileNumber != ''
        ) {
          userDetail.MobileNumberState = this.state.allDetail.mobileNumber
        }
        if (
          this.state.allDetail.alternativeNo != null &&
          this.state.allDetail.alternativeNo != ''
        ) {
          userDetail.alternateMobileState = this.state.allDetail.alternativeNo
        }
        if (
          this.state.allDetail.applicationLoanId != null &&
          this.state.allDetail.applicationLoanId != ''
        ) {
          userDetail.appLoanId = this.state.allDetail.applicationLoanId
        }
        fetch(APIS.USER_DETAIL_BY_ID + this.state.allDetail.userId)
          .then(res => res.json())
          .then(addressData => {


            // if (
            //   this.state.allDetail.addressDetailsPOList.currentAddress != null &&
            //   this.state.allDetail.addressDetailsPOList.currentAddress != ''
            // ) {
            userDetail.CurrentAddressState = this.state.allDetail.addressDetailsPOList.currentAddress
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.currentAddress != null &&
            //   this.state.allDetail.addressDetailsPOList.currentPincode != 0
            // ) {
            userDetail.CurrentPinCodeState = this.state.allDetail.addressDetailsPOList.currentPincode.toString()
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.currentState != null &&
            //   this.state.allDetail.addressDetailsPOList.currentState != ''
            // ) {
            userDetail.CurrentStateState = this.state.allDetail.addressDetailsPOList.currentState
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.currentCity != null &&
            //   this.state.allDetail.addressDetailsPOList.currentCity != ''
            // ) {
            userDetail.CurrentCityState = this.state.allDetail.addressDetailsPOList.currentCity
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.currentResidenceType != null &&
            //   this.state.allDetail.addressDetailsPOList.currentResidenceType != ''
            // ) {
            userDetail.CurrentResidenceTypeValue = this.state.allDetail.addressDetailsPOList.currentResidenceType
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.currentResidenceValue != null &&
            //   this.state.allDetail.addressDetailsPOList.currentResidenceValue != ''
            // ) {
            userDetail.CurrentDurationState = this.state.allDetail.addressDetailsPOList.currentResidenceValue
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.permanentAddress != null &&
            //   this.state.allDetail.addressDetailsPOList.permanentAddress != ''
            // ) {
            userDetail.PermanentAddressState = this.state.allDetail.addressDetailsPOList.permanentAddress
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.permanentPincode != null &&
            //   this.state.allDetail.addressDetailsPOList.permanentPincode != 0
            // ) {
            userDetail.PermanentPinCodeState = this.state.allDetail.addressDetailsPOList.permanentPincode.toString()
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.permanentCity != null &&
            //   this.state.allDetail.addressDetailsPOList.permanentCity != ''
            // ) {
            userDetail.PermanentCityState = this.state.allDetail.addressDetailsPOList.permanentCity
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.permanentState != null &&
            //   this.state.allDetail.addressDetailsPOList.permanentState != ''
            // ) {
            userDetail.PermanentStateState = this.state.allDetail.addressDetailsPOList.permanentState
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.permanentResidenceType !=
            //   null &&
            //   this.state.allDetail.addressDetailsPOList.permanentResidenceType != ''
            // ) {
            userDetail.PermanentResidenceTypeValue = this.state.allDetail.addressDetailsPOList.permanentResidenceType
            // }
            // if (
            //   this.state.allDetail.addressDetailsPOList.permanentResidenceValue !=
            //   null &&
            //   this.state.allDetail.addressDetailsPOList.permanentResidenceValue != ''
            // ) {
            userDetail.PermanentDurationState = this.state.allDetail.addressDetailsPOList.permanentResidenceValue
            // }

            if (
              this.state.allDetail.employmentDetailsPO.highestQualification != null &&
              this.state.allDetail.employmentDetailsPO.highestQualification != ''
            ) {
              userDetail.HighQualificationState = this.state.allDetail.employmentDetailsPO.highestQualification
            }
            if (
              this.state.allDetail.employmentDetailsPO.fullPartTime != null &&
              this.state.allDetail.employmentDetailsPO.fullPartTime != ''
            ) {
              userDetail.fullPartTimeState = this.state.allDetail.employmentDetailsPO.fullPartTime
            }
            if (
              this.state.allDetail.employmentDetailsPO.employerType != null &&
              this.state.allDetail.employmentDetailsPO.employerType != ''
            ) {
              userDetail.EmployeeTypeValue = this.state.allDetail.employmentDetailsPO.employerType
            }
            if (
              this.state.allDetail.employmentDetailsPO.organizationName != null &&
              this.state.allDetail.employmentDetailsPO.organizationName != ''
            ) {
              userDetail.OrganizationNameState = this.state.allDetail.employmentDetailsPO.organizationName
            }
            if (
              this.state.allDetail.employmentDetailsPO.netTakeHomeSalary != null &&
              this.state.allDetail.employmentDetailsPO.netTakeHomeSalary != ''
            ) {
              userDetail.HomeSalaryState = this.state.allDetail.employmentDetailsPO.netTakeHomeSalary
            }
            if (
              this.state.allDetail.employmentDetailsPO.officeAddress != null &&
              this.state.allDetail.employmentDetailsPO.officeAddress != ''
            ) {
              userDetail.officeAddressState = this.state.allDetail.employmentDetailsPO.officeAddress
            }
            if (
              this.state.allDetail.employmentDetailsPO.optionalEmail != null &&
              this.state.allDetail.employmentDetailsPO.optionalEmail != ''
            ) {
              userDetail.officeMailState = this.state.allDetail.employmentDetailsPO.optionalEmail
            }
            if (
              this.state.allDetail.employmentDetailsPO.designation != null &&
              this.state.allDetail.employmentDetailsPO.designation != ''
            ) {
              userDetail.designationState = this.state.allDetail.employmentDetailsPO.designation
            }
            if (
              this.state.allDetail.employmentDetailsPO
                .workExperienceCurrentOrganization != null &&
              this.state.allDetail.employmentDetailsPO
                .workExperienceCurrentOrganization != ''
            ) {
              userDetail.WorkExperienceCurrentState = this.state.allDetail.employmentDetailsPO.workExperienceCurrentOrganization
            }
            if (
              this.state.allDetail.employmentDetailsPO.isThisFirstJob != null &&
              this.state.allDetail.employmentDetailsPO.isThisFirstJob != ''
            ) {
              userDetail.IsFirstJobState = this.state.allDetail.employmentDetailsPO.isThisFirstJob
            }
            if (
              this.state.allDetail.employmentDetailsPO
                .workExperiencePreviousOrganization != null &&
              this.state.allDetail.employmentDetailsPO
                .workExperiencePreviousOrganization != ''
            ) {
              userDetail.WorkExperiencePreviousState = this.state.allDetail.employmentDetailsPO.workExperiencePreviousOrganization
            }
            if (
              this.state.allDetail.employmentDetailsPO.totalExperience != null &&
              this.state.allDetail.employmentDetailsPO.totalExperience != ''
            ) {
              userDetail.WorkExperienceTotalState = this.state.allDetail.employmentDetailsPO.totalExperience
            }
            if (
              this.state.allDetail.employmentDetailsPO.selfEmployeeType != null &&
              this.state.allDetail.employmentDetailsPO.selfEmployeeType != ''
            ) {
              userDetail.EmployeeTypeState = this.state.allDetail.employmentDetailsPO.selfEmployeeType
            }
            if (
              this.state.allDetail.employmentDetailsPO.businessIndustryType != null &&
              this.state.allDetail.employmentDetailsPO.businessIndustryType != ''
            ) {
              userDetail.IndustrialTypeState = this.state.allDetail.employmentDetailsPO.businessIndustryType
              userDetail.otherIndustrialTypeState = this.state.allDetail.employmentDetailsPO.businessIndustryType
            }
            if (
              this.state.allDetail.employmentDetailsPO.monthlyIncome != null &&
              this.state.allDetail.employmentDetailsPO.monthlyIncome != ''
            ) {
              userDetail.MonthlyIncomeState = this.state.allDetail.employmentDetailsPO.monthlyIncome
            }
            if (
              this.state.allDetail.employmentDetailsPO.noOfEmployees != null &&
              this.state.allDetail.employmentDetailsPO.noOfEmployees != ''
            ) {
              userDetail.noOfEmployeeState = this.state.allDetail.employmentDetailsPO.noOfEmployees
            }
            this.setState({ userDetail })
          })
        // if (json !== '' && json !== undefined) {
        // if (
        //   json.nameAsBankAccount != null &&
        //   json.nameAsBankAccount != ''
        // ) {
        //   userDetail.BankUserNameState = json.nameAsBankAccount
        // }
        // if (
        //   json.accountNumber != null &&
        //   json.accountNumber != 0
        // ) {
        //   userDetail.AccountNoState = json.accountNumber
        // }
        // if (
        //   json.ifscCode != null &&
        //   json.ifscCode != ''
        // ) {
        //   userDetail.IFSCCodeState = json.ifscCode
        // }
        // if (
        //   json.branch != null &&
        //   json.branch != ''
        // ) {
        //   userDetail.BankBranchState = json.branch
        // }
        // if (
        //   json.bankName != null &&
        //   json.bankName != ''
        // ) {
        //   userDetail.BankNameState = json.bankName
        // }
        // if (
        //   json.ifSalariedAccount != null &&
        //   json.ifSalariedAccount != ''
        // ) {
        //   userDetail.IfSalriedAccountState = json.ifSalariedAccount
        // }
        // }
        this.setState({ bankData: json })
      })
    // if (this.state.allDetail.loanDetailList != null && this.state.allDetail.loanDetailList != "") {
    //     let loanDataLength = this.state.allDetail.loanDetailList.length - 1;
    //     userDetail.loanAmountState = this.state.allDetail.loanDetailList[loanDataLength].loanAmount
    //     userDetail.loanDaysState = this.state.allDetail.loanDetailList[loanDataLength].paybackDuration
    //     userDetail.loanReasonState = this.state.allDetail.loanDetailList[loanDataLength].loanReason
    // }

    if (userDetails.loanAmount != null && userDetails.loanAmount != '') {
      userDetail.loanAmountState = userDetails.loanAmount
    }
    if (userDetails.loanDuration != null && userDetails.loanDuration != '') {
      userDetail.loanDaysState = userDetails.loanDuration
    }
    if (userDetails.loanReason != null && userDetails.loanReason != '') {
      userDetail.loanReasonState = userDetails.loanReason
    }
    if (userDetails.chequeLimit != null && userDetails.chequeLimit != '') {
      userDetail.chequeLimitState = userDetails.chequeLimit
    }

    if (
      this.state.allDetail.panNumber != null &&
      this.state.allDetail.panNumber != ''
    ) {
      userDetail.PanNoState = this.state.allDetail.panNumber
    }
    if (
      this.state.allDetail.aadharNumber != null &&
      this.state.allDetail.aadharNumber != 0
    ) {
      userDetail.aadharNoState = this.state.allDetail.aadharNumber
    }
    this.setState({ userDetail })
    fetch(APIS.GET_USER_DOCUMENTS_BY_USER_ID + userDetails.userId)
      .then(res => res.json())
      .then(data => {
        if (
          data.documentList != null &&
          data.documentList.photographUrl != null
        ) {
          userDetail.dashPhotoState = data.documentList.photographUrl
        }
        if (
          data.documentList != null &&
          data.documentList.adharCardUrl != null
        ) {
          userDetail.dashIdProofState = data.documentList.adharCardUrl
        }
        if (
          data.documentList != null &&
          data.documentList.panCardUrl != null
        ) {
          userDetail.dashPanState = data.documentList.panCardUrl
        }
        if (
          data.documentList != null &&
          data.documentList.bankStatementUrl != null
        ) {
          userDetail.dashBankStatmentState = data.documentList.bankStatementUrl
        }
        if (
          data.documentList != null &&
          data.documentList.salaryProofUrl != null
        ) {
          userDetail.dashSalProofState = data.documentList.salaryProofUrl
        }
        if (
          data.documentList != null &&
          data.documentList.itrUrl != null
        ) {
          userDetail.dashItrState = data.documentList.itrUrl
        }
        if (
          data.documentList != null &&
          data.documentList.cibilUrl != null
        ) {
          userDetail.dashCibilState = data.documentList.cibilUrl
        }
        if (
          data.documentList != null &&
          data.documentList.miscellaneousUrl != null
        ) {
          userDetail.dashMiscellaneousState = data.documentList.miscellaneousUrl
        }
        if (
          data.documentList != null &&
          data.documentList.currentAddressUrl != null
        ) {
          userDetail.currentAddresState = data.documentList.currentAddressUrl
        }
      })
    if (
      this.state.allDetail.ambassadorId != null &&
      this.state.allDetail.ambassadorId != ''
    ) {
      userDetail.ambassadorIdState = this.state.allDetail.ambassadorId
    }
    this.setState({
      userDetail, loaderState: false
    })

  }

  docPasswordHandler(e, type) {
    let userDetail = Object.assign({}, this.state.userDetail)
    if (type == 'bank') {
      uploadPdf(
        File,
        TYPE.BANK_STATMENT +
        '@' +
        getRendomNumber(3) +
        '-' +
        this.state.docPass,
        'val',
        this.state.docPass,
        this.state.allDetail.userId,
        callBack => {
          if (callBack != null && callBack != '') {
            userDetail.dashBankStatmentState = callBack
            this.setState({
              BankPasswordState: false,
              userDetail,
              passwordErrorMsg: ''
            })
          } else {
            this.setState({
              passwordErrorMsg: 'Wrong Password'
            })
          }
        }
      )
    } else if (type == 'salary') {
      uploadPdf(
        File,
        TYPE.SALARY_PROOF + '@' + getRendomNumber(3) + '-' + this.state.docPass,
        'val',
        this.state.docPass,
        this.state.allDetail.userId,
        callBack => {
          if (callBack != null && callBack != '') {
            userDetail.dashSalProofState = callBack
            this.setState({
              SalaryPasswordState: false,
              userDetail
            })
          } else {
            this.setState({
              passwordErrorMsg: 'Wrong Password'
            })
          }
        }
      )
    }
  }
  docPassword(e) {
    this.setState({
      docPass: e.target.value,
      passwordErrorMsg: ''
    })
  }

  setCompnyValue(companyName) {
    let userDetail = Object.assign({}, this.state.userDetail)
    userDetail.OrganizationNameState = companyName
    this.setState({ userDetail, autoSuggestionState: false })
  }
  autoSuggestionClose() {
    this.setState({ autoSuggestionState: false })
  }

  onChangeHandler(e, type) {
    const { admin } = this.props
    let userDetail = Object.assign({}, this.state.userDetail)
    if (type == TYPE.FIRST_NAME) {
      userDetail.firstNameState = e.target.value
    } else if (type == TYPE.MIDDLE_NAME) {
      userDetail.MiddleNameState = e.target.value
    } else if (type == TYPE.LAST_NAME) {
      userDetail.lastNameState = e.target.value
    } else if (type == TYPE.DOB) {
      if (e < new Date()) {
        userDetail.BasicDOB = _formatDate(e)
        this.setState({
          BasicDOB: e
        })
      } else {
        userDetail.BasicDOB = ''
        this.setState({
          BasicDOB: ''
        })
      }
    } else if (type == TYPE.GENDER) {
      userDetail.GenderState = e.target.value
    } else if (type == TYPE.CURRENT_ADDRESS) {
      userDetail.CurrentAddressState = e.target.value
    } else if (type == TYPE.CURRENT_PIN_CODE) {
      if (admin.role == 'superuser') {
        let pincode = e.target.value
        if (pincode.length == 6) {
          zipAndBranchDetail(e.target.value, 'zip', res => {
            if (res.status === 'success') {
              userDetail.CurrentCityState = res.destrict
              userDetail.CurrentStateState = res.state
              this.setState({ userDetail })
            }
          })
          // fetch(APIS.GET_STATE_CITY + pincode)
          //   .then(res => res.json())
          //   .then(json => {
          //     userDetail.CurrentStateState = json.state
          //     userDetail.CurrentCityState = json.district
          //     document.getElementById('userCurrentState').value = json.state
          //     document.getElementById('userCurrentCity').value = json.district
          //   })
        } else {
          userDetail.CurrentStateState = ''
          userDetail.CurrentCityState = ''
          document.getElementById('userCurrentState').value = ''
          document.getElementById('userCurrentCity').value = ''
        }
        userDetail.CurrentPinCodeState = pincode
      } else {
        let pinCodeCurrent = e.target.value
        if (pinCodeCurrent.length == 6) {
          zipAndBranchDetail(e.target.value, 'zip', res => {
            if (res.status === 'success') {
              userDetail.CurrentCityState = res.destrict
              userDetail.CurrentStateState = res.state
              this.setState({ userDetail })
            }
          })
        
          } else if (this.state.loanMsges.pincodes != undefined) {
            userDetail.CurrentStateState = this.state.loanMsges[0].state
            userDetail.CurrentCityState = this.state.loanMsges[0].district
            document.getElementById('userCurrentState').value = this.state.loanMsges[0].state
            document.getElementById('userCurrentCity').value = this.state.loanMsges[0].district
          
        } else {
          userDetail.currentPincode = ''
          userDetail.CurrentStateState = ''
          userDetail.CurrentCityState = ''
          document.getElementById('userCurrentState').value = ''
          document.getElementById('userCurrentCity').value = ''
        }
        userDetail.CurrentPinCodeState = pinCodeCurrent
      }
    } else if (type == TYPE.CURRENT_RESIDANCE_TYPE) {
      userDetail.CurrentResidenceTypeValue = e.target.value
    } else if (type == TYPE.CURRENT_DURATION) {
      userDetail.CurrentDurationState = e.target.value
    } else if (type == TYPE.CURRENT_STATE) {
      userDetail.CurrentStateState = e.target.value
    } else if (type == TYPE.CURRENT_CITY) {
      userDetail.CurrentCityState = e.target.value
    } else if (type == TYPE.PERMANENT_ADDRESS) {
      userDetail.PermanentAddressState = e.target.value
    } else if (type == TYPE.PERMANENT_PIN_CODE) {
      userDetail.PermanentPinCodeState = e.target.value
      zipAndBranchDetail(e.target.value, 'zip', res => {
        if (res.status === 'success') {
          userDetail.PermanentCityState = res.destrict
          userDetail.PermanentStateState = res.state
          this.setState({ userDetail })
        }
      })
    } else if (type == TYPE.PERMANENT_RESIDANCE_TYPE) {
      userDetail.PermanentResidenceTypeValue = e.target.value
    } else if (type == TYPE.PERMANENT_DURATION) {
      userDetail.PermanentDurationState = e.target.value
    } else if (type == TYPE.PERMANENT_STATE) {
      userDetail.PermanentStateState = e.target.value
    } else if (type == TYPE.PERMANENT_CITY) {
      userDetail.PermanentCityState = e.target.value
    } else if (type == TYPE.HIGHEST_QUALIFICATION) {
      userDetail.HighQualificationState = e.target.value
    } else if (type == TYPE.FULL_PART_TIME) {
      userDetail.fullPartTimeState = e.target.value
    } else if (type == TYPE.EMPLOYER_TYPE) {
      userDetail.EmployeeTypeValue = e.target.value
    } else if (type === TYPE.DESIGNATION) {
      userDetail.designationState = e.target.value
    } else if (type === TYPE.NO_OF_EMPLOYEE) {
      userDetail.noOfEmployeeState = e.target.value
    } else if (type == TYPE.ORGANIZATION_NAME) {
      let orgName = e.target.value
      userDetail.OrganizationNameState = orgName
      if (orgName.length > 3) {
        fetch(APIS.GET_COMPANY_NAME + orgName)
          .then(res => res.json())
          .then(json => {
            this.setState({
              suggestedCompanyName: json,
              autoSuggestionState: true
            })
          })
      } else if (orgName.length < 1) {
        this.setState({ autoSuggestionState: false })
      }
    } else if (type == TYPE.OFFICE_ADDRESS) {
      userDetail.officeAddressState = e.target.value
    } else if (type == TYPE.OFFICE_EMAIL) {
      userDetail.officeMailState = e.target.value
    } else if (type == TYPE.SALARY) {
      userDetail.HomeSalaryState = e.target.value
    } else if (type == TYPE.CURRENT_WORK_EXPERIENCE) {
      userDetail.WorkExperienceCurrentState = e.target.value
    } else if (type == TYPE.IS_FIRST_JOB) {
      userDetail.IsFirstJobState = e.target.value
    } else if (type == TYPE.PREVIOUS_WORK) {
      userDetail.WorkExperiencePreviousState = e.target.value
    } else if (type == TYPE.TOTAL_EXPERIENCE) {
      userDetail.WorkExperienceTotalState = e.target.value
    } else if (type == TYPE.SELF_EMPLOYEE_TYPE) {
      userDetail.EmployeeTypeState = e.target.value
    } else if (type == TYPE.INDUSTRY_TYPE) {
      userDetail.IndustrialTypeState = e.target.value
    } else if (type == TYPE.OTHER_INDUSTRY_TYPE) {
      userDetail.otherIndustrialTypeState = e.target.value
      userDetail.IndustrialTypeState = e.target.value
    } else if (type == TYPE.MONTHLY_INCOME) {
      userDetail.MonthlyIncomeState = e.target.value
    } else if (type == TYPE.BANK_USER_NAME) {
      userDetail.BankUserNameState = e.target.value
    } else if (type == TYPE.ACCOUNT_NO) {
      userDetail.AccountNoState = e.target.value
    } else if (type == TYPE.BANK_NAME) {
      userDetail.BankNameState = e.target.value
    } else if (type == TYPE.BANK_BRANCH) {
      userDetail.BankBranchState = e.target.value
    } else if (type == TYPE.IFSC_CODE) {
      userDetail.IFSCCodeState = e.target.value
      let ifscCode = e.target.value
      if (ifscCode.length == 11) {
        zipAndBranchDetail(e.target.value, 'branch', res => {
          if (res.status == 'success') {
            ; (userDetail.BankBranchState = res.branchCode),
              (userDetail.BankNameState = res.branchName)
            document.getElementById('userBankName').value = res.branchName
            document.getElementById('userBankBranch').value = res.branchCode
          }
        })
      } else {
        ; (userDetail.BankBranchState = ''), (userDetail.BankNameState = '')
        document.getElementById('userBankName').value = ''
        document.getElementById('userBankBranch').value = ''
      }
    } else if (type == TYPE.IF_SALARIED_ACCOUNT) {
      userDetail.IfSalriedAccountState = e.target.value
    } else if (type == TYPE.LOAN_AMOUNT) {
      userDetail.loanAmountState = e.target.value
    } else if (type == TYPE.LOAN_DAYS) {
      userDetail.loanDaysState = e.target.value
    } else if (type == TYPE.PAN_NO) {
      userDetail.PanNoState = e.target.value
      let penNo = e.target.value

      if (penNo.length >= 10 && admin.role === 'superuser') {
        userDetail.PanNoState = e.target.value
        this.setState({ isPenNo: false })
      }



      if (penNo.length >= 10 && admin.role !== 'superuser') {
        this.setState({ loaderStateforPan: true })
        fetch(APIS.CHECK_PEN_NUMBER + penNo + '&userId=' + this.state.allDetail.userId)
          .then(res => res.json())
          .then(res => {
            if (res.status == 'success') {
              this.setState({ isPenNo: false, loaderStateforPan: false })
            } else if (res.status == 'failure') {
              this.setState({
                isPenNo: true,
                popupState: true,
                popupStatus: 'Pan No Already Exist',
                loaderStateforPan: false
              }, () => this.removePopup())
            }
          })
      }
    } else if (type == 'PanName') {
      userDetail.nameAsOnPan = e.target.value
    }

    else if (type == TYPE.AADHAAR_NO) {
      userDetail.aadharNoState = e.target.value
    } else if (type == TYPE.LOAN_REASON) {
      userDetail.loanReasonState = e.target.value
    } else if (type == TYPE.MOBILE_NO) {
      userDetail.MobileNumberState = e.target.value
    } else if (type == TYPE.ALTERNATE_MOBILE) {
      userDetail.alternateMobileState = e.target.value
    } else if (type == TYPE.EMAIL_ID) {
      userDetail.EmailIDState = e.target.value
    } else if (type == TYPE.APP_LOAN_ID) {
      userDetail.appLoanId = e.target.value
    } else if (type == TYPE.CHEQUE_LIMIT) {
      userDetail.chequeLimitState = e.target.value
    } else if (type == TYPE.AMBASSADOR_ID) {
      userDetail.ambassadorIdState = e.target.value
    } else {
      let val = e.target.value.split(/[\\\/]/)[2]
      let reader = new FileReader()
      File1 = e.target.files[0]
      File2 = e.target.files[1]
      File3 = e.target.files[2]
      File4 = e.target.files[3]
      File5 = e.target.files[4]
      File6 = e.target.files[5]
      File = [File1, File2, File3, File4, File5, File6]
      let docType = val.split('.')
      let fileType = docType[docType.length - 1]
      if (type == TYPE.PHOTO) {
        userDetail.dashPhotoState = val
        this.setState({ progressState: true })
        this.progressHandler()
        if (fileType == 'pdf') {
          uploadPdf(
            File,
            type + '@' + getRendomNumber(3),
            val,
            '',
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashPhotoState = callBack
              this.setState({ progressState: false })
              userDetail.dashPhotoState = callBack
              this.setState({
                userDetail
              })
            }
          )
        } else {
          uploadImage(
            File,
            type + '@' + getRendomNumber(3),
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashPhotoState = callBack
              this.setState({ progressState: false })
              userDetail.dashPhotoState = callBack
              this.setState({
                userDetail
              })
            }
          )
        }
      } else if (type == TYPE.ADHAR_CARD) {
        userDetail.dashIdProofState = val
        this.setState({ progressState: true })
        if (fileType == 'pdf') {
          uploadPdf(
            File,
            type + '@' + getRendomNumber(3),
            val,
            '',
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashIdProofState = callBack
              this.setState({ progressState: false })
              this.setState({
                userDetail
              })
            }
          )
        } else {
          uploadImage(
            File,
            type + '@' + getRendomNumber(3),
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashIdProofState = callBack
              this.setState({ progressState: false })
              this.setState({
                userDetail
              })
            }
          )
        }
      } else if (type == TYPE.PAN_CARD) {
        userDetail.dashPanState = val
        this.setState({ progressState: true })
        if (fileType == 'pdf') {
          uploadPdf(
            File,
            type + '@' + getRendomNumber(3),
            val,
            '',
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashPanState = callBack
              this.setState({ progressState: false })
              userDetail.dashPanState = callBack
              this.setState({
                userDetail
              })
            }
          )
        } else {
          uploadImage(
            File,
            type + '@' + getRendomNumber(3),
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashPanState = callBack
              this.setState({ progressState: false })
              userDetail.dashPanState = callBack
              this.setState({
                userDetail
              })
            }
          )
        }
      } else if (type == TYPE.BANK_STATMENT) {
        userDetail.dashBankStatmentState = val
        this.setState({ progressState: true })
        if (fileType == 'pdf') {
          passwordCheck(File, callBack => {
            userDetail.dashBankStatmentState = callBack
            this.setState({ progressState: false })
            if (callBack == 'success') {
              this.setState({
                BankPasswordState: true
              })
            } else {
              uploadPdf(
                File,
                type + '@' + getRendomNumber(3),
                val,
                '',
                this.state.allDetail.userId,
                callBack => {
                  userDetail.dashBankStatmentState = callBack
                  this.setState({ progressState: false })
                  userDetail.dashBankStatmentState = callBack
                  this.setState({
                    userDetail
                  })
                }
              )
            }
          })
        } else {
          uploadImage(
            File,
            type + '@' + getRendomNumber(3),
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashBankStatmentState = callBack
              this.setState({ progressState: false })
              userDetail.dashBankStatmentState = callBack
              this.setState({
                userDetail
              })
            }
          )
        }
      } else if (type == TYPE.SALARY_PROOF) {
        userDetail.dashSalProofState = val
        this.setState({ progressState: true })
        if (fileType == 'pdf') {
          passwordCheck(File, callBack => {
            if (callBack == 'success') {
              this.setState({
                SalaryPasswordState: true
              })
            } else {
              uploadPdf(
                File,
                type + '@' + getRendomNumber(3),
                val,
                '',
                this.state.allDetail.userId,
                callBack => {
                  userDetail.dashSalProofState = callBack
                  this.setState({ progressState: false })
                  userDetail.dashSalProofState = callBack
                  this.setState({
                    userDetail
                  })
                }
              )
            }
          })
        } else {
          uploadImage(
            File,
            type + '@' + getRendomNumber(3),
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashSalProofState = callBack
              this.setState({ progressState: false })
              userDetail.dashSalProofState = callBack
              this.setState({
                userDetail
              })
            }
          )
        }
      } else if (type == TYPE.ITR_STATEMENT) {
        userDetail.dashItrState = val
        this.setState({ progressState: true })
        if (fileType == 'pdf') {
          uploadPdf(
            File,
            type + '@' + getRendomNumber(3),
            val,
            '',
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashItrState = callBack
              this.setState({ progressState: false })
              userDetail.dashItrState = callBack
              this.setState({
                userDetail
              })
            }
          )
        } else {
          uploadImage(
            File,
            type + '@' + getRendomNumber(3),
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashItrState = callBack
              this.setState({ progressState: false })
              userDetail.dashItrState = callBack
              this.setState({
                userDetail
              })
            }
          )
        }
      } else if (type == TYPE.CIBIL_REPORT) {
        userDetail.dashCibilState = val
        this.setState({ progressState: true })
        if (fileType == 'pdf') {
          uploadPdf(
            File,
            type + '@' + getRendomNumber(3),
            val,
            '',
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashCibilState = callBack
              this.setState({ progressState: false })
              userDetail.dashCibilState = callBack
              this.setState({
                userDetail
              })
            }
          )
        } else {
          uploadImage(
            File,
            type + '@' + getRendomNumber(3),
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashCibilState = callBack
              this.setState({ progressState: false })
              userDetail.dashCibilState = callBack
              this.setState({
                userDetail
              })
            }
          )
        }
      } else if (type == TYPE.MISCELLANEOUS) {
        userDetail.dashMiscellaneousState = val
        this.setState({ progressState: true })
        if (fileType == 'pdf') {
          uploadPdf(
            File,
            type + '@' + getRendomNumber(3),
            val,
            '',
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashMiscellaneousState = callBack
              this.setState({
                userDetail
              })
            }
          )
        } else {
          uploadImage(
            File,
            type + '@' + getRendomNumber(3),
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.dashMiscellaneousState = callBack
              this.setState({ progressState: false })
              userDetail.dashMiscellaneousState = callBack
              this.setState({
                userDetail
              })
            }
          )
        }
      } else if (type == TYPE.CURRENT_ADD_PROOF) {
        userDetail.currentAddresState = val
        this.setState({ progressState: true })
        if (fileType == 'pdf') {
          uploadPdf(
            File,
            type,
            val,
            '',
            this.state.allDetail.userId,
            callBack => {
              userDetail.currentAddresState = callBack
              this.setState({
                userDetail
              })
            }
          )
        } else {
          uploadImage(
            File,
            type,
            val,
            this.state.allDetail.userId,
            callBack => {
              userDetail.currentAddresState = callBack
              this.setState({ progressState: false })
              userDetail.currentAddresState = callBack
              this.setState({
                userDetail
              })
            }
          )
        }
      }
      this.progressHandler()
    }
    this.setState({
      userDetail,
      basicValidationMsg: '',
      residenceValidationMsg: '',
      occupationValidationMsg: '',
      bankValidationMsg: '',
      loanValidationMsg: '',
      docType: type
    })
  }

  progressHandler() {
    this.setState({ progressStateWidth: 6 })
    interval = setInterval(
      function () {
        this.setState({ progressStateWidth: this.state.progressStateWidth + 5 })
      }.bind(this),
      600
    )
  }
  onSubmitHandlerVreedhi() {
    const { admin } = this.props

    saveOrUpdateBuisnessDetaiom(
      this.state.vreedhiData,
      this.state.vreedhiDataState,
      admin,
      callBack => {
        this.setState({
          popupState: true,
          popupStatus: 'Vreedhi Details Changed Successfully',
          // dataChange:'yes'
        }, () => this.props.getData('yes'))

        this.removePopup()
      }
    )
  }
  onSubmitHandler(e, pageStatus) {
    const { admin } = this.props
    if (pageStatus == FORM_STATUS.BASIC) {
      let BasicValidation = basicFormValidationbadaBuisness(this.state.userDetail)
      if (BasicValidation.status) {
        this.setState({
          basicValidationMsg: BasicValidation
        })

        fetchUserBasicDetail(
          this.state.userDetail,
          this.state.allDetail,
          admin,
          callBack => {
            this.setState({
              popupState: true,
              popupStatus: 'Basic Details Changed Successfully',
              // dataChange:'yes'
            }, () => this.props.getData('yes'))

            this.removePopup()
          }
        )
      } else {
        this.setState({
          basicValidationMsg: BasicValidation
        })
      }
    } else if (pageStatus == FORM_STATUS.RESIDANCE) {
      let ResidanceValidation = BadaBuisnessResidenceFormValidation(this.state.userDetail)
      if (ResidanceValidation.status) {
        this.setState({
          residenceValidationMsg: ResidanceValidation
        })
        fetchAddressDetail(
          this.state.userDetail,
          this.state.allDetail,
          admin,
          callBack => {
            this.setState({
              popupState: true,
              popupStatus: 'Residence Details Changed Successfully'
            }, () => this.props.getData('yes'))
            this.removePopup()
          }
        )
      } else {
        this.setState({
          residenceValidationMsg: ResidanceValidation
        })
      }
    } else if (pageStatus == FORM_STATUS.OCCUPATIONAL) {
      let occupationValidation = occupationFormValidation(
        this.state.userDetail,
        this.state.salaryCheck
      )
      if (occupationValidation.status) {
        this.setState({
          occupationValidationMsg: occupationValidation
        })
        fetchOccupationAndEmployment(
          this.state.userDetail,
          this.state.allDetail,
          admin,
          callBack => {
            this.setState({
              popupState: true,
              popupStatus: 'Occupational Details Changed Successfully'
            }, () => this.props.getData('yes'))
            this.removePopup()
          }
        )
      } else {
        this.setState({
          occupationValidationMsg: occupationValidation
        })
      }
    } else if (pageStatus == FORM_STATUS.BANK) {
      this.saveVerify()
      if (this.state.isPenNo === false) {
        if (this.state.userDetail.nameAsOnPan != '' && this.state.userDetail.nameAsOnPan != null) {

          let bankValidation = BankFormValidation(this.state.userDetail)


          aadharExistCheck(
            this.state.allDetail.userId,
            this.state.userDetail.aadharNoState,
            callBack => {
              if (callBack.aadharExistence == false) {
                if (bankValidation.status) {
                  this.setState({
                    bankValidationMsg: bankValidation
                  })
                  fetchBankDetail(
                    this.state.userDetail,
                    this.state.allDetail.userId,
                    callBack => {
                      this.setState({
                        popupState: true,
                        popupStatus: 'Bank Details Changed Successfully'
                      }, () => this.props.getData('yes'))
                      this.removePopup()
                    }
                  )
                } else {
                  this.setState({
                    bankValidationMsg: bankValidation
                  })
                }
              } else if (callBack.aadharExistence == true) {
                this.setState({
                  confirmPopupState: true,
                  confirmationText: 'This Aadhar already exists.Do you want update anyway?'
                })

              }

            }
          )
        } else if (this.state.userDetail.nameAsOnPan == '' && this.state.userDetail.nameAsOnPan == null) {
          this.setState({ popupStatus: true, popupStatus: 'Enter Name On pan First' }, () => this.removePopup())

        }
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Pan No. Already Exist'
        }, () => this.removePopup())

      }
      // }
      // else{
      //     this.setState({
      //         popupState: true,
      //         popupStatus: 'Aadhar already Exists',
      //     })
      //     this.removePopup();
      // }
    } else if (pageStatus == FORM_STATUS.LOAN) {
      let loanValidation = LoanFormValidation(this.state.userDetail)
      if (loanValidation.status) {
        this.setState({
          loanValidationMsg: loanValidation
        })
        fetchLoanDetail(
          this.state.userDetail,
          this.state.allDetail,
          admin,
          callBack => {
            this.setState({
              popupState: true,
              popupStatus: 'Loan Details Changed Successfully'
            }, () => this.props.getData('yes'))
            this.removePopup()
          }
        )
      } else {
        this.setState({
          loanValidationMsg: loanValidation
        })
      }
    } else if (pageStatus == 'mobileNEmail') {
      fetch(
        APIS.UPDATE_MOBILE_AND_EMAIL +
        this.state.allDetail.userId +
        '&emailId=' +
        this.state.userDetail.EmailIDState +
        '&mobileNumber=' +
        this.state.userDetail.MobileNumberState
      )
        .then(res => res.text())
        .then(text => {
          if (text == 'success') {
            this.setState({
              popupState: true,
              popupStatus: 'Mobile and Email Changed Successfully'
            }, () => this.props.getData('yes'))
            this.removePopup()
          } else if (text == 'Already exist') {
            this.setState({
              popupState: true,
              popupStatus: 'Email Id already exist'
            })
            this.removePopup()
          } else {
            this.setState({
              popupState: true,
              popupStatus: 'Data not saved'
            })
            this.removePopup()
          }
        })
    } else if (pageStatus == 'LoanId') {
      if (this.state.userDetail.appLoanId != '') {
        saveLoanId(
          this.state.userDetail.appLoanId,
          this.state.allDetail.userId,
          callBack => {
            if (callBack == 'success') {
              this.setState({
                popupState: true,
                popupStatus: 'Loan Id save successfully',
                disburseLoanId: ''
              }, () => this.props.getData('yes'))
              this.removePopup()
            } else {
              this.setState({
                popupState: true,
                popupStatus: 'Loan Id not save'
              })
              this.removePopup()
            }
          }
        )
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please enter loan id',
          disburseLoanId: ''
        })
        this.removePopup()
      }
    } else if (pageStatus == 'chequeLimit') {
      if (this.state.chequeLimitState != '') {
        fetch(
          APIS.SAVE_CHEQUE_LIMIT +
          this.state.allDetail.userId +
          '&chequeLimit=' +
          this.state.userDetail.chequeLimitState
        )
          .then(res => res.text())
          .then(text => {
            if (text == 'success') {
              this.setState({
                popupState: true,
                popupStatus: 'Cheque Limit save successfully',
                disburseLoanId: ''
              }, () => this.props.getData('yes'))
              this.removePopup()
            } else {
              this.setState({
                popupState: true,
                popupStatus: 'Cheque Limit not save'
              })
              this.removePopup()
            }
          })
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please enter Cheque Limit',
          disburseLoanId: ''
        })
        this.removePopup()
      }
    } else if (pageStatus == 'ambassadorId') {
      if (this.state.userDetail.ambassadorIdState != '') {
        fetch(
          APIS.ADD_AMBASSADOR_ID_IN_APPLICATION +
          this.state.userDetail.ambassadorIdState +
          '&applicationId=' +
          this.state.allDetail.applicationId
        )
          .then(res => res.text())
          .then(text => {
            if (text == 'success') {
              this.setState({
                popupState: true,
                popupStatus: 'Ambassador Id save successfully',
                disburseLoanId: ''
              }, () => this.props.getData('yes'))
              this.removePopup()
            } else {
              this.setState({
                popupState: true,
                popupStatus: 'Ambassador Id not save'
              })
              this.removePopup()
            }
          })
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please enter Cheque Limit',
          disburseLoanId: ''
        })
        this.removePopup()
      }
    }
  }

  componentWillUnmount() {
    clearInterval(interval)
  }
}


export default EditUserDetails
