import React, { useState } from "react";
import CommunicateViaSelection from "./communicationviaselection";
import BycsvMainpage from "./communicateviacsv/mainpage";
import CommunicationLogs from '../communicationLogs'
const MainRunCampaign = ({allStatus,admin}) => {
    const [viewState, setViewState] = useState('selection')
    return (
        <>
            <div className="container-fluid">
                <h3 style={{ marginTop: '20px' }}><strong>Run Campaign</strong></h3>
                <div className="row" style={{ marginTop: '25px' }}>
                    <div onClick={() => setViewState('selection')} className={viewState === 'selection' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                        <div >
                            <div className="text-center" >Communicate Via Selection</div>
                        </div>
                    </div>
                    <div onClick={() => setViewState('csv')} className={viewState === 'csv' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                        <div >
                            <div className="text-center" >Communicate Via CSV</div>
                        </div>
                    </div>
                    <div onClick={() => setViewState('logs')} className={viewState === 'logs' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                        <div>
                            <div className="text-center" >  Communication Logs </div>
                        </div>
                    </div>
                </div>
                {viewState === 'selection' ?
                    <CommunicateViaSelection allStatus={allStatus} admin={admin} />
                    : ""}
                {viewState === 'csv' ?
                    <BycsvMainpage admin={admin} />
                    : ""}
                    {viewState==='logs'?
                    <CommunicationLogs admin={admin}/>
                :""}

            </div>
        </>
    )
}

export default MainRunCampaign