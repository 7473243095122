import React from 'react'

const UpdatePopup = ({ soundHandler, playingState, closeSoundPopup }) => (
    <div>
        <div className="background-blur" onClick={e => closeSoundPopup()}></div>
        <div className="popup-main small-popup container-fluid dbl-border max-width-500px">
            <button className="close" onClick={e => closeSoundPopup()}>&times;</button>
            <div className="row top-bottom-20px text-center">
                <div className="col-sm-2 hidden-xs">SOUND</div>
                <div className="col-sm-10 col-xs-12">
                    <span>OFF&nbsp;</span>
                    <label className="sound-switch">
                        <input type="checkbox" onChange={(e) => soundHandler(e)} checked={playingState} />
                        <span className="slider round"></span>
                    </label>
                    <span>&nbsp;ON</span>
                </div>
            </div>
        </div>
    </div>
)

export default UpdatePopup;