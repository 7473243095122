import React, { Component } from 'react'
import AmbassadorView from '../../../presentationals/Admin/Ambassador/AmbassadorLeads.component'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { allAmbassadors, getAmbassadorDetailApi } from '../AdminActionCreator.component'
import AmbassadorDetail from './AmbassadorMain.container'
import { APIS } from '../../../../utils/api-factory'
import DotLoader from '../../../presentationals/Loader/layerLoader.component'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'

let isDeleteAmb = false

class Ambassador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadarState: false,
            forwordPageState: 1,
            ambassadorDetailState: false,
            ambassadorLeadState: true,
            ambassadorCount: '',
            confirmationPopup: false,
            confirmationText: '',
            ambIdForDelete: '',
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }
    componentWillMount() {
        this.setState({ loadarState: true })
        this.props.allAmbassadors(1,
            (callBack) => {
                this.setState({ loadarState: false })
            })
        fetch(APIS.TOTAL_AMBASSADOR_COUNT)
            .then(res => res.text())
            .then(res => {
                this.setState({ ambassadorCount: res })
            })
    }

    render() {
        const { allAmbassadorData, admin, ambassadorAllData } = this.props
        if (admin.role != null && admin.role == 'superuser') {
            isDeleteAmb = true
        }
        return (
            <div>
                {this.state.ambassadorLeadState == true ?
                    <div>
                        {this.state.loadarState == true ?
                            <DotLoader />
                            : <AmbassadorView
                                allAmbassadorData={allAmbassadorData}
                                admin={admin}
                                ambDetailHandler={this.ambDetailHandler.bind(this)}
                                refreshpage={this.refreshpage.bind(this)}
                                backpage={this.backpage.bind(this)}
                                forwordpage={this.forwordpage.bind(this)}
                                ambassadorCount={this.state.ambassadorCount}
                                isDeleteAmb={isDeleteAmb}
                                deleteAmbHandler={this.deleteAmbHandler.bind(this)}
                                getFilterValue={this.getFilterValue.bind(this)}
                                FilteredData={this.state.FilteredData}
                                getPageValue={this.getPageValue.bind(this)}
                                pageIndexToShow={this.state.pageIndexToShow}
                            />
                        }
                    </div>
                    : ""}
                {this.state.ambassadorDetailState == true ?
                    <AmbassadorDetail
                        detailBackHandler={this.detailBackHandler.bind(this)}
                        ambassadorAllData={ambassadorAllData}
                        admin={admin} />
                    : ""}

                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    deleteAmbHandler(ambId) {
        this.setState({
            ambIdForDelete: ambId,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete this ambassador'
        })
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            fetch(APIS.DELETE_COMPLETE_AMBASSADOR + this.state.ambIdForDelete)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        this.setState({ confirmationPopup: false, loadarState: true })
                        this.props.allAmbassadors(1,
                            (callBack) => {
                                this.setState({ loadarState: false })
                            })
                        fetch(APIS.TOTAL_AMBASSADOR_COUNT)
                            .then(res => res.text())
                            .then(res => {
                                this.setState({ ambassadorCount: res })
                            })
                    }
                })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }


    ambDetailHandler(ambData) {
        this.props.getAmbassadorDetailApi(ambData.ambassadorId,
            (callBack) => {
                this.setState({
                    ambassadorDetailState: true,
                    ambassadorLeadState: false
                })
            })
    }
    detailBackHandler() {
        this.props.allAmbassadors(1,
            (callBack) => {

            })
        this.setState({
            ambassadorDetailState: false,
            ambassadorLeadState: true
        })
    }
    refreshpage() {
        this.setState({
            loadarState: true
        })
        this.props.allAmbassadors(1,
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            });
    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                loadarState: true
            })
            this.props.allAmbassadors(this.state.forwordPageState - 1,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                });
        }
    }
    forwordpage() {
        this.setState({
            loadarState: true,
            forwordPageState: this.state.forwordPageState + 1
        })
        this.props.allAmbassadors(this.state.forwordPageState + 1,
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            });
    }
}

const mapStateToProps = ({ allAmbassadorData, ambassadorAllData }) => {
    return {
        allAmbassadorData: allAmbassadorData,
        ambassadorAllData: ambassadorAllData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        allAmbassadors,
        getAmbassadorDetailApi
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Ambassador);