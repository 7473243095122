import React from 'react'

const SavePopup = ({ otpHandler, OtpSubmit }) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">

            <div className="row from-group" style={{ marginTop: '30px' }}>
                <div className="col-xs-12 col-sm-2">
                </div>
                <div className="col-xs-12 col-sm-8">
                    <label style={{ color: '#000' }}>Enter OTP sent to Administrator</label>
                    <input className='form-control' onChange={(e) => otpHandler(e)} placeholder='Enter OTP' />


                </div>
                <div className="col-xs-12 col-sm-2">
                </div>
            </div>
            <div className="row from-group text-center">
                <div className="col-xs-12 col-sm-12">
                    <button className='profile-save-btn' onClick={() => OtpSubmit()}>Submit</button>

                </div>

            </div>
        </div>
    </div>

)

export default SavePopup;