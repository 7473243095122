import React, { Component } from 'react'
import WhatsappUi from './whatsapp.component'
import { sendWhatsappMsgesApi, getWhatsappMsgApi, whatsappFileApi } from '../../AdminActionCreator.component'
import { MSG_STRING, MAIL_STRING, emandateEsignMsg } from '../../../../../utils/Constant'
import { WEB_URL } from '../../../../../utils/api-factory'
import $ from 'jquery'
var sortJsonArray = require('sort-json-array');

let interval;



class Whatsapp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDetail: this.props.allDetail,
            allWhatsappMsg: '',
            errorMsg: '',
            loaderState: false,
            tickStatus: 0,
            writeWhatsappMsg: '',
            selectedTemplate:''
        }
    }

    componentWillMount() {
        if (this.state.allDetail.mobileNumber != null && this.state.allDetail.mobileNumber != '') {
            getWhatsappMsgApi(this.state.allDetail.mobileNumber, 'communication', '',
                (callBack) => {
                    this.setState({ allWhatsappMsg: callBack, tickStatus: callBack.status })
                })
        } else {
            this.setState({ errorMsg: 'Mobile number is blank' })
        }
    }

    componentDidMount() {
        if (this.state.allDetail.mobileNumber != null && this.state.allDetail.mobileNumber != '') {
            interval = setInterval(function () {
                getWhatsappMsgApi(this.state.allDetail.mobileNumber, 'communication', '',
                    (callBack) => {
                        if (this.state.allWhatsappMsg != null && this.state.allWhatsappMsg.messages.length != callBack.messages.length) {
                            this.setState({ allWhatsappMsg: callBack, tickStatus: callBack.status })
                            $('#whatsappMsg').animate({
                                scrollTop: $('#whatsappMsg').get(0).scrollHeight
                            }, 100);
                        }
                    })
            }.bind(this), 30000) // 30 sec
        } else {
            this.setState({ errorMsg: 'Mobile number is blank' })
        }

        $("#whatsappMsgWrite").keydown(function (e) {
            if (e.keyCode == 13 && !e.shiftKey) {
                e.preventDefault();
            }
        });
    }
    msgTemplateClick = (template) => {
        console.log(template)

    };
    render() {
        const { userDetail, approveAmount, userId } = this.props
        return (
            <WhatsappUi
            selectedTemplate={this.state.selectedTemplate}
                userDetail={userDetail}
                approveAmount={approveAmount}
                userId={userId}
                whatsappmsg={this.whatsappmsg.bind(this)}
                writeWhatsappMsg={this.state.writeWhatsappMsg}
                whatsappmsgHandler={this.whatsappmsgHandler.bind(this)}
                errorMsg={this.state.errorMsg}
                allWhatsappMsg={this.state.allWhatsappMsg != '' && this.state.allWhatsappMsg.messages != null ? sortJsonArray(this.state.allWhatsappMsg.messages, 'date', 'asc') : ""}
                msgTempleteClick={this.msgTempleteClick.bind(this)}
                loaderState={this.state.loaderState}
                tickStatus={this.state.tickStatus}
                myCallback={this.myCallback.bind(this)}
                whatsappMsgChange={this.whatsappMsgChange.bind(this)}
            />
        )
    }

    myCallback(abc) {

    }

    whatsappMsgChange(e) {
        this.setState({ writeWhatsappMsg: e.target.value })
    }

    msgTempleteClick(msgType) {
        this.setState({selectedTemplate:msgType})
        const { allDetail, userDetail, approveAmount } = this.props;
        let userName = allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""

        let eMandateLinkTechProcess = allDetail != null ? allDetail.loanId != null ? WEB_URL + "eNach/" + allDetail.loanId : "" : ""
        let eMandateLink = allDetail != null ? allDetail.loanId != null ? WEB_URL + "enach/" + allDetail.loanId : "" : ""
        let eSignLink = allDetail != null ? allDetail.loanId != null ? WEB_URL + "esign/" + allDetail.loanId : "" : ""

        let eMandateLinkTechProcessGift = allDetail != null ? userDetail.giftCardId != null ? WEB_URL + "eNach/" + userDetail.giftCardId : "" : ""
        let eMandateLinkGift = allDetail != null ? userDetail.giftCardId != null ? WEB_URL + "enach/" + userDetail.giftCardId : "" : ""
        let eSignLinkGift = allDetail != null ? userDetail.giftCardId != null ? WEB_URL + "esign/" + userDetail.giftCardId : "" : ""
        let referencelink = WEB_URL + "userReference/" + allDetail.userId
        let eMandateLinkBillDesk = allDetail != null ? allDetail.loanId != null ? "https://payment.borrowbuddy.co.in/emandate/" + allDetail.loanId : "" : ""


        if (msgType == 'ExecutiveContact') {
            this.setState({ writeWhatsappMsg: MSG_STRING.EXECUTIVE_CONTACT })
        } else if (msgType == 'googleRating') {
            this.setState({ writeWhatsappMsg: MSG_STRING.GOOGL_PLAY_RATINGS })
        } else if (msgType == 'DocsUpload') {
            this.setState({ writeWhatsappMsg: MSG_STRING.DOC_MSG })
        } else if (msgType == 'eMandateRepeate') {
            if (userDetail.typeOfProduct == 'Loan') {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.nachRejected + '\n\n' + emandateEsignMsg.continueNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.cooperation + '\n\n' + emandateEsignMsg.clickNach + eMandateLink + '\n\n' + emandateEsignMsg.footer })
            } else {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.nachRejected + '\n\n' + emandateEsignMsg.continueNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.cooperation + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkGift + '\n\n' + emandateEsignMsg.footer })

            }
        } else if (msgType == 'eMandateRepeateTechp') {
            if (userDetail.typeOfProduct == 'Loan') {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.nachRejected + '\n\n' + emandateEsignMsg.continueNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.cooperation + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkTechProcess + '\n\n' + emandateEsignMsg.footer })
            } else
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.nachRejected + '\n\n' + emandateEsignMsg.continueNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.cooperation + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkTechProcessGift + '\n\n' + emandateEsignMsg.footer })
        } else if (msgType == 'eMandate') {
            if (userDetail.typeOfProduct == 'Loan') {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.normalNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLink + '\n\n' + emandateEsignMsg.footer })
            } else {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.normalNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkGift + '\n\n' + emandateEsignMsg.footer })

            }
        } else if (msgType == 'eMandatetechP') {
            if (userDetail.typeOfProduct == 'Loan') {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.normalNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkTechProcess + '\n\n' + emandateEsignMsg.footer })
            } else {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.normalNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkTechProcessGift + '\n\n' + emandateEsignMsg.footer })

            }
        } else if (msgType == 'eSign') {
            if (userDetail.typeOfProduct == 'Loan') {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.paperlessEsign + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickSign + eSignLink + '\n\n' + emandateEsignMsg.footer })
            } else {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.paperlessEsign + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickSign + eSignLinkGift + '\n\n' + emandateEsignMsg.footer })

            }
        } else if (msgType == 'eSignEmandate') {
            if (userDetail.typeOfProduct == 'Loan') {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.esignEmandate + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLink + '\n' + emandateEsignMsg.clickSign + eSignLink + '\n\n' + emandateEsignMsg.footer })
            } else {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.esignEmandate + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkGift + '\n' + emandateEsignMsg.clickSign + eSignLinkGift + '\n\n' + emandateEsignMsg.footer })

            }
        } else if (msgType == 'techprocessEmandate') {
            if (userDetail.typeOfProduct == 'Loan') {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.esignEmandate + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkTechProcess + '\n' + emandateEsignMsg.clickSign + eSignLink + '\n\n' + emandateEsignMsg.footer })
            } else {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.esignEmandate + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkTechProcessGift + '\n' + emandateEsignMsg.clickSign + eSignLinkGift + '\n\n' + emandateEsignMsg.footer })

            }
        } else if (msgType == 'razorpay') {
            this.setState({ writeWhatsappMsg: emandateEsignMsg.razorpay })
        }
        else if (msgType == 'reference') {

            this.setState({
                writeWhatsappMsg: 'Dear ' + userName
                    + '\n\n' + 'Your loan is approved for INR ' + approveAmount + '.'
                    + ' Kindly ' + ' Click On this Link ' + referencelink + ' to share 3 contact numbers for reference purposes to process your disbursement. ' + '\n\n' + emandateEsignMsg.footer
            })
        } else if (msgType == 'billDeskNach') {
            if (userDetail.typeOfProduct == 'Loan') {
                this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.normalNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkBillDesk + '\n\n' + emandateEsignMsg.footer })
            }
            //  else {
            //     this.setState({ writeWhatsappMsg: 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.normalNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.steps + '\n\n' + emandateEsignMsg.clickNach + eMandateLinkBillDesk + '\n\n' + emandateEsignMsg.footer })

            // }
        }
    }

    whatsappmsg(e, type) {
        if (type == 'text') {
            this.setState({ errorMsg: '' })
        } else if (type == 'file') {
            this.setState({ loaderState: true })
            let val = e.target.value.split(/[\\\/]/)[2];
            let File1 = e.target.files[0];
            let docType = val.split(".");
            let fileType = docType[docType.length - 1];
            whatsappFileApi(File1, this.state.allDetail, val, fileType, this.props.admin, 'communication',
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({ loaderState: false })
                        getWhatsappMsgApi(this.state.allDetail.mobileNumber, 'communication', '',
                            (callBack) => {
                                this.setState({ allWhatsappMsg: callBack, tickStatus: callBack.status })
                                $('#whatsappMsg').animate({
                                    scrollTop: $('#whatsappMsg').get(0).scrollHeight
                                }, 100);
                            })
                    }
                })

        }
    }

    sendWhatsappMsges() {
        const { admin } = this.props
        sendWhatsappMsgesApi(this.state.allDetail, this.state.writeWhatsappMsg, admin, 'communication',
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        errorMsg: '',
                        writeWhatsappMsg: ''
                    })
                    getWhatsappMsgApi(this.state.allDetail.mobileNumber, 'communication', '',
                        (callBack) => {
                            this.setState({ allWhatsappMsg: callBack, tickStatus: callBack.status })
                            $('#whatsappMsg').animate({
                                scrollTop: $('#whatsappMsg').get(0).scrollHeight
                            }, 100);
                        })
                } else {
                    this.setState({ errorMsg: 'Something wrong! please try again' })
                }
            })
    }

    whatsappmsgHandler(e, type) {
        if (this.state.writeWhatsappMsg.length > 0) {
            if (type == 'enter') {
                if (e.keyCode == 13) {
                    this.sendWhatsappMsges()
                }
            } else if (type == 'click') {
                this.sendWhatsappMsges()
            }
        } else {
            this.setState({ errorMsg: '' })
        }
    }

    componentWillUnmount() {
        clearInterval(interval);
    }
}

export default Whatsapp;