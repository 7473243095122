import React from 'react'

const MerchantnAdd = ({ merchantData, ulMerchantData, postDataHandler, listingOfCategory,
    getDataHandler, MerchantValidationMsg, deleteMerchant }) => (
        <div className="container-fluid">
            <div className="row form-group">
                <h3 className="text-center">Add Merchant Details</h3>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Outlet Name</label>
                    <input type="text" value={merchantData.merchantName} className="form-control" placeholder="Enter Outlet Name" id="merchantName" onChange={e => getDataHandler(e, "merchantName")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "merchantName" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Contact Person Name</label>
                    <input type="text" value={merchantData.contactPersonName} className="form-control" placeholder="Enter Contact Person Name" id="contactPersonName" onChange={e => getDataHandler(e, "contactPersonName")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "contactPersonName" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Contact Person Mobile No.</label>
                    <input type="number" value={merchantData.merchantMobile} className="form-control" placeholder="Enter Contact Person Number" id="merchantMobile" onChange={e => getDataHandler(e, "merchantMobile")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "merchantMobile" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Contact Person Email Id</label>
                    <input type="email" value={merchantData.merchantEmail} className="form-control" placeholder="Enter Contact Person Email Id" id="merchantEmail" onChange={e => getDataHandler(e, "merchantEmail")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "merchantEmail" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Company Website</label>
                    <input type="text" value={merchantData.companyWebsite} className="form-control" placeholder="Enter Company Website" id="companyWebsite" onChange={e => getDataHandler(e, "companyWebsite")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "companyWebsite" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Company Registered Address</label>
                    <input type="text" value={merchantData.companyAddress} className="form-control" placeholder="Enter Company Registered Address" id="companyAddress" onChange={e => getDataHandler(e, "companyAddress")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "companyAddress" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Priority</label>
                    <select className="form-control" value={merchantData.discountPriority} id="discountPriority" onChange={e => getDataHandler(e, "discountPriority")}>
                        <option value="">Select Priority</option>
                        <option value="1">High</option>
                        <option value="2">Low</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "discountStatus" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Status</label>
                    <select className="form-control" value={merchantData.SelectMerchentStatus ? 'Active' : 'Inactive'} id="merchantStatus" onChange={e => getDataHandler(e, "SelectMerchentStatus")}>
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "SelectMerchentStatus" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 text-center">
                    <button className="profile-save-btn" id="merchantAdd" onClick={e => postDataHandler()}>
                        <span className="fa fa-plus"></span>&nbsp;&nbsp;ADD
                            </button>
                </div>
            </div>

            <div className="row overflow-auto">
                <div className="col-xs-12">
                    {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                        <table className="text-center loan-detail-table">
                            <thead>
                                <tr className="background-blue text-white">
                                    <th>ID</th>
                                    <th>Outlet Name</th>
                                    <th>Contact Person</th>
                                    <th>Contact No.</th>
                                    <th>Email Id</th>
                                    <th>password</th>
                                    <th>Website</th>
                                    <th>Address</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                    <th>Priority</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            {listingOfCategory.map((listing,i) => {
                                return (
                                    <tbody key={i}>
                                        {listing.Merchant != null && listing.Merchant.length > 0 ? listing.Merchant.map((data, i) => {
                                            return (
                                                <tr className="pointer text-black" onClick={e => ulMerchantData(e, data)} key={i}>
                                                    <td>{data.merchantid}</td>
                                                    <td>{data.merchantname}</td>
                                                    <td>{data.contactperson}</td>
                                                    <td>{data.merchanthphone}</td>
                                                    <td>{data.contactpersonemail}</td>
                                                    <td>{data.password}</td>
                                                    <td>{data.companywebsite}</td>
                                                    <td>{data.companyregisteredaddress}</td>
                                                    <td>{data.createdby}</td>
                                                    <td>{data.status == true ? 'Active' : 'Inactive'}</td>
                                                    <td>{data.priority}</td>
                                                    <td><span className="fa fa-trash" onClick={e => deleteMerchant(data)} /></td>
                                                </tr>
                                            )
                                        }) : ""}
                                    </tbody>
                                )
                            })}
                        </table>
                        : ""}
                </div>
            </div>
        </div>
    )

export default MerchantnAdd;