import React from 'react'

const Ui = ({ nbfcData }) => (
    <div className='conatiner'>
        <div className='row'>

            <div className='col-sm-12 col-xs-12'>
                <div className="profile-details-box-new" style={{ minHeight: "200px", marginTop: '50px' }}>
                    <div className="new-basic-head" >NBFC Details</div>
                    <div className='row'>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>Name :&nbsp;</b>{nbfcData != null ? nbfcData.name != null ? nbfcData.name : "" : ""}</p>
                            </div>
                        </div>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>Maximum Month :&nbsp;</b>{nbfcData != null ? nbfcData.maxMonth != null ? nbfcData.maxMonth : "" : ""}</p>
                            </div>
                        </div>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>Maximum Days :&nbsp;</b>{nbfcData != null ? nbfcData.maxDays != null ? nbfcData.maxDays : "" : ""}</p>
                            </div>
                        </div>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>Interest Rate  :&nbsp;</b>{nbfcData != null ? nbfcData.interestRate != null ? nbfcData.interestRate : "" : ""}</p>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>Contact Person Name :&nbsp;</b>{nbfcData != null ? nbfcData.contactPersonName != null ? nbfcData.contactPersonName : "" : ""}</p>
                            </div>
                        </div>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>Contact Person Number  :&nbsp;</b>{nbfcData != null ? nbfcData.contactPersonNumber != null ? nbfcData.contactPersonNumber : "" : ""}</p>
                            </div>
                        </div>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>City  :&nbsp;</b>{nbfcData != null ? nbfcData.city != null ? nbfcData.city : "" : ""}</p>
                            </div>
                        </div>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>Pincode :&nbsp;</b>{nbfcData != null ? nbfcData.pinCode != null ? nbfcData.pinCode : "" : ""}</p>
                            </div>
                        </div>


                    </div>
                    <div className='row'>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>State :&nbsp;</b>{nbfcData != null ? nbfcData.state != null ? nbfcData.state : "" : ""}</p>
                            </div>
                        </div>
                        <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                            <div className="">
                                <p><b>Address :&nbsp;</b>{nbfcData != null ? nbfcData.address != null ? nbfcData.address : "" : ""}</p>
                            </div>
                        </div>
                        {nbfcData.logoUrl !== null && nbfcData.logoUrl !== undefined ?
                            <div className='col-sm-3 col-xs-12' style={{marginTop:'20px'}}>
                                <div className="">
                                    <p><b>Logo :</b> <img style={{width:'100%'}} src={nbfcData.logoUrl}/></p>
                                </div>
                            </div>
                            : ""}
                    </div>
                </div>
            </div>
        </div>

    </div>
)

export default Ui