import React, { Component } from 'react'
import { initiateBulkPaymentByRazorpay } from '../AdminActionCreator.component'
import PaymentUi from '../../../presentationals/Admin/Recovery/initiateBulkPayment.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import ConfirmPopup from '../../../presentationals/Admin/Recovery/confirmpopupPayment'
import Loader from '../../../presentationals/Loader/graphloader.component'
class BulkPayment extends Component {
  constructor (props) {
    super(props)
    this.state = {
      csvFile: '',
      fileName: '',
      popupState: false,
      popupStatus:'',
      loaderState:false,
      warningState:false,
      csvDataArray:[]
    }
  }
  removePopup() {
    setTimeout(function () {
        this.setState({ popupState: false });
    }.bind(this), 5000)
}
  csvHandler (e) {
    this.setState({
      csvFile: e.target.files[0],
      fileName: e.target.files[0].name
    })
  }
  handleForce(data) {
    this.setState({
        csvDataArray: csvReader(data)
    })
};
  submit () {
    if (this.state.csvFile != null && this.state.csvFile != '') {

    this.setState({warningState:true})
    }else{
      this.setState({ popupState: true,popupStatus:'Please Select Csv' })
      this.removePopup()
    }
    // const { admin } = this.props
    // if (this.state.csvFile != null && this.state.csvFile != '') {
    //     this.setState({loaderState:true})
    //   initiateBulkPaymentByRazorpay(this.state.csvFile, admin, callBack => {
    //     console.log(callBack)
    //     if(callBack == 'success'){
    //         this.setState({ popupState: true,popupStatus:'Payment Successfully Initiated',loaderState:false })
    //         this.removePopup()
    //     }else{
    //         this.setState({ popupState: true,popupStatus:'Please try again later',loaderState:false })
    //         this.removePopup()
    //     }
    //   })
    // } else {
    //     this.setState({ popupState: true,popupStatus:'Please Select Csv' })
    //     this.removePopup()
    // }
  }
  confirmInitiate(type){
    if(type == 'yes'){
      const { admin } = this.props
      if (this.state.csvFile != null && this.state.csvFile != '') {
          this.setState({loaderState:true})
        initiateBulkPaymentByRazorpay(this.state.csvFile, admin, callBack => {
          if(callBack == 'success'){
              this.setState({warningState:false, popupState: true,popupStatus:'Payment Successfully Initiated',loaderState:false })
              this.removePopup()
          }else{
              this.setState({warningState:false, popupState: true,popupStatus:'Please try again later',loaderState:false })
              this.removePopup()
          }
        })
      } else {
          this.setState({ popupState: true,popupStatus:'Please Select Csv' })
          this.removePopup()
      }
    }else if(type =='No'){
      this.setState({warningState:false})
    }
  }
  closePopup () {
    this.setState({ popupState: false })
  }
  render () {
    return (
      <div>
          {this.state.loaderState?
          <Loader/>
          :""}
          {this.state.warningState?
          <ConfirmPopup
          confirmInitiate={this.confirmInitiate.bind(this)}
          />
        :""}
        {this.state.popupState == true ? (
          <Popup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
        ) : (
          ''
        )}
        <PaymentUi
          fileName={this.state.fileName}
          csvHandler={this.csvHandler.bind(this)}
          submit={this.submit.bind(this)}
        />
      </div>
    )
  }
}

export default BulkPayment
