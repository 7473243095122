import React from 'react'
import { APIS } from '../../../../utils/api-factory';
import Loader from '../../../presentationals/Loader/Loader.component'
import ProductConfig from './addProductConfig';
import Popup from '../../../presentationals/Popup/Popup.component';
import Charges from './Charges.conatiner'
import Esign from './EsignConfig.container'
import Enach from './EnachConfig.container'
class ConfigNbfc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainPopup: true,
            allNbfcList: '',
            loaderState: false,
            viewState: '',
            nbfcId: '',
            popupState: false,
            popupStatus: '',
        }
    }
    cancelLoanConfirm(data) {
        this.setState({ selectType: data, mainPopup: false })
    }
    getNbfcList(data) {
        this.setState({ loaderState: true })
        fetch(APIS.GET_LIST_OF_NBFC + data)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allNbfcList: res,
                    loaderState: false
                })
            })
    }
    componentDidMount() {
        this.getNbfcList('')
    }
    tabHandler(data) {
        if (this.state.nbfcId !== '') {
            this.setState({ viewState: data })
        } else {
            this.setState({ popupStatus: 'Select NBFC First !', popupState: true }, () => this.removePopup())
        }
    }
    nbfc(e) {
        this.setState({ nbfcId: e.target.value })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <div className='container-fluid' >
                    <h3 className='text-center'><b>Configuration</b></h3>
                    <div className='' style={{ width: '200px', marginRight: 'auto', marginLeft: 'auto', marginTop: '20px' }}>
                        <select className='form-control' onChange={(e) => this.nbfc(e)} >
                            <option value=''>Select Nbfc</option>
                            {this.state.allNbfcList !== '' && this.state.allNbfcList !== undefined ? this.state.allNbfcList.map((data, i) => {
                                return (
                                    <option value={data.nbfcId}>{data.name}</option>
                                )
                            })

                                : ""}
                        </select>
                    </div>
                    <div className='row'>
                        <div className='col-sm-1 col-xs-12'></div>
                        <div className='col-sm-2 col-xs-12'>
                            <div onClick={(e) => this.tabHandler('product')} className={this.state.viewState === 'product' ? 'card card-nbfc-active text-center ' : 'card card-nbfc text-center'} style={{ marginTop: '20px', width: '80%', height: '100px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '40px' }}>
                                <b > Product </b>
                            </div>
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <div onClick={(e) => this.tabHandler('enach')} className={this.state.viewState === 'enach' ? 'card card-nbfc-active text-center ' : 'card card-nbfc text-center'} style={{ marginTop: '20px', width: '80%', height: '100px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '40px' }}>
                                <b > E-NACH </b>
                            </div>
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <div onClick={(e) => this.tabHandler('esign')} className={this.state.viewState === 'esign' ? 'card card-nbfc-active text-center ' : 'card card-nbfc text-center'} style={{ marginTop: '20px', width: '80%', height: '100px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '40px' }}>
                                <b > E-SIGN </b>
                            </div>
                        </div>

                        <div className='col-sm-2 col-xs-12'>
                            <div onClick={(e) => this.tabHandler('charges')} className={this.state.viewState === 'charges' ? 'card card-nbfc-active text-center ' : 'card card-nbfc text-center'} style={{ marginTop: '20px', width: '80%', height: '100px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '40px' }}>
                                <b style={{ marginTop: '20px' }}> Charges </b>
                            </div>
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <div onClick={(e) => this.tabHandler('agreement')} className={this.state.viewState === 'agreement' ? 'card card-nbfc-active text-center ' : 'card card-nbfc text-center'} style={{ marginTop: '20px', width: '80%', height: '100px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '40px' }}>
                                <b > Agreement </b>
                            </div>
                        </div>
                        <div className='col-sm-1 col-xs-12'></div>

                    </div>
                    {this.state.viewState === 'product' && this.state.nbfcId !== '' ?
                        <ProductConfig nbfcId={this.state.nbfcId} />
                        : ""}
                    {this.state.viewState === 'charges' && this.state.nbfcId !== '' ?
                        <Charges nbfcId={this.state.nbfcId} />
                        : ""}
                    {this.state.viewState === 'esign' && this.state.nbfcId !== '' ?
                        <Esign nbfcId={this.state.nbfcId} />
                        : ""}
                    {this.state.viewState === 'enach' && this.state.nbfcId !== '' ?
                        <Enach nbfcId={this.state.nbfcId} />
                        : ""}
                    {this.state.popupState == true ?
                        <Popup
                            closePopup={this.closePopup.bind(this)}
                            popupStatus={this.state.popupStatus}
                        />
                        : ""}
                </div>
            </React.Fragment>
        )
    }
}
export default ConfigNbfc