import React from 'react';
import DatePicker from 'react-datepicker';
import DotLoader from '../../Loader/layerLoader.component'
import { ROLE, STATE_COLOR } from '../../../../utils/Constant'

const PhocketCustomerDetails = ({ userDetailHandler, userViewHandler, dataFilter, admin,
    allUserDetail, statusHandler, newDataBackground, todaysApplicationNo, isDeleteUser,
    allStatus, loadarState, markAsImportant, paginationHandler, commentColumn,
    columnHide, deleteUserHandler, userAssignPopup, userCommentChange, searchByDate,
    showRepeateApplications, followUpStatus, searchByAppNo, searchByemail, searchBymobile,
    searchByDateShow, nachCheckbox, nashHandler, markImportant, userCommentHandler,
    phocketTablePagination, tableMinPagination, tableMaxPagination }) => (
    <div className="container-fluid">
        {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <h3 className="text-center blue-text margin-0">
                            {todaysApplicationNo != null ?
                                <a className="pointer" onClick={e => showRepeateApplications()}>Repeat Applications- {todaysApplicationNo.repeatUserCount}</a>
                                : ""}
                        </h3>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <h3 className="text-center blue-text margin-0">
                            {todaysApplicationNo != null ?
                                <span>New Applications- {todaysApplicationNo.newUserCount}</span>
                                : ""}
                        </h3>
                    </div>
                </div>
                : null} */}
        <div className="row">
            <ul className="loan-lead-search">
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> By Status</label>
                    <select className="form-control" value={followUpStatus} onChange={(e) => statusHandler(e)}>
                        <optgroup className="display-none">
                            <option value="">Select Status</option>
                        </optgroup>
                        <optgroup label="Status">
                            {allStatus != null && allStatus != undefined && allStatus != '' ?
                                allStatus.map((status, i) => {
                                    return (
                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                    );
                                }) : ""}
                        </optgroup>
                    </select>
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Application No.</label>
                    <input type="number" className="form-control" value={searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Email ID</label>
                    <input type="email" className="form-control" value={searchByemail} onChange={e => dataFilter(e, "email")} />
                </li>

                <li>
                    <label className="font-12px margin-0" ><span className="fa fa-search" /> Mobile No.</label>
                    <input type="number" value={searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Date</label>
                    <DatePicker
                        value={searchByDate}
                        selected={searchByDateShow}
                        onChange={e => dataFilter(e, "date")}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                    />
                </li>
            </ul>
        </div>
        {/* : null} */}
        <div className="row">
            <div className="col-xs-6 text-left">
                <ul className="state-color-list">
                    <li><span className="rajasthan-color state-color-box" />Rajasthan</li>
                    <li><span className="west-bengal-color state-color-box" />West Bengal</li>
                    <li><span className="Maharashtra-color state-color-box" />Maharashtra</li>
                    <li><span className="loan-adda-color state-color-box" />Loan Adda</li>
                </ul>
            </div>
            <div className="col-xs-6 text-right">
                <button className="admin-pagination-btn" id="forword" onClick={e => paginationHandler('refresh')}><span className="fa fa-refresh fa-spin" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button>
                {/* <p>1-100</p> */}
            </div>
        </div>
        <div className="row text-center">
            <div className="col-xs-12 no-padding">
                {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                    <div>
                        <div className="overflow-x-auto">
                            <table className="phocket-table">
                                <thead>
                                    <tr>
                                        {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ? <th>Assign To</th> : null : ""}
                                        <th>Details</th>
                                        {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?
                                            <th>View</th> : "" : ""}
                                        {markImportant ? <th>Important</th> : null}
                                        {nachCheckbox ? <th>Nach</th> : null}
                                        {commentColumn ? <th>Comment</th> : null}
                                        <th>Application Id</th>
                                        <th>Loan Id</th>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                        <th>Apply Date</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Installment</th>
                                        <th>Tenure</th>
                                        <th>Reason</th>
                                        <th>State</th>
                                        <th>IVR Status</th>
                                        <th>Device</th>
                                        {isDeleteUser ? <th>Delete User</th> : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                        return (
                                            <tr key={i}
                                            // style={{
                                            //     backgroundColor: newDataBackground != undefined ? data.loanId == newDataBackground.id ? newDataBackground.color : "" : "",
                                            //     background: data.state == 'Rajasthan' ? STATE_COLOR.RAJASTHAN
                                            //         : data.state == 'West Bengal' ? STATE_COLOR.WEST_BENGAL
                                            //             : data.state == 'Maharashtra' ? STATE_COLOR.MAHARASHTRA
                                            //                 : data.state == 'Karnataka' ? STATE_COLOR.KARNATAKA
                                            //                     : "",
                                            // }}
                                            >
                                                {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ? <td>
                                                    <a className="pointer" onClick={e => userAssignPopup(data.loanId, data.assignUser)}>{data.assignUser != null ? data.assignUser : 'UnAssigned'}</a>
                                                </td> : null : ""}
                                                <td>
                                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                                        <button className="background-none-btn" onClick={e => userDetailHandler(data)}><span className="fa fa-info" /></button>
                                                        : "" : ""}
                                                </td>
                                                {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?

                                                    <td>
                                                        <button className="background-none-btn" onClick={e => userViewHandler(data)}><span className="fa fa-eye" /></button>
                                                    </td>
                                                    : "" : ""}
                                                {markImportant ? <td>
                                                    {data.important == true ?
                                                        <span className="fa fa-star orange-text" onClick={e => markAsImportant(data, false)} />
                                                        :
                                                        <span className="fa fa-star-o" onClick={e => markAsImportant(data, true)} />
                                                    }
                                                </td> : null}
                                                {nachCheckbox ? <td>
                                                    <input type="checkbox" onChange={(e) => nashHandler(e, data)} checked={data.nachStatus} />
                                                </td> : null}
                                                {commentColumn ? <td>
                                                    <div className="display-flex">
                                                        <input type="text" className="lead-select min-width-100px" onChange={e => userCommentChange(e)} />
                                                        <span className="fa fa-save loan-id-save-btn" onClick={e => userCommentHandler(data)} />
                                                    </div>
                                                </td> : null}
                                                <td>{data.applicationId}</td>
                                                <td>{data.applicationLoanId}</td>
                                                <td style={{whiteSpace:'break-spaces'}}>{data.userName}</td>
                                                <td>{data.mobileNumber}</td>
                                                <td>{data.loanApplyDate}</td>
                                                <td className="light-dark-background">{data.applicationStatus}</td>
                                                <td>{data.loanAmount}</td>
                                                <td>{data.loanEmi}</td>
                                                <td>{data.loanDuration}</td>
                                                <td className="white-space-normal">{data.loanReason}</td>
                                                <td>{data.state}</td>
                                                <td>{data.ivrStatus == 1 ? 'Confirmed' : data.ivrStatus == 2 ? 'Need Call' : null}</td>
                                                <td>{data.loginDevice}</td>
                                                {isDeleteUser ? <td><button className="background-none-btn" onClick={e => deleteUserHandler(data.userId)}><span className="fa fa-trash" /></button></td> : null}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block grey  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                                <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                                <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block grey" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                            </div>
                        </div>
                    </div>
                    : ""}
            </div>
            {loadarState == true ?
                <DotLoader />
                : ""}
        </div>
    </div>
)

export default PhocketCustomerDetails;
