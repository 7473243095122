import React, { Component } from 'react'
import CompleteLeadsUi from '../../../presentationals/Admin/LeadManagement/RepeateLeads.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    getManagementLeadApiOptimized, getUserDetailByLoanIdOptimisedApi, saveImportantLoans,
    getlistByApplication, getlistByEmail, getistByNumber, pushNotification, getCommentsApi, getLeadManagmentCountsApi
} from '../AdminActionCreator.component'
import MainDetailsPopup from '../CustomerPages/MainDetailPopup.container'
import UserDetail from '../CustomerPages/UserDetailMainNew.container';
import { STRING } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import { APIS } from '../../../../utils/api-factory'
import localStorageUtil from '../../../../utils/localStorageUtil';
import DotLoader from '../../../presentationals/Loader/layerLoader.component'
import moment from 'moment';
import Popup from '../../../presentationals/Popup/Popup.component';
let paginationNumber = 10


let leadType = "repeat"
let empId = localStorageUtil.getFromLocalStorage("AdminemailId")
let interval;
class CompleteLeads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FilteredData: [],
            pageIndexToShow: 0,
            allUserDetail: [],
            userState: true,
            viewState: false,
            allDetail: [],
            userDetail: [],
            loadarState: false,
            forwordPageState: 1,
            actionButtonState: true,
            followUpStatus: STRING.SELECT_ALL,
            dataFilterState: {
                searchByAppNo: '',
                searchByemail: '',
                searchBymobile: '',
                searchByDate: "",
                searchByDateShow: ''
            },
            todaysApplicationNo: '',
            markCompleteBtn: true,
            countforstatus: '',
            commentType: '',
            popupStatus: '',
            commentStateSelect: '',
            popupState: false,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            forwordPageState: 1,
            startDate: moment(new Date()).format("DD-MM-YYYY"),
            leadComment: '',
            dataChange: '',
            bucketId: '1',
            commentsData: [],
            pageNumber: 1,
            endDate: moment(new Date()).format("DD-MM-YYYY"),
            countType: 'repeat',
            leadManagementCounts: {},
            bucketStage: 'default',




        }
    }
    getCount() {
        const { admin } = this.props
        getLeadManagmentCountsApi(admin.emailId, this.state.startDate, this.state.countType, this.state.endDate, this.state.bucketStage, callBack => {
            console.log(callBack)
            this.setState({ leadManagementCounts: callBack })
        })
    }

    bucketHandler(e) {
        this.setState({ bucketId: e.target.value, loadarState: true })
        this.props.getManagementLeadApiOptimized(leadType, this.state.followUpStatus, this.state.forwordPageState, empId, this.state.dataFilterState.searchByDate, this.state.commentType, '', e.target.value,
            (callBack) => {
                this.setState({ loadarState: false }, () => this.getComments())
            })
    }
    getComments() {
        empId = localStorageUtil.getFromLocalStorage('AdminemailId')
        getCommentsApi(leadType, this.state.followUpStatus, this.state.forwordPageState, empId, this.state.dataFilterState.searchByDate, this.state.commentType, '', this.state.bucketStage, callBack => {
            this.setState({ commentsData: callBack })

        })
    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    userCommentSelect(e) {
        this.setState({ commentStateSelect: e.target.value })

    }


    userCommentHandler(data) {
        const { admin } = this.props
        pushNotification(data, this.state.commentStateSelect, '', admin.emailId, "comment", '', '', '', '', '',
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Comment Saved Successfully',
                        commentStateSelect: ''
                    })
                    this.removePopup();
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                        commentStateSelect: ''
                    })
                    this.removePopup();
                }
            })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ popupState: false });
    }
    getCountForStatus() {
        const { admin } = this.props

        fetch(APIS.GET_DASHBOARD_COUNT_FOR_STATUS + admin.emailId + '&startDate=' + this.state.startDate + '&endDate=' + this.state.startDate + '&status=' + admin.department)
            .then(res => res.json())
            .then(res => {
                this.setState({ countforstatus: res })
            })
    }
    componentWillMount() {
        localStorage.setItem('showComment', 'no')

        // this.getCountForStatus()

        this.getLoanLead()
        // fetch(APIS.TODAYS_LOAN_COUNT + "&type=" + leadType)
        //     .then(res => res.json())
        //     .then(json => {
        //         this.setState({ todaysApplicationNo: json })
        //     })
    }
    componentDidMount() {
        this.getCount()
        interval = setInterval(function () {
            // this.setState({ loadarState: true })
            empId = localStorageUtil.getFromLocalStorage("AdminemailId")
            if (this.state.viewState === false) {
                this.props.getManagementLeadApiOptimized(leadType, this.state.followUpStatus, this.state.forwordPageState, empId, this.state.dataFilterState.searchByDate, this.state.commentType, '', this.state.bucketStage,
                    (callBack) => {
                        this.setState({ loadarState: false }, () => this.getComments())
                    })
            }
        }.bind(this), 30000) // 5 min
    }
    componentWillUnmount() {
        clearInterval(interval);
    }

    getData() {

    }
    getLoanLead() {
        this.setState({ loadarState: true })
        empId = localStorageUtil.getFromLocalStorage("AdminemailId")
        this.props.getManagementLeadApiOptimized(leadType, this.state.followUpStatus, this.state.forwordPageState, empId, this.state.dataFilterState.searchByDate, this.state.commentType, '', this.state.bucketStage,
            (callBack) => {
                this.setState({ loadarState: false }, () => this.getComments())
            })
    }
    handleChangeNewPagination(e, value) {
        this.setState({ pageNumber: value })
        const newMinPagination = (value - 1) * paginationNumber;
        const newMaxPagination = value * paginationNumber;
        this.setState({
            tableMinPagination: newMinPagination,
            tableMaxPagination: newMaxPagination,
        });
    };
    setBucketStage(type) {
        this.setState({ bucketStage: type }, () => this.getLoanLead())
    }
    getLoaderState(data) {
        this.setState({ loadarState: data })
    }

    render() {
        const { admin, leadManagementDataOptimized, allStatus, newDataBackground } = this.props
        const updatedData = leadManagementDataOptimized.map((entry1) => {
            const duplicateEntry = this.state.commentsData.find((entry2) => entry2.loanId === entry1.loanId);

            if (duplicateEntry) {
                return { ...entry1, adminCommentBody: duplicateEntry.adminCommentBody };
            } else {
                return entry1;
            }
        });

        return (
            <div className="full-height">
                {this.state.userState == true ?
                    <CompleteLeadsUi
                        countType={this.state.countType}
                        getLoanLead={this.getLoanLead.bind(this)}
                        getLoaderState={this.getLoaderState.bind(this)}
                        setBucketStage={this.setBucketStage.bind(this)}
                        bucketStage={this.state.bucketStage}
                        leadManagementCounts={this.state.leadManagementCounts}
                        forwordPageState={this.state.forwordPageState}
                        handleChangeNewPagination={this.handleChangeNewPagination.bind(this)}
                        pageNumber={this.state.pageNumber}
                        bucketHandler={this.bucketHandler.bind(this)}
                        bucketId={this.state.bucketStage}
                        leadComment={this.state.leadComment}
                        commentType={this.state.commentType}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        userCommentSelect={this.userCommentSelect.bind(this)}
                        userCommentHandler={this.userCommentHandler.bind(this)}

                        countforstatus={this.state.countforstatus}

                        allUserDetail={updatedData}
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        userViewHandler={this.userViewHandler.bind(this)}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        paginationHandler={this.paginationHandler.bind(this)}
                        allStatus={allStatus}
                        dataFilterState={this.state.dataFilterState}
                        followUpStatus={this.state.followUpStatus}
                        dataFilter={this.dataFilter.bind(this)}
                        admin={admin}
                        markAsImportant={this.markAsImportant.bind(this)}
                        loadarState={this.state.loadarState}
                        newDataBackground={newDataBackground}
                        todaysApplicationNo={this.state.todaysApplicationNo}
                        showRepeateApplications={this.showRepeateApplications.bind(this)}
                    />
                    : null}

                {this.state.viewDetailPopupState == true ?
                    <MainDetailsPopup
                        getData={this.getData.bind(this)}
                        leadManagementData={leadManagementDataOptimized}
                        getviewDetailPopupState={this.getviewDetailPopupState.bind(this)}
                        admin={admin}
                        userDetails={this.state.userDetail}
                        allDetail={this.state.viewPopupDetail}
                    />
                    : null}

                {this.state.viewState == true ?
                    <UserDetail
                    userDetailHandler={this.userDetailHandler.bind(this)}

                        getData={this.getData.bind(this)}
                        leadManagementData={leadManagementDataOptimized}
                        allDetail={this.state.allDetail}
                        userDetails={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                        markCompleteBtn={this.state.markCompleteBtn}
                        setDetailsPage={this.props.setDetailsPage}
                    />
                    : ""}
                {this.state.loadarState == true ?
                    <DotLoader />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
            </div>
        )
    }

    markAsImportant(allDetail, isImportant) {
        const { admin } = this.props
        saveImportantLoans(allDetail, isImportant, admin.emailId,
            (callBack) => {
                this.backHandler()
            })
    }

    showRepeateApplications() {
        this.setState({
            loadarState: true,
            followUpStatus: 'repeate'
        }, () => this.getLoanLead())
    }

    dataFilter(e, chngType) {
        const { admin } = this.props
        this.setState({ loadarState: true })
        let dataFilterState = Object.assign({}, this.state.dataFilterState)
        if (chngType == 'status') {
            this.setState({ followUpStatus: e.target.value }, () => this.getLoanLead())
        } else if (chngType == 'applicationNo') {
            dataFilterState.searchByAppNo = e.target.value
            if (e.target.value.length > 4) {
                this.props.getlistByApplication(e.target.value, 1, leadType, admin, false,
                    (callBack) => {
                        this.setState({ loadarState: false }, () => this.getComments())
                    })
            }
        } else if (chngType == 'email') {
            dataFilterState.searchByemail = e.target.value
            if (e.target.value.length > 2) {
                this.props.getlistByEmail(e.target.value, 1, leadType, admin,
                    (callBack) => {
                        this.setState({ loadarState: false }, () => this.getComments())
                    })
            }
        } else if (chngType == 'mobileNo') {
            dataFilterState.searchBymobile = e.target.value
            if (e.target.value.length > 9) {
                this.props.getistByNumber(e.target.value, 1, leadType, admin,
                    (callBack) => {
                        this.setState({ loadarState: false }, () => this.getComments())
                    })
            }
        } else if (chngType == "date") {
            dataFilterState.searchByDate = _preFormatDate(e)
            dataFilterState.searchByDateShow = e
            this.setState({
                dataFilterState
            }, () => this.getLoanLead())
        } else if (chngType == 'comment') {
            this.setState({ commentType: e.target.value, leadComment: e.target.value }, () => this.getLoanLead())
        }
        if (chngType != "date" && e.target.value == "") {
            this.getLoanLead()
        }
        this.setState({
            dataFilterState
        })
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    userDetailHandler(userData) {
        this.setState({ loadarState: true })

        getUserDetailByLoanIdOptimisedApi(userData.loanId, userData.userId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    allDetail: callBack,
                    userDetail: userData,
                    loadarState: false
                })
            })
        this.props.setDetailsPage(true)

    }

    userViewHandler(userData) {
        this.setState({ loadarState: true })

        getUserDetailByLoanIdOptimisedApi(userData.loanId, userData.userId,
            (callBack) => {
                this.setState({
                    viewDetailPopupState: true,
                    viewPopupDetail: callBack,
                    userDetail: userData,
                    loadarState: false
                })
            })
    }

    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

    backHandler() {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false,
            // loadarState: true
        })
        if (this.state.dataFilterState.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.dataFilterState.searchByAppNo, 1, leadType, admin, false,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.dataFilterState.searchByemail != '') {
            this.props.getlistByEmail(this.state.dataFilterState.searchByemail, 1, leadType, admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.dataFilterState.searchBymobile != '') {
            this.props.getistByNumber(this.state.dataFilterState.searchBymobile, 1, leadType, admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else {
            if (this.state.dataChange == 'yes') {
                this.getLoanLead()
                this.setState({ dataChange: '' })
            }
        }
    }

    paginationHandler(btnType) {
        this.setState({
            loadarState: true,
            pageIndexToShow: 0
        })
        if (btnType == 'refresh') {
            let dataFilterState = Object.assign({}, this.state.dataFilterState)
            dataFilterState.searchByDate = ''
            this.setState({ dataFilterState })
            this.getLoanLead()
        } else if (btnType == 'back') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                }, () => this.getLoanLead())
            }
        } else if (btnType == 'forward') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,

            }, () => this.getLoanLead())
        }
    }
}

const mapStateToProps = ({ leadManagementDataOptimized }) => {
    return {
        leadManagementDataOptimized: leadManagementDataOptimized
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getManagementLeadApiOptimized

    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteLeads);