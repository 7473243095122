import React from 'react'
const List = ({ allAmbasadorData, filterHandler, paginationHadler, entryHandler }) => (
    <div className='container-fluid'>
        <h3 className='text-center' style={{marginTop:'30px'}}>Tea Garden Ambassador's</h3>
        <div className='row' style={{ marginTop: '50px' }}>
            <div className='col-sm-4 col-xs-12'>
                <label>Search By Mobile No.</label>
                <input className='form-control' placeholder='Enter Number' onChange={(e) => filterHandler(e, 'number')} />
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label>Search By Email </label>
                <input className='form-control' placeholder='Enter Email' onChange={(e) => filterHandler(e, 'email')} />
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label>Search By Date </label>
                <input type='date' className='form-control' placeholder='Select Date' onChange={(e) => filterHandler(e, 'date')} />
            </div>
        </div>
        <div className='row' style={{ marginTop: '50px' }}>
            <div className='col-sm-12 col-xs-12'>
                <div className="overflow-x-auto" >
                    <table className="phocket-table">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email Id</th>
                                <th>Mobile Number</th>

                            </tr>
                        </thead>
                        <tbody>
                            {allAmbasadorData? allAmbasadorData.map((data, i) => {
                                return (
                                    <tr key={i} >
                                        <td>{data.firstName}</td>
                                        <td>{data.lastName}</td>
                                        <td>{data.emailId}</td>
                                        <td>{data.mobileNumber}</td>

                                    </tr>)
                            }) : ""}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div className='row' style={{marginTop:'20px'}}>
            <div className='col-sm-5 col-xs-12' >
                <button className='btn btn-default fa fa-chevron-left btn-lg btn-block mage' onClick={() => paginationHadler('back')}></button>
            </div>
            <div className='col-sm-2 col-xs-12' style={{ marginTop: '5px' }}>
                <input placeholder='Number Of Entry' type='number' className='form-control' onChange={(e) => entryHandler(e)} />
            </div>

            <div className='col-sm-5 col-xs-12'>
                <button className='btn btn-default fa fa-chevron-right btn-lg btn-block mage' onClick={() => paginationHadler('next')}></button>

            </div>

        </div>

    </div>
)
export default List