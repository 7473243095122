import React from 'react'

const RecoveryComment = ({ recoveryCommentChange, recoveryCommentHandler }) => (
    <div className="container-fluid max-width-500px">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Comment</h3>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12">
                <textarea className="form-control" rows="5" placeholder="Max character is 160" onChange={e => recoveryCommentChange(e)}></textarea>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12">
                <button className="profile-save-btn center-block" onClick={e => recoveryCommentHandler()}>SAVE</button>
            </div>
        </div>
    </div>
)

export default RecoveryComment;