import React, { Component } from 'react'
import StatusActivityUi from '../../../presentationals/Admin/CustomerPages/UserStatusActivity.component'
import { APIS } from '../../../../utils/api-factory'
import Loader from '../../../presentationals/Loader/DotLoader.component'
class StatusActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusActivityData: '',
loaderState:true
        }
    }

    componentWillMount() {
        const { allDetail ,userDetail} = this.props;
        if(userDetail.typeOfProduct =='Loan' ){
        fetch(APIS.STATUS_ACTIVITY_DATA + userDetail.loanId)
            .then(res => res.json())
            .then(json => {
                this.setState({ statusActivityData: json ,loaderState:false})
            })
        }else if(userDetail.typeOfProduct == 'Gift Card'){
            fetch(APIS.STATUS_ACTIVITY_DATA + userDetail.giftCardId)
            .then(res => res.json())
            .then(json => {
                this.setState({ statusActivityData: json,loaderState:false })
            }) 
        }
    }

    render() {
        return (
            <div>
                {this.state.loaderState?

                <Loader/>:""}
            <StatusActivityUi
            loaderState={this.state.loaderState}
                statusActivityData={this.state.statusActivityData}
            />
            </div>
        )
    }
}
export default StatusActivity;