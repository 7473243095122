import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  saveTentativeDataApi,
  getUserDetailByLoanId,
  getNbfcList,
  getCardLoanDetailByUserId,
  saveTentativeDataGiftCard,
  rfdApi

} from '../../AdminActionCreator.component'
import { calculateEmiApi } from '../../../Forms/PhocketActionCreator.component'
import { mandateVarificationApi, calculateEmiNbfcApi } from '../../AdminActionCreator.component'
import Popup from '../../../../presentationals/Popup/Popup.component'
import { _formatDateInDash } from '../../../../../utils/validation'
import UserFinancial from '../../../../presentationals/Admin/CustomerPages/cards utility pages/UserFinancialCardscomponent'
import BulletToEmiPopup from '../../../Dashboard/BulletToEmi.container'
import { APIS } from '../../../../../utils/api-factory'
import { TYPE } from '../../../../../utils/Constant'
import Loader from '../../../../presentationals/Loader/DotLoader.component'
import LoaderGift from '../../../../presentationals/Loader/Loader.component'

import GiftPopup from '../../../../presentationals/Popup/giftCardPopup.component'


class FinancialUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaderState: false,
      nbfc: 'RTcTsJVH',
      nbfcList: '',
      allDetail: this.props.allDetail,
      repeatApplication: false,
      tentativePopupState: false,
      popupState: false,
      popupStatus: '',
      lastEmiDate: '',
      BulletToEmiState: false,
      loanDataForEmiChange: '',
      tentativeDetail: {
        tentativeAmount: '',
        // lastEmiDate: '',
        // tentativeTenure: '',
        tentativeShift: '',
        selectEMI: '',
        nbfcId: 'RTcTsJVH',
        // company:''
      },
      emiTableData: [],
      newAllDetail: '',
      bankData: '',
      company: '',
      giftCardData: '',
      giftPopupState: false,
      giftLoader: false,
      belowoneLakhState: false,
      rangeVreedhi: '',
      salaryDateToShow: ''


    }
  }
  belowoneLakh(belowoneLakhState) {
    if (belowoneLakhState == true) {
      this.setState({ belowoneLakhState: false })
      let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
      tentativeDetail.tentativeAmount = ''
      this.setState({
        tentativeDetail
      })
    }
    else if (belowoneLakhState == false) {
      this.setState({ belowoneLakhState: true })
      let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
      tentativeDetail.tentativeAmount = ''
      this.setState({
        tentativeDetail
      })
    }

  }
  getGiftCardDetail(giftCardId) {
    this.setState({ giftLoader: true })

    if (giftCardId != null && giftCardId != '') {
      fetch(
        APIS.GET_GIFT_VOUCHER_DETAILS + giftCardId
      )
        .then(res => res.json())
        .then(json => {

          this.setState({ giftCardData: json, giftPopupState: true, giftLoader: false })

        })
    }
  }
  nbfcHandler(e) {
    this.setState({ nbfc: e.target.value })
    let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
    tentativeDetail.nbfcId = e.target.value
    this.setState({ tentativeDetail })
  }
  componentWillMount() {
    const { userDetails, admin } = this.props
    let nbfcName =
      admin.nbfcName != null && admin.nbfcName != '' ? admin.nbfcName : 'nbfc'
    getNbfcList(callBack => {
      this.setState({ nbfcList: callBack })
    })
    this.setState({ salaryDateToShow: this.state.allDetail.employmentDetailsPO.salaryDay })
    let tentativeDetail = Object.assign({}, this.state.tentativeDetail)

    if (userDetails.loanId != null && userDetails.loanId != '') {
      getUserDetailByLoanId(userDetails.loanId, callBack => {
        tentativeDetail.tentativeAmount = callBack.loanAmount
        // callBack.tentativeAmount != null ? callBack.tentativeAmount : ''
        this.setState({ allDetail: callBack, tentativeDetail })
      })
    }
    let loanEmi =
      this.state.allDetail.tentativeAmount > 10000
        ? this.state.allDetail.loanEmi
        : 1
    if (userDetails.typeOfProduct == 'Loan') {
      if (userDetails.loanFrom === 'vreedhi') {
        calculateEmiNbfcApi(
          userDetails.loanAmount,
          userDetails.loanEmi,
          this.state.allDetail.employmentDetailsPO.salaryDay,
          userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                if (emi.Date != undefined) {
                  tentativeDetail['tentativeTenure' + i] = emi.Date
                  // tentativeDetail['tentativeTenure' + i] = emi.Date
                  tentativeDetail['lastEmiDate' + i] = ''
                }
              })
              : null
            tentativeDetail.selectEMI = this.state.allDetail.loanEmi

            this.setState({ emiTableData: callBack, tentativeDetail })
          }
        )

      } else {
        calculateEmiApi(
          userDetails.loanAmount,
          userDetails.loanEmi,
          this.state.allDetail.employmentDetailsPO.salaryDay,
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                tentativeDetail['tentativeTenure' + i] = emi.Date
                tentativeDetail['lastEmiDate' + i] = ''
              })
              : null
            tentativeDetail.selectEMI = this.state.allDetail.loanEmi
            this.setState({ emiTableData: callBack, tentativeDetail })
          }
        )
      }
    } else if (userDetails.typeOfProduct == 'Gift Card') {
      calculateEmiApi(
        userDetails.loanAmount,
        '3',
        this.state.allDetail.employmentDetailsPO.salaryDay,
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              tentativeDetail['tentativeTenure' + i] = emi.Date
              tentativeDetail['lastEmiDate' + i] = ''
            })
            : null
          tentativeDetail.selectEMI = this.state.allDetail.loanEmi
          this.setState({ emiTableData: callBack, tentativeDetail })
        }
      )
    }

  }
  closePopupGift() {
    this.setState({
      giftPopupState: false
    })
  }
  RfdFunction(data) {
    const { admin, userDetails } = this.props
    rfdApi(userDetails.loanId,data, admin.emailId, userDetails.userId,userDetails.giftCardId, callBack => {
      if (callBack === 'success') {
        this.setState({
          popupState: true,
          popupStatus: 'saved successfully'
        })
        this.removePopup()
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please Try Again later!'
        })
        this.removePopup()
      }
    })
  }
  salaryhandler(e) {
    const { allDetail, admin, userDetails } = this.props

    let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
    this.setState({ salaryDateToShow: e.target.value })


    let nbfcName =
      admin.nbfcName != null && admin.nbfcName != '' ? admin.nbfcName : 'nbfc'
    if (userDetails.typeOfProduct == 'Loan') {
      calculateEmiNbfcApi(
        userDetails.loanAmount,
        this.state.tentativeDetail.selectEMI,
        e.target.value,
        userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              if (emi.Date != undefined) {
                tentativeDetail['tentativeTenure' + i] = emi.Date
                // tentativeDetail['tentativeTenure' + i] = emi.Date
                tentativeDetail['lastEmiDate' + i] = ''
              }
            })
            : null
          // tentativeDetail.selectEMI = this.state.allDetail.loanEmi

          this.setState({ emiTableData: callBack, tentativeDetail })
        }
      )
    } else if (userDetails.typeOfProduct == 'Gift Card') {
      let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
      tentativeDetail.selectEMI = '3'
      calculateEmiNbfcApi(
        userDetails.loanAmount,
        '3',
        e.target.value,
        userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              if (emi.Date != undefined) {
                tentativeDetail['tentativeTenure' + i] = emi.Date
                // tentativeDetail['tentativeTenure' + i] = emi.Date
                tentativeDetail['lastEmiDate' + i] = ''
              }
            })
            : null

          this.setState({ emiTableData: callBack, tentativeDetail })
        }
      )
    }

    this.setState({
      tentativeDetail
    })

  }

  render() {
    const { admin, userDetails } = this.props
    return (
      <div>

        {this.state.loaderState ? <Loader /> : ''}
        {this.state.popupState == true ? (
          <Popup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
        ) : (
          ''
        )}
        {this.state.giftPopupState ?
          <GiftPopup
            giftCardData={this.state.giftCardData}
            closePopup={this.closePopupGift.bind(this)}
          />
          : ""}
        {this.state.giftLoader ?
          <LoaderGift />
          : ""}
        <UserFinancial
          salaryDateToShow={this.state.salaryDateToShow}
          salaryhandler={this.salaryhandler.bind(this)}
          rangeVreedhi={this.state.rangeVreedhi}
          RfdFunction={this.RfdFunction.bind(this)}
          belowoneLakhState={this.state.belowoneLakhState}
          belowoneLakh={this.belowoneLakh.bind(this)}
          hideDeactivateButton={this.props.hideDeactivateButton}
          getGiftCardDetail={this.getGiftCardDetail.bind(this)}
          company={this.state.company}
          userDetails={userDetails}
          bankData={this.state.bankData}
          nbfc={this.state.nbfc}
          nbfcHandler={this.nbfcHandler.bind(this)}
          nbfcList={this.state.nbfcList}
          allDetail={this.state.newAllDetail}
          repeatApplication={this.state.repeatApplication}
          tentativePopup={this.tentativePopup.bind(this)}
          tentativePopupState={this.state.tentativePopupState}
          tentativeData={this.tentativeData.bind(this)}
          tentativeSaveHandler={this.tentativeSaveHandler.bind(this)}
          tentativeDetail={this.state.tentativeDetail}
          admin={admin}
          emiTableData={this.state.emiTableData}
          convertLoan={this.convertLoan.bind(this)}
          emiConvertHandler={this.emiConvertHandler.bind(this)}
          mendateVerification={this.mendateVerification.bind(this)}
        />
        {this.state.BulletToEmiState == true ? (
          <BulletToEmiPopup
            loanDataForEmiChange={this.state.loanDataForEmiChange}
            getLoanState={this.getLoanState.bind(this)}
            userDetail={this.state.allDetail}
          />
        ) : null}
      </div>
    )
  }

  mendateVerification() {
    mandateVarificationApi(this.state.allDetail.userId,this.state.allDetail.loanId, callBack => {
      if (callBack == 'success') {
        this.setState({
          popupState: true,
          popupStatus: 'Sent for verification!'
        })
        this.removePopup()
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please try again'
        })
        this.removePopup()
      }
    })
  }

  emiConvertHandler(e) {
    fetch(
      APIS.UPDATE_CONVERT_EMI_BTN +
      this.state.allDetail.userId +
      '/' +
      e.target.checked
    )
      .then(res => res.text())
      .then(text => {
        if (text == 'success') {
          getUserDetailByLoanId(this.state.allDetail.loanId, callBack => {
            this.setState({
              allDetail: callBack,
              popupState: true,
              popupStatus: 'saved successfully'
            })
            this.removePopup()
          })
        }
      })
  }

  convertLoan(loanData) {
    this.setState({
      BulletToEmiState: true,
      loanDataForEmiChange: loanData
    })
  }

  getLoanState(updatePopupState) {
    this.setState({
      BulletToEmiState: updatePopupState
    })
    getUserDetailByLoanId(this.state.allDetail.loanId, callBack => {
      this.setState({ allDetail: callBack })
    })
  }

  removePopup() {
    setTimeout(
      function () {
        this.setState({ popupState: false })
      }.bind(this),
      5000
    )
  }

  closePopup() {
    this.setState({ popupState: false })
  }

  componentDidMount() {
    const { userDetails } = this.props
    // for (let i = 0; i < this.state.allDetail.loanDetailList.length; i++) {
    //   let loanData = this.state.allDetail.loanDetailList[i]
    //   if (loanData.loanSanctionStatus == 'Closed') {
    //     this.setState({ repeatApplication: true })
    //     break
    //   }
    // }
    this.setState({ loaderState: true })
    getCardLoanDetailByUserId(userDetails.userId, callBack => {
      if (callBack != '') {
        let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
        tentativeDetail.tentativeAmount = callBack.loanAmount
        tentativeDetail.selectEMI = callBack.loanEmi
        this.setState({ tentativeDetail })
      }
      this.setState({

        newAllDetail: callBack,
        loaderState: false
      })
    })

    fetch(APIS.GET_BANK_DETAIL_BY_USER_ID + userDetails.userId)
      .then(res => res.json())
      .then(json => {
        this.setState({ bankData: json })
      })
  }

  tentativeData(e, tType, index) {
    const { userDetails } = this.props

    let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
    if (tType == 'amount') {
      // tentativeDetail.tentativeAmount = e.target.value
      if (this.state.belowoneLakhState == false && e.target.value <= 100000 && userDetails.loanFrom !== 'vreedhi') {
        tentativeDetail.tentativeAmount = e.target.value
      } else if (this.state.belowoneLakhState == false && e.target.value > 100000 && userDetails.loanFrom !== 'vreedhi') {
        alert('Kindly tick the check box provided')

      }
      if (this.state.belowoneLakhState == true) {
        tentativeDetail.tentativeAmount = e.target.value
      }
      if (userDetails.loanFrom === 'vreedhi') {
        tentativeDetail.tentativeAmount = e.target.value
      }
    } else if (tType == 'tenure') {
      // tentativeDetail.lastEmiDate = e
      // tentativeDetail.tentativeTenure = _formatDateInDash(e)
      this.state.emiTableData.length > 0
        ? this.state.emiTableData.map((emi, i) => {
          if (i == index) {
            tentativeDetail['lastEmiDate' + i] = e
            tentativeDetail['tentativeTenure' + i] = _formatDateInDash(e)
          }
        })
        : null
    } else if (tType == 'shift') {
      tentativeDetail.tentativeShift = e.target.value
    } else if (tType == TYPE.SELECT_EMI) {
      tentativeDetail.selectEMI = e.target.value
      if (userDetails.loanFrom === 'vreedhi') {
        if (e.target.value < 26 || e.target.value > 104) {
          e.target.value = ''
          this.setState({ rangeVreedhi: 'Please Enter Between 26 to 104 Weeks' })
        } else {
          this.setState({ rangeVreedhi: '' })

        }
      }
      if (userDetails.loanFrom === 'vreedhi') {
        calculateEmiNbfcApi(
          userDetails.loanAmount,
          e.target.value,
          this.state.allDetail.employmentDetailsPO.salaryDay,
          userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                if (emi.Date != undefined) {
                  tentativeDetail['tentativeTenure' + i] = emi.Date
                  // tentativeDetail['tentativeTenure' + i] = emi.Date
                  tentativeDetail['lastEmiDate' + i] = ''
                }
              })
              : null

            this.setState({ emiTableData: callBack, tentativeDetail })
          }
        )

      } else {
        calculateEmiApi(
          userDetails.loanAmount,
          e.target.value,
          this.state.salaryDateToShow !== '' ? this.state.salaryDateToShow : this.state.allDetail.employmentDetailsPO.salaryDay,
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                tentativeDetail['tentativeTenure' + i] = emi.Date
                tentativeDetail['lastEmiDate' + i] = ''
              })
              : null

            this.setState({ emiTableData: callBack, tentativeDetail })
          }
        )
      }
    } else if (tType == 'company') {
      // tentativeDetail.company=e.target.value
      this.setState({ company: e.target.value })
    }
    this.setState({ tentativeDetail })
  }

  tentativeSaveHandler() {
    const { admin, userDetails } = this.props
    let paybackDates = []
    this.state.emiTableData.length > 0
      ? this.state.emiTableData.map((emi, i) => {
        let dateValue = document.getElementById('RepaymentDate_' + i).value
        paybackDates.push(dateValue)
      })
      : null
    if (
      this.state.tentativeDetail.nbfcId != '' &&
      this.state.tentativeDetail.tentativeAmount != ''
      // &&
      // this.state.tentativeDetail.tentativeShift != ''
    ) {
      if (userDetails.typeOfProduct == 'Loan' && this.state.rangeVreedhi === '') {
        saveTentativeDataApi(
          this.state.tentativeDetail,
          paybackDates,
          userDetails,
          admin,
          callBack => {
            if (callBack == 'success') {
              this.setState(
                {
                  popupState: true,
                  popupStatus: 'Data saved successfully',
                  tentativePopupState: false
                },
                () => this.props.getData('yes')
              )
              this.removePopup()
            } else {
              this.setState({
                popupState: true,
                popupStatus: 'Please try again'
              })
              this.removePopup()
            }
          }
        )
      } else if (userDetails.typeOfProduct == 'Gift Card' && this.state.rangeVreedhi === '') {
        saveTentativeDataGiftCard(
          this.state.tentativeDetail,
          paybackDates,
          userDetails,
          admin,
          this.state.company,
          callBack => {
            if (callBack == 'success') {
              this.setState({
                popupState: true,
                popupStatus: 'Data saved successfully',
                tentativePopupState: false
              })
              this.removePopup()
            } else {
              this.setState({
                popupState: true,
                popupStatus: 'Please try again'
              })
              this.removePopup()
            }
          }
        )
      }
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please fill the details'
      })
      this.removePopup()
    }
  }

  tentativePopup(type) {
    const { userDetails } = this.props
    if (type == 'open') {
      this.setState({ tentativePopupState: true })
      // let tentativeDetail=Object.assign({},this.state.tentativeDetail)
      // tentativeDetail.company=this.props.userDetails.giftCardType
      this.setState({ company: userDetails.giftCardType })
    } else if (type == 'close') {
      this.setState({ tentativePopupState: false })
    }
  }
}
const mapStateToProps = ({ pendingDetail }) => {
  return {
    pendingDetail: pendingDetail
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(FinancialUser)
