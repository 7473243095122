import React from 'react'
import VerifyImage from '../../../images/verified.png'
import NotVerified from '../../../images/cross.png'
const SavePopup = ({ closePopup, jsonErrorData, aadharDataFromKarza }) => (
    <div className='background-blur income'>
        <div className='popup-main small-popup container-fluid dbl-border'>
            <button className='close' onClick={e => closePopup()}>
                &times;
            </button>
            <div className='col-xs-12 col-sm-12 text-center' style={{ marginBottom: '20px' }}>
                <h3 className='text-center' style={{ color: 'black' }}>Error Message</h3>
                Status:<b style={{ color: aadharDataFromKarza.status ? 'green':'red'}}>{aadharDataFromKarza.status ? 'True' : 'False'}</b>
                <h4 className='text-center' style={{ marginTop: '30px' }}><b>{jsonErrorData.result.message}</b></h4>
            </div>
        </div>


    </div>
)

export default SavePopup;