
import Logo from '../../maketingimages/Group 230265.png'
export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip-new" style={{width:'350px'}}>
              
                <div style={{ display: 'inline-flex', }}>
                    <div style={{ background: '#5B8FF9', padding: '12px', borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }}>
                        <img src={Logo} style={{ width: '12px',marginTop:'5px' }} />
                    </div>
                    {payload.map((pld,i) => {
                        return(
                        <p style={{ marginTop: '7px', marginLeft: '10px' }}>  Count : {pld.value }<br/>
                        Reason :{pld.payload.loanRejectReason}
                        </p>
                        )
                        })}
                </div>

            </div>
        );
    }

    return null;
};