import React, { Component } from 'react'
import UserAgreement from '../../../presentationals/Admin/CustomerPages/UserAgreement.component'
import { getUserDetailByLoanId, uploadStampDutyApi } from '../AdminActionCreator.component'
import { APIS } from '../../../../utils/api-factory';
import Loader from '../../../presentationals/Loader/Loader.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { _formatDateInDash } from '../../../../utils/validation';

class AgreementUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderState: false,
            creditScoringPopup: false,
            certificateNoValue: '',
            popupState: false,
            popupStatus: '',
            disburseDate: '',
            disburseDateValue: '',
            senderMailId: '',
            allDetail: this.props.allDetail,
            disburseDateDisable: true
        }
    }


    componentDidMount() {
        console.log(this.state.allDetail)
        this.setState({
            certificateNoValue: this.state.allDetail.certificateNumber != null ? this.state.allDetail.certificateNumber : "",
        })
        if (this.state.allDetail.applicationStatus == 'Disbursed') {
            this.setState({
                disburseDateDisable: true,
                disburseDateValue: this.state.allDetail.disburseDate.split(" ")[0]
            })
        } else {
            this.setState({
                disburseDateDisable: false,
            })
        }
    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}

                <UserAgreement
                    agreementHandler={this.agreementHandler.bind(this)}
                    agreementInput={this.agreementInput.bind(this)}
                    certificateNoSaveHandler={this.certificateNoSaveHandler.bind(this)}
                    certificateNoValue={this.state.certificateNoValue}
                    disburseDate={this.state.disburseDate}
                    disburseDateValue={this.state.disburseDateValue}
                    disburseDateDisable={this.state.disburseDateDisable}
                    uploadStampDuty={this.uploadStampDuty.bind(this)}
                />
                {this.state.loaderState == true ?
                    <Loader />
                    : ""}
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    uploadStampDuty(e) {
        let val = e.target.value.split(/[\\\/]/)[2]
        let docType = val.split(".");
        let file = [e.target.files[0]]
        let fileType = docType[docType.length - 1];
        uploadStampDutyApi(file, fileType, 'stampDuty', this.state.allDetail.userId,
            (callBack) => {
                this.setState({
                    popupState: true,
                    popupStatus: 'Stamp Duty Uploaded Successfully',
                })
                this.removePopup();
            })
    }

    agreementInput(e, certiType) {
        if (certiType == 'certiNo') {
            this.setState({ certificateNoValue: e.target.value })
        } else if (certiType == 'disburseDate') {
            this.setState({
                disburseDate: e,
                disburseDateValue: _formatDateInDash(e)
            })
        } else if (certiType == 'senderId') {
            this.setState({ senderMailId: e.target.value })
        }
    }

    certificateNoSaveHandler() {
        const {userDetail}=this.props
        if(userDetail.typeOfProduct == 'Loan'){

        if (this.state.certificateNoValue != '' && this.state.disburseDateValue != '') {
            
            fetch(APIS.SAVE_CERTIFICATE_NUMBER + this.state.allDetail.loanId + '&certificateNumber=' + this.state.certificateNoValue.toUpperCase() + '&date=' + this.state.disburseDateValue + '&tenure=' + this.state.allDetail.loanTenure)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Certificate No. saved Successfully',
                        })
                        this.removePopup();
                        getUserDetailByLoanId(this.state.allDetail.loanId,
                            (callBack) => {
                                this.setState({ allDetail: callBack })
                            })
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please Try Again',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please enter Certificate No. and Disburse Date',
            })
            this.removePopup();
        }
    }else if(userDetail.typeOfProduct == 'Gift Card'){
        if (this.state.certificateNoValue != '' && this.state.disburseDateValue != '') {
            fetch(APIS.SAVE_CERTIFICATE_NUMBER + userDetail.giftCardId + '&certificateNumber=' + this.state.certificateNoValue.toUpperCase() + '&date=' + this.state.disburseDateValue + '&tenure=' + userDetail.loanDuration)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Certificate No. saved Successfully',
                        })
                        this.removePopup();
                        getUserDetailByLoanId(this.state.allDetail.loanId,
                            (callBack) => {
                                this.setState({ allDetail: callBack })
                            })
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please Try Again',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please enter Certificate No. and Disburse Date',
            })
            this.removePopup();
        }
    }
    }

    agreementHandler(btntype) {
        console.log(this.state.allDetail.certificateNumber)
        // if (this.state.allDetail.certificateNumber != null && this.state.allDetail.certificateNumber != '') {
            this.setState({ loaderState: true })
            if (btntype == 'save') {
                fetch(APIS.GET_LOAN_AGREEMENT + this.state.allDetail.loanId)
                    .then(res => res.text())
                    .then(text => {
                        window.open(text);
                        this.setState({ loaderState: false })
                    })

            } else if (btntype == 'send') {
                if (this.state.senderMailId != '') {
                    fetch(APIS.SEND_AGGREMENT + this.state.allDetail.loanId + '&emailId=' + this.state.senderMailId)
                        .then(res => res.text())
                        .then(res => {
                            window.open(res)
                            this.setState({ loaderState: false })
                        })
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please enter mail id',
                        loaderState: false
                    })
                    this.removePopup();
                }
            }
        // } else {
        //     this.setState({
        //         popupState: true,
        //         popupStatus: 'Please enter Certificate No.',
        //     })
        //     this.removePopup();
        // }
    }
}

export default AgreementUser;