import React, { useEffect, useState } from "react";
import { getCategoryList, saveCategory } from "./actionCreatorScore";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const Listing = () => {
    const [categoryData, setCategoryData] = useState('')
    const [name, setname] = useState('')
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getdata()
    }, [])
    const getdata = () => {
        try {
            setLoader(true)
            getCategoryList(callback => {
                if(callback){
                setCategoryData(callback);
                setLoader(false);
                }
            });
        } catch (error) {
            console.error("Error:", error);
        }
        
    }
    const saveCategoryApi = () => {
        setLoader(true)
        saveCategory(name, callback => {
            getdata()

        })
    }
    const changeHandler = (e) => {
        setname(e.target.value)
    }
    return (

        <div className="">
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <div className="box-score">
                        <h3> Category List</h3>
                        <div className="phocket-table-new">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader === false && categoryData !== '' && categoryData.length > 0 && categoryData.map((data, i) => {
                                        return (
                                            <tr>
                                                <td>{moment(data.addedOn).format('DD-MM-YYYY')}</td>
                                                <td>{data.name}</td>
                                            </tr>
                                        )
                                    })}
                                    {loader ?
                                        <>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                        </>
                                        : ""}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12 ">
                    <div className="box-score">
                        <h3> Add Category</h3>

                        <div style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div style={{ marginTop: '50px' }}>
                                <label>Name</label>
                                <input onChange={(e) => changeHandler(e)} className="form-control" placeholder="Enter Name" />
                            </div>
                            <div className="text-center" style={{ marginTop: '30px' }}>
                                <button onClick={(e) => saveCategoryApi()} className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Listing