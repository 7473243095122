import React from 'react'
import DatePicker from 'react-datepicker';
import { EMPLOYER_TYPE, STRING, FORM_STATUS, TYPE } from '../../../../utils/Constant'

const EDit = ({ admin, onSubmitHandler, onChangeHandler, userDetail, BasicDOB,
    basicValidationMsg, residenceValidationMsg,
    bankValidationMsg, userOtherInfo }) => (
    <div className="crm-edit-profile">
        <div className="container-fluid customer-dashbord-view-box">
            {/* {admin != null && admin.emailId != null ? admin.emailId == 'SuperUser' ? */}
            <div>
                <div className="row">
                    <h3>Mobile &amp; Email</h3>
                    <div className="col-sm-4 col-xs-12">
                        <h4>Mobile Number</h4>
                        <input readOnly={admin != null && admin.role != null && admin.role == 'superuser' ? false : true} type="number" className="form-control" value={userDetail.MobileNumberState} onChange={(e) => onChangeHandler(e, TYPE.MOBILE_NO)} />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <h4>EmailId</h4>
                        <input readOnly={admin != null && admin.role != null && admin.role == 'superuser' ? false : true} type="email" className="form-control" value={userDetail.EmailIDState} onChange={(e) => onChangeHandler(e, TYPE.EMAIL_ID)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <button className="profile-save-btn center-block" onClick={(e) => onSubmitHandler(e, 'mobileNEmail')} disabled={admin != null && admin.role != null && admin.role == 'superuser' ? false : true}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;SAVE
                        </button>
                    </div>
                </div>

            </div>
            {userOtherInfo != false ?
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <h4>Loan Id</h4>
                        <div className="display-flex">
                            <input className="form-control" type="text" onChange={(e) => onChangeHandler(e, TYPE.APP_LOAN_ID)} value={userDetail.appLoanId} />
                            <button className="profile-save-btn margin-0" onClick={(e) => onSubmitHandler(e, 'LoanId')}><span className="fa fa-save" /></button>
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <h4>Cheque Limit</h4>
                        <div className="display-flex">
                            <input className="form-control" type="text" onChange={(e) => onChangeHandler(e, TYPE.CHEQUE_LIMIT)} value={userDetail.chequeLimitState} />
                            <button className="profile-save-btn margin-0" onClick={(e) => onSubmitHandler(e, 'chequeLimit')}><span className="fa fa-save" /></button>
                        </div>
                    </div>
                </div>
                : null}
            <div className="row">
                <h3>Personal Details</h3>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>Name</h4>
                    <input type="text" className="form-control" value={userDetail.firstNameState} onChange={(e) => onChangeHandler(e, TYPE.FIRST_NAME)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "firstName" ? basicValidationMsg.msg : ""}
                    </div>
                </div>


                <div className="col-sm-4 col-xs-12">
                    <h4>D.O.B</h4>
                    <DatePicker
                        value={userDetail.BasicDOB}
                        selected={BasicDOB}
                        onChange={(e) => onChangeHandler(e, TYPE.DOB)}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="DD/MM/YYYY"
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                    />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "BasicDOB" ? basicValidationMsg.msg : ""}
                    </div>
                </div>

                <div className="col-sm-4 col-xs-12">
                    <h4>Email Id</h4>
                    <input type="email" readOnly className="form-control" value={userDetail.EmailIDState} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "emailId" ? basicValidationMsg.msg : ""}
                    </div>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandler(e, FORM_STATUS.BASIC)}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
            <hr />
            <div className="row">
                <h3>Residence Details</h3>
            </div>
            <div className="row">
                <h5>Current Address</h5>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>Address</h4>
                    <input type="text" className="form-control" value={userDetail.CurrentAddressState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_ADDRESS)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "CurrentAddress" ? residenceValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Pincode</h4>
                    <input type="text" className="form-control" value={userDetail.CurrentPinCodeState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_PIN_CODE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "CurrentPin" ? residenceValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>State</h4>
                    <input readOnly type="text" className="form-control" id="userCurrentState" value={userDetail.CurrentStateState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_STATE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>City</h4>
                    <input readOnly type="text" className="form-control" id="userCurrentCity" value={userDetail.CurrentCityState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_CITY)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>

            </div>
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandler(e, FORM_STATUS.RESIDANCE)}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
            <hr />
            <div className="row">
                <h3>Bank Details</h3>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Name as in Bank Account</h4>
                    <input type="text" className="form-control" value={userDetail.BankUserNameState} onChange={(e) => onChangeHandler(e, TYPE.BANK_USER_NAME)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "BankUserName" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>IFSC Code</h4>
                    <input type="text" className="form-control" value={userDetail.IFSCCodeState} onChange={(e) => onChangeHandler(e, TYPE.IFSC_CODE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "IFSCCode" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Account Number</h4>
                    <input type="text" className="form-control" value={userDetail.AccountNoState} onChange={(e) => onChangeHandler(e, TYPE.ACCOUNT_NO)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "AccountNo" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Bank Name</h4>
                    <input type="text" id="userBankName" className="form-control" value={userDetail.BankNameState} onChange={(e) => onChangeHandler(e, TYPE.BANK_NAME)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Branch</h4>
                    <input type="text" id="userBankBranch" className="form-control" value={userDetail.BankBranchState} onChange={(e) => onChangeHandler(e, TYPE.BANK_BRANCH)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>

                <div className="col-sm-4 col-xs-12">
                    <h4>Name As on PAN</h4>
                    <input type="text" className="form-control" placeholder='Name As On PAN' value={userDetail.nameAsOnPan} onChange={(e) => onChangeHandler(e, 'PanName')} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Pan Number</h4>
                    <input type="text" id="panNumber" className="form-control" value={userDetail.PanNoState} onChange={(e) => onChangeHandler(e, TYPE.PAN_NO)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "PanNo" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Aadhaar Number</h4>
                    <input type="number" id="aadhaarNumber" className="form-control" value={userDetail.aadharNoState} onChange={(e) => onChangeHandler(e, TYPE.AADHAAR_NO)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "aadharNo" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandler(e, FORM_STATUS.BANK)}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
        </div>
    </div>
)
export default EDit