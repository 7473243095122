import { Alert } from "@mui/material";
import React from "react";

const SidePopup=({popupType,msg})=>{
    return(
        <div className="" style={{position:'fixed',bottom:'20px',zIndex:'99999'}}>
        <Alert style={{fontSize:'18px'}} severity={popupType}>{msg}</Alert>
        </div>
    )
}
export default SidePopup