import React from 'react'
import moment from "moment";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter, ComposedChart, Line,
} from 'recharts';
export default function TablePopup({ tablePopupData, close, tabName, tablePopupDataType }) {
    return (
        <>
            <div className="background-blur " onClick={close}></div>
            {
                tabName == 'DebtManagement' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '100%' }}>
                        <div className="popup-content income">
                            <h4 className="text-center">{tablePopupData?.month}</h4>
                            <div className="tables">
                                <div className="phocket-table-new newTable2" style={{ overflowY: 'auto', maxHeight: '400px' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Creditor</th>
                                                <th>Starting Balance</th>
                                                <th>Interest Rate</th>
                                                <th>Payment</th>
                                                <th>New Balance</th>
                                                <th>Estimated Payoff Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablePopupData?.data?.data?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.Creditor}</td>
                                                    <td>{data.startingBalance}</td>
                                                    <td>{data.intrestRate}</td>
                                                    <td>{data.Payment}</td>
                                                    <td>{data.newBalance}</td>
                                                    <td>{moment(data.payoffDate).format('DD-MM-YYYY')}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
                    {console.log("tablePopupData",tablePopupData)}
            {
                tabName == 'DebtManagementGraph' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '100%' }}>
                        <div className="popup-content income">
                            <div className="tables">
                                <div className="phocket-table-new newTable2" style={{ overflowY: 'auto', maxHeight: '400px' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Creditor</th>
                                                <th>Starting Balance</th>
                                                <th>Interest Rate</th>
                                                <th>Payment</th>
                                                <th>New Balance</th>
                                                <th>Estimated Payoff Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(tablePopupData?.data?.totalData)?.length > 0 ?
                                                <tr >
                                                    <td>{tablePopupData?.data?.totalData.Creditor}</td>
                                                    <td>{tablePopupData?.data?.totalData.startingBalance}</td>
                                                    <td>{tablePopupData?.data?.totalData.intrestRate}</td>
                                                    <td>{tablePopupData?.data?.totalData.Payment}</td>
                                                    <td>{tablePopupData?.data?.totalData.newBalance}</td>
                                                    <td>{moment(tablePopupData?.data?.totalData.payoffDate).format('DD-MM-YYYY')}</td>
                                                </tr>
                                            :''}

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="charts" style={{ marginTop: '50px', border: '1px solid rgba(0,0,0,0.25)', borderRadius: '6px', padding: '10px' }}>
                                <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '600px' : '300px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={150}
                                            data={tablePopupData.data.data}
                                            barSize={window?.innerWidth > 1800 ? 40 : 20 }
                                            margin={{
                                                top: 20,
                                                right: 0,
                                                left: 0,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid stroke='none' />
                                            <XAxis dataKey="Creditor" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="newBalance" fill="#8884d8" />
                                            <Line type="monotone" dataKey="newBalance" stroke="#223D64" strokeWidth={2} dot={function customDot() { return null }} />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>

                            </div>
                        </div>
                    </div>
                    : ''}
            {
                tabName == 'DebtManagementDashboard' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '800px' }}>
                        <div className="popup-content income">
                            <h4 className="text-center">{tablePopupDataType?.name}</h4>
                            <div className="tables">
                                <div className="phocket-table-new newTable2">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Creditors</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablePopupData?.data?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data?.creditors}</td>
                                                    <td>{data?.amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            {
                tabName == 'KPILoanManagementDashboard' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '800px' }}>
                        <div className="popup-content income">
                            <div className="tables">
                                <div className="phocket-table-new newTable2">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Reference Number</th>
                                                <th>Customer Name</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablePopupData?.data?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.referenceNumber}</td>
                                                    <td>{data.name}</td>
                                                    <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                                                    <td>{data.amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
        </>
    )
}
