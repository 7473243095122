import React from 'react'
import Ui from './Basic.conatiner'
import { getNbfcProductByNbfcId } from '../AdminActionCreator.component';
import Product from './Product.container';
import Charges from './AllCharges.container'
import Esign from './AllEsign.container'
import ENach from './AllEnach.container'

class DetailsUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nbfcData: '',
            pageNo: 1,
            noOfEntry: 10,
            viewPageState: 'Basic'
        }
    }
    componentDidMount() {
        this.setState({ nbfcData: this.props.nbfcData })
        // if (this.props && this.props.nbfcData && this.props.nbfcData.nbfcId) {
        //     this.getProductData(this.props.nbfcData.nbfcId)
        // }
    }

    sendHandler(data) {
        this.setState({ viewPageState: data })
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="edit-profile-menu col-xs-12">
                        <div className="btn-group-tab">
                            <button className={this.state.viewPageState == 'Basic' ? 'active' : null} onClick={(e) => this.sendHandler('Basic')}>Basic</button>
                            <button className={this.state.viewPageState == 'Product' ? 'active' : null} onClick={(e) => this.sendHandler('Product')}>Product</button>
                            <button className={this.state.viewPageState == 'Charges' ? 'active' : null} onClick={(e) => this.sendHandler('Charges')}>Charges</button>
                            <button className={this.state.viewPageState == 'E-sign' ? 'active' : null} onClick={(e) => this.sendHandler('E-sign')}>E-sign</button>
                            <button className={this.state.viewPageState == 'E-Nach' ? 'active' : null} onClick={(e) => this.sendHandler('E-Nach')}>E-Nach</button>

                        </div>
                    </div>
                </div>
                {this.state.viewPageState === 'Basic' ?
                    <Ui nbfcData={this.state.nbfcData} />
                    : ""}
                {this.state.viewPageState === 'Product' ?
                    <Product nbfcData={this.state.nbfcData} />
                    : ""}
                {this.state.viewPageState === 'Charges' ?
                    <Charges nbfcData={this.state.nbfcData} />
                    : ""}
                {this.state.viewPageState === 'E-sign' ?
                    <Esign nbfcData={this.state.nbfcData} />
                    : ""}
                {this.state.viewPageState === 'E-Nach' ?
                    <ENach nbfcData={this.state.nbfcData} />
                    : ""}
            </React.Fragment>
        )
    }
}
export default DetailsUi