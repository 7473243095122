import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket: NACH Bounced

const SecurityChequeMail = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div><a href={MAIL_STRING.WEB_URL_NACH}  className="blue-text">Click here for NACH Form and Sample Document.</a></div>
        <br />
        <div>Kindly Print the two copies of Nach form and do sign on both copies.</div>
        <br />
        <div>And we need your Salary A/C Cheque for security purposes, This cheque needs to be duly signed and undated & in favour of Superline Fininvest Pvt. Ltd</div>
        <br />
        <div><b>TO, Superline Fininvest Pvt. Ltd </b></div>
        <br />
        <div>All this you have to Post on our office address ASAP, once we received it we will disbursed your amount.</div>
        <br />
        <div><u><b>Address:</b></u></div>
        <br />
        <div><b>BorrowBuddy Infotech Pvt. Ltd.</b></div>

        <div><b>1401,1403, Building No. 43, Chiranjiv Tower</b> </div>

        <div><b>Opposite Eros Hotel, Nehru Place</b> </div>

        <div><b>New Delhi- 110019.</b></div>
        <br />
        <div>Warm Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default SecurityChequeMail