import { APIS } from '../../../../utils/api-factory'


export const getLoginModeCount = (userData, callBack) => {
    var url = APIS.GET_PROFILE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loanAmountData = {
                // height: 420,
                // width: 1106,
                animationEnabled: true,
                animationDuration: 2000,
                title: {
                    text: "Login Mode Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    margin: 15,
                    fontWeight: "bold",
                    horizontalAlign: "left"
                },
                axisY: {
                    interval: 5000,
                    gridDashType: "dot",
                    gridColor: "#ccc",
                },
                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                data: [
                    {
                        type: "column",
                        showInLegend: true,
                        name: "complete",
                        color: "#0bc9cd",
                        dataPoints: res.Complete_doc_login_mode
                    }
                    , {
                        type: "column",
                        showInLegend: true,
                        name: "Partial",
                        color: "#274060",
                        dataPoints: res.Partial_Complete_doc_login_mode
                    }, {
                        type: "column",
                        showInLegend: true,
                        color: "#046a81",
                        name: "Incomplete",
                        dataPoints: res.Incomplete_Complete_doc_login_mode
                    }
                ]

            }
            callBack(loanAmountData)
        })
}



export const getLoginDeviceDataMethod = (userData, callBack) => {
    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginDeviceData = {
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Gender Wise Count",

                },

                data: [{
                    colorSet: "colorSetNew",
                    type: "pie",
                    startAngle: 75,
                    toolTipContent: "<b>{label}</b>: {y}",
                    showInLegend: "true",
                    legendText: "{label}",
                    indexLabelFontSize: 16,
                    indexLabel: "{label} - {y}",
                    dataPoints: res.Complete_gender_application
                }

                    ,]
            }
            callBack(loginDeviceData)
        })
}
export const getLoginDeviceincompleteDataMethod = (userData, callBack) => {
    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginDeviceData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Gender Wise Count",

                },

                data: [
                    {
                        type: "pie",
                        startAngle: 75,
                        toolTipContent: "<b>{label}</b>: {y}",
                        showInLegend: "true",
                        legendText: "{label}",
                        indexLabelFontSize: 16,
                        indexLabel: "{label} - {y}",
                        dataPoints: res.Incomplete_gender_application
                    }]
            }
            callBack(loginDeviceData)
        })
}

export const getApplicationAnalysisLoginMode = (userData, callBack) => {

    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginDeviceData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Login Mode Count",

                },

                data: [{
                    type: "pie",
                    startAngle: 75,
                    toolTipContent: "<b>{label}</b>: {y}",
                    showInLegend: "true",
                    legendText: "{label}",
                    indexLabelFontSize: 16,
                    indexLabel: "{label} - {y}",
                    dataPoints: res.Complete_login_mode_count
                },

                ]
            }
            callBack(loginDeviceData)
        })
}

export const getApplicationAnalysisLoginModeincomplete = (userData, callBack) => {

    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginDeviceData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Login Mode Count",

                },
                data: [
                    {
                        type: "pie",
                        startAngle: 75,
                        toolTipContent: "<b>{label}</b>: {y}",
                        showInLegend: "true",
                        legendText: "{label}",
                        indexLabelFontSize: 16,
                        indexLabel: "{label} - {y}",
                        dataPoints: res.Incomplete_login_mode_count
                    }
                ]
            }
            callBack(loginDeviceData)
        })
}

export const getEventDataMethod = (userData, callBack) => {
    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url).then(res => res.json())
        .then(res => {
            let eventData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Device Wise Count",

                },

                data: [
                    {
                        type: "pie",
                        startAngle: 75,
                        toolTipContent: "<b>{label}</b>: {y}",
                        showInLegend: "true",
                        legendText: "{label}",
                        indexLabelFontSize: 16,
                        indexLabel: "{label} - {y}",
                        dataPoints: res.Complete_device_count
                    }

                ]
            }
            callBack(eventData)
        })
}
export const getEventDataMethodincomplete = (userData, callBack) => {
    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let eventData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Device Wise Count",

                },

                data: [
                    {

                        type: "pie",
                        startAngle: 75,
                        toolTipContent: "<b>{label}</b>: {y}",
                        showInLegend: "true",
                        legendText: "{label}",
                        indexLabelFontSize: 16,
                        indexLabel: "{label} - {y}",
                        dataPoints: res.Incomplete_device_count
                    }
                ]
            }
            callBack(eventData)
        })
}

export const getEdaDataMethod = (userData,callBack) => {
    var url = APIS.GET_FORM_STAGE_COUNT;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let formStatus = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                // height: 420,
                // width: 1106,
                animationEnabled: true,
                title: {
                    text: "Form Status",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    horizontalAlign: "left",
                    padding: 10,
                    margin: 15,
                    fontWeight: "bold"
                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                    shared: false
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc"
                },
                data: [{
                    type: "funnel",
                    reversed: true,
                    // click: e => graphClick(e),
                    showInLegend: true,
                    legendText: "{label}",
                    indexLabel: "{label} - {y}",
                    toolTipContent: "<b>{label}</b>: {y} ",
                    indexLabelFontColor: "black",
                    dataPoints: res.Total_user_login
                }]
            }
            callBack(formStatus)

        })
}

export const getOccupationdata = (userData, callBack) => {
    var url = APIS.GET_FORM_STAGE_COUNT;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let occupationData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Occupation Wise ",
                    fontColor: "#2f4f4f",
                    horizontalAlign: "left",
                    fontSize: 18,
                    padding: 10,
                    margin: 15,
                    fontWeight: "bold"
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                data: [{
                    type: "doughnut",
                    innerRadius: 60,
                    startAngle: 75,
                    toolTipContent: "<b>{label}</b>: {y}",
                    showInLegend: "true",
                    legendText: "{label}",
                    indexLabelFontSize: 16,
                    indexLabel: "{label} - {y}",
                    dataPoints: res.Total_Occupation_status
                }]
            }
            callBack(occupationData)
        })
}


export const getDocumentdata = (userData, callBack) => {
    var url = APIS.GET_FORM_STAGE_COUNT;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let documentData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Document Wise",
                    fontColor: "#2f4f4f",
                    horizontalAlign: "left",
                    fontSize: 18,
                    padding: 10,
                    margin: 15,
                    fontWeight: "bold"
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                data: [{
                    type: "doughnut",
                    startAngle: 75,
                    innerRadius: 60,
                    toolTipContent: "<b>{label}</b>: {y}",
                    showInLegend: "true",
                    legendText: "{label}",
                    indexLabelFontSize: 16,
                    indexLabel: "{label} - {y}",
                    dataPoints: res.Document_status
                }]
            }
            callBack(documentData)
        })
}

export const getAddressdata = (userData, callBack) => {
    var url = APIS.GET_FORM_STAGE_COUNT;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let addressData = {
                colorSet: "colorSetNew",
                height: 330,
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Address Wise",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    horizontalAlign: "left",
                    padding: 10,
                    margin: 15,
                    fontWeight: "bold"
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc"
                },
                data: [{
                    type: "doughnut",
                    startAngle: 75,
                    innerRadius: 60,
                    toolTipContent: "<b>{label}</b>: {y}",
                    showInLegend: "true",
                    legendText: "{label}",
                    indexLabelFontSize: 16,
                    indexLabel: "{label} - {y}",
                    dataPoints: res.current_user_address
                }]
            }
            callBack(addressData)
        })
}

export const getProfileAnalysisData = (userData, callBack) => {
    var url = APIS.GET_PROFILE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Gender Wise Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Complete",
                        showInLegend: true,
                        dataPoints: res.Complete_doc_gender
                    }, {
                        markerBorderColor: "white",
                        markerBorderThickness: 20,
                        type: "column",
                        name: "Incomplete",
                        legendText: "Incomplete",
                        showInLegend: true,
                        dataPoints: res.Incomplete_complete_doc_gender
                    },
                    {
                        type: "column",
                        markerBorderColor: "white",
                        markerBorderThickness: 2,
                        name: "Partial Complete",
                        legendText: "Partial Complete",
                        showInLegend: true,
                        dataPoints: res.Partial_complete_doc_gender
                    }]
            }
            callBack(loginTypeData)
        })
}

export const getLoanAnalysisData = (userData, callBack) => {
    var url = APIS.GET_LOAN_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Loan Approved Amount Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Loan Approved Amount",
                        showInLegend: true,
                        dataPoints: res.Approved_loan_amounts
                    }]
            }
            callBack(loginTypeData)
        })
}

export const overdueAnalysis = (userData, callBack) => {
    var url = APIS.GET_OVERDUE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Overdue Days",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        colorSet: "colorSetNew",
                        type: "column",
                        name: "Overdue Days",
                        showInLegend: true,
                        dataPoints: res.overdue_days
                    }]
            }
            callBack(loginTypeData)
        })
}

export const getLoanOverdueSalary = (userData, callBack) => {
    var url = APIS.GET_OVERDUE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                // height: 420,
                // width: 1106,
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Overdue Salary",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Overdue Salary",
                        showInLegend: true,
                        dataPoints: res.overdue_salary
                    }]
            }
            callBack(loginTypeData)
        })
}

export const getLoanOverDueData = (userData, callBack) => {
    var url = APIS.GET_OVERDUE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                // height: 420,
                // width: 1106,
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Overdue Approve Loan Amount",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Overdue Approve Loan Amount",
                        showInLegend: true,
                        dataPoints: res.overdue_approve_loan_amount
                    }]
            }
            callBack(loginTypeData)
        })
}


export const getLoanOverDueTenureData = (userData, callBack) => {
    var url = APIS.GET_OVERDUE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                // height: 420,
                // width: 1106,
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Tenure Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Tenure Count",
                        showInLegend: true,
                        dataPoints: res.Tenure_count
                    }]
            }
            callBack(loginTypeData)
        })
}


export const getLoanStatusData = (userData, callBack) => {
    var url = APIS.GET_LOAN_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Loan Status Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Loan Status count",
                        showInLegend: true,
                        dataPoints: res.loan_status_count
                    }]
            }
            callBack(loginTypeData)
        })
}

export const getLoanRejectData = (userData, callBack) => {
    var url = APIS.GET_LOAN_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Loan Reject Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        markerSize: 25,
                        type: "pie",
                        name: "Loan Reject count",
                        indexLabel: "{label} {y}",
                        // showInLegend: true,
                        dataPoints: res.reject_reason_count
                    }]
            }
            callBack(loginTypeData)
        })
}

export const getProfileAnalysisSourceOfInformation = (userData, callBack) => {
    var url = APIS.GET_PROFILE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Platform Wise Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Complete",
                        showInLegend: true,
                        dataPoints: res.Complete_doc_plateform
                    }, {
                        markerBorderColor: "white",
                        markerBorderThickness: 20,
                        type: "column",
                        name: "Incomplete",
                        legendText: "Incomplete",
                        showInLegend: true,
                        dataPoints: res.Incomplete_Complete_doc_plateform
                    },
                    {
                        type: "column",
                        markerBorderColor: "white",
                        markerBorderThickness: 2,
                        name: "Partial Complete",
                        legendText: "Partial Complete",
                        showInLegend: true,
                        dataPoints: res.Partial_Complete_doc_plateform
                    }]
            }
            callBack(loginTypeData)
        })
}

export const getLoanAmountProfileData = (userData, callBack) => {
    var url = APIS.GET_PROFILE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                // height: 420,
                // width: 1106,
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Loan Wise Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Complete",
                        showInLegend: true,
                        dataPoints: res.complete_doc_loan_amounts
                    }, {
                        markerBorderColor: "white",
                        markerBorderThickness: 20,
                        type: "column",
                        name: "Incomplete",
                        legendText: "Incomplete",
                        showInLegend: true,
                        dataPoints: res.Incomplete_doc_loan_amounts
                    },
                    {
                        type: "column",
                        markerBorderColor: "white",
                        markerBorderThickness: 2,
                        name: "Partial Complete",
                        legendText: "Partial Complete",
                        showInLegend: true,
                        dataPoints: res.Partial_complete_doc_loan_amounts
                    }]
            }
            callBack(loginTypeData)
        })
}

export const getStateCountData = (userData, callBack) => {
    var url = APIS.GET_PROFILE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                // height: 420,
                // width: 1106,
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "State Wise Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Complete",
                        showInLegend: true,
                        dataPoints: res.Complete_doc_state
                    }, {
                        markerBorderColor: "white",
                        markerBorderThickness: 20,
                        type: "column",
                        name: "Incomplete",
                        legendText: "Incomplete",
                        showInLegend: true,
                        dataPoints: res.Incomplete_doc_state
                    },
                    {
                        type: "column",
                        markerBorderColor: "white",
                        markerBorderThickness: 2,
                        name: "Partial Complete",
                        legendText: "Partial Complete",
                        showInLegend: true,
                        dataPoints: res.Partial_complete_doc_state
                    }]
            }
            callBack(loginTypeData)
        })
}
export const getSalaryData = (userData, callBack) => {
    var url = APIS.GET_PROFILE_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                // height: 420,
                // width: 1106,
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Salary Wise",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Complete",
                        showInLegend: true,
                        dataPoints: res.complete_doc_salary_amounts
                    }, {
                        markerBorderColor: "white",
                        markerBorderThickness: 20,
                        type: "column",
                        name: "Incomplete",
                        legendText: "Incomplete",
                        showInLegend: true,
                        dataPoints: res.Incomplete_doc_salary_amounts
                    },
                    {
                        type: "column",
                        markerBorderColor: "white",
                        markerBorderThickness: 2,
                        name: "Partial Complete",
                        legendText: "Partial Complete",
                        showInLegend: true,
                        dataPoints: res.Partial_complete_doc_salary_amounts
                    }]
            }
            callBack(loginTypeData)
        })
}

export const getLoginTypedata = (userData, callBack) => {
    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Source of Information",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [
                    {
                        type: "column",
                        name: "Incomplete",
                        legendText: "Incomplete",
                        showInLegend: true,
                        dataPoints: res.Complete_source_of_information
                    }
                ]
            }
            callBack(loginTypeData)
        })
}

export const getLoginTypedataincomplete = (userData, callBack) => {
    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let loginTypeData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Source of Information",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",

                },

                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                dataPointMaxWidth: 60,
                data: [

                    {
                        type: "column",
                        name: "Incomplete",
                        legendText: "Incomplete",
                        showInLegend: true,
                        dataPoints: res.Incomplete_source_of_information
                    }
                ]
            }
            callBack(loginTypeData)
        })
}

export const applicationStatusdata = (userData, callBack) => {
    var url = APIS.GET_APPLICATION_ANALYSIS_DATA;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let applicationData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                // height: 420,
                // width: 1106,
                animationEnabled: true,
                title: {
                    text: "Device Type",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    horizontalAlign: "left",
                    padding: 10,
                    margin: 15,
                    fontWeight: "bold"
                },
                toolTip: {
                    shared: true
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",
                },
                data: [
                    {
                        type: "column",
                        name: "Complete",
                        showInLegend: true,
                        dataPoints: res.Complete_device_type
                    }, {
                        markerBorderColor: "white",
                        markerBorderThickness: 20,
                        type: "column",
                        name: "Incomplete",
                        legendText: "Incomplete",
                        showInLegend: true,
                        dataPoints: res.Incomplete_device_type
                    },
                    {
                        type: "spline",
                        markerBorderColor: "white",
                        markerBorderThickness: 2,
                        name: "Total Device Count",
                        legendText: "Total Device Count",
                        showInLegend: true,
                        dataPoints: res.Total_device_type
                    }

                ]
            }
            callBack(applicationData)
        })
}
export const formStageCount = (userData, callBack) => {
    var url = APIS.GET_FORM_STAGE_COUNT;
    if (userData != null) {
        if (userData.startDate != null) {
            url = url + "?start=" + userData.startDate;
        }
        if (userData.dateFilterType != null) {
            url = url + "&state=" + userData.dateFilterType;
        }

        if (userData.endDate != null) {
            url = url + "&end=" + userData.endDate
        }
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let applicationData = {
                animationDuration: 2000,
                // height: 420,
                // width: 1106,
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "Form Stage Count",
                    fontColor: "#2f4f4f",
                    fontSize: 18,
                    horizontalAlign: "left",
                    padding: 10,
                    margin: 15,
                    fontWeight: "bold"
                },
                toolTip: {
                    shared: true
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",
                },
                data: [
                    {
                        type: "funnel",
                        reversed: true,
                        // click: e => graphClick(e),
                        showInLegend: true,
                        legendText: "{label}",
                        indexLabel: "{label} - {y}",
                        toolTipContent: "<b>{label}</b>: {y} ",
                        indexLabelFontColor: "black",
                        dataPoints: res.Total_user_login
                    }]
            }
            callBack(applicationData)
        })
}



export const getFilterUsingDate = (graphFilterData, graphtype, callBack) => {
    let url;
    if (graphtype == 'incomplete Login Type') {
        getLoginTypedataincomplete(graphFilterData, callBack)
    } else if (graphtype == 'incompleteloginModeData') {
        getApplicationAnalysisLoginModeincomplete(graphFilterData, callBack)
    } else if (graphtype == 'incompleteEvents') {
        getEventDataMethodincomplete(graphFilterData, callBack)
    } else if (graphtype == 'incompletegender') {
        getLoginDeviceincompleteDataMethod(graphFilterData, callBack)
    } else if (graphtype == 'Application') {
        getLoginDeviceDataMethod(graphFilterData, callBack);

    } else if (graphtype == 'Events') {
        getEventDataMethod(graphFilterData, callBack)
    } else if (graphtype == 'FormStage') {
        getEdaDataMethod(graphFilterData, callBack)
    } else if (graphtype == 'Gender Wise') {
        getLoginDeviceincompleteDataMethod(graphFilterData, callBack)
    } else if (graphtype == 'Login Type') {
        getLoginTypedata(graphFilterData, callBack)
    } else if (graphtype == 'Loan Data') {
        // getLoanAmountData(graphFilterData, callBack)
    } else if (graphtype == 'Application Status') {
        applicationStatusdata(graphFilterData, callBack)
    } else if (graphtype == 'profileAnalysis') {
        getProfileAnalysisData(graphFilterData, callBack)
    } else if (graphtype == 'loginmode') {
        getLoginModeCount(graphFilterData, callBack)
    } else if (graphtype == 'Source') {
        getProfileAnalysisSourceOfInformation(graphFilterData, callBack)
    } else if (graphtype == 'State Count') {
        getStateCountData(graphFilterData, callBack)
    } else if (graphtype == 'salary') {
        getSalaryData(graphFilterData, callBack)

    } else if (graphtype == 'loanAmount') {
        getLoanAmountProfileData(graphFilterData, callBack)
    } else if (graphtype == 'apprrovedamt') {
        getLoanAnalysisData(graphFilterData, callBack)
    } else if (graphtype == 'overduesalary') {
        getLoanOverdueSalary(graphFilterData, callBack)
    } else if (graphtype == 'overdueapprove') {
        getLoanOverDueData(graphFilterData, callBack)
    } else if (graphtype == 'overduedays') {
        overdueAnalysis(graphFilterData, callBack)
    } else if (graphtype == 'tenurecount') {
        getLoanOverDueTenureData(graphFilterData, callBack)
    } else if (graphtype == 'loginModeData') {
        getApplicationAnalysisLoginMode(graphFilterData, callBack)
    } else if (graphtype == 'statusamt') {
        getLoanStatusData(graphFilterData, callBack)
    } else if (graphtype == 'rejectdata') {
        getLoanRejectData(graphFilterData, callBack)
    }
    fetch(url)
        .then(res => res.json())
        .then(res => {
            let eventData = {
                colorSet: "colorSetNew",
                animationDuration: 2000,
                animationEnabled: true,
                title: {
                    text: "",
                    // fontColor: "#2f4f4f",
                    fontSize: 18,
                    padding: 10,
                    horizontalAlign: "left",
                    margin: 15,
                    fontWeight: "bold"
                },
                toolTip: {
                    shared: true

                },
                legend: {
                    verticalAlign: "top",
                    cursor: "pointer",
                },
                axisY: {
                    gridDashType: "dot",
                    gridColor: "#ccc",
                    interval: 40000
                },
                dataPointMaxWidth: 50,
                data: [
                    // {
                    //     type: "column",
                    //     name: "Complete",
                    //     showInLegend: true,
                    //     dataPoints: res.Complete_device_count
                    // }, {
                    //     markerBorderColor: "white",
                    //     markerBorderThickness: 20,
                    //     type: "column",
                    //     name: "Incomplete",
                    //     legendText: "Incomplete",
                    //     showInLegend: true,
                    //     dataPoints: res.Incomplete_device_count
                    // },
                    // {
                    //     type: "spline",
                    //     markerBorderColor: "white",
                    //     markerBorderThickness: 2,
                    //     name: "Total Device Count",
                    //     legendText: "Total Device Count",
                    //     showInLegend: true,
                    //     dataPoints: res.Total_device_count
                ]
            }


            callBack(eventData)
        })
}
