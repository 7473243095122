import React from 'react';
import Slider from 'react-rangeslider';
import DatePicker from 'react-datepicker';
import moment from 'moment'
const Calculator = ({ OnChange, OnClickHandler, monthArray,
    calculatedData, totalAmount, emiLoader, AmountLabel, MonthsLabel, SalaryLabel, slectMonths, minimumAmount, maximumAmount, dateForTable,
    MonthMax, emiCalcData }) => (
    <div className="container-fluid relative">
        <div className="white-bg phocket-calc-main top-bottom-10px border-radius-10px">
            <div className="row phocket_border_blue margin-0 calc-main-row relative_position">
                {emiLoader ?
                    <div className="emi-loader">
                        <span className="fa fa-spinner fa-spin" />
                    </div>
                    : null}
                <div className="col-xs-12 phocket-calc-header">
                    Loan EMI Calculator
                    </div>
                <div className="col-sm-6 col-xs-12 calc-content">
                <div className="col-xs-12">
                            <h4>Salary Date</h4>
                        <DatePicker
                            value={emiCalcData.salaryDate}
                            onChange={(e) => OnChange(e, 'salary', '')}
                            className="form-control"
                            placeholderText="select Date"
                            dateFormat="DD-MM-YYYY"
                            // minDate={moment().toDate()}
                            maxDetail='month'

                        />
                        </div>
                    <div className="col-xs-6">
                        <h4>Months</h4>
                    </div>
                    <div className="col-xs-6"><span className="loan-variables">{emiCalcData.EMIMonths} Months</span></div>
                    <div className="col-xs-12" style={{ paddingRight: '24px' }}>
                        {/* <div class="btn-group-tab"><button class="">1</button><button class="">2</button><button class="active">3</button><button class="">6</button><button class="">12</button></div> */}
                        <div className="">
                            {/* <label>Select Emi</label>
                            <select className='form-control' onChange={(e) => slectMonths(e)}>
                                <option value=''>Select Emi</option>
                                <option value='1'>1 EMI</option>
                                <option value='2'>2 EMI</option>
                                <option value='3'>3 EMI</option>
                                <option value='6'>6 EMI</option>
                                <option value='12'>12 EMI</option>

                            </select> */}
                            <Slider
                                value={emiCalcData.EMIMonths}
                                orientation="horizontal"
                                min={1}
                                max={MonthMax}
                                step={1}
                                labels={MonthsLabel}
                                onChange={(e) => slectMonths(e)}
                                tooltip={true}
                            />
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <h4>Amount</h4>
                    </div>
                    <div className="col-xs-6"><span className="loan-variables"><span className="fa fa-inr" />&nbsp;{emiCalcData.amount}</span></div>
                    <div className="col-xs-12" style={{ paddingRight: '24px' }}>
                        <div className="phocket-slider">
                            <Slider
                                value={emiCalcData.amount}
                                orientation="horizontal"
                                min={minimumAmount}
                                max={maximumAmount}
                                step={(emiCalcData.EMIMonths == 12 || emiCalcData.EMIMonths == 6) && emiCalcData.amount <= 100000 ? 1000 : (emiCalcData.EMIMonths == 12 || emiCalcData.EMIMonths == 6) && emiCalcData.amount > 100000 ? 10000 : 1000}
                                labels={AmountLabel}
                                onChange={e => OnChange(e, 'amount', '')}
                                tooltip={true}
                            />
                        </div>
                    </div>

                    {/* <div className="col-xs-6">
                            <h4>Months</h4>
                        </div>
                        <div className="col-xs-6"><span className="loan-variables">{emiCalcData.EMIMonths} Months</span></div>
                        <div className="col-xs-12" style={{paddingRight:'24px'}}>
                            <div className="phocket-slider">
                                
                                <Slider
                                    value={emiCalcData.EMIMonths}
                                    orientation="horizontal"
                                    min={1}
                                    max={MonthMax}
                                    step={1}
                                    labels={MonthsLabel}
                                    onChange={e => OnChange(e, 'month', '')}
                                    tooltip={true}
                                />
                            </div>
                        </div> */}

                    {/* <div className="col-xs-6">
                            <h4>Salary Date</h4>
                        </div>
                        <div className="col-xs-6"><span className="loan-variables">{emiCalcData.salaryDate}</span></div> */}
                        <div className="col-xs-12">
                            {/* <h4>Salary Date</h4>
                        <DatePicker
                            value={emiCalcData.salaryDate}
                            onChange={(e) => OnChange(e, 'salary', '')}
                            className="form-control"
                            placeholderText="select Date"
                            dateFormat="DD-MM-YYYY"
                        /> */}
                            {/* <div className="phocket-slider">
                                <Slider
                                    value={emiCalcData.salaryDate}
                                    orientation="horizontal"
                                    min={1}
                                    max={31}
                                    step={1}
                                    labels={SalaryLabel}
                                    onChange={e => OnChange(e, 'salary', '')}
                                    tooltip={true}
                                />
                            </div> */}
                        </div>
                    <div className="col-xs-12">
                        <h4>Disburse Date</h4>
                    </div>
                    <div className="col-xs-12">
                        <DatePicker
                            value={emiCalcData.disburseDateToSend}
                            onChange={(e) => OnChange(e, 'disburseDate', '')}
                            className="form-control"
                            placeholderText="select Date"
                            dateFormat="DD-MM-YYYY"
                        />
                    </div>
                    {monthArray.length > 0 ?
                        monthArray.map((val, i) => {
                            return (
                                <div key={i}>
                                    <div className="col-xs-12">
                                        <h4>Repayment Date {val}</h4>
                                    </div>
                                    <div className="col-xs-12">
                                        <DatePicker
                                        
                                            value={emiCalcData['repaymentDateToSend' + i]}
                                            selected={emiCalcData['repaymentDate' + i]}
                                            onChange={(e) => OnChange(e, 'repaymentDate', i)}
                                            className="form-control"
                                            placeholderText="select Date"
                                            dateFormat="dd-mm-yyyy"
                                            isClearable
                                            id={"repaymentDate" + val}
                                        />
                                          {/* <DatePicker style={{cursor:'pointer'}} className="form-control"  placeholderText='Change-Repayment-date'  onChange={(e)=>OnChange(e, 'repaymentDate', i)} /> */}
                                    </div>
                                </div>
                            )
                        })
                        : null}
                    <div className="text-center col-xs-12">
                        <button className="btn profile-save-btn" onClick={e => OnClickHandler()}><span className="fa fa-calculator" />&nbsp;&nbsp;CALCULATE</button>
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <div className="calc-final-amount"><p>You Pay </p>
                        <p><span className="fa fa-inr"></span>&nbsp;<span className="orange-text">{totalAmount}</span></p>
                    </div>
                    {calculatedData.length > 0 ?
                        <div>
                            <h4 className="text-center blue-text">Processing Fees = {calculatedData[0].PROCESSINGFEE}</h4>
                            <h4 className="text-center blue-text">Net amount you will get = {calculatedData[0].NETAMOUNT}</h4>
                            <h4 className="text-center blue-text">Tenure = {calculatedData[0].DAYS}</h4>
                        </div>
                        : null}
                    <h3 className="text-center blue-text text-center">Calculation</h3>
                    <div style={{display:'flex'}}>
                    {calculatedData != '' && calculatedData.length > 0 ?
                        <table className="text-center loan-detail-table">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {calculatedData.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{data.PAYABLEAMOUNT}</td>
                                            {/* <td>{data.Date}</td> */}
                                            {/* <td>{emiCalcData['repaymentDateToSend' + monthArray[i]]}</td> */}


                                        </tr>

                                    )
                                })}

                            </tbody>
                        </table>
                        : null}
                    {calculatedData != '' && calculatedData.length > 0 ?
                        <table className="text-center loan-detail-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dateForTable.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{data}</td>




                                        </tr>

                                    )
                                })}

                            </tbody>
                        </table>
                        : null}

                </div>
                </div>
            </div>
        </div>
    </div>
)

export default Calculator;