import React, { Component } from 'react';
import FieldBoyUi from '../../../presentationals/Admin/CustomerPages/AssignFieldBoy.component'
import { APIS } from '../../../../utils/api-factory'
import { assignApplicationApi, updateAssignedApplicationApi, userDocsByFieldBoy } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import moment from 'moment';
import { TYPE } from '../../../../utils/Constant';

let interval;

class FieldBoy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            employeeData: '',
            allDetail: this.props.allDetail,
            assignedData: '',
            progressBarState: false,
            progressValue: 20,
            docType: '',
            fieldBoyData: {
                boyNameState: '',
                addressState: '',
                timeFrom: moment(new Date()),
                timeTo: moment(new Date()),
                ChequeState: 'No File Chosen',
                LocationState: 'No File Chosen',
                idProofState: 'No File Chosen'
            },
        }
    }

    componentWillMount() {
        fetch(APIS.GET_FIELDBOY_DETAIL)
            .then(res => res.json())
            .then(json => {
                this.setState({ employeeData: json })
            })
        this.assignData()
    }

    assignData(){
        fetch(APIS.ASSIGNED_APPLICATIONS + this.state.allDetail.loanId)
        .then(res => res.json())
        .then(json => {
            this.setState({ assignedData: json })
        })
    }

    render() {
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <FieldBoyUi
                    fieldBoyData={this.state.fieldBoyData}
                    employeeData={this.state.employeeData}
                    fieldBoyChange={this.fieldBoyChange.bind(this)}
                    fieldBoyHandler={this.fieldBoyHandler.bind(this)}
                    assignedData={this.state.assignedData}
                    AddressHandler={this.AddressHandler.bind(this)}
                    updateData={this.updateData.bind(this)}
                    progressBarState={this.state.progressBarState}
                    docType={this.state.docType}
                    progressValue={this.state.progressValue}
                />
            </div>
        )
    }

    setInterval1() {
        interval = setInterval(function () {
            this.setState({
                progressValue: this.state.progressValue + 5
            })
        }.bind(this), 500)
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    fieldBoyChange(e, info) {
        const { admin } = this.props
        let fieldBoyData = Object.assign({}, this.state.fieldBoyData);
        if (info == "selectBoy") {
            fieldBoyData.boyNameState = e.target.value
        } else if (info == "address") {
            fieldBoyData.addressState = e.target.value
        } else if (info == "timeFrom") {
            fieldBoyData.timeFrom = e
        } else if (info == "timeTo") {
            fieldBoyData.timeTo = e
        } else {
            let valt = e.target.value;
            let val = valt.split(/[\\\/]/)[2];
            this.setInterval1();
            let File = e.target.files[0];
            if (info === TYPE.CHEQUE) {
                fieldBoyData.ChequeState = val;
            } else if (info === TYPE.LOCATION) {
                fieldBoyData.LocationState = val;
            } else if (info === TYPE.OFFICE_ID) {
                fieldBoyData.idProofState = val;
            }
            userDocsByFieldBoy(File, info, admin.emailId, this.state.allDetail.userId, this.state.allDetail.loanId,
                (callBack) => {
                    this.setState({
                        progressBarState: false,
                        fieldBoyData
                    })
                    this.assignData()
                })
            this.setState({
                progressBarState: true,
                docType: info,
                progressValue: 20
            })
        }
        this.setState({
            fieldBoyData
        })
    }

    updateData(data) {
        let fieldBoyData = Object.assign({}, this.state.fieldBoyData);
        fieldBoyData.boyNameState = data.empid
        fieldBoyData.addressState = data.address
        fieldBoyData.timeFrom = moment(data.timeSlot.split(' - ')[0], "HH:mm")
        fieldBoyData.timeTo = moment(data.timeSlot.split(' - ')[1], "HH:mm")
        this.setState({
            fieldBoyData
        })
    }

    AddressHandler(type) {
        let fieldBoyData = Object.assign({}, this.state.fieldBoyData);
        if (type == "permanent") {
            fieldBoyData.addressState = this.state.allDetail.addressDetailsPOList.permanentAddress + ', ' + this.state.allDetail.addressDetailsPOList.permanentCity + ', ' + this.state.allDetail.addressDetailsPOList.permanentState + ', ' + this.state.allDetail.addressDetailsPOList.permanentPincode
        } else if (type == "current") {
            fieldBoyData.addressState = this.state.allDetail.addressDetailsPOList.currentAddress + ', ' + this.state.allDetail.addressDetailsPOList.currentCity + ', ' + this.state.allDetail.addressDetailsPOList.currentState + ', ' + this.state.allDetail.addressDetailsPOList.currentPincode
        } else if (type == "office") {
            fieldBoyData.addressState = this.state.allDetail.employmentDetailsPO.officeAddress
        }
        this.setState({
            fieldBoyData
        })
    }

    fieldBoyHandler(type) {
        const { admin } = this.props
        if (type == 'save') {
            if (this.state.fieldBoyData.boyNameState != '') {
                assignApplicationApi(this.state.fieldBoyData, admin, this.state.allDetail,
                    (callBack) => {
                        if (callBack == 'success') {
                            this.setState({
                                fieldBoyData: {
                                    boyNameState: '',
                                    addressState: '',
                                    timeFrom: '',
                                    timeTo: ''
                                },
                                popupState: true,
                                popupStatus: 'Assigned Successfully'
                            })
                            this.removePopup()
                            fetch(APIS.ASSIGNED_APPLICATIONS + this.state.allDetail.loanId)
                                .then(res => res.json())
                                .then(json => {
                                    this.setState({ assignedData: json })
                                })
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again'
                            })
                            this.removePopup()
                        }
                    })
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'Please Select'
                })
                this.removePopup()
            }
        } else if (type == 'update') {
            if (this.state.fieldBoyData.boyNameState != '') {
                updateAssignedApplicationApi(this.state.fieldBoyData, admin, this.state.allDetail,
                    (callBack) => {
                        if (callBack == 'success') {
                            this.setState({
                                fieldBoyData: {
                                    boyNameState: '',
                                    addressState: '',
                                    timeFrom: '',
                                    timeTo: ''
                                },
                                popupState: true,
                                popupStatus: 'Updated Successfully'
                            })
                            this.removePopup()
                            fetch(APIS.ASSIGNED_APPLICATIONS + this.state.allDetail.loanId)
                                .then(res => res.json())
                                .then(json => {
                                    this.setState({ assignedData: json })
                                })
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again'
                            })
                            this.removePopup()
                        }
                    })
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'Please Select'
                })
                this.removePopup()
            }
        }
    }

    componentWillUnmount() {
        clearInterval(interval);
    }

}

export default FieldBoy;