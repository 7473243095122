import React, { Component } from 'react';
import RecoveryMailUi from '../../../presentationals/Admin/Recovery/RecoveryMail.component';
import Popup from '../../../presentationals/Popup/Popup.component';
import { sendRecoveryMailAPI } from '../AdminActionCreator.component'

class RecoveryMailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            subject: '',
            mailViewState: ''
        }
    }

    componentWillMount() {

    }

    render() {
        const { dataForCommunication, admin, collectionType } = this.props;
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <RecoveryMailUi
                    recoveryMailChange={this.recoveryMailChange.bind(this)}
                    recoveryMailHandler={this.recoveryMailHandler.bind(this)}
                    mailTempleteClick={this.mailTempleteClick.bind(this)}
                    allDetail={dataForCommunication}
                    subject={this.state.subject}
                    collectionType={collectionType}
                    mailViewState={this.state.mailViewState}
                />
            </div>
        )
    }

    mailTempleteClick(e, mailType) {
        this.setState({ mailViewState: mailType, subject: e.target.value })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }


    recoveryMailChange(e, mailType) {
        if (mailType == 'subject') {
            this.setState({ subject: e.target.value })
        }
    }

    recoveryMailHandler() {
        const { dataForCommunication, admin } = this.props;
        var mailBody = document.getElementById("recoveryMailBody").innerHTML
        if (this.state.subject != '') {
            sendRecoveryMailAPI(this.state.subject, dataForCommunication, mailBody, admin,'',
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Mail Sent Successfully',
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please Try Again',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please Type Subject and Body first',
            })
            this.removePopup();
        }
    }

}

export default RecoveryMailPage;