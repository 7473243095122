import React from 'react'

const Profile = ({ falconUser, userDetailsByUserId, addressData, bankData, addFalconUser }) => (
    <div className='container-fluid '>
        <div className='row' style={{ marginTop: falconUser ? '' : '30px' }} >
            {falconUser === false ?
                <div className='text-right'>
                    <button className='profile-save-btn' onClick={(e) => addFalconUser(e)}>Add User</button>
                </div>
                : ""}
            <div className='col-sm-6 col-xs-12' >
                <div className="profile-details-box-new" style={{ minHeight: "400px", marginTop: '40px' }}>
                    <div className="new-basic-head" >Personal Information</div>

                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>First Name</b>
                                <span style={{ float: 'right', fontWeight: '700' }}> {userDetailsByUserId.firstName}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}> Last Name</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{userDetailsByUserId.lastName}</span></div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Gender</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{userDetailsByUserId.gender}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Mobile Number</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{userDetailsByUserId.mobileNumber}</span></div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Date Of Birth</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{userDetailsByUserId.dateOfBirth}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>PAN Number</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{userDetailsByUserId.panNo}</span></div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Aadhaar Number</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{userDetailsByUserId.aadhaarNo}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Email</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{userDetailsByUserId.email}</span></div>
                        </div>
                    </div>
                    <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Bank Details</h4>
                    <div className='row'>

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Account Number</b>
                                <span style={{ float: 'right', fontWeight: '700' }}> {bankData.accountNo}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }} >
                                <b style={{ color: 'gray' }}>IFSC Code</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{bankData.ifscCode}</span></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Name On Bank</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{bankData.nameOnBank}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-6 col-xs-12'>
                <div className="profile-details-box-new" style={{ minHeight: "400px", marginTop: '40px' }}>
                    <div className="new-basic-head" >KYC Address Details</div>
                    {/* <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>KYC Address Details</h4> */}


                    <div className='row'>
                        {/* <div className='col-sm-6 col-xs-12'>
                            <div className='' style={{ marginTop: '30px' }}>
                                <div style={{marginLeft:'10px',marginRight:'10px'}}>
                                    <b style={{ color: 'gray' }}>Address 1</b>
                                    <span style={{ float: 'right', fontWeight:'700' }}> {addressData.address1}</span></div>
                            </div>
                        </div> */}
                        <div className='col-sm-12 col-xs-12'>
                            <div className='' style={{ marginTop: '30px' }}>
                                <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <b style={{ color: 'gray' }}>Address</b>
                                    <span style={{ float: 'right', fontWeight: '700' }}> {addressData.address1 + ', ' + addressData.address2 + ', ' + addressData.address3}</span></div>
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        {/* <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{marginLeft:'10px',marginRight:'10px'}}>
                                <b style={{ color: 'gray' }}>Address 3</b>
                                <span style={{ float: 'right', fontWeight:'700' }}>{addressData.address3}</span></div>
                        </div> */}
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>City</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{addressData.city}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>State</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{addressData.state}</span></div>
                        </div>
                    </div>
                    <div className='row'>
                        {/* <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{marginLeft:'10px',marginRight:'10px'}}>
                                <b style={{ color: 'gray' }}>State</b>
                                <span style={{ float: 'right', fontWeight:'700' }}>{addressData.state}</span></div>
                        </div> */}
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Pincode</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{addressData.pinCode}</span></div>
                        </div>
                    </div>
                    <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Delivery Address Details</h4>

                    <div className='row'>
                        <div className='col-sm-12 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Delivery Address</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{addressData.deliveryAddress1 + ', ' + addressData.deliveryAddress1 + ', ' + addressData.deliveryAddress3}</span></div>
                        </div>
                        {/* <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{marginLeft:'10px',marginRight:'10px'}}>
                                <b style={{ color: 'gray' }}>Delivery Address 1</b>
                                <span style={{ float: 'right', fontWeight:'700' }}>{addressData.deliveryAddress1}</span></div>
                        </div> */}
                    </div>
                    <div className='row'>
                        {/* <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{marginLeft:'10px',marginRight:'10px'}}>
                                <b style={{ color: 'gray' }}>Delivery Address 2</b>
                                <span style={{ float: 'right', fontWeight:'700' }}>{addressData.deliveryAddress2}</span></div>
                        </div> */}

                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Delivery City</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{addressData.deliveryCity}</span></div>
                        </div>
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Delivery State</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{addressData.deliveryState}</span></div>
                        </div>
                    </div>
                    <div className='row'>
                        {/* <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Delivery State</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{addressData.deliveryState}</span></div>
                        </div> */}
                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <b style={{ color: 'gray' }}>Delivery Pincode</b>
                                <span style={{ float: 'right', fontWeight: '700' }}>{addressData.deliveryPinCode}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
)


export default Profile