import React, { useState, useEffect } from 'react';
import MsgAnalysis from './alltrxn.component';
import DotLoader from '../../../presentationals/Loader/layerLoader.component';
import { APIS } from '../../../../utils/api-factory';

const paginationNumber = 5;

const Analysis = ({ allDetail }) => {
  const [selectedBankName, setSelectedBankName] = useState('');
  const [msgDataType, setMsgDataType] = useState('Credit');
  const [FilteredData, setFilteredData] = useState([]);
  const [pageIndexToShow, setPageIndexToShow] = useState(0);
  const [tableMinPagination, setTableMinPagination] = useState(0);
  const [tableMaxPagination, setTableMaxPagination] = useState(paginationNumber);
  const [tableMinPagination2, setTableMinPagination2] = useState(0);
  const [tableMaxPagination2, setTableMaxPagination2] = useState(paginationNumber);
  const [loadarState, setloadarState] = useState(false);
  const [msgData, setMessageData] = useState('')
  const [currentPage, setCurrentPage2] = React.useState(1);
  const [pageNumber, setpageNumber] = useState(1)
  const itemsPerPage = 10;

  useEffect(() => {

    setloadarState(true)
    fetch(APIS.USER_MSG_ANALYSIS + allDetail.userId)
      .then(res => res.json())
      .then(res => {
        setMessageData(res)
        setloadarState(false)

      })
  }, []);

  const tablePaginationNew = (type) => {
    if (type === 'back') {
      if (currentPage > 1) {
        setCurrentPage2(currentPage - 1);
      }
    } else if (type === 'forward') {
      if (currentPage < Math.ceil(msgData[msgDataType].length / itemsPerPage)) {
        setCurrentPage2(currentPage + 1);
      }
    }
  };


  const phocketTablePagination = (data, type) => {
    if (type === 'back') {
      if (tableMinPagination >= paginationNumber) {
        setTableMinPagination(tableMinPagination - paginationNumber);
        setTableMaxPagination(tableMaxPagination - paginationNumber);
      }
    } else if (type === 'forward') {
      if (tableMaxPagination < data.length) {
        setTableMaxPagination(tableMaxPagination + paginationNumber);
        setTableMinPagination(tableMinPagination + paginationNumber);
      }
    }
  };

  const tablePagination = (data, type) => {
    if (type === 'back') {
      if (tableMinPagination >= paginationNumber) {
        setTableMinPagination(tableMinPagination - paginationNumber);
        setTableMaxPagination(tableMaxPagination - paginationNumber);
      }
    } else if (type === 'forward') {
      if (tableMaxPagination < data.length) {
        setTableMaxPagination(tableMaxPagination + paginationNumber);
        setTableMinPagination(tableMinPagination + paginationNumber);
      }
    }
  };

  const getFilterValue = (filtered) => {
    setFilteredData(filtered);
  };

  const getPageValue = (pageIndex) => {
    setPageIndexToShow(pageIndex);
  };

  const bankNamesHandler = (e, name) => {
    document.getElementById(e.target.id).classList.add('active-msg');
    document.querySelectorAll('li:not(#' + e.target.id + ')').forEach((item) => {
      item.classList.remove('active-msg');
    });
    setSelectedBankName(name);
  };

  useEffect(() => {
    if (document.getElementById('messageCredit') != null)
      document.getElementById('messageCredit').classList.add('active-msg');
  }, []);

  const msgDataTypeHandler = (e, dataType) => {
    document.getElementById(e.target.id).classList.add('active-msg');
    document.querySelectorAll('button:not(#' + e.target.id + ')').forEach((item) => {
      item.classList.remove('active-msg');
    });
    setMsgDataType(dataType);
  };
  const handleChangeNewPagination = (e, value) => {
    setpageNumber(value)
    const newMinPagination = (value - 1) * paginationNumber;
    const newMaxPagination = value * paginationNumber;
    setTableMaxPagination2(newMaxPagination)
    setTableMinPagination2(newMinPagination)

  };
  return (
    <div>
      <MsgAnalysis
        handleChangeNewPagination={handleChangeNewPagination}
        pageNumber={pageNumber}
        loadarState={loadarState}
        tablePaginationNew={tablePaginationNew}
        tableMaxPagination2={tableMaxPagination2}
        tableMinPagination2={tableMinPagination2}
        msgData={msgData}
        bankNamesHandler={bankNamesHandler}
        selectedBankName={selectedBankName}
        msgDataTypeHandler={msgDataTypeHandler}
        msgDataType={msgDataType}
        getFilterValue={getFilterValue}
        FilteredData={FilteredData}
        getPageValue={getPageValue}
        pageIndexToShow={pageIndexToShow}
        phocketTablePagination={phocketTablePagination}
        tableMaxPagination={tableMaxPagination}
        tableMinPagination={tableMinPagination}
        tablePagination={tablePagination}
      />
    </div>
  );
};

export default Analysis;
