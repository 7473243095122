
import React, { Component } from 'react'
import UserBasic from '../../../../presentationals/Admin/CustomerPages/UserBasicCards.component'
import BadaBuisnessBasic from '../../../../presentationals/Admin/CustomerPages/badabuisnessbasicdetails.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { APIS } from '../../../../../utils/api-factory';
import Loader from '../../../../presentationals/Loader/Loader.component'
import CreditScore from '../UserCreditScoring.container'
import Popup from '../../../../presentationals/Popup/Popup.component';
import { _formatDateInDash } from '../../../../../utils/validation';
import { userReferenceApi, sendverificationRequestApi, getdeactivateNachByLoanId, panProfileDetailsCards, aadharVerificationDetails, aadharXmlDetails, postCallPanDetails, aadharXmlData, postCallDigilocker, postCallAdharVerification } from '../../AdminActionCreator.component'
import Confirmation from '../../../../presentationals/Popup/ConfirmationPopup.component'
import KarzaPOPUPUI from '../../../../presentationals/Popup/KarzaPopup.component'
import AAdharPopup from '../../../../presentationals/Popup/KarzaAddharPopup.component'
import {getBankDetailsByUserIdApi,getAddressDetailsByUserIdApi} from '../../../Admin/CardsUtility/actionCreator'

// import EditProfile from '../../../containers/Admin/CustomerPages/EditUserDetails.container'
// import Documents from '../../../containers/Admin/CustomerPages/UserDocs.container'
var result = [];
class BasicUser extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            verificationType: '',
            aadharVerificationData: '',
            typeOfData: '',
            paninfo: false,
            aadharInfo: false,
            karzaAdharData: '',
            karzaData: '',
            addharPopup: false,
            panPopup: false,
            // panProfileDetail:'',
            panVerifiedstate: false,
            inputFeildState: '',
            nameOnPan: '',
            confirmationPopup: false,
            confirmationText: '',
            loaderState: false,
            creditScoringPopup: false,
            userScore: '',
            popupState: false,
            popupStatus: '',
            senderMailId: '',
            allDetail: this.props.allDetail,
            mailIdForVerification: '',
            randomValues: {
                first: 0,
                second: 0,
                third: 0,
                fourth: 0,
                five: 0,
                six: 0,
                total: 0
            },
            linkState: false,
            // pageViewState: 'msgPage'
            vreedhiData: '',
            appVersion: '',
            userReference: '',
            confirmationPopupIncomplete: false,
            deactivateNach: false,
            // bankData: '',
            bankDataCards: '',
            // addressState: false,
            addressDataCards: '',
        }
    }
    bankDetails() {
        getBankDetailsByUserIdApi(this.props.userDetail.userId, callback => {
            this.setState({ bankDataCards: callback })
        })
    }
    addressDetails() {
        getAddressDetailsByUserIdApi(this.props.userDetail.userId, callback => {
            this.setState({ addressDataCards: callback })

        })
    }

    componentWillMount() {
        // let randomValues = Object.assign({}, this.state.randomValues)
        // randomValues.first = this.getRandomNumber();
        // randomValues.second = this.getRandomNumber();
        // randomValues.third = this.getRandomNumber();
        // randomValues.fourth = this.getRandomNumber();
        // randomValues.five = this.getRandomNumber();
        // randomValues.six = this.getRandomNumber();
        // randomValues.total = this.sumNumber();
        // this.setState({
        //     randomValues
        // })

    }
    newconfilrmation(buttonType) {
        const { admin, allDetail, userDetail } = this.props

        if (buttonType == 'yes') {
            fetch(APIS.MARK_APPLICATION_COMPLETE + allDetail.userId + '&loanId=' + allDetail.loanId + '&giftCardId=' + userDetail.giftCardId)
                .then(res => res.text())
                .then(res => {
                    if (res == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Marked Successfully',
                            confirmationPopupIncomplete: false
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again',
                            confirmationPopupIncomplete: false
                        })
                        this.removePopup();
                    }
                })

        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupIncomplete: false
            })
        }
    }
    markincompleteapplication(buttonType) {
        const { allDetail } = this.props
        this.setState({ confirmationPopupIncomplete: true, confirmationText: 'Are You Sure To Mark Incomplete this application ?' })


    }

    userLinkHandler(data) {
        if (data == 'yes') {
            this.setState({ linkState: true })
        } else if (data == 'no') {
            this.setState({ linkState: false })
        }
    }
    getRandomNumber() {
        var letters = '012345';
        var number = '';
        // console.log(Math.random())
        for (var i = 0; i < 5; i++) {
            number = letters[Math.floor(Math.random() * 4)];
        }
        var sum = 0
        for (var j = 0; j < result.length; j++) {
            sum += Number(result[j]);
        }
        // console.log(sum)
        if (sum > 14) {
            getRandomNumber()
        }

        result.push(number)
        return number;

    }
    sumNumber() {
        var finalresult = result.reduce((a, b) => Number(a) + Number(b), 0)
        result = []
        return finalresult
    }

    componentDidMount() {
        this.bankDetails()
        this.addressDetails()
        this._isMounted = true;
        const { allDetail, userDetail } = this.props;


        panProfileDetailsCards(userDetail.userId, callBack => {
            console.log('get')

            if (callBack !== null && callBack !== '' && callBack !== undefined) {
                this.setState({ karzaData: callBack, paninfo: true })
            } else {
                // postCallPanDetails(allDetail.userId, callBack => {
                console.log('post')
                //     this.setState({ karzaData: callBack, paninfo: true })

                // })
            }
        })

        aadharXmlData(allDetail.userId, callBack => {
            // console.log(callBack)
            if (callBack !== '' && callBack !== null && callBack.status === true) {
                // aadharXmlDetails(allDetail.userId, callBack => {
                this.setState({ typeOfData: callBack.type, aadharInfo: true })

                // })


            }
        })
        // if (this._isMounted) {
        //     fetch(APIS.GET_LOAN_SCORE + allDetail.loanId)
        //         .then(res => res.json())
        //         .then(json => {
        //             this.setState({ userScore: json })
        //         })
        // }

        getdeactivateNachByLoanId(allDetail.loanId, callBack => {
            if (callBack == '') {

                this.setState({ deactivateNach: false })
            } else {
                this.setState({ deactivateNach: true })

            }
        })
        fetch(APIS.GET_USER_DEVICE_DATA + allDetail.userId)
            .then(res => res.json())
            .then(json => {

                this.setState({
                    appVersion: (json.appVersion),

                })
            })
        userReferenceApi(allDetail.userId, callBack => {
            // console.log(callBack)
            this.setState({ userReference: callBack })
        })

        if (userDetail.loanFrom === "vreedhi") {
            fetch(APIS.GET_VREEDHI_DATA + userDetail.userId)
                .then(res => res.json())
                .then(json => {

                    this.setState({ vreedhiData: json })
                })
        }
        if (allDetail.panVerified == true) {
            this.setState({
                panVerifiedstate: true
            })
        } else {
            this.setState({
                panVerifiedstate: false
            })
        }
        if (userDetail.loanFrom === "bazarBussiness") {

            fetch(APIS.GET_ACCOUNT_DETAILS_USER_ID + userDetail.userId)
                .then(res => res.json())
                .then(json => {
                    this.setState({ bankData: json })
                })
        }
    }
    verifyHandler(e) {
        this.setState({
            nameOnPan: e.target.value

        })

    }
    saveVerify() {
        if (this.state.nameOnPan != '' && this.state.nameOnPan != null) {
            fetch(APIS.PAN_VERIFY_CUSTOMER + this.state.allDetail.userId + "&pan=" + this.state.allDetail.panNumber + "&nameOnPan=" + this.state.nameOnPan)
                .then(res => res.text())
                .then(json => {
                    if (json == 'success') {
                        this.setState({
                            panVerifiedstate: true,
                        })

                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: "PAN could not be verified!",
                            panVerifiedstate: false
                        }, () => this.removePopup())

                    }
                })

        } else {
            this.setState({
                popupState: true,
                popupStatus: "Enter Name as on Pancard"
            }, () => this.removePopup())
        }
    }
    karzaPopup(e, type) {
        const { allDetail, userDetail } = this.props;
        if (type === 'pan') {
            if (this.state.karzaData === '' || this.state.karzaData === null || this.state.karzaData === undefined) {
                this.setState({ loaderState: true })
                postCallPanDetails(allDetail.userId, callBack => {
                    if (callBack !== null && callBack !== '' && callBack !== undefined && callBack.result !== '' && callBack.result !== null && callBack.result !== undefined) {
                        this.setState({ karzaData: callBack.result, paninfo: true, loaderState: false, panPopup: true })
                    } else {
                        this.setState({ loaderState: false, popupStatus: 'Data Not Available', popupState: true }, () => this.removePopup())
                    }

                })
            } else {
                this.setState({ panPopup: true, paninfo: true })

            }
        }
        if (type === 'aadhar') {

            aadharVerificationDetails(allDetail.userId, callBack => {
                if (callBack.gender === null) {
                    postCallAdharVerification(allDetail.userId, allDetail.aadharNumber, callBack => {
                        aadharVerificationDetails(allDetail.userId, callBack => {
                            this.setState({ aadharVerificationData: callBack, addharPopup: true })
                        })
                    })
                } else {
                    this.setState({ aadharVerificationData: callBack,addharPopup:true })

                }
            })
            if (this.state.typeOfData === 'aadhaar') {
                this.setState({ verificationType: 'XML' })

                this.setState({ loaderState: true })

                aadharXmlDetails(allDetail.userId, callBack => {
                    if (callBack) {
                        this.setState({ karzaAdharData: callBack, loaderState: false, addharPopup: true })
                    }
                })
            }
            if (this.state.typeOfData === 'digiLocker') {
                this.setState({ verificationType: 'Digilocker', loaderState: true })

                aadharXmlDetails(allDetail.userId, callBack => {
                    if (callBack !== null && callBack !== '' && callBack !== undefined) {
                        this.setState({ karzaAdharData: callBack, loaderState: false, addharPopup: true })

                    } else {
                        postCallDigilocker(allDetail.userId, callBack => {
                            if (callBack.error !== '' && callBack.error !== null && callBack.error !== undefined) {
                                this.setState({ popupStatus: callBack.error, popupState: true, loaderState: false }, () => this.removePopup())
                            } else {
                                aadharXmlDetails(allDetail.userId, callBack => {
                                    this.setState({ karzaAdharData: callBack, loaderState: false, addharPopup: true })

                                })
                            }

                        })
                    }
                })
            }


        }
    }

    downloadFile(filename, content) {


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + content);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }





    render() {
        // this.downloadFile("download.zip",this.state.karzaAdharData.file);
        const { allDetail, showCredit, admin, userDetail } = this.props;
        return (
            <div>
                {this.state.panPopup ?
                    <KarzaPOPUPUI userDetail={allDetail} karzaData={this.state.karzaData} closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.addharPopup ?
                    <AAdharPopup verificationType={this.state.verificationType} karzaData={this.state.karzaAdharData} closePopup={this.closePopup.bind(this)}
                        downloadFile={this.downloadFile.bind(this)} aadharVerificationData={this.state.aadharVerificationData} />
                    : ""}
                {/* {this.props.creditScore == false ?
                    <div className="col-xs-12 form-group">
                        <h3 className="text-center blue-text">
                            <span className="fa fa-chevron-left pull-left pointer" onClick={(e) => this.detailBackHandler()} />
                            {allDetail.firstName + " " + allDetail.lastName} Details ({allDetail.applicationId})</h3>
                            
                    </div>
                    : ""} */}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.props.creditScore != false && showCredit == false ?
                    <CreditScore
                        allDetail={this.state.allDetail}
                        getCreditPopupState={this.getCreditPopupState.bind(this)}
                        creditScoringPopup={this.state.creditScoringPopup}
                        admin={admin}
                    />
                    : ""}
                {userDetail.loanFrom !== 'bazarBussiness' ?
                    <UserBasic
                    userDetailsByUserId={userDetail}
                    bankData={this.state.bankDataCards}
                    addressData={this.state.addressDataCards}
                        aadharInfo={this.state.aadharInfo}
                        paninfo={this.state.paninfo}
                        karzaPopup={this.karzaPopup.bind(this)}
                        // bankData={this.state.bankData}
                        deactivateNach={this.state.deactivateNach}
                        markincompleteapplication={this.markincompleteapplication.bind(this)}
                        userReference={this.state.userReference}
                        appVersion={this.state.appVersion}
                        vreedhiData={this.state.vreedhiData}
                        linkState={this.state.linkState}
                        userLinkHandler={this.userLinkHandler.bind(this)}
                        userDetail={userDetail}
                        panVerifiedstate={this.state.panVerifiedstate}
                        verifyHandler={this.verifyHandler.bind(this)}
                        saveVerify={this.saveVerify.bind(this)}
                        allDetail={this.state.allDetail}
                        userScore={this.state.userScore}
                        verifyMail={this.verifyMail.bind(this)}
                        admin={admin}
                        randomValues={this.state.randomValues}
                    />
                    : ""}
                {userDetail.loanFrom === 'bazarBussiness' ?
                    <BadaBuisnessBasic
                        bankData={this.state.bankData}
                        deactivateNach={this.state.deactivateNach}
                        markincompleteapplication={this.markincompleteapplication.bind(this)}
                        userReference={this.state.userReference}
                        appVersion={this.state.appVersion}
                        vreedhiData={this.state.vreedhiData}
                        linkState={this.state.linkState}
                        userLinkHandler={this.userLinkHandler.bind(this)}
                        userDetail={userDetail}
                        panVerifiedstate={this.state.panVerifiedstate}
                        verifyHandler={this.verifyHandler.bind(this)}
                        saveVerify={this.saveVerify.bind(this)}
                        allDetail={this.state.allDetail}
                        userScore={this.state.userScore}
                        verifyMail={this.verifyMail.bind(this)}
                        admin={admin}
                        randomValues={this.state.randomValues}
                    />
                    : ""}
                {this.state.loaderState == true ?
                    <Loader />
                    : ""}
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.confirmationPopupIncomplete == true ?
                    <Confirmation
                        cancelLoanConfirm={this.newconfilrmation.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}



            </div>
        )
    }


    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            sendverificationRequestApi(this.state.mailIdForVerification,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Mail has been successfully sent to user.',
                            confirmationPopup: false
                        })
                        this.removePopup()
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again.',
                            confirmationPopup: false
                        })
                        this.removePopup()
                    }
                })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false, panPopup: false, addharPopup: false });
    }


    getCreditPopupState(state) {
        this.props.getCreditPopupState(state)
    }

    detailBackHandler() {
        this.props.basicBackHandler()
    }

    verifyMail(mailId) {
        this.setState({
            mailIdForVerification: mailId,
            confirmationText: 'Are you sure for send verification mail to this user?',
            confirmationPopup: true
        })
        // sendverificationRequestApi(mailId,
        //     (callBack) => {
        //         if (callBack == 'success') {
        //             this.setState({
        //                 popupState: true,
        //                 popupStatus: 'Mail has been successfully sent to user.',
        //             })
        //             this.removePopup()
        //         } else {
        //             this.setState({
        //                 popupState: true,
        //                 popupStatus: 'Please try again.',
        //             })
        //             this.removePopup()
        //         }
        //     })
    }

    componentWillUnmount() {
        const { allDetail } = '';
        this._isMounted = false;
    }

}
const mapStateToProps = ({ pendingDetail }) => {
    return {
        pendingDetail: pendingDetail
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicUser);