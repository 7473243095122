import React, { Component } from 'react'
import AllCreditScoreUi from '../../../presentationals/Admin/ApplicationLeads/AllCreditScore.component'
import { APIS } from '../../../../utils/api-factory'
import { getUserDetailByLoanId } from '../AdminActionCreator.component'
import UserAssessment from '../CustomerPages/UserDetailPopup.container'
import DotLoader from '../../../presentationals/Loader/layerLoader.component'

class AllCreditScore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allCreditScoreData: '',
            loadarState: false,
            allDetail: '',
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }

    componentWillMount() {
        this.setState({ loadarState: true })
        fetch(APIS.ALL_CREDIT_SCORE)
            .then(res => res.json())
            .then(json => {
                this.setState({ allCreditScoreData: json, loadarState: false })
            })
    }

    render() {
        const { admin } = this.props
        return (
            <div>
                {this.state.loadarState == true ?
                    <DotLoader />
                    : <div>
                        <AllCreditScoreUi
                            allCreditScoreData={this.state.allCreditScoreData}
                            creditViewHandler={this.creditViewHandler.bind(this)}
                            getFilterValue={this.getFilterValue.bind(this)}
                            FilteredData={this.state.FilteredData}
                            getPageValue={this.getPageValue.bind(this)}
                            pageIndexToShow={this.state.pageIndexToShow}
                        />
                        {this.state.UserAssessmentState == true ?
                            <UserAssessment
                                allDetail={this.state.allDetail}
                                admin={admin}
                                getCreditPopupState={this.getCreditPopupState.bind(this)} />
                            : ""}
                    </div>
                }
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    getCreditPopupState(state){
        this.setState({
            UserAssessmentState: state
        })
    }
    creditViewHandler(loanId) {
        getUserDetailByLoanId(loanId,
            (callBack) => {
                this.setState({
                    allDetail: callBack,
                    UserAssessmentState: true
                })
            })
    }
}

export default AllCreditScore;