import React from 'react'
import { ROLE, STATE_COLOR } from '../../../../utils/Constant'
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import AndroidLogo from '../../../../images/android.png'
import WebLogo from '../../../../images/globe.png'
import FlipkartLogo from '../../../../images/giftnew.png'
import LoanLogo from '../../../../images/LoanFinal.png'
import { Pagination } from '@mui/material';
import LeadmanagementsearchContainer from '../../../containers/Admin/LeadManagement/leadmanagementsearch.container';
const LeadManagement = ({countType, getLoanLead, getLoaderState, setBucketStage, bucketStage, leadManagementCounts, pageNumber, forwordPageState, handleChangeNewPagination, countforstatus, allUserDetail, userDetailHandler, userViewHandler, allStatus,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow, paginationHandler,
    dataFilterState, followUpStatus, dataFilter, admin, loadarState, newDataBackground,
    todaysApplicationNo, showRepeateApplications, markAsImportant, userCommentHandler, userCommentSelect, commentStateSelect, forwordpage, backpage, tableMinPagination, tableMaxPagination, phocketTablePagination, commentType, leadComment, commentsData }) => (
    <div className="container-fluid">
        <div className='row' style={{ marginTop: '15px', marginBottom: '15px' }}>
            {console.log(bucketStage)}
            <div className='col-sm-6 col-xs-6' onClick={() => setBucketStage('default')}>
                <div className={bucketStage === 'default' ? 'lead-count-box-active' : 'lead-count-box'}>
                    <h4 style={{ color: '#232F63' }}><b>{leadManagementCounts && leadManagementCounts.assignedCount !== undefined ? leadManagementCounts.assignedCount : ""}</b></h4>
                    <h5 >Assigned Leads</h5>
                </div>
            </div>
            <div className='col-sm-6 col-xs-6' onClick={() => setBucketStage('qc')}>
                <div className={bucketStage === 'qc' ? 'lead-count-box-active' : 'lead-count-box'}>
                    <h4 style={{ color: '#232F63' }}><b>{leadManagementCounts && leadManagementCounts.qualityCheckCount !== undefined ? leadManagementCounts.qualityCheckCount : ""}</b></h4>
                    <h5 >Quality Checked</h5>
                </div>
            </div>
        </div>
        {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
            <div className="row form-group">
                <div className="col-sm-2 col-xs-12">
                    <h4 className="text-center blue-text ">
                        {todaysApplicationNo != null ?
                            <a className="pointer" onClick={e => showRepeateApplications()}>Repeat Applications- {todaysApplicationNo.repeatUserCount}</a>
                            : ""}
                    </h4>
                </div>
                <div className="col-sm-2 col-xs-12">
                    <h4 className="text-center blue-text ">
                        {todaysApplicationNo != null ?
                            <span>New Applications- {todaysApplicationNo.newUserCount}</span>
                            : ""}
                    </h4>
                </div>
                {countforstatus != '' ?
                    <div className="col-sm-2 col-xs-12">
                        <h4 className="text-center  ">
                            Total Action Done:  {countforstatus.totalAssignmentDone + countforstatus.totalPending + countforstatus.approved + countforstatus.expired + countforstatus.rejected}
                        </h4>
                    </div>
                    : ""}
                {countforstatus != '' ?
                    <div className="col-sm-3 col-xs-12">
                        <h4 className="text-center  ">
                            Total Assignment Done:  {countforstatus.totalAssignmentDone}
                        </h4>
                    </div>
                    : ""}
                {countforstatus != '' ?
                    <div className="col-sm-2 col-xs-12">
                        <h4 className="text-center  ">
                            Total Pending:  {countforstatus.totalPending}
                        </h4>
                    </div>
                    : ""}
                {countforstatus != '' ?
                    <div className="col-sm-2 col-xs-12">
                        <h4 className="text-center  ">
                            Total Approved:  {countforstatus.approved}
                        </h4>
                    </div>
                    : ""}
                {countforstatus != '' ?
                    <div className="col-sm-2 col-xs-12">
                        <h4 className="text-center  ">
                            Total Rejected:  {countforstatus.rejected}
                        </h4>
                    </div>
                    : ""}
                {countforstatus != '' ?
                    <div className="col-sm-2 col-xs-12">
                        <h4 className="text-center  ">
                            Total Expired:  {countforstatus.expired}
                        </h4>
                    </div>
                    : ""}
            </div>
            : null} */}
        <div className="row" style={{marginBottom:'15px'}}>
            <div className='col-sm-3 col-xs-12'>
                <label className="font-12px "> Select Filter</label>

                <LeadmanagementsearchContainer
                    getLoanLead={getLoanLead}
                    admin={admin}
                    getLoaderState={getLoaderState}
                    countType={countType}
                />
            </div>
            <div className='col-sm-2 col-xs-12'>
                <label className="font-12px "> Date</label>

                <DatePicker
                    value={dataFilterState.searchByDate}
                    selected={dataFilterState.searchByDateShow}
                    onChange={e => dataFilter(e, "date")}
                    className="form-control-date"
                    dateFormat="DD/MM/YYYY"
                    placeholderText='Select date'
                />
            </div>
            <div className='col-sm-2 col-xs-12'>
                <label className="font-12px " > Comment Category</label>
                <select style={{ padding: '15px', borderRadius: '6px', background: '#DBE9FF', width: '100%', border: 'none' }} onChange={e => dataFilter(e, "comment")} value={commentType}>
                    <option value=''>Select Comment</option>
                    <option value='Reference Number'>Reference Number</option>
                    <option value='NOC'>NOC</option>
                    <option value='Bank Details/ Chq'>Bank Details/ Chq</option>
                    <option value='Aadhar Card'>Aadhar Card</option>
                    <option value='Updated bank st/ salary slip'>Updated bank st/ salary slip</option>
                    <option value='Current Address Update/Proof'>Current Address Update/Proof</option>
                    <option value='Location clarification'>Location clarification </option>

                    <option value='Others'>Others</option>


                </select>
            </div>
            <div className='col-sm-2 col-xs-12'>
                <label className="font-12px "> By Status</label>
                <select style={{ padding: '15px', borderRadius: '6px', background: '#DBE9FF', width: '100%', border: 'none' }} value={followUpStatus} onChange={(e) => dataFilter(e, "status")}>
                    <optgroup className="display-none">
                        <option value="">Select Status</option>
                    </optgroup>
                    <optgroup label="Status">
                        {allStatus != null && allStatus != undefined && allStatus != '' ?
                            allStatus.map((status, i) => {
                                return (
                                    <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                );
                            }) : ""}
                    </optgroup>
                </select>

            </div>
            <div className='col-sm-2 col-xs-12'>
            <label className="font-12px " >  Salary Range</label>
                    <select style={{ padding: '15px 2px', borderRadius: '6px', background: '#DBE9FF', width: '100%', border: 'none' }} className="form-control" onChange={e => dataFilter(e, "salaryRange")} value={dataFilterState.salaryRange}>
                        <option value=''>Select Range</option>
                        <option value='<30000'>Below 30k</option>
                        <option value='>=30000'>Above 30k</option>

                    </select>
            </div>
            <div className='col-sm-1 col-xs-12'>
                <button className='btn btn-default reset-btn'onClick={e => paginationHandler('refresh')} ><i className='fa fa-repeat'>&nbsp;&nbsp;</i>Reset </button>
            </div>
        </div>
        {/* <ul className="loan-lead-search-new">
                <li>

                </li>
                <li>
                    <label className="font-12px "> By Status</label>
                    <select className="form-control" value={followUpStatus} onChange={(e) => dataFilter(e, "status")}>
                        <optgroup className="display-none">
                            <option value="">Select Status</option>
                        </optgroup>
                        <optgroup label="Status">
                            {allStatus != null && allStatus != undefined && allStatus != '' ?
                                allStatus.map((status, i) => {
                                    return (
                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                    );
                                }) : ""}
                        </optgroup>
                    </select>
                </li> */}
        {/* <li>
                    <label className="font-12px "> Application No.</label>
                    <input type="number" className="form-control" value={dataFilterState.searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
                </li>
                <li>
                    <label className="font-12px "> Email ID</label>
                    <input type="email" className="form-control" value={dataFilterState.searchByemail} onChange={e => dataFilter(e, "email")} />
                </li>

                <li>
                    <label className="font-12px " > Mobile No.</label>
                    <input type="number" value={dataFilterState.searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
                </li>
                <li>
                    <label className="font-12px "> Date</label>
                    <DatePicker
                        value={dataFilterState.searchByDate}
                        selected={dataFilterState.searchByDateShow}
                        onChange={e => dataFilter(e, "date")}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                    />
                </li> */}
        {/* <li>
                    <label className="font-12px " > Comment Category</label>
                    <select className="form-control" onChange={e => dataFilter(e, "comment")} value={commentType}>
                        <option value=''>Select Comment</option>
                        <option value='Reference Number'>Reference Number</option>
                        <option value='NOC'>NOC</option>
                        <option value='Bank Details/ Chq'>Bank Details/ Chq</option>
                        <option value='Aadhar Card'>Aadhar Card</option>
                        <option value='Updated bank st/ salary slip'>Updated bank st/ salary slip</option>
                        <option value='Current Address Update/Proof'>Current Address Update/Proof</option>
                        <option value='Location clarification'>Location clarification </option>

                        <option value='Others'>Others</option>


                    </select>
                </li>
                <li>
                    <label className="font-12px " > Select Salary Range</label>
                    <select className="form-control" onChange={e => dataFilter(e, "salaryRange")} value={dataFilterState.salaryRange}>
                        <option value=''>Select Range</option>
                        <option value='<30000'>Below 30k</option>
                        <option value='>=30000'>Above 30k</option>

                    </select>
                </li>

            </ul> */}
        {/* </div> */}
     
<hr/>
        <div className=" text-center">
            {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                <div>
                    <div className="overflow-x-auto phocket-table-new">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Assigned</th>
                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                        <th>Details</th> : "" : ""}
                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?
                                        <th>View</th> : "" : ""}
                                    {/* <th>Important</th> */}
                                    <th className='text-center'>Comment</th>
                                    <th>Already Save Comment</th>
                                    <th>Application Id</th>
                                    <th>Type</th>
                                    <th>Crif Decision</th>
                                    {/* <th>Loan Id</th> */}
                                    <th>state</th>
                                    <th>Name</th>
                                    {/* <th>Mobile No.</th> */}
                                    <th>Apply Date</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                    <th>Installment</th>
                                    {/* <th>Tenure</th> */}
                                    {/* <th>Reason</th> */}
                                    {/* <th>Loan From</th> */}

                                    {/* <th>Device</th> */}

                                </tr>

                            </thead>
                            <tbody>
                                {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    const updateAdminCommentBody = (loanid) => {

                                        const newData = allUserDetail.map(item =>
                                            item.loanid === loanid ? { ...item, adminCommentBody: 'Updated Comment' } : item
                                        );
                                        // Assuming allUserDetail is a state, update the state

                                    };

                                    return (
                                        <tr key={i} style={{ backgroundColor: data.completeMarked ? '#c3fcca' : data.incompleteMarked ? '#e4b9c0' : "" }} >
                                            <td>{data.assignUser}</td>

                                            <td>
                                                {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                                    <button onClick={e => userDetailHandler(data)}><span className="fa fa-info" /></button>
                                                    : "" : ""}
                                            </td>
                                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?

                                                <td>
                                                    <button onClick={e => userViewHandler(data)}><span className="fa fa-eye" /></button>
                                                </td>
                                                : "" : ""}
                                            {/* <td> {data.important == true ?
                                                <span className="fa fa-star orange-text" onClick={e => markAsImportant(data, false)} />
                                                :
                                                <span className="fa fa-star-o" onClick={e => markAsImportant(data, true)} />
                                            }</td> */}
                                            <td>
                                                <div style={{ display: 'flex', width: '197px' }} >
                                                    <select style={{ borderTopRightRadius: '0', borderBottomRightRadius: 0 }} onChange={e => userCommentSelect(e)} className="form-control" placeholder=''  >
                                                        <option value=''>Select Comment</option>

                                                        <option value='Reference Number'>Reference Number</option>
                                                        <option value='NOC'>NOC</option>
                                                        <option value='Bank Details/ Chq'>Bank Details/ Chq</option>
                                                        <option value='Aadhar Card'>Aadhar Card</option>
                                                        <option value='Updated bank st/ salary slip'>Updated bank st/ salary slip</option>
                                                        <option value='Current Address Update/Proof'>Current Address Update/Proof</option>
                                                        <option value='Location clarification'>Location clarification </option>

                                                        <option value='Others'>Others</option>
                                                    </select>
                                                    <button  onClick={e => userCommentHandler(data)} style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: 0,background:'#2B78FF' }} className='btn btn-primary'>Save</button>
                                                    {/* <span className="fa fa-save loan-id-save-btn" style={{ fontSize: '20px' }} onClick={e => userCommentHandler(data)} /> */}
                                                </div></td>
                                            <td>{data.adminCommentBody}</td>
                                            <td>{data.applicationId}</td>
                                            {data.typeOfProduct == 'Gift Card' ?
                                                <td><i style={{ fontSize: '22px', color: '#ff6347' }} class="fa fa-gift" aria-hidden="true"></i>
                                                </td>
                                                : data.typeOfProduct == 'Loan' ? <td><img src={LoanLogo} /></td> : data.typeOfProduct == null || data.typeOfProduct == '' ? <td>Empty</td> : ""}
                                            <td>{data.crifDecision}</td>

                                            {/* <td>{data.applicationLoanId}</td> */}
                                            <td>{data.state}</td>
                                            <td style={{ whiteSpace: 'break-spaces' }}>{data.userName}</td>
                                            {/* <td>{data.mobileNumber}</td> */}
                                            <td>{data.loanApplyDate}</td>
                                            <td>{data.applicationStatus}</td>
                                            <td>{data.loanAmount}</td>
                                            <td>{data.loanEmi}</td>
                                            {/* <td>{data.loanDuration}</td> */}
                                            {/* <td>{data.loanReason} </td> */}
                                            {/* <td>{data.loanFrom}</td> */}

                                            {/* {data.loginDevice == 'android' ?
                                                <td style={{ color: '#a4c639' }}><img src={AndroidLogo} width='40px' />
                                                </td>
                                                : data.loginDevice == 'website' ? <td style={{ color: 'blue' }}><img src={WebLogo} /></td>
                                                    : data.loginDevice == 'IOS' || data.loginDevice == 'ios' ? <td style={{ fontSize: '25px' }}><i class="fa fa-apple" aria-hidden="true"></i>
                                                    </td> : data.loginDevice == '' || data.loginDevice == null ? <td>Empty</td> : ""} */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ float: 'right', marginBottom: '15px', marginTop: '10px', display: 'flex', marginRight: '20px' }}>
                        {forwordPageState > 1 ? <div className='grey-circle' onClick={e => paginationHandler('back')}><i className="fa fa-chevron-left" style={{ cursor: 'pointer', marginTop: '15px' }}  ></i></div> : ""}  <Pagination size='large' onChange={handleChangeNewPagination} color="primary"
                            count={Math.ceil(allUserDetail.length / 10)}
                            page={pageNumber} />
                        {Math.ceil(allUserDetail.length / 10) === 10 ? <div className='grey-circle' onClick={e => paginationHandler('forward')}><i className="fa fa-chevron-right" style={{ cursor: 'pointer', marginTop: '15px' }}  ></i></div> : ""}

                    </div>
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-3 col-xs-12 no-padding">     <button className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('back')}><span className="fa fa-chevron-left" /></button></div>
                            <div className="col-sm-2 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block  red" onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                            <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                            <div className="col-sm-2 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block  red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                            <div className="col-sm-3 col-xs-12 no-padding" > <button className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('forward')}><span className="fa fa-chevron-right" /></button> </div>
                                      </div>
                    </div> */}
                </div>
                : ""}

        </div>
    </div>
)

export default LeadManagement;