import React from 'react'

const Cards = ({ changeHandler, nameFile, sumbit }) => (
    <div className='container'>
        <div className='col-sm-3 col-xs-12'></div>
        <div className='col-sm-6 col-xs-12'>
            <h3 className='text-center blue-text'>Upload File of Users</h3>

            <div className="image-upload-wrap">
                <input
                    accept='.csv'

                    className="file-upload-input" type='file' onChange={(e) => changeHandler(e)} />
                <div className="drag-text">
                    <h5> {nameFile !== '' ? nameFile : 'Drag and drop a file or select Image'} </h5>
                </div>
            </div>
            <div className='text-center'>
                <button className='profile-save-btn' onClick={() => sumbit()}> Submit</button>
            </div>
        </div>
        <div className='col-sm-3 col-xs-12'></div>

    </div>
)

export default Cards