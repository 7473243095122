import React from 'react'

const View = ({usedStatus, selectedFilter,initiateEsignhhandler,stampDutyData, usedHandler, count,paginationHandler ,openStampPopup,pageNo}) => (
    <div className='container-fluid'>
        <div className='row'>
            <h3 className="text-center blue-text">Uploaded Stamp Duty</h3>
        </div>
        <div className='row' style={{ marginBottom: '10px' }}>
            <div className='col-sm-4 col-xs-12'>
                <label>Select Used</label>
                <select className='form-control' value={usedStatus} onChange={(e) => usedHandler(e)}>
                    <option value={'true'}>Yes</option>
                    <option value={'false'}>No</option>

                </select>
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label>Esign Initiated</label>
                <select className='form-control' value={selectedFilter} onChange={(e) => initiateEsignhhandler(e)}>
                <option value=''>Select value</option>

                    <option value={'true'}>Yes</option>
                    <option value={'false'}>No</option>

                </select>
            </div>
            <div className='col-sm-4 col-xs-12 text-center'>
                <div style={{marginTop:'20px'}}>
                Total : <b>{count}</b>
                </div>
            </div>
        </div>
        <div className='row mt-2'>
            <div className='col-sm-12 col-xs-12'>
                <div className="overflow-x-auto">
                    <table className="phocket-table">
                        <thead>
                            <tr>

                                <th>Status(Used/Not Used)</th>
                                <th>Initiate Esign</th>
                                <th>View</th>

                            </tr>
                        </thead>
                        <tbody>
                            {stampDutyData ? stampDutyData.map((data, i) => {
                                return (
                                    <tr key={i}   >
                                        <td>{data.usedStatus === true ? 'Used' : data.usedStatus === false ? 'Not Used' : ""}</td>
                                        <td>{data.initiateEsign === true ? 'Yes' : data.initiateEsign === false ? 'No' : ""}</td>
                                        <td><button className='profile-save-btn' onClick={()=>openStampPopup(data.url)}>View</button></td>
                                    </tr>
                                )
                            }) : ""}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-5 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                    <div className='col-sm-2 col-xs-12 text-center' style={{ marginTop: '10px' }}> Page Number {pageNo} </div>
                    <div className="col-sm-5 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
            </div>
        </div>
    </div>
)
export default View