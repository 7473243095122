import React, { Component } from 'react'
import Graph from '../../../presentationals/Admin/BIGraphs/NewBigarph.component'
import OccupationPopup from '../../../presentationals/Popup/OccupationPopupData.component'
import {
    getApplicationAnalysisLoginMode, getEventDataMethodincomplete, getLoanOverDueTenureData, getLoanOverdueSalary, overdueAnalysis, getLoanRejectData, getLoginDeviceDataMethod, getEventDataMethod, getLoanStatusData, getLoanAnalysisData, getEdaDataMethod, getStateCountData, getSalaryData, getLoanAmountProfileData,
    getLoanOverDueData, getLoginTypedataincomplete, getFilterUsingDate, getApplicationAnalysisLoginModeincomplete, getOccupationdata, getLoginDeviceincompleteDataMethod, getLoginModeCount, getProfileAnalysisSourceOfInformation, getProfileAnalysisData, getAddressdata, getLoginTypedata, applicationStatusdata
} from './getDataForGraph'
import GraphLoader from '../../../presentationals/Loader/graphloader.component'
import TablePOPup from '../../../presentationals/Popup/TabularFormGraphData.component'
import { STRING } from '../../../../utils/Constant'
import { _formatDateInDash } from '../../../../utils/validation'
import { APIS } from '../../../../utils/api-factory';


class Bidoughnut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            graphFilterData: {
                startDateToShow: '',
                startDate: null,
                endDateToShow: '',
                endDate: null,
                dateFilterType: null
            },
            menuOpenState: '',
            getallState: "",
            filterState: false,
            loadarState: false,
            loginDeviceData: '',
            eventData: '',
            edaData: '',
            occupationData: '',
            loginData: '',
            loginTypeData: '',
            showPopup: false,
            popupGraphData: '',
            tablePopUpData: '',
            excel: '',
            tablePOPUP: false,
            loanAmountData: '',
            graphType: 'Application',
            genderData: '',
            formStatusData: '',
            genderAndFormStatus: false,
            loginTypeStatus: false,
            loginType: '',
            graphDiscription: '',
            menuOpenState: '',
            errorMsg: "",
            dateFilterType: null,
            totalCount: 0,

        }

    }

    // componentWillMount(){
    //     this.getTotalCount();
    // }


    setGraphMethod() {
     if (this.state.graphType == 'Login Type') {
            this.setState({ loadarState: true })
            getLoginTypedata(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } 
        else if (this.state.graphType == 'FormStage') {
            this.setState({ loadarState: true })
            getEdaDataMethod(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,
                    graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_GENDER
                })
            })

        }
       else if (this.state.graphType == 'incomplete Login Type') {
            this.setState({ loadarState: true })
            getLoginTypedataincomplete(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,
                    graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_GENDER
                })
            })

        }
        else if (this.state.graphType == 'incompleteloginModeData') {
            this.setState({ loadarState: true })
            getApplicationAnalysisLoginModeincomplete(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,
                    graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_GENDER
                })
            })

        }
        else if (this.state.graphType == 'incompleteEvents') {
            this.setState({ loadarState: true })
            getEventDataMethodincomplete(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,
                    graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_GENDER
                })
            })

        }
        else if (this.state.graphType == 'incompletegender') {
            this.setState({ loadarState: true })
            getLoginDeviceincompleteDataMethod(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,
                    graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_GENDER
                })
            })

        }
        else if (this.state.graphType == 'Application') {
            this.setState({ loadarState: true })
            getLoginDeviceDataMethod(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,
                    graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_GENDER
                })
            })

        } else if (this.state.graphType == 'Events') {
            this.setState({ loadarState: true })
            getEventDataMethod(null, callBack => {
                // this.setMinValue(callBack, 10000)
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,
                    graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_DEVICE

                })
            })
        } else if (this.state.graphType == 'Gender Wise') {
            this.setState({ loadarState: true })
            this.setState({
                loginDeviceData: this.state.genderData,
                loadarState: false,

            })
        } else if (this.state.graphType == 'Loan Data') {
            this.setState({ loadarState: true })
            // getLoanAmountData(null, callBack => {
            //     this.setState({
            //         loginDeviceData: callBack,
            //         loadarState: false
            //     })
            // })
        } else if (this.state.graphType == 'Application Status') {
            this.setState({ loadarState: true })
            applicationStatusdata(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == 'profileAnalysis') {
            this.setState({ loadarState: true })
            getProfileAnalysisData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == 'loginmode') {
            this.setState({ loadarState: true })
            getLoginModeCount(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,

                })
            })
        } else if (this.state.graphType == 'Source') {
            this.setState({ loadarState: true })
            getProfileAnalysisSourceOfInformation(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == "State Count") {
            this.setState({ loadarState: true })
            getStateCountData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == "salary") {
            this.setState({ loadarState: true })
            getSalaryData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == "loanAmount") {
            this.setState({ loadarState: true })
            getLoanAmountProfileData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == "apprrovedamt") {
            this.setState({ loadarState: true })
            getLoanAnalysisData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == "overduesalary") {
            this.setState({ loadarState: true })
            getLoanOverdueSalary(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == "overdueapprove") {
            this.setState({ loadarState: true })
            getLoanOverDueData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == "overduedays") {
            this.setState({ loadarState: true })
            overdueAnalysis(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == "tenurecount") {
            this.setState({ loadarState: true })
            getLoanOverDueTenureData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == 'loginModeData') {
            this.setState({ loadarState: true })
            getApplicationAnalysisLoginMode(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false,
                    graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_LOGIN_MODE

                })
            })
        } else if (this.state.graphType == 'statusamt') {
            this.setState({ loadarState: true })
            getLoanStatusData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        } else if (this.state.graphType == 'rejectdata') {
            this.setState({ loadarState: true })
            getLoanRejectData(null, callBack => {
                this.setState({
                    loginDeviceData: callBack,
                    loadarState: false
                })
            })
        }


    }


    componentDidMount() {
        this.setGraphMethod()
     
        this.getallState()


    }

    closegraphPopup() {
        this.setState({ showPopup: false });
        this.setState({ tablePOPUP: false });
    }
    filterHandler() {
        this.setState({
            filterState: !this.state.filterState,
        })
    }



 


    render() {
        const { admin } = this.props
   
        let totCount = 0;
        if (this.state.loginDeviceData.data != undefined && this.state.loginDeviceData.data != null && this.state.loginDeviceData.data.length >= 1) {

            this.state.loginDeviceData.data.map(data => {
                if (data.dataPoints != null && data.dataPoints != undefined && data.dataPoints.length >= 1) {
                    data.dataPoints.map(data1 => {
                        totCount += Number(data1.y)
                    })
                }
            })

        }
        return (
            <div>
               
                <div className="count pull-right">{totCount}</div>

                {/* {this.state.showPopup ?
                    <OccupationPopup
                        closeTaskAssignPopup={this.closegraphPopup.bind(this)}
                        occupationData={this.state.popupGraphData}
                    />
                    : null
                }
                {this.state.tablePOPUP ?
                    <TablePOPup
                        closeTaskAssignPopup={this.closegraphPopup.bind(this)}
                        tablePopUpData={this.state.loginDeviceData}
                        excel={this.state.loginDeviceData}
                    /> : null
                }
                <Graph
                    menuOpenState={this.state.menuOpenState}
                    refresh={this.refresh.bind(this)}
                    getallState={this.state.getallState}
                    graphType={this.state.graphType}
                    filterDataChange={this.filterDataChange.bind(this)}
                    graphFilterData={this.state.graphFilterData}
                    navItemClick={this.navItemClick.bind(this)}
                    graphDiscription={this.state.graphDiscription}
                    loginDeviceData={this.state.loginDeviceData}
                    eventData={this.state.eventData}
                    edaData={this.state.edaData}
                    genderData={this.state.genderData}
                    loginTypeData={this.state.loginTypeData}
                    loanAmountData={this.state.loanAmountData}
                    filterHandler={this.filterHandler.bind(this)}
                    filterDataHandler={this.filterDataHandler.bind(this)}
                    applicationData={this.state.applicationData}
                    genderAndFormStatus={this.state.genderAndFormStatus}
                    _click={this._click.bind(this)}
                    tablebuttonclick={this.tablebuttonclick.bind(this)}
                    loginTypeStatus={this.state.loginTypeStatus}
                />
                {this.state.loadarState == true ?
                    <GraphLoader />
                    : null
                }
                */}
            </div>
        )
    }

    navItemClick(navType) {
        if (this.state.menuOpenState != navType) {
            this.setState({ menuOpenState: navType })
        } else {
            this.setState({ menuOpenState: '' })
        }
    }

    filterDataChange(e, type) {
        let graphFilterData = Object.assign({}, this.state.graphFilterData)
        if (type == 'fromDate') {
            graphFilterData.startDateToShow = e
            graphFilterData.startDate = _formatDateInDash(e)
        } else if (type == 'tillDate') {
            graphFilterData.endDateToShow = e
            graphFilterData.endDate = _formatDateInDash(e)
        } else if (type == "statewise") {
            graphFilterData.dateFilterType = e.target.value
        }

        this.setState({ graphFilterData, errorMsg: '' })
    }


    filterDataHandler() {

        this.setState({ loadarState: true })

        getFilterUsingDate(this.state.graphFilterData, this.state.graphType, callBack => {
            // console.log(this.state.loadarState)

            this.setState({
                loginDeviceData: callBack,
                graphDiscription: STRING.DISCRIPTION_APPLICATION_ANALYSIS + STRING.DISCRIPTION_APPLICATION_ANALYSIS_LOGIN_MODE
            })
            setTimeout(function(){
                this.setState({
                    loadarState: false,
                })
            }.bind(this),3000)
           
            
            // console.log(this.state.loadarState)
        }
        )
    }
    refresh() {
        this.setGraphMethod()
        this.getallState()
        this.setState({ graphFilterData: '', getallState: '' })
    }

    getallState() {
        fetch(APIS.GET_ALL_STATE)
            .then(res => res.json())
            .then(res => {

                this.setState({ getallState: res })

            })
    }

    navItemClick(navType) {
        if (this.state.menuOpenState != navType) {
            this.setState({ menuOpenState: navType })
        } else {
            this.setState({ menuOpenState: '' })
        }
    }

    tablebuttonclick() {
        this.setState({
            tablePOPUP: !this.state.tablePOPUP
        })
    }

    _click(e, type) {
        this.setState({
            graphType: type,
        }, () => this.setGraphMethod())
    }

    setMinValue(data, minValue) {
        var minValue = minValue;
        var data = data.data;
        for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].dataPoints.length; j++) {
                var yValue = data[i].dataPoints[j].y;
                if (yValue <= minValue && yValue >= -minValue) {
                    data[i].dataPoints[j].y = yValue < 0 ? -minValue : minValue;
                    data[i].dataPoints[j].toolTipContent = ("{label}:" + yValue);
                }
            }
        }
    }

}

export default Bidoughnut;