import React from 'react'
import ReactTable from "react-table";
import Workbook from 'react-excel-workbook'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import DotLoader from '../../Loader/DotLoader.component'
import { ROLE } from '../../../../utils/Constant';
const ApplicationStatus = ({ paginationHandler, phocketTablePagination, tableMaxPagination, tableMinPagination, admin, allUserDetail, backpage, forwordpage, dataFilter,
    deleteUserHandler, isDeleteUser, editUserHandler, loadarState, unsubscribeHandler,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow, varifyMobile, teaGardenData, teaGardenId, teaChangeHandler }) => (
        <div className="container-fluid">
        {console.log("teaGardenDatateaGardenData",teaGardenData)}
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Application Status</h3>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Email ID</label>
                <input type="email" className="form-control" onChange={e => dataFilter(e, "email")} />
            </div>
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" /> Mobile No.</label>
                <input type="number" onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
            </div>
            {/* <div className='col-sm-3 col-xs-12'>
                <label>Select Tea Garden</label>
                <select className='form-control' value={teaGardenId} onChange={(e) => teaChangeHandler(e, 'teaGardenId')}>
                    <option value=''>Select Tea Garden</option>

                    {teaGardenData ? teaGardenData?.map((data, i) => {
                        return (
                            <option value={data.teaGardenId}>{data.tea_garden_name}</option>

                        )
                    }) : ""}
                </select>
            </div> */}
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" />Employee Id</label>
                <input type="number" onChange={(e) => teaChangeHandler(e, 'employeeId')} className="form-control" />
            </div>

        </div>

        <div className="row form-group">

            <div className="col-xs-6 text-left">
                {admin && admin.rolelist.indexOf(ROLE.DASHBOARD_REPORT_NEW) >= 0 ?
                    <Workbook filename="ApplicationStatus.xlsx" element={<button className="profile-save-btn margin-0"><span className="fa fa-download"></span>&nbsp;&nbsp;Excel</button>}>
                        <Workbook.Sheet data={allUserDetail} name="Sheet A">
                            <Workbook.Column label="firstName" value="firstName" />
                            {/* <Workbook.Column label="lastName" value="lastName" /> */}
                            <Workbook.Column label="emailId" value="emailId" />
                            <Workbook.Column label="mobileNumber" value="mobileNumber" />
                            <Workbook.Column label="panNumber" value="panNumber" />
                            <Workbook.Column label="formStatus" value="formStatus" />
                        </Workbook.Sheet>
                    </Workbook>
                    : ""}
            </div>
            <div className="col-xs-6 text-right">
                {/* <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button> */}
                {/* <p>1-100</p> */}
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 no-padding overflow-auto">
                {loadarState == false ?
                    allUserDetail != null ? allUserDetail.length > 0 ?
                        <table className='phocket-table'>
                            <thead>
                                <tr>
                                    <td>Form Step</td>
                                    <td>First Name</td>
                                    <td>Mobile Verified</td>
                                    <td>Email Id</td>
                                    <td>Login Date</td>
                                    <td>State</td>
                                    <td>Phone Number</td>
                                    {admin && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?

                                        <td>Edit</td>
                                        : ""}
                                    {admin && admin.emailId === 'SuperUser' ?
                                        <td>Unsubscribe</td>
                                        : ""}
                                    <td>User Id</td>
                                    {admin && admin.emailId === 'SuperUser' ?
                                        <td>Delete</td>
                                        : ""}
                                    {admin && admin.emailId === 'SuperUser' ?
                                        <td>Verify</td>
                                        : ""}

                                </tr>
                            </thead>

                            <tbody>
                                {allUserDetail && allUserDetail.slice(tableMinPagination, tableMaxPagination)?.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{data.formStatus}</td>
                                            <td> {data.firstName}</td>
                                            <td> <div style={{ textAlign: 'center' }}>
                                                {data.mobileVerified === true || data.mobileVerified === 'true' ?
                                                    <i class="fa fa-check" style={{ color: 'green' }}></i>
                                                    :
                                                    <i class="fa fa-close" style={{ color: 'red' }}></i>
                                                }
                                            </div></td>
                                            <td>{data.emailId}</td>
                                            <td>{data.loginDate}</td>
                                            <td>{data.state}</td>
                                            <td>{data.mobileNumber}</td>
                                            {admin && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?

                                                <td><button onClick={e => editUserHandler(data)}><span className="fa fa-pencil-square-o" /></button></td>
                                                : ""}
                                            {admin && admin.emailId === 'SuperUser' ?
                                                <td> <button className="unsubscribe-btn" onClick={e => unsubscribeHandler(data.userId)}>Unsubscribe</button></td> : ""}
                                            <td>{data.userId}</td>
                                            {admin && admin.emailId === 'SuperUser' ?
                                                <td> <button onClick={e => deleteUserHandler(data.userId)}><span className="fa fa-trash" /></button></td> : ""}
                                            {admin && admin.emailId === 'SuperUser' ?
                                                <td> <button onClick={e => varifyMobile(data.userId)}><span className="fa fa-save" /></button></td> : ""}

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>


                        : "" : "" :
                    <DotLoader />}

            </div>
            {!loadarState ?
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                        <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                        <div className="col-sm-2 col-xs-12  phoc text-center" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                        <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                        <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
                </div>
                : ""}
        </div>
    </div>
)

export default ApplicationStatus;