import React from 'react'

const panConfirmPopup = ({ cancelLoanConfirm, confirmationText, allDetail }) => (
    <div className="background-blur details-new income">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border" style={{ marginTop: '10%' }}>
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">{'Are you sure these data are correct for PAN Status Check.?'}</h4>
                    <p className="text-center">(As per NSDL PAN Number, Name as On PAN & DOB should be of same PAN Holder)</p>
                </div>
            </div>
            {/* <div className='row'>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                    <label>Pan Number</label>
                    <p>{allDetail != null ? allDetail.panNumber != null ? allDetail.panNumber : "" : ""}</p>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                    <label>Name as on PAN</label>
                    <p>{allDetail != null && allDetail.nameAsOnPan !== null && allDetail.nameAsOnPan !== null ? allDetail.nameAsOnPan : ""}</p>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                    <label>Dob</label>
                    <p>{allDetail != null && allDetail.dateOfBirth !== null && allDetail.dateOfBirth !== null ? allDetail.dateOfBirth : ""}</p>
                </div>
            </div> */}
            <div className='col-xs-12 col-sm-12' style={{ marginBottom: '20px' }}>
                <table className='income-table'>
                    <thead>
                        <tr>
                            <th>PAN Number</th>
                            <td>{allDetail != null ? allDetail.panNumber != null ? allDetail.panNumber : "" : ""}</td>
                        </tr>
                        <tr>
                            <th>Name As On PAN</th>
                            <td>{allDetail != null && allDetail.nameAsOnPan !== null && allDetail.nameAsOnPan !== null ? allDetail.nameAsOnPan : ""}</td>
                        </tr>

                        <tr>

                            <th>Dob:</th>
                            <td>{allDetail != null && allDetail.dateOfBirth !== null && allDetail.dateOfBirth !== null ? allDetail.dateOfBirth : ""}</td>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="row from-group text-center">

                <button className="btn btn-success" style={{ marginTop: '20px' }} onClick={(e) => cancelLoanConfirm('yes')}>Proceed</button>&nbsp;&nbsp; <button className="btn btn-danger" style={{ marginTop: '20px' }} onClick={(e) => cancelLoanConfirm('no')}>NO</button>
            </div>
        </div>
    </div>
)

export default panConfirmPopup;