import React from 'react'
import { ROLE } from '../../../utils/Constant';

const CrifConfirmationPopup = ({ ConfirmCrifDataGenerate, confirmationText,admin }) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">Select an option to view Report</h4>
                </div>
            </div>
            <div className="row from-group text-center" style={{marginTop:'20px'}}>
                <div className="col-xs-6">
                {admin&&admin.rolelist.indexOf(ROLE.FINBIT_RE_GENERATE) >= 0?
                    <button className="btn btn-primary display-inline" onClick={(e) => ConfirmCrifDataGenerate('yes')}>Regenerate</button>
               
                :""}
                 </div>
                <div className="col-xs-6">
                    <button className="btn btn-primary display-inline" onClick={(e) => ConfirmCrifDataGenerate('no')}>Open Existing</button>
                </div>
            </div>
        </div>
    </div>
)

export default CrifConfirmationPopup;