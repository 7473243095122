import React from 'react';
import DatePicker from 'react-datepicker';
import { ROLE, STATE_COLOR } from '../../../../utils/Constant'
import DotLoader from '../../Loader/layerLoader.component'
import NodataImage from '../../../../images/nodata.png'

const PhocketCustomerDetails = ({ filterData, dataType, reset, filterHandler, countByAnalysis, searchByEndDate, searchByEndDateTOShow, dateType, newFilterHandler, teaChangeHandler, removeclass, testbutton, commentColumn, userCommentChange, userDetailHandler, userViewHandler, dataFilter, admin,
    allUserDetail, statusHandler, newDataBackground, todaysApplicationNo, isDeleteUser, deactivateNachHandler,
    allStatus, loadarState, markAsImportant, paginationHandler, phocketTablePagination,
    columnHide, deleteUserHandler, userAssignPopup, searchByDate, commonSearch, nashHandler,
    showRepeateApplications, followUpStatus, commonSearchHandler, tableMinPagination,
    searchByDateShow, nachCheckbox, markImportant, tableMaxPagination, showWhatsappMsg, singleLeadPushHandler, paginationNumber,
    userCommentHandler, pushToRetraHandler }) => (
    <div className="container-fluid same">


        <h4 className='text-center'><strong>Credit Analysis Leads</strong></h4>
        <div className='row'>
            <div className="col-sm-2 col-xs-12">
                <label style={{ fontSize: '10px' }}>Start Date</label>
                <input value={searchByDateShow} style={{ width: '100%' }} className='date-input-admin form-control' type='date' placeholder='Start Date' onChange={(e) => newFilterHandler(e, 'startDate')} />

            </div>
            <div className="col-sm-2 col-xs-12">
                <label style={{ fontSize: '10px' }}>End Date</label>
                <input value={searchByEndDateTOShow} style={{ width: '100%' }} className='date-input-admin form-control' type='date' placeholder='End Date' onChange={(e) => newFilterHandler(e, 'endDate')} />
            </div>
            <div className='col-sm-1 col-xs-1'>
                <button className='btn btn-primary' onClick={() => filterData()} style={{ marginTop: '20px' }}><i className='fa fa-filter'></i></button>&nbsp;&nbsp;
                <button onClick={() => reset('newLead')} className='btn btn-default' style={{ marginTop: '20px' }}><i className='fa fa-undo'></i></button>
            </div>
            {/* <div className='col-sm-1 col-xs-1'>
                <button onClick={() => reset('newLead')} className='btn btn-default' style={{ marginTop: '20px' }}><i className='fa fa-undo'></i></button>
            </div> */}


        </div>
        <div className="row " style={{ marginTop: '30px' }}>
            <div className='col-sm-3 col-xs-12' style={{ marginTop: '40px'}}>
                <div className='row' style={{ backgroundColor: dataType === 'analysisRejectedCount' ? '#cecccc' : '',borderRadius:'5px',marginTop:'20px' }}>
                    <div className='col-sm-6 col-xs-7'>
                        <h4 style={{ cursor: 'pointer' }} onClick={() => filterHandler('analysisRejectedCount')}><b>Rejected</b></h4>
                    </div>
                    <div className='col-sm-6 col-xs-3 text-right' >
                        <h4 style={{ cursor: 'pointer' }} onClick={() => filterHandler('analysisRejectedCount')}> <span style={{ color: 'rgb(76, 199, 140)', background: 'rgb(225, 246, 236)', padding: '3px 5px', borderRadius: '5px', marginTop: '20px' }}>{countByAnalysis ? countByAnalysis.analysisRejectedCount ? countByAnalysis.analysisRejectedCount : 0 : ""}</span></h4>
                    </div>
                </div>
                <div className='row' style={{ backgroundColor: dataType === 'crifHitCount' ? '#cecccc' : '',borderRadius:'5px',marginTop:'20px' }}>
                    <div className='col-sm-6 col-xs-7'>
                        <h4 style={{ cursor: 'pointer' }} onClick={() => filterHandler('crifHitCount')}><b> Crif Hit</b></h4>
                    </div>
                    <div className='col-sm-6 col-xs-3 text-right' >
                        <h4 style={{ cursor: 'pointer' }} onClick={() => filterHandler('crifHitCount')}><span style={{ color: 'rgb(76, 199, 140)', background: 'rgb(225, 246, 236)', padding: '3px 5px', borderRadius: '5px', marginTop: '20px' }}>{countByAnalysis ? countByAnalysis.crifHitCount ? countByAnalysis.crifHitCount : 0 : ""}</span></h4>
                    </div>
                </div>
                <div className='row' style={{ backgroundColor: dataType === 'newLead' ? '#cecccc' : '',borderRadius:'5px',marginTop:'20px' }}>

                    <div className='col-sm-6 col-xs-7'>
                        <h4 style={{ cursor: 'pointer' }} onClick={() => filterHandler('newLead')}><b> Fresh Leads</b></h4>
                    </div>
                    <div className='col-sm-6 col-xs-3 text-right' >
                        <h4 style={{ cursor: 'pointer' }} onClick={() => filterHandler('newLead')}><span style={{ color: 'rgb(76, 199, 140)', background: 'rgb(225, 246, 236)', padding: '3px 5px', borderRadius: '5px', marginTop: '20px' }}> {countByAnalysis ? countByAnalysis.analysisRejectedCount ? countByAnalysis.analysisRejectedCount : 0 : ""}</span></h4>
                    </div>
                </div>

            </div>
            <div className='col-sm-1 col-xs-1'></div>
            <div className="col-sm-8 col-xs-12 text-center no-padding">
                {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                    <div>
                        <div className="overflow-x-auto">
                            <table className="phocket-table">
                                <thead>
                                    <tr>
                                        {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?

                                            <th>Details</th>
                                            : "" : ""}
                                        {/* {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?

                                            <th>View</th>
                                            : "" : ""} */}

                                        <th>Application Id</th>
                                        <th>Reject Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                        return (
                                            <tr key={i}

                                            >

                                                {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                                    <td>
                                                        <button className="background-none-btn" onClick={e => userDetailHandler(data, paginationNumber)}><span className="fa fa-info" /></button>

                                                    </td>
                                                    : "" : ""}

                                                <td>{data.applicationId}</td>
                                                <td>{data.rejectionReason}</td>


                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>


                    </div>
                    : ''}
                {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                            <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                            <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                            <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                            <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
                    </div>
                    : ""}
                {loadarState == true ?
                    <DotLoader />
                    : ""}
            </div>
        </div>
    </div>
)

export default PhocketCustomerDetails;
