import React from 'react'
import ProductConfigUi from '../../../presentationals/Admin/NbfcManagementNew/EsignConfig.component';
import PopUp from '../../../presentationals/Popup/Popup.component';
import { saveEsignConfigNbfc } from '../AdminActionCreator.component';
import { nbfcEsignConfigurationValidation } from '../../../../utils/validation';
import Loader from '../../../presentationals/Loader/Loader.component'
import moment from 'moment';

class ProductConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productConfig: {
                id: '',
                nbfcId: '',
                eSignRequestUrl: '',
                widgetTokenUrl: '',
                docketInfoUrl: '',
                signatureStatusUrl: '',
                apiKey: '',
                appId: '',
                status: ''

            },
            validationMsg: '',
            popupState: false,
            popupStatus: '',
            loaderState: false

        }
    }
    componentDidMount() {
        if (this.props && this.props.nbfcId !== null && this.props.nbfcId !== undefined) {
            let changeData = Object.assign({}, this.state.productConfig)
            changeData.nbfcId = this.props.nbfcId
            this.setState({ productConfig: changeData })
        }
        if (this.props && this.props.nbfcData !== undefined && this.props.nbfcData !== '') {
            let changeData = Object.assign({}, this.state.productConfig)
            changeData.eSignRequestUrl = this.props.nbfcData.eSignRequestUrl
            changeData.widgetTokenUrl = this.props.nbfcData.widgetTokenUrl
            changeData.docketInfoUrl = this.props.nbfcData.docketInfoUrl
            changeData.signatureStatusUrl = this.props.nbfcData.signatureStatusUrl
            changeData.apiKey = this.props.nbfcData.apiKey
            changeData.appId = this.props.nbfcData.appId
            changeData.status = this.props.nbfcData.status
            changeData.nbfcId = this.props.nbfcData.nbfcId
            changeData.id = this.props.nbfcData.id
            this.setState({ productConfig: changeData })
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    changeHandler(e, type) {
        let changeData = Object.assign({}, this.state.productConfig)

        if (type === 'eSignRequestUrl') {
            changeData.eSignRequestUrl = e.target.value
        }
        if (type === 'widgetTokenUrl') {
            changeData.widgetTokenUrl = e.target.value
        }
        if (type === 'docketInfoUrl') {
            changeData.docketInfoUrl = e.target.value
        }
      
        if (type === 'signatureStatusUrl') {
            changeData.signatureStatusUrl = e.target.value
        }
        if (type === 'apiKey') {
            changeData.apiKey = e.target.value
        }
        if (type === 'appId') {
            changeData.appId = e.target.value
        }
        if (type === 'status') {
            changeData.status = e.target.value
        }

        this.setState({ productConfig: changeData })
    }
    submit() {
        let validation = nbfcEsignConfigurationValidation(this.state.productConfig)
        if (validation.status) {
            this.setState({ loaderState: true })
            saveEsignConfigNbfc(this.state.productConfig, callback => {
                if (callback === 'success') {
                    this.setState({ loaderState: false, popupStatus: 'Successfully Saved !', popupState: true }, () => this.removePopup())
                } else {
                    this.setState({ loaderState: false, popupStatus: 'Please try again later !', popupState: true }, () => this.removePopup())

                }
            })
        } else {
            this.setState({ validationMsg: validation })
        }
    }
    render() {
        return (
            <div>
                <ProductConfigUi
                    validationMsg={this.state.validationMsg}
                    submit={this.submit.bind(this)}
                    productConfig={this.state.productConfig}
                    changeHandler={this.changeHandler.bind(this)}
                />
                {this.state.popupState == true ?
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
            </div>
        )
    }
}
export default ProductConfig