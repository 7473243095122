import axios from "axios";
import { APIS } from "../../../../utils/api-factory";
import { POST_HEADER } from "../../../../utils/apiHeaders";

export const getEventDataByPageNo = (pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_EVENT_DATA_BY_PAGE + pageNo + '&noOfEntry=' + noOfEntry, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getEventDataCountPageNo = (pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_EVENTS_COUNTS + pageNo + '&noOfEntry=' + noOfEntry, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getEventDataByPartnerId = (partnerId, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_EVENT_DATA_BY_PARTNER_ID + partnerId, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getAggregatorPayout = (partnerId, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_AGGREGATOR_PAYOUT + partnerId, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getInCompleteStatsByAggregator = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_IN_COMPLETE_STATS_BY_AGGREGATOR +
      payload.name +
      `&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getInCompleteStatsByBranchData = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_IN_COMPLETE_STATS_BY_BRANCH +
      payload.name +
      `&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getInCompleteStatsByAggregatorLeads = (
  payload,
  callBack,
  error
) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_IN_COMPLETE_STATS_BY_AGGREGATOR_LEADS +
      payload.name +
      `&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getInCompleteStatsByBranchDataLeads = (
  payload,
  callBack,
  error
) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_IN_COMPLETE_STATS_BY_BRANCH_LEADS +
      payload.name +
      `&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getAppliedStatsByBranchData = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_APPLIED_STATS_BY_BRANCH +
      payload.fromDate +
      `&to=${payload.toDate}&partnerName=${payload.name}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getAppliedStatsByAggregator = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_APPLIED_STATS_BY_AGGREGATOR_SUBMITTED +
      payload.fromDate +
      `&to=${payload.toDate}&partnerName=${payload.name}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};

export const getAgreegatorListApi = (callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_AGGREGATOR_NAME, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const saveOrUpdateAggregatorPayouts = (payload, callBack, error) => {
  console.log(payload, 'payloadpayload')
  const headerConfig = {
    headers: {},
  };
  axios
    .post(APIS.SAVE_OR_UPDATE_AGGREGATOR_PAYOUTS, payload, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
// // /// /// // // // /
export const getBranchStatsByPartnerId = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_BRANCH_STATS_BY_PARTNERID +
      `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}&type=customer`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getBranchCommunicateByEventName = (sdate, edate, body, admin, templateIdTosend, headerType, partnerId, eventName, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': 'Message',
    'body': body,
    'empId': admin.emailId,
    'templateId': templateIdTosend,
    'headerType': headerType,
    'partnerId': partnerId,
    'eventName': eventName,
  }
  const headerConfig = {
    headers: {},
  };
  console.log(data)

  // axios
  //   .post(APIS.GET_BRANCH_COMMUNICATE_BY_EVENT_NAME, data, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};

export const getIncompleteStatsByPartnerId = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_IN_COMPLETE_STATS_BY_PARTNER_ID +
      `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getIncompleteDataByPartnerId = (payload, pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_IN_COMPLETE_DATA_BY_PARTNER_ID + `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}&pageNo=${pageNo}&count=${noOfEntry}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const BulkCommunicateByFormStatus = (sdate, edate, body, admin, templateIdTosend, headerType, partnerId, formStatus, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['message'],
    'mailbody': body,
    'empId': admin.emailId,
    'templateId': templateIdTosend,
    'headerType': headerType,
    'partnerId': partnerId,
    'formStatus': formStatus,
  }

  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.BULK_COMMUNICATION_BY_FORM_STATUS, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};
export const BulkCommunicateByFormStatusMail = (sdate, edate, body, admin, partnerId, formStatus, subject, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['mail'],
    'mailbody': body,
    'empId': admin.emailId,
    'partnerId': partnerId,
    'formStatus': formStatus,
    'subject': subject
  }
  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.BULK_COMMUNICATION_BY_FORM_STATUS, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};
export const BulkCommunicateByFormStatusNotification = (sdate, edate, body, admin, partnerId, formStatus, subject, imgUrl, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['notification'],
    'mailbody': body,
    'empId': admin.emailId,
    'partnerId': partnerId,
    'formStatus': formStatus,
    'subject': subject,
    'imgUrl': imgUrl
  }
  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.BULK_COMMUNICATION_BY_FORM_STATUS, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};
export const BulkCommunicateByLoanStatus = (sdate, edate, body, admin, templateIdTosend, headerType, partnerId, formStatus, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['message'],
    'mailbody': body,
    'empId': admin.emailId,
    'templateId': templateIdTosend,
    'headerType': headerType,
    'partnerId': partnerId,
    'loanStatus': formStatus,
  }

  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.BULK_COMMUNICATION_BY_LOAN_STATUS, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};
export const BulkCommunicateByLoanStatusMail = (sdate, edate, body, admin, partnerId, formStatus, subject, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['mail'],
    'mailbody': body,
    'empId': admin.emailId,
    'partnerId': partnerId,
    'loanStatus': formStatus,
    'subject': subject
  }
  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.BULK_COMMUNICATION_BY_LOAN_STATUS, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};
export const BulkCommunicateByLoanStatusNotification = (sdate, edate, body, admin, partnerId, formStatus, subject, imgUrl, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['notification'],
    'mailbody': body,
    'empId': admin.emailId,
    'partnerId': partnerId,
    'loanStatus': formStatus,
    'subject': subject,
    'imgUrl': imgUrl
  }
  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.BULK_COMMUNICATION_BY_LOAN_STATUS, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};
export const BulkCommunicateByEventStatus = (sdate, edate, body, admin, templateIdTosend, headerType, partnerId, formStatus, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['message'],
    'mailbody': body,
    'empId': admin.emailId,
    'templateId': templateIdTosend,
    'headerType': headerType,
    'partnerId': partnerId,
    'eventName': formStatus,
  }

  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.GET_BRANCH_COMMUNICATE_BY_EVENT_NAME, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};
export const BulkCommunicateByEventStatusMail = (sdate, edate, body, admin, partnerId, formStatus, subject, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['mail'],
    'mailbody': body,
    'empId': admin.emailId,
    'partnerId': partnerId,
    'eventName': formStatus,
    'subject': subject
  }
  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.GET_BRANCH_COMMUNICATE_BY_EVENT_NAME, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};
export const BulkCommunicateByEventStatusNotification = (sdate, edate, body, admin, partnerId, formStatus, subject, imgUrl, callBack, error) => {
  let data = {
    'sdate': sdate,
    'edate': edate,
    'typeList': ['notification'],
    'mailbody': body,
    'empId': admin.emailId,
    'partnerId': partnerId,
    'eventName': formStatus,
    'subject': subject,
    'imgUrl': imgUrl
  }
  const headerConfig = {
    headers: {},
  };
  console.log(data)
  // axios
  //   .post(APIS.GET_BRANCH_COMMUNICATE_BY_EVENT_NAME, payload, headerConfig)
  //   .then((res) => {
  //     callBack(res.data);
  //   })
  //   .catch((e) => {
  //     error(e.res.data);
  //   });
};

export const getAppliedCountStatsByPartnerId = (payload, pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_APPLIED_COUNT_STATS_BY_PARTNER_ID +
      `${payload.partnerId}&from=${payload.fromDate}&to=${payload.toDate}&pageNo=${pageNo}&noOfEntry=${noOfEntry}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getAppliedDataByPartnerId = (payload, pageNo, noOfEntry, loanStatus, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_APPLIED_DATA_BY_PARTNER_ID + `${payload.partnerId}&from=${payload.fromDate}&to=${payload.toDate}&pageNo=${pageNo}&count=${noOfEntry}&loanStatus=${loanStatus}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res);
    });
};

export const getAppliedDataByPartnerIdAndLoanStatus = (
  payload,
  page, count,
  callBack,
  error
) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_APPLIED_DATA_BY_PARTNER_ID_LOAN_STATUS +
      `${payload.partnerId}&from=${payload.fromDate}&to=${payload.toDate}&loanStatus=${payload.loanStatus}&pageNo=${page}&count=${count}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getDisbursedCountStatsByPartnerId = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_DISBURSED_COUNT_STATS_BY_PARTNER_ID +
      `${payload.partnerId}&from=${payload.fromDate}&to=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};

// dashboard

export const getDisbursedStats = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_DISBURSMENT_STATS +
      `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getDisbursedStatsOverall = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_DISBURSE_OVERALL_COUNT +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getCountByStatusByPartnerId = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_COUNT_BY_STATUS_BY_PARTNER_ID +
      `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getRejectReasonCount = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_REJECT_REASON_COUNT +
      `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getTotalLeadsRecieved = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_TOTAL_LEADS_RECIEVED +
      `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getLeadParticular = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_LEAD_PARTICULAR +
      `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getConverstionDetails = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_CONVERSTION_DETAILS +
      `${payload.partnerId}&startDate=${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getDeviationReport = (payload, pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_DEVIATION_REPORT +
      `${payload.fromDate}&endDate=${payload.toDate}&pageNo=${pageNo}&noOfEntry=${noOfEntry}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};

export const communicateViaFilterApi = (payload, communicateThrough, status, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.COMMUNICATE_VIA_FILTER +
      `${payload.fromDate}&endDate=${payload.toDate}&communicateThrough=${communicateThrough}&status=${status}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const statusWiseCommunicationMarketSms = (
  typeList,
  mailbody,
  subject,
  empId,
  imageUrl,
  userIdList,
  templateIdTosend,
  messageHeader,
  payload,
  communicateViaFilter,
  status,
  callBack
) => {
  var data = {
    typeList: typeList,
    mailbody: mailbody,
    subject: subject,
    empId: empId,
    imageUrl: imageUrl,
    userIdList: userIdList,
    templateId: templateIdTosend,
    messageHeader: messageHeader,
    startDate: payload.fromDate,
    endDate: payload.toDate,
    communicationThrough: communicateViaFilter,
    status: status

  }
  console.log(data)
  // fetch(APIS.BULK_COMMUNICATION, {
  //   method: 'POST',
  //   headers: POST_HEADER,
  //   body: JSON.stringify(data)
  // })
  //   .then(res => res.text())
  //   .then(text => {
  //     callBack(text)
  //   })
}
export const getBuisnessFromAgreegatorsApi = (payload, pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.BUISNESS_FROM_AGREEGATORS +
      `${payload.fromDate}&endDate=${payload.toDate}&pageNo=${pageNo}&noOfEntry=${noOfEntry}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const bulkCommunicateByCsvApiNew = (
  csvFile,
  type,
  requestFrom,
  imgUrl,
  body,
  subject,
  admin,
  templateIdTosend,
  headerType,
  callBack
) => {

  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('csvfile', csvFile)
    imageFormData.append('type', type)
    imageFormData.append('empId', admin.emailId)
    imageFormData.append('subject', subject)
    imageFormData.append('requestFrom', requestFrom)
    imageFormData.append('body', body)
    imageFormData.append('imageUrl', imgUrl)
    imageFormData.append('templateId', templateIdTosend)
    imageFormData.append('headerType', headerType)

    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.BULK_COM_BY_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)

  })


}
export const getAutomationLogsNewApi = (payload, pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_AUTOMATION_LOGS_NEW +
      `${payload.fromDate}&endDate=${payload.toDate}&pageNo=${pageNo}&noOfEntry=${noOfEntry}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getAutomationLogsCountNewApi = (payload, pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_AUTOMATION_LOGS_COUNT +
      `${payload.fromDate}&endDate=${payload.toDate}&pageNo=${pageNo}&noOfEntry=${noOfEntry}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getOverallparticularLead = (payload, pageNo, noOfEntry, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_LEAD_PARTICULAR_OVERALL +
      `${payload.fromDate}&endDate=${payload.toDate}&pageNo=${pageNo}&noOfEntry=${noOfEntry}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getMarketingDashboardCounts = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios.get(APIS.GET_DASHOBARD_COUNTS_MARKETING + `${payload.fromDate}&endDate=${payload.toDate}&partnerId=${payload.partnerId}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getOveralldashCounts = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios.get(APIS.GET_OVERALL_DASHBOARD_COUNTS + `${payload.fromDate}&endDate=${payload.toDate}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const leadsCountDashBaordApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios.get(APIS.GET_LEADS_COUNT_DASHBOARD_DATA + `${payload.fromDate}&endDate=${payload.toDate}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getDeviationCount = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios.get(APIS.GET_DEVIATION_COUNT + `${payload.fromDate}&endDate=${payload.toDate}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};


export const getRejectReasonCountOverall = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_REJECT_REASON_COUNT_OVERALL +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getConfigDetailsOfAgreegator = (partnerId, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_AGREEGATOR_CONFIG_BY_AGREEGATOR_ID + partnerId
      ,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};

export const getPlaningDetailsOfAgreegator = (partnerId, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_AGREEGATOR_MONTHLY_PLANING_BY_ID + partnerId
      ,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const leadParticularDataDateWise = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios.get(APIS.GET_LEAD_PARTICULAR_LEADS_DATA_DATE_WISE + `${payload.fromDate}&endDate=${payload.toDate}`, headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const salaryRangeWiseCountApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_SALARY_WISE_COUNT_DASHBOARD_DATA +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const ageRangeWiseCountApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_AGE_WISE_COUNT_DASHBOARD_DATA +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const emiWiseCountApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_EMI_WISE_COUNT_DASHBOARD_DATA +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const genderWiseCountApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_GENDER_WISE_COUNT_DASHBOARD_DATA +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const loanStatusWiseCountApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_LOAN_STATUS_WISE_COUNT_DASHBOARD_DATA +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const rejectReasonWiseCountApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_REJECT_REASON_WISE_COUNT_DASHBOARD_DATA +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const stateWiseCountApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_STATE_WISE_COUNT_DASHBOARD_DATA +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getAllFormStatusApi = (payload, callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(
      APIS.GET_ALL_FORM_STATS +
      `${payload.fromDate}&endDate=${payload.toDate}`,
      headerConfig
    )
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};