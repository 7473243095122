import React from 'react'

const ApproveDataPopup = ({ Sourcedata,close,sourceHandler,handler}) => (
        <div className="">
            <div className="background-blur"></div>
            <div className="popup-main small-popup container-fluid dbl-border">
                <div className="row from-group">
                    <div className="col-xs-12">
                        <button className="close" onClick={()=>close()}>&times;</button>
                        <h4 className="text-center" style={{ height: "18px" }}>Select Deduction Source<br />&nbsp;</h4>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12">
                    
                        <select className="form-control"  onChange={(e)=>handler(e)}>
                    <optgroup className="display-none">
                        <option value="">select Process</option>
                    </optgroup>
                    <optgroup label="Process">
                        {Sourcedata != null && Sourcedata != "" ?
                            Object.keys(Sourcedata).map((data, i) => {
                                return (
                                    <option key={i} value={data}>{data}</option>
                                )
                            })
                            : null}
                    </optgroup>
                </select>
                    
                                   </div>
                </div>
                <div className="row text-center">
                    <div className="col-xs-12">
                        <button className="profile-save-btn mt-0px"  onClick={e => sourceHandler(e)}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    )

export default ApproveDataPopup;