import React, { Component } from 'react'
import AddCategoryUi from '../../../presentationals/Admin/AdminCoupons/AddSubCategory.component'
import { saveSubCategory, deleteSubCategory } from './AddMerchentActionCreator.Component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { AddSubCategoryValidation } from './Validation'
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'

class SubCategoryAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopup: false,
            confirmationText: '',
            idForDelete: '',
            popupState: false,
            popupStatus: '',
            MerchantValidationMsg: '',
            catIdForTable: '',
            merchantData: {
                subCatName: '',
                selectSubCatStatus: '',
                categoryId: '',
                subCatId: ''
            }
        }
    }

    render() {
        const { listingOfCategory } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AddCategoryUi
                    merchantData={this.state.merchantData}
                    getDataHandler={this.getDataHandler.bind(this)}
                    postDataHandler={this.postDataHandler.bind(this)}
                    listingOfCategory={listingOfCategory}
                    ulSubCategoryData={this.ulSubCategoryData.bind(this)}
                    catIdForTable={this.state.catIdForTable}
                    MerchantValidationMsg={this.state.MerchantValidationMsg}
                    deleteSubCategory={this.deleteSubCategory.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            deleteSubCategory(this.state.idForDelete, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        confirmationPopup: false,
                        merchantData: {
                            subCatName: '',
                            selectSubCatStatus: '',
                            categoryId: '',
                            subCatId: ''
                        }
                    })
                    this.props.getAllData()
                }
            })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }
    deleteSubCategory(data){
        this.setState({
            idForDelete: data.subcategoryid,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete ' + data.subcategory_name
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    ulSubCategoryData(e, data) {
        let merchantData = Object.assign({}, this.state.merchantData);
        merchantData.subCatName = data.subcategory_name;
        merchantData.selectSubCatStatus = data.status;
        merchantData.subCatId = data.subcategoryid;
        merchantData.categoryId = data.categoryid;
        this.setState({ merchantData })
    }

    getDataHandler(e, type) {
        let merchantData = Object.assign({}, this.state.merchantData);
        if (type == "subCatName") {
            merchantData.subCatName = e.target.value;
        } else if (type == "selectSubCatStatus") {
            merchantData.selectSubCatStatus = e.target.value == 'Active' ? true : false;
        } else if (type == "selectCatId") {
            merchantData.categoryId = e.target.value;
            this.setState({ catIdForTable: e.target.value })
        }
        this.setState({ merchantData, MerchantValidationMsg: '' })
    }

    postDataHandler() {
        const { admin } = this.props
        let MerchantValidation = AddSubCategoryValidation(
            this.state.merchantData
        )
        if (MerchantValidation.status) {
            this.setState({
                MerchantValidationMsg: MerchantValidation,
            })
            saveSubCategory(this.state.merchantData, admin, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'SubCategory saved Successfully',
                        merchantData: {
                            subCatName: '',
                            selectSubCatStatus: '',
                            categoryId: '',
                            subCatId: ''
                        }
                    })
                    this.removePopup();
                    this.props.getAllData()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup();
                }
            })
        } else {
            this.setState({
                MerchantValidationMsg: MerchantValidation,
            })
        }
    }
}

export default SubCategoryAdd;