import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  saveTentativeDataApi,
  getUserDetailByLoanId,
  deactivateNachByLoanId
} from '../../AdminActionCreator.component'
import { calculateEmiApi } from '../../../Forms/PhocketActionCreator.component'
import Popup from '../../../../presentationals/Popup/Popup.component'
import { _formatDateInDash } from '../../../../../utils/validation'
import UserFinancial from '../../../../presentationals/Admin/CustomerPages/UserFinancial.component'
import BulletToEmiPopup from '../../../Dashboard/BulletToEmi.container'
import { APIS } from '../../../../../utils/api-factory'
import { ROLE } from '../../../../../utils/Constant'
import Finance from './userFinanceCard.container'
import Transaction from '../../../../containers/Admin/CustomerPages/CardsUtilityPages/TransactionCards.container'
import BadaBuisnessTransaction from '../../../../containers/Admin/CustomerPages/BadabuisnessTransaction.container'
import Emi from '../../CardsUtility/Emi/UserEmi.Component'
import Installment from '../CardsUtilityPages/InstallmentCards.container'

class FinancialUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // allDetail: this.props.allDetail,
      repeatApplication: false,
      tentativePopupState: false,
      popupState: false,
      popupStatus: '',
      lastEmiDate: '',
      BulletToEmiState: false,
      loanDataForEmiChange: '',
      tentativeDetail: {
        tentativeAmount: '',
        // lastEmiDate: '',
        // tentativeTenure: '',
        tentativeShift: '',
        selectEMI: ''
      },
      emiTableData: [],
      pageViewState: 'finance',
      hideDeactivateButton: false
    }
  }
  checkEnachStatus() {
    const { userDetail } = this.props;
    if (userDetail != '') {
      deactivateNachByLoanId(userDetail.loanId, callBack => {
        if (callBack == '') {
          this.setState({ hideDeactivateButton: false })
        } else {
          this.setState({ hideDeactivateButton: true })

        }
      })
    }
  }
  componentDidMount() {
    // this.checkEnachStatus()
  }
  render() {
    const { userDetail, admin, allDetail } = this.props
    return (
      <div>
        <div className='btn-group-tab'>
          <button
            className={this.state.pageViewState == 'finance' ? 'active' : ''}
            onClick={e => this.sendHandler('finance')}
          >
            Loan Report
          </button>
          {admin != null && admin.rolelist.indexOf(ROLE.USER_EMI) >= 0 ?
            <button
              className={this.state.pageViewState == 'emi' ? 'active' : ''}
              onClick={e => this.sendHandler('emi')}
            >
              Emi
            </button>
            : ""}
          {userDetail.loanFrom!=='bazarBussiness'&&admin != null && admin.rolelist.indexOf(ROLE.TRANSACTION) >= 0 ? (
            <button
              className={
                this.state.pageViewState == 'transaction' ? 'active' : ''
              }
              onClick={e => this.sendHandler('transaction')}
            >
              Transaction
            </button>
          ) : null}
              {userDetail.loanFrom==='bazarBussiness'? (
            <button
              className={
                this.state.pageViewState == 'badabuisnessTransaction' ? 'active' : ''
              }
              onClick={e => this.sendHandler('badabuisnessTransaction')}
            >
              Transaction
            </button>
          ) : null}
          {userDetail.loanFrom!=='bazarBussiness'&&admin != null && admin.rolelist.indexOf(ROLE.TRANSACTION) >= 0 ? (
            <button
              className={
                this.state.pageViewState == 'installment' ? 'active' : ''
              }
              onClick={e => this.sendHandler('installment')}
            >
              {' '}
              Installment
            </button>
          ) : null}
        </div>

        {this.state.pageViewState == 'finance' ? (
          <Finance
            getData={this.props.getData.bind(this)}
            allDetail={allDetail}
            admin={admin}
            userDetail={allDetail}
            userDetails={userDetail}
            hideDeactivateButton={this.state.hideDeactivateButton}
          />
        ) : this.state.pageViewState == 'emi' ? (
          <Emi
            userDetail={userDetail}
            getData={this.props.getData.bind(this)}
            allDetail={this.props.allDetail}
            emiDetail={this.state.emiDetail}
            admin={admin}
          />
        ) : this.state.pageViewState == 'transaction' ? (
          <Transaction
            getData={this.props.getData.bind(this)}
            allDetail={this.props.allDetail}
            userDetail={userDetail}
            admin={admin}
          />
        ) : this.state.pageViewState == 'badabuisnessTransaction' ? (
          <BadaBuisnessTransaction
            getData={this.props.getData.bind(this)}
            allDetail={allDetail}
            admin={admin}
          />
        )
         : this.state.pageViewState == 'installment' ? (
          <Installment
            getData={this.props.getData.bind(this)}
            allDetail={allDetail}
            userDetail={userDetail}
            admin={admin}
          />
        ) : null}
      </div>
    )
  }
  sendHandler(type) {
    this.setState({
      pageViewState: type
    })
  }
}
const mapStateToProps = ({ pendingDetail }) => {
  return {
    pendingDetail: pendingDetail
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(FinancialUser)
