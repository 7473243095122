import React, { useState } from "react";
import { getCompleteApplicationCountsApi } from "../Admin/AdminActionCreator.component";
import moment from "moment";
import Loader from '../../presentationals/Loader/Loader.component'
const CompleteApplicationTracker = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [objectData, setObjectData] = useState(null);
    const [loaderState, setloaderState] = useState(false);


    const Submit = () => {
        setloaderState(true)
        getCompleteApplicationCountsApi(
            moment(startDate).format('DD-MM-YYYY'),
            moment(endDate).format('DD-MM-YYYY'),
            callBack => {
                setObjectData(callBack);
                setloaderState(false)

            }
        );
    }

    const changeHandler = (e, type) => {
        if (type === 'sDate') {
            setStartDate(e.target.value);
            if (moment(e.target.value).add(3, 'months').isBefore(moment(endDate))) {
                setEndDate(moment(e.target.value).add(3, 'months').format('YYYY-MM-DD'));
            }
        }
        if (type === 'eDate') {
            setEndDate(e.target.value);
            if (moment(e.target.value).subtract(3, 'months').isAfter(moment(startDate))) {
                setStartDate(moment(e.target.value).subtract(3, 'months').format('YYYY-MM-DD'));
            }
        }
    }
    // Select All,checkbounce,incompleteapplication,softapproval
    return (
        <div className="container-fluid">
            {loaderState?
          <Loader/>  
        :""}
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <h3><b>Data As Per Applied Date</b></h3>
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>Start Date</label>
                    <input value={startDate} onChange={(e) => changeHandler(e, 'sDate')} className="form-control" type="date" />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>End Date</label>
                    <input value={endDate} onChange={(e) => changeHandler(e, 'eDate')} className="form-control" type="date" />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <button onClick={Submit} style={{ marginTop: '22px', padding: '10px' }} className="btn btn-primary">Search</button>
                </div>
            </div>

            {objectData && Object.keys(objectData).map(month => (
                <div key={month} className="col-sm-4 col-xs-12  income" style={{ marginBottom: '20px' }}>
                    <h4><b>{month}</b></h4>
                    <div className="">
                        <table className='income-table-new' style={{ border: '2px solid #b9d8f9', borderRadius: '5px' }}>
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '1px solid #000' }}>Repeat</th>
                                    <td style={{ borderBottom: '1px solid #000' }}>{objectData[month].repeat}</td>
                                </tr>
                                <tr>
                                    <th>Fresh</th>
                                    <td>{objectData[month].new}</td>
                                </tr>
                            </thead>
                        </table>

                        {/* <table>
                            <thead>
                                <tr>
                                    <th>Repeat</th>
                                    <th>Fresh</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{objectData[month].repeat}</td>
                                    <td>{objectData[month].new}</td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>
                    <div className="phocket-table-new">
                        <h4><b>{' Status-wise Breakdown'}</b></h4>

                        <table className="" style={{ border: '2px solid #b9d8f9', borderRadius: '18px' }}>
                            <thead>
                                <tr>
                                    <th>Loan Status</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(objectData[month].loanStatus).map(([key, value]) => (
                                    key!=='Select All'&&key!=='Cheque Bounced'&&key!=='Incomplete Application'&&key!=='Soft Approval'?
                                    <tr key={key}>
                                        <td>{key==='totalSum'?<b>Total Sum</b>:key}</td>
                                        <td>{key === 'totalSum' ? <b>{value}</b> : value}</td>
                                    </tr>
                                    :""
                                ))}

                                {/* <tr>
                                    <td>Total</td>
                                    <td><b>{objectData[month].loanStatus.totalSum}</b></td>
                                </tr> */}

                            </tbody>
                        </table>
                        <table className="" style={{ border: '2px solid #b9d8f9', borderRadius: '18px',marginTop:'30px' }}>
                            <thead>
                                {/* <tr>
                                    <th>Loan Status</th>
                                    <th>Count</th>
                                </tr> */}
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td>Repeat</td>
                                    <td>{objectData[month].repeat}</td>
                                </tr>
                                <tr>
                                    <td>New</td>
                                    <td>{objectData[month].new}</td>
                                </tr> */}
                                <tr>
                                    <td style={{width:'67%'}}> Auto Rejected By Crif</td>
                                    <td style={{width:'50%'}}>{objectData[month].crifAutoRejected}</td>
                                </tr>
                                <tr>
                                    <td style={{width:'67%'}}>Assigned to CR</td>
                                    <td style={{width:'50%'}}>{objectData[month].assignedToCS}</td>
                                </tr>
                                <tr>
                                    <td style={{width:'67%'}}>Rejected by CR</td>
                                    <td style={{width:'50%'}}>{objectData[month].rejectedByCS}</td>
                                </tr>
                                <tr>
                                    <td style={{width:'67%'}}>Expired by CR</td>
                                    <td style={{width:'50%'}}>{objectData[month].expiredByCS}</td>
                                </tr>
                                {/* <tr>
                                    <td style={{width:'50%'}}>Auto Expired</td>
                                    <td style={{width:'50%'}}>{objectData[month].autoExpired}</td>
                                </tr> */}
                                <tr>
                                    <td style={{width:'50%'}}>Assigned For Credit</td>
                                    <td style={{width:'50%'}}>{objectData[month].assignedToCredit}</td>
                                </tr>
                                <tr>
                                    <td style={{width:'50%'}}>Cancelled After Assignment</td>
                                    <td style={{width:'50%'}}>{objectData[month].cancelled}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CompleteApplicationTracker;
