import React from 'react'
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';


const data = [
  {
    name: 'Abhinav', uv: 1907772, Action: 'Disburse', amt: '22-01-2019', Time: '1:30pm',
  },
  {
    name: ' Vishal', uv: 1907773, Action: 'Communicate', amt: '23-05-2019', Time: '3:30pm',
  },
  {
    name: 'Girish', uv: 1907774, Action: 'Reject', amt: '25-05-2019', Time: '4:30pm',
  },
  {
    name: 'Abhinav', uv: 1907772, Action: 'Disburse', amt: '22-01-2019', Time: '1:30pm',
  },
  {
    name: ' Vishal', uv: 1907773, Action: 'Communicate', amt: '23-05-2019', Time: '3:30pm',
  },
  {
    name: 'Girish', uv: 1907774, Action: 'Reject', amt: '25-05-2019', Time: '4:30pm',
  },
  {
    name: 'Abhinav', uv: 1907772, Action: 'Disburse', amt: '22-01-2019', Time: '1:30pm',
  },
  {
    name: ' Vishal', uv: 1907773, Action: 'Communicate', amt: '23-05-2019', Time: '3:30pm',
  },
  {
    name: 'Girish', uv: 1907774, Action: 'Reject', amt: '25-05-2019', Time: '4:30pm',
  },
];

const ActivityEmp = ({ filterHandler }) => (
  <div className="container-fluid ">
    <div className="col-xs-12  "><i className="fa fa-filter filter pointer" onClick={e => filterHandler()} aria-hidden="true"></i><strong>Filter</strong>
    </div>
    <h3 className="text-center blue-text"><strong>EMPLOYEE ACTIVITY</strong></h3>
    <div className="overflow-x-auto">
      <table className="phocket-table ">
        <thead className="headcol">
          <tr>
            <th>Application Id</th>
            <th>Name</th>
            <th>Stay in CA more than 1 years</th>
            <th>CA=Adhar Address</th>
            <th>CA=PA</th>
            <th>CA=owned</th>
            <th>Total work exp > 3 years</th>
            <th>Current Company Work exp > 1 year</th>
            <th>Company Category</th>
            <th>0</th>
            <th>Salary on time</th>
            <th>EMI / Income Ratio</th>
            <th>Presence of existing loan</th>
            <th>No emi defaults evident</th>
            <th>Ability to save / Opening and closing balance</th>
            <th>Presence of high value transaction</th>
            <th>No. of overdue accounts/Total accounts</th>
            <th>CIBIL score</th>
            <th>Income</th>
            <th>Obligations</th>
            <th>Surplus</th>
            <th>Name</th>
            <th>Presence of high value transaction</th>
            <th>No. of overdue accounts/Total accounts</th>
            <th>CIBIL score</th>
            <th>Income</th>
            <th>Obligations</th>
            <th>Surplus</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Application Id</td>
            <td>Name</td>
            <td>Stay in CA more than 1 years</td>
            <td>CA=Adhar Address</td>
            <td>CA=PA</td>
            <td>CA=owned</td>
            <td>Total work exp > 3 years</td>
            <td>Current Company Work exp > 1 year</td>
            <td>Company Category</td>
            <td>0</td>
            <td>Salary on time</td>
            <td>EMI / Income Ratio</td>
            <td>Presence of existing loan</td>
            <td>No emi defaults evident</td>
            <td>Ability to save / Opening and closing balance</td>
            <td>Presence of high value transaction</td>
            <td>No. of overdue accounts/Total accounts</td>
            <td>CIBIL score</td>
            <td>Income</td>
            <td>Obligations</td>
            <td>Surplus</td>
            <td>Name</td>
            <td>Presence of high value transaction</td>
            <td>No. of overdue accounts/Total accounts</td>
            <td>CIBIL score</td>
            <td>Income</td>
            <td>Obligations</td>
            <td>Surplus</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
)

export default ActivityEmp;