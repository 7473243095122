import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

//subject: Reminder: Auto debit of your Phocket Loan Repayment amount (with NACH).

const AutoDebitWithNach = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.userName != null ? allDetail.userName : ""},</div>
        <br />
        <div>Your BorrowBuddy loan is due on {allDetail != null && allDetail.loanPaybackDate != null ? allDetail.loanPaybackDate.split(' ')[0] : ""} and repayment amount is {allDetail != null && allDetail.loanPaybackAmount != null ? allDetail.loanPaybackAmount : ""}.</div>
        <br />
        <div>As we can see in our records your NACH auto debit facility is activated with us.</div>
        <br />
        <div>Please maintain sufficient balance to avoid any inconvenience.</div>
        <br />
        <div>Kindly intimate if already paid.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default AutoDebitWithNach