import React from 'react';
import UserEmiHistory from '../../../presentationals/Admin/Emi/EmiHistory.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { APIS } from '../../../../utils/api-factory'

class EmiHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            popupState: false,
            popupStatus: '',
            allDetail: this.props.allDetail,
            emiDetail: '',
            selectedValue: ''
        }
    }

    componentDidMount() {
        const { userDetail } = this.props
        fetch(APIS.GET_EMIS_HISTORY_CARDS + userDetail.userId)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    emiDetail: json,
                    selectedValue: Object.keys(json)[0]
                })
            })
    }


    render() {
        const { userDetail } = this.props
        return (
            <div>
                <p className="height-20px text-center margin-0">{this.state.errorMsg}</p>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <UserEmiHistory
                    userDetail={userDetail}
                    emiDetail={this.state.emiDetail}
                    applicationChange={this.applicationChange.bind(this)}
                    selectedValue={this.state.selectedValue}
                />
            </div>
        )
    }

    applicationChange(e) {
        this.setState({
            selectedValue: e.target.value
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

}
export default EmiHistory;