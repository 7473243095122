import React, { useEffect, useState } from "react";
import { getAllBusiness } from "../targetactioncreator";
import ReactApexChart from 'react-apexcharts';
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import Nodata from '../../../../../images/nodata.png'
const ExpensesList = ({ editHandler,ActiveMonthFilter }) => {
    // const expenseData = {
    //     "expenseAmount": 500.75,
    //     "addedBy": "John Doe",
    //     "gstAmount": 75.11,
    //     "isGSTInclusive": true,
    //     "isVerified": false,
    //     "verifiedBy": "Auditor Name" ,
    //     "id":"1"
    // };
    const [expenseData, setExepensedata] = useState([
        {
            "noOfApplicationTarget": 100,
            "noOfApprovalTarget": 80,
            "noOfDisbursementTarget": 60,
            "aumAmountTarget": 50000,
            "disbursementAmountTarget": 40000,
            "avgTicketSize3MonthsTarget": 5000,
            "avgTicketSizeMoreThan3MonthsTarget": 7000,
            "avgTicketSizeTarget": 6000,
            "month": "September",
            "year": 2024,
            "noOfApplicationAchieved": 90,
            "noOfApprovalAchieved": 75,
            "noOfDisbursementAchieved": 55,
            "aumAmountAchieved": 48000,
            "disbursementAmountAchieved": 38000,
            "avgTicketSizeMoreThanMonthsAchieved": 6800,
            "avgTicketSizeMoreThan3MonthsAchieved": 7100,
            "avgTicketSizeAchieved": 6500,
            "revenueExpected": 100000,
            "revenueAchieved": 95000,
            "collectionExpected": 85000,
            "collectionAchieved": 82000,
            "graphData": [{
                name: 'Income',
                type: 'column',
                data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
            }, {
                name: 'Cashflow',
                type: 'column',
                data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
            }, {
                name: 'Revenue',
                type: 'line',
                data: [20, 29, 37, 36, 44, 45, 50, 58]
            }]
        },
        {
            "noOfApplicationTarget": 120,
            "noOfApprovalTarget": 90,
            "noOfDisbursementTarget": 70,
            "aumAmountTarget": 55000,
            "disbursementAmountTarget": 45000,
            "avgTicketSize3MonthsTarget": 5200,
            "avgTicketSizeMoreThan3MonthsTarget": 7200,
            "avgTicketSizeTarget": 6200,
            "month": "August",
            "year": 2024,
            "noOfApplicationAchieved": 100,
            "noOfApprovalAchieved": 85,
            "noOfDisbursementAchieved": 65,
            "aumAmountAchieved": 53000,
            "disbursementAmountAchieved": 43000,
            "avgTicketSizeMoreThanMonthsAchieved": 6900,
            "avgTicketSizeMoreThan3MonthsAchieved": 7300,
            "avgTicketSizeAchieved": 6600,
            "revenueExpected": 110000,
            "revenueAchieved": 102000,
            "collectionExpected": 90000,
            "collectionAchieved": 88000,
            "graphData": [{
                name: 'Income',
                type: 'column',
                data: [1.5, 2.1, 2.6, 1.6, 2.6, 2.9, 3.9, 4.7]
            }, {
                name: 'Cashflow',
                type: 'column',
                data: [1.2, 3.1, 3.2, 4.1, 4.2, 5, 6.6, 8.6]
            }, {
                name: 'Revenue',
                type: 'line',
                data: [21, 30, 38, 37, 45, 46, 51, 59]
            }]
        }
    ]);
    
    const [openAccordion, setOpenAccordion] = useState(null);


    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };

    useEffect(() => {
        getAllBusiness(callback => {
            setExepensedata(callback.data)
        })
    }, [ActiveMonthFilter])

    const tableDatacheck = (data) => {
        if (data == null || data == undefined || data == '') {
            return '-'
        } else {
            return data
        }
    }

    const handleDelete = () => {
    };

    const options = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        xaxis: {
            categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
        },
        yaxis: [
            {
                seriesName: 'Income',
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    }
                },
                title: {
                    text: "Income (thousand crores)",
                    style: {
                        color: '#008FFB',
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: 'Cashflow',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#00E396'
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    }
                },
                title: {
                    text: "Operating Cashflow (thousand crores)",
                    style: {
                        color: '#00E396',
                    }
                },
            },
            {
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019'
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: "Revenue (thousand crores)",
                    style: {
                        color: '#FEB019',
                    }
                }
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
    }

    return (
        // <div className="container-fluid Executor">
        //     <div
        //         className=""
        //         style={{
        //             background: '#f2f2f2',
        //             padding: '10px',
        //             margin: '50px 0 0',
        //             borderRadius: '5px',
        //             overflow: 'auto',
        //             // maxWidth: '1000px' 
        //         }}
        //     >
        //         <div className="">
        //             <table className='table-logs th-width' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
        //                 <thead>
        //                     <tr>
        //                         <th>No. Of Application Target</th>
        //                         <th>No. Of Approval Target</th>
        //                         <th>No. Of Disbursement Target</th>
        //                         <th>AUM Amount Target</th>
        //                         <th>Disbursement Amount Target</th>
        //                         <th>Average Ticket Size 3 Months Target</th>
        //                         <th>{'Average Ticket Size > 3 Months Target'}</th>
        //                         <th>Average Ticket Size Target</th>
        //                         <th>Month</th>
        //                         <th>year</th>
        //                         <th>No. Of Application Achieved</th>
        //                         <th>No. Of Approval Achieved</th>
        //                         <th>No. Of Disbursement Achieved</th>
        //                         <th>AUM Amount Achieved</th>
        //                         <th>Disbursement Amount Achieved</th>
        //                         <th>{'Average Ticket Size > Months Achieved'}</th>
        //                         <th>{'Average Ticket Size > 3 Months Achieved'}</th>
        //                         <th>Average Ticket Size Achieved</th>
        //                         <th>Revenue Expected</th>
        //                         <th>Revenue Achieved</th>
        //                         <th>Collection Expected</th>
        //                         <th>Collection Achieved</th>

        //                         <th>Edit</th>
        //                         <th>Delete</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
        //                         <td className='text-center'>{expenseData.numberOfApplicationTarget}</td>
        //                         <td>{expenseData.numberOfApprovalTarget}</td>
        //                         <td>{expenseData.numberOfDisbursementTarget}</td>
        //                         <td>{expenseData.aumAmountTarget}</td>
        //                         <td>{expenseData.disbursementAmountTarget}</td>
        //                         <td>{expenseData.averageTicketSize3MonthsTarget}</td>
        //                         <td>{expenseData.averageTicketSizeMoreThan3MonthsTarget}</td>
        //                         <td>{expenseData.averageTicketSizeTarget}</td>
        //                         <td>{expenseData.month}</td>
        //                         <td>{expenseData.year}</td>
        //                         <td>{expenseData.numberOfApplicationAchieved}</td>
        //                         <td>{expenseData.numberOfApprovalAchieved}</td>
        //                         <td>{expenseData.numberOfDisbursementAchieved}</td>
        //                         <td>{expenseData.aumAmountAchieved}</td>
        //                         <td>{expenseData.disbursementAmountAchieved}</td>
        //                         <td>{expenseData.averageTicketSize3MonthsAchieved}</td>
        //                         <td>{expenseData.averageTicketSizeMoreThan3MonthsAchieved}</td>
        //                         <td>{expenseData.averageTicketSizeAchieved}</td>
        //                         <td>{expenseData.revenueExpected}</td>
        //                         <td>{expenseData.revenueAchieved}</td>
        //                         <td>{expenseData.collectionExpected}</td>
        //                         <td>{expenseData.collectionAchieved}</td>
                                
        //                         <td>
        //                             <i
        //                                 className="fa fa-pencil-square-o fa-lg"
        //                                 aria-hidden="true"
        //                                 onClick={() => editHandler(expenseData)}
        //                                 style={{ cursor: 'pointer' }}
        //                                 aria-label="Edit Expense"
        //                                 title="Edit Expense"
        //                             ></i>
        //                         </td>
        //                         <td>
        //                             <i
        //                                 className="fa fa-trash-o fa-lg"
        //                                 aria-hidden="true"
        //                                 onClick={() => handleDelete(expenseData)}
        //                                 style={{ cursor: 'pointer' }}
        //                                 aria-label="Delete Expense"
        //                                 title="Delete Expense"
        //                             ></i>
        //                         </td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </div>
        //     </div>
        // </div>
        <div className="projection-new">

        <div className="container-fluid Projection" style={{ padding: '20px 50px' }}>


            <div className="accords openaccord" style={{ background: '#fff' }}>
                <div className="">
                    {expenseData && expenseData?.length > 0 ? expenseData.map((item, index) => (
                        <AccordionItem
                            key={index}
                            id={index}
                            isOpen={openAccordion === index}
                            toggleAccordion={toggleAccordion}
                            item={item}
                            expenseData={expenseData}
                            tableDatacheck={tableDatacheck}
                            editHandler={editHandler}
                            handleDelete={handleDelete}
                            options={options}


                        />
                    )) :
                        <div className="text-center" style={{ marginTop: '20px', marginBottom: '20px' }}><img width={'30%'} src={Nodata} /></div>
                    }

                </div>

            </div>
        </div>


    </div>
    );
};

export default ExpensesList;


function AccordionItem({ id, isOpen, toggleAccordion, expenseData, item, tableDatacheck, editHandler, handleDelete, options }) {
    return (
        <div className={`accords accordsub ${isOpen ? 'openaccord' : ''}`}>
            <div className="heading table-responsive">
                <div className="box" onClick={() => toggleAccordion(id)} style={{ backgroundColor: isOpen ? 'black' : 'white' }}>
                    <h5 style={{ color: isOpen ? 'white' : 'black' }}>
                        {isOpen ? <ArrowUpward /> : <ArrowDownward />}
                    </h5>
                </div>
                <table className="table text-center tableheader">
                    <tbody>
                        <tr>
                            <td className='td1 subtd' style={{ width: '100px', textAlign: 'left', border: 'none' }}>Disbursement Amount Target</td>
                            <td><b>{item.disbursementAmountTarget}</b></td>
                            <td>
                                <i
                                    className="fa fa-pencil-square-o fa-lg"
                                    aria-hidden="true"
                                    onClick={() => editHandler(expenseData)}
                                    style={{ cursor: 'pointer' }}
                                    aria-label="Edit Expense"
                                    title="Edit Expense"
                                ></i>
                            </td>
                            <td>
                                <i
                                    className="fa fa-trash-o fa-lg"
                                    aria-hidden="true"
                                    onClick={() => handleDelete(expenseData)}
                                    style={{ cursor: 'pointer' }}
                                    aria-label="Delete Expense"
                                    title="Delete Expense"
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {isOpen && (
                <div className="bottomtable">
                    <div className={`subbottom table-responsive ${isOpen ? 'scrollanimation' : ''}`} style={{ overflow: 'hidden' ,border:'none'}}>
                        <div className="row">
                            <div className="col-md-6 col-xs-12" style={{padding:'10px 8px'}}>
                                <div className="tableedit" style={{boxShadow:'3px 3px 9px 1px #c4c1c1',borderRadius:'6px',height:'350px',padding:'10px',overflowY:'auto'}}>
                                    <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                        <table style={{ marginBottom: '10px' }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{padding:'20px 10px'}}>No. Of Application Target</th>
                                                    <td>{tableDatacheck(item.noOfApplicationTarget)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>No. Of Approval Target</th>
                                                    <td>{tableDatacheck(item.noOfApprovalTarget)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>No. Of Disbursement Target</th>
                                                    <td>{tableDatacheck(item.noOfDisbursementTarget)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>AUM Amount Target</th>
                                                    <td>{tableDatacheck(item.aumAmountTarget)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Average Ticket Size 3 Months Target</th>
                                                    <td>{tableDatacheck(item.avgTicketSize3MonthsTarget)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>{'Average Ticket Size > 3 Months Target'}</th>
                                                    <td>{tableDatacheck(item.avgTicketSizeMoreThan3MonthsTarget)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Average Ticket Size Target</th>
                                                    <td>{tableDatacheck(item.avgTicketSizeTarget)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Month</th>
                                                    <td>{tableDatacheck(item.month)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Year</th>
                                                    <td>{tableDatacheck(item.year)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>No. Of Application Achieved</th>
                                                    <td>{tableDatacheck(item.noOfApplicationAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>No. Of Approval Achieved</th>
                                                    <td>{tableDatacheck(item.noOfApprovalAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>No. Of Disbursement Achieved</th>
                                                    <td>{tableDatacheck(item.noOfDisbursementAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>AUM Amount Achieved</th>
                                                    <td>{tableDatacheck(item.aumAmountAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Disbursement Amount Achieved</th>
                                                    <td>{tableDatacheck(item.disbursementAmountAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>{'Average Ticket Size > Months Achieved'}</th>
                                                    <td>{tableDatacheck(item.avgTicketSizeMoreThanMonthsAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>{'Average Ticket Size > 3 Months Achieved'}</th>
                                                    <td>{tableDatacheck(item.avgTicketSizeMoreThan3MonthsAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Average Ticket Size Achieved</th>
                                                    <td>{tableDatacheck(item.avgTicketSizeAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Revenue Expected</th>
                                                    <td>{tableDatacheck(item.revenueExpected)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Revenue Achieved</th>
                                                    <td>{tableDatacheck(item.revenueAchieved)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Collection Expected</th>
                                                    <td>{tableDatacheck(item.collectionExpected)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{padding:'15px 10px'}}>Collection Achieved</th>
                                                    <td>{tableDatacheck(item.collectionAchieved)}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12" style={{padding:'10px 8px'}}>
                                <div className="tableedit" style={{boxShadow:'3px 3px 9px 1px #c4c1c1',borderRadius:'6px',height:'350px'}}>
                                    <div id="chart">
                                        <ReactApexChart options={options} series={item.graphData} type="line" height={350} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>);
}
