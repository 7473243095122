import moment from 'moment';
var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
var pattern = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
var specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
var passCheck = /([A-Z][0-9])/;


export const thirdpartyloginvalidation = (userEmailInput,password) => {
  
    if (userEmailInput == '') {
        return { status: false, msg: 'Please enter your Email', field: 'emailId' };
    } else if (!filter.test(userEmailInput)) {
        return { status: false, msg: 'Please enter a valid Email', field: 'emailId' };
    } else if (password == '') {
        return { status: false, msg: 'Please enter password', field: 'password' };
    }
    return { status: true, msg: '' };

}


export const thirdPartyRegistrationValidation = (userdetail) => {
    if (userdetail.companyName== '') {
        return { status: false, msg: 'Please enter Company Name', field: "companyname" };
    } else if (specialChar.test(userdetail.companyName)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'companyname' };
    }
    else if (userdetail.contactPersonName=='') {
        return { status: false, msg: 'Please enter Contact Person Name', field: "contactPersonName" };
    } else if (specialChar.test(userdetail.contactPersonName)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'contactPersonName' };
     } else if (userdetail.password == '') {
        return { status: false, msg: 'Please enter password', field: 'password' };
    }
    else if (userdetail.contactPersonMobileNumber == ""){
        return { status: false, msg: 'Please enter your Mobile No.', field: 'mobileNo' };
    } else if (userdetail.contactPersonMobileNumber.length > 10 || userdetail.contactPersonMobileNumber.length < 10) {
        return { status: false, msg: 'Please enter a valid 10 digit Mobile No.', field: 'mobileNo' };
    }
    else if (userdetail.contactPersonEmail == "") {
        return { status: false, msg: 'Please enter your Email Id', field: 'emailId' };
    } else if (!filter.test(userdetail.contactPersonEmail)) {
        return { status: false, msg: 'Please enter a valid Email Id', field: 'emailId' };
    }
    else if (userdetail.companywebaddress == "") {
        return { status: false, msg: 'Please enter Web Address', field: 'webaddress' };
    }
    else if (userdetail.companyregisteredaddress==''){
        return { status: false, msg: 'Please enter a valid Company Address', field: 'companyaddress' };
    }
    
    return { status: true, msg: ' ' };
    }