import React from 'react'
import QualityUi from '../../../presentationals/Admin/CustomerPages/AssetQuality.component'
import {  getQualityAssets } from '../AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/DotLoader.component'

class Quality extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      allData: '',
      loaderState:false
    }
  }
  render () {
    return (
      <div>
        <QualityUi
        allData={this.state.allData}
         />
         {this.state.loaderState?
         <Loader />
         :""}
      </div>
    )
  }
  componentDidMount(){
      this.setState({loaderState:true})
    getQualityAssets(callback=>{
        this.setState({allData:callback,loaderState:false})
    })
  }
}
export default Quality
