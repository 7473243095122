import React, { useEffect, useState } from "react";
import { getAllPolicyApi } from "../Analysis/analysisactionCreator";
import { saveOrUpdatePolicyDetails } from "../AdminActionCreator.component";

const AddPolicy = ({ editData }) => {
    const [objectData, setObjectDta] = useState({})
    const [editedData, setEditData] = useState({})

    const changeHandler = (e) => {
        let changeData = Object.assign({}, editedData)
        changeData.name = e.target.value
        setEditData(changeData)
    }
    const saveData = () => {
        var data = {

        }
        if (editData && editData.id) {
            data = {
                id: editData.id,
                policyId: editData.policyId,
                name: editedData.name,
                addedOn: editData.addedOn,
                updatedOn: editData.updatedOn
            }
        } else {
            data = {
                id: '',
                policyId: '',
                name: editedData.name,
                addedOn: '',
                updatedOn: ''
            }
        }
        if (data.name) {
            saveOrUpdatePolicyDetails(data, callback => {
                getAllPolicyApi(callback => {
                    setObjectDta(callback)
                })
            })
        }
    }
    useEffect(() => {
        if (editData) {
            let changeData = Object.assign({}, editedData)
            changeData.id = editData.id
            changeData.name = editData.name
            changeData.policyId = editData.policyId
            changeData.addedOn = editData.addedOn
            changeData.updatedOn = editData.updatedOn
            setEditData(changeData)

        }
    }, [])
    return (
        <div className="row" style={{ marginTop: '20px' }}>

            <div className="col-sm-3 col-xs-12"></div>
            <div className="col-sm-6 col-xs-12">
                <h3>{editData && editData.id ? 'Update' : 'Add'} Policy</h3>
                <div className="" style={{ marginTop: '22px' }}>
                    <textarea className="form-control" value={editedData.name} onChange={(e) => changeHandler(e)} />
                </div>
                <div className="text-center">
                    <button className="btn btn-primary" onClick={() => saveData()} style={{ marginTop: '20px' }}>Save</button>
                </div>
            </div>


            <div className="col-sm-3 col-xs-12"></div>


        </div>
    )
}
export default AddPolicy