import React from 'react'
import { ROLE, STATE_COLOR } from '../../../../utils/Constant'
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import AndroidLogo from '../../../../images/android.png'
import WebLogo from '../../../../images/globe.png'
import FlipkartLogo from '../../../../images/giftnew.png'
import LoanLogo from '../../../../images/LoanFinal.png'

const LeadManagement = ({ countforstatus, allUserDetail, userDetailHandler, userViewHandler, allStatus,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow, paginationHandler, leadComment,
    dataFilterState, followUpStatus, dataFilter, admin, loadarState, newDataBackground,
    todaysApplicationNo, showRepeateApplications, markAsImportant, userCommentHandler, userCommentSelect, tableMinPagination, tableMaxPagination, phocketTablePagination, nachCheckbox, nashHandler, showWhatsappMsg, commentType }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text text-center">Partial Leads</h3>
            </div>
        </div>
        {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
            <div className="row form-group">
                <div className="col-sm-2 col-xs-12">
                    <h4 className="text-center blue-text ">
                        {todaysApplicationNo != null ?
                            <a className="pointer" onClick={e => showRepeateApplications()}>Repeat Applications- {todaysApplicationNo.repeatUserCount}</a>
                            : ""}
                    </h4>
                </div>
                <div className="col-sm-2 col-xs-12">
                    <h4 className="text-center blue-text ">
                        {todaysApplicationNo != null ?
                            <span>New Applications- {todaysApplicationNo.newUserCount}</span>
                            : ""}
                    </h4>
                </div>
                {countforstatus != '' ?
                    <div className="col-sm-2 col-xs-12">
                        <h4 className="text-center  ">
                            Total Action Done:  {countforstatus.totalAssignmentDone+ countforstatus.totalPending}
                        </h4>
                    </div>
                    : ""}
                {countforstatus != '' ?
                    <div className="col-sm-3 col-xs-12">
                        <h4 className="text-center  ">
                            Total Assignment Done:  {countforstatus.totalAssignmentDone}
                        </h4>
                    </div>
                    : ""}
                {countforstatus != '' ?
                    <div className="col-sm-2 col-xs-12">
                        <h4 className="text-center  ">
                            Total Pending:  {countforstatus.totalPending}
                        </h4>
                    </div>
                    : ""}
            </div>
            : null} */}
        <div className="row">
            <ul className="loan-lead-search-new">
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> By Status</label>
                    <select className="form-control" value={followUpStatus} onChange={(e) => dataFilter(e, "status")}>
                        <optgroup className="display-none">
                            <option value="">Select Status</option>
                        </optgroup>
                        <optgroup label="Status">
                            {allStatus != null && allStatus != undefined && allStatus != '' ?
                                allStatus.map((status, i) => {
                                    return (
                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                    );
                                }) : ""}
                        </optgroup>
                    </select>
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Application No.</label>
                    <input type="number" className="form-control" value={dataFilterState.searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Email ID</label>
                    <input type="email" className="form-control" value={dataFilterState.searchByemail} onChange={e => dataFilter(e, "email")} />
                </li>

                <li>
                    <label className="font-12px margin-0" ><span className="fa fa-search" /> Mobile No.</label>
                    <input type="number" value={dataFilterState.searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
                </li>
                <li>
                    <label className="font-12px margin-0"><span className="fa fa-search" /> Date</label>
                    <DatePicker
                        value={dataFilterState.searchByDate}
                        selected={dataFilterState.searchByDateShow}
                        onChange={e => dataFilter(e, "date")}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                    />
                </li>
                <li>
                    <label className="font-12px margin-0" ><span className="fa fa-search" /> Comment Category</label>
                    <select className="form-control" onChange={e => dataFilter(e, "comment")} value={commentType}>
                        <option value=''>Select Comment</option>
                        <option value='Not Answering/Disconnecting'> Not Answering/Disconnecting</option>
                        <option value='Not reachable/Switched off'>Not reachable/Switched off</option>
                        <option value='KYC pending'>KYC pending</option>
                        <option value='Bank statement/Finbit'>Bank statement/Finbit</option>
                        <option value='Salary Slip/Employment Proof'>Salary Slip/Employment Proof</option>
                        <option value='Current Address'>Current Address</option>
                        <option value='About to Share Doc'>About to Share Doc</option>
                        <option value='Need to Call Back'>Need to Call Back</option>
                        <option value='Others'>Others</option>
                    </select>
                </li>
                <li>
                    <label className="font-12px margin-0" ><span className="fa fa-search" /> Select Salary Range</label>
                    <select className="form-control" onChange={e => dataFilter(e, "salaryRange")} value={dataFilterState.salaryRange}>
                        <option value=''>Select Range</option>
                        <option value='<30000'>Below 30k</option>
                        <option value='>=30000'>Above 30k</option>
                    </select>
                </li>

            </ul>
        </div>
        <div className="row">
            <div className="col-xs-12 text-right">
                <button className="admin-pagination-btn" id="refresh" onClick={e => paginationHandler('refresh')}><span className="fa fa-refresh fa-spin" /></button>
                {/* <button className="admin-pagination-btn" id="backword" onClick={e => paginationHandler('back')}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => paginationHandler('forward')}><span className="fa fa-chevron-right" /></button> */}
                {/* <p>1-100</p> */}
            </div>
        </div>
     
        <div className="row text-center">
            {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                <div>
                    <div className="overflow-x-auto phocket-table-new">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Assigned</th>
                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                        <th>Details</th> : "" : ""}
                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?
                                        <th>View</th> : "" : ""}
                                    {/* <th>Important</th> */}
                                    {/* {nachCheckbox ? <th>Nach</th> : null} */}
                                    {/* {commentColumn? */}

                                    {/* :""} */}
                                    <th>eSign</th>
                                    <th>Comment</th>
                                    <th>Already Save Comment</th>
                                    <th><i className="fa fa-whatsapp whats-app-approval whatsapp-icon" /></th>


                                    <th>Application Id</th>
                                    {/* <th>Type</th> */}
                                    {/* <th>Crif Decision</th> */}

                                    {/* <th>Loan Id</th> */}
                                    <th>state</th>
                                    <th>Name</th>
                                    <th>Salary</th>
                                    <th>Mobile No.</th>
                                    <th>Apply Date</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                    <th>Loan EMI</th>

                                    {/* <th>Installment</th> */}
                                    {/* <th>Tenure</th> */}
                                    {/* <th>Reason</th> */}
                                    {/* <th>Loan From</th> */}

                                    {/* <th>Device</th> */}

                                </tr>

                            </thead>
                            <tbody>
                                {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    return (
                                        <tr key={i} style={{ backgroundColor: data.completeMarked ? '#c3fcca' : data.incompleteMarked ? '#e4b9c0' : "" }} >
                                            <td>{data.assignUser}</td>

                                            <td>
                                                {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                                    <button onClick={e => userDetailHandler(data)}><span className="fa fa-info" /></button>
                                                    : "" : ""}
                                            </td>
                                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?

                                                <td>
                                                    <button onClick={e => userViewHandler(data)}><span className="fa fa-eye" /></button>
                                                </td>
                                                : "" : ""}
                                            {/* {nachCheckbox ? <td>
                                                <input type="checkbox" onChange={(e) => nashHandler(e, data)} checked={data.nachStatus} />
                                            </td> : null} */}
                                            <td>{data.eSignStatus != undefined ? data.eSignStatus ? <span>&#10003;</span> : <span>&#10005;</span> : null}</td>
                                            <td>
                                                <div style={{ display: 'flex', width: '197px' }} >
                                                    <select onChange={e => userCommentSelect(e)} className="form-control" placeholder='' >
                                                        <option value=''>Select Comment</option>
                                                        <option value='Not Answering/Disconnecting'> Not Answering/Disconnecting</option>
                                                        <option value='Not reachable/Switched off'>Not reachable/Switched off</option>
                                                        <option value='KYC pending'>KYC pending</option>
                                                        <option value='Bank statement/Finbit'>Bank statement/Finbit</option>
                                                        <option value='Salary Slip/Employment Proof'>Salary Slip/Employment Proof</option>
                                                        <option value='Current Address'>Current Address</option>
                                                        <option value='About to Share Doc'>About to Share Doc</option>
                                                        <option value='Need to Call Back'>Need to Call Back</option>
                                                        <option value='Others'>Others</option>
                                                    </select>
                                                    &nbsp;&nbsp;  <span className="fa fa-save loan-id-save-btn" style={{ fontSize: '20px' }} onClick={e => userCommentHandler(data)} />
                                                </div></td>
                                            <td>{data.adminCommentBody}</td>
                                            {/* <td> {data.important == true ?
                                                <span className="fa fa-star orange-text" onClick={e => markAsImportant(data, false)} />
                                                :
                                                <span className="fa fa-star-o" onClick={e => markAsImportant(data, true)} />
                                            }</td> */}
                                            <td>
                                                <span className={"fa fa-whatsapp whats-app-approval relative_position whatsapp-icon" + (data.whatAppUnreadCount > 0 ? " fa-lg pointer" : '')} onClick={e => showWhatsappMsg(data)}>
                                                    <a className="blink">{data.whatAppUnreadCount > 0 ? <div className="not-count-circle" >{data.whatAppUnreadCount}</div> : null}</a>
                                                </span>
                                            </td>
                                            <td>{data.applicationId}</td>
                                            {/* {data.typeOfProduct == 'Gift Card' ?
                                                <td><i style={{ fontSize: '22px', color: '#ff6347' }} class="fa fa-gift" aria-hidden="true"></i>
                                                </td>
                                                : data.typeOfProduct == 'Loan' ? <td><img src={LoanLogo} /></td> : data.typeOfProduct == null || data.typeOfProduct == '' ? <td>Empty</td> : ""} */}
                                            {/* <td>{data.crifDecision}</td> */}

                                            {/* <td>{data.applicationLoanId}</td> */}
                                            <td>{data.state}</td>
                                            <td style={{whiteSpace:'break-spaces'}}>{data.userName}</td>
                                            <td>{data.nettakehomesalary}</td>
                                            <td>{data.mobileNumber}</td>
                                            <td>{data.loanApplyDate}</td>
                                            <td>{data.applicationStatus}</td>
                                            <td>{data.loanAmount}</td>
                                            <td>{data.loanEmi}</td>

                                            {/* <td>{data.loanEmi}</td> */}
                                            {/* <td>{data.loanDuration}</td> */}
                                            {/* <td>{data.loanReason} </td> */}
                                            {/* <td>{data.loanFrom}</td> */}
                                            {/* {data.loginDevice == 'android' ?
                                                <td style={{ color: '#a4c639' }}><img src={AndroidLogo} width='40px' />
                                                </td>
                                                : data.loginDevice == 'website' ? <td style={{ color: 'blue' }}><img src={WebLogo} /></td>
                                                    : data.loginDevice == 'IOS' || data.loginDevice == 'ios' ? <td style={{ fontSize: '25px' }}><i class="fa fa-apple" aria-hidden="true"></i>
                                                    </td> : data.loginDevice == '' || data.loginDevice == null ? <td>Empty</td> : ""}        */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-3 col-xs-12 no-padding">     <button className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('back')}><span className="fa fa-chevron-left" /></button></div>
                            <div className="col-sm-2 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block  red" onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                            <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                            <div className="col-sm-2 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block  red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                            <div className="col-sm-3 col-xs-12 no-padding" > <button className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('forward')}><span className="fa fa-chevron-right" /></button> </div>
                        </div>
                    </div>
                </div>
                : ""}

        </div>
    </div>
)

export default LeadManagement;