import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const ChequeBounce = ({ chequeBounceData, getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Cheque Bounce Page</h3>
            </div>
        </div>
        <div className="row">
      
            {chequeBounceData != null && chequeBounceData != '' && chequeBounceData.length > 0 ?
                <div className='overflow-auto'>
                    <table className='phocket-table'>
                        <thead>

                            <tr>
                                <th>Loan Id</th>
                                <th>Application Id</th>
                                <th>Name</th>
                                <th>Mobile No.</th>
                                <th>Email ID</th>
                                <th>Cheque Bounce Date</th>
                                <th>Days Count</th>
                            </tr>

                        </thead>
                        <tbody>
                            {chequeBounceData && chequeBounceData.map((promo, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{promo.applicationLoanId}</td>
                                        <td>{promo.applicationId}</td>
                                        <td>{promo.userName}</td>
                                        <td>{promo.mobileNumber}</td>
                                        <td>{promo.emailId}</td>
                                        <td>{promo.date}</td>
                                        <td>{promo.count}</td>


                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : ""}

        </div>
    </div>
)

export default ChequeBounce;