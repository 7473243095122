import React from 'react'
import moment from 'moment'

const MessagePage = ({ whatsappmsg, whatsappmsgHandler, errorMsg, writeWhatsappMsg,
    allWhatsappMsg, msgTempleteClick, loaderState, tickStatus, collectionType }) => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-5 col-xs-12">
                    <h3 className=" text-center blue-text">Templates</h3>
                    {collectionType == 'recovery' ?
                        <ol>
                            <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('NachBounced')} /> &nbsp;&nbsp;
                                <span><strong>Nach Bounced</strong></span>
                            </li>
                            {/* <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('ChequeBounced')} /> &nbsp;&nbsp;
                                <span><strong>Cheque Bounced</strong></span>
                            </li> */}
                            {/* <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('ChequeDepositAlert')} /> &nbsp;&nbsp;
                                <span><strong>Cheque Deposit Alert</strong></span>
                            </li> */}
                            {/* <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('LegalNotice')} /> &nbsp;&nbsp;
                                <span><strong>Legal Notice</strong></span>
                            </li> */}
                            <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('LoanUnderDefault')} /> &nbsp;&nbsp;
                                <span><strong>Loan Under Default</strong></span>
                            </li>
                        </ol>
                        : collectionType == 'disburse' ?
                            <ol>
                                <li>
                                    <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('WithNach')} /> &nbsp;&nbsp;
                                    <span><strong>With Nach</strong></span>
                                </li>
                                <li>
                                    <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('WithoutNach')} /> &nbsp;&nbsp;
                                    <span><strong>Without Nach</strong></span>
                                </li>
                                <li>
                                    <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('EmiWithNach')} /> &nbsp;&nbsp;
                        <span><strong>EMI With Nach</strong></span>
                                </li>
                                <li>
                                    <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('EmiWithoutNach')} /> &nbsp;&nbsp;
                        <span><strong>EMI Without Nach</strong></span>
                                </li>

                            </ol>
                            : null}
                </div>
                <div className="col-sm-7 col-xs-12">
                    <h3 className="text-center blue-text">Whatsapp Messages</h3>
                    <div className="relative_position">
                        <div className="whatsapp-msg" id="whatsappMsg">
                            {loaderState == true ?
                                <div className="whatsapp-loader">
                                    <div className="fa fa-spinner fa-spin fa-lg" />
                                </div>
                                : ""}
                            {allWhatsappMsg != null && allWhatsappMsg != '' && allWhatsappMsg != undefined ?
                                allWhatsappMsg.map((whatsappData, i) => {
                                    return (
                                        <div key={i} className="margin-0 row">
                                            {whatsappData.fromMe == true ?
                                                <div className="whatsappmsg msg-left">
                                                    <div>
                                                        {whatsappData.body.includes('https://') ?
                                                            whatsappData.body.includes('.pdf?alt') ?
                                                                <object className="area-200px" data={whatsappData.body} type="application/pdf"></object>
                                                                : <img className="area-200px" src={whatsappData.body} />
                                                            : whatsappData.body}
                                                    </div>
                                                    <div className="msgDate">{moment(new Date(whatsappData.date)).format('DD-MM-YYYY HH:mm')}</div>
                                                    <span className="tail-container" />
                                                </div>
                                                : whatsappData.fromMe == false ?
                                                    <div className="whatsappmsg msg-right">
                                                        <div>
                                                            {whatsappData.body.split("&type=")[1] == "pdf" ?
                                                                <object className="area-200px" data={whatsappData.body} type="application/pdf"></object>
                                                                : whatsappData.body.split("&type=")[1] == "img" ?
                                                                    <img className="area-200px" src={whatsappData.body} />
                                                                    : whatsappData.body}
                                                        </div>
                                                        <div className="msgDate">
                                                            {moment(new Date(whatsappData.date)).format('DD-MM-YYYY HH:mm')}&nbsp;&nbsp;
                                                            {tickStatus == 0 ?
                                                                <span className="font-12px">&#10003;</span>
                                                                : tickStatus == 1 ?
                                                                    <span className="whatsapp-doubble-tick font-12px">&#10003;&#10003;</span>
                                                                    : tickStatus == 2 ?
                                                                        <span className="whatsapp-doubble-tick font-12px blue-tick">&#10003;&#10003;</span>
                                                                        : null}
                                                        </div>
                                                        <span className="tail-container" />
                                                    </div>
                                                    : ""}
                                        </div>
                                    )
                                })
                                : ""}
                        </div>
                    </div>
                    <div className="display-flex" onKeyDown={e => whatsappmsgHandler(e, 'enter')}>
                        <textarea className="form-control white-bg-transparent resize-none" rows="4" placeholder="Enter Message" value={writeWhatsappMsg} onChange={e => whatsappmsg(e, 'text')} />
                        <div>
                            <button className="profile-save-btn margin-0" onClick={e => whatsappmsgHandler(e, 'click')}><span className="fa fa-paper-plane" /></button>
                            <div className="text-center margin-top-20px">
                                <label htmlFor="whatsappFiles" className="input-field-label-new margin-0-auto">
                                    <span className="fa fa-paperclip  margin-0"></span>
                                </label>
                                <input accept="image/png, image/jpeg, application/pdf" type="file" className="form-control display-none" id="whatsappFiles" onChange={e => whatsappmsg(e, 'file')} />
                            </div>
                        </div>
                    </div>
                </div>
                <p className="text-center height-20px margin-0 red-text">{errorMsg}</p>
            </div>
        </div>
    )

export default MessagePage;