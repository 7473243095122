import React, { useState, useEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter,ComposedChart,Line, } from 'recharts';


const APIDatashow3month = {
    'INTEREST INCOME': [
        {
            "date": "June 2024",
            "value": "4645322",
            "value2": "4600000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "4427940",
            "value2": "4400000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "5186607",
            "value2": "5100000" // Slightly less than value
        }
    ],
    "PROCESSING FEES": [
        {
            "date": "June 2024",
            "value": "2430600",
            "value2": "2400000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "2224600",
            "value2": "2200000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "1778100",
            "value2": "1750000" // Slightly less than value
        }
    ],
    "PENALTY & CHARGES": [
        {
            "date": "June 2024",
            "value": "540449",
            "value2": "530000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "570303",
            "value2": "560000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "584240",
            "value2": "570000" // Slightly less than value
        }
    ],
    "BOUNCE CHARGES": [
        {
            "date": "June 2024",
            "value": "126750",
            "value2": "125000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "125000",
            "value2": "120000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "172000",
            "value2": "170000" // Slightly less than value
        }
    ],
    "INTEREST ACCURED ON LOAN GIVEN/MF": [
        {
            "date": "June 2024",
            "value": "32056",
            "value2": "31000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "56038",
            "value2": "55000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "62470",
            "value2": "61000" // Slightly less than value
        }
    ],
    "INTEREST ACCURED ON CITRA LOAN GIVEN": [
        {
            "date": "June 2024",
            "value": "441444",
            "value2": "430000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "452083",
            "value2": "440000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "428587",
            "value2": "420000" // Slightly less than value
        }
    ],
    "TOTAL INCOME": [
        {
            "date": "June 2024",
            "value": "8296610",
            "value2": "8200000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "8019775",
            "value2": "7900000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "7565297",
            "value2": "7400000" // Slightly less than value
        }
    ],
    "PROCESSING FEES % (on loan disbursed)": [
        {
            "date": "June 2024",
            "value": "4.31",
            "value2": "4.20" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "4.21",
            "value2": "4.10" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "4.85",
            "value2": "4.75" // Slightly less than value
        }
    ]
};

const APIDatashow6month = {
    'INTEREST INCOME': [
        {
            "date": "March 2024",
            "value": "4550000",
            "value2": "4500000" // Slightly less than value
        },
        {
            "date": "April 2024",
            "value": "4530000",
            "value2": "4500000" // Slightly less than value
        },
        {
            "date": "May 2024",
            "value": "4490000",
            "value2": "4450000" // Slightly less than value
        },
        {
            "date": "June 2024",
            "value": "4645322",
            "value2": "4600000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "4427940",
            "value2": "4400000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "5186607",
            "value2": "5100000" // Slightly less than value
        }
    ],
    "PROCESSING FEES": [
        {
            "date": "March 2024",
            "value": "2380000",
            "value2": "2350000" // Slightly less than value
        },
        {
            "date": "April 2024",
            "value": "2350000",
            "value2": "2300000" // Slightly less than value
        },
        {
            "date": "May 2024",
            "value": "2300000",
            "value2": "2250000" // Slightly less than value
        },
        {
            "date": "June 2024",
            "value": "2430600",
            "value2": "2400000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "2224600",
            "value2": "2200000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "1778100",
            "value2": "1750000" // Slightly less than value
        }
    ],
    "PENALTY & CHARGES": [
        {
            "date": "March 2024",
            "value": "530000",
            "value2": "520000" // Slightly less than value
        },
        {
            "date": "April 2024",
            "value": "550000",
            "value2": "540000" // Slightly less than value
        },
        {
            "date": "May 2024",
            "value": "560000",
            "value2": "550000" // Slightly less than value
        },
        {
            "date": "June 2024",
            "value": "540449",
            "value2": "530000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "570303",
            "value2": "560000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "584240",
            "value2": "570000" // Slightly less than value
        }
    ],
    "BOUNCE CHARGES": [
        {
            "date": "March 2024",
            "value": "125000",
            "value2": "120000" // Slightly less than value
        },
        {
            "date": "April 2024",
            "value": "124000",
            "value2": "120000" // Slightly less than value
        },
        {
            "date": "May 2024",
            "value": "122000",
            "value2": "120000" // Slightly less than value
        },
        {
            "date": "June 2024",
            "value": "126750",
            "value2": "125000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "125000",
            "value2": "120000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "172000",
            "value2": "170000" // Slightly less than value
        }
    ],
    "INTEREST ACCURED ON LOAN GIVEN/MF": [
        {
            "date": "March 2024",
            "value": "31000",
            "value2": "30000" // Slightly less than value
        },
        {
            "date": "April 2024",
            "value": "32000",
            "value2": "31000" // Slightly less than value
        },
        {
            "date": "May 2024",
            "value": "33000",
            "value2": "32000" // Slightly less than value
        },
        {
            "date": "June 2024",
            "value": "32056",
            "value2": "31000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "56038",
            "value2": "55000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "62470",
            "value2": "61000" // Slightly less than value
        }
    ],
    "INTEREST ACCURED ON CITRA LOAN GIVEN": [
        {
            "date": "March 2024",
            "value": "430000",
            "value2": "420000" // Slightly less than value
        },
        {
            "date": "April 2024",
            "value": "435000",
            "value2": "425000" // Slightly less than value
        },
        {
            "date": "May 2024",
            "value": "440000",
            "value2": "430000" // Slightly less than value
        },
        {
            "date": "June 2024",
            "value": "441444",
            "value2": "430000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "452083",
            "value2": "440000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "428587",
            "value2": "420000" // Slightly less than value
        }
    ],
    "TOTAL INCOME": [
        {
            "date": "March 2024",
            "value": "8200000",
            "value2": "8100000" // Slightly less than value
        },
        {
            "date": "April 2024",
            "value": "8150000",
            "value2": "8050000" // Slightly less than value
        },
        {
            "date": "May 2024",
            "value": "8100000",
            "value2": "8000000" // Slightly less than value
        },
        {
            "date": "June 2024",
            "value": "8296610",
            "value2": "8200000" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "8019775",
            "value2": "7900000" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "7565297",
            "value2": "7400000" // Slightly less than value
        }
    ],
    "PROCESSING FEES % (on loan disbursed)": [
        {
            "date": "March 2024",
            "value": "4.00",
            "value2": "3.90" // Slightly less than value
        },
        {
            "date": "April 2024",
            "value": "4.10",
            "value2": "4.00" // Slightly less than value
        },
        {
            "date": "May 2024",
            "value": "4.20",
            "value2": "4.10" // Slightly less than value
        },
        {
            "date": "June 2024",
            "value": "4.31",
            "value2": "4.20" // Slightly less than value
        },
        {
            "date": "July 2024",
            "value": "4.21",
            "value2": "4.10" // Slightly less than value
        },
        {
            "date": "August 2024",
            "value": "4.85",
            "value2": "4.75" // Slightly less than value
        }
    ]
};

const APIDatashow1year = {
    'INTEREST INCOME': [
        { date: 'September 2023', value: '4400000', value2: '4300000' },
        { date: 'October 2023', value: '4380000', value2: '4280000' },
        { date: 'November 2023', value: '4350000', value2: '4250000' },
        { date: 'December 2023', value: '4330000', value2: '4230000' },
        { date: 'January 2024', value: '4300000', value2: '4200000' },
        { date: 'February 2024', value: '4280000', value2: '4180000' },
        { date: 'March 2024', value: '4550000', value2: '4450000' },
        { date: 'April 2024', value: '4530000', value2: '4430000' },
        { date: 'May 2024', value: '4490000', value2: '4390000' },
        { date: 'June 2024', value: '4645322', value2: '4545322' },
        { date: 'July 2024', value: '4427940', value2: '4327940' },
        { date: 'August 2024', value: '5186607', value2: '5086607' }
    ],
    'PROCESSING FEES': [
        { date: 'September 2023', value: '2350000', value2: '2250000' },
        { date: 'October 2023', value: '2320000', value2: '2220000' },
        { date: 'November 2023', value: '2300000', value2: '2200000' },
        { date: 'December 2023', value: '2280000', value2: '2180000' },
        { date: 'January 2024', value: '2250000', value2: '2150000' },
        { date: 'February 2024', value: '2220000', value2: '2120000' },
        { date: 'March 2024', value: '2380000', value2: '2280000' },
        { date: 'April 2024', value: '2350000', value2: '2250000' },
        { date: 'May 2024', value: '2300000', value2: '2200000' },
        { date: 'June 2024', value: '2430600', value2: '2330600' },
        { date: 'July 2024', value: '2224600', value2: '2124600' },
        { date: 'August 2024', value: '1778100', value2: '1678100' }
    ],
    'PENALTY & CHARGES': [
        { date: 'September 2023', value: '525000', value2: '515000' },
        { date: 'October 2023', value: '545000', value2: '535000' },
        { date: 'November 2023', value: '555000', value2: '545000' },
        { date: 'December 2023', value: '560000', value2: '550000' },
        { date: 'January 2024', value: '565000', value2: '555000' },
        { date: 'February 2024', value: '570000', value2: '560000' },
        { date: 'March 2024', value: '530000', value2: '520000' },
        { date: 'April 2024', value: '540000', value2: '530000' },
        { date: 'May 2024', value: '550000', value2: '540000' },
        { date: 'June 2024', value: '540449', value2: '530449' },
        { date: 'July 2024', value: '570303', value2: '560303' },
        { date: 'August 2024', value: '584240', value2: '574240' }
    ],
    'BOUNCE CHARGES': [
        { date: 'September 2023', value: '124000', value2: '114000' },
        { date: 'October 2023', value: '123000', value2: '113000' },
        { date: 'November 2023', value: '121000', value2: '111000' },
        { date: 'December 2023', value: '120000', value2: '110000' },
        { date: 'January 2024', value: '118000', value2: '108000' },
        { date: 'February 2024', value: '115000', value2: '105000' },
        { date: 'March 2024', value: '125000', value2: '115000' },
        { date: 'April 2024', value: '124000', value2: '114000' },
        { date: 'May 2024', value: '122000', value2: '112000' },
        { date: 'June 2024', value: '126750', value2: '116750' },
        { date: 'July 2024', value: '125000', value2: '115000' },
        { date: 'August 2024', value: '172000', value2: '162000' }
    ],
    'INTEREST ACCURED ON LOAN GIVEN/MF': [
        { date: 'September 2023', value: '30500', value2: '29500' },
        { date: 'October 2023', value: '31000', value2: '30000' },
        { date: 'November 2023', value: '31500', value2: '30500' },
        { date: 'December 2023', value: '32000', value2: '31000' },
        { date: 'January 2024', value: '32500', value2: '31500' },
        { date: 'February 2024', value: '33000', value2: '32000' },
        { date: 'March 2024', value: '31000', value2: '30000' },
        { date: 'April 2024', value: '32000', value2: '31000' },
        { date: 'May 2024', value: '33000', value2: '32000' },
        { date: 'June 2024', value: '32056', value2: '31056' },
        { date: 'July 2024', value: '56038', value2: '55038' },
        { date: 'August 2024', value: '62470', value2: '61470' }
    ],
    'INTEREST ACCURED ON CITRA LOAN GIVEN': [
        { date: 'September 2023', value: '425000', value2: '415000' },
        { date: 'October 2023', value: '430000', value2: '420000' },
        { date: 'November 2023', value: '435000', value2: '425000' },
        { date: 'December 2023', value: '440000', value2: '430000' },
        { date: 'January 2024', value: '445000', value2: '435000' },
        { date: 'February 2024', value: '450000', value2: '440000' },
        { date: 'March 2024', value: '430000', value2: '420000' },
        { date: 'April 2024', value: '435000', value2: '425000' },
        { date: 'May 2024', value: '440000', value2: '430000' },
        { date: 'June 2024', value: '441444', value2: '431444' },
        { date: 'July 2024', value: '452083', value2: '442083' },
        { date: 'August 2024', value: '428587', value2: '418587' }
    ],
    'TOTAL INCOME': [
        { date: 'September 2023', value: '8100000', value2: '8000000' },
        { date: 'October 2023', value: '8150000', value2: '8050000' },
        { date: 'November 2023', value: '8200000', value2: '8100000' },
        { date: 'December 2023', value: '8250000', value2: '8150000' },
        { date: 'January 2024', value: '8300000', value2: '8200000' },
        { date: 'February 2024', value: '8350000', value2: '8250000' },
        { date: 'March 2024', value: '8200000', value2: '8100000' },
        { date: 'April 2024', value: '8250000', value2: '8150000' },
        { date: 'May 2024', value: '8300000', value2: '8200000' },
        { date: 'June 2024', value: '8445100', value2: '8345100' },
        { date: 'July 2024', value: '8556000', value2: '8456000' },
        { date: 'August 2024', value: '9023730', value2: '8923730' }
    ],
    'PROCESSING FEES % (on loan disbrushed)': [
        {
            date: 'September 2023',
            value: '4.00',
            value2: '3.90'  // Slightly less than 4.00
        },
        {
            date: 'October 2023',
            value: '4.05',
            value2: '3.95'  // Slightly less than 4.05
        },
        {
            date: 'November 2023',
            value: '4.10',
            value2: '4.00'  // Slightly less than 4.10
        },
        {
            date: 'December 2023',
            value: '4.15',
            value2: '4.05'  // Slightly less than 4.15
        },
        {
            date: 'January 2024',
            value: '4.20',
            value2: '4.10'  // Slightly less than 4.20
        },
        {
            date: 'February 2024',
            value: '4.25',
            value2: '4.15'  // Slightly less than 4.25
        },
        {
            date: 'March 2024',
            value: '4.00',
            value2: '3.90'  // Slightly less than 4.00
        },
        {
            date: 'April 2024',
            value: '4.10',
            value2: '4.00'  // Slightly less than 4.10
        },
        {
            date: 'May 2024',
            value: '4.20',
            value2: '4.10'  // Slightly less than 4.20
        },
        {
            date: 'June 2024',
            value: '4.31',
            value2: '4.21'  // Slightly less than 4.31
        },
        {
            date: 'July 2024',
            value: '4.21',
            value2: '4.11'  // Slightly less than 4.21
        },
        {
            date: 'August 2024',
            value: '4.85',
            value2: '4.75'  // Slightly less than 4.85
        }
    ],
};





const APIDatashow3monthExpenses = {
    'Credit Underwritting Cost': [
        {
            date: 'June 2024',
            value: '501433',
            value2: '491400'  // Slightly less than 501433
        },
        {
            date: 'July 2024',
            value: '487259',
            value2: '477230'  // Slightly less than 487259
        },
        {
            date: 'August 2024',
            value: '6670717',
            value2: '6575000'  // Slightly less than 6670717
        }
    ],
    'Loan Processing Expenses': [
        {
            date: 'June 2024',
            value: '333960',
            value2: '323960'  // Slightly less than 333960
        },
        {
            date: 'July 2024',
            value: '215389',
            value2: '205389'  // Slightly less than 215389
        },
        {
            date: 'August 2024',
            value: '85773',
            value2: '84773'  // Slightly less than 85773
        }
    ],
    'INTEREST EXP (Interest income/30%*18%)': [
        {
            date: 'June 2024',
            value: '1434796',
            value2: '1420000'  // Slightly less than 1434796
        },
        {
            date: 'July 2024',
            value: '1581530',
            value2: '1565000'  // Slightly less than 1581530
        },
        {
            date: 'August 2024',
            value: '1525401',
            value2: '1510000'  // Slightly less than 1525401
        }
    ],
    'Loan Fees': [
        {
            date: 'June 2024',
            value: '157142',
            value2: '152000'  // Slightly less than 157142
        },
        {
            date: 'July 2024',
            value: '184901',
            value2: '179000'  // Slightly less than 184901
        },
        {
            date: 'August 2024',
            value: '136980',
            value2: '131000'  // Slightly less than 136980
        }
    ],
    'Finance Cost': [
        {
            date: 'June 2024',
            value: '201',
            value2: '191'  // Slightly less than 201
        },
        {
            date: 'July 2024',
            value: '188',
            value2: '178'  // Slightly less than 188
        },
        {
            date: 'August 2024',
            value: '238',
            value2: '228'  // Slightly less than 238
        }
    ],
    'Legal & Proffesional Fees': [
        {
            date: 'June 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        },
        {
            date: 'July 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        },
        {
            date: 'August 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        }
    ],
    'DIRECTOR SALARY': [
        {
            date: 'June 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        },
        {
            date: 'July 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        },
        {
            date: 'August 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        }
    ],
    'EMPLOYEE COST': [
        {
            date: 'June 2024',
            value: '2037935',
            value2: '2017935'  // Slightly less than 2037935
        },
        {
            date: 'July 2024',
            value: '2068082',
            value2: '2048082'  // Slightly less than 2068082
        },
        {
            date: 'August 2024',
            value: '2197090',
            value2: '2177090'  // Slightly less than 2197090
        }
    ],
    'MARKETING EXPENSES': [
        {
            date: 'June 2024',
            value: '570385',
            value2: '560000'  // Slightly less than 570385
        },
        {
            date: 'July 2024',
            value: '750595',
            value2: '740000'  // Slightly less than 750595
        },
        {
            date: 'August 2024',
            value: '747238',
            value2: '737000'  // Slightly less than 747238
        }
    ],
    'COLLECTION COST': [
        {
            date: 'June 2024',
            value: '92333',
            value2: '91333'  // Slightly less than 92333
        },
        {
            date: 'July 2024',
            value: '162916',
            value2: '152916'  // Slightly less than 162916
        },
        {
            date: 'August 2024',
            value: '165931',
            value2: '155931'  // Slightly less than 165931
        }
    ],
    'TECH COST': [
        {
            date: 'June 2024',
            value: '326179',
            value2: '316179'  // Slightly less than 326179
        },
        {
            date: 'July 2024',
            value: '324171',
            value2: '314171'  // Slightly less than 324171
        },
        {
            date: 'August 2024',
            value: '306567',
            value2: '296567'  // Slightly less than 306567
        }
    ],
    'Provision for baddebts - 50% of incremental of overdue EMI from 13th Nov-21': [
        {
            date: 'June 2024',
            value: '1271981',
            value2: '1260000'  // Slightly less than 1271981
        },
        {
            date: 'July 2024',
            value: '1165607',
            value2: '1150000'  // Slightly less than 1165607
        },
        {
            date: 'August 2024',
            value: '1433825',
            value2: '1420000'  // Slightly less than 1433825
        }
    ],
    'OFFICE EXPENSES': [
        {
            date: 'June 2024',
            value: '1271981',
            value2: '1260000'  // Slightly less than 1271981
        },
        {
            date: 'July 2024',
            value: '1173939',
            value2: '1160000'  // Slightly less than 1173939
        },
        {
            date: 'August 2024',
            value: '1433862',
            value2: '1420000'  // Slightly less than 1433862
        }
    ],
    'TOTAL EXPENSES': [
        {
            date: 'June 2024',
            value: '317923',
            value2: '307923'  // Slightly less than 317923
        },
        {
            date: 'July 2024',
            value: '370661',
            value2: '360661'  // Slightly less than 370661
        },
        {
            date: 'August 2024',
            value: '396243',
            value2: '386243'  // Slightly less than 396243
        }
    ],
};

const APIDatashow6monthExpenses = {
    'Credit Underwritting Cost': [
        {
            date: 'March 2024',
            value: '450000',
            value2: '440000'  // Slightly less than 450000
        },
        {
            date: 'April 2024',
            value: '445000',
            value2: '435000'  // Slightly less than 445000
        },
        {
            date: 'May 2024',
            value: '440000',
            value2: '430000'  // Slightly less than 440000
        },
        {
            date: 'June 2024',
            value: '501433',
            value2: '490000'  // Slightly less than 501433
        },
        {
            date: 'July 2024',
            value: '487259',
            value2: '475000'  // Slightly less than 487259
        },
        {
            date: 'August 2024',
            value: '6670717',
            value2: '6550000'  // Slightly less than 6670717
        }
    ],
    'Loan Processing Expenses': [
        {
            date: 'March 2024',
            value: '320000',
            value2: '310000'  // Slightly less than 320000
        },
        {
            date: 'April 2024',
            value: '310000',
            value2: '300000'  // Slightly less than 310000
        },
        {
            date: 'May 2024',
            value: '300000',
            value2: '290000'  // Slightly less than 300000
        },
        {
            date: 'June 2024',
            value: '333960',
            value2: '320000'  // Slightly less than 333960
        },
        {
            date: 'July 2024',
            value: '215389',
            value2: '210000'  // Slightly less than 215389
        },
        {
            date: 'August 2024',
            value: '85773',
            value2: '85000'  // Slightly less than 85773
        }
    ],
    'INTEREST EXP (Interest income/30%*18%)': [
        {
            date: 'March 2024',
            value: '1400000',
            value2: '1350000'  // Slightly less than 1400000
        },
        {
            date: 'April 2024',
            value: '1500000',
            value2: '1450000'  // Slightly less than 1500000
        },
        {
            date: 'May 2024',
            value: '1550000',
            value2: '1500000'  // Slightly less than 1550000
        },
        {
            date: 'June 2024',
            value: '1434796',
            value2: '1400000'  // Slightly less than 1434796
        },
        {
            date: 'July 2024',
            value: '1581530',
            value2: '1550000'  // Slightly less than 1581530
        },
        {
            date: 'August 2024',
            value: '1525401',
            value2: '1500000'  // Slightly less than 1525401
        }
    ],
    'Loan Fees': [
        {
            date: 'March 2024',
            value: '150000',
            value2: '140000'  // Slightly less than 150000
        },
        {
            date: 'April 2024',
            value: '145000',
            value2: '135000'  // Slightly less than 145000
        },
        {
            date: 'May 2024',
            value: '140000',
            value2: '130000'  // Slightly less than 140000
        },
        {
            date: 'June 2024',
            value: '157142',
            value2: '150000'  // Slightly less than 157142
        },
        {
            date: 'July 2024',
            value: '184901',
            value2: '175000'  // Slightly less than 184901
        },
        {
            date: 'August 2024',
            value: '136980',
            value2: '130000'  // Slightly less than 136980
        }
    ],
    'Finance Cost': [
        {
            date: 'March 2024',
            value: '210',
            value2: '200'  // Slightly less than 210
        },
        {
            date: 'April 2024',
            value: '200',
            value2: '190'  // Slightly less than 200
        },
        {
            date: 'May 2024',
            value: '190',
            value2: '180'  // Slightly less than 190
        },
        {
            date: 'June 2024',
            value: '201',
            value2: '190'  // Slightly less than 201
        },
        {
            date: 'July 2024',
            value: '188',
            value2: '180'  // Slightly less than 188
        },
        {
            date: 'August 2024',
            value: '238',
            value2: '220'  // Slightly less than 238
        }
    ],
    'Legal & Proffesional Fees': [
        {
            date: 'March 2024',
            value: '48000',
            value2: '47000'  // Slightly less than 48000
        },
        {
            date: 'April 2024',
            value: '47000',
            value2: '46000'  // Slightly less than 47000
        },
        {
            date: 'May 2024',
            value: '46000',
            value2: '45000'  // Slightly less than 46000
        },
        {
            date: 'June 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        },
        {
            date: 'July 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        },
        {
            date: 'August 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        }
    ],
    'DIRECTOR SALARY': [
        {
            date: 'March 2024',
            value: '340000',
            value2: '330000'  // Slightly less than 340000
        },
        {
            date: 'April 2024',
            value: '330000',
            value2: '320000'  // Slightly less than 330000
        },
        {
            date: 'May 2024',
            value: '320000',
            value2: '310000'  // Slightly less than 320000
        },
        {
            date: 'June 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        },
        {
            date: 'July 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        },
        {
            date: 'August 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        }
    ],
    'EMPLOYEE COST': [
        {
            date: 'March 2024',
            value: '1900000',
            value2: '1850000'  // Slightly less than 1900000
        },
        {
            date: 'April 2024',
            value: '1950000',
            value2: '1900000'  // Slightly less than 1950000
        },
        {
            date: 'May 2024',
            value: '2000000',
            value2: '1950000'  // Slightly less than 2000000
        },
        {
            date: 'June 2024',
            value: '2037935',
            value2: '2000000'  // Slightly less than 2037935
        },
        {
            date: 'July 2024',
            value: '2068082',
            value2: '2030000'  // Slightly less than 2068082
        },
        {
            date: 'August 2024',
            value: '2197090',
            value2: '2150000'  // Slightly less than 2197090
        }
    ],
    'MARKETING EXPENSES': [
        {
            date: 'March 2024',
            value: '550000',
            value2: '540000'  // Slightly less than 550000
        },
        {
            date: 'April 2024',
            value: '560000',
            value2: '550000'  // Slightly less than 560000
        },
        {
            date: 'May 2024',
            value: '570000',
            value2: '560000'  // Slightly less than 570000
        },
        {
            date: 'June 2024',
            value: '570385',
            value2: '560000'  // Slightly less than 570385
        },
        {
            date: 'July 2024',
            value: '750595',
            value2: '740000'  // Slightly less than 750595
        },
        {
            date: 'August 2024',
            value: '747238',
            value2: '735000'  // Slightly less than 747238
        }
    ],
    'COLLECTION COST': [
        {
            date: 'March 2024',
            value: '90000',
            value2: '88000'  // Slightly less than 90000
        },
        {
            date: 'April 2024',
            value: '92000',
            value2: '90000'  // Slightly less than 92000
        },
        {
            date: 'May 2024',
            value: '94000',
            value2: '92000'  // Slightly less than 94000
        },
        {
            date: 'June 2024',
            value: '92333',
            value2: '91000'  // Slightly less than 92333
        },
        {
            date: 'July 2024',
            value: '162916',
            value2: '160000'  // Slightly less than 162916
        },
        {
            date: 'August 2024',
            value: '165931',
            value2: '162000'  // Slightly less than 165931
        }
    ],
    'TECH COST': [
        {
            date: 'March 2024',
            value: '320000',
            value2: '310000'  // Slightly less than 320000
        },
        {
            date: 'April 2024',
            value: '310000',
            value2: '300000'  // Slightly less than 310000
        },
        {
            date: 'May 2024',
            value: '300000',
            value2: '290000'  // Slightly less than 300000
        },
        {
            date: 'June 2024',
            value: '326179',
            value2: '320000'  // Slightly less than 326179
        },
        {
            date: 'July 2024',
            value: '324171',
            value2: '320000'  // Slightly less than 324171
        },
        {
            date: 'August 2024',
            value: '306567',
            value2: '300000'  // Slightly less than 306567
        }
    ],
    'Provision for baddebts - 50% of incremental of overdue EMI from 13th Nov-21': [
        {
            date: 'March 2024',
            value: '1200000',
            value2: '1180000'  // Slightly less than 1200000
        },
        {
            date: 'April 2024',
            value: '1220000',
            value2: '1200000'  // Slightly less than 1220000
        },
        {
            date: 'May 2024',
            value: '1240000',
            value2: '1220000'  // Slightly less than 1240000
        },
        {
            date: 'June 2024',
            value: '1271981',
            value2: '1250000'  // Slightly less than 1271981
        },
        {
            date: 'July 2024',
            value: '1165607',
            value2: '1150000'  // Slightly less than 1165607
        },
        {
            date: 'August 2024',
            value: '1433825',
            value2: '1400000'  // Slightly less than 1433825
        }
    ],
    'OFFICE EXPENSES': [
        {
            date: 'March 2024',
            value: '1200000',
            value2: '1180000'  // Slightly less than 1200000
        },
        {
            date: 'April 2024',
            value: '1220000',
            value2: '1200000'  // Slightly less than 1220000
        },
        {
            date: 'May 2024',
            value: '1240000',
            value2: '1220000'  // Slightly less than 1240000
        },
        {
            date: 'June 2024',
            value: '1271981',
            value2: '1250000'  // Slightly less than 1271981
        },
        {
            date: 'July 2024',
            value: '1173939',
            value2: '1150000'  // Slightly less than 1173939
        },
        {
            date: 'August 2024',
            value: '1433862',
            value2: '1400000'  // Slightly less than 1433862
        }
    ],
    'TOTAL EXPENSES': [
        {
            date: 'March 2024',
            value: '300000',
            value2: '290000'  // Slightly less than 300000
        },
        {
            date: 'April 2024',
            value: '310000',
            value2: '300000'  // Slightly less than 310000
        },
        {
            date: 'May 2024',
            value: '320000',
            value2: '310000'  // Slightly less than 320000
        },
        {
            date: 'June 2024',
            value: '317923',
            value2: '310000'  // Slightly less than 317923
        },
        {
            date: 'July 2024',
            value: '370661',
            value2: '360000'  // Slightly less than 370661
        },
        {
            date: 'August 2024',
            value: '396243',
            value2: '390000'  // Slightly less than 396243
        }
    ],
};

const APIDatashow1yearExpenses = {
    'Credit Underwritting Cost': [
        {
            date: 'September 2023',
            value: '460000',
            value2: '455000'  // Slightly less than 460000
        },
        {
            date: 'October 2023',
            value: '455000',
            value2: '450000'  // Slightly less than 455000
        },
        {
            date: 'November 2023',
            value: '450000',
            value2: '445000'  // Slightly less than 450000
        },
        {
            date: 'December 2023',
            value: '445000',
            value2: '440000'  // Slightly less than 445000
        },
        {
            date: 'January 2024',
            value: '440000',
            value2: '435000'  // Slightly less than 440000
        },
        {
            date: 'February 2024',
            value: '435000',
            value2: '430000'  // Slightly less than 435000
        },
        {
            date: 'March 2024',
            value: '430000',
            value2: '425000'  // Slightly less than 430000
        },
        {
            date: 'April 2024',
            value: '425000',
            value2: '420000'  // Slightly less than 425000
        },
        {
            date: 'May 2024',
            value: '420000',
            value2: '415000'  // Slightly less than 420000
        },
        {
            date: 'June 2024',
            value: '501433',
            value2: '490000'  // Slightly less than 501433
        },
        {
            date: 'July 2024',
            value: '487259',
            value2: '475000'  // Slightly less than 487259
        },
        {
            date: 'August 2024',
            value: '6670717',
            value2: '6600000'  // Slightly less than 6670717
        }
    ],
    'Loan Processing Expenses': [
        {
            date: 'September 2023',
            value: '330000',
            value2: '325000'  // Slightly less than 330000
        },
        {
            date: 'October 2023',
            value: '320000',
            value2: '315000'  // Slightly less than 320000
        },
        {
            date: 'November 2023',
            value: '310000',
            value2: '305000'  // Slightly less than 310000
        },
        {
            date: 'December 2023',
            value: '300000',
            value2: '295000'  // Slightly less than 300000
        },
        {
            date: 'January 2024',
            value: '290000',
            value2: '285000'  // Slightly less than 290000
        },
        {
            date: 'February 2024',
            value: '280000',
            value2: '275000'  // Slightly less than 280000
        },
        {
            date: 'March 2024',
            value: '270000',
            value2: '265000'  // Slightly less than 270000
        },
        {
            date: 'April 2024',
            value: '260000',
            value2: '255000'  // Slightly less than 260000
        },
        {
            date: 'May 2024',
            value: '250000',
            value2: '245000'  // Slightly less than 250000
        },
        {
            date: 'June 2024',
            value: '333960',
            value2: '320000'  // Slightly less than 333960
        },
        {
            date: 'July 2024',
            value: '215389',
            value2: '210000'  // Slightly less than 215389
        },
        {
            date: 'August 2024',
            value: '85773',
            value2: '85000'  // Slightly less than 85773
        }
    ],
    'INTEREST EXP (Interest income/30%*18%)': [
        {
            date: 'September 2023',
            value: '1350000',
            value2: '1330000'  // Slightly less than 1350000
        },
        {
            date: 'October 2023',
            value: '1400000',
            value2: '1380000'  // Slightly less than 1400000
        },
        {
            date: 'November 2023',
            value: '1450000',
            value2: '1430000'  // Slightly less than 1450000
        },
        {
            date: 'December 2023',
            value: '1500000',
            value2: '1480000'  // Slightly less than 1500000
        },
        {
            date: 'January 2024',
            value: '1550000',
            value2: '1530000'  // Slightly less than 1550000
        },
        {
            date: 'February 2024',
            value: '1600000',
            value2: '1580000'  // Slightly less than 1600000
        },
        {
            date: 'March 2024',
            value: '1650000',
            value2: '1630000'  // Slightly less than 1650000
        },
        {
            date: 'April 2024',
            value: '1700000',
            value2: '1680000'  // Slightly less than 1700000
        },
        {
            date: 'May 2024',
            value: '1750000',
            value2: '1730000'  // Slightly less than 1750000
        },
        {
            date: 'June 2024',
            value: '1434796',
            value2: '1410000'  // Slightly less than 1434796
        },
        {
            date: 'July 2024',
            value: '1581530',
            value2: '1560000'  // Slightly less than 1581530
        },
        {
            date: 'August 2024',
            value: '1525401',
            value2: '1500000'  // Slightly less than 1525401
        }
    ],
    'Loan Fees': [
        {
            date: 'September 2023',
            value: '155000',
            value2: '153000'  // Slightly less than 155000
        },
        {
            date: 'October 2023',
            value: '150000',
            value2: '148000'  // Slightly less than 150000
        },
        {
            date: 'November 2023',
            value: '145000',
            value2: '143000'  // Slightly less than 145000
        },
        {
            date: 'December 2023',
            value: '140000',
            value2: '138000'  // Slightly less than 140000
        },
        {
            date: 'January 2024',
            value: '135000',
            value2: '133000'  // Slightly less than 135000
        },
        {
            date: 'February 2024',
            value: '130000',
            value2: '128000'  // Slightly less than 130000
        },
        {
            date: 'March 2024',
            value: '125000',
            value2: '123000'  // Slightly less than 125000
        },
        {
            date: 'April 2024',
            value: '120000',
            value2: '118000'  // Slightly less than 120000
        },
        {
            date: 'May 2024',
            value: '115000',
            value2: '113000'  // Slightly less than 115000
        },
        {
            date: 'June 2024',
            value: '157142',
            value2: '155000'  // Slightly less than 157142
        },
        {
            date: 'July 2024',
            value: '184901',
            value2: '182000'  // Slightly less than 184901
        },
        {
            date: 'August 2024',
            value: '136980',
            value2: '134000'  // Slightly less than 136980
        }
    ],
    'Finance Cost': [
        {
            date: 'September 2023',
            value: '220',
            value2: '210'  // Slightly less than 220
        },
        {
            date: 'October 2023',
            value: '210',
            value2: '200'  // Slightly less than 210
        },
        {
            date: 'November 2023',
            value: '200',
            value2: '190'  // Slightly less than 200
        },
        {
            date: 'December 2023',
            value: '190',
            value2: '180'  // Slightly less than 190
        },
        {
            date: 'January 2024',
            value: '180',
            value2: '170'  // Slightly less than 180
        },
        {
            date: 'February 2024',
            value: '170',
            value2: '160'  // Slightly less than 170
        },
        {
            date: 'March 2024',
            value: '160',
            value2: '150'  // Slightly less than 160
        },
        {
            date: 'April 2024',
            value: '150',
            value2: '140'  // Slightly less than 150
        },
        {
            date: 'May 2024',
            value: '140',
            value2: '130'  // Slightly less than 140
        },
        {
            date: 'June 2024',
            value: '201',
            value2: '190'  // Slightly less than 201
        },
        {
            date: 'July 2024',
            value: '188',
            value2: '175'  // Slightly less than 188
        },
        {
            date: 'August 2024',
            value: '238',
            value2: '225'  // Slightly less than 238
        }
    ],
    'Legal & Proffesional Fees': [
        {
            date: 'September 2023',
            value: '49000',
            value2: '48000'  // Slightly less than 49000
        },
        {
            date: 'October 2023',
            value: '48000',
            value2: '47000'  // Slightly less than 48000
        },
        {
            date: 'November 2023',
            value: '47000',
            value2: '46000'  // Slightly less than 47000
        },
        {
            date: 'December 2023',
            value: '46000',
            value2: '45000'  // Slightly less than 46000
        },
        {
            date: 'January 2024',
            value: '45000',
            value2: '44000'  // Slightly less than 45000
        },
        {
            date: 'February 2024',
            value: '44000',
            value2: '43000'  // Slightly less than 44000
        },
        {
            date: 'March 2024',
            value: '43000',
            value2: '42000'  // Slightly less than 43000
        },
        {
            date: 'April 2024',
            value: '42000',
            value2: '41000'  // Slightly less than 42000
        },
        {
            date: 'May 2024',
            value: '41000',
            value2: '40000'  // Slightly less than 41000
        },
        {
            date: 'June 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        },
        {
            date: 'July 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        },
        {
            date: 'August 2024',
            value: '50000',
            value2: '49000'  // Slightly less than 50000
        }
    ],
    'DIRECTOR SALARY': [
        {
            date: 'September 2023',
            value: '340000',
            value2: '335000'  // Slightly less than 340000
        },
        {
            date: 'October 2023',
            value: '330000',
            value2: '325000'  // Slightly less than 330000
        },
        {
            date: 'November 2023',
            value: '320000',
            value2: '315000'  // Slightly less than 320000
        },
        {
            date: 'December 2023',
            value: '310000',
            value2: '305000'  // Slightly less than 310000
        },
        {
            date: 'January 2024',
            value: '300000',
            value2: '295000'  // Slightly less than 300000
        },
        {
            date: 'February 2024',
            value: '290000',
            value2: '285000'  // Slightly less than 290000
        },
        {
            date: 'March 2024',
            value: '280000',
            value2: '275000'  // Slightly less than 280000
        },
        {
            date: 'April 2024',
            value: '270000',
            value2: '265000'  // Slightly less than 270000
        },
        {
            date: 'May 2024',
            value: '260000',
            value2: '255000'  // Slightly less than 260000
        },
        {
            date: 'June 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        },
        {
            date: 'July 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        },
        {
            date: 'August 2024',
            value: '350000',
            value2: '340000'  // Slightly less than 350000
        }
    ],
    'EMPLOYEE COST': [
        {
            date: 'September 2023',
            value: '2100000',
            value2: '2080000'  // Slightly less than 2100000
        },
        {
            date: 'October 2023',
            value: '2000000',
            value2: '1980000'  // Slightly less than 2000000
        },
        {
            date: 'November 2023',
            value: '1900000',
            value2: '1880000'  // Slightly less than 1900000
        },
        {
            date: 'December 2023',
            value: '1800000',
            value2: '1780000'  // Slightly less than 1800000
        },
        {
            date: 'January 2024',
            value: '1700000',
            value2: '1680000'  // Slightly less than 1700000
        },
        {
            date: 'February 2024',
            value: '1600000',
            value2: '1580000'  // Slightly less than 1600000
        },
        {
            date: 'March 2024',
            value: '1500000',
            value2: '1480000'  // Slightly less than 1500000
        },
        {
            date: 'April 2024',
            value: '1400000',
            value2: '1380000'  // Slightly less than 1400000
        },
        {
            date: 'May 2024',
            value: '1300000',
            value2: '1280000'  // Slightly less than 1300000
        },
        {
            date: 'June 2024',
            value: '2037935',
            value2: '2010000'  // Slightly less than 2037935
        },
        {
            date: 'July 2024',
            value: '2068082',
            value2: '2040000'  // Slightly less than 2068082
        },
        {
            date: 'August 2024',
            value: '2197090',
            value2: '2170000'  // Slightly less than 2197090
        }
    ],
    'MARKETING EXPENSES': [
        {
            date: 'September 2023',
            value: '560000',
            value2: '550000'  // Slightly less than 560000
        },
        {
            date: 'October 2023',
            value: '550000',
            value2: '540000'  // Slightly less than 550000
        },
        {
            date: 'November 2023',
            value: '540000',
            value2: '530000'  // Slightly less than 540000
        },
        {
            date: 'December 2023',
            value: '530000',
            value2: '520000'  // Slightly less than 530000
        },
        {
            date: 'January 2024',
            value: '520000',
            value2: '510000'  // Slightly less than 520000
        },
        {
            date: 'February 2024',
            value: '510000',
            value2: '500000'  // Slightly less than 510000
        },
        {
            date: 'March 2024',
            value: '500000',
            value2: '490000'  // Slightly less than 500000
        },
        {
            date: 'April 2024',
            value: '490000',
            value2: '480000'  // Slightly less than 490000
        },
        {
            date: 'May 2024',
            value: '480000',
            value2: '470000'  // Slightly less than 480000
        },
        {
            date: 'June 2024',
            value: '570385',
            value2: '560000'  // Slightly less than 570385
        },
        {
            date: 'July 2024',
            value: '750595',
            value2: '740000'  // Slightly less than 750595
        },
        {
            date: 'August 2024',
            value: '747238',
            value2: '735000'  // Slightly less than 747238
        }
    ],
    'COLLECTION COST': [
        {
            date: 'September 2023',
            value: '94000',
            value2: '92000'  // Slightly less than 94000
        },
        {
            date: 'October 2023',
            value: '93000',
            value2: '91000'  // Slightly less than 93000
        },
        {
            date: 'November 2023',
            value: '92000',
            value2: '90000'  // Slightly less than 92000
        },
        {
            date: 'December 2023',
            value: '91000',
            value2: '89000'  // Slightly less than 91000
        },
        {
            date: 'January 2024',
            value: '90000',
            value2: '88000'  // Slightly less than 90000
        },
        {
            date: 'February 2024',
            value: '89000',
            value2: '87000'  // Slightly less than 89000
        },
        {
            date: 'March 2024',
            value: '88000',
            value2: '86000'  // Slightly less than 88000
        },
        {
            date: 'April 2024',
            value: '87000',
            value2: '85000'  // Slightly less than 87000
        },
        {
            date: 'May 2024',
            value: '86000',
            value2: '84000'  // Slightly less than 86000
        },
        {
            date: 'June 2024',
            value: '92333',
            value2: '90000'  // Slightly less than 92333
        },
        {
            date: 'July 2024',
            value: '162916',
            value2: '160000'  // Slightly less than 162916
        },
        {
            date: 'August 2024',
            value: '165931',
            value2: '163000'  // Slightly less than 165931
        }
    ],
    'TECH COST': [
        {
            date: 'September 2023',
            value: '330000',
            value2: '325000'  // Slightly less than 330000
        },
        {
            date: 'October 2023',
            value: '320000',
            value2: '315000'  // Slightly less than 320000
        },
        {
            date: 'November 2023',
            value: '310000',
            value2: '305000'  // Slightly less than 310000
        },
        {
            date: 'December 2023',
            value: '300000',
            value2: '295000'  // Slightly less than 300000
        },
        {
            date: 'January 2024',
            value: '290000',
            value2: '285000'  // Slightly less than 290000
        },
        {
            date: 'February 2024',
            value: '280000',
            value2: '275000'  // Slightly less than 280000
        },
        {
            date: 'March 2024',
            value: '270000',
            value2: '265000'  // Slightly less than 270000
        },
        {
            date: 'April 2024',
            value: '260000',
            value2: '255000'  // Slightly less than 260000
        },
        {
            date: 'May 2024',
            value: '250000',
            value2: '245000'  // Slightly less than 250000
        },
        {
            date: 'June 2024',
            value: '326179',
            value2: '320000'  // Slightly less than 326179
        },
        {
            date: 'July 2024',
            value: '324171',
            value2: '318000'  // Slightly less than 324171
        },
        {
            date: 'August 2024',
            value: '306567',
            value2: '300000'  // Slightly less than 306567
        }
    ],
    'Provision for baddebts - 50% of incremental of overdue EMI from 13th Nov-21': [
        {
            date: 'September 2023',
            value: '1200000',
            value2: '1185000'  // Slightly less than 1200000
        },
        {
            date: 'October 2023',
            value: '1190000',
            value2: '1175000'  // Slightly less than 1190000
        },
        {
            date: 'November 2023',
            value: '1180000',
            value2: '1165000'  // Slightly less than 1180000
        },
        {
            date: 'December 2023',
            value: '1170000',
            value2: '1155000'  // Slightly less than 1170000
        },
        {
            date: 'January 2024',
            value: '1160000',
            value2: '1145000'  // Slightly less than 1160000
        },
        {
            date: 'February 2024',
            value: '1150000',
            value2: '1135000'  // Slightly less than 1150000
        },
        {
            date: 'March 2024',
            value: '1140000',
            value2: '1125000'  // Slightly less than 1140000
        },
        {
            date: 'April 2024',
            value: '1130000',
            value2: '1115000'  // Slightly less than 1130000
        },
        {
            date: 'May 2024',
            value: '1120000',
            value2: '1105000'  // Slightly less than 1120000
        },
        {
            date: 'June 2024',
            value: '1271981',
            value2: '1250000'  // Slightly less than 1271981
        },
        {
            date: 'July 2024',
            value: '1165607',
            value2: '1150000'  // Slightly less than 1165607
        },
        {
            date: 'August 2024',
            value: '1433825',
            value2: '1410000'  // Slightly less than 1433825
        }
    ],
    'OFFICE EXPENSES': [
        {
            date: 'September 2023',
            value: '1240000',
            value2: '1220000'  // Slightly less than 1240000
        },
        {
            date: 'October 2023',
            value: '1230000',
            value2: '1210000'  // Slightly less than 1230000
        },
        {
            date: 'November 2023',
            value: '1220000',
            value2: '1200000'  // Slightly less than 1220000
        },
        {
            date: 'December 2023',
            value: '1210000',
            value2: '1190000'  // Slightly less than 1210000
        },
        {
            date: 'January 2024',
            value: '1200000',
            value2: '1180000'  // Slightly less than 1200000
        },
        {
            date: 'February 2024',
            value: '1190000',
            value2: '1170000'  // Slightly less than 1190000
        },
        {
            date: 'March 2024',
            value: '1180000',
            value2: '1160000'  // Slightly less than 1180000
        },
        {
            date: 'April 2024',
            value: '1170000',
            value2: '1150000'  // Slightly less than 1170000
        },
        {
            date: 'May 2024',
            value: '1160000',
            value2: '1140000'  // Slightly less than 1160000
        },
        {
            date: 'June 2024',
            value: '1240000',
            value2: '1220000'  // Slightly less than 1240000
        },
        {
            date: 'July 2024',
            value: '1240000',
            value2: '1220000'  // Slightly less than 1240000
        },
        {
            date: 'August 2024',
            value: '1240000',
            value2: '1220000'  // Slightly less than 1240000
        }
    ]
}





const APIDatashow3monthNetIncome = {
    'NET INCOME FORM BUSINESS': [
        {
            date: 'June 2024',
            value: '409908',
            value2: '405000'  // Slightly less than 409908
        },
        {
            date: 'July 2024',
            value: '80454',
            value2: '80000'  // Slightly less than 80454
        },
        {
            date: 'August 2024',
            value: '337837',
            value2: '335000'  // Slightly less than 337837
        }
    ],
};

const APIDatashow6monthNetIncome = {
    'NET INCOME FORM BUSINESS': [
        {
            date: 'March 2024',
            value: '450000',
            value2: '445000'  // Slightly less than 450000
        },
        {
            date: 'April 2024',
            value: '430000',
            value2: '425000'  // Slightly less than 430000
        },
        {
            date: 'May 2024',
            value: '410000',
            value2: '405000'  // Slightly less than 410000
        },
        {
            date: 'June 2024',
            value: '409908',
            value2: '405000'  // Slightly less than 409908
        },
        {
            date: 'July 2024',
            value: '80454',
            value2: '80000'  // Slightly less than 80454
        },
        {
            date: 'August 2024',
            value: '337837',
            value2: '335000'  // Slightly less than 337837
        }
    ],
};

const APIDatashow1yearNetIncome = {
    'NET INCOME FORM BUSINESS': [
        {
            date: 'September 2023',
            value: '400000',
            value2: '395000'  // Slightly less than 400000
        },
        {
            date: 'October 2023',
            value: '390000',
            value2: '385000'  // Slightly less than 390000
        },
        {
            date: 'November 2023',
            value: '380000',
            value2: '375000'  // Slightly less than 380000
        },
        {
            date: 'December 2023',
            value: '370000',
            value2: '365000'  // Slightly less than 370000
        },
        {
            date: 'January 2024',
            value: '360000',
            value2: '355000'  // Slightly less than 360000
        },
        {
            date: 'February 2024',
            value: '350000',
            value2: '345000'  // Slightly less than 350000
        },
        {
            date: 'March 2024',
            value: '340000',
            value2: '335000'  // Slightly less than 340000
        },
        {
            date: 'April 2024',
            value: '330000',
            value2: '325000'  // Slightly less than 330000
        },
        {
            date: 'May 2024',
            value: '320000',
            value2: '315000'  // Slightly less than 320000
        },
        {
            date: 'June 2024',
            value: '409908',
            value2: '405000'  // Slightly less than 409908
        },
        {
            date: 'July 2024',
            value: '80454',
            value2: '80000'  // Slightly less than 80454
        },
        {
            date: 'August 2024',
            value: '337837',
            value2: '335000'  // Slightly less than 337837
        }
    ],
};





export default function IncomeExpenses({ fromDate, toDate, applyFilter, handleApplyFilter, clearFilter, handleclearFilter, edit, handleEdit,ActiveMonthFilter  }) {

    const [selectedData, setSelectedData] = useState([]);
    const [selectedDataIndex, setSelectedDataIndex] = useState(-1);
    const [selectedDataHeading, setSelectedDataHeading] = useState('')
    const [APIData,setAPIData]=useState(APIDatashow3month)
    const [APIDataExpenses,setAPIDataExpenses]=useState(APIDatashow3monthExpenses)
    const [APIDataNetIncome,setAPIDataNetIncome]=useState(APIDatashow3monthNetIncome)
    useEffect(() => {
        if (applyFilter == true) {
            console.log("apply", fromDate, toDate)
            // api call for applying filter here 
            setTimeout(() => {
                handleApplyFilter()
            }, 500)
        }
        if (clearFilter == true) {
            setAPIData(APIDatashow3month)
            setAPIDataExpenses(APIDatashow3monthExpenses)
            setAPIDataNetIncome(APIDatashow3monthNetIncome)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

            console.log("clear", fromDate, toDate)
            // api call for clear filter here 
            setTimeout(() => {
                handleclearFilter()
            }, 500)
        }
        if(edit == true){
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
            setSelectedData([])
        }
        if(ActiveMonthFilter === '3month'){
            setAPIData(APIDatashow3month)
            setAPIDataExpenses(APIDatashow3monthExpenses)
            setAPIDataNetIncome(APIDatashow3monthNetIncome)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }else if(ActiveMonthFilter === '6month'){
            setAPIData(APIDatashow6month)
            setAPIDataExpenses(APIDatashow6monthExpenses)
            setAPIDataNetIncome(APIDatashow6monthNetIncome)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }else if(ActiveMonthFilter === '1year'){
            setAPIData(APIDatashow1year)
            setAPIDataExpenses(APIDatashow1yearExpenses)
            setAPIDataNetIncome(APIDatashow1yearNetIncome)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }
    }, [applyFilter, clearFilter,edit,ActiveMonthFilter])






    const handleSelectedData = (index,datakey, data) => {
        setSelectedData([])
        if (index === selectedDataIndex) {
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

        } else {
            setSelectedDataHeading(datakey)
            setSelectedDataIndex(index)
            setTimeout(()=>{
                setSelectedData(data)
            },300)  

        }

    }

    const handleEditValue = (e) => {
        const { name, value } = e.target;
    }

    const handleSavebtn = () => {
        handleEdit();
    }


    return (
        <div className="Dashboardsmis">
            {
                !edit ?
            <div className="row" style={{ position: 'relative' }}>
            <div className={`${selectedDataIndex == '-1' ? 'col-xs-12' : "col-md-6 col-xs-12"} transition-col`}>
                    <div className="table" style={{ marginTop: '20px' }}>
                        <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                            <table>
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Income</th>
                                        {
                                            APIData['INTEREST INCOME'].map((item, index) => (
                                                <th key={index}>{item.date}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                        <tr className={selectedDataIndex === `income-${index}` ? 'activeTr' : ''} key={index} style={{ cursor: 'pointer' }} onClick={() => handleSelectedData(`income-${index}`,datakey, datavalue)}>
                                            <td className='leftsticky'><b>{datakey}</b></td>
                                            {
                                                datavalue?.map((value, index) => {
                                                    return (
                                                        <td key={index}  style={{width: ActiveMonthFilter === '3month' ? '100px':'auto'}}>{value?.value}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Expenses</th>
                                        {
                                            APIData['INTEREST INCOME'].map((item, index) => (
                                                <th key={index}></th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(APIDataExpenses).map(([datakey, datavalue], index) => (
                                        <tr className={selectedDataIndex === `expenses-${index}` ? 'activeTr' : ''} key={index} style={{ cursor: 'pointer' }} onClick={() => handleSelectedData(`expenses-${index}`,datakey, datavalue)}>
                                            <td className='leftsticky'><b>{datakey?.length > 40 ? datakey.slice(0,40)+'...':datakey}</b></td>
                                            {
                                                datavalue?.map((value, index) => {
                                                    return (
                                                        <td key={index}  style={{width: ActiveMonthFilter === '3month' ? '100px':'auto'}}>{value?.value}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Net Income</th>
                                        {
                                            APIData['INTEREST INCOME'].map((item, index) => (
                                                <th key={index}></th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(APIDataNetIncome).map(([datakey, datavalue], index) => (
                                        <tr className={selectedDataIndex === `netincome-${index}` ? 'activeTr' : ''} key={index} style={{ cursor: 'pointer' }} onClick={() => handleSelectedData(`netincome-${index}`,datakey, datavalue)}>
                                            <td className='leftsticky'><b>{datakey}</b></td>
                                            {
                                                datavalue?.map((value, index) => {
                                                    return (
                                                        <td key={index}  style={{width: ActiveMonthFilter === '3month' ? '100px':'auto'}}>{value?.value}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {
                    selectedData?.length > 0 ?
                        <div className="col-md-6 col-xs-12" style={{ position: 'sticky', top: '10%' }}>
                                                        <div className="charts" style={{marginTop:'20px' }}>
                            <h3>{selectedDataHeading}</h3>
                            <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '730px':'460px'}}>
                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    width={500}
                                                    height={300}
                                                    data={selectedData}
                                                    barSize={ActiveMonthFilter === '3month' ? 40: ActiveMonthFilter === '6month' ? 30 : 20}
                                                    margin={{
                                                        top: 20,
                                                        right: 0,
                                                        left: 30,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid stroke='none' />
                                                    <XAxis dataKey="date" />
                                                    <YAxis padding={{ top: 20 }} />
                                                    <Tooltip />
                                                    <Bar dataKey="value" fill="#8884d8" />
                                                    <Line type="monotone" dataKey="value" stroke="#223D64" strokeWidth={2} dot={null} />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                            </div>
                        </div>
                        </div>
                        : ''}
            </div>
            :
            <div className="row">
            <div className='col-xs-12'>
                    <div className="table" style={{ marginTop: '20px' }}>
                        <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                            <table>
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Income</th>
                                        {
                                            APIData['INTEREST INCOME'].map((item, index) => (
                                                <th key={index}>{item.date}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                        <tr key={index}>
                                            <td className='leftsticky'><b>{datakey}</b></td>
                                            {
                                                datavalue?.map((value, index) => {
                                                    return (
                                                        <td key={index}><input type="number" name={datakey + '-' + value?.date} value={value.value} className='Editinputtag'  onChange={(e) => handleEditValue(e)} /></td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Expenses</th>
                                        {
                                            APIData['INTEREST INCOME'].map((item, index) => (
                                                <th key={index}></th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(APIDataExpenses).map(([datakey, datavalue], index) => (
                                       <tr key={index}>
                                            <td className='leftsticky'><b>{datakey}</b></td>
                                            {
                                                datavalue?.map((value, index) => {
                                                    return (
                                                        <td key={index}><input type="number" name={datakey + '-' + value?.date} value={value.value} className='Editinputtag'  onChange={(e) => handleEditValue(e)} /></td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Net Income</th>
                                        {
                                            APIData['INTEREST INCOME'].map((item, index) => (
                                                <th key={index}></th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(APIDataNetIncome).map(([datakey, datavalue], index) => (
                                      <tr key={index}>
                                            <td className='leftsticky'><b>{datakey}</b></td>
                                            {
                                                datavalue?.map((value, index) => {
                                                    return (
                                                        <td key={index}><input type="number" name={datakey + '-' + value?.date} value={value.value} className='Editinputtag'  onChange={(e) => handleEditValue(e)} /></td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="Filterations" style={{marginTop:'40px',textAlign:'center'}}>
                                <button className='filterbtn' style={{width:'8%'}} onClick={()=>handleSavebtn()}>Save</button>
                            </div>
                </div>
            </div>
}
        </div>
    )
}
