import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { STRING } from '../../../../../utils/Constant'
import AdminAction from '../../../../presentationals/Admin/CustomerPages/Action.component'
import { APIS } from '../../../../../utils/api-factory';
import { getLoanApprovalLead, saveActionLogs, getUserDetailByLoanId, approveLoanApi,giftCardSanction } from '../../AdminActionCreator.component';
import Popup from '../../../../presentationals/Popup/Popup.component';
import ConfirmationPopup from '../../../../presentationals/Popup/ConfirmationPopup.component';
import ApproveDataPopup from './Approve.popup';
import SoftApproveDataPopup from './SoftApprove.popup';
import DisburseDataPopup from './Disburse.popup';
import PaidDataPopup from './Paid.popup';
import SelectNbfcPopup from './SelectNbfc.popup';
import { _formatDateInDash, _preFormatDate } from '../../../../../utils/validation';
import VerificationPopup from '../../../../presentationals/Admin/CustomerPages/VarificationMailPopup.component'
import BankDetailPopup from '../BankDetailPopup.container'

class ActionContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDetail: this.props.userDetail,
            popupState: false,
            popupStatus: '',
            reason: '',
            confirmationPopupState: false,
            confirmationText: '',
            loanStatusState: '',
            mailState: '',
            approveDataState: false,
            softApproveDataState: false,
            loanApproveDate: '',
            loanApproveAmount: '',
            loanApproveTenure: '',
            loanPaybackDate: '',
            paybackDateToSave: '',
            score: '',
            actionChangeValue: '',
            dateLoanApprove: '',
            dateLoanPaid: '',
            varificationPopupViewState: false,
            actionReason: '',
            OtherInput: false,
            bankDetailPopup: false,
            actionLogs: {
                userIdState: '',
                actionState: '',
                loanIdState: '',
                userEmailState: '',
                empIdState: ''
            },
            disburseDataState: false,
            paidDataState: false,
            SelectNbfcPopupState: false,
            nbfcNameForApprove: 'nbfc',
            searchByEndDate: '',
            tabType: ''
        }
    }

    componentDidMount() {
        const { userDetail,allDetail } = this.props;
       
        if (userDetail != null) {
            this.setState({
                loanApproveDate: _formatDateInDash(new Date()),
            })
        }
          const { admin } = this.props
        let actionLogs = Object.assign({}, this.state.actionLogs);
        actionLogs.userIdState = this.state.allDetail.userId
        actionLogs.loanIdState = this.state.allDetail.loanId
        actionLogs.userEmailState = this.state.allDetail.emailId
        actionLogs.empIdState = admin.emailId
        this.setState({
            actionLogs,
        })

    }

    render() {
        const { admin,userDetails,userDetail,allDetail } = this.props;
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AdminAction
                userDetails={userDetail}
                    alldetails={allDetail}
                    reason={this.reason.bind(this)}
                    score={this.score.bind(this)}
                    actionChangeHandler={this.actionChangeHandler.bind(this)}
                    actionSubmitHandler={this.actionSubmitHandler.bind(this)}
                    varificationMailPopupHandler={this.varificationMailPopupHandler.bind(this)}
                    actionReason={this.state.actionReason}
                    otherReason={this.otherReason.bind(this)}
                    OtherInput={this.state.OtherInput}
                    admin={admin}
                    removeFromNBFCHandler={this.removeFromNBFCHandler.bind(this)}
                />
                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
                {this.state.approveDataState == true ?
                    <ApproveDataPopup
                    userDetails={userDetail}
                        allDetail={allDetail}
                        admin={admin}
                        closeApprovePopup={this.closeApprovePopup.bind(this)}
                        mailState={this.state.mailState}
                        reason={this.state.reason}
                        score={this.state.score}
                        nbfcNameForApprove={this.state.nbfcNameForApprove}
                    />
                    : ""}
                {this.state.softApproveDataState == true ?
                    <SoftApproveDataPopup
                        allDetail={allDetail}
                        admin={admin}
                        closeNbfcApprovePopup={this.closeNbfcApprovePopup.bind(this)}
                        mailState={this.state.mailState}
                        reason={this.state.reason}
                        score={this.state.score}
                        nbfcNameForApprove={this.state.nbfcNameForApprove}
                    />
                    : ""}
                {this.state.disburseDataState == true ?
                    <DisburseDataPopup
                    userDetails={userDetail}
                        closeDisbursePopup={this.closeDisbursePopup.bind(this)}
                        allDetail={allDetail}
                        admin={admin}
                        mailState={this.state.mailState}
                    />
                    : null}

                {this.state.paidDataState == true ?
                    <PaidDataPopup
                    userDetail={userDetail}

                        closePaidPopup={this.closePaidPopup.bind(this)}
                        allDetail={allDetail}
                        admin={admin}
                    />
                    : null}
                {this.state.SelectNbfcPopupState == true ?
                    <SelectNbfcPopup
                        closeNbfcPopup={this.closeNbfcPopup.bind(this)}
                        allDetail={allDetail} />
                    : null}
                {this.state.varificationPopupViewState == true ?
                    <VerificationPopup
                    userDetail={userDetail}
                        varificationPopupViewHandler={this.varificationPopupViewHandler.bind(this)}
                        verificationType={this.verificationType.bind(this)}
                        verificationSend={this.verificationSend.bind(this)}
                    />
                    : ""}
                {this.state.bankDetailPopup == true ?
                    <BankDetailPopup
                        allDetail={allDetail}
                        removeBankDetailPopup={this.removeBankDetailPopup.bind(this)} />
                    : ""}
            </div>
        )
    }

    removeFromNBFCHandler(data) {
        this.setState({
            loanStatusState: "removeNBFC",
            confirmationText: 'Are you sure, you want to REMOVE this Appication from NBFC',
            confirmationPopupState: true
        })
    }

    closeApprovePopup(callBack) {
        const { admin } = this.props
        if (callBack == 'success') {
            getUserDetailByLoanId(this.state.allDetail.loanId,
                (callBack) => {
                    this.setState({
                        allDetail: callBack,
                        // bankDetailPopup: admin.nbfcName == "" || admin.nbfcName == null ? true : false,
                        approveDataState: false,
                        popupState: true,
                        popupStatus: 'Loan has been Approved successfully',
                    },()=>this.props.getData('yes'))
                    this.props.getData('yes')
                    this.removePopup();
                })
        } else {
            this.setState({
                approveDataState: false,
                popupState: true,
                popupStatus: 'Loan not Approved',
            })
            this.removePopup();
        }
    }

    closeNbfcApprovePopup(callBack) {
        if (callBack == 'success') {
            getUserDetailByLoanId(this.state.allDetail.loanId,
                (callBack) => {
                    this.setState({
                        allDetail: callBack,
                        bankDetailPopup: true,
                        softApproveDataState: false,
                        popupState: true,
                        popupStatus: 'Loan has been Soft Approved successfully',
                    },()=>this.props.getData('yes'))
                    this.removePopup();
                })
        } else {
            this.setState({
                softApproveDataState: false,
                popupState: true,
                popupStatus: 'Loan not Approved',
            })
            this.removePopup();
        }
    }

    closePaidPopup(callBack) {
        if (callBack == 'success') {
            this.setState({
                paidDataState: false,
                popupState: true,
                popupStatus: 'Loan has been Paid successfully',
            },()=>this.props.getData('yes'))
            this.removePopup();
        } else {
            this.setState({
                paidDataState: false,
                popupState: true,
                popupStatus: 'Loan not Paid',
            })
            this.removePopup();
        }
    }

    closeDisbursePopup(callBack) {
        
        if (callBack == 'success') {
            this.setState({
                disburseDataState: false,
                popupState: true,
                popupStatus: 'Loan has been Disbursed successfully',
            },()=>this.props.getData('yes'))
            this.removePopup();
        } else {
            this.setState({
                disburseDataState: false,
                popupState: true,
                popupStatus: callBack!=undefined && callBack!=''?callBack:'Please Try Again',
            })
            this.removePopup();
        }
    }

    closeNbfcPopup(callBack, nbfcState) {
        if (callBack == 'success') {
            this.setState({
                SelectNbfcPopupState: false,
                popupState: true,
                popupStatus: 'Soft Approved Successfully',
                softApproveDataState: true,
                mailState: false,
                nbfcNameForApprove: nbfcState
            },()=>this.props.getData('yes'))
            this.removePopup();
        } else {
            this.setState({
                SelectNbfcPopupState: false,
                popupState: true,
                popupStatus: 'Please try again',
            })
            this.removePopup();
        }
    }

    removeBankDetailPopup() {
        this.setState({ bankDetailPopup: false })
    }

    varificationMailPopupHandler(id) {
        this.setState({
            idForVerification: id,
            varificationPopupViewState: true
        })
    }

    varificationPopupViewHandler() {
        this.setState({
            varificationPopupViewState: false
        })
    }

    verificationType(e) {
        this.setState({
            verficationMailType: e.target.value
        })
    }

    verificationSend() {
        const {userDetail} = this.props
        if(userDetail.typeOfProduct == 'Loan'){
        fetch(APIS.APPROVE_VERIFICATION + this.state.idForVerification + '&type=' + this.state.verficationMailType)
            .then(res => res.text())
            .then(text => {
                if (text == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Verification Mail Send Successfully',
                    },()=>this.props.getData('yes'))
                    this.removePopup();
                    this.varificationPopupViewHandler();
                }
            })
        }else if(userDetail.typeOfProduct == 'Gift Card'){
            fetch(APIS.APPROVE_VERIFICATION_GIFTCARD + userDetail.giftCardId + '&type=' + this.state.verficationMailType)
            .then(res => res.text())
            .then(text => {
                if (text == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Verification Mail Send Successfully',
                    },()=>this.props.getData('yes'))
                    this.removePopup();
                    this.varificationPopupViewHandler();
                }
            })
        }
    }

  
    actionChangeHandler(e) {
        let actionLogs = Object.assign({}, this.state.actionLogs);
        actionLogs.actionState = e.target.value
        this.setState({ actionChangeValue: e.target.value, actionLogs })
        if (e.target.value == 'reject' || e.target.value == 'Reject Without Mail' || e.target.value == STRING.PERMANENT_REJECT) {
            fetch(APIS.APPROVE_REJECT_REASON_CARD + 'reject')
                .then(res => res.json())
                .then(res => {
                    this.setState({ actionReason: res },()=>this.props.getData('yes'))
                })
        }
    }

    actionSubmitHandler() {
        const { userDetails,userDetail } = this.props;
        // console.log(userDetails)
        if(userDetail.typeOfProduct == 'Loan'){
        saveActionLogs(this.state.actionLogs,'',
            (callBack) => {
                

                if (callBack == 'success') {
                    if (this.state.actionChangeValue == "approve") {
                        this.setState({
                            loanStatusState: STRING.APPROVED_STATUS,
                            mailState: true,
                            loanApproveAmount: userDetail.loanAmount,
                            confirmationText: 'Are you sure, you want to APPROVE this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "reject") {
                        this.setState({
                            loanStatusState: STRING.REJECT_STATUS,
                            mailState: true,
                            confirmationText: 'Are you sure, you want to REJECT this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "Approve Without Mail") {
                        this.setState({
                            loanStatusState: STRING.APPROVED_STATUS,
                            loanApproveAmount: userDetail.loanAmount,
                            mailState: false,
                            confirmationText: 'Are you sure, You want to APPROVE this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "Reject Without Mail") {
                        this.setState({
                            loanStatusState: STRING.REJECT_STATUS,
                            mailState: false,
                            confirmationText: 'Are you sure, you want to REJECT this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "Disbursed") {
                        if(userDetails.loanFrom==='bazarBussiness'){
                            this.setState({
                                loanStatusState: STRING.DISBURSED,
                                mailState: true,
                                confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                confirmationPopupState: true,
                                popupState:false
                            })
                        }
                        if(userDetails.loanFrom!=='bazarBussiness'){

                        if (userDetail.statusId == STRING.APPROVED_STATUS) {
                            this.setState({
                                loanStatusState: STRING.DISBURSED,
                                mailState: true,
                                confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                confirmationPopupState: true,
                            })
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Kindly APPROVE the loan first!',
                            })
                            this.removePopup();
                        }
                    }
                    } else if (this.state.actionChangeValue == "Disbursed Without Mail") {
                        if(userDetails.loanFrom==='bazarBussiness'){
                            this.setState({
                                loanStatusState: STRING.DISBURSED,
                                mailState: false,
                                confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                confirmationPopupState: true,
                                popupState:false
                            })
                        }
                        if(userDetails.loanFrom!=='bazarBussiness'){
                        if ((userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED )) {
                            this.setState({
                                loanStatusState: STRING.DISBURSED,
                                mailState: false,
                                confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                confirmationPopupState: true,
                            })
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Kindly APPROVE the loan first!',
                            })
                            this.removePopup();
                        }
                    }
                    } else if (this.state.actionChangeValue == "Paid") {
                        this.setState({
                            loanStatusState: STRING.PAID,
                            loanApproveAmount: this.state.allDetail.totalPayBackAmount,
                            confirmationText: 'Are you sure, this LOAN has been PAID',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == STRING.PERMANENT_REJECT) {
                        this.setState({
                            loanStatusState: STRING.PERMANENT_REJECT,
                            mailState: true,
                            confirmationText: 'Are you sure, you want to Permanent REJECT this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "softApproval") {
                        this.setState({
                            loanStatusState: "softApproval",
                            mailState: true,
                            confirmationText: 'Are you sure, you want to Soft Approve this LOAN',
                            confirmationPopupState: true
                        })
                    }
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: callBack,
                    })
                    this.removePopup();
                }
            })
        }else if(userDetail.typeOfProduct == 'Gift Card'){
            saveActionLogs(this.state.actionLogs,userDetail.giftCardId,
            (callBack) => {
                if (callBack == 'success') {
                    if (this.state.actionChangeValue == "approve") {
                        this.setState({
                            loanStatusState: STRING.APPROVED_STATUS,
                            mailState: true,
                            loanApproveAmount: userDetail.loanAmount,
                            confirmationText: 'Are you sure, you want to APPROVE this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "reject") {
                        this.setState({
                            loanStatusState: STRING.REJECT_STATUS,
                            mailState: true,
                            confirmationText: 'Are you sure, you want to REJECT this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "Approve Without Mail") {
                        this.setState({
                            loanStatusState: STRING.APPROVED_STATUS,
                            loanApproveAmount: userDetail.loanAmount,
                            mailState: false,
                            confirmationText: 'Are you sure, You want to APPROVE this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "Reject Without Mail") {
                        this.setState({
                            loanStatusState: STRING.REJECT_STATUS,
                            mailState: false,
                            confirmationText: 'Are you sure, you want to REJECT this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "Disbursed") {
                        if (userDetail.statusId == STRING.APPROVED_STATUS) {
                            this.setState({
                                loanStatusState: STRING.DISBURSED,
                                mailState: true,
                                confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                confirmationPopupState: true,
                            })
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Kindly APPROVE the loan first!',
                            })
                            this.removePopup();
                        }
                    } else if (this.state.actionChangeValue == "Disbursed Without Mail") {
                        if (userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED) {
                            this.setState({
                                loanStatusState: STRING.DISBURSED,
                                mailState: false,
                                confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                confirmationPopupState: true,
                            })
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Kindly APPROVE the loan first!',
                            })
                            this.removePopup();
                        }
                    } else if (this.state.actionChangeValue == "Paid") {
                        this.setState({
                            loanStatusState: STRING.PAID,
                            loanApproveAmount: this.state.allDetail.totalPayBackAmount,
                            confirmationText: 'Are you sure, this LOAN has been PAID',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == STRING.PERMANENT_REJECT) {
                        this.setState({
                            loanStatusState: STRING.PERMANENT_REJECT,
                            mailState: true,
                            confirmationText: 'Are you sure, you want to Permanent REJECT this LOAN',
                            confirmationPopupState: true
                        })
                    } else if (this.state.actionChangeValue == "softApproval") {
                        this.setState({
                            loanStatusState: "softApproval",
                            mailState: true,
                            confirmationText: 'Are you sure, you want to Soft Approve this LOAN',
                            confirmationPopupState: true
                        })
                    }
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: callBack,
                    })
                    this.removePopup();
                }
            })   
        }
    }
    reason(e) {
        let reasonType = e.target.value
        if (reasonType == 'Other') {
            this.setState({ OtherInput: true })
        } else {
            this.setState({ reason: reasonType, OtherInput: false })
        }
    }

    otherReason(e) {
        this.setState({ reason: e.target.value })
    }
    score(e) {
        this.setState({ score: e.target.value })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    cancelLoanConfirm(buttonType) {
        const { admin ,userDetail,allDetail} = this.props
        if (buttonType == 'yes') {
            this.setState({
                confirmationPopupState: false
            })
            if (this.state.loanStatusState == STRING.APPROVED_STATUS) {
                this.setState({
                    approveDataState: true
                })
            } else if (this.state.loanStatusState == STRING.DISBURSED) {
                this.setState({
                    disburseDataState: true,
                })
            } else if (this.state.loanStatusState == STRING.REJECT_STATUS || this.state.loanStatusState == STRING.PERMANENT_REJECT) {
                if(userDetail.typeOfProduct == 'Loan'){
                approveLoanApi(userDetail, this.state.loanStatusState, this.state.reason, '', this.state.mailState, this.state.score, admin.emailId, [], admin.nbfcName,
                    callBack => {
                        
                        if(callBack == 'success'){
                        this.props.getLoanApprovalLead(STRING.SELECT_ALL, 1, admin.emailId, '', admin.nbfcName, this.state.searchByEndDate, this.state.tabType,'','','','','',
                            (callBack) => {
                                
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Loan has been Rejected successfully',
                                },()=>this.props.getData('yes'))
                                this.removePopup();
                            });
                        }
                    })
                }else if(userDetail.typeOfProduct == 'Gift Card'){
                    giftCardSanction(userDetail, this.state.loanStatusState, this.state.reason, '', this.state.mailState, this.state.score, admin.emailId, [], admin.nbfcName,
                    callBack => {
                        if(callBack == 'success'){
                        this.props.getLoanApprovalLead(STRING.SELECT_ALL, 1, admin.emailId, '', admin.nbfcName, this.state.searchByEndDate, this.state.tabType,'','','','','',
                            (callBack) => {
                                
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Loan has been Rejected successfully',
                                },()=>this.props.getData('yes'))
                                this.removePopup();
                            });
                        }
                    })
                }
            } else if (this.state.loanStatusState == STRING.PAID) {
                this.setState({
                    paidDataState: true,
                })
            } else if (this.state.loanStatusState == "softApproval") {
                this.setState({
                    SelectNbfcPopupState: true,
                })
            } else if (this.state.loanStatusState == "removeNBFC") {
                fetch(APIS.REMOVE_APPLICATION_FROM_NBFC + this.state.allDetail.loanId + '&nbfc=')
                    .then(res => res.text())
                    .then(text => {
                        this.setState({
                            popupState: true,
                            popupStatus: text,
                        })
                        this.removePopup();
                    })

            }
        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }

    componentWillUnmount() {

    }
    componentWillMount(){
    }

}
const mapStateToProps = ({ }) => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getLoanApprovalLead,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(ActionContainer);