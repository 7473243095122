import React, { Component } from 'react'
import AssgnRole from '../../../presentationals/Admin/Settings/NewRoleAssignment.component'
import { fetchRoleDetail, getRoleAssignView, adminLoginApi } from '../AdminActionCreator.component'
import { bindActionCreators } from 'redux';
import localStorageUtil from '../../../../utils/localStorageUtil.js';
import { connect } from 'react-redux';
import { TYPE } from '../../../../utils/Constant'
import { APIS } from '../../../../utils/api-factory'
import Popup from '../../../presentationals/Popup/Popup.component';
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
let permissions = []
let checkRole = []
let leaveCheckbox = false
let paginationNumber=10
class AssignRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopupState: false,
            confirmationText: '',
            popupState: false,
            popupStatus: '',
            adminRole: '',
            userRole: '',
            roleDataState: "",
            roleDetail: {
                emailIdState: "",
                nameState: "",
                permissionState: [],
                passwordState: "",
                roleState: '',
                departmentState: "",
                nbfcState: ""
            },
            FilteredData: [],
            pageIndexToShow: 0,
            allNbfcList: [],
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            selectedFilter:''
        }
    }
nameFilter(e){
    this.setState({selectedFilter:e.target.value})
}
    getNbfcList() {
        fetch(APIS.GET_LIST_OF_NBFC)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allNbfcList: res
                })
            })
    }
    tablePagination(data,type){
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    render() {
        const { admin } = this.props
        if (admin.role != undefined && admin.role == 'superuser') {
            leaveCheckbox = true
        }
        let filterData = this.state.roleDataState
        if (this.state.selectedFilter && this.state.selectedFilter != '' && filterData != null && filterData != '' && filterData.length > 0) {
            filterData = filterData.filter((row) => {
                if (row.name != null) {
                    return row.name.toLowerCase().includes(this.state.selectedFilter.toLowerCase())
                }
            })
        }
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AssgnRole
                nameFilter={this.nameFilter.bind(this)}
                selectedFilter={this.state.selectedFilter}
                tablePagination={this.tablePagination.bind(this)}
                tableMaxPagination={this.state.tableMaxPagination}
                tableMinPagination={this.state.tableMinPagination}
                RoleAssignHandler={this.RoleAssignHandler.bind(this)}
                    RoleChange={this.RoleChange.bind(this)}
                    roleDetail={this.state.roleDetail}
                    userRole={this.state.userRole}
                    roleDataState={filterData}
                    adminRole={this.state.adminRole}
                    RoleEditHandler={this.RoleEditHandler.bind(this)}
                    RoleSelectAll={this.RoleSelectAll.bind(this)}
                    RoleDeleteHandler={this.RoleDeleteHandler.bind(this)}
                    admin={admin}
                    leaveCheckbox={leaveCheckbox}
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                    assignApplicationHandler={this.assignApplicationHandler.bind(this)}
                    allNbfcList={this.state.allNbfcList}
                />

                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
            </div>
        )
    }

    assignApplicationHandler(e, data) {
        const { admin } = this.props
        fetch(APIS.UPDATE_ASSIGN_STATUS + data.emailId + '&assign=' + e.target.checked)
            .then(res => res.text())
            .then(res => {
                if (res == 'success') {
                    getRoleAssignView(admin.emailId,
                        (callBack) => {
                            this.setState({
                                roleDataState: callBack,
                                popupState: true,
                                popupStatus: 'Changed Successfully',
                            })
                            this.removePopup();
                        });
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup();
                }
            })
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    componentWillMount() {
        const { admin } = this.props
        getRoleAssignView(admin.emailId,
            (callBack) => {
                this.setState({
                    roleDataState: callBack
                })
            });

        fetch(APIS.GET_ALL_ADMIN_ROLE + admin.emailId)
            .then(json => json.json())
            .then(res => {
                this.setState({
                    adminRole: res
                })
            })

        fetch(APIS.GET_ALL_USER_ROLE)
            .then(json => json.json())
            .then(res => {
                this.setState({
                    userRole: res
                })
            })
        this.getNbfcList()
    }

    RoleSelectAll(e, roleData) {
        let roleDetail = Object.assign({}, this.state.roleDetail);
        if (e.target.checked) {
            for (let i = 0; i < roleData.length; i++) {
                document.getElementById("role" + roleData[i].roleId).checked = true;
                if (permissions.includes(roleData[i].roleId)) {

                } else {
                    permissions.push(roleData[i].roleId)
                }

            }
        } else {
            for (let i = 0; i < roleData.length; i++) {
                document.getElementById("role" + roleData[i].roleId).checked = false;
            }
            permissions = []
        }
        roleDetail.permissionState = permissions;
        this.setState({
            roleDetail,
        });
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    RoleEditHandler(roleData) {
        
        for (let i = 0; i < this.state.userRole.length; i++) {
            document.getElementById("role" + this.state.userRole[i].roleId).checked = false;
        }
        let roleDetail = Object.assign({}, this.state.roleDetail);
        roleDetail.emailIdState = roleData.emailId;
        roleDetail.passwordState = roleData.password;
        roleDetail.nameState = roleData.name;
        roleDetail.roleState = roleData.role;
        roleDetail.departmentState = roleData.department;
        roleDetail.nbfcState = roleData.nbfcName;
        for (let j = 0; j < roleData.rolelist.length; j++) {
            checkRole = roleData.rolelist[j]
            if (document.getElementById("role" + checkRole))
                document.getElementById("role" + checkRole).checked = true;
            if (permissions.includes(checkRole)) {

            } else {
                permissions.push(checkRole)
            }
        }
        roleDetail.permissionState = permissions;
        this.setState({
            roleDetail,
        });
    }

    RoleDeleteHandler(roleData) {
        this.setState({
            confirmationText: "Are You Sure To Delete " + roleData.name + " From CRM",
            confirmationPopupState: true,
            roleDataForDelete: roleData
        })
    }

    cancelLoanConfirm(confirmType) {
        const { admin } = this.props
        if (confirmType == 'yes') {
            fetch(APIS.DELETE_EMPLOYEE + this.state.roleDataForDelete.emailId)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        getRoleAssignView(admin.emailId,
                            (callBack) => {
                                this.setState({
                                    roleDataState: callBack,
                                    confirmationPopupState: false,
                                    popupState: true,
                                    popupStatus: this.state.roleDataForDelete.name + ' Deleted Successfully',
                                })
                                this.removePopup();
                                localStorage.clear()
                            });
                    }
                })
        } else if (confirmType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }

    RoleChange(e, type) {
        let roleDetail = Object.assign({}, this.state.roleDetail);
        if (type == TYPE.EMP_ID) {
            roleDetail.emailIdState = e.target.value;
        } else if (type == TYPE.EMP_PASS) {
            roleDetail.passwordState = e.target.value;
        } else if (type == TYPE.EMP_NAME) {
            roleDetail.nameState = e.target.value;
        } else if (type == TYPE.EMP_ROLE) {
            roleDetail.roleState = e.target.value;
        } else if (type == TYPE.EMP_DEPARTMENT) {
            roleDetail.departmentState = e.target.value;
        } else if (type == TYPE.NBFC_NAME) {
            roleDetail.nbfcState = e.target.value;
        }
        else if (type == TYPE.EMP_PERMISSION) {
            document.getElementById("roleSelectAll").checked = false
            if (e.target.checked == true) {
                if (!permissions.includes(e.target.value)) {
                    permissions.push(e.target.value)
                }
            } else {
                let index = permissions.indexOf(e.target.value)
                if (index >= 0) {
                    permissions.splice(index, 1);
                }
            }
            roleDetail.permissionState = permissions;
        }

        this.setState({
            roleDetail,
        });
    }

    RoleAssignHandler() {
        const { admin } = this.props
        fetchRoleDetail(this.state.roleDetail, (callBack) => {
            let roleDetail = Object.assign({}, this.state.roleDetail)
            if (callBack == "success") {
                getRoleAssignView(admin.emailId, (callBack) => {
                    this.setState({
                        roleDataState: callBack
                    })
                });
                if (localStorageUtil.getFromLocalStorage('AdminemailId') != null && localStorageUtil.getFromLocalStorage('password') != null) {
                    this.props.adminLoginApi(localStorageUtil.getFromLocalStorage('AdminemailId'), localStorageUtil.getFromLocalStorage('password'), (callBack) => {

                    })
                }
                permissions = []
                for (let i = 0; i < this.state.userRole.length; i++) {
                    document.getElementById("role" + this.state.userRole[i].roleId).checked = false;
                }
                document.getElementById("roleSelectAll").checked = false
                this.setState({
                    popupState: true,
                    popupStatus: 'Role Assigned Successfully',
                    roleDetail: {
                        emailIdState: "",
                        passwordState: "",
                        nameState: "",
                        roleState: "",
                        departmentState: "",
                        permissionState: [],
                        nbfcState: ""
                    }
                })
                this.removePopup();
            }
        });
    }
}
const mapStateToProps = ({ roleDetail }) => {
    return {
        roleDetail: roleDetail
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        adminLoginApi
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignRole);