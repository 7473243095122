import React from "react";

const SpinnerLoader = () => {
    return (
        <div className="spinner-loader">
        <span class="loader"></span>
        </div>

    )
}
export default SpinnerLoader