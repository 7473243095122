import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BulkStatus from '../../../presentationals/Admin/BulkActions/BulkStatusChange.component';
import {
    getLoanApprovalLead, getistByNumber, getlistByEmail,
    getlistByApplication, bulkStatusChange, getUserDetailByLoanId
} from '../AdminActionCreator.component';
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { STRING } from '../../../../utils/Constant'
import Popup from '../../../presentationals/Popup/Popup.component'


class BulkStatusContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userState: true,
            viewState: false,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.SELECT_ALL,
            forwordPageState: 1,
            loadarState: false,
            viewPopupDetail: '',
            logsUserId: '',
            setStatusValue: '',
            usersForChangeStatus: [],
            FilteredData: [],
            pageIndexToShow: 0,
            searchByEndDate: '',
            tabType: '',
            popupState: false,
            popupStatus: '',
        }
    }

    componentDidMount() {
        this.getLoanLead()
    }

    getLoanLead() {
        this.setState({
            loadarState: true
        })
        this.props.getLoanApprovalLead(this.state.followUpStatus, this.state.forwordPageState, this.props.admin.emailId, '', this.props.admin.nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '','',
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            });
    }

    render() {
        const { loanApprovalLead, admin, allStatus } = this.props;
       
        // console.log(this.state.usersForChangeStatus)
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.userState == true ?
                    <BulkStatus
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        allUserDetail={loanApprovalLead}
                        statusHandler={this.statusHandler.bind(this)}
                        forwordpage={this.forwordpage.bind(this)}
                        backpage={this.backpage.bind(this)}
                        dataFilter={this.dataFilter.bind(this)}
                        admin={admin}
                        loadarState={this.state.loadarState}
                        refreshpage={this.refreshpage.bind(this)}
                        allStatus={allStatus}
                        changeStatusHandler={this.changeStatusHandler.bind(this)}
                        statusSelectHandler={this.statusSelectHandler.bind(this)}
                        statusSaveHandler={this.statusSaveHandler.bind(this)}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        usersForChangeStatus={this.state.usersForChangeStatus}
                    /> : ""}
                {this.state.viewState == true ?
                    <UserDetail
                        getData={this.getData.bind(this)}
                        allDetail={this.state.allDetail}
                        userDetail={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)} />
                    : ""}
            </div>
        )
    }
    getData() {

    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }
    statusSelectHandler(e) {
        this.setState({ setStatusValue: e.target.value })
    }
    changeStatusHandler(e, allDetail) {
        if (e.target.checked == true) {
            if (this.state.usersForChangeStatus.includes(allDetail.loanId)) {

            } else {
                this.state.usersForChangeStatus.push(allDetail.loanId)
            }
        } else {
            let userSChangeStatusIndex = this.state.usersForChangeStatus.indexOf(allDetail.loanId)
            if (userSChangeStatusIndex >= 0) {
                this.state.usersForChangeStatus.splice(userSChangeStatusIndex, 1);
            }
        }

        this.setState({
            usersForChangeStatus: this.state.usersForChangeStatus,
        })
    }

    statusSaveHandler() {
        const { admin } = this.props
        bulkStatusChange(this.state.setStatusValue, this.state.usersForChangeStatus, admin.emailId,
            (callBack) => {
                // console.log(callBack)
                if (callBack == "success") {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Successfully Changed',
                        setStatusValue: '',
                        usersForChangeStatus: [],
                    })
                    this.removePopup()
                    this.getLoanLead()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please Try Again',
                    })
                    this.removePopup()
                }

            })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ popupState: false });
    }
    refreshpage() {
        this.getLoanLead()
    }

    dataFilter(e, type) {
        const { admin } = this.props
        if (type == "applicationNo") {
            let appNo = e.target.value
            if (appNo.length > 4) {
                this.setState({
                    loadarState: true,
                })
                this.props.getlistByApplication(appNo, 1, '', admin, true,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "email") {
            let email = e.target.value
            if (email.length > 2) {
                this.setState({
                    loadarState: true,
                })
                this.props.getlistByEmail(email, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "mobileNo") {
            let mobile = e.target.value
            if (mobile.length > 9) {
                this.setState({
                    loadarState: true,
                })
                this.props.getistByNumber(mobile, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        }
        if (e.target.value == "") {
            this.getLoanLead()
        }
    }
    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1
        }, () => this.getLoanLead())

    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
            }, () => this.getLoanLead())
        }
    }
    statusHandler(e) {
        this.setState({
            followUpStatus: e.target.value
        }, () => this.getLoanLead())
    }

    componentDidUpdate() {
        const { allUserDetail, loanApprovalLead, admin } = this.props;
        if (loanApprovalLead.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }
    }

    backHandler() {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false,
        })
        this.getLoanLead()
    }
    editUserDetailHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: false,
                    allDetail: callBack,
                    userDetail: allDetail,
                })
            })
    }
    userDetailHandler(allDetail) {

        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    allDetail: callBack,
                    userDetail: allDetail
                })
            })
    }
    userViewHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    viewPopupDetail: callBack
                })
            })
    }

}

const mapStateToProps = ({ allUserDetail, loanApprovalLead, allStatus }) => {
    return {
        allUserDetail: allUserDetail,
        loanApprovalLead: loanApprovalLead,
        allStatus: allStatus
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getLoanApprovalLead

    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(BulkStatusContainer);