import React from "react";
import { ROLE, STRING } from "../../../../utils/Constant";
import UserStatus from '../../../presentationals/Admin/CustomerPages/UserStatus.component'
import { saveFollowUpStatus, saveFollowUpStatusForGiftCard } from "../AdminActionCreator.component";
import PopUp from "../../../presentationals/Popup/Popup.component";
import ActionContainer from "./loanAction/action";
import CommTab from "./CommunicationNew/mainTabsCommunicate";
class Action extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionPopup: false,
            popupStatus: '',
            popupState: false,
            communicationPopup: false,
            statusPopup: false,
            statusValue: ''
        }
    }
    handlePopup(type) {
        if (type === 'action') {
            this.setState({ actionPopup: !this.state.actionPopup })
        } else {
            this.setState({ communicationPopup: !this.state.communicationPopup })

        }
    }
    statusHandler(e) {
        if (this.state.statusValue != '') {
            const { userDetail, admin } = this.props;

            let statusValue = this.state.statusValue
            if (statusValue === '113' && admin.department !== 'Recovery Team') {
                // if (statusValue === '113' && (admin.department === 'Legal' || admin.department === 'Customer Service' || admin.emailId === 'SuperUser' || admin.emailId === 'vishnu.pandey@phocket.in')) {
                this.setState({ loaderState: true })

                if (userDetail.typeOfProduct == 'Loan') {
                    saveFollowUpStatus(userDetail.loanId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Follow-up status save Successfully',
                                    loaderState: false
                                })
                                this.removePopup();
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Please try again later!',
                                    loaderState: false
                                })
                                this.removePopup();
                            }
                        })
                } else if (userDetail.typeOfProduct == 'Gift Card') {
                    this.setState({ loaderState: true })

                    saveFollowUpStatusForGiftCard(userDetail.giftCardId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Follow-up status save Successfully',
                                    loaderState: false
                                })
                                this.removePopup();
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Please try again later!',
                                    loaderState: false
                                })
                                this.removePopup();
                            }
                        })
                }
            } else {
                this.setState({
                    popupState: true,
                    loaderState: false,
                    popupStatus: 'You can not set this status from here',
                })
                this.removePopup();
            }

            if (statusValue == STRING.CHEQUE_BOUNCE) {
                if (userDetail.loanPaybackDate.split(' ')[0] <= moment(new Date()).format('DD-MM-YYYY')) {
                    this.setState({ loaderState: true })

                    saveFollowUpStatus(userDetail.loanId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Follow-up status save Successfully',
                                    loaderState: false
                                })
                                this.removePopup();
                            } else {
                                this.setState({
                                    popupState: true,
                                    loaderState: false,
                                    popupStatus: 'Please try again later!',
                                })
                                this.removePopup();
                            }
                        })
                } else {
                    this.setState({
                        popupState: true,
                        loaderState: false,
                        popupStatus: 'Please wait for payback date',
                    })
                    this.removePopup();
                }
            }
            if (statusValue != STRING.APPROVED_STATUS && statusValue != STRING.PAID && statusValue != STRING.DISBURSED && statusValue != STRING.RECEIVED && statusValue != STRING.CANCEL_BY_USER && statusValue != STRING.REJECT_STATUS && statusValue != STRING.CHEQUE_BOUNCE && statusValue != STRING.PERMANENT_REJECT && statusValue !== '113') {
                this.setState({ loaderState: true })

                if (userDetail.typeOfProduct == 'Loan') {
                    this.setState({ loaderState: true })

                    saveFollowUpStatus(userDetail.loanId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    loaderState: false,
                                    popupStatus: 'Follow-up status save Successfully',
                                })
                                this.removePopup();
                            } else {
                                this.setState({
                                    popupState: true,
                                    loaderState: false,
                                    popupStatus: 'Please try again later!',
                                })
                                this.removePopup();
                            }
                        })
                } else if (userDetail.typeOfProduct == 'Gift Card') {
                    this.setState({ loaderState: true })

                    saveFollowUpStatusForGiftCard(userDetail.giftCardId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    loaderState: false,
                                    popupStatus: 'Follow-up status save Successfully',
                                })
                                this.removePopup();
                            } else {
                                this.setState({
                                    loaderState: false,
                                    popupState: true,
                                    popupStatus: 'Please try again later!',
                                })
                                this.removePopup();
                            }
                        })
                }
            } else {
                this.setState({
                    popupState: true,
                    loaderState: false,
                    popupStatus: 'You can not set this status from here',
                })
                this.removePopup();
            }

        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    selectStatusHandler(e) {
        this.setState({ statusValue: e.target.value })
    }
    render() {
        const { allDetail, allStatus, admin, userDetail } = this.props
        return (
            <div className="mt-5">
                {this.state.popupState ? <PopUp popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)}

                /> : ""}
                <div className='' >
                    {admin.rolelist != undefined && (admin.rolelist.indexOf(ROLE.STATUS_CHANGE_DROPDOWN) >= 0 || admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 || admin.rolelist.indexOf(ROLE.ACTION_PAID) >= 0 || admin.rolelist.indexOf(ROLE.ACTION) >= 0) ?
                        <>
                            {!this.state.actionPopup ?
                                <button onClick={() => this.handlePopup('action')} className='action-btn-close'>Action <i className='fa fa fa-chevron-up'></i> </button>

                                : ""}
                        </>
                        : ""}
                    {/* <div className={this.state.actionPopup ? "action-box animated slideInRight" : 'action-box animated slideOutRight'} > */}
                    {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                        <>
                            {!this.state.communicationPopup ?
                                <button onClick={() => this.handlePopup('communication')} className='action-btn-close-communication'>Communication <i className='fa fa fa-chevron-up'></i> </button>

                                : ""}
                        </>
                        : ""}
                    {this.state.actionPopup ?
                        admin.rolelist != undefined && (admin.rolelist.indexOf(ROLE.STATUS_CHANGE_DROPDOWN) >= 0 || admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 || admin.rolelist.indexOf(ROLE.ACTION_PAID) >= 0 || admin.rolelist.indexOf(ROLE.ACTION) >= 0) ?
                            <div className='action-box animated slideInRight' >
                                <button onClick={() => this.handlePopup('action')} className='action-btn'>Action <i className='fa fa fa-chevron-down'></i> </button>
                                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.STATUS_CHANGE_DROPDOWN) >= 0 ?
                                    <div className="">
                                        <div className="" style={{ background: '#1D3557', cursor: 'pointer', color: '#fff', borderRadius: '6px', padding: '10px', marginTop: '20px', borderBottomLeftRadius: this.state.actionPopup ? '0px' : '6px', borderBottomRightRadius: this.state.actionPopup ? '0px' : '6px' }} onClick={() => this.setState({ statusPopup: !this.state.statusPopup })} >
                                            <div className="" >
                                                Set Status
                                                {!this.state.statusPopup ? <i className="fa fa-chevron-down text-right" style={{ float: 'inline-end' }}></i>
                                                    : <i className="fa fa-chevron-up text-right" style={{ float: 'inline-end' }}></i>}

                                            </div>

                                        </div>

                                        {this.state.statusPopup ?
                                            <div className="" style={{
                                                padding: '20px',
                                                borderRadius: '12px', border: '1px solid rgba(0, 0, 0, 0.15)', minHeight: '100px',
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0
                                            }}>
                                                <UserStatus
                                                    admin={admin}
                                                    statusHandler={this.statusHandler.bind(this)}
                                                    allDetail={allDetail}
                                                    allStatus={allStatus}
                                                    userDetails={userDetail}
                                                    selectStatusHandler={this.selectStatusHandler.bind(this)}
                                                />
                                            </div>
                                            : ""}

                                    </div>
                                    : null}
                                {admin.rolelist != undefined && (admin.rolelist.indexOf(ROLE.ACTION) >= 0||admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0||admin.rolelist.indexOf(ROLE.ACTION_PAID) >= 0) ?
                                    <ActionContainer
                                        admin={admin}
                                        allDetail={allDetail}
                                        allStatus={allStatus}
                                        userDetails={userDetail}
                                        userDetail={userDetail} />
                                    : ""}
                            </div>
                            : ""
                        : ""}
                    {this.state.communicationPopup ?
                        admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                            <div className='action-box animated slideInRight' >
                                <button onClick={() => this.handlePopup('communication')} className='action-btn-communication'>Communication <i className='fa fa fa-chevron-down'></i> </button>
                                <CommTab admin={admin} userDetail={userDetail} allDetail={allDetail} />
                            </div>
                            : ""
                        : ""}
                    {/* </div> */}
                </div>
            </div>
        )
    }
}
export default Action