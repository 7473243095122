
import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const RADIAN = Math.PI / 180;
const data = [
    { name: 'A', value: 80, color: '#232F63' },
    { name: 'B', value: 45, color: '#DBE9FFB2' },
];
const cx = 90;
const cy = 80;
const iR = 50;
const oR = 80;
const value = 50;

const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
        total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = ((iR + 2 * oR) / 3) - 50;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle cx={x0 - 1} cy={y0 - 5} r={27} fill={'white'} stroke="#0000001A " style={{ boxShadow: '0px 4px 4px 0px #0000001A' }} />,
        <circle cx={x0 - 1} cy={y0 - 5} r={20} fill={'#DBE9FF'} stroke="#0000001A" style={{ boxShadow: '2px -2px 4px 0px #0000001A inset' }} />,
        <circle cx={x0} cy={y0} r={5} fill={'#232F63'} stroke="none" />,
        <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={'#232F63'} />,
        <circle cx={x0} cy={y0} r={3} fill={'#DBE9FF'} stroke="none" />,
    ];
};

export default class Example extends PureComponent {

    render() {
        const { dashboardData } = this.props;
     
        let dataToshow = []
        dataToshow.push({ name: 'A', value: dashboardData.markedAsCompleteLeadsPercentage, color: '#232F63' },
            { name: 'B', value: dashboardData.totalAssignedLeadsPercentage, color: '#DBE9FFB2' },)
        return (
            <PieChart width={200} height={110}>
                <Pie
                    dataKey="value"
                    startAngle={180}
                    cornerRadius={3}
                    endAngle={0}
                    data={dataToshow}
                    cx={cx}
                    cy={cy}
                    innerRadius={iR}
                    outerRadius={oR}
                    fill="#8884d8"
                    stroke="none"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                {needle(dashboardData.markedAsCompleteLeadsPercentage, data, cx, cy, iR, oR, '#d0d000')}
            </PieChart>
        );
    }
}
