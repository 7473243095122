import React, { useState } from 'react'
import BuddyLoanReport from './BuddyLoanReport.container'
import BranchReport from './BranchData.container'
import { ROLE } from '../../../../utils/Constant'
export default function BranchBuddyLoanMain({admin}) {
    const [viewState, setViewState] = useState('branch')
    return (
        <>
            <div className='marketing-main-css'>
                <div className="row fixed-top">
                    {
                        admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0  ?
                        <div className={ admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ? "col-sm-6 col-xs-6":'col-xs-12'} onClick={() => setViewState('branch')} style={{ borderBottom: viewState === 'buddy' ? '2px solid #267DFF' : '', background: viewState === 'branch' ? '#267DFF' : '#DBE9FF', padding: viewState === 'branch' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'branch' ? '#fff' : '#000', cursor: 'pointer' }}>
                        Branch Report
                    </div>
                    :''}
                    {admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ?
                    <div className={admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0  ? "col-sm-6 col-xs-6":'col-xs-12'} onClick={() => setViewState('buddy')} style={{ borderBottom: viewState === 'branch' ? '2px solid #267DFF' : '', background: viewState === 'buddy' ? '#267DFF' : '#DBE9FF', padding: viewState === 'buddy' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'buddy' ? '#fff' : '#000', cursor: 'pointer' }}>
                         Buddy Loan Report

                    </div>
                    :''}

                </div>
                <div className="full-height" style={{marginTop:window.innerWidth < 1800 ? '80px' : ''}}>
                    {viewState === 'branch' ?
                        <BranchReport  />
                        : ""}
                    {viewState === 'buddy' ?
                        <BuddyLoanReport  />
                        : ""}
                </div>
            </div>
        </>
    )
}
