import React, { Component } from 'react'
import GenerateReportUi from '../../../presentationals/Admin/DashboardAndReports/buddyLoanReport.component'
import { APIS } from '../../../../utils/api-factory'
import { _preFormatDate } from '../../../../utils/validation'
import Loader from '../../../presentationals/Loader/Loader.component'
import { downloadBuddyLoanReportComplete,downloadBuddyLoanReportApplied } from '../../Admin/AdminActionCreator.component'
class ReportLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Start: '',
            End: '',
            startDate: _preFormatDate(new Date()),
            endDate: _preFormatDate(new Date()),
            typeData: 'complete',
            url: '',
            loaderState: false

        }
    }
    componentDidMount() {

        this.setState({ url: APIS.BUDDY_LOAN_COMPLETE_DATA + this.state.startDate + '&dateTo=' + this.state.endDate })

    }
    removePopup() {
        setTimeout(function () {
            this.setState({ loaderState: false });
        }.bind(this), 6000)
    }

    datehandler(e, listType) {
        if (listType == 'startDate') {
            this.setState({ startDate: _preFormatDate(e), Start: e })
        }
        if (listType == 'endDate') {
            this.setState({ endDate: _preFormatDate(e), End: e })
        }
    }
    downloadFile() {
        if (this.state.typeData === 'complete') {
            this.setState({loaderState:true})
            downloadBuddyLoanReportComplete(this.state.startDate , this.state.endDate, callback => {
                if(callback !==''){
                    this.setState({loaderState:false})
                }
            })
        }
        if (this.state.typeData === 'applied') {
            this.setState({loaderState:true})
            downloadBuddyLoanReportApplied(this.state.startDate , this.state.endDate, callback => {
                if(callback !==''){
                    this.setState({loaderState:false})
                }
            })
        }
    }
    selectHandler(e) {
        this.setState({ typeData: e.target.value })
    }

    render() {
        return (
            <div>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <GenerateReportUi
                    url={this.state.url}
                    selectHandler={this.selectHandler.bind(this)}
                    typeData={this.state.typeData}
                    Start={this.state.Start}
                    End={this.state.End}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    downloadFile={this.downloadFile.bind(this)}
                    datehandler={this.datehandler.bind(this)}
                />
            </div>
        )
    }

}

export default ReportLogs;