import React from 'react'
import DatePicker from 'react-datepicker'
const Razor = ({downloadreport, startDate, endDate, dateChangeHandler, Start, End }) => (
    <div className='container '>
         <div className='row' style={{marginTop:'50px'}}>
            <div className='col-sm-4 col-xs-12'>
            </div>
            <div className='col-sm-4 col-xs-12'>
            <h3 className="text-center blue-text">Download RazorPay Data</h3>

                           </div>
            <div className='col-sm-4 col-xs-12'>
            </div>
        </div>
        <div className='row' style={{marginTop:'30px'}}>
            <div className='col-sm-4 col-xs-12'>
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label className="font-12px">Date From</label>
                <DatePicker
                    value={startDate}
                    selected={Start}
                    onChange={(e) => dateChangeHandler(e, 'start')}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                />
            </div>
            <div className='col-sm-4 col-xs-12'>
            </div>
        </div>
        <br/>
        <div className='row'>
            <div className='col-sm-4 col-xs-12'>
            </div>
            <div className='col-sm-4 col-xs-12'>

                <label className="font-12px">Date To</label>
                <DatePicker
                    value={endDate}
                    selected={End}
                    onChange={(e) => dateChangeHandler(e, 'end')}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                />
            </div>
            <div className='col-sm-4 col-xs-12'>
            </div>
        </div>
        <div className='row text-center'>
            <div className='col-sm-4 col-xs-12'>
            </div>
            <div className='col-sm-4 col-xs-12'>

                <button className='profile-save-btn' onClick={()=>downloadreport()}>Download</button>
            </div>
            <div className='col-sm-4 col-xs-12'>
            </div>
        </div>
    </div>
)
export default Razor