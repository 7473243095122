import React, { Component } from 'react'
import PromoDetails from '../../../presentationals/Admin/Promotions/PromotionalCodeEntry.component'
import { _preFormatDate, _formatDateInDash } from '../../../../utils/validation';
import { APIS } from '../../../../utils/api-factory';
import { saveOfferDataApi } from '../AdminActionCreator.component'

class PromotionalCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoStartState: '',
            promoEndState: '',
            promoDetail: {
                promoCodeState: '',
                promoAmountState: '',
                promoStartState: _preFormatDate(new Date()),
                promoEndState: _preFormatDate(new Date()),
            },
            promoList: '',
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }
    componentWillMount() {
        fetch(APIS.GET_ALL_PROMOTATION_OFFER)
            .then(res => res.json())
            .then(json => {
                this.setState({ promoList: json });
            })
    }
    render() {
        return (
            <div className="">
                <PromoDetails
                    promoHandler={this.promoHandler.bind(this)}
                    promotionalCode={this.promotionalCode.bind(this)}
                    promoStartState={this.state.promoStartState}
                    promoEndState={this.state.promoEndState}
                    promoDetail={this.state.promoDetail}
                    promoList={this.state.promoList}
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                />
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    promotionalCode(e, promoType) {
        let promoDetail = Object.assign({}, this.state.promoDetail);
        if (promoType == 'promoCode') {
            promoDetail.promoCodeState = e.target.value
        } else if (promoType == 'promoAmount') {
            promoDetail.promoAmountState = e.target.value
        } else if (promoType == 'promoStart') {
            this.setState({ promoStartState: e })
            promoDetail.promoStartState = _preFormatDate(e)
        } else if (promoType == 'promoEnd') {
            this.setState({ promoEndState: e })
            promoDetail.promoEndState = _preFormatDate(e)
        }
        this.setState({ promoDetail })
    }
    promoHandler() {
        saveOfferDataApi(this.state.promoDetail,
            callBack => {
                this.setState({ promoList: [...this.state.promoList, callBack] })
            })
    }
}
export default PromotionalCode;