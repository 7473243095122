


import React from "react";
import ReactDatePicker from "react-datepicker";
import Logo from '../../../images/rewampimages/Vector.png'
import { PLACEHOLDER, STRING } from "../../../utils/Constant";
const TransactionPopup = ({ editTransHandler, saveTransHandler, trnsDateState, transDetail, close }) => (
    <>
        <div className="background-blur details-new">

            <div className="popup-main small-popup container-fluid max-width-500px dbl-border" style={{ marginTop: '150px' }}>
                <div style={{ textAlign: 'right', cursor: 'pointer' }}>
                    <i className="fa fa-times" onClick={() => close()}></i>
                </div>
                <h4 style={{ color: '#000', marginTop: '-15px' }}><img src={Logo} style={{ width: '27px' }} />&nbsp;&nbsp;Transaction Details</h4>
                <div className="row">
                </div>
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px" style={{marginTop:'10px'}}>TRANSACTION ID</label>
                        <input type="text" className="form-control" placeholder="ENTER TRANSACTION ID" onChange={(e) => editTransHandler(e, 'trnsId')} />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px" style={{marginTop:'10px'}}>TRANSACTION DATE</label>
                        <ReactDatePicker
                            value={transDetail.trnsDateState}
                            selected={trnsDateState}
                            onChange={(e) => editTransHandler(e, 'trnsDate')}
                            className="date-picker form-control"
                            dateFormat="DD-MM-YYYY"
                            placeholderText="ENTER TRANSACTION DATE"
                        />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px" style={{marginTop:'10px'}}>TRANSACTION MODE</label>
                        <select style={{border:'none'}} className="form-control" id="paymentMode" onChange={(e) => editTransHandler(e, 'trnsMode')}>
                            <option value="">{PLACEHOLDER.SELECT_TRANSACTION_MODE}</option>
                            <option value={STRING.CHEQUE}>{STRING.CHEQUE}</option>
                            <option value={STRING.NET_BANKING}>{STRING.NET_BANKING}</option>
                            <option value='UPI'>UPI</option>

                        </select>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px" style={{marginTop:'10px'}}>TRANSACTION AMOUNT</label>
                        <input type="number" className="form-control" placeholder="ENTER TRANSACTION AMOUNT" onChange={(e) => editTransHandler(e, 'trnsAmount')} />
                    </div>
                </div>

                <div className="row text-center form-group">
                    <button className="btn btn-primary" style={{marginTop:'20px'}} id="transSubmitBtn" onClick={e => saveTransHandler()}>
                       Save
                    </button>
                </div>
            </div>
        </div>
    </>
)
export default TransactionPopup