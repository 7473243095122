import React from 'react'
import AddAmbassadorUi from '../../../presentationals/Admin/Tea Garden/addAmbassador.component';
import { addAmbassadorTeaGarden, addOrUpdateAddress, addOrUpDateBankDetails } from './actioncreator'
import { zipAndBranchDetail } from '../../Admin/AdminActionCreator.component'
// import { addOrUpDateBankDetails } from '../../TeaGarden/actionCreator'
import Loader from '../../../presentationals/Loader/Loader.component'
import SidePoup from '../../../presentationals/Popup/Popup.component'
class AddAmbassador extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPage: 'first',
            ambassadorData: {},
            addressData: {},
            userId: '',
            bankData: {},
            loaderState: false,
            popupStatus: "",
            popState: false,
            formStatus: 'Bank'
        }
    }
    inputHandler(e) {
        let ambassadorData = Object.assign({}, this.state.ambassadorData)
        ambassadorData[e.target.name] = e.target.value
        this.setState({ ambassadorData: ambassadorData })
    }
    closePopup() {
        this.setState({ popState: false })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popState: false });
        }.bind(this), 5000)
    }

    submit(type) {
        // console.log(this.state.ambassadorData)
        const { admin } = this.props

        if (type === 'firstPage') {
            // this.setState({viewPage:'addressPage'})
            this.setState({ loaderState: true })
            addAmbassadorTeaGarden(this.state.ambassadorData, admin, callback => {
                // console.log(callback.userId, callback)
                if (callback.message === 'Success' || callback.message === 'success') {
                    this.setState({ userId: callback.userId, viewPage: 'addressPage', loaderState: false })
                } else {
                    this.setState({ loaderState: false, popupStatus: callback.message, popState: true }, () => this.removePopup())
                }
            })

        }
        if (type === 'addressPage') {
            const { admin } = this.props
            // console.log(this.state.addressData)
            this.setState({ loaderState: true })
            addOrUpdateAddress(this.state.addressData, this.state.userId, admin, this.state.formStatus, callBack => {
                // console.log(callBack)
                if (callBack === 'success') {
                    this.setState({ loaderState: false, viewPage: 'bankPage' })
                } else {
                    this.setState({ loaderState: false, popupStatus: callBack, popState: true }, () => this.removePopup())
                }
            })
        }
        if (type === 'bankPage') {
            const { admin } = this.props
            // console.log(this.state.bankData)
            this.setState({ loaderState: true })
            addOrUpDateBankDetails(this.state.bankData, this.state.userId, admin, callBack => {
                // console.log(callBack)
                if (callBack === 'success') {
                    this.setState({ loaderState: false, viewPage: 'successPage' })
                } else {
                    this.setState({ loaderState: false, popupStatus: 'Please Try Agian Later !', popState: true }, () => this.removePopup())

                }
            })
        }
    }
    accountHandler(e) {
        let bankData = Object.assign({}, this.state.bankData)
        bankData[e.target.name] = e.target.value
        if (e.target.name === 'ifscCode' && (e.target.value).length === 11) {
            this.setState({loaderState:true})
            zipAndBranchDetail(e.target.value, 'branch', res => {
                // console.log(res)
                if (res.status === 'success') {
                    bankData['bankBranch'] = res.branchCode
                    bankData['bankName']=res.branchName
                    this.setState({ bankData: bankData,loaderState:false })
                }
            })
        } else {
            this.setState({ bankData: bankData })

        }
    }
    addressHandler(e) {
        let addressData = Object.assign({}, this.state.addressData)
        addressData[e.target.name] = e.target.value

        if (e.target.name === 'pinCode' && (e.target.value).length === 6) {
            this.setState({loaderState:true})
            zipAndBranchDetail(e.target.value, 'zip', res => {
                // console.log(res)
                if (res.status === 'success') {
                    addressData['city'] = res.destrict
                    addressData['state'] = res.state
                    this.setState({ addressData: addressData,loaderState:false })
                }
            })
        } else {
            this.setState({ addressData: addressData })

        }
        // console.log(this.state.addressData)
    }
    render() {
        return (
            <div>
                <AddAmbassadorUi
                    bankData={this.state.bankData}
                    accountHandler={this.accountHandler.bind(this)}
                    addressData={this.state.addressData}
                    addressHandler={this.addressHandler.bind(this)}
                    viewPage={this.state.viewPage}
                    inputHandler={this.inputHandler.bind(this)}
                    submit={this.submit.bind(this)}
                />
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popState ?
                    <SidePoup
                        popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)}
                    />
                    : ""}
            </div>
        )
    }
}

export default AddAmbassador