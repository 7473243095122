import React, { Component } from 'react';
import RecoveryMsgUi from './RecoveryMsg.container';
import RecoveryMailUi from './RecoveryMail.container';
import RecoveryNotificationUi from './RecoveryNotification.container';
import RecoveryCommentUi from './RecoveryComment.container'
import RecoveryLogsUi from './RecoveryComLogs.container'
import WhatsappUi from './Whatsapp.container'

class RecoveryCommunication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageToShow: 'mail'
        }
    }

    componentWillMount() {

    }
  

    render() {
        const { dataForCommunication, admin, collectionType, allDetail } = this.props
        return (
            <div className="">
                <div className="col-xs-12 form-group">
                    <h3 className="text-center blue-text">
                        <span className="fa fa-chevron-left pull-left pointer" onClick={(e) => this.detailBackHandler()} />
                        {dataForCommunication.userName}  ({dataForCommunication.applicationId})
                    </h3>
                </div>
                <div className="btn-group">
                    <button className={this.state.pageToShow == "msg" ? "active" : null} onClick={(e) => this.sendHandler('msg')}>Message</button>
                    <button className={this.state.pageToShow == "mail" ? "active" : null} onClick={(e) => this.sendHandler('mail')}>Mail</button>
                    <button className={this.state.pageToShow == "Whatsapp" ? "active" : null} onClick={(e) => this.sendHandler('Whatsapp')}>Whatsapp</button>
                    <button className={this.state.pageToShow == "notification" ? "active" : null} onClick={(e) => this.sendHandler('notification')}>Notification</button>
                    <button className={this.state.pageToShow == "comment" ? "active" : null} onClick={(e) => this.sendHandler('comment')}>Comment</button>
                    <button className={this.state.pageToShow == "logs" ? "active" : null} onClick={(e) => this.sendHandler('logs')}>Logs</button>
                </div>
                {this.state.pageToShow == 'msg' ?
                    <RecoveryMsgUi
                        dataForCommunication={dataForCommunication}
                        admin={admin}
                        collectionType={collectionType}
                    />
                    : this.state.pageToShow == 'mail' ?
                        <RecoveryMailUi
                            dataForCommunication={dataForCommunication}
                            admin={admin}
                            collectionType={collectionType}
                        />
                        : this.state.pageToShow == 'Whatsapp' ?
                            <WhatsappUi
                                dataForCommunication={dataForCommunication}
                                admin={admin}
                                collectionType={collectionType}
                            />
                            : this.state.pageToShow == 'notification' ?
                                <RecoveryNotificationUi
                                    dataForCommunication={dataForCommunication}
                                    admin={admin}
                                />
                                : this.state.pageToShow == 'comment' ?
                                    <RecoveryCommentUi
                                        dataForCommunication={dataForCommunication}
                                        admin={admin}
                                    />
                                    : this.state.pageToShow == 'logs' ?
                                        <RecoveryLogsUi
                                            dataForCommunication={dataForCommunication}
                                        />
                                        : ""}
            </div>
        )
    }

    sendHandler(sendType) {
        this.setState({ pageToShow: sendType })
    }

    detailBackHandler() {
        this.props.recoveryBackHandler()
    }

}

export default RecoveryCommunication;