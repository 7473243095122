import { APIS } from '../../../utils/api-factory'
import localStorageUtil from '../../../utils/localStorageUtil.js'
import { FORM_STATUS, EMPLOYER_TYPE } from '../../../utils/Constant'
import moment from 'moment'
import { POST_HEADER } from '../../../utils/apiHeaders'

export const fetchUserBasicDetail = (userDetail, userData, callBack) => {
  var data = {
    userId: localStorageUtil.getFromLocalStorage('userId'),
    loanId: userData.loanId,
    firstName: userDetail.firstNameState,
    middleName: userDetail.MiddleNameState,
    lastName: userDetail.lastNameState,
    dateOfBirth: userDetail.BasicDOB,
    emailId: userDetail.EmailIDState,
    gender: userDetail.GenderState,
    infoAboutUs: userDetail.HowToKnowState,
    alternativeNo: userDetail.alternateMobileState,
    referenceCode: userDetail.referralCodeState,
    formStatus: userDetail.formStatus,
    ambassadorId: userData.ambassadorId,
    ambassadorName: userData.userName,
    empId: 'user'
  }
  fetch(APIS.USER_BASIC_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const giftCardEmiDetails = (userDetail, callBack) => {
  fetch(APIS.GET_GIFTCARD_EMI + userDetail)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => {
      callBack('no data')
    })
}
export const fetchAddressDetail = (userAddressDetail, userData, callBack) => {
  var data = {
    userId: localStorageUtil.getFromLocalStorage('userId'),
    loanId: userData.loanId,
    currentAddress: userAddressDetail.CurrentAddressState,
    currentPincode: userAddressDetail.CurrentPinCodeState,
    currentState: userAddressDetail.CurrentStateState,
    currentCity: userAddressDetail.CurrentCityState,
    currentResidenceType: userAddressDetail.CurrentResidenceTypeValue,
    currentResidenceValue: userAddressDetail.CurrentDurationState,
    empId: 'user',
    permanentAddress: userAddressDetail.PermanentAddressState,
    permanentPincode: userAddressDetail.PermanentPinCodeState,
    permanentState: userAddressDetail.PermanentStateState,
    permanentCity: userAddressDetail.PermanentCityState,
    permanentResidenceType: userAddressDetail.PermanentResidenceTypeValue,
    permanentResidenceValue: userAddressDetail.PermanentDurationState,
    formStatus: userAddressDetail.formStatus
  }
  fetch(APIS.USER_ADDRESS_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchOccupationAndEmployment = (
  employeeDetail,
  formStatus,
  userData,
  callBack
) => {

  var data = {
    userId: localStorageUtil.getFromLocalStorage('userId'),
    loanId: userData.loanId,
    empId: 'user',
    highestQualification: employeeDetail.HighQualificationState,
    fullPartTime: employeeDetail.fullPartTimeState,
    optionalEmail: employeeDetail.optionalEmailState,
    employerType: employeeDetail.EmployeeTypeValue,
    organizationName: employeeDetail.OrganizationNameState,
    designation: employeeDetail.designationState,
    netTakeHomeSalary: employeeDetail.HomeSalaryState,
    workExperienceCurrentOrganization:
      employeeDetail.WorkExperienceCurrentState,
    workExperiencePreviousOrganization:
      employeeDetail.WorkExperiencePreviousState,
    isThisFirstJob: employeeDetail.IsFirstJobState,
    selfEmploymentType: employeeDetail.EmployeeTypeState,
    officeAddress: employeeDetail.officepincode,

    // officeAddress: 'Address=' + employeeDetail.officeAddress + ',' + employeeDetail.officeCity + 'State=' + employeeDetail.officeState + ',' + 'PinCode=' + employeeDetail.officepincode,
    totalExperience: employeeDetail.WorkExperienceTotalState,
    selfEmployeeType: employeeDetail.EmployeeTypeState,
    monthlyIncome: employeeDetail.MonthlyIncomeState,
    salaryDay: employeeDetail.salaryDayState,
    businessStartDate: employeeDetail.businessStartDateState,
    businessIndustryType: employeeDetail.IndustrialTypeState,
    formStatus: formStatus,
    noOfEmployees: employeeDetail.noOfEmployeeState
  }
  fetch(APIS.USER_EMPLOYMENT_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchLoanDetail = (loanDetail, nexoPay, uniqueId, salaryDay, callBack) => {
  var data = {
    userId: localStorageUtil.getFromLocalStorage('userId'),
    loanAmount: Math.round(loanDetail.loanAmountState),
    loanFrom: nexoPay,
    uniqueId: uniqueId,
    paybackDuration: loanDetail.loanDaysState,
    loanEMI: Number(loanDetail.selectEMI),
    loanReason: loanDetail.loanReasonState,
    couponCode: loanDetail.couponCodeState.toUpperCase(),
    formStatus: loanDetail.formStatus,
    salariedDate: salaryDay,
    isAadharMobileLinked: Number(loanDetail.adharLinkedState),
    currentObligation: loanDetail.obligationTextState,
    panNumber: loanDetail.PanNoState,
    aadharNumber: loanDetail.aadharNoState,
    platFormFrom: 'Website'
  }
  fetch(APIS.USER_LOAN_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchBankDetail = (bankDetail, callBack) => {
  var data = {
    userId: localStorageUtil.getFromLocalStorage('userId'),
    bankName: bankDetail.BankNameState,
    nameAsBankAccount: bankDetail.BankUserNameState,
    accountNumber: bankDetail.AccountNoState,
    panNo: bankDetail.PanNoState,
    aadhaarNo: bankDetail.aadharNoState,
    ifscCode: bankDetail.IFSCCodeState,
    bankBranch: bankDetail.BankBranchState,
    ifSalariedAccount: bankDetail.IfSalriedAccountState,
    formStatus: bankDetail.formStatus
  }

  fetch(APIS.FORM_BANK_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const documentNext = () => {
  fetch(
    APIS.FORM_DOCUMENT_NEXT + localStorageUtil.getFromLocalStorage('userId')
  )
    .then(res => res.text())
    .then(json => { })
}
export const aadharExistCheck = (aadaharNumber, callBack) => {

  fetch(
    APIS.AADHAR_EXIST_CHECK +
    localStorageUtil.getFromLocalStorage('userId') +
    '&aadharNumber=' +
    aadaharNumber
  )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const documentPageNext = (status, currnetAddSameAdharAdd) => {
  fetch(
    APIS.FORM_DOCUMENT_PAGE_NEXT +
    localStorageUtil.getFromLocalStorage('userId') +
    '&status=' +
    status +
    '&aadharCurrentAddSame=' +
    currnetAddSameAdharAdd
  )
    .then(res => res.text())
    .then(json => { })
}

export const updateLoanDetail = (loanDetail, userData, callBack) => {
  var data = {
    userId: localStorageUtil.getFromLocalStorage('userId'),
    loanId: userData.loanId,
    empId: 'user',
    loanAmount: loanDetail.loanAmountState,
    paybackDuration: loanDetail.loanDaysState,
    loanReason: loanDetail.loanReasonState,
    panNumber: loanDetail.PanNoState,
    aadharNumber: loanDetail.aadharNoState,
    formStatus: loanDetail.formStatus
  }

  fetch(APIS.LOAN_UPDATE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const uploadImage = (imageFile, type, fileName, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < imageFile.length; i++) {
      imageFormData.append('uploadfile', imageFile[i])
    }
    imageFormData.append(
      'userId',
      localStorageUtil.getFromLocalStorage('userId')
    )
    imageFormData.append('type', 'img')
    imageFormData.append('fileName', type)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_FILE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }

    xhr.send(imageFormData)
  })
}

export const uploadPdf = (pdfFile, type, fileName, password, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < pdfFile.length; i++) {
      imageFormData.append('uploadfile', pdfFile[i])
    }
    imageFormData.append(
      'userId',
      localStorageUtil.getFromLocalStorage('userId')
    )
    imageFormData.append('type', 'pdf')
    imageFormData.append('fileName', type)
    imageFormData.append('password', password)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_FILE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }

    xhr.send(imageFormData)
  })
}

export const passwordCheck = (pdfFile, callBack) => {
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < pdfFile.length; i++) {
      imageFormData.append('uploadfile', pdfFile[i])
    }
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.DOC_PASSWORD_PROTECT, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }

    xhr.send(imageFormData)
  })
}

//110044&type=zip USER_FEEDBACK
export const zipAndBranchDetail = (value, type, res) => {
  fetch(APIS.ZIP_DETAIL + value + '&type=' + type)
    .then(res => res.json())
    .then(json => {
      res(json)
    })
}

export const fetchFeedbackDetails = (FeedbackDetail, callBack) => {
  var data = {
    userId: FeedbackDetail.userId,
    happyWithService: FeedbackDetail.serviceState,
    friendlinessRating: FeedbackDetail.rating,
    knowledgeRating: FeedbackDetail.rating2,
    quicknessRating: FeedbackDetail.rating3,
    useAppFuture: FeedbackDetail.futureUse,
    suggestion: FeedbackDetail.improveService,
    docExperience: FeedbackDetail.docExperience
  }

  fetch(APIS.USER_FEEDBACK, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const updateUsingCSVApi = (pdfFile, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < pdfFile.length; i++) {
      imageFormData.append('uploadfile', pdfFile[i])
    }
    imageFormData.append('type', 'pdf')
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPDATE_USING_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }

    xhr.send(imageFormData)
  })
}

export const saveLocationFromWebApi = (userId, userLocation) => {
  var data = {
    userId: userId,
    deviceLocation:
      '{"date":"' +
      moment(new Date()).format('DD/MM/YYYY HH:mm:ss') +
      '","latitude":"' +
      userLocation +
      '","longitude":"' +
      userLocation +
      '"}'
  }
  // console.log(APIS.SAVE_LOCATION_FROM_WEB)
  fetch(APIS.SAVE_LOCATION_FROM_WEB, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      // console.log(text)
    })
}
export const saveLocationFromWebApiNew = (userId, latitude,longitude) => {
  var data = {
    userId: userId,
    deviceLocation:
      '{"date":"' +
      moment(new Date()).format('DD/MM/YYYY HH:mm:ss') +
      '","latitude":"' +
      latitude +
      '","longitude":"' +
      longitude +
      '"}'
  }
  // console.log(APIS.SAVE_LOCATION_FROM_WEB)
  fetch(APIS.SAVE_LOCATION_FROM_WEB, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      // console.log(text)
    })
}

export const calculateEmiApi = (amount, months, salaryDay, callBack) => {
  fetch(APIS.EMI_CALCULATOR + Math.round(amount) + '/' + months + '/' + salaryDay)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const calculateEmiApiGiftCard = (amount, months, salaryDay, callBack) => {
  fetch(APIS.EMI_CALCULATOR_GIFT_CARD + Math.round(amount) + '/' + months + '/' + salaryDay)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const checkLoanEligibility = (userId, callBack) => {
  fetch(APIS.CHECK_LOAN_ELIGIBILITY + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(function (err) {
    })
}
/////Apply giftCard////////////
export const giftcardApplyApi = (data, callBack) => {
  var data = {
    userId: localStorageUtil.getFromLocalStorage('userId'),
    loanEMI: '3',
    loanAmount: data.loanAmount,
    giftCardType: data.type,
    platFormFrom: 'website',
    formStatus: data.formStatus
  }
  fetch(APIS.APPLY_GIFT_CARD_API, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const giftCardDetails = callBack => {

  fetch(
    APIS.GIFTCARD_DETAIL_BY_USER_ID +
    localStorageUtil.getFromLocalStorage('userId')
  )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const saveFollowUpStatusForGiftCard = (
  giftCardId,
  status,
  admin,
  callBack
) => {

  fetch(
    APIS.CHANGE_FOLLOW_UP_GIFT_CARD +
    giftCardId +
    '&status=' +
    status +
    '&empId=' +
    admin
  )
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}


export const nexopayUserRegistration = (data, dataNexoPay, callBack) => {
  var data = {
    uniqueId: data,
    emailId: dataNexoPay.email,
    accessToken: "",
    refreshToken: "",
    name: dataNexoPay.parent_name,
    expiresIn: 0,
    mobileNumber: dataNexoPay.phone_number
  }
  fetch(APIS.NEXOPAY_USER_REGISTRATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getNexoPayData = (uniqueId, callBack) => {
  fetch(APIS.GET_NEXOPAY_DATA + uniqueId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => {
      callBack('no data')
    })
}
export const nexopayUserRegistrationnew = (data, email, parent_name, phone_number, callBack) => {
  var data = {
    uniqueId: data,
    emailId: email,
    accessToken: "",
    refreshToken: "",
    name: parent_name,
    expiresIn: 0,
    mobileNumber: phone_number
  }
  fetch(APIS.NEXOPAY_USER_REGISTRATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const userReferenceApi  = (data,userId, callBack) => {
  var data = data
  fetch(APIS.USER_REFERENCE_SAVE_API + userId, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const saveUrlBankDetails = (bankDetail,userId, callBack) => {
  var data = {
    userId: userId,
    bankName: bankDetail.bankName,
    nameAsBankAccount: bankDetail.nameAsBankAccount,
    accountNumber: bankDetail.accountNumber,
    ifscCode: bankDetail.ifscCode,
    bankBranch: bankDetail.bankBranch,

  }

  fetch(APIS.SAVE_BANK_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getuserReferenceApi = (userId, callBack) => {
  var data = {
    // emailID: emailID
  }
  fetch(APIS.GET_USER_REFERENCE + userId, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}