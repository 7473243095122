import React, { useState, useEffect } from 'react'
import SmsTable from './TableSms'
import FraudTable from './FraudTable'
import Legalsms from './legalSms'
import EmismsTable from './emisms'
import OverdueSms from './overduesms'
import RawMsg from '../CustomerPages/RawMsg.container'

import FinBit from './FinBitAnalysis.container'
import Report from './crifReport'
const Credit = ({ allDetail,finbitTab, userId, similarData, crifData, smsData, finbitData, tableMaxPagination, tableMinPagination, tabToShow, secondTab, setSecondTab, matchByCategory, fraudData, similarFilterData, filterType, phocketTablePagination }) => {
    const [smsTab, setSmsTab] = useState('')
    const [crifTab, setcrifTab] = useState('')

    const [allDetailUser, setallDetail] = useState({ 'userId': userId })
    const smsTabHandler = (type) => {
        setSmsTab(type)

    }
    const crifTabHandler = (type) => {
        setcrifTab(type)
    }
  


    return (
        <div className="container-fluid">
            <ul className="credit-head-tab" style={{ display: 'flex', marginBottom: '0px' }}>
                <li className={secondTab === 'sms' ? 'cred-sub-tab-active' : "cred-sub-tab"} onClick={() => setSecondTab('sms')}>
                    <div className="row">
                        <div className="col-sm-8 col-xs-8">
                            <h5 style={{ fontWeight: '600' }}>SMS Analysis</h5>
                            <div className="box-verify">
                                {smsData && smsData.smsAnalysisResult ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px' }}><i className="fa fa-check-circle"></i>&nbsp;Verified</span> : <span style={{ color: '#fff', background: 'red', borderRadius: '4px', padding: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Unverified</span>}

                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-4">
                            <div className="cred-circle" >
                                <i class="fa fa-comments" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </li>
                <li className={secondTab === 'crif' ? 'cred-sub-tab-active' : "cred-sub-tab"} onClick={() => setSecondTab('crif')}>
                    <div className="row">
                        <div className="col-sm-8 col-xs-8">
                            <h5 style={{ fontWeight: '600' }}>CRIF Verification</h5>
                            <div className="box-verify">
                                {crifData && crifData.finBoxDecision&&crifData.finBoxDecision.finalDecision==='Approved' ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px' }}><i className="fa fa-check-circle"></i>&nbsp;Verified</span> : <span style={{ color: '#fff', background: 'red', borderRadius: '4px', padding: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Unverified</span>}

                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-4">
                            <div className="cred-circle" >
                                <i class="fa fa-tachometer" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </li>
                <li className={secondTab === 'bank' ? 'cred-sub-tab-active' : "cred-sub-tab"} onClick={() => setSecondTab('bank')}>
                    <div className="row">
                        <div className="col-sm-8 col-xs-8">
                            <h5 style={{ fontWeight: '600' }}>Bank Statement </h5>
                            <div className="box-verify">
                                {finbitData && finbitData.finBitAnalysisResult ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px' }}><i className="fa fa-check-circle"></i>&nbsp;Verified</span> : <span style={{ color: '#fff', background: 'red', borderRadius: '4px', padding: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Unverified</span>}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-4">
                            <div className="cred-circle" >
                                <i class="fa fa-file-text" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div></li>
                <li className={secondTab === 'internal' ? 'cred-sub-tab-active' : "cred-sub-tab"} onClick={() => setSecondTab('internal')}>
                    <div className="row">
                        <div className="col-sm-8 col-xs-8">
                            <h5 style={{ fontWeight: '600' }}>Internal Dedupe</h5>
                            <div className="box-verify">
                                {similarData && similarData.matchBy && (similarData.matchBy.includes('name') || similarData.matchBy.includes('address') || similarData.matchBy.includes('accountNo')) ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px' }}><i className="fa fa-check-circle"></i>&nbsp;Verified</span> : <span style={{ color: '#fff', background: 'red', borderRadius: '4px', padding: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Unverified</span>}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-4">
                            <div className="cred-circle" >
                                <i class="fa fa-clone" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div></li>
                <li className={secondTab === 'view' ? 'cred-sub-tab-active' : "cred-sub-tab"} onClick={() => tabToShow('view')}>
                    <div className="row">
                        <div className="col-sm-8 col-xs-8">
                            <h5 style={{ fontWeight: '600' }}>Summary </h5>
                        </div>
                        <div className="col-sm-4 col-xs-4">
                            <div className="cred-circle" >
                                <i class="fa fa-book" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div></li>
            </ul>
            {secondTab === 'sms' ?
                <ul className="credit-head-tab" style={{ display: 'flex', marginBottom: '0px' }}>
                    <li onClick={() => smsTabHandler('overdue')} className={smsTab === 'overdue' ? 'col-sm-2 col-xs-12 credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'}>
                        <div className='text-center' style={{ fontWeight: '700' }} >
                            Overdue SMS
                            <br />
                            {smsData && smsData.overDue ? <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#4CC78C' }}>Found &nbsp;<i className='fa fa-check-circle'></i></div> :
                                <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#FF5C5D' }}>Not Found &nbsp;<i className='fa fa-times-circle'></i></div>}

                        </div>
                    </li>
                    <li onClick={() => smsTabHandler('legal')} className={smsTab === 'legal' ? 'col-sm-2 col-xs-12 credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'}>
                        <div className='text-center' style={{ fontWeight: '700', fontSize: '14px' }} >
                            Legal SMS
                            <br />
                            {smsData && smsData.legalMsg ? <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#4CC78C' }}>Found &nbsp;<i className='fa fa-check-circle'></i></div> :
                                <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#FF5C5D' }}>Not Found &nbsp;<i className='fa fa-times-circle'></i></div>}
                        </div>
                    </li>
                    <li onClick={() => smsTabHandler('emi')} className={smsTab === 'emi' ? 'col-sm-2 col-xs-12 credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'}>
                        <div className='text-center' style={{ fontWeight: '700', fontSize: '14px' }} >
                            EMI Amount
                            <br />
                            {smsData && smsData.totalEmiAmt ? <span style={{ fontSize: '12px', color: '#2B78FF' }}>INR {smsData ? Math.round(smsData.totalEmiAmt) : ''}</span> : <div> <br /></div>}
                        </div>
                    </li>
                    <li onClick={() => smsTabHandler('salary')} className={smsTab === 'salary' ? 'col-sm-2 col-xs-12 credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'}>
                        <div className='text-center' style={{ fontWeight: '700', fontSize: '14px' }} >
                            Salary
                            <br />
                            {smsData && smsData.salary ? <span style={{ fontSize: '12px', color: '#2B78FF' }}>INR {smsData ? Math.round(smsData.salary) : ''}</span> : <div> <br /></div>}

                        </div>
                    </li>
                    {/* <div onClick={() => smsTabHandler('organisation')} className={smsTab === 'organisation' ? 'col-sm-2 col-xs-12 credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'}>
                        <div className=''>
                            Organisation Name
                        </div>
                    </div> */}
                    <li className={smsTab === 'viewall' ? 'col-sm-2 col-xs-12 credit-sub-tab-active' : 'col-sm-2 col-xs-12 credit-sub-tab'} onClick={() => smsTabHandler('viewall')} >
                        <div className='text-center' style={{ fontWeight: '700' }}>
                            View All
                            <br />
                            <br />
                        </div>
                    </li>

                </ul>
                : ""}


            {secondTab === 'internal' ?
                <ul className="credit-head-tab" style={{ display: 'flex', marginBottom: '0px' }}>
                    {matchByCategory ? matchByCategory.includes('name') ?

                        <li className={filterType === 'name' ? 'credit-sub-tab-active' : 'credit-sub-tab'} onClick={() => similarFilterData('name')}>
                            <div className=''>
                                KYC Data Match
                            </div>
                        </li>
                        : "" : ""}
                    {matchByCategory ? matchByCategory.includes('address') ?
                        <li className={filterType === 'address' ? 'credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'} onClick={() => similarFilterData('address')}>
                            <div className=''>
                                Address Match</div>
                        </li>
                        : "" : ""}
                    {matchByCategory ? matchByCategory.includes('mobileNo') ?
                        <li className={filterType === 'mobileNo' ? 'credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'} onClick={() => similarFilterData('mobileNo')}>
                            <div className=''>
                                Mobile Match
                            </div>
                        </li>
                        : "" : ""}
                    {matchByCategory ? matchByCategory.includes('accountNo') ?
                        <li className={filterType === 'accountNo' ? 'credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'} onClick={() => similarFilterData('accountNo')}>
                            <div className=''>
                                Account Number
                            </div>
                        </li>
                        : "" : ""}
                    <li className={filterType === '' ? 'credit-sub-tab-active' : 'credit-sub-tab col-sm-2 col-xs-12'} onClick={() => similarFilterData('')}>
                        <div className=''>
                            View All
                        </div>
                    </li>

                </ul>
                : ""}
            {secondTab === 'sms' && smsTab === 'salary' ?
                <SmsTable
                    smsData={smsData}
                    tableMaxPagination={tableMaxPagination}
                    tableMinPagination={tableMinPagination}
                    phocketTablePagination={phocketTablePagination}


                />
                : ""}
            {secondTab === 'sms' && smsTab === 'legal' ?
                <Legalsms
                    smsData={smsData}
                    tableMaxPagination={tableMaxPagination}
                    tableMinPagination={tableMinPagination}
                    phocketTablePagination={phocketTablePagination}
                />
                : ""}
            {secondTab === 'sms' && smsTab === 'overdue' ?
                <OverdueSms
                    smsData={smsData}
                    tableMaxPagination={tableMaxPagination}
                    tableMinPagination={tableMinPagination}
                    phocketTablePagination={phocketTablePagination}
                />
                : ""}

            {secondTab === 'sms' && smsTab === 'emi' ?
                <EmismsTable
                    smsData={smsData}
                    tableMaxPagination={tableMaxPagination}
                    tableMinPagination={tableMinPagination}
                    phocketTablePagination={phocketTablePagination}
                />
                : ""}
            {secondTab === 'sms' && smsTab === 'viewall' ?
                <RawMsg allDetail={allDetailUser} />
                : ""}
            {secondTab === 'internal' ?
                <FraudTable
                    phocketTablePagination={phocketTablePagination}
                    filterType={filterType}
                    fraudData={fraudData}
                    tableMaxPagination={tableMaxPagination}
                    tableMinPagination={tableMinPagination}
                />
                : ""}
            {secondTab === 'bank' ?
                <FinBit 
                userId={userId}
                    finbitData={finbitData}
                    allDetail={allDetail}

                />
                : ""}
            {secondTab === 'crif' ?
                <Report userId={userId}
                    crifTabHandler={crifTabHandler}
                    crifTab={crifTab}
                    crifData={crifData}
                    allDetail={allDetail}
                />
                : ""}
        </div >
    )
}
export default Credit