import React, { Component } from 'react'
import AddMerchantUi from '../../../presentationals/Admin/AdminCoupons/AddMerchant.component'
import { saveMerchant, deleteMerchant } from './AddMerchentActionCreator.Component'
import Popup from '../../../presentationals/Popup/Popup.component';
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'
import { AddMerchantValidation } from './Validation'

class MerchantAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
    confirmationPopup: false,
            confirmationText: '',
            idForDelete: '',
            popupState: false,
            popupStatus: '',
            MerchantValidationMsg: '',
            merchantData: {
                merchantName: '',
                contactPersonName: '',
                merchantMobile: '',
                merchantEmail: '',
                companyWebsite: '',
                companyAddress: '',
                SelectMerchentStatus: '',
                merchantId: '',
                password: '',
                discountPriority: ''
            }
        }
    }

    render() {
        const { listingOfCategory } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AddMerchantUi
                    merchantData={this.state.merchantData}
                    getDataHandler={this.getDataHandler.bind(this)}
                    postDataHandler={this.postDataHandler.bind(this)}
                    listingOfCategory={listingOfCategory}
                    ulMerchantData={this.ulMerchantData.bind(this)}
                    MerchantValidationMsg={this.state.MerchantValidationMsg}
                    deleteMerchant={this.deleteMerchant.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            deleteMerchant(this.state.idForDelete, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        confirmationPopup: false,
                        merchantData: {
                            merchantName: '',
                            contactPersonName: '',
                            merchantMobile: '',
                            merchantEmail: '',
                            companyWebsite: '',
                            companyAddress: '',
                            SelectMerchentStatus: '',
                            merchantId: '',
                        }
                    })
                    this.props.getAllData()
                }
            })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    deleteMerchant(data) {
        this.setState({
            idForDelete: data.merchantid,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete ' + data.merchantname
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    ulMerchantData(e, data) {
        let merchantData = Object.assign({}, this.state.merchantData);
        merchantData.merchantName = data.merchantname;
        merchantData.merchantMobile = data.merchanthphone;
        merchantData.SelectMerchentStatus = data.status;
        merchantData.merchantId = data.merchantid;
        merchantData.contactPersonName = data.contactperson
        merchantData.merchantEmail = data.contactpersonemail
        merchantData.companyWebsite = data.companywebsite
        merchantData.companyAddress = data.companyregisteredaddress
        merchantData.password = data.password
        merchantData.discountPriority = data.priority
        this.setState({ merchantData })
    }

    getDataHandler(e, type) {
        let merchantData = Object.assign({}, this.state.merchantData);
        if (type == "merchantName") {
            merchantData.merchantName = e.target.value;
        } else if (type == "contactPersonName") {
            merchantData.contactPersonName = e.target.value;
        } else if (type == "merchantMobile") {
            merchantData.merchantMobile = e.target.value;
        } else if (type == "merchantEmail") {
            merchantData.merchantEmail = e.target.value;
        } else if (type == "companyWebsite") {
            merchantData.companyWebsite = e.target.value;
        } else if (type == "companyAddress") {
            merchantData.companyAddress = e.target.value;
        } else if (type == "SelectMerchentStatus") {
            merchantData.SelectMerchentStatus = e.target.value == 'Active' ? true : false;
        } else if (type == "discountPriority") {
            merchantData.discountPriority = e.target.value
        }
        this.setState({ merchantData, MerchantValidationMsg: '' })
    }

    postDataHandler() {
        const { admin } = this.props
        let MerchantValidation = AddMerchantValidation(
            this.state.merchantData
        )
        if (MerchantValidation.status) {
            this.setState({
                MerchantValidationMsg: MerchantValidation,
            })
            saveMerchant(this.state.merchantData, admin, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Merchant saved Successfully',
                        merchantData: {
                            merchantName: '',
                            contactPersonName: '',
                            merchantMobile: '',
                            merchantEmail: '',
                            companyWebsite: '',
                            companyAddress: '',
                            SelectMerchentStatus: '',
                            merchantId: '',
                        }
                    })
                    this.removePopup();
                    this.props.getAllData()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup();
                }
            })
        } else {
            this.setState({
                MerchantValidationMsg: MerchantValidation,
            })
        }
    }
}

export default MerchantAdd;