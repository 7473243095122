import React, { Component } from 'react';
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../utils/commonUtility'


const AddUrl = ({ getTextFieldValue, companyList, saveCampaignUrl, campaigndata,
   getPageValue, pageIndexToShow, getFilterValue, FilteredData, deleteUserHandler, }) => (
   <div className="container-fluid full-height">
      <div className='row'>
         <div className='col-sm-3 col-xs-12'></div>
         <div className='col-sm-6 col-xs-12'>
            <div className=" center-block">
               <div className="well-header">
                  <h3 className="text-center text-success"> Add Campaign Url  </h3>


               </div>
               <div className="row">
                  <div>               <input type="text" placeholder="Add Campaign Url" name="name" className="form-control" onChange={e => getTextFieldValue(e, "campaignUrl")} />
                  </div>
                  <select style={{ marginTop: '20px' }} className="form-control" onChange={e => getTextFieldValue(e, "CompanyName")} >
                     {companyList != null && companyList != '' ? companyList.map(data => {
                        return (
                           <option value={data.id} key={data.id}>{data.email}</option>
                        )
                     }) : ""}
                  </select>
                  <div className="col-xs-12 text-center">
                     <button className="profile-save-btn" onClick={e => saveCampaignUrl(e)}>Save</button>
                  </div>
               </div>
            </div>
            <div className=" center-block">
               {campaigndata != null && campaigndata != "" && campaigndata.length > 0 ?
                  <table className='text-center blueTable '>

                     <thead>
                        <tr>
                           <td>Url</td>
                           <td>Delete</td>

                        </tr>
                     </thead>

                     <tbody>
                        {campaigndata && campaigndata.map((data, i) => {
                           return (
                              <tr>
                                 <td>{data.campaignUrl}</td>
                                 <td> <button onClick={e => deleteUserHandler(data.id)}><span className="fa fa-trash" /></button></td>

                              </tr>
                           )
                        })}
                     </tbody>
                  </table>

                  : ""}
            </div>

            <div className='col-sm-3 col-xs-12'></div>
         </div>
       
      </div>
   </div>

)
export default AddUrl;