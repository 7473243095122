import React from 'react'
import EditUi from './addNbfc.container'
import DetailsUi from './DetailsnbfcTabs.container'
class NbfcDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewState: 'Details'
        }
    }
    buttonActionHandler(data) {
        this.setState({ viewState: data })
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className='container-fluid user-detail-tab'>
                
                <div className="row bt-color" style={{marginTop:'20px'}}>
                        <div className="edit-profile-menu col-xs-12">
                            <div className="btn-group ">
                                <button className={this.state.viewState == 'Details' ? 'active' : ''} onClick={e => this.buttonActionHandler("Details")}>Details</button>
                                <button className={this.state.viewState == 'Edit' ? 'active' : ''} onClick={e => this.buttonActionHandler("Edit")}>Edit</button>
                            </div>


                    </div>
                </div>
                {this.state.viewState === 'Details' ?
                    <DetailsUi 
                    nbfcData={this.props.nbfcData}
                    />
                    : ""}
                {this.state.viewState === 'Edit' ?
                   
                    <EditUi
                    nbfcData={this.props.nbfcData}
                    />
                    : ""}
            </div>
        )
    }
}
export default NbfcDetails