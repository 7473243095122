import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminDashFilter from '../../../presentationals/Admin/Settings/EmployeeOveallFilter.component'
import { getAllStatus, getAllStatusByBetweenDate, getAllStatusByMonth, getAllStatusByDate } from '../AdminActionCreator.component';
import { TYPE } from '../../../../utils/Constant';
import { _preFormatDate } from '../../../../utils/validation'

class StatusMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dayWiseState: false,
            monthlyState: false,
            betweenDateState: false,
            Startdate: '',
            EndDate: '',
            Start: _preFormatDate(new Date()),
            End: _preFormatDate(new Date()),
            selectedMonth: 0
        }
    }

    render() {
        return (
            <div className="">
                <AdminDashFilter
                    dayWiseState={this.state.dayWiseState}
                    monthlyState={this.state.monthlyState}
                    betweenDateState={this.state.betweenDateState}
                    filteringHandler={this.filteringHandler.bind(this)}
                    filterToggleHandler={this.filterToggleHandlerr.bind(this)}
                    handleChange={this._handleChange.bind(this)}
                    Startdate={this.state.Start}
                    Enddate={this.state.End}
                    submitHandlet={this._submitHandlet.bind(this)}
                    monthClose={this.monthClose.bind(this)}
                    selectedMonth={this.state.selectedMonth}
                />
            </div>
        )
    }
    monthClose() {

    }
    _submitHandlet(type) {
        if (type == TYPE.DAY_WISE) {
            this.props.getAllStatusByDate(this.state.Start);
        } else if (type == TYPE.BETWEEN_DATE_WISE) {
            this.props.getAllStatusByBetweenDate(this.state.Start, this.state.End,
                callBack => {
                    
                });
        } else if (type == TYPE.MONTH_WISE) {
            this.props.getAllStatusByMonth(this.state.Start, this.state.End);
        } else {
            this.props.getAllStatus((callBack) => {

            });
        }
        this.props.filterHandler();
    }

    _handleChange(type, e, start) {
        if (type == TYPE.DAY_WISE) {
            this.setState({
                Startdate: e,
                Start: _preFormatDate(e)
            })
        } else if (type == TYPE.BETWEEN_DATE_WISE) {
            if (start == "start") {
                this.setState({
                    Startdate: e,
                    Start: _preFormatDate(e)
                })
            } else {
                this.setState({
                    EndDate: e,
                    End: _preFormatDate(e)
                })
            }

        } else if (type == TYPE.MONTH_WISE) {
            this.setState({
                Start: e,
                End: start + 1,
                selectedMonth: start
            })
        }
    }
    filterToggleHandlerr(e) {
        document.getElementById("filterContent").classList.toggle("filter-show");

    }
    componentDidMount() {
        window.onclick = function (event) {
            if (!event.target.matches('.filter-btn')) {

                var dropdowns = document.getElementsByClassName("filter-content");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                    var openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('filter-show')) {
                        openDropdown.classList.remove('filter-show');
                    }
                }
            }
        }
    }
    filteringHandler(e, type) {
        if (type == "dayWise") {
            this.setState({
                dayWiseState: true,
                monthlyState: false,
                betweenDateState: false
            })
        }
        else if (type == "monthly") {
            this.setState({
                monthlyState: true,
                dayWiseState: false,
                betweenDateState: false
            })
        }
        else if (type == "btwnDates") {
            this.setState({
                betweenDateState: true,
                dayWiseState: false,
                monthlyState: false,
            })
        }
    }
}
const mapStateToProps = ({ allStatus }) => {
    return {
        allStatus: allStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAllStatus,
        getAllStatusByBetweenDate,
        getAllStatusByMonth,
        getAllStatusByDate
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusMenu);