import React, { Component } from 'react'
import Ambassadar from '../../../presentationals/Admin/Ambassador/AmbassadorStats.component'

class AMBStats extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { ambasadorDetails } = this.props
        return (
            <Ambassadar
                ambasadorDetails={ambasadorDetails} />
        )
    }

}

export default AMBStats;