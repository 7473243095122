import React from 'react'
import StampDuty from './uploadStampDuty.container'
import View from './viewStamDuty.conatainer';
class MainStamp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageViewState: 'View'
        }
    }
    buttonActionHandler(type) {
        this.setState({ pageViewState: type })
    }
    render() {
        const {admin}=this.props
        return (
            <div>
                <div className="dashboard-tabs">
                    <button style={{marginLeft:'10px'}} className={this.state.pageViewState == 'View' ? 'active' : ''} onClick={e => this.buttonActionHandler("View")}>View</button>
                    <button className={this.state.pageViewState == 'Add' ? 'active' : ''} onClick={e => this.buttonActionHandler("Add")}>Add</button>
                </div>
                {this.state.pageViewState === 'Add' ?
                    <StampDuty admin={admin} />
                    : ""}
                       {this.state.pageViewState === 'View' ?
                    <View />
                    : ""}
            </div>
        )
    }
}
export default MainStamp