import React, { Component } from 'react'
import UpdateUsingCSVUi from '../../../presentationals/Admin/BulkActions/UpdateUsingCSV.component'
import { updateUsingCSVApi, updateDBUsingMappedDataAPI } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component';

let File1 = ''
let File = [];
let mappedData = []

class UpdateUsingCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataForMapping: '',
            popupState: false,
            popupStatus: '',
        }
    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}

                <UpdateUsingCSVUi
                    uploadCSVFile={this.uploadCSVFile.bind(this)}
                    dataForMapping={this.state.dataForMapping}
                    csvTableMapping={this.csvTableMapping.bind(this)}
                    updateData={this.updateData.bind(this)}
                    mappedData={mappedData}
                    deletearrayitem={this.deletearrayitem.bind(this)}
                />
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    deletearrayitem(data) {
        for (var i = 0; i < mappedData.length; i++) {
            var objKey1 = Object.keys(mappedData[i]);
            if (objKey1[0] == Object.keys(data)[0]) {
                mappedData.splice(i, 1);
                break;
            }
        }
    }

    csvTableMapping(e, csvField) {
        let key = e.target.value
        var obj = {};
        obj[key] = csvField;
        if (mappedData.length > 0) {
            for (let i = 0; i < mappedData.length; i++) {
                let data = mappedData[i]
                var objKey = Object.keys(data)[i];
                let objValue = data[objKey]
                if (objValue != csvField) {
                    mappedData.push(obj)
                    break;
                } else {
                    mappedData.splice(i, 1);
                    mappedData.push(obj)
                    break;
                }
            }
        } else {
            mappedData.push(obj)
        }
    }

    updateData() {
        if (mappedData.length > 0) {
            updateDBUsingMappedDataAPI(mappedData,
                (callBack => {
                    if (callBack == 'success') {
                        mappedData = []
                        this.setState({
                            popupState: true,
                            popupStatus: 'Data updated successfully',
                            dataForMapping: ''
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again',
                        })
                        this.removePopup();
                    }
                }))
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select values',
            })
            this.removePopup();
        }
    }

    uploadCSVFile(e) {
        let valt = e.target.value;
        let val = valt.split(/[\\\/]/)[2];
        File1 = e.target.files[0];
        File = [File1];
        updateUsingCSVApi(File,
            (callBack => {
                this.setState({ dataForMapping: JSON.parse(callBack) })
            })
        );
    }
}

export default UpdateUsingCSV;