import axios from "axios";
import { APIS } from "../../../../utils/api-factory";

export const saveDoctorProducts = (data, callBack, error) => {
    const headerConfig = {
        headers: {},
    };
    axios.post(APIS.SAVE_DOC_PRODUCTS, data, headerConfig)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const getDoctorProducts = (pageNo, noOfEntry, nbfcId, callBack, error) => {
    axios.get(APIS.GET_DOC_PRODUCTS + pageNo + '&noOfEntry=' + noOfEntry + '&nbfcId=' + nbfcId)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const getDoctorProductsCount = (nbfcId, callBack, error) => {
    axios.get(APIS.GET_PRODUCTS_COUNT + '?nbfcId=' + nbfcId)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const deleteDoctorProduct = (id, callBack, error) => {
    axios.delete(APIS.DELETE_PRODUCTS + id)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const getProductFromNbfcId = (nbfcId, callBack, error) => {
    axios.get(APIS.GET_PRODUCT_BY_NBFC_ID_NEW + nbfcId)
        .then(res => {
            console.log(res)
            callBack(res.data.data)
        })
        .catch(e => {
            console.log(e)
        })
}
export const getCalculationDoctor = (productId, loanAmount, callBack, error) => {
    axios.get(APIS.GET_CALCULATION + productId + '&loanAmount=' + loanAmount)
        .then(res => {
            console.log(res)
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
        })
}
export const editCalculationApi = (data, callBack, error) => {
    const headerConfig = {
        headers: {},
    };
    axios.post(APIS.EDIT_CALCULATION, data, headerConfig)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const getDoctorProductsNew = (doctorId, assign, callBack, error) => {
    axios.get(APIS.GET_PRODUCT_BY_DOCTORID
        + doctorId
        + '&assign=' +
        assign)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}

export const assignProductToDoctor = (doctorId, productId, callBack, error) => {
    axios.get(APIS.ASSIGN_PRODUCT_TO_DOCTOR + doctorId + '&productId=' + productId)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const activeInactiveProductApi = (status, productId, callBack, error) => {
    axios.get(APIS.CHANGE_PRODUCT_STATUS + status + '&productId=' + productId)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const unassignProductToDoctor = (doctorId, productId, callBack, error) => {
    axios.delete(APIS.UN_ASSIGN_PRODUCT_TO_DOCTOR + doctorId + '&productId=' + productId)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const uploadDoctorFile = (file, callBack, error) => {
    const headerConfig = {
        headers: {},
    };
    let multipartFormData = new FormData()
    multipartFormData.append('file', file)

    axios.post(APIS.UPLOAD_DOCTOR_RATING_FILE, multipartFormData, headerConfig)
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            error(e.res.data)
        })
}
export const saveProocessingFeeTypeApi = (productId, processigFeeType , callBack, error) => {
    axios.get(APIS.SAVE_PROCESSING_FEE_TYPE + productId + '&type=' + processigFeeType )
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}
export const getAllOrganisation = (callBack, error) => {
    axios.get(APIS.GET_ALL_ORGANISATION  )
        .then(res => {
            callBack(res.data)
        })
        .catch(e => {
            console.log(e)
            error(e.res.data)
        })
}

/////////////
