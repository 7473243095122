import React, { Component } from 'react'
import CommunicationByMobileUi from '../../../presentationals/Admin/BulkActions/BulkCommunicateByMobile.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { bulkCommunicateByCsvMObileApi } from '../AdminActionCreator.component'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import { _formatDateInDash, _formatDateInYMD } from '../../../../utils/validation'
import MainLoader from '../../../presentationals/Loader/Loader.component'
import { TemplateId, HeaderType } from '../../../presentationals/Admin/BulkActions/MessageTemplates.component'

let File = ''

class CommunicationByMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listOfUsersToCommunicate: [],
            popupState: false,
            popupStatus: '',

            url: '',
            confirmationPopup: false,
            confirmationText: '',
            notificationImage: '',
            uploadedCSV: '',
            btnDisabled: false,
            communicateByCsv: {
                body: '',
                type: ["Message"],
            },
            csvFile: '',
            requestFrom: 0,
            MainLoaderState: false,
            templateIdTosend: '',
            headerType: '',
            messageType: ''

        }


    }
    mailTempleteClick(e, type) {
        let communicateByCsv = Object.assign({}, this.state.communicateByCsv)
        if (type == 'REVIEW') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.REVIEW_ON_PLAYSTORE, messageType: HeaderType.REVIEW_ON_PLAYSTORE })
        }
        if (type == 'MONTHEND') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MONTH_END_SAL_ADVANCE, messageType: HeaderType.MONTH_END_SAL_ADVANCE })
        }
        if (type == 'BILLPAY') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BILL_PAY_SAL_ADVANCE, messageType: HeaderType.BILL_PAY_SAL_ADVANCE })
        }
        if (type == 'TRYBORROWBUDDY') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BORROWBUDDY_APP_TRY_KARO, messageType: HeaderType.BORROWBUDDY_APP_TRY_KARO })
        }
        if (type == 'JHATPATLOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.JHATPAT_LOAN, messageType: HeaderType.JHATPAT_LOAN })
        }
        if (type == 'ADVANCESALARY') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC, messageType: HeaderType.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC })
        }
        if (type == 'FESTIVESHOPING') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.FESTIVE_SHOPPING_LOAN, messageType: HeaderType.FESTIVE_SHOPPING_LOAN })
        }
        if (type == 'FEWMINUTELOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WITHIN_FEW_MINUTES, messageType: HeaderType.LOAN_WITHIN_FEW_MINUTES })
        }
        if (type == 'LASTCHANCELOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LAST_CHANCE_LOAN, messageType: HeaderType.LAST_CHANCE_LOAN })
        }
        if (type == 'UPTO100000') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.UPTO_200000_LOAN, messageType: HeaderType.UPTO_200000_LOAN })
        }
        if (type == 'LOANWAITING') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WAITING, messageType: HeaderType.LOAN_WAITING })
        }
        if (type == 'LOWINTERST') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_AT_LOW_INTEREST, messageType: HeaderType.LOAN_AT_LOW_INTEREST })
        }
        if (type == 'SPEEDOFROCKET') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MONEY_AT_SPEED_OF_ROCKET, messageType: HeaderType.MONEY_AT_SPEED_OF_ROCKET })
        }
        if (type == 'BANKBAZAR') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ADVANCE_SALARY_VIA_BANKBAZAAR, messageType: HeaderType.ADVANCE_SALARY_VIA_BANKBAZAAR })
        }
        if (type == 'URGENTCASH') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.URGENT_CASH, messageType: HeaderType.URGENT_CASH })
        }
        if (type == 'FIVEMINUTELOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WITHIN_5_MINUTES, messageType: HeaderType.LOAN_WITHIN_5_MINUTES })
        } if (type == 'BANKBAZAAR') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BANKBAZAAR, messageType: HeaderType.BANKBAZAAR })
        } if (type == 'SALARY_12_MONTH') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.SALARY_12_MONTH, messageType: HeaderType.SALARY_12_MONTH })
        }
        if (type == 'GET_A_BORROWBUDDY_PL') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.GET_A_BORROWBUDDY_PL, messageType: HeaderType.GET_A_BORROWBUDDY_PL })
        }
        if (type == 'ADVANCE_SAL_BY_BUDDYLOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ADVANCE_SAL_BY_BUDDYLOAN, messageType: HeaderType.ADVANCE_SAL_BY_BUDDYLOAN })
        }
        if (type == 'PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY, messageType: HeaderType.PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY })
        }
        if (type == 'PHOCKET_BANKBAZAAR') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BORROWBUDDY_BANKBAZAAR, messageType: HeaderType.BORROWBUDDY_BANKBAZAAR })
        }
        if (type == 'LOAN_FOR_15000_SALARY') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_FOR_15000_SALARY, messageType: HeaderType.LOAN_FOR_15000_SALARY })
        }
        if (type == 'Phocket_App_partnered_with_Anjraj_Talent') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BorrowBuddy_App_partnered_with_Anjraj_Talent, messageType: HeaderType.BorrowBuddy_App_partnered_with_Anjraj_Talent })
        }
        if (type == 'Dear_Customer_Refer_your_friends') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Dear_Customer_Refer_your_friends, messageType: HeaderType.Dear_Customer_Refer_your_friends })
        }
        if (type == 'Phocket_App_partnered_with_Genesis') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BorrowBuddy_App_partnered_with_Genesis, messageType: HeaderType.BorrowBuddy_App_partnered_with_Genesis })
        }
        if (type == 'Phocket_App_partnered_with_Hyrexpert') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BorrowBuddy_App_partnered_with_Hyrexpert, messageType: HeaderType.BorrowBuddy_App_partnered_with_Hyrexpert })
        }
        if (type == 'Phocket_App_partnered_with_Karyarth') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BorrowBuddy_App_partnered_with_Karyarth, messageType: HeaderType.BorrowBuddy_App_partnered_with_Karyarth })
        }
        if (type == 'Phocket_App_partnered_with_SAM_Career') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Borrowbuddy_App_partnered_with_SAM_Career, messageType: HeaderType.Borrowbuddy_App_partnered_with_SAM_Career })
        }
        if (type == 'PHONEPARLOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PHONEPARLOAN, messageType: HeaderType.PHONEPARLOAN })
        }
        if (type == 'REPEAT_CAMPAIGN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.REPEAT_CAMPAIGN, messageType: HeaderType.REPEAT_CAMPAIGN })
        }
        if (type == 'TWICE_APPROVED_AMOUNT') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.TWICE_APPROVED_AMOUNT, messageType: HeaderType.TWICE_APPROVED_AMOUNT })
        }
        if (type == 'MAXIMIZE_APPROVED_AMOUNT') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MAXIMIZE_APPROVED_AMOUNT, messageType: HeaderType.MAXIMIZE_APPROVED_AMOUNT })
        }
        this.setState({ communicateByCsv })
    }
    componentWillMount() {

    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ popupState: false });
    }

    cancelLoanConfirm(conType) {
        const { admin } = this.props
        this.setState({
            MainLoaderState: true
        })
        if (conType == 'yes') {
            
            if (this.state.messageType) {
                bulkCommunicateByCsvMObileApi(
                    this.state.csvFile,
                    this.state.requestFrom,
                    this.state.communicateByCsv.body,
                    admin,
                    this.state.templateIdTosend,
                    this.state.messageType === 'Promotional' ? 'Promotional' : 'Borrowbuddy',

                    callBack => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Message send successfully',
                                csvFile: '',
                                uploadedCSV: '',
                                communicateByCsv: {
                                    body: '',
                                    type: ["Message"],
                                },
                                msgBoxState: false,
                                mailBoxSate: false,
                                confirmationPopup: false,
                                MainLoaderState: false
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again',
                                confirmationPopup: false,
                                MainLoaderState: false
                            })
                            this.removePopup();
                        }
                    })
            } else {
            }
        }
        else if (conType == 'no') {
            this.setState({ confirmationPopup: false, MainLoaderState: false })
        }

    }

    communicateByCsvHandler(type) {
        if (this.state.csvFile != '') {
            if (type == 'Borrowbuddy') {
                this.setState({
                    requestFrom: 0
                })
            } else {
                this.setState({
                    requestFrom: 1
                })
            }
            this.setState({
                confirmationPopup: true,
                confirmationText: 'Are you sure you want to sent to All.'
            })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select CSV file',
            })
            this.removePopup();
        }
    }

    uploadcsvHandler(e) {
        let val = e.target.value.split(/[\\\/]/)[2];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (fileType == 'csv') {
            this.setState({
                uploadedCSV: val,
                csvFile: e.target.files[0]
            })

        }
        File = e.target.files[0];
    }
    csvCommunicate(e, comType) {
        let communicateByCsv = Object.assign({}, this.state.communicateByCsv);
        if (comType == 'body') {
            communicateByCsv.body = e.target.value;
        }
        this.setState({ communicateByCsv })
    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <CommunicationByMobileUi
                    mailTempleteClick={this.mailTempleteClick.bind(this)}
                    csvCommunicate={this.csvCommunicate.bind(this)}
                    communicateByCsvHandler={this.communicateByCsvHandler.bind(this)}
                    uploadcsvHandler={this.uploadcsvHandler.bind(this)}
                    communicateByCsv={this.state.communicateByCsv}
                    uploadedCSV={this.state.uploadedCSV}
                />
                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.MainLoaderState ?
                    <MainLoader />
                    : null}
            </div>
        )
    }
}
export default CommunicationByMobile