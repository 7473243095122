import React from 'react';




const ConfigSimilar = ({ sendHandler ,MSG, btnHandler }) => (
    <div className="container-fluid config-pg">
        <div className="row ">
            <div className="col-sm-6 col-xs-12">
                <p>1. PAN NO. :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="0" onClick={e=>btnHandler(0,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="0Exclude" onClick={e=>btnHandler(0,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
            <div className="col-sm-6 col-xs-12">
                <p>2. CURRENT V/S PERMANENT ADDRES :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="1" onClick={e=>btnHandler(1,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="1Exclude" onClick={e=>btnHandler(1,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
        </div>

        <br /><br />
        <div className="row ">
            <div className="col-sm-6 col-xs-12">
                <p>3. CURRENT V/S CURRENT ADDRESS :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="2" onClick={e=>btnHandler(2,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="2Exclude" onClick={e=>btnHandler(2,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
            <div className="col-sm-6 col-xs-12">
                <p>4. PERMANENT V/S CURRENT ADDRESS  :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="3" onClick={e=>btnHandler(3,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="3Exclude" onClick={e=>btnHandler(3,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
        </div>

        <br /><br />
        <div className="row ">
            <div className="col-sm-6 col-xs-12">
                <p>5. PERMANENT V/S PERMANENT  ADDRESS :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="4" onClick={e=>btnHandler(4,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="4Exclude" onClick={e=>btnHandler(4,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
            <div className="col-sm-6 col-xs-12">
                <p>6. COMPANY NAME :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="5" onClick={e=>btnHandler(5,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="5Exclude" onClick={e=>btnHandler(5,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
        </div>

        <br /><br />
        <div className="row ">
            <div className="col-sm-6 col-xs-12">
                <p>7. MOBILE NO. V/S ALTERNATE MOBILE NO. :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="6" onClick={e=>btnHandler(6,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="6Exclude" onClick={e=>btnHandler(6,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
            <div className="col-sm-6 col-xs-12">
                <p>8. ALTERNATE MOBILE NO. V/S MOBILE NO. :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="7" onClick={e=>btnHandler(7,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="7Exclude" onClick={e=>btnHandler(7,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
        </div>

        <br /><br />
        <div className="row ">
            <div className="col-sm-6 col-xs-12">
                <p>9. ALTERNATE MOBILE NO. V/S ALTERNATE MOBILE NO. :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="8" onClick={e=>btnHandler(8,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="8Exclude" onClick={e=>btnHandler(8,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
            <div className="col-sm-6 col-xs-12">
                <p>10. IFSC CODE :&nbsp;&nbsp;
                    <div className="pull-right">
                        <button className="similarBtn" id="9" onClick={e=>btnHandler(9,"Include")}>Include</button>
                        &nbsp;&nbsp;
                <button className="similarBtn" id="9Exclude" onClick={e=>btnHandler(9,"Exclude")} >Exclude</button>
                    </div>
                </p>
            </div>
        </div>
        <br />
        <h4 className="text-center">{MSG}</h4>
        <div className="row text-center">
            <div className="col-xs-12">
                <button className="profile-save-btn" onClick={(e) => sendHandler()}>
                    <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                </button>
            </div>
        </div>
        <div className="row from-group">
            <div className="col-xs-12">
            </div>
        </div>

    </div>
)

export default ConfigSimilar;
