import React from 'react'
import { WEB_URL } from '../../../../utils/api-factory';
import { ROLE } from '../../../../utils/Constant';
class BasicTea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            linkState: false

        }
    }
    componentDidMount() {
        const { teaGardenData, allDetail, linkState } = this.props
        var dateOfJoin = allDetail.employmentDetailsPO.dateOfJoining !== null && allDetail.employmentDetailsPO.dateOfJoining !== undefined ? allDetail.employmentDetailsPO.dateOfJoining.split("-").reverse().join("-") : ""
        var dob = allDetail.dateOfBirth !== null && allDetail.dateOfBirth !== undefined ? allDetail.dateOfBirth.split("-").reverse().join("-") : ""
        let changeData = Object.assign({}, this.state.userData)
        changeData.empId = teaGardenData.UserToEmpId.empId
        changeData.teaGardenName = teaGardenData.TeaGardenDetail.tea_garden_name
        changeData.name = allDetail.firstName
        changeData.dateOfBirth = allDetail.dateOfBirth
        changeData.gender = allDetail.gender
        changeData.employmentType = allDetail.employmentDetailsPO.fullPartTime
        changeData.ageOfEmployment = allDetail.employmentDetailsPO.totalExperience
        changeData.panNumber = allDetail.panNumber
        changeData.aadharNumber = allDetail.aadharNumber
        changeData.pinCode = allDetail.addressDetailsPOList.currentPincode
        changeData.address = allDetail.addressDetailsPOList.currentAddress
        changeData.state = allDetail.addressDetailsPOList.currentState
        changeData.city = allDetail.addressDetailsPOList.currentCity
        changeData.mobileNumber = allDetail.mobileNumber
        changeData.ifBankAccount = teaGardenData.UserToEmpId.bankAccount
        changeData.dateOfJoining = dateOfJoin
        this.setState({ userData: changeData })
    }
    userLinkHandler(data) {
        if (data == 'yes') {
            this.setState({ linkState: true })
        } else if (data == 'no') {
            this.setState({ linkState: false })
        }
    }
    render() {
        const { admin, userDetail, allDetail } = this.props
        let userData = this.state.userData
        let linkState = this.state.linkState
        var dob = userData.dateOfBirth !== null && userData.dateOfBirth !== undefined ? userData.dateOfBirth.split("-").reverse().join("-") : ""

        return (
            <div className='container-fluid'>
                {admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && linkState == false ?
                    <button className='profile-save-btn' onClick={() => this.userLinkHandler('yes')}>Click Here For User Links</button>
                    : ""}
                {admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && linkState == true ?
                    <div className='row animated slideInLeft'>
                        <button style={{ marginLeft: '15px' }} className='profile-save-btn' onClick={() => this.userLinkHandler('no')}>Close Links</button>

                        <div className="col-xs-12">
                            <br />
                            <p><b>Upload Document Link :&nbsp;</b>{allDetail != null ? allDetail.userId != null ? WEB_URL + "uploadDocuments/" + allDetail.userId : "" : ""}</p>
                        </div>
                        <div className="col-xs-12">
                            <p><b>Feedback Link :&nbsp;</b>{allDetail != null ? allDetail.userId != null ? WEB_URL + "feedback/" + allDetail.userId : "" : ""}</p>
                        </div>
                        <div className="col-xs-12">
                            {userDetail.loanId != '' && userDetail.loanId != null ?
                                <p><b>E-Mandate with Rajorpay Link :&nbsp;</b>{userDetail != null ? userDetail.loanId != null ? WEB_URL + "enach/" + userDetail.loanId : "" : ""}</p>
                                : userDetail.giftCardId != '' && userDetail.giftCardId != null ? <p><b>E-Mandate with Rajorpay Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? WEB_URL + "enach/" + userDetail.giftCardId : "" : ""}</p> : ""}
                        </div>
                        <div className="col-xs-12">
                            {userDetail.loanId != '' && userDetail.loanId != null && userDetail.typeOfProduct == 'Loan' ?
                                <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.loanId != null ? WEB_URL + "eNach/" + userDetail.loanId : "" : ""}</p> : ""}
                            {/* //    :userDetail.giftCardId!= '' &&userDetail.giftCardId!= null? <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? WEB_URL + "eNach/" + userDetail.giftCardId : "" : ""}</p>:""} */}
                        </div>
                        <div className="col-xs-12">
                            {userDetail.loanId != '' && userDetail.loanId != null ?
                                <p><b>E-Sign Link :&nbsp;</b>{userDetail != null ? userDetail.loanId != null ? WEB_URL + "esign/" + userDetail.loanId : "" : ""}</p>
                                : userDetail.giftCardId != '' && userDetail.giftCardId != null ? <p><b>E-Sign Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? WEB_URL + "esign/" + userDetail.giftCardId : "" : ""}</p> : ""}
                        </div>
                        {/* <div className="col-xs-12">
                    <p><b>E-Sign &amp; E-Mandate Link :&nbsp;</b>{allDetail != null ? allDetail.loanId != null ? WEB_URL + "esignEnach/" + allDetail.loanId : "" : ""}</p>
                </div> */}

                        {/* <div className="col-xs-12">
                    <p><b>Current Obligation :&nbsp;</b>{allDetail != null && allDetail.loanDetailList != null && allDetail.loanDetailList.length > 0 && allDetail.loanDetailList[0].currentObligation != null ? allDetail.loanDetailList[0].currentObligation : "No Obligation"}</p>
                </div> */}
                    </div>
                    : null}
                <div className="profile-details-box-new" style={{ minHeight: "300px", marginTop: '20px' }}>
                    <div className="new-basic-head" >Personal Information</div>

                    <div className='row'>
                        <div className="col-sm-4 col-xs-12">
                            <p className="word-break"><b>Name :&nbsp;</b>{userData != null ? userData.name != null ? userData.name : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p className="word-break"><b>Emp Id :&nbsp;</b>{userData != null ? userData.empId != null ? userData.empId : "" : ""}</p>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <p><b>Mobile Number :&nbsp;</b>{userData != null ? userData.mobileNumber != null ? userData.mobileNumber : "" : ""}</p>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <p><b>Age Of Employment :&nbsp;</b>{userData != null ? userData.ageOfEmployment != null ? userData.ageOfEmployment : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>Gender :&nbsp;</b>{userData != null ? userData.gender != null ? userData.gender : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>DOB :&nbsp;</b>{userData != null ? userData.dateOfBirth != null ? userData.dateOfBirth : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>PAN Number :&nbsp;</b>{userData != null ? userData.panNumber != null ? userData.panNumber : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>Aadhar Number :&nbsp;</b>{userData != null ? userData.aadharNumber != null ? userData.aadharNumber : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>Address :&nbsp;</b>{userData != null ? userData.address != null ? userData.address : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>Pin Code :&nbsp;</b>{userData != null ? userData.pinCode != null ? userData.pinCode : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>State :&nbsp;</b>{userData != null ? userData.state != null ? userData.state : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>City :&nbsp;</b>{userData != null ? userData.city != null ? userData.city : "" : ""}</p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <p><b>Tea Garden Name :&nbsp;</b>{userData != null ? userData.teaGardenName != null ? userData.teaGardenName : "" : ""}</p>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default BasicTea