import React, { Component } from 'react'
import Scoring from '../../../presentationals/Admin/CustomerPages/scoreDataViewOnly'
import { fetchCibilDetail } from '../AdminActionCreator.component'
import { APIS } from '../../../../utils/api-factory'
import Popup from '../../../presentationals/Popup/Popup.component';
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'
class Score extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDetail: this.props.allDetail,
            popupState: false,
            popupStatus: '',
            scoreDetail: {
                cibilOverdueAccount: '',
                cibilScore: '',
                cibilincome: '',
                cibilObligations: '',
                cibilSurplus: '',
                cibilRemarks: '',
                shortTermCreditCard: '',
                confirmationPopup: false,
                confirmationText: '',
                dbrValue: ''
            }
        }
    }
    cancelLoanConfirm(type) {
        if (type == 'no') {
            this.setState({ confirmationPopup: false })
        } if (type == 'yes') {
            this.scoreHandler()
        }
    }
    openConfirmation() {
        this.setState({ confirmationPopup: true, confirmationText: 'Are You Sure To Change Score?' })
    }



    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <Scoring
                    flagData={this.props.flagData}
                    dbrValue={this.state.dbrValue}
                    scoreChange={this.scoreChange.bind(this)}
                    scoreHandler={this.scoreHandler.bind(this)}
                    scoreDetail={this.state.scoreDetail}
                    openConfirmation={this.openConfirmation.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )

    }

    closePopup() {
        this.setState({ popupState: false });
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    componentDidMount() {
        let scoreDetail = Object.assign({}, this.state.scoreDetail)

        fetch(APIS.GET_CIBIL_SCORE_BY_USERID + this.state.allDetail.userId +'&loanId='+this.state.allDetail.loanId)
            .then(res => res.json())
            .then(res => {
                if (res.responseCode == 0) {

                } else {
                    if (res.overDueAccount != null) {
                        scoreDetail.cibilOverdueAccount = res.overDueAccount
                    }
                    if (res.cibilScore != null) {
                        scoreDetail.cibilScore = res.cibilScore
                    }
                    if (res.income != null) {
                        scoreDetail.cibilincome = res.income
                    }
                    if (res.obligations != null) {
                        scoreDetail.cibilObligations = res.obligations
                    }
                    if (res.surplus != null) {
                        scoreDetail.cibilSurplus = res.surplus
                    }
                    if (res.remarks != null) {
                        scoreDetail.cibilRemarks = res.remarks
                    }
                    if (res.shortTermCreditCard != null) {
                        scoreDetail.shortTermCreditCard = res.shortTermCreditCard
                    }
                    if (res.income && res.obligations) {
                        this.setState({ dbrValue: (res.obligations / res.income) * 100 })

                    }
                    this.setState({
                        scoreDetail
                    })
                }
            })
    }

    scoreChange(e, type) {
        let scoreDetail = Object.assign({}, this.state.scoreDetail)
        if (type == 'cibilOverdueAccount') {
            scoreDetail.cibilOverdueAccount = e.target.value;
        } else if (type == 'cibilScore') {
            if (e.target.value.includes('-') == false) {
                scoreDetail.cibilScore = e.target.value;
            } else {
                this.setState({ popupState: true, popupStatus: 'Please Enter Only Positive Value' })
            } this.removePopup();
        } else if (type == 'cibilincome') {
            scoreDetail.cibilincome = e.target.value;
            scoreDetail.cibilSurplus = e.target.value - this.state.scoreDetail.cibilObligations
        } else if (type == 'cibilObligations') {
            scoreDetail.cibilObligations = e.target.value;
            scoreDetail.cibilSurplus = this.state.scoreDetail.cibilincome - e.target.value
        } else if (type == 'cibilSurplus') {
            scoreDetail.cibilSurplus = e.target.value;
        } else if (type == 'cibilRemarks') {
            scoreDetail.cibilRemarks = e.target.value;
        } else if (type == 'shortTermCreditCard') {
            scoreDetail.shortTermCreditCard = e.target.value;
        }

        this.setState({
            scoreDetail
        });
        if (this.state.scoreDetail.cibilincome && this.state.scoreDetail.cibilObligations) {
            this.setState({ dbrValue: (this.state.scoreDetail.cibilObligations / this.state.scoreDetail.cibilincome) * 100 })
        }
    }

    scoreHandler(type) {
        const { admin } = this.props;
        // if (type == 'cibil')
        fetchCibilDetail(this.state.scoreDetail, this.state.allDetail, admin,
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Cibil Details Saved Successfully',
                        confirmationPopup: false
                    }, () => this.props.getData('yes'))
                    this.removePopup();
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                        confirmationPopup: false
                    })
                    this.removePopup();
                }
            }
        )
    }
}


export default Score;