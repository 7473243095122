import React, { Component } from 'react'
import { getListOfferDetail } from './AddMerchentActionCreator.Component'
import Menu from './CouponListButtons.Container'
import LocationUi from './AddLocation.container'
import SubLocationUi from './AddOutletLocation.container'
import CategoryUi from './AddCategory.container'
import SubCategoryUi from './AddSubCategory.container'
import MerchantUi from './AddMerchant.container'
import CouponDetailUi from './CouponAdd.Container'
import AddOutletUi from './MapOutlets.container'
import CouponListingUi from './CouponReporting.Container'
import DocPage from './AddMerchantImages.container'
import AddStatusUi from './MapStatus.container'
import CouponsReport from './CustomerCouponsReporting'
import AddCouponCode from './AddCouponCode.container'

class CouponMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listingOfCategory: '',
            viewPageState: 'AddMerchantDetails',
            dataForUpdate: ''
        }
    }

    componentWillMount() {
        this.getAllData()
    }

    getAllData() {
        getListOfferDetail((callBack) => {
            this.setState({
                listingOfCategory: callBack
            })
        })
    }

    render() {
        const { admin } = this.props
        return (
            <div>
                <Menu
                    getPageShow={this.getPageShow.bind(this)}
                />
                {this.state.viewPageState == 'AddLocation' ?
                    <LocationUi
                        listingOfCategory={this.state.listingOfCategory}
                        getAllData={this.getAllData.bind(this)} />
                    : this.state.viewPageState == 'AddSubLocation' ?
                        <SubLocationUi
                            listingOfCategory={this.state.listingOfCategory}
                            getAllData={this.getAllData.bind(this)} />
                        : this.state.viewPageState == 'AddCategory' ?
                            <CategoryUi
                                listingOfCategory={this.state.listingOfCategory}
                                admin={admin}
                                getAllData={this.getAllData.bind(this)} />
                            : this.state.viewPageState == 'AddSubCategory' ?
                                <SubCategoryUi
                                    listingOfCategory={this.state.listingOfCategory}
                                    admin={admin}
                                    getAllData={this.getAllData.bind(this)} />
                                : this.state.viewPageState == 'AddMerchantDetails' ?
                                    <MerchantUi
                                        listingOfCategory={this.state.listingOfCategory}
                                        admin={admin}
                                        getAllData={this.getAllData.bind(this)} />
                                    : this.state.viewPageState == 'addDiscountDetails' ?
                                        <CouponDetailUi
                                            dataForUpdate={this.state.dataForUpdate}
                                            listingOfCategory={this.state.listingOfCategory}
                                            admin={admin}
                                            getAllData={this.getAllData.bind(this)} />
                                        : this.state.viewPageState == 'addOutlet' ?
                                            <AddOutletUi
                                                dataForUpdate={this.state.dataForUpdate}
                                                listingOfCategory={this.state.listingOfCategory}
                                                admin={admin}
                                                getAllData={this.getAllData.bind(this)} />
                                            : this.state.viewPageState == 'couponListing' ?
                                                <CouponListingUi
                                                    listingOfCategory={this.state.listingOfCategory}
                                                    admin={admin}
                                                    updateOffer={this.updateOffer.bind(this)}
                                                    getAllData={this.getAllData.bind(this)} />
                                                : this.state.viewPageState == 'AddMerchantImage' ?
                                                    <DocPage
                                                        listingOfCategory={this.state.listingOfCategory}
                                                        admin={admin}
                                                        updateOffer={this.updateOffer.bind(this)}
                                                        getAllData={this.getAllData.bind(this)} />
                                                    : this.state.viewPageState == 'mapStatus' ?
                                                        <AddStatusUi
                                                            dataForUpdate={this.state.dataForUpdate}
                                                            listingOfCategory={this.state.listingOfCategory}
                                                            admin={admin}
                                                            getAllData={this.getAllData.bind(this)} />
                                                        : this.state.viewPageState == 'customerReport' ?
                                                            <CouponsReport />
                                                            : this.state.viewPageState == 'addCouponCode' ?
                                                                <AddCouponCode
                                                                    dataForUpdate={this.state.dataForUpdate}
                                                                    listingOfCategory={this.state.listingOfCategory}
                                                                    admin={admin}
                                                                    getAllData={this.getAllData.bind(this)} />
                                                                : null}
            </div>
        )
    }
    updateOffer(data) {
        this.setState({ dataForUpdate: data, viewPageState: 'addDiscountDetails' })
    }

    getPageShow(page) {
        this.setState({ viewPageState: page })
    }
}

export default CouponMain;