import React from 'react'
import TransactionPage from '../../../presentationals/CardsUtility/transactionPage.component';
import { getTransactionDetailsByUserIdApi, getCardLimitByUserIdApi, getCardDetailsByUserIdApi, addFalconVirtualCardApi, getCardTransactionsDetails, enableOrDisableCard, blockCard, getCardStatusData, getCvvData, getCardPolicyDetails, enableDisableChanel, addFalconPhysicalCardApi } from './actionCreator'
import SidePopup from '../../../presentationals/Popup/Popup.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import ConfirmationPopupCard from '../../../presentationals/CardsUtility/confirlmationPopup.component';
import Blockcardpopup from '../../../presentationals/CardsUtility/blockcardpopup.component'
import PhysicalCardPopup from '../../../presentationals/CardsUtility/physicalCard.component';
import moment from 'moment';
class Transaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardLimitState: false,
            cardDetailsState: false,
            transactionDetails: true,
            cardData: "",
            cardLimitData: "",
            transactionData: '',
            loaderState: false,
            popupState: false,
            popupStatus: '',
            cardStatus: '',
            confirmationPopupState: false,
            reasonToBlock: '',
            reasonBlockState: false,
            startDate: moment(new Date()).format('DD-MM-YYYY HH:MM:SS'),
            endDate: moment(new Date()).format('DD-MM-YYYY HH:MM:SS'),
            cardStatusData: '',
            cvvData: '',
            policyData: '',
            cardPolicyState: false,
            paymentChannel: '',
            paymentChannelStatus: '',
            physicalCardPopup: false,
            dataForPhysicalCard: ""


        }
    }

    getCardPolicyDetailsApi() {
        this.setState({ loaderState: true })
        if (this.props.cardId !== undefined && this.props.cardId !== null && this.props.cardId !== '') {
            getCardPolicyDetails(this.props.cardId, this.props.userDetailsByUserId.cardUserId, callback => {
                if (callback ) {
                    this.setState({ policyData: callback, loaderState: false })
                }else{
                    this.setState({  loaderState: false ,popupState: true, popupStatus: 'No Data Found !'},()=>this.removePopup())
                }
            })
        } else {
            this.setState({ loaderState: false, popupState: true, popupStatus: 'Please try again later !' }, () => this.removePopup())

        }
    }
    enableDisableChanelApi(paymentChannel, paymentChannelStatus) {
        this.setState({ loaderState: true })
        if (this.props.cardId !== undefined && this.props.cardId !== null && this.props.cardId !== '') {
            enableDisableChanel(this.props.cardId, this.props.userDetailsByUserId.cardUserId, paymentChannel, paymentChannelStatus === 'ENABLED' ? 'DISABLED' : paymentChannelStatus === 'DISABLED' ? 'ENABLED' : "", callback => {
                if (callback === 'success') {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'Changed Successfully !' }, () => this.removePopup())
                } else {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'Please try again later !' }, () => this.removePopup())
                }

            })
        } else {
            this.setState({ loaderState: false, popupState: true, popupStatus: 'Please try again later !' }, () => this.removePopup())

        }

    }

    channelSubmit(data1, data2) {
        this.enableDisableChanelApi(data1, data2)
    }
    getCvv() {
        this.setState({ loaderState: true })
        if (this.props.cardId !== undefined && this.props.cardId !== null && this.props.cardId !== '') {
            getCvvData(this.props.cardId, this.props.userDetailsByUserId.cardUserId, callback => {
                this.setState({ cvvData: callback, loaderState: false })
            })
        } else {
            this.setState({ loaderState: false, popupState: true, popupStatus: 'Please try again later !' }, () => this.removePopup())

        }
    }
    dateHandler(e, type) {
        if (type === 'first') {
            this.setState({ startDate: moment(new Date(e.target.value)).format('DD-MM-YYYY HH:MM:SS') })
        }
        if (type === 'end') {
            this.setState({ endDate: moment(new Date(e.target.value)).format('DD-MM-YYYY HH:MM:SS') })

        }
    }
    getCardStatusDataApi() {
        getCardStatusData(this.props.cardId, callback => {
            this.setState({ cardStatusData: callback })
        })
    }
    searchSubmit() {
        this.transactionApi()
    }
    transactionApi() {
        this.setState({ loaderState: true })
        getCardTransactionsDetails(this.props.userDetailsByUserId.userId, this.state.startDate, this.state.endDate, callback => {
            if (callback) {
                this.setState({ transactionData: callback, loaderState: false })
            } else {
                this.setState({ loaderState: false })
            }
        })
    }
    enableOrDisable() {
        if (this.state.cardStatus) {
            enableOrDisableCard(this.props.userDetailsByUserId, this.props.cardId, this.state.cardStatus, callback => {
            })
        }
    }

    changeCardStatus(e, type) {
        if (type === 'change') {
            this.setState({
                confirmationPopupState: true
            })
        }
        if (type === 'block') {
            this.setState({
                reasonBlockState: true
            })
        }
    }
    componentDidMount() {
        this.transactionApi()
    }

    cardLimitApi() {
        this.setState({ loaderState: true })
        getCardLimitByUserIdApi(this.props.userDetailsByUserId.userId, callback => {
            this.setState({ cardLimitData: callback, loaderState: false })
        })
    }
    cardDetailsApi() {
        this.setState({ loaderState: true })
        getCardDetailsByUserIdApi(this.props.userDetailsByUserId.userId, callback => {
            localStorage.setItem('cardId', callback.cardId)
            this.setState({ cardData: callback, loaderState: false })
        })
        this.getCardStatusDataApi()
    }
    sendHandler(type) {
        if (type === 'transaction') {
            this.transactionApi()
            this.setState({ cardPolicyState: false, transactionDetails: true, cardDetailsState: false, cardLimitState: false })
        }
        if (type === 'limit') {
            this.cardLimitApi()
            this.setState({ cardPolicyState: false, transactionDetails: false, cardDetailsState: false, cardLimitState: true })
        }
        if (type === 'details') {
            this.cardDetailsApi()
            this.setState({ cardPolicyState: false, transactionDetails: false, cardDetailsState: true, cardLimitState: false })
        }
        if (type === 'policy') {
            this.getCardPolicyDetailsApi()
            this.setState({ cardPolicyState: true, transactionDetails: false, cardDetailsState: false, cardLimitState: false })
        }
    }
    addFalconVirtualCard() {
        const { admin } = this.props
        this.setState({ loaderState: true })
        if (this.props.userDetailsByUserId.userId) {
            addFalconVirtualCardApi(this.props.userDetailsByUserId, admin.emailId, callback => {
                if (callback === 'success') {
                    this.setState({ popupState: true, popupStatus: 'Added Successfully !', loaderState: false }, () => this.removePopup())
                    this.cardDetailsApi()
                } else {
                    this.setState({ popupState: true, popupStatus: 'Please Try Again Later !', loaderState: false }, () => this.removePopup())

                }
            })
        }

    }
    submitPhysical() {
        const { admin } = this.props
        this.setState({ loaderState: true })
        if (this.state.dataForPhysicalCard.bin && this.state.dataForPhysicalCard.vpan && this.state.dataForPhysicalCard.proxyNumber) {
            addFalconPhysicalCardApi(this.props.userDetailsByUserId, this.state.dataForPhysicalCard, admin.emailId, callBack => {
                if (callBack === 'success') {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'Created Successfully!', physicalCardPopup: false }, () => this.removePopup())

                } else {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'Please Try Again Later !' }, () => this.removePopup())

                }
            })
        } else {
            this.setState({ loaderState: false, popupState: true, popupStatus: 'Enter All Details !' }, () => this.removePopup())

        }

    }
    physicalCardHandler(e) {
        let dataForPhysicalCard = Object.assign({}, this.state.dataForPhysicalCard)
        dataForPhysicalCard[e.target.name] = e.target.value
        this.setState({ dataForPhysicalCard: dataForPhysicalCard })
    }
    addFalconPhysicalCardApi() {
        this.setState({ physicalCardPopup: true })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ popupState: false, physicalCardPopup: false })
    }
    statusHandler(e) {
        this.setState({ cardStatus: e.target.value })
    }
    blockcardapi() {
        if (this.props.cardId !== undefined && this.props.cardId !== null && this.props.cardId !== '') {
            if (this.state.reasonToBlock) {
                blockCard(this.props.userDetailsByUserId, this.props.cardId, this.state.reasonToBlock, callback => {
                })
            }
        } else {
            this.setState({ loaderState: false, popupState: true, popupStatus: 'Please try again later !' }, () => this.removePopup())

        }
    }
    reasonChangeHandler(e) {
        this.setState({ reasonToBlock: e.target.value })
    }
    close() {
        this.setState({ confirmationPopupState: false, reasonBlockState: false })
    }
    render() {
        const { admin } = this.props
        return (
            <div>
                <div className="btn-group-tab">
                    <button className={this.state.transactionDetails ? 'active' : null} onClick={(e) => this.sendHandler('transaction')}>Transaction Details</button>
                    <button className={this.state.cardDetailsState ? 'active' : null} onClick={(e) => this.sendHandler('details')}>Card Details</button>
                    <button className={this.state.cardLimitState ? 'active' : null} onClick={(e) => this.sendHandler('limit')}>Card Limit Details</button>
                    <button className={this.state.cardPolicyState ? 'active' : null} onClick={(e) => this.sendHandler('policy')}>Card Policy</button>

                </div>
                <TransactionPage
                    addFalconPhysicalCardApi={this.addFalconPhysicalCardApi.bind(this)}
                    channelSubmit={this.channelSubmit.bind(this)}
                    getCvv={this.getCvv.bind(this)}
                    admin={admin}
                    searchSubmit={this.searchSubmit.bind(this)}
                    dateHandler={this.dateHandler.bind(this)}
                    transactionData={this.state.transactionData}
                    cardLimitData={this.state.cardLimitData}
                    changeCardStatus={this.changeCardStatus.bind(this)}
                    state={this.state}
                    addFalconVirtualCardApi={this.addFalconVirtualCard.bind(this)} />
                {this.state.popupState ?
                    <SidePopup popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.confirmationPopupState ?
                    <ConfirmationPopupCard close={this.close.bind(this)} submit={this.enableOrDisable.bind(this)} statusHandler={this.statusHandler.bind(this)} />
                    : ""}
                {this.state.reasonBlockState ?
                    <Blockcardpopup close={this.close.bind(this)} submit={this.blockcardapi.bind(this)} reasonChangeHandler={this.reasonChangeHandler.bind(this)} />
                    : ""}
                {this.state.physicalCardPopup === true ?
                    <PhysicalCardPopup

                        submitPhysical={this.submitPhysical.bind(this)}
                        physicalCardHandler={this.physicalCardHandler.bind(this)}
                        close={this.closePopup.bind(this)}
                    />
                    : ""}
            </div>
        )
    }
}
export default Transaction