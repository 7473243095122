import React, { useState, useEffect } from 'react';
import File from '../../../../images/loanidcsv.png';
import LoanId from '../../../../images/uploadloanid.csv';
import { readCsvFileLoanId, saveCsvFileLoanId } from '../AdminActionCreator.component';
import Loader from '../../../presentationals/Loader/Loader.component';
import PopUp from '../../../presentationals/Popup/Popup.component';
const UpdateLoanId = ({ }) => {
    const [csvFile, setCsvFile] = useState(null);
    const [nameOfFile, setNameOfFile] = useState('');
    const [csvData, setCsvData] = useState('');
    const [csvSaveData, setSaveData] = useState('');
    const [loaderState, setLoaderState] = useState(false);
    const [popupState, setPopupState] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');

    useEffect(() => { }, []);

    const changeHandler = (e) => {
        setCsvFile(e.target.files[0]);
        setNameOfFile(e.target.files[0].name);
    };

    const submitHandler = () => {
        setLoaderState(true);
        readCsvFileLoanId(csvFile, (callback) => {
            console.log(csvFile);
            if (callback) {
                setCsvData(JSON.parse(callback));
                setLoaderState(false);
            }
            setLoaderState(false);
        });
    };

    const UpdateIds = () => {
        setLoaderState(true);
        saveCsvFileLoanId(csvFile, (callback) => {
            console.log(callback);
            if (callback) {
                setCsvData(JSON.parse(callback));
                setLoaderState(false);
                setPopupState(true)
                setPopupStatus('Updated Successfully!')
                removePopup()
            } else {
                setPopupStatus('Please try again later!')
                removePopup()
                setLoaderState(false);
            }
        });
    };
    const closePopup = () => {
        setPopupState(false)
    }
    const removePopup = () => {
        setTimeout(() => {
            setPopupState(false)
        }, 5000);
    }
    return (
        <>
            {loaderState && <Loader />}
            {popupState ? <PopUp popupStatus={popupStatus} closePopup={closePopup} /> : ""}
            <div className='container-fluid'>
                <h3>
                    <b>Add Loan Id</b>
                </h3>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <div className='upload-csv text-center' style={{ marginTop: '53px' }}>
                            <label className='' htmlFor='formFileLg'>
                                <i className='bi bi-cloud-arrow-up-fill me-3'></i>
                                <span className='align-middle'>
                                    {nameOfFile ? (
                                        nameOfFile
                                    ) : (
                                        <>
                                            <i className='fa fa-cloud-upload' style={{ fontSize: '20px', marginTop: '10px' }}></i>
                                            &nbsp;&nbsp; Click here to Upload CSV File
                                        </>
                                    )}
                                </span>
                            </label>
                            <input
                                className='form-control form-control-lg'
                                id='formFileLg'
                                accept='.csv'
                                type='file'
                                onChange={(e) => {
                                    changeHandler(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className='' style={{ display: 'flex' }}>
                        <div >
                            {nameOfFile && (
                                <div className='text-center' style={{ marginTop: '50px', }}>
                                    <button className='btn btn-primary' style={{ background: '#2B78FF', padding: '15px' }} onClick={submitHandler}>
                                        Submit
                                    </button>
                                </div>
                            )}
                        </div>
                        &nbsp;&nbsp;
                        <div >

                            <a href={LoanId}>
                                <button className='btn btn-primary' style={{ background: '#2B78FF', marginBottom: '20px', marginTop: '50px', padding: '15px' }}>
                                    <i className='fa fa-cloud-download'></i>&nbsp;&nbsp;Download CSV Sample File{' '}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {csvData && csvData.csv && (
                        <div className='col-sm-6 col-xs-12 text-center phocket-table-new'>

                            <>
                                <h3>
                                    <b>Uploaded Csv</b>
                                </h3>
                                {/* <div className='text-right'>
                                    <button style={{background:'#2B78FF'}} className='btn btn-primary' onClick={UpdateIds}>
                                        Update
                                    </button>
                                </div> */}
                                <br />

                                <table>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Mobile Number</th>
                                            <th className='text-center'>Loan Id  <button style={{ background: '#2B78FF', float: 'right' }} className='btn btn-primary' onClick={UpdateIds}>
                                                Update
                                            </button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(csvData.csv).map((csvKey) => (
                                            <tr key={csvKey}>
                                                <td>{csvKey}</td>
                                                <td>{csvData.csv[csvKey]}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </>

                        </div>
                    )}
                    <div className='col-sm-6 col-xs-12'>
                        <div style={{ padding: '20px' }}>
                            {console.log(csvData.error)}
                            <p className='text-danger'>
                                {csvData && csvData.error !== null && typeof csvData.error === 'string' && csvData.error !== '' && csvData.error.length > 0 &&
                                    <p>Error: {csvData.error}</p>
                                }

                            </p>
                            <p className='text-danger'>

                                {csvData && typeof csvData.error === 'object' && csvData.error !== null && (
                                    <table>

                                        <thead>
                                            <tr>
                                                <th>Error</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(csvData.error).map(([key, value]) => (

                                                <tr key={key}>
                                                    <td>{value}<br /></td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </p>
                        </div>
                        {/* <p className='text-danger'>{csvData && csvData.duplicate ? <p>Duplicate: </p> : ""}</p> */}

                        {/* {csvData && csvData.duplicate && Object.keys(csvData.duplicate).length > 0 && Object.entries(csvData.duplicate).map(([loanId, value], index) => (

                            <tr key={`duplicate-${index}`}>
                                <tr >
                                    <td>{value}<br /></td>

                                </tr>
                            </tr>
                        ))} */}
                        {csvData && typeof csvData.duplicate === 'object' && csvData.duplicate !== null && (Object.keys(csvData.duplicate)).length > 0 && (
                            <table className='text-danger' style={{ padding: '20px' }}>

                                <thead>
                                    <tr>
                                        <th>Duplicate</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(csvData.duplicate).map(([key, value]) => (

                                        <tr key={key}>
                                            <td>{value}<br /></td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}


                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateLoanId;
