import React, { useState } from "react";
import MainExpenses from "./main";
import MainCategory from "./category/main";
import MainSubCategory from "./subcategory/main";
import MainDescription from "./description/main";
import MainTarget from "./Targets/main";

const TabsExpense = () => {
    const [viewState, setViewState] = useState('expense')
    return (
        <>
            <div className="row fixed-top" style={{ marginTop: '0px', display: 'flex', width: '101%' }}>
                <div className="" onClick={() => setViewState('expense')} style={{ borderBottom: viewState === 'expense' ? '2px solid #267DFF' : '', background: viewState === 'expense' ? '#267DFF' : '#DBE9FF', padding: viewState === 'expense' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'expense' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Expense List

                </div>
                <div className="" onClick={() => setViewState('category')} style={{ borderBottom: viewState === 'category' ? '2px solid #267DFF' : '', background: viewState === 'category' ? '#267DFF' : '#DBE9FF', padding: viewState === 'category' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'category' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Expense Category

                </div>
                <div className="" onClick={() => setViewState('subCategory')} style={{ borderBottom: viewState === 'subCategory' ? '2px solid #267DFF' : '', background: viewState === 'subCategory' ? '#267DFF' : '#DBE9FF', padding: viewState === 'subCategory' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'subCategory' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Expense  Sub-Category

                </div>
                <div className="" onClick={() => setViewState('description')} style={{ borderBottom: viewState === 'description' ? '2px solid #267DFF' : '', background: viewState === 'description' ? '#267DFF' : '#DBE9FF', padding: viewState === 'description' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'description' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Expense Description

                </div>
                <div className="" onClick={() => setViewState('target')} style={{ borderBottom: viewState === 'target' ? '2px solid #267DFF' : '', background: viewState === 'target' ? '#267DFF' : '#DBE9FF', padding: viewState === 'target' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'target' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Expense Target

                </div>

            </div>
            {viewState === 'expense' ?
                <MainExpenses />
                : ""}
            {viewState === 'category' ?
                <MainCategory />
                : ""}
            {viewState === 'subCategory' ?
                <MainSubCategory />
                : ""}
            {viewState === 'description' ?
                <MainDescription />
                : ""}
            {viewState === 'target' ?
                <MainTarget />
                : ""}
        </>
    )
}
export default TabsExpense