import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { getAllBranchData, getEventListApi } from './actioncreator'
let paginationNumber = 10

const Alldata = () => {
    const [allData, setAllData] = useState('')
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [startDateToShow, setstartDateToShow] = useState('')
    const [endDateToShow, setendDateToShow] = useState('')
    const [eventData, setEventData] = useState([])
    const [minPagination, setminPagination] = useState(0)
    const [maxPagination, setmaxPagination] = useState(10)
    const [type, setType] = useState('')

    useEffect(() => {
        getData()
        getEvents()
    }, [])
    const getEvents = () => {
        getEventListApi(callback => {
            setEventData(callback)
        })
    }
    const getData = () => {
        if (type) {
            getAllBranchData(startDate, endDate, type, callback => {
                setAllData(callback)
            })
        }
    }
    const changeHandler = (e, type) => {
        if (type === 'start') {
            setstartDateToShow(e.target.value)
            setstartDate(moment(e.target.value).format('DD-MM-YYYY'))
        }
        if (type === 'end') {
            setendDateToShow(e.target.value)
            setendDate(moment(e.target.value).format('DD-MM-YYYY'))

        }
        if (e.target.value === '' || e.target.value === 'Invalid date') {
            setstartDate('')
            setendDate('')
            setstartDateToShow('')

            setendDateToShow('')
            getAllBranchData('', '', '', callback => {
                setAllData(callback)
            })
        }
    }
    const search = () => {
        getData()


    }
    const paginationHandler = (data, type) => {
        if (type == 'back') {
            if (minPagination >= paginationNumber) {
                setminPagination(minPagination - paginationNumber)
                setmaxPagination(maxPagination - paginationNumber)
            }
        } else if (type == 'forward') {
            if (maxPagination < data.length) {
                setminPagination(minPagination + paginationNumber)
                setmaxPagination(maxPagination + paginationNumber)

            }
        }
    }
    return (
        <div className='container-fluid '>
            <div className='row'>
                <div className='col-sm-2 col-xs-12' style={{ marginTop: '20px' }}><input type='date' value={startDateToShow} placeholder='start date' className='form-control' onChange={(e) => changeHandler(e, 'start')} /></div>
                <div className='col-sm-2 col-xs-12' style={{ marginTop: '20px' }}><input type='date' value={endDateToShow} className='form-control' onChange={(e) => changeHandler(e, 'end')} placeholder='end date' /></div>
                <div className='col-sm-2 col-xs-12' style={{ marginTop: '20px' }}>

                    <select className='form-control' onChange={(e) => setType(e.target.value)}>
                        <option value=''>Select Type</option>
                        <option value='approve'>Approved</option>
                        <option value='complete'>Complete</option>

                    </select>
                </div>

                <div className='col-sm-1 col-xs-12'><button className='profile-save-btn' onClick={() => search()}>Search</button></div>
            </div>
            <div style={{ overflow: 'auto' }}>
                {allData && Object.entries(allData).slice(minPagination, maxPagination).map((data, i) => {
                    return (
                        <div className=''>
                            <div className='col-sm-3 col-xs-12'>
                                <div style={{ marginTop: i === 0 ? '50px' : '10px' }}><strong>{data[0]}</strong></div></div>
                            <div className='col-sm-9 col-xs-12' >
                                <div className='' style={{ display: 'flex', marginTop: '-20px' }}>
                                    {eventData && eventData.eventList.map((l, q) => {
                                        return (
                                            <div>
                                                <table className='blueTable' style={{ overflow: 'scroll' }}>
                                                    <thead>
                                                        {i === 0 && l !== null ? <th className='text-center' style={{ padding: '10px 0px' }}>{l}</th> : ""}
                                                    </thead>
                                                    <tbody>
                                                        {Object.entries(data[1]).map((d, r) => {
                                                            return (
                                                                l !== null ? <td style={{ padding: '14px 98px' }}>{d[0] === l ? <span style={{ padding: d[1].count < 9 ? '14px 16.5px' : d[1].count >= 1000 ? '14px 8px' : (d[1].count > 9 && d[1].count <= 99) ? '14px 13.5px' : (d[1].count > 99 && d[1].count <= 999) ? '14px 9px' : '' }}> {d[1].count}</span> : "0"}</td> : ""
                                                            )

                                                        })}
                                                    </tbody>

                                                </table>

                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {allData !== '' && (Object.keys(allData)).length > 0 ?
                <div className='row'>
                    <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => paginationHandler(Object.keys(allData), 'back')}></button></div>
                    <div className="col-sm-2 col-xs-12  phoc text-center" >{(minPagination + 1) + ' - ' + (maxPagination) + ' of ' + (Object.keys(allData)).length}</div>
                    <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => paginationHandler(Object.keys(allData), 'forward')}></button></div>
                </div>
                : ""}
            {/* </div> */}

        </div>
    )
}
export default Alldata