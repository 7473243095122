import React from 'react';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import { WEEKNAME, PLACEHOLDER } from '../../../../utils/Constant';



const Collection = ({ MSG,sendHandler}) => (
    <div className="container-fluid config-notif">
        <div className="max-width-500px">
            <div className="row">
                <div className="col-xs-12">
                    <h5 className="bold">Message</h5>
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Time</label>
                    <TimePicker
                        format="HH:mm"
                        showSecond={false}
                        minuteStep={10}
                        className="full-width"
                    />
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Day</label>
                    <select className="rc-time-picker-input pointer">
                        <option value="" disabled selected>{PLACEHOLDER.WEEKNAME}</option>
                        <option value={WEEKNAME.MONDAY}>{WEEKNAME.MONDAY}</option>
                        <option value={WEEKNAME.TUESDAY}>{WEEKNAME.TUESDAY}</option>
                        <option value={WEEKNAME.WEDNESDAY}>{WEEKNAME.WEDNESDAY}</option>
                        <option value={WEEKNAME.THURSDAY}>{WEEKNAME.THURSDAY}</option>
                        <option value={WEEKNAME.FRIDAY}>{WEEKNAME.FRIDAY}</option>
                        <option value={WEEKNAME.SATURDAY}>{WEEKNAME.SATURDAY}</option>
                        <option value={WEEKNAME.SUNDAY}>{WEEKNAME.SUNDAY}</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <h5 className="bold">WhatsApp</h5>
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Time</label>
                    <TimePicker
                        format="HH:mm"
                        showSecond={false}
                        minuteStep={10}
                        className="full-width"
                    />
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Day</label>
                    <select className="rc-time-picker-input pointer">
                        <option value="" disabled selected>{PLACEHOLDER.WEEKNAME}</option>
                        <option value={WEEKNAME.MONDAY}>{WEEKNAME.MONDAY}</option>
                        <option value={WEEKNAME.TUESDAY}>{WEEKNAME.TUESDAY}</option>
                        <option value={WEEKNAME.WEDNESDAY}>{WEEKNAME.WEDNESDAY}</option>
                        <option value={WEEKNAME.THURSDAY}>{WEEKNAME.THURSDAY}</option>
                        <option value={WEEKNAME.FRIDAY}>{WEEKNAME.FRIDAY}</option>
                        <option value={WEEKNAME.SATURDAY}>{WEEKNAME.SATURDAY}</option>
                        <option value={WEEKNAME.SUNDAY}>{WEEKNAME.SUNDAY}</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <h5 className="bold">Mail</h5>
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Time</label>
                    <TimePicker
                        format="HH:mm"
                        showSecond={false}
                        minuteStep={10}
                        className="full-width"
                    />
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Day</label>
                    <select className="rc-time-picker-input pointer">
                        <option value="" disabled selected>{PLACEHOLDER.WEEKNAME}</option>
                        <option value={WEEKNAME.MONDAY}>{WEEKNAME.MONDAY}</option>
                        <option value={WEEKNAME.TUESDAY}>{WEEKNAME.TUESDAY}</option>
                        <option value={WEEKNAME.WEDNESDAY}>{WEEKNAME.WEDNESDAY}</option>
                        <option value={WEEKNAME.THURSDAY}>{WEEKNAME.THURSDAY}</option>
                        <option value={WEEKNAME.FRIDAY}>{WEEKNAME.FRIDAY}</option>
                        <option value={WEEKNAME.SATURDAY}>{WEEKNAME.SATURDAY}</option>
                        <option value={WEEKNAME.SUNDAY}>{WEEKNAME.SUNDAY}</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <h5 className="bold">App Notification</h5>
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Time</label>
                    <TimePicker
                        format="HH:mm"
                        showSecond={false}
                        minuteStep={10}
                        className="full-width"
                    />
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Day</label>
                    <select className="rc-time-picker-input pointer">
                        <option value="" disabled selected>{PLACEHOLDER.WEEKNAME}</option>
                        <option value={WEEKNAME.MONDAY}>{WEEKNAME.MONDAY}</option>
                        <option value={WEEKNAME.TUESDAY}>{WEEKNAME.TUESDAY}</option>
                        <option value={WEEKNAME.WEDNESDAY}>{WEEKNAME.WEDNESDAY}</option>
                        <option value={WEEKNAME.THURSDAY}>{WEEKNAME.THURSDAY}</option>
                        <option value={WEEKNAME.FRIDAY}>{WEEKNAME.FRIDAY}</option>
                        <option value={WEEKNAME.SATURDAY}>{WEEKNAME.SATURDAY}</option>
                        <option value={WEEKNAME.SUNDAY}>{WEEKNAME.SUNDAY}</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <h5 className="bold">Auto Call</h5>
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Time</label>
                    <TimePicker
                        format="HH:mm"
                        showSecond={false}
                        minuteStep={10}
                        className="full-width"
                    />
                </div>
                <div className="col-sm-6 col-xs-12 form-group">
                    <label className="font-12px">Day</label>
                    <select className="rc-time-picker-input pointer">
                        <option value="" disabled selected>{PLACEHOLDER.WEEKNAME}</option>
                        <option value={WEEKNAME.MONDAY}>{WEEKNAME.MONDAY}</option>
                        <option value={WEEKNAME.TUESDAY}>{WEEKNAME.TUESDAY}</option>
                        <option value={WEEKNAME.WEDNESDAY}>{WEEKNAME.WEDNESDAY}</option>
                        <option value={WEEKNAME.THURSDAY}>{WEEKNAME.THURSDAY}</option>
                        <option value={WEEKNAME.FRIDAY}>{WEEKNAME.FRIDAY}</option>
                        <option value={WEEKNAME.SATURDAY}>{WEEKNAME.SATURDAY}</option>
                        <option value={WEEKNAME.SUNDAY}>{WEEKNAME.SUNDAY}</option>
                    </select>
                </div>
            </div>
        </div>

        <h4 className="text-center">{MSG}</h4>

        <div className="row text-center" onClick={(e) => sendHandler()}>
            <div className="col-xs-12">
                <button className="profile-save-btn" >
                    <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                </button>
            </div>
        </div>
    </div>
)

export default Collection;