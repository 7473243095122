import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DisburseUi from '../../../presentationals/Admin/Recovery/Disburse.component';
import RecoveryCommunication from './RecoveryCommunication.container'
import {
    getistByNumber, getlistByEmail, getlistByApplication, getDisburseLead, saveLoanId,
    getlistByAppLoanId, saveCHequeNoticeApi, saveNachDataApi
} from '../AdminActionCreator.component';
import { STRING, ROLE } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import Popup from '../../../presentationals/Popup/Popup.component';
import { APIS } from '../../../../utils/api-factory';
import ChequeNoticePopup from '../../../presentationals/Admin/Recovery/NoticeAndCheckDate.component'

let nachCheckbox = false

let addLoanId = false
let paginationNumber = 10;

class DueContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            recoveryPageState: true,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.DISBURSED,
            forwordPageState: 1,
            loadarState: false,
            viewPopupDetail: '',
            logsUserId: '',
            dateToSend: '',
            actionButtonState: false,
            loadarState: false,
            FilteredData: [],
            pageIndexToShow: 0,
            dataForCommunication: '',
            chequeNoticePopup: false,
            chequeNoticeData: '',
            chequeNoticeDateToShow: '',
            chequeNoticeDate: '',
            chequeNoticeType: '',
            searchByAppNo: '',
            searchByemail: '',
            searchBymobile: '',
            disburseLoanId: '',
            searchByDate: '',
            searchByDateShow: '',
            collectionType: 'disburse',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }
    }

    getLeads() {
        this.setState({ loadarState: true })
        this.props.getDisburseLead(this.state.followUpStatus, this.state.forwordPageState, this.props.admin.emailId, this.state.searchByDate,
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            })
    }

    componentDidMount() {
        this.getLeads()
    }

    render() {
        const { disburseLeads, admin } = this.props;
        if (admin.rolelist != undefined) {
            if (admin.rolelist.indexOf(ROLE.NACH_CHANGE) >= 0) {
                nachCheckbox = true
            }
            if (admin.rolelist.indexOf(ROLE.ADD_LOAN_ID) >= 0) {
                addLoanId = true
            }
        }
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.recoveryPageState == true ?
                    <DisburseUi
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        paginationHandler={this.paginationHandler.bind(this)}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        allUserDetail={disburseLeads}
                        forwordpage={this.forwordpage.bind(this)}
                        backpage={this.backpage.bind(this)}
                        dataFilter={this.dataFilter.bind(this)}
                        loadarState={this.state.loadarState}
                        refreshpage={this.refreshpage.bind(this)}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        recoveryComunicateHandler={this.recoveryComunicateHandler.bind(this)}
                        chequeNoticeShowHandler={this.chequeNoticeShowHandler.bind(this)}
                        ignoreHandler={this.ignoreHandler.bind(this)}
                        searchByAppNo={this.state.searchByAppNo}
                        searchByemail={this.state.searchByemail}
                        searchBymobile={this.state.searchBymobile}
                        nashHandler={this.nashHandler.bind(this)}
                        nachCheckbox={nachCheckbox}
                        addLoanId={addLoanId}
                        admin={admin}
                        disburseLoanId={this.disburseLoanId.bind(this)}
                        saveDisburseLoanId={this.saveDisburseLoanId.bind(this)}
                        searchByDate={this.state.searchByDate}
                        searchByDateShow={this.state.searchByDateShow}
                    /> : ""}
                {this.state.communicationPageState == true ?
                    <RecoveryCommunication
                        dataForCommunication={this.state.dataForCommunication}
                        recoveryBackHandler={this.recoveryBackHandler.bind(this)}
                        admin={admin}
                        collectionType={this.state.collectionType}
                    />
                    : ""}
                {this.state.chequeNoticePopup == true ?
                    <ChequeNoticePopup
                        chequeNotice={this.chequeNotice.bind(this)}
                        chequeNoticeHandler={this.chequeNoticeHandler.bind(this)}
                        chequeNoticeDateToShow={this.state.chequeNoticeDateToShow}
                        chequeNoticeDate={this.state.chequeNoticeDate}
                        closeNoticePopup={this.closeNoticePopup.bind(this)} />
                    : ""}
            </div>
        )
    }

    disburseLoanId(e) {
        this.setState({ disburseLoanId: e.target.value })
    }

    saveDisburseLoanId(data) {
        if (this.state.disburseLoanId != '') {
            saveLoanId(this.state.disburseLoanId, data.userId,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: "Loan Id save successfully",
                            disburseLoanId: ''
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: "Loan Id not save",
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: "Please enter loan id",
                disburseLoanId: ''
            })
            this.removePopup();
        }
    }


    nashHandler(e, incomingData) {
        const { admin } = this.props;
        saveNachDataApi(e.target.checked, incomingData.userId, admin,
            (callBack) => {
                this.getLeads()
            })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    ignoreHandler(e, loanId) {
        fetch(APIS.SAVE_EXTENSION_TIME + loanId + '&status=' + e.target.checked)
            .then(res => res.text())
            .then(res => {
                this.getLeads()
            })
    }

    chequeNotice(e, inpType) {
        if (inpType == 'chequeNotice') {
            this.setState({
                chequeNoticeType: e.target.value,
            })
        } else if (inpType == 'chequeNoticeDate') {
            this.setState({
                chequeNoticeDateToShow: e,
                chequeNoticeDate: _preFormatDate(e)
            })
        }
    }

    chequeNoticeHandler() {
        const { admin } = this.props;
        if (this.state.chequeNoticeType != '' && this.state.chequeNoticeDate != '') {
            saveCHequeNoticeApi(this.state.chequeNoticeData.loanId, this.state.chequeNoticeType, this.state.chequeNoticeDate, admin, this.state.chequeNoticeData.emiId,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            chequeNoticePopup: false,
                            chequeNoticeType: '',
                            chequeNoticeDate: '',
                            chequeNoticeDateToShow: '',
                            popupState: true,
                            popupStatus: 'Data saved successfully',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select TYPE & DATE',
            })
            this.removePopup();
        }
    }

    closeNoticePopup() {
        this.setState({
            chequeNoticePopup: false,
        })
    }

    chequeNoticeShowHandler(data) {
        this.setState({
            chequeNoticePopup: true,
            chequeNoticeData: data
        })
    }

    recoveryComunicateHandler(data) {
        this.setState({
            recoveryPageState: false,
            communicationPageState: true,
            dataForCommunication: data
        })
    }
    recoveryBackHandler() {
        const { admin } = this.props
        this.setState({
            recoveryPageState: true,
            communicationPageState: false
        })
        if (this.state.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.searchByAppNo, 1, '', admin, true,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchByemail != '') {
            this.props.getlistByEmail(this.state.searchByemail, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchBymobile != '') {
            this.props.getistByNumber(this.state.searchBymobile, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else {
            this.getLeads()
        }
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    refreshpage() {
        this.getLeads()
    }

    dataFilter(e, type) {
        const { admin } = this.props
        if (type == "applicationNo") {
            this.setState({ searchByAppNo: e.target.value })
            if (e.target.value.length > 4) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByApplication(e.target.value, 1, '', admin, true,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "email") {
            this.setState({ searchByemail: e.target.value })
            if (e.target.value.length > 2) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByEmail(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "mobileNo") {
            this.setState({ searchBymobile: e.target.value })
            if (e.target.value.length > 9) {
                this.setState({
                    loadarState: true
                })
                this.props.getistByNumber(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "date") {
            this.setState({
                searchByDate: _preFormatDate(e),
                searchByDateShow: e,
            }, () => this.getLeads())
        }
        if (type != "date" && e.target.value == "") {
            this.getLeads()
        }
    }
    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1,
            pageIndexToShow: 0
        }, () => this.getLeads())

    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                pageIndexToShow: 0
            }, () => this.getLeads())
        }
    }
    componentDidUpdate() {
        const { disburseLeads, admin } = this.props;
        if (disburseLeads.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }
    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    paginationHandler(type) {
        if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                    tableMinPagination: 0,
                    tableMaxPagination: paginationNumber,
                }, () => this.getLeads())
            }
        } else if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
            }, () => this.getLeads())
        }
    }

}


const mapStateToProps = ({ disburseLeads }) => {
    return {
        disburseLeads: disburseLeads,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getDisburseLead,
        getlistByAppLoanId
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(DueContainer);