import React from 'react'
import moment from 'moment'

const MessagePage = ({ userDetail, whatsappmsg, whatsappmsgHandler, errorMsg, writeWhatsappMsg,
    allWhatsappMsg, msgTempleteClick, loaderState, tickStatus, whatsappMsgChange, approveAmount }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-5 col-xs-12">
                <h4 className=" text-center blue-text"><strong>Templates</strong></h4>
                <ol>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('ExecutiveContact')} /> &nbsp;&nbsp;
                        <span><strong>Executive contact</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('DocsUpload')} /> &nbsp;&nbsp;
                        <span><strong>Docs upload</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('eMandateRepeate')} /> &nbsp;&nbsp;
                        <span><strong>Repeat E - Mandate Razorpay</strong></span>
                    </li>
                    {userDetail.typeOfProduct == 'Loan' ?
                        <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('eMandateRepeateTechp')} /> &nbsp;&nbsp;
                            <span><strong>Repeat E - Mandate Techprocess</strong></span>
                        </li>
                        : ""}
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('eMandate')} /> &nbsp;&nbsp;
                        <span><strong>E - Mandate Razorpay</strong></span>
                    </li>
                    {userDetail.typeOfProduct == 'Loan' ?

                        <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('eMandatetechP')} /> &nbsp;&nbsp;
                            <span><strong>E - Mandate Techprocess</strong></span>
                        </li>
                        : ""}
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('eSign')} /> &nbsp;&nbsp;
                        <span><strong>E - Sign</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('eSignEmandate')} /> &nbsp;&nbsp;
                        <span><strong>E - Sign &amp; E - Mandate Razorpay</strong></span>
                    </li>
                    {userDetail.typeOfProduct == 'Loan' ?

                        <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('techprocessEmandate')} /> &nbsp;&nbsp;
                            <span><strong>E - Sign &amp; E - Mandate Techprocess</strong></span>
                        </li>
                        : ""}
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('razorpay')} /> &nbsp;&nbsp;
                        <span><strong>Bank not live with Razorpay's e-mandate</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('googleRating')} /> &nbsp;&nbsp;
                        <span><strong>Google Play Store Rating</strong></span>
                    </li>
                    {/* {approveAmount !== null && approveAmount !== '' ? */}
                        <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('reference')} /> &nbsp;&nbsp;
                            <span><strong>Three Persons Reference</strong></span>
                        </li>
                        <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('billDeskNach')} /> &nbsp;&nbsp;
                            <span><strong>E-MANDATE WITH BILLDESK</strong></span>
                        </li>
                        {/* : ""} */}
                </ol>
            </div>
            <div className="col-sm-7 col-xs-12">
                <h4 className="text-center blue-text"><strong>Whatsapp Messages</strong></h4>
                <div className="relative_position">
                    <div className="whatsapp-msg" id="whatsappMsg">
                        {loaderState == true ?
                            <div className="whatsapp-loader">
                                <div className="fa fa-spinner fa-spin fa-lg" />
                            </div>
                            : ""}
                        {allWhatsappMsg != null && allWhatsappMsg != '' && allWhatsappMsg != undefined ?
                            allWhatsappMsg.map((whatsappData, i) => {
                                return (
                                    <div key={i} className="margin-0 row">
                                        {whatsappData.fromMe == true ?
                                            <div className="whatsappmsg msg-left">
                                                <div>
                                                    {whatsappData.body.includes('https://') ?
                                                        whatsappData.body.includes('.pdf?alt') ?
                                                            <object className="area-200px" data={whatsappData.body} type="application/pdf"></object>
                                                            : <img className="area-200px" src={whatsappData.body} />
                                                        : whatsappData.body}
                                                </div>
                                                <div className="msgDate">{moment(new Date(whatsappData.date)).format('DD-MM-YYYY HH:mm')}</div>
                                                <span className="tail-container" />
                                            </div>
                                            : whatsappData.fromMe == false ?
                                                <div className="whatsappmsg msg-right">
                                                    <div>
                                                        {whatsappData.body.split("&type=")[1] == "pdf" ?
                                                            <object className="area-200px" data={whatsappData.body} type="application/pdf"></object>
                                                            : whatsappData.body.split("&type=")[1] == "img" ?
                                                                <img className="area-200px" src={whatsappData.body} />
                                                                : whatsappData.body}
                                                    </div>
                                                    <div className="msgDate">
                                                        {moment(new Date(whatsappData.date)).format('DD-MM-YYYY HH:mm')}&nbsp;&nbsp;
                                                        {tickStatus == 0 ?
                                                            <span className="font-12px">&#10003;</span>
                                                            : tickStatus == 1 ?
                                                                <span className="whatsapp-doubble-tick font-12px">&#10003;&#10003;</span>
                                                                : tickStatus == 2 ?
                                                                    <span className="whatsapp-doubble-tick font-12px blue-tick">&#10003;&#10003;</span>
                                                                    : null}
                                                    </div>
                                                    <span className="tail-container" />
                                                </div>
                                                : ""}
                                    </div>
                                )
                            })
                            : ""}
                    </div>
                </div>
                <div className="display-flex" onKeyDown={e => whatsappmsgHandler(e, 'enter')}>
                    <textarea className="whatsapp-input-container form-control" onChange={e => whatsappMsgChange(e)} value={writeWhatsappMsg}></textarea>
                    {/* <div className="whatsapp-input-container form-control" id="whatsappMsgWrite" contentEditable="true">{writeWhatsappMsg}</div> */}
                    <div className="white-bg">
                        <button className="profile-save-btn margin-0" onClick={e => whatsappmsgHandler(e, 'click')}><span className="fa fa-paper-plane" /></button>
                        <div className="text-center margin-top-20px">
                            <label htmlFor="whatsappFiles" className="input-field-label-new margin-0-auto">
                                <span className="fa fa-paperclip  margin-0"></span>
                            </label>
                            <input accept="image/png, image/jpeg, application/pdf" type="file" className="form-control display-none" id="whatsappFiles" onChange={e => whatsappmsg(e, 'file')} />
                        </div>
                        {/* <EmojiPicker onEmojiClick={e => myCallback(e)}/> */}
                    </div>
                </div>
            </div>
            <p className="text-center height-20px margin-0 red-text">{errorMsg}</p>
        </div>
    </div>
)

export default MessagePage;