import React from 'react'
import DatePicker from 'react-datepicker';

const CouponAdd = ({ merchantData, listingOfCategory, selectDate, postDataHandler,
    getDataHandler, MerchantValidationMsg, createList }) => (
        <div className="admin-coupon-details" onKeyDown={e => createList(e)}>
            <div className="container-fluid">
                <div className="row">
                    <h3 className="text-center">Add Coupon Details</h3>
                </div>
                <div className="row form-group">
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Merchant Name</label>
                        <select className="form-control" value={merchantData.merchantId} id="discMerchantName" onChange={e => getDataHandler(e, "selectDiscMerchantName")}>
                            <optgroup className="display-none">
                                <option selected="selected">Select Merchant</option>
                            </optgroup>
                            {listingOfCategory != null && listingOfCategory != '' && listingOfCategory.length>0?
                                listingOfCategory.map((listing,i) => {
                                    return (
                                        <optgroup key={i} label="Merchant List">
                                            {listing.Merchant != null && listing.Merchant.length > 0 ? listing.Merchant.map((data,i) => {
                                                return (
                                                    <option key={i} value={data.merchantid}>{data.merchantname}</option>
                                                )
                                            }) : ""}
                                        </optgroup>
                                    )

                                }) : ""}
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "merchantId" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Category Name</label>
                        <select className="form-control" value={merchantData.categoryId} id="promoMainCatName" onChange={e => getDataHandler(e, "promoMainCatName")}>
                            <optgroup className="display-none">
                                <option selected="selected">Select Category</option>
                            </optgroup>
                            {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                                listingOfCategory.map((listing, i) => {
                                    return (
                                        <optgroup key={i} label="Category List">
                                            {listing.Category != null && listing.Category.length > 0 ? listing.Category.map((data, i) => {
                                                return (
                                                    <option key={i} value={data.categoryid}>{data.category}</option>
                                                )
                                            }) : ""}
                                        </optgroup>
                                    )
                                }) : ""}
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "categoryId" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Sub-Category Name</label>
                        <select className="form-control" value={merchantData.subCatId} id="discSubCatName" onChange={e => getDataHandler(e, "selectDiscSubCatName")}>
                            <optgroup className="display-none">
                                <option selected="selected">Select Sub-Category</option>
                            </optgroup>
                            {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                                listingOfCategory.map((listing, i) => {
                                    return (
                                        <optgroup key={i} label="Sub Category List">
                                            {listing.SubCategory != null && listing.SubCategory.length > 0 ? listing.SubCategory.map((data, i) => {
                                                return (
                                                    <option value={data.subcategoryid} style={{ display: merchantData.categoryId == data.categoryid ? 'block' : 'none' }} key={i}>{data.subcategory_name}</option>
                                                )
                                            }) : ""}
                                        </optgroup>
                                    )

                                }) : ""}
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "subCatId" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Location</label>
                        <select className="form-control" value={merchantData.locationId} id="discLocationName" onChange={e => getDataHandler(e, "selectDiscLocationName")}>
                            <optgroup className="display-none">
                                <option selected="selected">Select Location</option>
                            </optgroup>
                            {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                                listingOfCategory.map((listing, i) => {
                                    return (
                                        <optgroup key={i} label="Location List">
                                            {listing.Location != null && listing.Location.length > 0 ? listing.Location.map((data, i) => {
                                                return (
                                                    <option key={i} value={data.locationid}>{data.location}</option>
                                                )
                                            }) : ""}
                                        </optgroup>
                                    )

                                }) : ""}
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "locationId" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Title</label>
                        <input type="text" className="form-control" value={merchantData.discountTitle} placeholder="Enter Discount Title" id="discountTitle" onChange={e => getDataHandler(e, "discountTitle")} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "discountTitle" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Discount(%)</label>
                        <input type="text" className="form-control" value={merchantData.discountAmount} placeholder="Enter Discount Amount" id="subCatName" onChange={e => getDataHandler(e, "discountAmount")} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "discountAmount" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Offer Timing</label>
                        <div className="form-control contentEditable" contentEditable="true" id="couponTiming" />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">See Offer Details</label>
                        <div className="form-control contentEditable" contentEditable="true" id="seeOfferDetails" />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">About this place</label>
                        <div className="form-control contentEditable" contentEditable="true" id="couponDescription" />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Term And Condition</label>
                        <div className="form-control contentEditable" contentEditable="true" id="couponTLC" />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Coupon Code</label>
                        <input type="text" className="form-control" value={merchantData.couponCode} placeholder="Enter Coupon Code" id="couponCode" onChange={e => getDataHandler(e, "couponCode")} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "discountAmount" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Expiration Date</label>
                        <DatePicker
                            className="form-control"
                            dateFormat="DD-MM-YYYY"
                            selected={selectDate}
                            value={merchantData.selectDate}
                            onChange={(e) => getDataHandler(e, "selectDate")}
                            placeholderText="Select Expiry Date"
                        />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "selectDate" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <label className="font-12px">Status</label>
                        <select className="form-control" value={merchantData.discountStatus ? 'Active' : 'Inactive'} id="discountStatus" onChange={e => getDataHandler(e, "discountStatus")}>
                            <option value="">Select Status</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "discountStatus" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <button className="profile-save-btn" id="discountAdd" onClick={e => postDataHandler()}>
                            <span className="fa fa-plus"></span>&nbsp;&nbsp;ADD
                            </button>
                    </div>
                </div>
            </div>
        </div>
    )

export default CouponAdd;