import React from 'react'
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

const CouponReporting = ({ offerDetails, updatedata, deleteCoupon, listToDisplay,
    showList, viewDetails, listingOfCategory }) => (
        <div className="admin-coupon-details">
            <div className="container-fluid">
                <div className="row">
                    <h3 className="text-center">Coupon Details</h3>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12 max-width-500px">
                        <select className="form-control" onChange={e => listToDisplay(e)}>
                            <optgroup>
                                <option value="">Select one</option>
                            </optgroup>

                            {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                                listingOfCategory.map((listing,i) => {
                                    return (
                                        <optgroup key={i} label="Merchant List">
                                            {listing.Merchant != null && listing.Merchant.length > 0 ? listing.Merchant.map((data, i) => {
                                                return (
                                                    <option key={i} value={data.merchantname}>{data.merchantname}</option>
                                                )
                                            }) : ""}
                                        </optgroup>
                                    )

                                }) : ""}
                        </select>
                    </div>
                </div>
                <div className="max-width-750px center-block">
                    {offerDetails != '' && offerDetails != null ? offerDetails.map((offer, i) => {
                        return (
                            <div key={i}>
                                {showList == '' ?
                                    <div className="row coupon-view-box relative_position" style={{ borderLeft: offer.status == false || moment(new Date()).format('DD-MM-YYYY') < moment(new Date(offer.expireon)).format('DD-MM-YYYY') ? '6px solid red' : '6px solid green' }}>
                                        <button className="close coupon-delete-btn" onClick={e => deleteCoupon(offer)} >&times;</button>
                                        <div className="col-xs-3">
                                            <p className="text-center">OfferId - {offer.couponid}</p>
                                            <div className="offer-title center-block">{offer.discount != undefined ? offer.discount : ""}%<br />OFF</div>
                                            {offer.Address != null && offer.Address != '' && offer.Address.length > 0 ?
                                                offer.Address.map((add, i) => {
                                                    return (
                                                        <h5 key={i} className="text-center">{add.address}</h5>
                                                    )
                                                }) : <h5 className="red-text text-center">No outlet mapped</h5>}
                                        </div>

                                        <div className="col-xs-9">
                                            <div className="complete-offer-detail">
                                                <span className="coupon-heads">{offer.category}  {offer.subcategory}</span>
                                                <span className="coupon-heads pull-right">{"Expire date : " + moment(new Date(offer.expireon)).format('DD-MMM-YYYY')}</span>
                                                <h4 className="blue-text">{offer.merchantname}</h4>
                                                <hr />
                                                <h5>{offer.title}</h5>
                                                {offer.description != null && offer.description != "" ?
                                                    <div>
                                                        <h6><b>Description</b></h6>
                                                        {ReactHtmlParser(offer.description)}
                                                    </div>
                                                    : ""}
                                                {offer.termscondition != null && offer.termscondition != "" ?
                                                    <div>
                                                        <h6><b>Terms &amp; Conditions</b></h6>
                                                        {ReactHtmlParser(offer.termscondition)}
                                                    </div>
                                                    : ""}
                                                <a className="coupon-view-btns" onClick={e => viewDetails(e, offer)}>{offer.totalused + ' DOWNLOADED'}</a>
                                                <a className="coupon-view-btns" onClick={e => updatedata(e, offer)}>UPDATE</a>
                                            </div>
                                        </div>
                                    </div>
                                    : showList == offer.merchantname ?
                                        <div className="row coupon-view-box relative_position" style={{ borderLeft: offer.status == false || moment(new Date()).format('DD-MM-YYYY') < moment(new Date(offer.expireon)).format('DD-MM-YYYY') ? '6px solid red' : '6px solid green' }}>
                                            <button className="close coupon-delete-btn" onClick={e => deleteCoupon(offer)} >&times;</button>
                                            <div className="col-xs-3">
                                                <div className="offer-title center-block">{offer.discount != undefined ? offer.discount : ""}%<br />OFF</div>
                                                {offer.Address.length > 0 ? offer.Address.map((add,i) => {
                                                    return (
                                                        <h5 key={i} className="text-center">{add.address + " - " + add.outlet}</h5>
                                                    )
                                                }) : ""}
                                            </div>

                                            <div className="col-xs-9">
                                                <div className="complete-offer-detail">
                                                    <span className="coupon-heads">{offer.category} {offer.subcategory}</span>
                                                    <span className="coupon-heads pull-right">{"Expire date : " + moment(new Date(offer.expireon)).format('DD-MMM-YYYY')}</span>
                                                    <h4 className="blue-text">{offer.merchantname}</h4>
                                                    <hr />
                                                    <h5>{offer.title}</h5>
                                                    {offer.description != null && offer.description != "" ?
                                                        <div>
                                                            <h6><b>Description</b></h6>
                                                            <h5 className="">{ReactHtmlParser(offer.description)}</h5>
                                                        </div>
                                                        : ""}
                                                    {offer.termscondition != null && offer.termscondition != "" ?
                                                        <div>
                                                            <h6><b>Terms &amp; Conditions</b></h6>
                                                            <h5 className="">{ReactHtmlParser(offer.termscondition)}</h5>
                                                        </div>
                                                        : ""}
                                                    <a className="coupon-view-btns" onClick={e => viewDetails(e, offer)}>{offer.totalused + ' DOWNLOADED'}</a>
                                                    <a className="pointer text-center pull-right" onClick={e => updatedata(e, offer)}>UPDATE</a>
                                                </div>
                                            </div>
                                        </div> : ""}
                            </div>
                        )
                    }) : <h3 className="text-center">No Offer Available</h3>}
                </div>
            </div>
        </div>
    )
export default CouponReporting;