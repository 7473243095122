import { APIS } from '../../../utils/api-factory'
import localStorageUtil from '../../../utils/localStorageUtil.js'
import { POST_HEADER } from '../../../utils/apiHeaders'

export const getUserDetail = userDetail => {
  return dispatch => {
    dispatch({ type: 'USER_DETAIL', userDetail: userDetail })
  }
}
export const getUserDetailApi = () => {
  let userId = localStorageUtil.getFromLocalStorage('userId')
  return dispatch => {
    fetch(APIS.USER_DETAIL_BY_ID + userId)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'USER_DETAIL', userDetail: json })
        }
      })
  }
}

export const getUserNotification = () => {
  let userId = localStorageUtil.getFromLocalStorage('userId')
  return dispatch => {
    fetch(APIS.GET_NOTIFICATION + userId)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'USER_NOTIFICATION', notification: json })
        }
      })
  }
}

export const logoutFromWebsite = () => {
  return dispatch => {
    dispatch(
      { type: 'USER_NOTIFICATION', notification: [] },
      { type: 'USER_DETAIL', userDetail: json }
    )
  }
}

export const fetchTransaction = (
  userId,
  loanId,
  transactionId,
  prepayAmount,
  prepayDate,
  prepayMode,
  callBack
) => {
  var data = {
    userId: userId,
    loanId: loanId,
    transactionId: transactionId,
    totalAmount: '',
    prepayAmount: prepayAmount,
    prepayDate: prepayDate,
    paymentMode: prepayMode,
    empId: 'user'
  }
  fetch(APIS.SAVE_TRANSACTION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const saveFollowUpStatus = (loanId, status, callBack) => {
  fetch(
    APIS.SAVE_FOLLOW_UP_STATUS + loanId + '&status=' + status + '&empId=user'
  )
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}

export const getTransaction = (userId, callBack) => {
  fetch(APIS.GET_TRANSACTIONS + userId)
    .then(json => json.json())
    .then(res => {
      callBack(res)
    })
}

export const emiDetails = (allDetail, callBack) => {
  fetch(APIS.EMI_DETAIS_GET + allDetail.loanId)
    .then(res => res.json())
    .then(json => {
      if (json.responseCode == 0) {
      } else {
        callBack(json)
      }
    })
}

export const bulletToEmiConverApi = (loanData, day, month, callBack) => {
  var data = {
    salaryday: day,
    loanId: loanData.loanId,
    noOfMonths: month,
    loanAmount: loanData.approveAmount,
    disbursedDate: loanData.disburseDate,
    loanApplyDate: loanData.loanApplyDate
  }
  fetch(APIS.BULLET_TO_EMI_CONVERSION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const saveConvertedEmiApi = (loanData, day, month, callBack) => {
  var data = {
    salaryday: day,
    loanID: loanData.loanId,
    userID: loanData.userId,
    noOfMonths: month,
    loanAmount: loanData.approveAmount,
    disbursedDate: loanData.disburseDate,
    loanApplyDate: loanData.loanApplyDate
  }
  fetch(APIS.SAVE_CONVERTED_EMI, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getLoanDetailByUserId = (userId, callBack) => {
  // console.log(APIS.GET_LOAN_DETAIL_BY_USERID + userId + '&type=' + 'Loan')
  fetch(APIS.GET_LOAN_DETAIL_BY_USERID + userId + '&type=' + 'All')
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getLoanDetailByUserIdAllData = callBack => {
  let userId = localStorageUtil.getFromLocalStorage('userId')

  fetch(APIS.GET_LOAN_DETAIL_BY_USERID + userId + '&type=' + 'All')
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getloanDetailByUserId = (userId,callBack) => {

  fetch(APIS.GET_LOAN_DETAIL_BY_USERID + userId + '&type=' + 'All')
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getInstallments = (amount, salaryday, callBack) => {
  fetch(APIS.GET_MONTHLY_EMI + '/' + amount + '/' + '3' + '/' + salaryday)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const saveENachDeactivation = (
  userId,
  loanId,
  callBack
) => {
  var data = {
    userId: userId,
    loanId: loanId,
  }
  fetch(APIS.ENACH_DEACTIVATION_BY_USERID_AND_LOANID, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const deactivateNachByLoanId = (loanId,  callBack) => {
  fetch(APIS.GET_ENACH_DEACTIVATION_BY_LOANID + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getTransactionVyLoanId = (userId, callBack) => {
  fetch(APIS.GET_TXN_BY_LOANID + userId)
    .then(json => json.json())
    .then(res => {
      callBack(res)
    })
}