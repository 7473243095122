import React from 'react'
import Ui from '../../../presentationals/Admin/Tea Garden/ListOfAmbassador.component'
import { getAllTeaAmbassador } from './actioncreator'
import Loader from '../../../presentationals/Loader/Loader.component'
class AllAmbasador extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            noOfEntry: 20,
            date: '',
            mobileNo: '',
            email: '',
            allAmbasadorData: '',
            loaderState: false
        }
    }
    getList() {
        this.setState({ loaderState: true })
        if (this.state.noOfEntry === '' || this.state.noOfEntry === 0) {
            this.setState({ noOfEntry: 20 },()=>{
                getAllTeaAmbassador(this.state.pageNumber, this.state.noOfEntry, this.state.date, this.state.mobileNo, this.state.email, callBack => {
                    // console.log(callBack)
                    this.setState({ allAmbasadorData: callBack, loaderState: false })
                })
            })
        }else{
        getAllTeaAmbassador(this.state.pageNumber, this.state.noOfEntry, this.state.date, this.state.mobileNo, this.state.email, callBack => {
            // console.log(callBack)
            this.setState({ allAmbasadorData: callBack, loaderState: false })
        })
    }
    }
    componentDidMount() {
        this.getList()
    }
    filterHandler(e, type) {
        if (type === 'number') {

            if ((e.target.value).length === 10) {
                this.setState({ mobileNo: e.target.value }, () => this.getList())
            }
            if (e.target.value === '') {
                this.setState({ mobileNo: '' }, () => this.getList())
            }
        }
        if (type === 'email') {
            if ((e.target.value).includes('@')) {
                this.setState({ email: e.target.value }, () =>
                    this.getList())
            }
            if (e.target.value === '') {
                this.setState({ email: '' }, () => this.getList())
            }
        }
        if (type === 'date') {
            this.setState({ date: (e.target.value).split("-").reverse().join("-") }, () => this.getList())
            if (e.target.value === '') {
                this.setState({ date: '' }, () => this.getList())
            }
            // if ((e.target.value).length === 10) {

            // }
        }


    }
    paginationHadler(type) {
        if (type === 'next') {
            this.setState({ pageNumber: this.state.pageNumber + 1 }, () => this.getList())
        }
        if (type === 'back') {
            if (this.state.pageNumber > 1) {
                this.setState({ pageNumber: this.state.pageNumber - 1 }, () => this.getList())
            }
        }

    }
    entryHandler(e) {
        this.setState({ noOfEntry: e.target.value }, () => this.getList())
    }
    render() {
        return (
            <div>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <Ui
                    entryHandler={this.entryHandler.bind(this)}
                    filterHandler={this.filterHandler.bind(this)}
                    paginationHadler={this.paginationHadler.bind(this)}
                    allAmbasadorData={this.state.allAmbasadorData} />
            </div>
        )
    }
}
export default AllAmbasador