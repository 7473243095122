import { APIS } from '../../../../../utils/api-factory';
import { POST_HEADER } from '../../../../../utils/apiHeaders'

export const salaryDateDetail = (allDetail, date, callBack) => {
    var data = {
        loanID: allDetail.loanId,
        loanAmount: allDetail.loanAmount,
        noOfMonths: 3,
        salaryDate: date
    }
    fetch(APIS.EMI_DETAIS_SAVE, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)

    }).then(res => res.text())
        .then(json => {
            callBack(json);
        })
}

export const emiDetails = (allDetail, callBack) => {
    fetch(APIS.EMI_DETAIS_GET + allDetail.loanId)
        .then(res =>
            res.json())
        .then(json => {
            if (json.responseCode == 0) {
            } else {
                callBack(json)

            }
        })
        .catch(error => {
            callBack("no data");
        })
}
export const emiDetailsHistory = (userId, callBack) => {
    fetch(APIS.GET_EMI_HISTORY + userId)
        .then(res =>
            res.json())
        .then(json => {
            callBack(json)

        })
        .catch(error => {
            callBack("no data");
        })
}
export const giftCardEmiDetails = (userDetail, callBack) => {
    fetch(APIS.GET_GIFTCARD_EMI + userDetail.giftCardId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(error => {

            callBack("no data");
        })
}
export const giftCardEmiDetailsHistory = (userId, callBack) => {
    fetch(APIS.GET_EMI_HISTORY_GIFTCARD + userId)
        // .then(res=>console.log(res))
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(error => {
            callBack("no data");
        })

}

export const saveEmi = (allDetail, date, empID, PaidData, emiPaidEmiId, callBack) => {
    
    var data = {
        emiId: emiPaidEmiId,
        loanID: allDetail.loanId,
        emiDate: date,
        empID: empID,
        paidAmount: PaidData.emiPaidAmountState,
        paidDate: PaidData.emiPaidDateToSend,
        flag: PaidData.flag,
        chequeBounceAmount: PaidData.chequeBounceAmount,
        enachBounceAmount: PaidData.nachBounceAmount,
        processingFees: PaidData.processingFees,
        interestAmount: PaidData.interestAmount,
        penaltyAmount: PaidData.penaltyAmount,
        loanApproveAmount: PaidData.loanApproveAmount,
        totalPayableAmount: PaidData.totalPayableAmount
    }
    fetch(APIS.EMI_PAID_SAVED, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)

    }).then(res => res.text())
        .then(json => {
            callBack(json);
        })
}

export const saveEmiGiftCard = (allDetail, date, empID, PaidData, emiPaidEmiId, callBack) => {
    var data = {
        emiId: emiPaidEmiId,
        giftCardId: allDetail.giftCardId,
        emiDate: date,
        empID: empID,
        paidAmount: PaidData.emiPaidAmountState,
        paidDate: PaidData.emiPaidDateToSend,
        flag: PaidData.flag,
        chequeBounceAmount: PaidData.chequeBounceAmount,
        enachBounceAmount: PaidData.nachBounceAmount,
        processingFees: PaidData.processingFees,
        interestAmount: PaidData.interestAmount,
        penaltyAmount: PaidData.penaltyAmount,
        loanApproveAmount: PaidData.loanApproveAmount,
        totalPayableAmount: PaidData.totalPayableAmount
    }
    fetch(APIS.EMI_PAID_SAVED_GIFT_CARD, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)

    }).then(res => res.text())
        .then(json => {
            callBack(json);
        })
}


export const emiDetailsCards = (allDetail, callBack) => {
    fetch(APIS.EMI_DETAIS_GET_CARDS + allDetail.loanId)
        .then(res =>
            res.json())
        .then(json => {
            if (json.responseCode == 0) {
            } else {
                callBack(json)

            }
        })
        .catch(error => {
            callBack("no data");
        })
}
export const CardsEmiDetailsHistory = (userId, callBack) => {
    fetch(APIS.GET_EMI_HISTORY_CARDS + userId)
        // .then(res=>console.log(res))
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(error => {
            callBack("no data");
        })

}