import React from 'react';




const ConfigUi = ({ sendHandler, MSG }) => (
    <div className="container-fluid " >
        {/* <h1 className="text-center text-blue"><u>pH Scoring</u></h1> */}
        <br />
        <br /><br />
        <div className="row form-group">
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Bank Statement</label>
                <input type="text" className="form-control" />
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Mobile</label>
                <input type="text" className="form-control" />
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Location</label>
                <input type="text" className="form-control" />
            </div>
        </div>
        <div className="row form-group">
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Social Network</label>
                <input type="text" className="form-control" />
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Psychometric</label>
                <input type="text" className="form-control" />
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Similar Profile</label>
                <input type="text" className="form-control" />
            </div>
        </div>
        <div className="row text-center">
            <div className="col-xs-12">
                <h4 className="text-center">{MSG}</h4>
                <button className="profile-save-btn" onClick={(e) => sendHandler()}>
                    <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                </button>
                <div className="row from-group">
                    <div className="col-xs-12">
                        <h4 className="text-center"></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default ConfigUi;
