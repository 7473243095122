import React from 'react'
import {
  salaryDateDetail,
  emiDetailsCards,
  saveEmi,
  giftCardEmiDetails,
  CardsEmiDetailsHistory,
  saveEmiGiftCard,
  emiDetails
} from './EmiActionCreator.component'
import UserEmi from '../../../../presentationals/Admin/Emi/Emi.component'
import { _formatDateInDash, _preFormatDate } from '../../../../../utils/validation'
import { STRING } from '../../../../../utils/Constant'
import Popup from '../../../../presentationals/Popup/Popup.component'
import EmiPopup from '../../../../presentationals/Admin/Emi/EmiDialoge.popup'
import EmiHis from '../../../../presentationals/Admin/Emi/EmiHistory.component'
import moment from 'moment'
import { APIS } from '../../../../../utils/api-factory'
var sortJsonArray = require('sort-json-array')


class Emi extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      historyLoader: false,
      emiLoader: false,
      confirmationPopup: false,
      confirmationText: '',
      popupState: false,
      popupStatus: '',
      userSalaryDate: '',
      salaryDateforDatePicker: '',
      allDetail: this.props.allDetail,
      emiDetail: [],
      emiDetailHistory: [],
      errorMsg: '',
      emiPayDate: '',
      emiPaidStatus: '',
      emiPaidEmiId: '',
      selectedValue: '',
      allTransactions: '',
      PaidData: {
        flag: false,
        chequeBounceAmount: '',
        nachBounceAmount: '',
        processingFees: '',
        interestAmount: '',
        penaltyAmount: '',
        loanApproveAmount: '',
        emiPaidAmountState: '',
        totalPayableAmount: '',
        emiPaidDate: new Date(),
        emiPaidDateToSend: _formatDateInDash(new Date())
      }
    }
  }

  // componentWillMount() {

  //     if(this.props.userDetails.typeOfProduct!= "Gift Card")
  //     {this.getEmiDetail()
  //     this.getEmiHistory()
  //     }
  // }

  getEmiDetail () {
    const { userDetails, allDetail, userDetail } = this.props

    this.setState({ emiDetail: [], emiLoader: true })
    emiDetailsCards(userDetail, callback => {
      if (callback != 'no data') {
        this.setState({
          emiDetail: callback,
          emiLoader: false
        })
      } else {
        this.setState({ emiDetail: [], emiLoader: false })
      }
    })
  }

  getEmiHistory () {
    const { userDetail } = this.props

    this.setState({ emiDetailHistory: [], historyLoader: true })
    fetch(APIS.GET_EMI_HISTORY_CARDS + userDetail.userId)
      .then(res => res.json())
      .then(json => {
        this.setState({
          emiDetailHistory: json,
          selectedValue: Object.keys(json)[0],
          historyLoader: false
        })
      })
  }

  getPaymentTransaction (emi, date) {
 
    const {userDetail}=this.props
    if(userDetail.typeOfProduct == 'Loan'){
    let PaidData = Object.assign({}, this.state.PaidData)
    fetch(
      APIS.GET_PAYMENT_TRANSACTION +
        this.state.allDetail.loanId +
        '&emiId=' +
        emi +
        '&date=' +
        date
    )
      .then(res => res.json())
      .then(res => {
        PaidData.flag = true
        PaidData.chequeBounceAmount =
          res.chequeBounceAmount != null ? res.chequeBounceAmount : ''
        PaidData.nachBounceAmount =
          res.enachBounceAmount != null ? res.enachBounceAmount : ''
        PaidData.processingFees =
          res.processingFees != null ? res.processingFees : ''
        PaidData.interestAmount =
          res.interestAmount != null ? res.interestAmount : ''
        PaidData.penaltyAmount =
          res.penaltyAmount != null ? res.penaltyAmount : ''
        PaidData.loanApproveAmount =
          res.loanApproveAmount != null ? res.loanApproveAmount : ''
        PaidData.emiPaidAmountState =
          res.totalAmountPayable != null ? res.totalAmountPayable : ''
        PaidData.totalPayableAmount =
          res.totalAmountPayable != null ? res.totalAmountPayable : ''
        PaidData.emiPaidDateToSend = date
        this.setState({
          PaidData
        })
      })
    }else if(userDetail.typeOfProduct=='Gift Card'){
      let PaidData = Object.assign({}, this.state.PaidData)
      fetch(
        APIS.GET_SINGLE_EMI_BREAK_UP +
          userDetail.giftCardId +
          '&emiId=' +
          emi +
          '&date=' +
          date
      )
        .then(res => res.json())
        .then(res => {
          PaidData.flag = true
          PaidData.chequeBounceAmount =
            res.chequeBounceAmount != null ? res.chequeBounceAmount : ''
          PaidData.nachBounceAmount =
            res.enachBounceAmount != null ? res.enachBounceAmount : ''
          PaidData.processingFees =
            res.processingFees != null ? res.processingFees : ''
          PaidData.interestAmount =
            res.interestAmount != null ? res.interestAmount : ''
          PaidData.penaltyAmount =
            res.penaltyAmount != null ? res.penaltyAmount : ''
          PaidData.loanApproveAmount =
            res.loanApproveAmount != null ? res.loanApproveAmount : ''
          PaidData.emiPaidAmountState =
            res.totalAmountPayable != null ? res.totalAmountPayable : ''
          PaidData.totalPayableAmount =
            res.totalAmountPayable != null ? res.totalAmountPayable : ''
          PaidData.emiPaidDateToSend = date
          this.setState({
            PaidData
          })
        })
    }
  }
  

  render () {
    const { admin,userDetail } = this.props
    // console.log('yesy')
    return (
      <div className='container-fluid'>
        <div className='row'>
          <p className='height-20px text-center margin-0'>
            {this.state.errorMsg}
          </p>
          {this.state.popupState == true ? (
            <Popup
              closePopup={this.closePopup.bind(this)}
              popupStatus={this.state.popupStatus}
            />
          ) : (
            ''
          )}
          <div className='col-xs-12'>
            <UserEmi
            userDetail={userDetail}
              emiLoader={this.state.emiLoader}
              emiDetail={this.state.emiDetail}
              emiPaidChange={this.emiPaidChange.bind(this)}
              emiPaidHandler={this.emiPaidHandler.bind(this)}
              admin={admin}
            />
          </div>
          <div className='col-xs-12'>
            <EmiHis
             userDetail={userDetail}
              historyLoader={this.state.historyLoader}
              emiDetail={this.state.emiDetailHistory}
              applicationChange={this.applicationChange.bind(this)}
              selectedValue={this.state.selectedValue}
            />
          </div>
          {this.state.confirmationPopup == true ? (
            <EmiPopup
              emiAmountChange={this.emiAmountChange.bind(this)}
              emiAmountHandler={this.emiAmountHandler.bind(this)}
              closeEmiPopup={this.closeEmiPopup.bind(this)}
              PaidData={this.state.PaidData}
            />
          ) : null}
        </div>
      </div>
    )
  }

  emiPaidChange (e) {
    this.setState({ emiPaidStatus: e.target.value })
  }

  closeEmiPopup () {
    this.setState({ confirmationPopup: false })
  }

  componentDidMount () {
  
    const { userDetails, allDetail, userDetail } = this.props
    if (userDetail != null) {
      this.setState({
        salaryDateforDatePicker: _formatDateInDash(new Date())
      })
    }

    if (userDetail.typeOfProduct == 'Loan') {
      this.getEmiDetail()
      this.getEmiHistory()
    } else if (userDetail.typeOfProduct == 'Gift Card') {
      this.getGiftCardEmi()
      this.getGiftCardEmiHistory()
      //   this.getEmiDetail()
    }
  }
  getGiftCardEmi () {
    const { userDetail } = this.props
    this.setState({ emiDetail: [], emiLoader: true })
    giftCardEmiDetails(userDetail, callback => {
      if (callback != 'no data') {
        this.setState({
          emiDetail: callback,
          emiLoader: false
        })
      } else {
        this.setState({ emiDetail: [], emiLoader: false })
      }
    })
  }
  getGiftCardEmiHistory () {
    const { userDetails, allDetail, userDetail } = this.props

    this.setState({ emiDetailHistory: [], historyLoader: true })
    CardsEmiDetailsHistory(userDetail.userId, callback => {
      if (callback != 'no data') {
        this.setState({
          emiDetailHistory: callback,
          selectedValue: Object.keys(callback)[0],
          historyLoader: false
        })
      } else {
        this.setState({
          historyLoader: false
        })
      }
    })
  }

  emiAmountChange (e, type) {
    let PaidData = Object.assign({}, this.state.PaidData)
    if (type == 'emiAmount') {
      PaidData.emiPaidAmountState = e.target.value
    } else if (type == 'emiPaidDate') {
      this.getPaymentTransaction(this.state.emiPaidEmiId, _formatDateInDash(e))
      PaidData.emiPaidDate = e
      PaidData.emiPaidDateToSend = _formatDateInDash(e)
    } else if (type == 'intrestAmount') {
      PaidData.interestAmount = e.target.value
    } else if (type == 'processingFees') {
      PaidData.processingFees = e.target.value
    } else if (type == 'chequeBounceAmount') {
      PaidData.chequeBounceAmount = e.target.value
    } else if (type == 'nachBounceAmount') {
      PaidData.nachBounceAmount = e.target.value
    } else if (type == 'penaltyAmount') {
      PaidData.penaltyAmount = e.target.value
    }
    this.setState({
      PaidData
    })
  }

  emiAmountHandler () {
    const { allDetail, admin,userDetail } = this.props
    if(userDetail.typeOfProduct == 'Loan'){
    saveEmi(
      allDetail,
      this.state.emiPayDate,
      admin.emailId,
      this.state.PaidData,
      this.state.emiPaidEmiId,
      callback => {
        if (callback == 'success') {
          this.setState(
            {
              emiDetail: [],
              confirmationPopup: false,
              popupState: true,
              popupStatus: 'Data Saved Successfully'
            },
            () => this.props.getData('yes')
          )
          this.removePopup()
          emiDetails(allDetail, callback => {
            this.setState({ emiDetail: callback })
          })
        } else {
          this.setState({
            confirmationPopup: false,
            popupState: true,
            popupStatus: 'Data not Saved! Please try again'
          })
          this.removePopup()
        }
      }
    )
    }
    else if(userDetail.typeOfProduct =='Gift Card'){
      saveEmiGiftCard(
        userDetail,
        this.state.emiPayDate,
        admin.emailId,
        this.state.PaidData,
        this.state.emiPaidEmiId,
        callback => {
          if (callback == 'success') {
            this.setState(
              {
                emiDetail: [],
                confirmationPopup: false,
                popupState: true,
                popupStatus: 'Data Saved Successfully'
              },
              () => this.props.getData('yes')
            )
            this.removePopup()
            giftCardEmiDetails(userDetail, callback => {
              this.setState({ emiDetail: callback })
            })
          } else {
            this.setState({
              confirmationPopup: false,
              popupState: true,
              popupStatus: 'Data not Saved! Please try again'
            })
            this.removePopup()
          }
        }
      )
    }
  }

  // cancelLoanConfirm(conType) {
  //     const { allDetail } = this.props;
  //     if (conType == 'yes') {
  //         saveEmi(allDetail, this.state.emiPayDate, callback => {
  //             if (callback == "success") {
  //                 this.setState({
  //                     emiDetail: [],
  //                     confirmationPopup: false,
  //                     popupState: true,
  //                     popupStatus: "Data Saved Successfully"
  //                 })
  //                 this.removePopup()
  //                 emiDetails(allDetail, callback => {
  //                     this.setState({ emiDetail: callback })
  //                 })
  //             } else {
  //                 this.setState({
  //                     confirmationPopup: false,
  //                     popupState: true,
  //                     popupStatus: "Data not Saved! Please try again"
  //                 })
  //                 this.removePopup()
  //             }
  //         })
  //     } else if (conType == 'no') {
  //         this.setState({ confirmationPopup: false })
  //     }
  // }

  emiPaidHandler (data) {
    const { allDetail, admin, userDetail } = this.props

    this.setState({ emiPaidEmiId: data.emiId })
    this.getPaymentTransaction(
      data.emiId,
      this.state.PaidData.emiPaidDateToSend
    )
    if (this.state.emiPaidStatus != '') {
      if (this.state.emiPaidStatus == STRING.PAID) {
        this.setState({
          emiPayDate: moment(data.PAYDATE, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          confirmationPopup: true,
          confirmationText: 'Are you sure to want to paid this Emi?',
          emiPaidAmountState: data.AMOUNT
        })
      } else {
        if (userDetail.typeOfProduct == 'Loan') {
          fetch(
            APIS.CHANAGE_EMI_STATUS +
              allDetail.userId +
              '&loanId=' +
              allDetail.loanId +
              '&emiId=' +
              data.emiId +
              '&status=' +
              this.state.emiPaidStatus +
              '&empId=' +
              admin.emailId
          )
            .then(res => res.text())
            .then(res => {
              if (res == 'success') {
                this.setState(
                  {
                    popupState: true,
                    popupStatus: 'Data Saved Successfully'
                  },
                  () => this.props.getData('yes')
                )
                this.removePopup()
              } else {
                this.setState({
                  popupState: true,
                  popupStatus: 'Please try again'
                })
                this.removePopup()
              }
            })
        } else if (userDetail.typeOfProduct == 'Gift Card') {
          fetch(
            APIS.CHANGE_EMI_STATUS_GIFT_CARD +
              allDetail.userId +
              '&giftCardId=' +
              userDetail.giftCardId +
              '&emiId=' +
              data.emiId +
              '&status=' +
              this.state.emiPaidStatus +
              '&empId=' +
              admin.emailId
          )
            .then(res => res.text())
            .then(res => {
              if (res == 'success') {
                this.setState(
                  {
                    popupState: true,
                    popupStatus: 'Data Saved Successfully'
                  },
                  () => this.props.getData('yes')
                )
                this.removePopup()
              } else {
                this.setState({
                  popupState: true,
                  popupStatus: 'Please try again'
                })
                this.removePopup()
              }
            })
        }
      }
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please Select Status'
      })
      this.removePopup()
    }
  }

  salaryDateHandler () {
    const { allDetail } = this.props
    if (this.state.emiDetail.length > 0) {
      this.setState({ errorMsg: 'EMI Already Converted' })
    } else {
      if (this.state.userSalaryDate != '') {
        if (allDetail.statusId == '105' || allDetail.statusId == '107') {
          salaryDateDetail(allDetail, this.state.userSalaryDate, callback => {
            if (callback == 'success') {
              this.setState(
                { popupState: true, popupStatus: 'Data Saved Successfully' },
                () => this.props.getData('yes')
              )
              this.removePopup()
            }
          })
        } else {
          this.setState({
            popupState: true,
            popupStatus: 'You can not saved date before loan approve'
          })
          this.removePopup()
        }
      } else {
        this.setState({ errorMsg: 'please select salary date' })
      }
    }
  }

  salaryDate (e) {
    if (e != null) {
      if (moment(new Date(e)) >= moment(new Date())) {
        this.setState({
          salaryDateforDatePicker: e,
          errorMsg: '',
          userSalaryDate: _formatDateInDash(e)
        })
      } else {
        this.setState({ errorMsg: 'You can not select back date' })
      }
    } else {
      this.setState({
        salaryDateforDatePicker: '',
        errorMsg: '',
        userSalaryDate: ''
      })
    }
  }

  removePopup () {
    setTimeout(
      function () {
        this.setState({ popupState: false })
      }.bind(this),
      5000
    )
  }

  closePopup () {
    this.setState({ popupState: false })
  }
  applicationChange (e) {
    this.setState({
      selectedValue: e.target.value
    })
  }
}
export default Emi
