import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoanApply from '../../../presentationals/Admin/ApplicationLeads/ImportantLeads.component';
import {
    getImportantLead, getistByNumber, getlistByEmail, getlistByApplication, getUserDetailByLoanId
} from '../AdminActionCreator.component';
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { ROLE, STRING } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import MainDetailsPopup from '../CustomerPages/MainDetailPopup.container'
import Loder from '../../../presentationals/Loader/Loader.component'

class LoanApplyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userState: true,
            viewState: false,
            editState: false,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.SELECT_ALL,
            forwordPageState: 1,
            loadarState: false,
            viewDetailPopupState: false,
            viewPopupDetail: '',
            logsUserId: '',
            downloadExcelState: '',
            actionButtonState: true,
            FilteredData: [],
            pageIndexToShow: 0,
            loadarState: false,
            searchByAppNo: '',
            searchByemail: '',
            searchBymobile: '',
            searchByDate: '',
            searchByDateShow: '',
            loaderState: false,
            followUpStatus: 'Important',


        }
    }
    getData() {
    }
    getLeads() {
        this.setState({
            loadarState: true
        })
        this.props.getImportantLead(this.state.followUpStatus, this.state.forwordPageState, this.props.admin.emailId, this.state.searchByDate,
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            });
    }
    statusHandler(e) {
        this.setState({
            followUpStatus: e.target.value,

        }, () => this.getLeads())
    }


    componentDidMount() {
        this.getLeads()
    }

    render() {
        const { importantLeads, admin, allStatus } = this.props;
        return (
            <div className="full-height">
                <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-10'></div>
                    <div className='col-sm-2'>
                        {admin != undefined && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
                            <div>
                                <select className="form-control" onChange={e => this.props.leadChangeHandler(e)} value={this.state.leadsViewState}>
                                    <option value="Advance">Loan Approval Leads</option>

                                    <option value="Important Leads">Important Leads</option>

                                </select>
                            </div>
                            : null}
                            </div>
                    </div>
                </div>
                {this.state.loaderState ?
                    <Loder />
                    : ""}
                {this.state.userState == true ?
                    <LoanApply
                        allStatus={allStatus}
                        followUpStatus={this.state.followUpStatus}
                        statusHandler={this.statusHandler.bind(this)}
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        allUserDetail={importantLeads}
                        forwordpage={this.forwordpage.bind(this)}
                        backpage={this.backpage.bind(this)}
                        dataFilter={this.dataFilter.bind(this)}
                        editUserDetailHandler={this.editUserDetailHandler.bind(this)}
                        userViewHandler={this.userViewHandler.bind(this)}
                        admin={admin}
                        loadarState={this.state.loadarState}
                        refreshpage={this.refreshpage.bind(this)}
                        downloadExcelState={this.state.downloadExcelState}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        searchByAppNo={this.state.searchByAppNo}
                        searchByemail={this.state.searchByemail}
                        searchBymobile={this.state.searchBymobile}
                        searchByDate={this.state.searchByDate}
                        searchByDateShow={this.state.searchByDateShow}
                    /> : ""}
                {this.state.viewState == true ?
                    <UserDetail
                        getData={this.getData.bind(this)}
                        allDetail={this.state.allDetail}
                        userDetail={this.state.userDetail}
                        admin={admin}
                        userDetails={this.state.userDetail}

                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                    />
                    : ""}
                {this.state.viewDetailPopupState == true ?
                    <MainDetailsPopup
                        getviewDetailPopupState={this.getviewDetailPopupState.bind(this)}
                        admin={admin}
                        getData={this.getData.bind(this)}
                        userDetails={this.state.userDetail}

                        allDetail={this.state.viewPopupDetail}
                    />
                    : ""}
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    refreshpage() {
        this.getLeads()
    }

    dataFilter(e, type) {
        const { admin } = this.props
        if (type == "applicationNo") {
            this.setState({ searchByAppNo: e.target.value })
            if (e.target.value.length > 4) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByApplication(e.target.value, 1, '', admin, true,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "email") {
            this.setState({ searchByemail: e.target.value })
            if (e.target.value.length > 2) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByEmail(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "mobileNo") {
            this.setState({ searchBymobile: e.target.value })
            if (e.target.value.length > 9) {
                this.setState({
                    loadarState: true
                })
                this.props.getistByNumber(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "date") {
            this.setState({
                searchByDate: _preFormatDate(e),
                searchByDateShow: e
            }, () => this.getLeads())
        }
        if (type != "date" && e.target.value == "") {
            this.getLeads()
        }
    }
    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1,
            pageIndexToShow: 0
        }, () => this.getLeads())

    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                loadarState: true,
                pageIndexToShow: 0
            }, () => this.getLeads())
        }
    }

    componentDidUpdate() {
        const { importantLeads } = this.props;
        if (importantLeads.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }

    }

    backHandler() {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false,
            editState: false
        })
        if (this.state.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.searchByAppNo, 1, '', admin, true,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchByemail != '') {
            this.props.getlistByEmail(this.state.searchByemail, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchBymobile != '') {
            this.props.getistByNumber(this.state.searchBymobile, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else {
            this.getLeads()
        }
    }
    editUserDetailHandler(allDetail) {
        this.setState({ loaderState: true })
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    editState: true,
                    userState: false,
                    viewState: false,
                    allDetail: callBack,
                    userDetail: allDetail,
                    loaderState: false
                })
            })
    }
    userDetailHandler(allDetail) {

        this.setState({ loaderState: true })

        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    editState: false,
                    allDetail: callBack,
                    userDetail: allDetail,
                    loaderState: false

                })
            })
    }
    userViewHandler(allDetail) {
        this.setState({ loaderState: true })

        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    viewDetailPopupState: true,
                    viewPopupDetail: callBack,
                    userDetail: allDetail,
                    loaderState: false

                })
            })
    }
    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

}

const mapStateToProps = ({ allUserDetail, importantLeads }) => {
    return {
        allUserDetail: allUserDetail,
        importantLeads: importantLeads,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getImportantLead
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanApplyContainer);