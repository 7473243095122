import React from 'react'

const ActivityPopup = ({ activityPopupViewHandler, activityPopupData }) => (
    <div>
        <div className="background-blur" onClick={e => activityPopupViewHandler()}></div>
        <div className="popup-main small-popup overflow-auto container-fluid dbl-border">
            <div className="row">
                <div className="col-xs-10">
                    <p>Date:- {activityPopupData.date}</p>
                </div>
                <div className="col-xs-2">
                    <button className="close" onClick={e => activityPopupViewHandler()}>&times;</button>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <p>From:- {activityPopupData.from}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <p>To:- {activityPopupData.to}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <p>Name:- {activityPopupData.name}</p>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <p>Category:- {activityPopupData.category}</p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-xs-12">
                    Subject:- {activityPopupData.subject}
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-xs-12 word-break">
                    {activityPopupData.body.split('>')}
                </div>
            </div>
        </div>
    </div>
)

export default ActivityPopup;