import React, { Component } from 'react'
import RawMsgUi from '../../../presentationals/Admin/CustomerPages/RawMsg.component'
import Loader from '../../../presentationals/Loader/DotLoader.component'
import { APIS } from '../../../../utils/api-factory'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

let paginationNumber = 5;

class RawMsg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rawMsgData: '',
            FilteredData: [],
            pageIndexToShow: 0,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            selectedFilter: '',
            rawMsgDataBackUp: '',
            loaderState: false,
            currentPage: 1

        }
    }
    // handlePageChange(e, value) {

    //     this.setState({ currentPage: value })
    //     const newMinPagination = (value - 1) * this.state.tableMinPagination;
    //     const newMaxPagination = value * this.state.tableMaxPagination;
    //     this.setState({tableMinPagination:newMinPagination,tableMaxPagination:newMaxPagination})
    // };
     handlePageChange(e, value) {
        this.setState({ currentPage: value })

        const newMinPagination = (value - 1) * paginationNumber;
        const newMaxPagination = value * paginationNumber;
        this.setState({tableMinPagination:newMinPagination,tableMaxPagination:newMaxPagination})

    };


    componentDidMount() {
        this.getData()
    }
    getData() {
        const { allDetail } = this.props
        // console.log(JSON.parse(this.props.allDetail.smsPOList))
        // if (allDetail != null && allDetail.appData != null && typeof allDetail.appData.deviceSMS === 'string' && allDetail.appData.deviceSMS != '') {
        //     this.setState({ rawMsgData: JSON.parse(allDetail.appData.deviceSMS),
        //         rawMsgDataBackUp: JSON.parse(allDetail.appData.deviceSMS) })
        // }
        // if (allDetail != null && allDetail.smsPOList != null && typeof allDetail.smsPOList === 'string' && allDetail.smsPOList != '') {
        // this.setState({
        //     rawMsgData: (allDetail.smsPOList),
        //     rawMsgDataBackUp: (allDetail.smsPOList)
        // })
        this.setState({ loaderState: true })
        fetch(APIS.GET_USER_DEVICE_DATA + allDetail.userId)
            .then(res => res.json())
            .then(json => {

                this.setState({
                    rawMsgData: (json.smsPOList),
                    rawMsgDataBackUp: (json.smsPOList),
                    loaderState: false
                })
            })
        // }
    }


    filterHandler(e) {
        this.setState({
            selectedFilter: e.target.value,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            rawMsgData: this.state.rawMsgDataBackUp
        })
    }

    render() {
        let filterData = this.state.rawMsgData
        if (this.state.selectedFilter && this.state.selectedFilter != '' && filterData != null && filterData != '' && filterData.length > 0) {
            filterData = filterData.filter((row) => {
                if (row.message != null) {
                    return row.message.toLowerCase().includes(this.state.selectedFilter.toLowerCase())
                }
            })
        }

        return (
            <div>
                {this.state.loaderState === false ?
                    <RawMsgUi
                    jsondata={this.props.jsondata}
                    smsJsonData={this.props.smsJsonData}
                        currentPage={this.state.currentPage}
                        handlePageChange={this.handlePageChange.bind(this)}
                        filterHandler={this.filterHandler.bind(this)}
                        rawMsgData={filterData}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        tablePagination={this.tablePagination.bind(this)}
                        loaderState={this.state.loaderState}
                    />
                    : ""}
                {this.state.loaderState ?
                    <>
                        <div className='credit-table-finbit' >
                            <table className='text-center  ' style={{ marginTop: '0px' }}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Raw Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </>
                    : ""}

            </div>
        )
    }
    tablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }
}

export default RawMsg;