import React from 'react';
import DatePicker from 'react-datepicker';
import { ROLE, STATE_COLOR } from '../../../../utils/Constant'
import DotLoader from '../../Loader/layerLoader.component'
import AndroidLogo from '../../../../images/android.png'
import WebLogo from '../../../../images/globe.png'
import FlipkartLogo from '../../../../images/giftnew.png'
import LoanLogo from '../../../../images/LoanFinal.png'
import { Pagination } from '@mui/material';

const PhocketCustomerDetails = ({ dataTosend, DeleteRazorpayToken, changehandler, selectAll, forwordPageState, handleChangeNewPagination, pageNumber, searchByEndDate, searchByEndDateTOShow, dateType, newFilterHandler, teaChangeHandler, removeclass, testbutton, commentColumn, userCommentChange, userDetailHandler, userViewHandler, dataFilter, admin,
    allUserDetail, statusHandler, newDataBackground, todaysApplicationNo, isDeleteUser, deactivateNachHandler,
    allStatus, loadarState, markAsImportant, paginationHandler, phocketTablePagination,
    columnHide, deleteUserHandler, userAssignPopup, searchByDate, commonSearch, nashHandler,
    showRepeateApplications, followUpStatus, commonSearchHandler, tableMinPagination,
    searchByDateShow, nachCheckbox, markImportant, tableMaxPagination, showWhatsappMsg, singleLeadPushHandler, paginationNumber,
    userCommentHandler, pushToRetraHandler }) => (
    <div className="container-fluid same">

        {/* <div className="row"> */}
        {/* <button className="reset-btn " style={{ float: 'right', marginTop: '20px' }} onClick={e => paginationHandler('refresh')}>Refresh</button>  */}
        {/* {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                <div className="col-sm-2 col-xs-12">
                    <label style={{ fontSize: '10px' }}>Loan Status</label>
                    <select className="form-control " value={followUpStatus} onChange={(e) => statusHandler(e)}>
                        <optgroup className="display-none">
                            <option value="">Select Status</option>
                        </optgroup>
                        <optgroup label="Status">
                            {allStatus != null && allStatus != undefined && allStatus != '' ?
                                allStatus.map((status, i) => {
                                    return (
                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                    );
                                }) : ""}
                        </optgroup>
                    </select>
                </div>
                : null} */}
        {/* <div className="col-sm-2 col-xs-12">
                <label style={{ fontSize: '10px' }}>Date Type</label>

                <select className='form-control' value={dateType} onChange={(e) => newFilterHandler(e, 'dateType')}>
                    <option value={''}>Select Date Type</option>
                    <option value={'apply'}>Apply Date</option>
                    <option value={'disburse'}>Disburse Date</option>
                    <option value={'approve'}>Approved Date</option>

                </select>
            </div> */}
        {/* {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                <div className="col-sm-2 col-xs-12">
                    <label style={{ fontSize: '10px' }}>Start Date</label>
                    <input value={searchByDateShow} className='form-control' type='date' placeholder='Start Date' onChange={(e) => newFilterHandler(e, 'startDate')} />
                  
                </div>
                : null}
            {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                <div className="col-sm-2 col-xs-12">
                    <label style={{ fontSize: '10px' }}>End Date</label>
                    <input value={searchByEndDateTOShow} className='form-control' type='date' placeholder='End Date' onChange={(e) => newFilterHandler(e, 'endDate')} />

                  
                </div>
                : null} */}
        {/* <div className="col-sm-2 col-xs-12"> */}
        {/* <label style={{ fontSize: '10px' }}><i className='fa fa-search'></i></label>

                <input type="text" className="form-control" onChange={e => commonSearchHandler(e)} value={commonSearch} placeholder="Name, State or device" /> */}
        {/* </div>
            <div className="col-sm-2 col-xs-12 btn-group text-right"> */}
        {/* {admin != null && admin.emailId == 'SuperUser' ?
                        <button className="btn-bg-new center-block" onClick={e => paginationHandler('cache')}>&nbsp;<i className="fa fa-refresh fa-spin" onClick={e => paginationHandler('cache')}></i>&nbsp;</button>
                        : null} */}

        {/* <button className="btn-bg-new center-block margin-left-25px " style={{ float: 'right', marginTop: '20px' }} onClick={e => paginationHandler('refresh')}>Refresh</button> */}
        {/* </div> */}
        {/* </div> */}
        {console.log(dataTosend,'dataTosend')}
        <div className='row'>
            <div className='col-sm-1' style={{ marginTop: '20px' }}>
                <label style={{ fontSize: '18px' }}>Select All </label>&nbsp;&nbsp;
                <input type='checkbox' onChange={(e) => selectAll(e)} />
            </div>

                <div className='col-sm-2' style={{ marginTop: '10px' }}>
                    <button className='btn btn-primary' onClick={() => DeleteRazorpayToken()}>Delete Token</button>
                </div>
     
        </div>
        <div className="row text-center">

            <div className="col-xs-12 no-padding">
                {loadarState == false ? allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                    <div>
                        <div className="overflow-x-auto phocket-table-new">
                            <table >
                                <thead>
                                    <tr>

                                        {nachCheckbox ? <th>Nach</th> : null}
                                        <th>eSign</th>
                                        <th><i className="fa fa-whatsapp whats-app-approval whatsapp-icon" /></th>
                                        <th>Application Id</th>
                                        <th>Crif Decision</th>

                                        {/* {allUserDetail.typeOfProduct == null || allUserDetail.typeOfProduct== ''?
                                            <th>Type</th>
                                            :""}
                                            <th>Device</th> */}

                                        <th>Loan Id</th>
                                        <th>Name</th>
                                        <th>State</th>
                                        <th>Mobile No.</th>
                                        <th>Apply Date</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Installment</th>
                                        <th>Loan From</th>
                                        {allUserDetail.typeOfProduct == null || allUserDetail.typeOfProduct == '' ?
                                            <th>Type</th>
                                            : ""}
                                        <th>Select</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                        return (
                                            <tr key={i}
                                            // style={{
                                            //     backgroundColor: newDataBackground != undefined ? data.loanId == newDataBackground.id ? newDataBackground.color : "" : "",
                                            //     background: data.loginDevice != '' && data.loginDevice != null ?
                                            //         data.state == 'Rajasthan' ? STATE_COLOR.RAJASTHAN
                                            //             : data.state == 'West Bengal' ? STATE_COLOR.WEST_BENGAL
                                            //                 : data.state == 'Maharashtra' ? STATE_COLOR.MAHARASHTRA
                                            //                     : data.state == 'Karnataka' ? STATE_COLOR.KARNATAKA
                                            //                         : "" : STATE_COLOR.LOAN_ADDA,
                                            // }}
                                            >

                                                {nachCheckbox ? <td>
                                                    <input type="checkbox"  checked={data.nachStatus} />

                                                    {/* <input type="checkbox" onChange={(e) => admin.emailId === 'SuperUser' ? nashHandler(e, data) : ""} checked={data.nachStatus} /> */}
                                                </td> : null}
                                                {/* {commentColumn?
                                                    <td>
                                                        <div className="display-flex">
                                                            <input type="text" className="app-comment-box" id={"comment" + data.loanId} onChange={e => userCommentChange(e)} placeholder="type here..." />
                                                            <span className="fa fa-save loan-id-save-btn" onClick={e => userCommentHandler(data)} />
                                                        </div>
                                                    </td>
                                                    :""} */}
                                                <td>{data.eSignStatus != undefined ? data.eSignStatus ? <span>&#10003;</span> : <span>&#10005;</span> : null}</td>
                                                <td>
                                                    <span className={"fa fa-whatsapp whats-app-approval relative_position whatsapp-icon" + (data.whatAppUnreadCount > 0 ? " fa-lg pointer" : '')} onClick={e => showWhatsappMsg(data)}>
                                                        <a className="blink">{data.whatAppUnreadCount > 0 ? <div className="not-count-circle" >{data.whatAppUnreadCount}</div> : null}</a>
                                                    </span>
                                                </td>
                                                {/* {console.log(data)} */}
                                                <td>{data.applicationId}</td>
                                                <td>{data.crifDecision}</td>


                                                <td>{data.applicationLoanId}</td>

                                                <td style={{ whiteSpace: 'break-spaces' }}>{data.userName}</td>
                                                <td>{data.state}</td>
                                                <td>{data.mobileNumber}</td>
                                                <td>{data.loanApplyDate != null ? data.loanApplyDate.split(" ")[0] : null}</td>
                                                <td className="light-dark-background">{data.applicationStatus}</td>
                                                <td>{data.loanAmount}</td>
                                                <td>{data.loanEmi}</td>
                                                {/* <td>{data.loanDuration}</td> */}
                                                <td>{data.loanFrom}</td>
                                                {data.typeOfProduct == 'Gift Card' ?
                                                    <td><i style={{ fontSize: '22px', color: '#ff6347' }} class="fa fa-gift" aria-hidden="true"></i>
                                                    </td>
                                                    : data.typeOfProduct == 'Loan' ? <td><img src={LoanLogo} /></td> : data.typeOfProduct == null || data.typeOfProduct == '' ? <td>Empty</td> : ""}
                                                <td><input id={'disburseIcici' + i} type='checkbox' onChange={(e) => changehandler(e, data)} /></td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ float: 'right', marginBottom: '15px', marginTop: '10px', display: 'flex' }}>
                            {forwordPageState > 1 ? <div className='grey-circle' onClick={e => paginationHandler('left')}><i className="fa fa-chevron-left" style={{ cursor: 'pointer', marginTop: '15px' }}  ></i></div> : ""}  <Pagination size='large' onChange={handleChangeNewPagination} color="primary"
                                // count={Math.ceil(allUserDetail.length / 10)}
                                page={pageNumber} />
                            {Math.ceil(allUserDetail.length / 10) === 10 ? <div className='grey-circle' onClick={e => paginationHandler('right')}><i className="fa fa-chevron-right" style={{ cursor: 'pointer', marginTop: '15px' }}  ></i></div> : ""}

                        </div>
                        {/* <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                                <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                                <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                                <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                                <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
                        </div> */}
                    </div>
                    : null : <DotLoader />}
            </div>
        </div>
    </div>
)

export default PhocketCustomerDetails;
