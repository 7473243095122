import React, { Component } from 'react';
import Registereduser from '../../presentationals/Campaigntrack/RegisterUserDetails.Component';
import { getCompanyData, changeIdStatus } from './ThirdPartyActionCreater.component';
import Loader from '../../presentationals/Loader/Loader.component'
let paginationNumber = 10;
import Popup from '../../presentationals/Popup/Popup.component'

export default class ThirdPartyRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyList: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            loaderState: false,
            status: '',
            popupStatus: '',
            popupState: ''
        }
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    statusHandler(status, emailId) {
        let statustosend = status === true ? false : true
        this.setState({ loaderState: true })
        changeIdStatus(statustosend, emailId, callback => {
            if (callback === 'success') {
                this.setState({ loaderState: false,popupState:true,popupStatus:'Status changed successfully!' })

                this.getData()
                this.removePopup()
            } else {
                this.setState({ loaderState: false,popupState:true,popupStatus:'please try again later!'  })
                this.removePopup()
            }
        })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }


    render() {
        const { admin } = this.props
        return (
            <div>
                {this.state.popupState?
                <Popup
                closePopup={this.closePopup.bind(this)}
                popupStatus={this.state.popupStatus}
                />
            :""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <Registereduser
                    statusHandler={this.statusHandler.bind(this)}
                    status={this.state.status}
                    admin={admin}
                    companyList={this.state.companyList}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                    exportExcelHandler={this.exportExcelHandler.bind(this)}
                />
            </div>

        )
    }

    exportExcelHandler(e) {

    }
    getData() {
        this.setState({ loaderState: true })

        getCompanyData(callback => {
            this.setState({ companyList: callback, loaderState: false })
        })
    }

    componentDidMount() {
        this.getData()
    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
}
