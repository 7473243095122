import React from 'react'
import PhocketLogo from '../../../images/Rectangle (1).png';
import ChipImage from '../../../images/Group.png';
import RupayImg from '../../../images/Group (1).png'

const TransactionPage = ({ channelSubmit, admin, state, cardData, cardLimitData, addFalconVirtualCardApi, changeCardStatus, dateHandler, searchSubmit, getCvv, addFalconPhysicalCardApi }) => (
    <div className='container-fluid '>
        <div className='row'>
            {state.cardDetailsState && (state.cardData.cardNo === null) ?
                <div className='text-right'>
                    <button className='profile-save-btn' onClick={(e) => addFalconVirtualCardApi(e)}>Add Virtual Card</button>
                </div>
                : ""}
            {state.cardDetailsState && (state.cardData.cardNo === null) ?
                <div className='text-right'>
                    <button className='profile-save-btn' onClick={(e) => addFalconPhysicalCardApi(e)}>Add Physical Card</button>
                </div>
                : ""}
            {state.cardDetailsState ?
                <div>
                    <button className='profile-save-btn' onClick={(e) => changeCardStatus(e, 'change')}>Enabled Or Disabled Card</button>&nbsp;&nbsp;
                    <button className='profile-save-btn' onClick={(e) => changeCardStatus(e, 'block')} >Block Card</button>
                </div>
                : ""}

            {state.transactionDetails ?

                <div className='col-sm-12 col-xs-12 ' style={{ minHeight: 'auto' }}>
                    <h3 className='text-center'><b>Transaction Details</b></h3>
                    <div className='row'>
                        <div className='col-sm-3 col-xs-12'>
                            <label>Start Date</label>
                            <input type='date' className='form-control' placeholder='Start Date' onChange={(e) => dateHandler(e, 'first')} />
                        </div>
                        <div className='col-sm-3 col-xs-12'>
                            <label>End Date</label>

                            <input type='date' className='form-control' placeholder='End Date' onChange={(e) => dateHandler(e, 'end')} />

                        </div>
                        <div className='col-sm-3 col-xs-12' >
                            <button className='profile-save-btn' onClick={searchSubmit}>Search</button>
                        </div>
                    </div>
                    <div className='overflow-x-auto' style={{ marginTop: '40px' }}>
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Transaction Date</th>
                                    <th>Transaction Id</th>
                                    <th>Transaction Type</th>
                                    <th>Bank Name</th>
                                    <th>Merchant Name</th>
                                    <th>Total Amount</th>
                                    <th>Transaction Amount</th>
                                    <th>Channel</th>
                                </tr>
                            </thead>
                            <tbody>

                                {state.transactionData && state.transactionData.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{data.transactionDate}</td>
                                            <td>{data.transactionId}</td>
                                            <td>{data.transactionType}</td>
                                            <td>{data.bankName}</td>
                                            <td>{data.merchantName}</td>
                                            <td>{data.totalAmount}</td>
                                            <td>{data.transactionAmount}</td>
                                            <td>{data.channel}</td>
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </table>
                    </div>
                </div>

                : ""}

            {state.cardDetailsState ?
                <div className='row'>
                    <div className='col-sm-2 col-xs-12'>
                    </div>

                    <div className='col-sm-8 col-xs-12 ' >
                        {/* <button className='profile-save-btn' onClick={(e) => changeCardStatus(e, 'change')}>Enabled Or Disabled Card</button>
                        <button className='profile-save-btn' onClick={(e) => changeCardStatus(e, 'block')} style={{ float: 'right' }}>Block Card</button> */}
                        <h3 className='text-center'><b>Card Details</b></h3>
                        <div className='row'>

                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                <div style={{ marginTop: '18px' }}>
                                    <b style={{ color: 'gray' }}>Card Status</b>
                                    <span style={{ float: 'right', color: 'blue' }}> {(state.cardData.cardStatus)}</span></div>
                                <div style={{ marginTop: '30px' }}>
                                    <b style={{ color: 'gray' }}>Blocked Status</b>
                                    <span style={{ float: 'right', color: 'blue' }}>{state.cardData.blockedStatus === false ? <i className='fa fa-close' style={{ fontSize: '18px', color: 'red' }}></i> : <i style={{ fontSize: '18px', color: 'green' }} className='fa fa-check'></i>}</span></div>
                                <div style={{ marginTop: '30px' }}>
                                    <b style={{ color: 'gray' }}>Balance Available</b>
                                    <span style={{ float: 'right', color: 'blue' }}> {(state.cardStatusData.balanceAvailable)}</span></div>
                                <div style={{ marginTop: '30px' }}>
                                    <b style={{ color: 'gray' }}>Amount Used</b>
                                    <span style={{ float: 'right', color: 'blue' }}>{state.cardStatusData.amountUsed}</span></div>

                                <div style={{ marginTop: '30px' }}>
                                    <b style={{ color: 'gray' }}>Usage Status </b>
                                    <span style={{ float: 'right', color: 'blue' }}>{state.cardData.usageStatus}</span></div>

                            </div>
                            <div className='col-sm-2 col-xs-12'></div>
                            <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                                <div className="card-card">
                                    <div className="card__front card__part">
                                        <img className="card__front-square card__square" src={PhocketLogo} />
                                        <img className="" style={{ marginTop: '100px' }} src={ChipImage} />
                                        <img src={RupayImg} style={{ float: 'right' }} />
                                        {/* <p className="card_numer">{state.cardData.cardNo}</p> */}
                                        {/* <div className="card__space-75">
                                            <span className="card__label">Card holder</span>
                                            <p className="card__info">{state.cardData.nameOnCard}</p>
                                        </div>
                                        <div className="card__space-25">
                                            <span className="card__label">Expires</span>
                                            <p className="card__info">{state.cardData.expiry}</p>
                                        </div> */}
                                        <div><p className='text-india'>For Use in India Only</p></div>
                                    </div>

                                    <div className="card__back card__part">
                                        <div className='row'>
                                            <div className='col-sm-6 col-xs-12' style={{ fontSize: '10px', color: '#fff', textAlign: 'center', fontWeight: '700' }}>
                                                Customer Care Number: 1800 103 5085
                                            </div>
                                            <div className='col-sm-6 col-xs-12' style={{ fontSize: '10px', color: '#fff', textAlign: 'center', fontWeight: '700' }}>
                                                ELECTRONIC USE ONLY
                                            </div>
                                        </div>
                                        <div className="card__black-line"></div>
                                        <div className="card__back-content" >
                                            <div className="card__secret">
                                                <p className="card__secret--last"></p>
                                            </div>
                                            <p className="card__info" style={{ marginTop: '30px' }}>{state.cardData.nameOnCard}</p>

                                            <div className='card__info'><p>{state.cardData.cardNo}</p></div>
                                            <div className='card__info'><p>{state.cardData.expiry}</p></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {admin.emailId === 'SuperUser' ?
                            <div className='row'>

                                <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>


                                    {/* <div >
                                        <b style={{ color: 'gray' }}>Cvv</b>
                                        <span style={{ float: 'right', color: 'blue' }}>{state.cardData.cvv}</span></div> */}
                                    {state.cardData.cvv === 0 ?
                                        <div className='' style={{ marginTop: '30px' }}>
                                            <button className='profile-save-btn' onClick={() => getCvv()}>Get Cvv</button>
                                            {state.cvvData !== '' ?

                                                <div style={{ marginTop: '30px' }}>
                                                    <div >
                                                        <b style={{ color: 'gray' }}>Cvv</b>
                                                        <span style={{ float: 'right', color: 'blue' }}>{state.cvvData.cvv}</span></div>
                                                </div>
                                                : ""}
                                        </div>

                                        : ""}
                                </div>


                            </div>
                            : ""}

                        <hr />
                        {/* <h3 className='text-center'><b>Card Status</b></h3>
                        <div className='row'>

                            <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                                <div>
                                    <b style={{ color: 'gray' }}>Balance Available</b>
                                    <span style={{ float: 'right', color: 'blue' }}> {(state.cardStatusData.balanceAvailable)}</span></div>
                            </div>
                            <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                                <div >
                                    <b style={{ color: 'gray' }}>Amount Used</b>
                                    <span style={{ float: 'right', color: 'blue' }}>{state.cardStatusData.amountUsed}</span></div>
                            </div>
                        </div> */}
                    </div>
                    <div className='col-sm-2 col-xs-12'>
                    </div>
                </div>
                : ""}
            {state.cardLimitState ?
                <div className='row'>
                    <div className='col-sm-3 col-xs-12'>
                    </div>
                    <div className='col-sm-6 col-xs-12 card-profile' style={{ minHeight: 'auto' }}>
                        <h3 className='text-center'><b>Card Limit Details</b></h3>
                        <div className='row'>

                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Limit</th>
                                        <th>Transaction Date</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    {state.cardLimitData && state.cardLimitData.map((data, i) => {
                                        return (
                                            <tr>
                                                <td>{data.limit}</td>
                                                <td>{data.date}</td>
                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='col-sm-3 col-xs-12'>
                    </div>
                </div>

                : ""}
            {state.cardPolicyState && state.policyData && state.policyData.atm ?
                <div className='row' style={{ marginTop: '20px' }}>
                    <h3 className='text-center' style={{ marginTop: '20px', marginBottom: '20px' }}>Card Policy Details</h3>
                    {state.policyData && state.policyData.atm ?
                        <div className='col-sm-4 col-xs-12'>
                            <div className='card-profile' style={{ minHeight: 'auto' }}>

                                <h5 className='text-center'>ATM</h5>
                                <div className='text-center'>
                                    <button className='profile-save-btn' style={{ marginTop: '0px' }} onClick={() => channelSubmit('atm', state.policyData.atm.status)}>Change Channel</button>
                                </div>
                                <div className=''>
                                    <b style={{ color: 'gray' }}>Status</b>
                                    <span style={{ float: 'right', color: state.policyData.atm.status === 'ENABLED' ? 'green' : 'red' }}>{state.policyData.atm.status ? state.policyData.atm.status : ""}</span></div>
                                <div style={{ marginTop: '20px' }}>
                                    <b style={{ color: 'gray' }}>Limit</b>
                                    <span style={{ float: 'right', color: 'blue' }}>{state.policyData.atm.limit}</span></div>

                            </div>
                        </div>
                        : ""}
                    {state.policyData && state.policyData.ecom ?

                        <div className='col-sm-4 col-xs-12'>
                            <div className='card-profile' style={{ minHeight: 'auto' }}>

                                <h5 className='text-center'>ECOM</h5>
                                <div className='text-center'>
                                    <button className='profile-save-btn' style={{ marginTop: '0px' }} onClick={() => channelSubmit('ecom', state.policyData.atm.status)}>Change Channel</button>
                                </div>
                                <div className=''>
                                    <b style={{ color: 'gray' }}>Status</b>
                                    <span style={{ float: 'right', color: state.policyData.ecom.status === 'ENABLED' ? 'green' : 'red' }}>{state.policyData.ecom.status ? state.policyData.ecom.status : ""}</span></div>
                                <div style={{ marginTop: '20px' }}>
                                    <b style={{ color: 'gray' }}>Limit</b>
                                    <span style={{ float: 'right', color: 'blue' }}>{state.policyData.ecom.limit}</span></div>

                            </div>
                        </div>
                        : ""}
                    {state.policyData && state.policyData.pos ?

                        <div className='col-sm-4 col-xs-12'>
                            <div className='card-profile' style={{ minHeight: 'auto' }}>

                                <h5 className='text-center'>POS</h5>
                                <div className='text-center'>
                                    <button className='profile-save-btn' style={{ marginTop: '0px' }} onClick={() => channelSubmit('pos', state.policyData.atm.status)}>Change Channel</button>
                                </div>
                                <div className=''>
                                    <b style={{ color: 'gray' }}>Status</b>
                                    <span style={{ float: 'right', color: state.policyData.pos.status === 'ENABLED' ? 'green' : 'red' }}>{state.policyData.pos.status ? state.policyData.pos.status : ""}</span></div>
                                <div style={{ marginTop: '20px' }}>
                                    <b style={{ color: 'gray' }}>Limit</b>
                                    <span style={{ float: 'right', color: 'blue' }}>{state.policyData.pos.limit ? state.policyData.pos.limit : ""}</span></div>

                            </div>
                        </div>
                        : ""}
                </div>
                : ""}
        </div>
    </div>
)


export default TransactionPage