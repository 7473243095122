import React from 'react'
import { BarChart, CartesianGrid, Tooltip, XAxis, YAxis, Bar } from 'recharts';

const colors = ['#41BAFC', '#41BAFC', '#41BAFC', '#41BAFC', '#41BAFC', '#41BAFC', '#0088FE', '#0088FE', '#0088FE', '#0088FE', '#0088FE', '#0088FE', '#0020C2', '#0020C2', '#0020C2', '#0020C2', '#0020C2', '#0020C2'];

const StatusMenu = ({ allStatus, piChartData, showDataHandler }) => (
    <div className="admin-dash">
        <div className="container-fluid">
            <div className="row  form-group">
            </div>
            <div className="row">
                {allStatus != null && allStatus.length > 0 ?
                    allStatus.map((status, i) => {
                        return (
                            <div className="col-sm-2 col-xs-6" key={i}>

                                <div className="bank-card pointer" style={{ borderLeftColor: colors[i] }} onClick={e => showDataHandler(status)}>
                                    <h3>{status.count}</h3>
                                    <h5>{status.adminStatus}</h5>
                                </div>
                            </div>
                        )
                    })
                    : ""}
            </div>
            <div className="overflow-x">
                {piChartData != null && piChartData.length > 0 ?
                    <BarChart

                        width={1350}
                        height={300}
                        data={piChartData}
                        scaleToFit={true}
                        margin={{
                            top: 20, right: 10, left: 10, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="name" allowDataOverflow={true} tickCount={10} interval={0}
                            minTickGap={12}
                        />
                        <YAxis dataKey="value" />
                        <Tooltip fill="#eeeee" />
                        <Bar layout="horizontal" type="monotone" dataKey="value" fill="#449d44" barSize={40} minPointSize={20} />
                    </BarChart>
                    : ""}
            </div>
        </div>
    </div>
)
export default StatusMenu;