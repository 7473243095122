import React, { useState, useEffect } from 'react'
import PopUp from "../../../../presentationals/Popup/Popup.component";
import Loader from '../../../../presentationals/Loader/Loader.component';
import { SaveUpdateAutomatedProcess } from '../../AdminActionCreator.component'
export default function AddEditExecutor({ editData, handleSavebtn }) {
    const PageType = (Object.keys(editData)?.length > 0 ? 'Edit ' + editData?.processName : 'Add Process')
    const [loader, setloader] = useState(false)
    const [popupstate, setPopupState] = useState(false)
    const [popupStatus, setpopupStatus] = useState('')
    const [data, setData] = useState({
        processName: '',
        description: '',
        occurrence: '',
        active: false
    })

    useEffect(() => {
        if (Object.keys(editData)?.length > 0) {
            setData({
                processName: editData.processName,
                description: editData.description,
                occurrence: editData.occurrence,
                active: editData.active
            })
        }
    }, [])

    const handlechange = (e) => {
        const { name, value } = e.target;

        setData({
            ...data,
            [name]: value
        })
    }

    const handleToggle = (type) => {
        setData({
            ...data,
            ['active']: !type
        })
    }

    const handlesave = (type) => {
        if (type == 'save') {
            console.log(data)
            setloader(true)
            SaveUpdateAutomatedProcess(data, (callback) => {
                setloader(false)
                if (callback == 'success') {
                    setPopupState(true)
                    setpopupStatus('Saved Successfully')
                    setTimeout(()=>{
                        handleSavebtn()
                    },2000)
                }else{
                    setPopupState(true)
                    setpopupStatus('Error')
                }
            })
        }
        else if (type == 'update') {
            let obj = {
                "id": editData?.id,
                "processName": data?.processName,
                "description": data?.description,
                "occurrence": data?.occurrence,
                "active": data?.active,
                "createdOn": editData?.createdOn
            }
            setloader(true)
            SaveUpdateAutomatedProcess(obj, (callback) => {
                setloader(false)
                if (callback == 'success') {
                    setPopupState(true)
                    setpopupStatus('Saved Successfully')
                    setTimeout(()=>{
                        handleSavebtn()
                    },2000)
                }else{
                    
                        handleSavebtn()
                }
            })
        }
        else if (type == 'back') {
            handleSavebtn()
        }
    }
    const closePopup = () => {
        setPopupState(false)
    }

    return (
        <>
            <div className="AddEditExecutor">
                {
                    loader ?
                        <Loader />
                        : ''}
                {
                    popupstate ?
                        <PopUp closePopup={closePopup} popupStatus={popupStatus} />
                        : ''}
                <h3 className='heading'>{PageType}</h3>

                <div className="forms">
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp">
                                <label htmlFor="processName">Process Name</label>
                                <input className='inputtag' type="text" id="processName" placeholder='Process Name' name="processName" value={data?.processName} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp">
                                <label htmlFor="occurrence">Process Occurrence</label>
                                {/* <input className='inputtag' type="text" id="occurrence" placeholder='Process Occurrence' name="occurrence" value={data?.occurrence} onChange={(e) => handlechange(e)} /> */}
                                <select className='inputtag' value={data?.occurrence} onChange={(e) => handlechange(e)} name="occurrence">
                                    <option value="">Select Occurrence</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp">
                                <label htmlFor="description">Process Description</label>
                                <textarea className='inputtag' type="text" id="description" placeholder='Process Description' name="description" value={data?.description} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp">
                                <label htmlFor="active">Process Active</label>
                                <div className={`togglebtn ${data?.active == true ? 'active' : 'inactive'}`} style={{ width: '15%', marginTop: '5px',cursor:'pointer' }} onClick={() => handleToggle(data?.active)}>
                                    <button className='btn'  style={{ padding: '15px' }}></button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='savebtn'>
                        <button className='btn back' onClick={() => handlesave('back')}>Back</button>
                        <button className='btn' onClick={() => handlesave(PageType == 'Add Process' ? 'save' : 'update')}>{PageType == 'Add Process' ? 'Save' : 'Update'}</button>
                    </div>
                </div>
            </div>

        </>
    )
}
