import React, { useEffect, useState } from 'react'
import Listing from './policyMainTabs'
import ConFig from './configPolicy'
import { getAllPolicyApi } from '../Analysis/analysisactionCreator'
import { deletePolicyByPolicyId } from '../AdminActionCreator.component'
const MainPolicyTab = () => {
    const [editData, saveEditData] = useState('')
    const [viewStateMain, setViewPageMain] = useState('list')
    const [objectData, setObjectData] = useState('')

    useEffect(() => {

        getAllPolicyApi(callback => {
            setObjectData(callback)
        })
    }, [])
    const getData = () => {
        getAllPolicyApi(callback => {
            setObjectData(callback)
        })
    }
    const deletePolicy=(data)=>{
        deletePolicyByPolicyId(data.policyId,callback=>{
            getAllPolicyApi(callback => {
                setObjectData(callback)
            })
        })
     }
    return (
        <div>
            {viewStateMain === 'list' ?
                <Listing deletePolicy={deletePolicy} objectData={objectData} saveEditData={saveEditData} setViewPageMain={setViewPageMain} />
                : ""}
            {viewStateMain === 'config' ?
                <ConFig getData={getData} editData={editData} setViewPageMain={setViewPageMain} />
                : ""}
        </div>
    )
}
export default MainPolicyTab

