import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

const Input = ({
    label = '',
    value = '',
    variant = 'standard',
    name = '',
    id = '',
    onChange,
    size = 'medium',
    type = 'text',
    readOnly = false,
    required = false,
    inputProps = {},
}) => {

    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(newValue);
    };

    return (
        <FormControl fullWidth>
            <TextField
                label={label}
                type={type}
                variant={variant}
                value={inputValue}
                name={name}
                id={id}
                onChange={handleChange}
                size={size}
                inputProps={{
                    ...inputProps,
                    readOnly,
                    style: { fontSize: '14px' },  // Set the font size for input field here
                }}
                required={required}
                InputLabelProps={{
                    style: { fontSize: '16px' },  // Set the font size for label here
                }}
            />
        </FormControl>
    );
};

export default Input;
