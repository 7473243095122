const MSG_STRING = {
    REVIEW_ON_PLAYSTORE: "Liking BorrowBuddy?? Write a great review on playstore and stand a chance to win amazing prizes. Check https://play.google.com/store/apps/details?id=com.borrowBuddy",
    MONTH_END_SAL_ADVANCE: "Hello Customer! Month end coming and have bills to pay? Get advance salary between 5000 & 1 Lac. 100% Digital. Lowest Fee. Check https://play.google.com/store/apps/details?id=com.borrowBuddy",
    BILL_PAY_SAL_ADVANCE: "Hi Customer! Salary not received and still have bills to pay during month end? Get advance salary loan. Lowest Interest & Fee. Check https://play.google.com/store/apps/details?id=com.borrowBuddy",

    BORROWBUDDY_APP_TRY_KARO: "Dear Customer! BorrowBuddy App bahut badhiya app hai. Market mai naya hai. Aasaani se 5000 se 1 Lac tak ka loan milta hai. Check https://play.google.com/store/apps/details?id=com.borrowBuddy",
    // PHOCKET_APP_TRY_KARO: "Dear Customer! BorrowBuddy App bahut badhiya app hai. Market mai naya hai. Aasaani se 5000 se 1 Lac tak ka loan milta hai. Check https://play.google.com/store/apps/details?id=com.borrowBuddy",

    JHATPAT_LOAN: "Hi Customer! BorrowBuddy se aasaani se 1 Lac tak ka jhatpat loan paaye aur woh bhi sabse kam interest par. Check https://play.google.com/store/apps/details?id=com.borrowBuddy",
    ELIGIBLE_FOR_ADVANCE_SAL_2_LAC: "You are eligible for a BorrowBuddy Advance Salary Loan of INR 2 Lac with Low-interest rates*. Option to repay in 12 EMIs. Apply Now - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    FESTIVE_SHOPPING_LOAN: "Hi Customer! This festive season shop at Amazon or Flipkart with Zero Cost EMI. Shop Now and Pay later.Apply Now - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    LOAN_WITHIN_FEW_MINUTES: "Hi Customer! loans starting Rs. 5,000 in your account within minutes. No paperwork, 100% Online!! Apply - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    LOAN_WITHIN_5_MINUTES: "Hi Customer! In just 5 minutes Get PL starting Rs. 5000 24x7 with ZERO UPLOADS in your A/c. 100% Online. Apply -https://play.google.com/store/apps/details?id=com.borrowBuddy",
    PERSONAL_LOAN: "Hi Customer, last chance! Apply for a BorrowBuddy PL for Quick disbursal, Low interest rates, 0 hidden charges. Apply - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    UPTO_200000_LOAN: "Hi Customer! Get up to Rs. 2,00,000 PL from BorrowBuddy just @0.065% interest rate per day. Apply - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    URGENT_CASH: "Hi Customer! Need urgent cash? Get a BorrowBuddy PL credited in your a/c in minutes | Low interest rates | Apply - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    LOAN_WAITING: "Hi Customer, your BorrowBuddy PL is waiting!- Instant credit check- Approval within minutes- Low interest rateApply. TnC apply | Link - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    LOAN_AT_LOW_INTEREST: "Dear Customer, BorrowBuddy PL - Get @ low interest rates via a 100% online process. 0 hidden charges. Apply. TnC apply | Link - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    MONEY_AT_SPEED_OF_ROCKET: "Dear Customer! Money in your pocket @ speed of rocket = BorrowBuddy | Up to Rs. 200K | Low interest | Fast disbursal. Apply - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    ADVANCE_SALARY_VIA_BANKBAZAAR: "Dear Customer! Thanx for your interest in BorrowBuddy PL via BankBazaar. Download our App to provide few details for instant cash. Link - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    BANKBAZAAR: "Thanx for your interest in BorrowBuddy - Advance Salary loan via BankBazaar. Download our App to provide few details for instant cash. Link - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    SALARY_12_MONTH: "BorrowBuddy now provides Advance Salary Loan of INR 2 Lac starting at 0%*. Option to repay in 12 flexible EMIs.Apply Now - https://play.google.com/store/apps/details?id=com.borrowBuddy",

    GET_A_BORROWBUDDY_PL: "Hi Customer! Need urgent cash? Get a BorrowBuddy PL credited in your a/c in minutes | Low interest rates | Apply - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    // GET_A_PHOCKET_PL: "Hi Customer! Need urgent cash? Get a BorrowBuddy PL credited in your a/c in minutes | Low interest rates | Apply - https://play.google.com/store/apps/details?id=com.borrowBuddy",

    ADVANCE_SAL_BY_BUDDYLOAN: "Thanx for your interest in BorrowBuddy - Advance Salary loan via Buddyloan. Download our App to provide few details for instant cash. Link - https://play.google.com/store/apps/details?id=com.borrowBuddy",

    PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY: "Hi,Your BorrowBuddy loan application through Buddy Loan is Pre-qualified for an amount Upto Rs 200000. Click https://play.google.com/store/apps/details?id=com.borrowBuddy to install the App",
    // PRE_QUALIFIED_BUDDY_LOAN_PHOCKET: "Hi,Your BorrowBuddy loan application through Buddy Loan is Pre-qualified for an amount Upto Rs 200000. Click https://play.google.com/store/apps/details?id=com.borrowBuddy to install the App",

    BORROWBUDDY_BANKBAZAAR: "Hello, Your BorrowBuddy loan request through BankBazaar is Pre-qualified for INR 2,00,000. Click - https://play.google.com/store/apps/details?id=com.borrowBuddy to install the App and complete the application.",
    // PHOCKET_BANKBAZAAR: "Hello, Your BorrowBuddy loan request through BankBazaar is Pre-qualified for INR 2,00,000. Click - https://play.google.com/store/apps/details?id=com.borrowBuddy to install the App and complete the application.",

    LOAN_FOR_15000_SALARY: "Hello! BorrowBuddy Loan is now available for employees with salary of 15000 and above | Low Interest Rates | Apply for urgent cash - https://play.google.com/store/apps/details?id=com.borrowBuddy",

    BorrowBuddy_App_partnered_with_Anjraj_Talent:"BorrowBuddy App partnered with Anjraj Talent to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 – 12 months. Apply Now - https://play.google.com/store/apps/details?id=com.borrowBuddy",
    // Phocket_App_partnered_with_Anjraj_Talent:"BorrowBuddy App partnered with Anjraj Talent to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 – 12 months. Apply Now - https://play.google.com/store/apps/details?id=com.borrowBuddy",

    Dear_Customer_Refer_your_friends:"Dear Customer Refer your friends to avail instant loan from BorrowBuddy and Earn INR 1000 for every successful disbursement. Share link - https://play.google.com/store/apps/details?id=com.borrowBuddy",

    BorrowBuddy_App_partnered_with_Genesis:"BorrowBuddy App partnered with Genesis Head hunters to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 – 12 months. Apply Now -https://play.google.com/store/apps/details?id=com.borrowBuddy",
    // Phocket_App_partnered_with_Genesis:"BorrowBuddy App partnered with Genesis Head hunters to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 – 12 months. Apply Now -https://play.google.com/store/apps/details?id=com.borrowBuddy",

    BorrowBuddy_App_partnered_with_Hyrexpert:"BorrowBuddy App partnered with Hyrexpert to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 – 12 months. Apply Now --https://play.google.com/store/apps/details?id=com.borrowBuddy",
    // Phocket_App_partnered_with_Hyrexpert:"BorrowBuddy App partnered with Hyrexpert to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 – 12 months. Apply Now --https://play.google.com/store/apps/details?id=com.borrowBuddy",

    BorrowBuddy_App_partnered_with_Karyarth:"BorrowBuddy App partnered with Karyarth to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 to 12 months. Apply Now : https://play.google.com/store/apps/details?id=com.borrowBuddy",
    // Phocket_App_partnered_with_Karyarth:"BorrowBuddy App partnered with Karyarth to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 to 12 months. Apply Now : https://play.google.com/store/apps/details?id=com.borrowBuddy",

    Borrowbuddy_App_partnered_with_SAM_Career:"BorrowBuddy App partnered with SAM Career to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 – 12 months. Apply Now : https://play.google.com/store/apps/details?id=com.borrowBuddy",
    // Phocket_App_partnered_with_SAM_Career:"BorrowBuddy App partnered with SAM Career to provide Instant Advance Salary Loan of INR 2 Lac starting at 0%* for 2 – 12 months. Apply Now : https://play.google.com/store/apps/details?id=com.borrowBuddy",

    PHONEPARLOAN:"Hello, Your BorrowBuddy loan application through Phoneparloan is Pre-qualified for an amount upto Rs 200000. Click - https://play.google.com/store/apps/details?id=com.borrowBuddy to install the App.",
    REPEAT_CAMPAIGN:"Hi! Your BorrowBuddy loan has been closed and you can re-apply for a new loan with your updated bank statement. For more info click here : https://play.google.com/store/apps/details?id=com.borrowBuddy"
}

const TemplateId = {
    REVIEW_ON_PLAYSTORE: "1207161475897943737",
    MONTH_END_SAL_ADVANCE: "1207161838775090581",
    BILL_PAY_SAL_ADVANCE: "1207161838780337167",

    BORROWBUDDY_APP_TRY_KARO: "1207161855445215044",
    // PHOCKET_APP_TRY_KARO: "1207161855445215044",

    JHATPAT_LOAN: "1207161855448844055",
    // ELIGIBLE_FOR_ADVANCE_SAL_2_LAC: "1207168605298092572",

    ELIGIBLE_FOR_ADVANCE_SAL_2_LAC: "1207168655126888467",

    FESTIVE_SHOPPING_LOAN: "1207161855457720557",
    LOAN_WITHIN_FEW_MINUTES: "1207161855462240006",
    LOAN_WITHIN_5_MINUTES: "1207161855465662117",
    PERSONAL_LOAN: "1207161855470164886",
    UPTO_200000_LOAN: "1207161855473588162",
    URGENT_CASH: "1207161855476748706",
    LOAN_WAITING: "1207161855484312721",
    LOAN_AT_LOW_INTEREST: "1207161855488399839",
    MONEY_AT_SPEED_OF_ROCKET: "1207161855492155203",
    ADVANCE_SALARY_VIA_BANKBAZAAR: "1207161855496018328",
    BANKBAZAAR: "1207161830830667775",
    SALARY_12_MONTH: "1207161830843489108",

    GET_A_BORROWBUDDY_PL: "1207162391872046951",
    // GET_A_PHOCKET_PL: "1207162391872046951",

    ADVANCE_SAL_BY_BUDDYLOAN: "1207163180188444607",

    PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY: "1207163180198554114",
    // PRE_QUALIFIED_BUDDY_LOAN_PHOCKET: "1207163180198554114",

    BORROWBUDDY_BANKBAZAAR: "1207163291197943825",
    // PHOCKET_BANKBAZAAR: "1207163291197943825",

    LOAN_FOR_15000_SALARY: "1207163299314646455",

    BorrowBuddy_App_partnered_with_Anjraj_Talent: "1207164259649342637",
    // Phocket_App_partnered_with_Anjraj_Talent: "1207164259649342637",

    Dear_Customer_Refer_your_friends:"1207164259654452739",

    BorrowBuddy_App_partnered_with_Genesis :"1207164303566767399",
    // Phocket_App_partnered_with_Genesis :"1207164303566767399",

    BorrowBuddy_App_partnered_with_Hyrexpert: "1207164371304381586",
    // Phocket_App_partnered_with_Hyrexpert: "1207164371304381586",

    BorrowBuddy_App_partnered_with_Karyarth:"1207164456699215882",
    // Phocket_App_partnered_with_Karyarth:"1207164456699215882",

    Borrowbuddy_App_partnered_with_SAM_Career:"1207164456705063170",
    // Phocket_App_partnered_with_SAM_Career:"1207164456705063170",

    PHONEPARLOAN:"1207164622455144923",
    REPEAT_CAMPAIGN:'1207170048184280986',
    TWICE_APPROVED_AMOUNT:'1207170866367680628',
    MAXIMIZE_APPROVED_AMOUNT:'1207170866981714667'


}
const HeaderType={
    REVIEW_ON_PLAYSTORE: "Service Implicit",
    MONTH_END_SAL_ADVANCE: "Service Explicit",
    BILL_PAY_SAL_ADVANCE: "Service Explicit",

    BORROWBUDDY_APP_TRY_KARO: "Service Explicit",
    // PHOCKET_APP_TRY_KARO: "Service Explicit",

    JHATPAT_LOAN: "Service Explicit",
    // ELIGIBLE_FOR_ADVANCE_SAL_2_LAC: "1207168605298092572",

    ELIGIBLE_FOR_ADVANCE_SAL_2_LAC: "Promotional",

    FESTIVE_SHOPPING_LOAN: "Service Explicit",
    LOAN_WITHIN_FEW_MINUTES: "Service Explicit",
    LOAN_WITHIN_5_MINUTES: "Service Explicit",
    PERSONAL_LOAN: "Service Explicit",
    UPTO_200000_LOAN: "Service Explicit",
    URGENT_CASH: "Service Explicit",
    LOAN_WAITING: "Service Explicit",
    LOAN_AT_LOW_INTEREST: "Service Explicit",
    MONEY_AT_SPEED_OF_ROCKET: "Service Explicit",
    ADVANCE_SALARY_VIA_BANKBAZAAR: "Service Explicit",
    BANKBAZAAR: "Service Explicit",
    SALARY_12_MONTH: "Service Explicit",

    GET_A_BORROWBUDDY_PL: "Service Explicit",
    // GET_A_PHOCKET_PL: "Service Explicit",

    ADVANCE_SAL_BY_BUDDYLOAN: "Service Explicit",

    PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY: "Service Explicit",
    // PRE_QUALIFIED_BUDDY_LOAN_PHOCKET: "Service Explicit",

    BORROWBUDDY_BANKBAZAAR: "Service Explicit",
    // PHOCKET_BANKBAZAAR: "Service Explicit",

    LOAN_FOR_15000_SALARY: "Service Explicit",

    BorrowBuddy_App_partnered_with_Anjraj_Talent: "1207164259649342637",
    // Phocket_App_partnered_with_Anjraj_Talent: "1207164259649342637",

    Dear_Customer_Refer_your_friends:"1207164259654452739",

    BorrowBuddy_App_partnered_with_Genesis :"1207164303566767399",
    // Phocket_App_partnered_with_Genesis :"1207164303566767399",

    BorrowBuddy_App_partnered_with_Hyrexpert: "1207164371304381586",
    // Phocket_App_partnered_with_Hyrexpert: "1207164371304381586",

    BorrowBuddy_App_partnered_with_Karyarth:"1207164456699215882",
    // Phocket_App_partnered_with_Karyarth:"1207164456699215882",

    Borrowbuddy_App_partnered_with_SAM_Career:"1207164456705063170",
    // Phocket_App_partnered_with_SAM_Career:"1207164456705063170",
    
    PHONEPARLOAN:"1207164622455144923",
    REPEAT_CAMPAIGN:'Promotional',
    TWICE_APPROVED_AMOUNT:'Promotional',
    MAXIMIZE_APPROVED_AMOUNT:'Promotional'


}
export {
    MSG_STRING,
    TemplateId,
    HeaderType
};
