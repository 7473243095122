import React, { Component } from 'react';
import PhocketCalculator from '../../../presentationals/Admin/Utility/EMICalculator.component'
import { calculateEmiApi } from '../AdminActionCreator.component'
import { _formatDateInDash, _preFormatDate } from '../../../../utils/validation';
import moment from 'moment'
import { APIS } from '../../../../utils/api-factory'
let gst = 0.18
let initialAmount = 5000
let initialMonths = 1
let initialsalaryDate = new Date()
let totalAmount = 5000
let monthArray = [1]

class PhocketCalc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calculatedData: [],
            totalAmount: totalAmount,
            emiLoader: false,
            // AmountLabel: { 5000: '5000', 40000: '40000', 70000: '70000', 100000: '100000', 2000000: '2000000' },
            AmountLabel: {},

            MonthsLabel: { 1: '1', 2: '2', 3: '3', 6: '6', 12: '12' },
            MonthMax: 12,
            SalaryLabel: { 1: '1', 5: '5', 10: '10', 15: '15', 20: '20', 25: '25', 31: '31' },
            emiCalcData: {
                amount: initialAmount,
                EMIMonths: initialMonths,
                salaryDate: _formatDateInDash(new Date()),
                disburseDate: new Date(),
                disburseDateToSend: _formatDateInDash(new Date()),
                repaymentDateToSend: ''
            },
            minimumAmount: '',
            maximumAmount: '',
            salaryDateToSend:'',
            dateForTable:'',
            loanMsges: [
                {
                    "minAmount": 5000,
                    "installment": 1,
                    "maxAmount": 100000
                },
                {
                    "minAmount": 5000,
                    "installment": 2,
                    "maxAmount": 100000
                },
                {
                    "minAmount": 11000,
                    "installment": 3,
                    "maxAmount": 100000
                },
                {
                    "minAmount": 21000,
                    "installment": 6,
                    "maxAmount": 200000
                },
                {
                    "minAmount": 41000,
                    "installment": 12,
                    "maxAmount": 200000
                }
            ]
        }
        }

    repaymentDates() {
        let repaymentDates = []
        monthArray.map(i => {
            let dateValue = document.getElementById("repaymentDate" + i).value
            repaymentDates.push(dateValue)
        })
        let emiCalcData = Object.assign({}, this.state.emiCalcData)
        calculateEmiApi(
            this.state.emiCalcData.amount,
            this.state.emiCalcData.EMIMonths,
            this.state.emiCalcData.salaryDate,
            // repaymentDates[repaymentDates],
            this.state.emiCalcData.disburseDateToSend,
            callBack => {
                callBack.length > 0
                    ? callBack.map((emi, i) => {
                        this.setState({salaryDateToSend:emi.date})
                        // emiCalcData['repaymentDateToSend' + i] = emi.Date
                        // emiCalcData['repaymentDateToShow' + i] = moment(emi.Date,'DD-MM-YYYY')
                        
                    })
                    : null
                //   tentativeDetail.selectEMI = this.state.allDetail.loanEmi
                this.setState({ emiCalcData })
            }
        )

    }
    slectMonths(e) {

    
        if (e != 4 && e != 5 && e != 7 && e != 8 && e != 9 && e != 10 && e != 11) {
            
            let emiCalcData = Object.assign({}, this.state.emiCalcData)
            // for (let i = 1; i <= e; i++) {
            //     emiCalcData['repaymentDate' + i]
            //     emiCalcData['repaymentDateToSend' + i]
            //     let arrValue = i
            //     monthArray.push(arrValue)
            // }
            calculateEmiApi(
                this.state.emiCalcData.amount,
                e,
                this.state.emiCalcData.salaryDate,
                this.state.emiCalcData.disburseDateToSend,
                callBack => {
                  callBack.length > 0
                    ? callBack.map((emi, i) => {
                      emiCalcData['repaymentDateToSend' + i] = emi.Date
                      emiCalcData['lastEmiDate' + i] = ''
                    })
                    : null
        
                  this.setState({  emiCalcData })
                }
              )
            if (e == 1) {

                emiCalcData.EMIMonths = 1
                this.setState({
                    AmountLabel: { 5000: '5000', 100000: '100000' },
                    // MonthMax: 2,
                    minimumAmount: (this.state.loanMsges[0].minAmount),
                    maximumAmount: this.state.loanMsges[0].maxAmount,
                    emiCalcData
                })

                // console.log(this.state.loanMsges[0])
                // this.setState({
                //     AmountLabel: { 5000: '5000', 10000: '10000' },
                //     MonthMax: 2,
                //     minimumAmount:this.state.loanMsges[0].minAmount,
                //     maximumAmount:this.state.loanMsges[0].maxAmount,
                //     emiCalcData
                // })
                monthArray = [1]


            }
            if (e == 2) {

                emiCalcData.EMIMonths = 2

                this.setState({
                    AmountLabel: { 5000: '5000', 100000: '100000' },
                    // MonthMax: 2,
                    minimumAmount: (this.state.loanMsges[0].minAmount),
                    maximumAmount: this.state.loanMsges[0].maxAmount,
                    emiCalcData
                })

                // console.log(this.state.loanMsges[0])
                // this.setState({
                //     AmountLabel: { 5000: '5000', 10000: '10000' },
                //     MonthMax: 2,
                //     minimumAmount:this.state.loanMsges[0].minAmount,
                //     maximumAmount:this.state.loanMsges[0].maxAmount,
                //     emiCalcData
                // })
                monthArray = [1, 2]


            }
            if (e == 3) {

                emiCalcData.EMIMonths = 3
                // this.repaymentDates()

                this.setState({
                    AmountLabel: { 11000: '11000', 100000: '100000' },
                    // MonthMax: 2,
                    minimumAmount: (this.state.loanMsges[2].minAmount),
                    maximumAmount: this.state.loanMsges[2].maxAmount,
                    emiCalcData
                })

                // this.setState({
                // AmountLabel: { 110000: '110000', 20000: '20000' },
                // MonthMax: 3,
                // // EMIMonths: 3
                // minimumAmount: this.state.loanMsges[1].minAmount,
                // maximumAmount:this.state.loanMsges[1].maxAmount,
                // emiCalcData
                // })
                monthArray = [1, 2, 3]
            }
            if (e == 6) {

                emiCalcData.EMIMonths = 6

                this.setState({
                    AmountLabel: { 21000: '21000', 200000: '200000' },
                    // MonthMax: 2,
                    minimumAmount: (this.state.loanMsges[3].minAmount),
                    maximumAmount: this.state.loanMsges[3].maxAmount,
                    emiCalcData
                })

                // this.setState({
                //     AmountLabel: { 21000: '21000', 40000: '40000' },
                //     MonthMax: 6,
                //     // EMIMonths: 6
                //     minimumAmount:21000,
                //     maximumAmount:40000,
                //     emiCalcData

                // })
                monthArray = [1, 2, 3, 4, 5, 6]

            }
            if (e == 12) {
                
                emiCalcData.EMIMonths = 12

                this.setState({
                    AmountLabel: { 41000: '41000', 200000: '200000' },
                    // MonthMax: 2,
                    minimumAmount: (this.state.loanMsges[4].minAmount),
                    maximumAmount: this.state.loanMsges[4].maxAmount,
                    emiCalcData
                })

                // this.setState({
                //     AmountLabel: { 41000: '41000', 200000: '200000' },
                //     MonthMax: 12,
                //     // EMIMonths: 12
                //     minimumAmount:41000,
                //     maximumAmount:200000,
                //     emiCalcData
                // })
                monthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            }
            // emiCalcData.EMIMonths = e
            // this.setState({emiCalcData})
        } else {
            document.getElementById('repaymentDate').disabled = true;
        }
    }
    calculateEmiAmount() {
        const { admin } = this.props
        this.setState({ emiLoader: true })
        let repaymentDates = []
        monthArray.map(i => {
            let dateValue = document.getElementById("repaymentDate" + i).value
            // console.log(dateValue)
            repaymentDates.push(dateValue)
            // repaymentDates.map((date,i)=>{
                this.setState({dateForTable:repaymentDates })
            // })
        })
        calculateEmiApi(this.state.emiCalcData.amount, this.state.emiCalcData.EMIMonths, repaymentDates[repaymentDates.length-1], this.state.emiCalcData.disburseDateToSend,
            (callBack) => {
                totalAmount = 0
                callBack.length > 0 ? callBack.map(data => {
                    totalAmount += Number(data.PAYABLEAMOUNT)
                }) : null
                this.setState({
                    calculatedData: callBack,
                    totalAmount: totalAmount,
                    emiLoader: false
                })
            })
    }

    render() {
        return (

            <div className="full-height">
                <PhocketCalculator
                dateForTable={this.state.dateForTable}
                    minimumAmount={this.state.minimumAmount}
                    maximumAmount={this.state.maximumAmount}
                    OnChange={this.OnChange.bind(this)}
                    OnClickHandler={this.OnClickHandler.bind(this)}
                    calculatedData={this.state.calculatedData}
                    emiCalcData={this.state.emiCalcData}
                    totalAmount={this.state.totalAmount}
                    emiLoader={this.state.emiLoader}
                    AmountLabel={this.state.AmountLabel}
                    MonthsLabel={this.state.MonthsLabel}
                    SalaryLabel={this.state.SalaryLabel}
                    MonthMax={this.state.MonthMax}
                    monthArray={monthArray}
                    slectMonths={this.slectMonths.bind(this)}
                />
            </div>
        )
    }


    componentDidMount() {
        this.calculateEmiAmount()



    }

    OnChange(e, type, index) {
        let emiCalcData = Object.assign({}, this.state.emiCalcData)
        if (type == 'amount') {
            // if (e <= 10000) {
            //     emiCalcData.EMIMonths = 2
            //     this.setState({
            //         AmountLabel: { 1: '1', 2: '2' },
            //         MonthMax: 2,
            //         emiCalcData
            //     })
            //     monthArray = [1, 2]


            // }
            // if (e > 10000) {
            //     emiCalcData.EMIMonths = 3

            //     this.setState({
            //         MonthsLabel: { 1: '1', 2: '2', 3: '3' },
            //         MonthMax: 3,
            //         // EMIMonths: 3
            //         emiCalcData
            //     })
            //     monthArray = [1, 2, 3]
            // }
            // if (e >= 21000 && 3 <= 200000) {
            //     emiCalcData.EMIMonths = 6

            //     this.setState({
            //         MonthsLabel: { 1: '1', 2: '2', 3: '3', 6: '6' },
            //         MonthMax: 6,
            //         // EMIMonths: 6
            //         emiCalcData

            //     })
            //     monthArray = [1, 2, 3, 4, 5, 6]

            // }
            // if (e >= 41000 && e <= 200000) {
            //     emiCalcData.EMIMonths = 12

            //     this.setState({
            //         MonthsLabel: { 1: '1', 2: '2', 3: '3', 6: '6', 12: '12' },
            //         MonthMax: 12,
            //         // EMIMonths: 12
            //         emiCalcData
            //     })
            //     monthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

            // }
            emiCalcData.amount = e
            this.setState({ emiCalcData })
            // if (e <= 10000) {
            //     this.setState({
            //         MonthsLabel: { 1: '1' },
            //         MonthMax: 1,
            //         EMIMonths: 1
            //     })
            // } else {
            //     this.setState({
            //         MonthsLabel: { 1: '1', 2: '2', 3: '3' },
            //         MonthMax: 3,
            //     })
            // }
            // emiCalcData.amount = e
        } else if (type == 'month') {
            if (e != 4 && e != 5 && e != 7 && e != 8 && e != 9 && e != 10 && e != 11) {
                monthArray = []
                for (let i = 1; i <= e; i++) {
                    emiCalcData['repaymentDate' + i]
                    emiCalcData['repaymentDateToSend' + i]
                    let arrValue = i
                    monthArray.push(arrValue)
                }
                if (e == 1 || e == 2) {
                    emiCalcData.EMIMonths = 2
                    this.setState({
                        AmountLabel: { 5000: '5000', 10000: '10000' },
                        MonthMax: 2,
                        emiCalcData
                    })
                    monthArray = [1, 2]


                }
                if (e == 3) {
                    emiCalcData.EMIMonths = 3

                    this.setState({
                        AmountLabel: { 110000: '110000', 20000: '20000' },
                        MonthMax: 3,
                        // EMIMonths: 3
                        emiCalcData
                    })
                    monthArray = [1, 2, 3]
                }
                if (e == 6) {
                    emiCalcData.EMIMonths = 6

                    this.setState({
                        AmountLabel: { 21000: '21000', 40000: '40000', 3: '3', 6: '6' },
                        MonthMax: 6,
                        // EMIMonths: 6
                        emiCalcData

                    })
                    monthArray = [1, 2, 3, 4, 5, 6]

                }
                if (e == 12) {
                    emiCalcData.EMIMonths = 12

                    this.setState({
                        AmountLabel: { 1: '1', 2: '2', 3: '3', 6: '6', 12: '12' },
                        MonthMax: 12,
                        // EMIMonths: 12
                        emiCalcData
                    })
                    monthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                }
                emiCalcData.EMIMonths = e

            } else {
                document.getElementById('repaymentDate').disabled = true;

                alert('Invalid Months')
            }
        } else if (type == 'salary') {
            calculateEmiApi(
                this.state.emiCalcData.amount,
                this.state.emiCalcData.EMIMonths,
                e,
                this.state.emiCalcData.disburseDateToSend,
                callBack => {
                  callBack.length > 0
                    ? callBack.map((emi, i) => {
                       
                      emiCalcData['repaymentDateToSend' + i] = emi.Date
                      emiCalcData['lastEmiDate' + i] = ''
                    })
                    : null
        
                  this.setState({  emiCalcData })
                }
              )
            emiCalcData.salaryDate = _formatDateInDash(e)
        } else if (type == 'disburseDate') {
            emiCalcData.disburseDate = e
            emiCalcData.disburseDateToSend = _formatDateInDash(e)
        } else if (type == 'repaymentDate') {
            monthArray.map(i => {
                // emiCalcData['repaymentDateToShow' + i]
                if (index == i) {
                    // emiCalcData['repaymentDate' + i] = e
                    
                    emiCalcData['repaymentDateToSend' + i] = _formatDateInDash(e)
                    this.setState({emiCalcData})
                }
                if (index == 0) {
                  
                    // emiCalcData['repaymentDate' + i] = e
                    emiCalcData['repaymentDateToSend' + 0] = _formatDateInDash(e)
                    this.setState({emiCalcData})
                }
                if (index == 1) {
                    // emiCalcData['repaymentDate' + i] = e
                    
                    emiCalcData['repaymentDateToSend' + 1] = _formatDateInDash(e)
                    this.setState({emiCalcData})
                }
            })
        }
        this.setState({
            emiCalcData
        })
    }

    OnClickHandler() {
        this.calculateEmiAmount()
    }
}

export default PhocketCalc;