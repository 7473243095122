import React from 'react';

const PhocketCustomerDetails = ({ forwordpage, backpage, allUserDetail, detailBackHandler,
    tableMinPagination, tableMaxPagination, phocketTablePagination }) => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <h3 className="text-center blue-text">
                        <span className="fa fa-chevron-left pull-left pointer" onClick={(e) => detailBackHandler()} />
                        <span>Detail</span>
                    </h3>
                </div>
            </div>
            <div className="row text-center">
                {allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                    <div>
                        <div className="overflow-x-auto">
                            <table className="phocket-table">
                                <thead>
                                    <tr>
                                        <th>Loan Id</th>
                                        <th>Application Id</th>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                        <th>Email ID</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{data.applicationLoanId}</td>
                                                <td>{data.applicationId}</td>
                                                <td style={{whiteSpace:'break-spaces'}}>{data.userName}</td>
                                                <td>{data.mobileNumber}</td>
                                                <td>{data.emailId}</td>
                                                <td>{data.applicationStatus}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-3 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button></div>
                                <div className="col-sm-2 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                                <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                                <div className="col-sm-2 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                                <div className="col-sm-3 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button></div> </div>
                        </div>
                    </div>
                    : ""}
            </div>
        </div>
    )

export default PhocketCustomerDetails;
