import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import ReactHtmlParser from 'react-html-parser';

const List = ({ communicationDetail, closeCouponPopup,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="">
        <div className="background-blur" onClick={e => closeCouponPopup()}></div>
        <div className="popup-main" style={{ overflowY: 'scroll', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="row">
                {/* {console.log(communicationDetail, 'communicationDetail')} */}
                <div className="overflow-x-auto">
                    <table className="phocket-table">
                        <thead>
                            <tr>

                                <th>Email ID</th>
                                <th>Mail</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr

                            >

                                <td>
                                    {communicationDetail.email.map((data, i) => {
                                        // console.log(data)
                                        return (
                                            <div>
                                                <div style={{ borderBottom: '1px solid black',padding:'10px' }}>{data.mail}<br /></div>
                                            </div>
                                        )

                                    })}
                                </td>
                                <td>{ReactHtmlParser(communicationDetail.communicationMatter)}</td>

                            </tr>
                            )
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
)

export default List;