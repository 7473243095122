import React from 'react'
import { STRING } from '../../../../utils/Constant'

const AddStatus = ({ getDataHandler, postDataHandler, couponList, merchatIdForCoupon, merchantData }) => (
    <div className="container-fluid">
        <div className="row">
            <h3 className="text-center">Add Coupon Code</h3>
        </div>
        <div className="row form-group">
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Merchant</label>
                <select className="form-control" onChange={e => getDataHandler(e, 'merchantDetail')}>
                    <optgroup className="display-none">
                        <option value="">Select Merchant</option>
                    </optgroup>
                    <optgroup label="Merchants">
                        {couponList != null && couponList !='' && couponList.length>0 ?
                            couponList.map(list => {
                                return (
                                    <option value={list.merchnatid} key={list.merchnatid}>{list.merchantname}</option>
                                )
                            })
                            : ""}
                    </optgroup>
                </select>
            </div>
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Coupons</label>
                <select className="form-control" onChange={e => getDataHandler(e, 'couponsDetail')}>
                    <optgroup className="display-none">
                        <option value="">Select Coupons</option>
                    </optgroup>
                    <optgroup label="Coupons">
                        {merchatIdForCoupon != '' && couponList != null && couponList != '' ?
                            couponList.map(list => {
                                return (
                                    <option style={{ display: merchatIdForCoupon == list.merchnatid ? 'block' : 'none' }} value={list.couponid} key={list.couponid}>{list.title}</option>
                                )
                            })
                            : ""}
                    </optgroup>
                </select>
            </div>
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Codes</label>
                <textarea className="form-control" value={merchantData.couponCode} placeholder="Enter coupon code here" onChange={e => getDataHandler(e, 'couponsCodes')}></textarea>
            </div>
        </div>
        <div className="row">
            <button className="profile-save-btn center-block" onClick={e => postDataHandler()}>SAVE</button>
        </div>
    </div>
)

export default AddStatus;