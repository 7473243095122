import React from 'react'

const ConfirmationPopup = ({ cancelLoanConfirm }) => (
    <div>
        <div className="background-blur" onClick={(e) => cancelLoanConfirm('no')}></div>
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">Are you sure to upload this CSV file.<br />
                    This data is directly inserted into database.<br />
                    Confirm your data before pressing yes.</h4>
                </div>
            </div>
            <div className="row from-group text-center">
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('yes')}>YES</button>
                </div>
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('no')}>NO</button>
                </div>
            </div>
        </div>
    </div>
)

export default ConfirmationPopup;