import React, { Component } from 'react'
import FeedbackLogs from '../../../presentationals/Admin/CustomerPages/UserFeedbackLogs.component'
import { APIS } from '../../../../utils/api-factory'
import DotLoader from '../../../presentationals/Loader/DotLoader.component'

class FeedbackDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FeedbackData: '',
            loadarState: false,
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }

    componentWillMount() {
        const { userDetail } = this.props
        this.setState({ loadarState: true })
        fetch(APIS.GET_FEEDBACK_BY_USERID + userDetail.userId)
            .then(json => json.json())
            .then(res => {
                this.setState({
                    FeedbackData: res,
                    loadarState: false
                })
            })
    }

    render() {
        return (
            <div className="full-height">
                {this.state.loadarState == true ?
                    <DotLoader />
                    : <FeedbackLogs
                        FeedbackData={this.state.FeedbackData}
                        allDetail={this.props.allDetail}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                    />}
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

}

export default FeedbackDetail;