import React from 'react'
import Ui from '../../../presentationals/Admin/Tea Garden/wages.component'
import { uploadByCsvWages } from './actioncreator'
import Sidepopup from '../../../presentationals/Popup/Popup.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import PayRoleDataPage from '../../../presentationals/Admin/Tea Garden/newpayroldata.component'
class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            nameFile: '',
            csvFile: '',
            teagardenId: 'kCV6VBiWe9FlUQTuiBuBoASA7NsgkRAs',
            loaderState: false,
            payRoleData: {},
            payRoleDataUi: false
        }
    }
    changeHandler(e) {
        // 2(e.target.files)
        if ((e.target.files).length > 0) {
            this.setState({ nameFile: e.target.files[0].name, csvFile: e.target.files[0] })
        } else {
            this.setState({ nameFile: '' })

        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    sumbit() {
        if (this.state.csvFile !== '' && this.state.csvFile !== undefined) {
            this.setState({ loaderState: true })
            uploadByCsvWages(this.state.csvFile, this.state.teagardenId, callBack => {
                // 2((callBack))
                if (callBack ) {
                    // 2(callBack,'callback')
                    this.setState({ payRoleData: JSON.parse(callBack), payRoleDataUi: true, popupState: true, popupStatus: 'Uploaded Successfully !', csvFile: '', nameFile: '', loaderState: false }, () => this.removePopup())
                } else {
                    this.setState({ popupState: true, popupStatus: 'Please try Again Later !', csvFile: '', nameFile: '', loaderState: false }, () => this.removePopup())

                }

            })
        } else {
            this.setState({ popupState: true, popupStatus: 'Select File First' }, () => this.removePopup())

        }
    }
    printDiv() {
        var divContents = document.getElementById("pdf-tea").innerHTML;
        // 2(divContents)
        var a = window.open('', '', 'height=500, width=500');
        a.document.write(divContents);
        a.document.close();
        a.print();


    }


    render() {
        return (
            <div>

                <Ui sumbit={this.sumbit.bind(this)} nameFile={this.state.nameFile} changeHandler={this.changeHandler.bind(this)} />
                {this.state.popupState ?
                    <Sidepopup popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.payRoleDataUi ?
                    <PayRoleDataPage
                    printDiv={this.printDiv.bind(this)}
                    csvDataNew={this.state.payRoleData} />
                    : ""}
            </div>
        )
    }
}

export default Cards