import React from 'react'
import { ROLE, STRING, PLACEHOLDER, TYPE } from '../../../utils/Constant'

const ButtonPopup = ({ admin, buttonsForFunction, RfdFunction, closeButtonPopup, cancelLoanConfirm, confirmationText }) => (
    <div className="background-blur">
        <div className="button-popup container-fluid max-width-500px dbl-border">
            {/* <div style={{ textAlign: 'right' }}><i class="fa fa-close" style={{ color: 'red', cursor: 'pointer' }} onClick={closeButtonPopup}></i></div> */}
            {admin.rolelist.indexOf(ROLE.Mark_InComplete) >= 0 ?
                <div className="row from-group text-center" >
                    {/* {(admin.department && admin.department !== 'Legal' )||admin.emailId==='SuperUser'? */}
                    <div className="col-xs-12 col-sm-12">
                        <button className='btn btn-primary' style={{ width: '145px' }} onClick={() => buttonsForFunction('incomplete')}> Mark Application<br /> Incomplete</button>
                    </div>
                    {/* :""} */}
                </div>
                : ""}
            {admin.rolelist.indexOf(ROLE.Mark_Complete) >= 0 ?
                <div className="row from-group text-center" >
                    {/* {(admin.department && admin.department === 'Customer Service'||admin.emailId==='SuperUser') ? */}
                    <div className="col-xs-12 col-sm-12">
                        <button className='btn btn-primary' style={{ width: '145px' }} onClick={() => buttonsForFunction('complete')}> Mark Application <br />Complete</button>
                    </div>
                    {/* : ""}  */}
                </div>
                : ""}

            <div>
                {admin.rolelist.indexOf(ROLE.Mark_RFD) >= 0 ?
                    <div className="row text-center" >
                        {/* {(admin.department && admin.department === 'Finance / Credit' )||admin.emailId==='SuperUser'? */}

                        <div className="col-xs-12 col-sm-12">

                            <button className='btn btn-primary' style={{ width: '145px' }} onClick={() => RfdFunction('202')}> Mark as RFD</button>
                        </div>
                        {/* : ""} */}
                    </div>
                    : ""}
                {admin.rolelist.indexOf(ROLE.Mark_PFD) >= 0 ?
                    <div className='row'>
                        {/* {(admin.department && admin.department === 'Legal' )||admin.emailId==='SuperUser'? */}

                        <div className="col-xs-12 col-sm-12">
                            <button className='btn btn-primary' style={{ width: '145px' }} onClick={() => RfdFunction('201')}> Mark as PFD</button>
                        </div>
                        {/* : ""} */}
                    </div>
                    : ""}
            </div>



        </div>
        {/* <div className='str'></div> */}
    </div>
)

export default ButtonPopup;