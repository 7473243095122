import React, { useState } from "react";
import { Pagination } from "@mui/material";
import { formatDate } from "../../utils/utils";
function PushLeadsListTable({ pushAllLeads }) {
  const [page, setPage] = useState(1);
  return (
    <>
   <div className='credit-table'>
            <table>
              <thead style={{ background: '#b9d8f9', color: '#000' }}>
                <tr className="thead-class">
              <th className="">S.No.</th>
              <th className="">Email Id</th>
              <th className="">Mobile Number</th>

              <th className="">First Name</th>

              <th className="">Form Status</th>
              <th className="">Created Date</th>
            </tr>
          </thead>
          <tbody>
            {pushAllLeads.length > 0 &&
              pushAllLeads.map((item, index) => {
                return (
                  <tr style={{ borderBottom: "none" }}>
                    <td scope="col">
                      <span id="comment">
                        <b>{index + 1}</b>
                      </span>
                    </td>
                    <td scope="col">
                      <span id="comment">{item.email_id || ""}</span>
                    </td>
                    <td scope="col">
                      <span id="comment">{item.mobile_number || ""}</span>
                    </td>

                    <td scope="col">
                      <span id="comment">{item.first_name || ""}</span>
                    </td>

                    <td scope="col">
                      <span id="comment">{item.form_status || ""}</span>
                    </td>
                    <td scope="col">
                      <span id="comment">
                        {formatDate(item.created_date) || ""}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="" style={{float:'right'}}>
        {/* <div className="d-inline-flex  pt-2">
          <p className="mt-1">
            <b>Go to page :</b>{" "}
          </p>
          <p className="mx-2 my-0">
            <select className="page-box" name="pageNumber" id="">
              <option value="number">1</option>
            </select>
          </p>
          <p className="mt-1">
            <b>of 10</b>
          </p>
        </div> */}
        <div className="">
          <Pagination
            style={{ alignSelf: "center" }}
            count={10}
            color="primary"
            shape="circle"
            onChange={(event, value) => {
              console.log(value);
              setPage(value);
            }}
            defaultValue={5}
            page={page}
          />
        </div>
      </div>
    </>
  );
}

export default PushLeadsListTable;
