import React, { Component } from 'react'
import AddNBFCUi from '../../../presentationals/Admin/NbfcManagementNew/addNbfc.component';
import { APIS } from '../../../../utils/api-factory'
import Popup from '../../../presentationals/Popup/Popup.component';
import { nbfcValidation } from '../../../../utils/validation'
import { saveNbfcApi, uploadNbfcLogo } from '../AdminActionCreator.component';
import Loader from '../../../presentationals/Loader/Loader.component'
let paginationNumber = 10;

class AddNbfc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderState: false,
            allNbfcList: [],
            searchState: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            popupState: false,
            popupStatus: '',
            newNbfc: '',
            nbfcData: {
                id: '',
                name: '',
                maxDays: '',
                maxMonth: '',
                interestRate: '',
                nbfcId: '',
                columnsAllowed: '',
                apiKey: '',
                logoUrl: '',
                dynamicEmi: '',
                contactPersonName: '',
                contactPersonNumber: '',
                city: '',
                pinCode: '',
                state: '',
                address: ''
            },
            validationMsg: ''
        }
    }

    componentDidMount() {
        // this.getNbfcList('')
        if (this.props && this.props.nbfcData !== undefined) {
            let changedata = Object.assign({}, this.state.nbfcData)
            changedata.id = this.props.nbfcData.id,
                changedata.name = this.props.nbfcData.name,
                changedata.maxDays = this.props.nbfcData.maxDays,
                changedata.maxMonth = this.props.nbfcData.maxMonth,
                changedata.interestRate = this.props.nbfcData.interestRate,
                changedata.nbfcId = this.props.nbfcData.nbfcId,
                changedata.columnsAllowed = this.props.nbfcData.columnsAllowed,
                changedata.apiKey = this.props.nbfcData.apiKey,
                changedata.logoUrl = this.props.nbfcData.logoUrl,
                changedata.dynamicEmi = this.props.nbfcData.dynamicEmi
            changedata.contactPersonName = this.props.nbfcData.contactPersonName
            changedata.contactPersonNumber = this.props.nbfcData.contactPersonNumber
            changedata.city = this.props.nbfcData.city
            changedata.pinCode = this.props.nbfcData.pinCode
            changedata.state = this.props.nbfcData.state
            changedata.address = this.props.nbfcData.address
            this.setState({ nbfcData: changedata })
        }
    }
    logoHandler(e) {
        if (this.state.nbfcData.name !== '') {
            uploadNbfcLogo(e.target.files[0], this.state.nbfcData.name, callback => {
                // console.log(callback)
                let changeData = Object.assign({}, this.state.nbfcData)
                changeData.logoUrl = callback
                this.setState({ nbfcData: changeData })
            })
        } else {

        }
    }
    getNbfcList(data) {
        this.setState({ loaderState: true })
        fetch(APIS.GET_LIST_OF_NBFC + data)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allNbfcList: res,
                    loaderState: false
                })
            })
    }

    render() {
        let allNbfcList = this.state.allNbfcList
        // if (this.state.searchState) {
        //     allNbfcList = allNbfcList.filter(row => {
        //         if (row != null) {
        //             return row.toLowerCase().includes(this.state.searchState.toLowerCase())
        //         }
        //     })
        // }
        return (
            <div>
                <AddNBFCUi
                    editHandler={this.editHandler.bind(this)}
                    nbfcData={this.state.nbfcData}
                    logoHandler={this.logoHandler.bind(this)}
                    validationMsg={this.state.validationMsg}
                    allNbfcList={this.state.allNbfcList}
                    searchHandler={this.searchHandler.bind(this)}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                    addNbfcHandler={this.addNbfcHandler.bind(this)}
                    addNbfcChange={this.addNbfcChange.bind(this)}
                    newNbfc={this.state.newNbfc} />
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
            </div>
        )
    }

    addNbfcChange(e, type) {
        // this.setState({ newNbfc: e.target.value });
        let changeData = Object.assign({}, this.state.nbfcData)

        if (type === 'name') {
            changeData.name = e.target.value
        }
        if (type === 'maxDays') {
            changeData.maxDays = e.target.value

        }
        if (type === 'maxMonth') {
            changeData.maxMonth = e.target.value

        }
        if (type === 'interestRate') {
            changeData.interestRate = e.target.value

        }
        if (type === 'columnsAllowed') {
            changeData.columnsAllowed = e.target.value

        }
        if (type === 'apiKey') {
            changeData.apiKey = e.target.value

        }
        if (type === 'dynamicEmi') {
            changeData.dynamicEmi = e.target.value

        }
        if (type === 'contactPersonName') {
            changeData.contactPersonName = e.target.value
        }
        if (type === 'contactPersonNumber') {
            changeData.contactPersonNumber = e.target.value
        }
        if (type === 'city') {
            changeData.city = e.target.value
        }
        if (type === 'state') {
            changeData.state = e.target.value
        }
        if (type === 'address') {
            changeData.address = e.target.value
        }
        if (type === 'pinCode') {
            changeData.pinCode = e.target.value
        }
        this.setState({ nbfcData: changeData })
    }

    addNbfcHandler() {
        let validation = nbfcValidation(this.state.nbfcData)
        if (validation.status) {
            // fetch(APIS.SAVE_NBFC + this.state.newNbfc + '&razorpayApi=&razorpaySecret=')
            //     .then(res => res.text())
            //     .then(res => {
            //         if (res == 'success') {
            //             this.setState({
            //                 popupState: true,
            //                 popupStatus: "Added successfully!",
            //                 newNbfc: ''
            //             })
            //             this.getNbfcList()
            //             this.removePopup();
            //         } else {
            //             this.setState({
            //                 popupState: true,
            //                 popupStatus: "Please try again",
            //             })
            //             this.removePopup();
            //         }
            //     })
            this.setState({ loaderState: true })
            saveNbfcApi(this.state.nbfcData, Callback => {
                // console.log(Callback)
                if (Callback === 'success') {
                    this.setState({ popupState: true, popupStatus: 'Added Successfully !' }, () => this.removePopup())
                    // let changedata = Object.assign({}, this.state.nbfcData)
                    // changedata.id = '',
                    //     changedata.name = '',
                    //     changedata.maxDays = '',
                    //     changedata.maxMonth = '',
                    //     changedata.interestRate = '',
                    //     changedata.nbfcId = '',
                    //     changedata.columnsAllowed = '',
                    //     changedata.apiKey = '',
                    //     changedata.logoUrl = '',
                    //     changedata.dynamicEmi = ''
                    // this.setState({ nbfcData: changedata })

                    this.getNbfcList('')
                } else {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'Please try again later !' }, () => this.removePopup())
                }
            })
        } else {
            this.setState({ validationMsg: validation })
        }
    }
    editHandler(data) {
        // console.log(data)
        let changedata = Object.assign({}, this.state.nbfcData)
        changedata.id = data.id,
            changedata.name = data.name,
            changedata.maxDays = data.maxDays,
            changedata.maxMonth = data.maxMonth,
            changedata.interestRate = data.interestRate,
            changedata.nbfcId = data.nbfcId,
            changedata.columnsAllowed = data.columnsAllowed,
            changedata.apiKey = data.apiKey,
            changedata.logoUrl = data.logoUrl,
            changedata.dynamicEmi = data.dynamicEmi
        this.setState({ nbfcData: changedata })
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    searchHandler(e) {
        this.getNbfcList(e.target.value)
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
}

export default AddNbfc;