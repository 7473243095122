import React from 'react'

const PasswordPopup = ({ docPassword, docPasswordHandler, passwordErrorMsg }) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    {/* <button className="close" onClick={()=>closePopup}>&times;</button> */}
                    <h4 className="text-center">Enter Bank Statement Password</h4>
                </div>
            </div>
            <div className="row from-group">
                <div className="col-xs-2"></div>
                <div className="col-xs-8">
                    <input type="text" className="form-control" onChange={(e) => docPassword(e)} />
                </div>
                <div className="col-xs-2"></div>
            </div>
            <div className="row from-group">
                <div className="col-xs-12 text-center red-text">{passwordErrorMsg}</div>
            </div>
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="btn btn-primary" style={{marginTop:'20px'}} onClick={(e) => docPasswordHandler(e, 'bank')}>SAVE</button>
                </div>
            </div>
        </div>
    </div>
)

export default PasswordPopup;