import React from 'react'
import ReactTable from "react-table";
import ReactHtmlParser from 'react-html-parser';
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const PromotionalDetails = ({ FeedbackData, allDetail,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid Phocket-promotional-details">
        <div className="row form-group">
            <div className="col-xs-12">
                {allDetail != null && allDetail.documentList != null && allDetail.documentList.comment != null ?
                    <h3 className="text-center blue-text max-width-750px center-block">{ReactHtmlParser(allDetail.documentList.comment)}</h3>
                    : <h3 className="text-center red-text">No Comments Given</h3>}
            </div>
        </div>
        <div className="row form-group overflow-auto">
           
                    {FeedbackData != null && FeedbackData != '' && FeedbackData.length > 0 ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <th> Date</th>
                                    <th>Service Experience</th>
                                    <th>Future Use</th>
                                    <th>Friendliness</th>
                                    <th>Knowledge</th>
                                    <th>Quickness</th>
                                    <th>Doc Exp</th>
                                    <th>Suggessions</th>
                                </tr>

                            </thead>
                            <tbody>
                                {FeedbackData && FeedbackData.map((promo, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{promo.feedbackDate}</td>
                                            <td>{promo.happyWithService}</td>
                                            <td>{promo.useAppFuture}</td>
                                            <td>{promo.friendlinessRating}</td>
                                            <td>{promo.knowledgeRating}</td>
                                            <td>{promo.quicknessRating}</td>
                                            <td>{promo.docExperience}</td>
                                            <td>{promo.suggestion}</td>


                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : ""}
        </div>
    </div>
)

export default PromotionalDetails;