import React from 'react'
import DotLoader from '../../Loader/layerLoader.component'

const AddNBFC = ({ allNbfcList, searchHandler, phocketTablePagination, newNbfc,
    tableMinPagination, tableMaxPagination, addNbfcHandler, addNbfcChange }) => (
        <div className="container-fluid">
            <div className="row">
                <h3 className="text-center blue-text">NBFC</h3>
            </div>
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Add NBFC</label>
                    <input type="text" className="form-control" onChange={e => addNbfcChange(e)} value={newNbfc} />
                    <button className="btn profile-save-btn center-block" onClick={e => addNbfcHandler()}>Save</button>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <span className="pull-right">
                        <input type="text" className="form-control" onChange={e => searchHandler(e)} placeholder="Search" />
                    </span>
                    {allNbfcList != null && allNbfcList.length > 0 ?
                        <div>
                            <table className="phocket-table">
                                <thead>
                                    <tr>
                                        <th>NBFC Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allNbfcList.slice(tableMinPagination, tableMaxPagination).map((nbfc, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{nbfc.name}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block grey  " onClick={e => phocketTablePagination(allNbfcList, 'back')}></button></div>
                                    <div className="col-sm-4 col-xs-12 text-center phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allNbfcList.length}</div>
                                    <div className="col-sm-4 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block grey" onClick={e => phocketTablePagination(allNbfcList, 'forward')}></button></div>
                                </div>
                            </div>
                        </div>
                        : <DotLoader />}
                </div>
            </div>
        </div>
    )

export default AddNBFC;