import React, { useState, useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import VerifyImage from '../../../../../images/verified.png'
import NotVerified from '../../../../../images/cross.png'
import { ROLE } from '../../../../../utils/Constant'
import moment from 'moment'
const CrifNew = ({ zoomState, zoomPage, crifprevdata, allDetail, jsondata, crfJsonData, underWriterHandler, saveUnderWritter, openCommentPopup, notesPopup, closepopup, newNotes, commentChangeHandler, saveNotesType, admin, notesType, crifTab, analysisData, crifData, userCrifData, regenerateCrif, crifLoaderData, parseData, sortedData, crifpopup, crifdatapopup,crifButtonFromApi,regenerateButton }) => {

    const containerRef = useRef(null);
    useEffect(() => {

        if ((crifprevdata !== undefined && crifprevdata !== null && zoomState == true) || (crifprevdata !== undefined && crifprevdata !== null)) {

            if (containerRef.current) {
                const firstBox = containerRef.current.querySelector('.box');
                if (firstBox) {
                    firstBox.style.padding = '0px';
                }
            }
        }
    }, [crifprevdata]);

    return (
        <>
            {zoomState ?
                crifprevdata ?
                    <div className='container-fluid' style={{ position: 'absolute', zIndex: '1000' }}>
                        <div className="background-blur">
                            < div className="modal" style={{ display: 'block', overflow: 'auto' }}>
                                <div className="modal-dialog" style={{ width: '1200px', margin: '10px auto' }}>
                                    <div className="modal-content" style={{ padding: '10px' }}>
                                        <div className="modalheader" style={{  marginBottom: '10px',textAlign:'end' }}>
                                            <button type="button" onClick={() => zoomPage()} style={{ position: 'absolute', zIndex: '100', marginLeft: '-1%' ,background:'red',padding:'5px'}}>
                                                <i className="fa fa-times fs-2" style={{color:'#fff'}}></i>
                                            </button>
                                        </div>
                                        <div className="modal-body" style={{ marginTop: '50px', maxHeight: 'calc(100vh - 60px)', overflowY: 'auto' }}>
                                            <div dangerouslySetInnerHTML={{ __html: crifprevdata }} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='container-fluid'>
                        <div className="background-blur">

                            <div className='text-right' style={{
                            }}>
                                <i onClick={() => zoomPage('zoom')} style={{
                                    fontSize: '14px', cursor: 'pointer', marginRight: '4.9%',
                                    marginTop: '79px',
                                    position: 'relative',
                                    zIndex: 99,
                                    height: '25px',
                                    width: '25px',
                                    background: 'red',
                                    color: '#fff', padding: '4px 7px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'
                                }} class="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="popup-main small-popup container  " style={{ padding: '20px', borderRadius: '10px', width: '100%', maxHeight: '100%', maxWidth: '90%', overflow: 'scroll', paddingBottom: '100px' }}>
                                <div className=' ' style={{ marginBottom: '20px' }}>

                                    <div className='row'>
                                        {analysisData.finalDecision != null && crifTab === 'crifreport' ?
                                            <div className='col-sm-6 col-xs-6'>
                                                <div style={{ borderRadius: '6px', border: analysisData.finalDecision === 'Rejected' ? '1px solid  #FF4444' : '1px solid var(--success, #00C851)', background: analysisData.finalDecision === 'Rejected' ? '#FEECEB' : 'var(--light-green, ##FEECEB)', padding: '10px' }}>
                                                    <div className='row'>
                                                        <div className='col-sm-6 col-xs-6'>
                                                            <p style={{ marginTop: '15px' }}>Automated Decision</p>
                                                        </div>
                                                        <div className='col-sm-6 col-xs-6 text-right'>
                                                            <h4><b style={{ color: analysisData.finalDecision === 'Rejected' ? 'red' : analysisData.finalDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finalDecision}</b></h4>
                                                            {analysisData.fetchDate ? <> Verified on - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}
                                        {analysisData.score != '' && analysisData.score != null && crifTab === 'crifreport' ?
                                            <div className='col-sm-6 col-xs-6'>
                                                <div style={{ borderRadius: '6px', border: '1px solid #267DFF', background: '#F1F7FF', padding: '10px' }}>
                                                    <div className='row'>
                                                        <div className='col-sm-6 col-xs-6'>
                                                            <p style={{ marginTop: '15px' }}>Cibil Score</p>

                                                        </div>
                                                        <div className='col-sm-6 col-xs-6 text-right'>
                                                            <h4><b style={{ color: analysisData.score === 'Rejected' ? 'red' : analysisData.score === 'Approved' ? 'green' : '#31311f' }}>{analysisData.score}</b></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}

                                    </div>

                                    {analysisData != '' && analysisData != null && crifTab === 'crifreport' ?
                                        <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '50px' }}>


                                            <div className="row">
                                                <div className="col-sm-4 col-xs-12 form-group">
                                                    <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Dual Pan&nbsp;</b>
                                                    <h5 style={{ fontWeight: '700' }}>{analysisData.dualPan == true ?
                                                        <img src={VerifyImage} width="22px" />
                                                        : <img src={NotVerified} width="20px" />} </h5>
                                                </div>
                                                <div className="col-sm-4 col-xs-12 form-group">
                                                    <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Mobile Matched&nbsp;</b>
                                                    <h5 style={{ fontWeight: '700' }}>{analysisData.mobileMatched == true ?
                                                        <img src={VerifyImage} width="22px" />
                                                        : <img src={NotVerified} width="20px" />
                                                    }</h5>

                                                </div>
                                                <div className="col-sm-4 col-xs-12 form-group">
                                                    <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Current Address Pincode Matched&nbsp;</b>
                                                    <h5 style={{ fontWeight: '700' }}>
                                                        {analysisData.currentAddressPincodeMatched == true ?
                                                            <img src={VerifyImage} width="22px" />
                                                            : <img src={NotVerified} width="20px" />
                                                        }                     </h5>

                                                </div>

                                            </div>



                                        </div>
                                        : ""}


                                    {parseData !== '' ?
                                        <>

                                            {parseData &&
                                                parseData['INDV-REPORT-FILE'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER'] ?

                                                <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                    <h5 ><b>Equiry By</b></h5>
                                                    <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                    <div className="row">
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Date of Request&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['DATE-OF-REQUEST']} </h5>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Batch ID&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['BATCH-ID']}</h5>

                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Prepared For&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>
                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['PREPARED-FOR']}                       </h5>

                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Prepared For Id&nbsp;</b>
                                                            <br />
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['PREPARED-FOR-ID']}</h5>

                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Report ID&nbsp;</b>
                                                            <br />
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['REPORT-ID']}</h5>

                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Date of Issue&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['DATE-OF-ISSUE']} </h5>

                                                        </div>
                                                    </div>



                                                </div>
                                                : ""}
                                            {parseData &&
                                                parseData['INDV-REPORT-FILE'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST'] ?

                                                <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                    <h5 ><b>Inquiry Input Information</b></h5>
                                                    <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                    <div className="row">
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Name&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['NAME']} </h5>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>DOB/Age&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['DOB']}/{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['AGE']}</h5>

                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">

                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Phone Number&nbsp;</b>
                                                            <br />
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['PHONES']['PHONE']}</h5>

                                                        </div>

                                                    </div>

                                                    <div className='row'>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Phone Number&nbsp;</b>
                                                            <br />
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['PHONES']['PHONE']}</h5>

                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>ID(s)&nbsp;</b>
                                                            <br />
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['IDS']['ID']['VALUE']}</h5>

                                                        </div>

                                                    </div>
                                                    <div className='row'>

                                                        <div className="col-sm-12 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Current Address&nbsp;</b>
                                                            <br />
                                                            <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['ADDRESSES']['ADDRESS']}</h5>

                                                        </div>

                                                    </div>
                                                </div>
                                                : ""}
                                            {parseData &&
                                                parseData['INDV-REPORT-FILE'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] ?
                                                <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                    <h5 ><b>CRIF HM Score(S)</b></h5>
                                                    <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                    <div className="row">
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORE NAME	&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-TYPE']
                                                                    ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-TYPE']
                                                                    : 'Score Type Not Available'}
                                                            </h5>                </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORE&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-VALUE']
                                                                    ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-VALUE']
                                                                    : 'Score Value Not Available'}
                                                            </h5>

                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 form-group">
                                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORING FACTORS&nbsp;</b>
                                                            <h5 style={{ fontWeight: '700' }}>
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-FACTORS']
                                                                    ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-FACTORS']
                                                                    : 'Score Factors Not Available'}
                                                            </h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                            {parseData &&
                                                parseData['INDV-REPORT-FILE'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']
                                                ?
                                                <div className='credit-second-box-small' style={{ fontSize: '1px', overflowY: 'auto' }}>
                                                    <h5 ><b>Personal Information - Variations</b></h5>
                                                    <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                    <div className=''>
                                                        <div className='col-sm-8 col-xs-12'>
                                                            <div className='variation-table'>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Name Variations	</th>
                                                                            <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {parseData['INDV-REPORT-FILE'] &&
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS'] &&
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'] &&
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'].length > 0 ? (
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                return (
                                                                                    <tr key={i}>
                                                                                        <td>{data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td>
                                                                                    {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION']?.VALUE}
                                                                                </td>
                                                                                <td style={{ textAlign: 'right' }}>
                                                                                    {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION']?.['REPORTED-DATE']}
                                                                                </td>
                                                                            </tr>
                                                                        )}



                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION']
                                                                ?
                                                                <div className='variation-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Adress Variations	</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                        </tr>
                                                                                    )
                                                                                }) : <tr>
                                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : ""}

                                                        </div>

                                                        <div className='col-sm-4 col-xs-12'>
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION']
                                                                ?
                                                                <div className='variation-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>DOB Variations	</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                        </tr>
                                                                                    )
                                                                                }) : <tr>
                                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : ""}
                                                            {parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']
                                                                ? (
                                                                    <div className='variation-table'>

                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Phone Number Variations</th>
                                                                                    <th style={{ textAlign: 'right' }}>Reported On</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {Array.isArray(parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']) &&
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].length > 0 ? (
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].map((data, i) => (
                                                                                        <tr key={i}>
                                                                                            <td>{typeof data.VALUE === 'object' ? 'Invalid Value' : data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td>{typeof parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.VALUE === 'object' ? 'Invalid Value' : parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.['REPORTED-DATE']}</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>


                                                                        </table>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            {/* {parseData &&
                                         parseData['INDV-REPORT-FILE'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']
                                         ?
                                         <div className='variation-table'>
                                             <table>
                                                 <thead>
                                                     <tr>
                                                         <th>Phone Number Variations	</th>
                                                         <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                     </tr>
                                                 </thead>
                                                 <tbody>
                                                     {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].length > 0 ?
                                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].map((data, i) => {
                                                             return (
                                                                 <tr>
                                                                     <td>{data.VALUE}</td>
                                                                     <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                 </tr>
                                                             )
                                                         }) : <tr>
                                                             <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].VALUE}</td>
                                                             <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                         </tr>}


                                                 </tbody>
                                             </table>
                                         </div>
                                         : ""} */}
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'] ?
                                                                <div className='variation-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Eamil-Id Variations	</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                        </tr>
                                                                                    )
                                                                                }) : <tr>
                                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                : ""}
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'] ?
                                                                <div className='variation-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>PAN Variations	</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                        </tr>
                                                                                    )
                                                                                }) :
                                                                                <tr>
                                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : ""}
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'] ?
                                                                <div className='variation-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Passport Variations	</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                        </tr>
                                                                                    )
                                                                                }) :
                                                                                <tr>
                                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : ""}
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'] ?
                                                                <div className='variation-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Driving Licence Variations	</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                        </tr>
                                                                                    )
                                                                                }) :
                                                                                <tr>
                                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : ""}
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'] ?
                                                                <div className='variation-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>VoterId Variations	</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                        </tr>
                                                                                    )
                                                                                }) :
                                                                                <tr>
                                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : ""}


                                                        </div>

                                                    </div>
                                                </div>
                                                : ""}
                                            {parseData &&
                                                parseData['INDV-REPORT-FILE'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY'] ?
                                                <div className='credit-second-box-small' style={{ fontSize: '1px', minHeight: '100px', overflowY: 'auto' }}>
                                                    <h5 ><b>Account Summary</b></h5>
                                                    <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                    <div className='variation-table'>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Type	</th>
                                                                    <th>Number of Account(s)		</th>
                                                                    <th>Active Account(s)	</th>
                                                                    <th>Overdue Account(s)	</th>
                                                                    <th>Current Balance	</th>
                                                                    <th>Amt Disbd/ High Credit	</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    {/* {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY'].map((data, i) => {
return (
 <> */}
                                                                    <td>Primary Match</td>
                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-NUMBER-OF-ACCOUNTS']}</td>
                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS']}</td>
                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-OVERDUE-NUMBER-OF-ACCOUNTS']}</td>
                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-CURRENT-BALANCE']}</td>
                                                                    <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-DISBURSED-AMOUNT']}</td>

                                                                    {/* </>
) */}
                                                                    {/* })} */}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES'] ?
                                                        <div className=''>
                                                            <div className='col-sm-4 col-xs-12' style={{ padding: '10px' }}>
                                                                <b>NEW ACCOUNTS IN LAST 6 MONTHS :{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES']['NEW-ACCOUNTS-IN-LAST-SIX-MONTHS']}</b>
                                                            </div>
                                                            <div className='col-sm-4 col-xs-12' style={{ padding: '10px' }}>
                                                                <b>NEW DELINQUENT ACCOUNT IN LAST 6 MONTHS :{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES']['NEW-DELINQ-ACCOUNT-IN-LAST-SIX-MONTHS']}</b>

                                                            </div>
                                                            <div className='col-sm-4 col-xs-12'></div>

                                                        </div>
                                                        : ""}
                                                </div>
                                                : ""}
                                            <div className='credit-second-box-small' style={{ fontSize: '14px', overflow: 'auto', textAlign: 'left' }}>
                                                <h5 ><b>Account Information</b></h5>
                                                <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                {parseData && parseData['INDV-REPORT-FILE'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] ?
                                                    <div className='loan-table-crif' style={{ textAlign: 'center' }} >

                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Account Type</th>
                                                                    <th>Ownership Ind</th>
                                                                    <th>Security Status</th>
                                                                    <th>Disbursed Amount</th>
                                                                    <th>Installment Amount</th>
                                                                    <th>Credit Guarantor</th>
                                                                    <th>Last Payment Date</th>
                                                                    <th>Write Off Amount</th>
                                                                    <th>Date Reported</th>
                                                                    <th>Account Status</th>
                                                                    <th>Overdue Amount</th>
                                                                    <th>Current Balance</th>
                                                                    <th>Actual Payment</th>
                                                                    <th>Disbursed Date</th>
                                                                    <th>Matched Type</th>
                                                                    <th>Closed Date</th>
                                                                    <th>Original Term</th>
                                                                    <th>Account Number</th>
                                                                    <th>Combined Payment History</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {sortedData ? sortedData.map((item, index) => {
                                                                    const combinedPaymentHistory = item['LOAN-DETAILS']['COMBINED-PAYMENT-HISTORY'];
                                                                    let splitTransactions = ''

                                                                    if (typeof combinedPaymentHistory === 'string') {
                                                                        splitTransactions = combinedPaymentHistory.split('|');
                                                                    } else {
                                                                        console.error('combinedPaymentHistory is not a string:', combinedPaymentHistory);
                                                                    }
                                                                    return (
                                                                        <tr key={index} style={{ background: item['LOAN-DETAILS']['ACCOUNT-STATUS'] === 'Closed' ? '#d2ecd2' : '#fff' }}>
                                                                            <td>{item['LOAN-DETAILS']['ACCT-TYPE']}</td>
                                                                            <td>{item['LOAN-DETAILS']['OWNERSHIP-IND']}</td>
                                                                            <td>{item['LOAN-DETAILS']['SECURITY-STATUS']}</td>
                                                                            <td>{item['LOAN-DETAILS']['DISBURSED-AMT']}</td>
                                                                            <td>{item['LOAN-DETAILS']['INSTALLMENT-AMT']}</td>
                                                                            <td>{item['LOAN-DETAILS']['CREDIT-GUARANTOR']}</td>
                                                                            <td>{item['LOAN-DETAILS']['LAST-PAYMENT-DATE']}</td>
                                                                            <td>{item['LOAN-DETAILS']['WRITE-OFF-AMT']}</td>
                                                                            <td>{item['LOAN-DETAILS']['DATE-REPORTED']}</td>
                                                                            <td>{item['LOAN-DETAILS']['ACCOUNT-STATUS']}</td>
                                                                            <td>{item['LOAN-DETAILS']['OVERDUE-AMT']}</td>
                                                                            <td>{item['LOAN-DETAILS']['CURRENT-BAL']}</td>
                                                                            <td>{item['LOAN-DETAILS']['ACTUAL-PAYMENT']}</td>
                                                                            <td>{item['LOAN-DETAILS']['DISBURSED-DATE']}</td>
                                                                            <td>{item['LOAN-DETAILS']['MATCHED-TYPE']}</td>
                                                                            <td>{item['LOAN-DETAILS']['CLOSED-DATE']}</td>
                                                                            <td>{item['LOAN-DETAILS']['ORIGINAL-TERM']}</td>
                                                                            <td>{item['LOAN-DETAILS']['ACCT-NUMBER']}</td>
                                                                            <td style={{ width: '50px' }}>
                                                                                {splitTransactions !== '' ? splitTransactions.map((transaction, transactionIndex) => {
                                                                                    const keywords = ['/SMA', '/SUB', '/STD', '/LSS', '/DBT'];
                                                                                    const shouldHighlight = keywords.some(keyword => transaction.includes(keyword));
                                                                                    return (
                                                                                        <div key={transactionIndex}>
                                                                                            {shouldHighlight ? <mark style={{ background: '#fa3c3c', color: '#fff' }}>{transaction.replace(/\|/g, ' ')}</mark> : transaction.replace(/\|/g, ' ')}
                                                                                        </div>
                                                                                    );
                                                                                }) : ""}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }) : ""}



                                                            </tbody>
                                                        </table>

                                                    </div>
                                                    : ""}
                                            </div>
                                            <div className='credit-second-box-small' style={{ fontSize: '1px', overflowY: 'auto' }}>
                                                <h5><b>Inquiries (reported for past 24 months)</b></h5>
                                                <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                {parseData &&
                                                    parseData['INDV-REPORT-FILE'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'] ?
                                                    < div className='loan-table-crif' style={{ textAlign: 'left' }} >
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Credit Grantor</th>
                                                                    <th>Date Of Inquiry</th>
                                                                    <th>Purpose</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'] ?
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].length > 0 ?
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>

                                                                                    <td>{item['MEMBER-NAME']}</td>
                                                                                    <td>{item['INQUIRY-DATE']}</td>
                                                                                    <td>{item.PURPOSE}</td>

                                                                                    <td>{item.AMOUNT}</td>

                                                                                </tr>
                                                                            )

                                                                        })
                                                                        : <tr >
                                                                            <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].AMOUNT}</td>
                                                                            <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].PURPOSE}</td>
                                                                            <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY']['MEMBER-NAME']}</td>
                                                                            <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY']['INQUIRY-DATE']}</td>

                                                                        </tr>

                                                                    : ""}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                    : ""}
                                            </div>
                                        </>
                                        : ""}



                                </div>
                            </div>

                        </div>
                    </div>
                : ""}
            {notesPopup ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                        <div className="row from-group">
                            <div className="col-xs-12" style={{ padding: '10px' }}>
                                <h5 className="" style={{ marginBottom: '10px', marginTop: '10px', fontWeight: '700' }}><i style={{ fontWeight: '700' }} className='fa fa-commenting-o'></i>&nbsp;&nbsp; Comment</h5>
                            </div>
                        </div>
                        <div className="popup-main small-popup container  " style={{ padding: '20px', borderRadius: '10px', width: '100%', maxHeight: '100%', maxWidth: '90%', overflow: 'scroll', paddingBottom: '100px' }}>
                            <div className=' ' style={{ marginBottom: '20px' }}>

                                <textarea className='form-control' placeholder='type here...' onChange={(e) => commentChangeHandler(e, notesType)} />
                                <div className='text-right'>
                                    <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                </div>
                            </div>
                            {newNotes !== '' && newNotes.length > 0 ? <h5 style={{ fontWeight: '700' }}> Previous Comments</h5> : ""}
                            {newNotes !== '' && newNotes.length > 0 ?
                                <div style={{
                                    borderRadius: '10px',
                                    border: '1px solid #D1D9E2',
                                    background: '#fff',
                                    maxHeight: '200px',
                                    overflowY: 'scroll'
                                }}>

                                    {newNotes.map((data, i) => {
                                        return (
                                            <div className='container-fluid'>
                                                <div className='row' style={{ borderBottom: '1px solid #D1D9E2', padding: '10px' }} key={i}>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        {data.notes}<br />
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.empId}</span>
                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                                    </div>

                                                </div>
                                                {/* <hr /> */}
                                            </div>
                                        )
                                    })}
                                </div>
                                : ""}

                        </div>
                    </div>
                {/* : ""} */}
                    <div className='container-fluid'>
                        {crifTab === 'crifreport' ?
                            <div className='col-xs-12  ' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                {admin.rolelist.indexOf(ROLE.CRIF_RE_GENERATE) >= 0 && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                                    <button className='btn btn-primary' onClick={e => regenerateCrif()}>
                                        Regenerate Crif
                                    </button>
                                    : ""}
                                {crifTab === 'crifreport' && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                                    <div style={{ float: 'inline-end', marginTop: '-15px' }} >
                                        <span onClick={() => openCommentPopup('crifDecision')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>

                                        <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'aadhaar')} >
                                            <option value={''}>Select Option</option>
                                            <option value={'Approved'}>Approved</option>
                                            <option value={'Reject'}>Reject</option>

                                        </select>
                                        <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter()}>Save</button>
                                    </div>
                                    : ""}
                            </div>
                            : ""}

                        {analysisData != null &&
                            analysisData.rejectNote != '' &&
                            analysisData.rejectNote != null &&
                            analysisData.rejectNote != undefined ? (
                            <h4 className='text-center'>
                                <b>

                                    <u> Notes</u>
                                </b>
                            </h4>
                        ) : (
                            ''
                        )}
                        {analysisData != null &&
                            analysisData != undefined &&
                            analysisData != '' &&
                            analysisData.rejectNote != null &&
                            analysisData.rejectNote != '' &&
                            analysisData.rejectNote.length > 0
                            ? analysisData.rejectNote.map((data, i) => {
                                return (
                                    <div key={i}>
                                        <h5 className='reject-note'>{data}</h5>
                                    </div>
                                )
                            })
                            : ''}


                        {/* {analysisData.finalDecision != null && crifTab === 'crifreport' ?
                    <p className='text-center'>
                        <h2>Automated Decision:-</h2>

                        {analysisData.finalDecision == 'Rejected' ?
                            <h1 className='blinknew text-center text-danger'>{analysisData.finalDecision}</h1>
                            : <h1 className='blinknew text-center' style={{ color: 'green' }}>{analysisData.finalDecision}</h1>}
                    </p>
                    : ""} */}

                        {crifLoaderData === false ?
                            <div className=''>
                                {crifTab === 'crifreport' ?
                                    <div className='row ' style={{ marginBottom: '20px' }}>
                                        {crifprevdata !== '' && crifprevdata !== undefined ?
                                            <div className='text-right' style={{ marginBottom: '10px' }}>
                                                <button className='btn btn-primary' onClick={() => zoomPage('zoom')}>
                                                    See Full Report&nbsp; <i style={{ cursor: 'pointer', fontSize: '20px' }} className='fa fa-expand'></i>
                                                </button>
                                            </div>
                                            : ''}
                                        <div className='row'>
                                            {analysisData.finalDecision != null && crifTab === 'crifreport' ?
                                                <div className='col-sm-6 col-xs-6'>
                                                    <div style={{ borderRadius: '6px', border: analysisData.finalDecision === 'Rejected' ? '1px solid  #FF4444' : '1px solid var(--success, #00C851)', background: analysisData.finalDecision === 'Rejected' ? '#FEECEB' : 'var(--light-green, ##FEECEB)', padding: '10px' }}>
                                                        <div className='row'>
                                                            <div className='col-sm-6 col-xs-6'>
                                                                <p style={{ marginTop: '15px' }}>Automated Decision</p>
                                                            </div>
                                                            <div className='col-sm-6 col-xs-6 text-right'>
                                                                <h4><b style={{ color: analysisData.finalDecision === 'Rejected' ? 'red' : analysisData.finalDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finalDecision}</b></h4>
                                                                {analysisData.fetchDate ? <> Verified on - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                            {analysisData.score != '' && analysisData.score != null && crifTab === 'crifreport' ?
                                                <div className='col-sm-6 col-xs-6'>
                                                    <div style={{ borderRadius: '6px', border: '1px solid #267DFF', background: '#F1F7FF', padding: '10px' }}>
                                                        <div className='row'>
                                                            <div className='col-sm-6 col-xs-6'>
                                                                <p style={{ marginTop: '15px' }}>Cibil Score</p>

                                                            </div>
                                                            <div className='col-sm-6 col-xs-6 text-right'>
                                                                <h4><b style={{ color: analysisData.score === 'Rejected' ? 'red' : analysisData.score === 'Approved' ? 'green' : '#31311f' }}>{analysisData.score}</b></h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}

                                        </div>

                                        {analysisData != '' && analysisData != null && crifTab === 'crifreport' ?
                                            <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '50px' }}>


                                                <div className="row">
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Dual Pan&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>{analysisData.dualPan == true ?
                                                            <img src={VerifyImage} width="22px" />
                                                            : <img src={NotVerified} width="20px" />} </h5>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Mobile Matched&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>{analysisData.mobileMatched == true ?
                                                            <img src={VerifyImage} width="22px" />
                                                            : <img src={NotVerified} width="20px" />
                                                        }</h5>

                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Current Address Pincode Matched&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>
                                                            {analysisData.currentAddressPincodeMatched == true ?
                                                                <img src={VerifyImage} width="22px" />
                                                                : <img src={NotVerified} width="20px" />
                                                            }                     </h5>

                                                    </div>

                                                </div>



                                            </div>
                                            : ""}


                                        {parseData !== '' ?
                                            <>

                                                {parseData &&
                                                    parseData['INDV-REPORT-FILE'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER'] ?

                                                    <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                        <h5 ><b>Equiry By</b></h5>
                                                        <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                        <div className="row">
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Date of Request&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['DATE-OF-REQUEST']} </h5>
                                                            </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Batch ID&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['BATCH-ID']}</h5>

                                                            </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Prepared For&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>
                                                                    {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['PREPARED-FOR']}                       </h5>

                                                            </div>

                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Prepared For Id&nbsp;</b>
                                                                <br />
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['PREPARED-FOR-ID']}</h5>

                                                            </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Report ID&nbsp;</b>
                                                                <br />
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['REPORT-ID']}</h5>

                                                            </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Date of Issue&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['DATE-OF-ISSUE']} </h5>

                                                            </div>
                                                        </div>



                                                    </div>
                                                    : ""}
                                                {parseData &&
                                                    parseData['INDV-REPORT-FILE'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST'] ?

                                                    <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                        <h5 ><b>Inquiry Input Information</b></h5>
                                                        <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                        <div className="row">
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Name&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['NAME']} </h5>
                                                            </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>DOB/Age&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['DOB']}/{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['AGE']}</h5>

                                                            </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">

                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Phone Number&nbsp;</b>
                                                                <br />
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['PHONES']['PHONE']}</h5>

                                                            </div>

                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Phone Number&nbsp;</b>
                                                                <br />
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['PHONES']['PHONE']}</h5>

                                                            </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>ID(s)&nbsp;</b>
                                                                <br />
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['IDS']['ID']['VALUE']}</h5>

                                                            </div>

                                                        </div>
                                                        <div className='row'>

                                                            <div className="col-sm-12 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Current Address&nbsp;</b>
                                                                <br />
                                                                <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['ADDRESSES']['ADDRESS']}</h5>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    : ""}
                                                {parseData &&
                                                    parseData['INDV-REPORT-FILE'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] ?
                                                    <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                        <h5 ><b>CRIF HM Score(S)</b></h5>
                                                        <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                        <div className="row">
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORE NAME	&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>
                                                                    {parseData &&
                                                                        parseData['INDV-REPORT-FILE'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-TYPE']
                                                                        ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-TYPE']
                                                                        : 'Score Type Not Available'}
                                                                </h5>                </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORE&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>
                                                                    {parseData &&
                                                                        parseData['INDV-REPORT-FILE'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-VALUE']
                                                                        ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-VALUE']
                                                                        : 'Score Value Not Available'}
                                                                </h5>

                                                            </div>
                                                            <div className="col-sm-4 col-xs-12 form-group">
                                                                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORING FACTORS&nbsp;</b>
                                                                <h5 style={{ fontWeight: '700' }}>
                                                                    {parseData &&
                                                                        parseData['INDV-REPORT-FILE'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-FACTORS']
                                                                        ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-FACTORS']
                                                                        : 'Score Factors Not Available'}
                                                                </h5>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {parseData &&
                                                    parseData['INDV-REPORT-FILE'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']
                                                    ?
                                                    <div className='credit-second-box-small' style={{ fontSize: '1px', overflowY: 'auto' }}>
                                                        <h5 ><b>Personal Information - Variations</b></h5>
                                                        <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                        <div className=''>
                                                            <div className='col-sm-8 col-xs-12'>
                                                                <div className='variation-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name Variations	</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {parseData['INDV-REPORT-FILE'] &&
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS'] &&
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'] &&
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'].length > 0 ? (
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                    return (
                                                                                        <tr key={i}>
                                                                                            <td>{data.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            ) : (
                                                                                <tr>
                                                                                    <td>
                                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION']?.VALUE}
                                                                                    </td>
                                                                                    <td style={{ textAlign: 'right' }}>
                                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION']?.['REPORTED-DATE']}
                                                                                    </td>
                                                                                </tr>
                                                                            )}



                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION']
                                                                    ?
                                                                    <div className='variation-table'>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Address Variations</th>
                                                                                    <th style={{ textAlign: 'right' }}>Reported On</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {Array.isArray(parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION']) ?
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td>{typeof data.VALUE === 'object' ? 'Empty Object' : data.VALUE}</td>
                                                                                                <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    }) :
                                                                                    <tr>
                                                                                        <td>{typeof parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].VALUE === 'object' ? 'Empty Object' : parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>
                                                                                    </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    : ""
                                                                }


                                                            </div>

                                                            <div className='col-sm-4 col-xs-12'>
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION']
                                                                    ?
                                                                    <div className='variation-table'>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>DOB Variations	</th>
                                                                                    <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{data.VALUE}</td>
                                                                                                <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                            </tr>
                                                                                        )
                                                                                    }) : <tr>
                                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                    </tr>}


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    : ""}
                                                                {parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']
                                                                    ? (
                                                                        <div className='variation-table'>

                                                                            <table>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Phone Number Variations</th>
                                                                                        <th style={{ textAlign: 'right' }}>Reported On</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {Array.isArray(parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']) &&
                                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].length > 0 ? (
                                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].map((data, i) => (
                                                                                            <tr key={i}>
                                                                                                <td>{typeof data.VALUE === 'object' ? 'Invalid Value' : data.VALUE}</td>
                                                                                                <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    ) : (
                                                                                        <tr>
                                                                                            <td>{typeof parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.VALUE === 'object' ? 'Invalid Value' : parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.VALUE}</td>
                                                                                            <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.['REPORTED-DATE']}</td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>


                                                                            </table>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                {/* {parseData &&
                                         parseData['INDV-REPORT-FILE'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS'] &&
                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']
                                         ?
                                         <div className='variation-table'>
                                             <table>
                                                 <thead>
                                                     <tr>
                                                         <th>Phone Number Variations	</th>
                                                         <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                     </tr>
                                                 </thead>
                                                 <tbody>
                                                     {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].length > 0 ?
                                                         parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].map((data, i) => {
                                                             return (
                                                                 <tr>
                                                                     <td>{data.VALUE}</td>
                                                                     <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                 </tr>
                                                             )
                                                         }) : <tr>
                                                             <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].VALUE}</td>
                                                             <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                         </tr>}


                                                 </tbody>
                                             </table>
                                         </div>
                                         : ""} */}
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'] ?
                                                                    <div className='variation-table'>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Eamil-Id Variations	</th>
                                                                                    <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{data.VALUE}</td>
                                                                                                <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                            </tr>
                                                                                        )
                                                                                    }) : <tr>
                                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                    </tr>}


                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    : ""}
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'] ?
                                                                    <div className='variation-table'>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>PAN Variations	</th>
                                                                                    <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{data.VALUE}</td>
                                                                                                <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                            </tr>
                                                                                        )
                                                                                    }) :
                                                                                    <tr>
                                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                    </tr>}


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    : ""}
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'] ?
                                                                    <div className='variation-table'>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Passport Variations	</th>
                                                                                    <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{data.VALUE}</td>
                                                                                                <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                            </tr>
                                                                                        )
                                                                                    }) :
                                                                                    <tr>
                                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                    </tr>}


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    : ""}
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'] ?
                                                                    <div className='variation-table'>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Driving Licence Variations	</th>
                                                                                    <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{data.VALUE}</td>
                                                                                                <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                            </tr>
                                                                                        )
                                                                                    }) :
                                                                                    <tr>
                                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                    </tr>}


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    : ""}
                                                                {parseData &&
                                                                    parseData['INDV-REPORT-FILE'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS'] &&
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'] ?
                                                                    <div className='variation-table'>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>VoterId Variations	</th>
                                                                                    <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].length > 0 ?
                                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{data.VALUE}</td>
                                                                                                <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                            </tr>
                                                                                        )
                                                                                    }) :
                                                                                    <tr>
                                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                                    </tr>}


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    : ""}


                                                            </div>

                                                        </div>
                                                    </div>
                                                    : ""}
                                                {parseData &&
                                                    parseData['INDV-REPORT-FILE'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY'] ?
                                                    <div className='credit-second-box-small' style={{ fontSize: '1px', minHeight: '100px', overflowY: 'auto' }}>
                                                        <h5 ><b>Account Summary</b></h5>
                                                        <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                        <div className='variation-table'>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Type	</th>
                                                                        <th>Number of Account(s)		</th>
                                                                        <th>Active Account(s)	</th>
                                                                        <th>Overdue Account(s)	</th>
                                                                        <th>Current Balance	</th>
                                                                        <th>Amt Disbd/ High Credit	</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        {/* {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY'].map((data, i) => {
return (
 <> */}
                                                                        <td>Primary Match</td>
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-NUMBER-OF-ACCOUNTS']}</td>
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS']}</td>
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-OVERDUE-NUMBER-OF-ACCOUNTS']}</td>
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-CURRENT-BALANCE']}</td>
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-DISBURSED-AMOUNT']}</td>

                                                                        {/* </>
) */}
                                                                        {/* })} */}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES'] ?
                                                            <div className=''>
                                                                <div className='col-sm-4 col-xs-12' style={{ padding: '10px' }}>
                                                                    <b>NEW ACCOUNTS IN LAST 6 MONTHS :{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES']['NEW-ACCOUNTS-IN-LAST-SIX-MONTHS']}</b>
                                                                </div>
                                                                <div className='col-sm-4 col-xs-12' style={{ padding: '10px' }}>
                                                                    <b>NEW DELINQUENT ACCOUNT IN LAST 6 MONTHS :{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES']['NEW-DELINQ-ACCOUNT-IN-LAST-SIX-MONTHS']}</b>

                                                                </div>
                                                                <div className='col-sm-4 col-xs-12'></div>

                                                            </div>
                                                            : ""}
                                                    </div>
                                                    : ""}
                                                <div className='credit-second-box-small' style={{ fontSize: '14px', overflow: 'auto', textAlign: 'left' }}>
                                                    <h5 ><b>Account Information</b></h5>
                                                    <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                    {parseData && parseData['INDV-REPORT-FILE'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] ?
                                                        <div className='loan-table-crif' style={{ textAlign: 'center' }} >

                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Account Type</th>
                                                                        <th>Ownership Ind</th>
                                                                        <th>Security Status</th>
                                                                        <th>Disbursed Amount</th>
                                                                        <th>Installment Amount</th>
                                                                        <th>Credit Guarantor</th>
                                                                        <th>Last Payment Date</th>
                                                                        <th>Write Off Amount</th>
                                                                        <th>Date Reported</th>
                                                                        <th>Account Status</th>
                                                                        <th>Overdue Amount</th>
                                                                        <th>Current Balance</th>
                                                                        <th>Actual Payment</th>
                                                                        <th>Disbursed Date</th>
                                                                        <th>Matched Type</th>
                                                                        <th>Closed Date</th>
                                                                        <th>Original Term</th>
                                                                        <th>Account Number</th>
                                                                        <th>Combined Payment History</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {sortedData ? sortedData.map((item, index) => {
                                                                        const combinedPaymentHistory = item['LOAN-DETAILS']['COMBINED-PAYMENT-HISTORY'];
                                                                        let splitTransactions = ''

                                                                        if (typeof combinedPaymentHistory === 'string') {
                                                                            splitTransactions = combinedPaymentHistory.split('|');
                                                                        } else {
                                                                            console.error('combinedPaymentHistory is not a string:', combinedPaymentHistory);
                                                                        }
                                                                        return (
                                                                            <tr key={index} style={{ background: item['LOAN-DETAILS']['ACCOUNT-STATUS'] === 'Closed' ? '#d2ecd2' : '#fff' }}>
                                                                                <td>{item['LOAN-DETAILS']['ACCT-TYPE']}</td>
                                                                                <td>{item['LOAN-DETAILS']['OWNERSHIP-IND']}</td>
                                                                                <td>{item['LOAN-DETAILS']['SECURITY-STATUS']}</td>
                                                                                <td>{item['LOAN-DETAILS']['DISBURSED-AMT']}</td>
                                                                                <td>{item['LOAN-DETAILS']['INSTALLMENT-AMT']}</td>
                                                                                <td>{item['LOAN-DETAILS']['CREDIT-GUARANTOR']}</td>
                                                                                <td>{item['LOAN-DETAILS']['LAST-PAYMENT-DATE']}</td>
                                                                                <td>{item['LOAN-DETAILS']['WRITE-OFF-AMT']}</td>
                                                                                <td>{item['LOAN-DETAILS']['DATE-REPORTED']}</td>
                                                                                <td>{item['LOAN-DETAILS']['ACCOUNT-STATUS']}</td>
                                                                                <td>{item['LOAN-DETAILS']['OVERDUE-AMT']}</td>
                                                                                <td>{item['LOAN-DETAILS']['CURRENT-BAL']}</td>
                                                                                <td>{item['LOAN-DETAILS']['ACTUAL-PAYMENT']}</td>
                                                                                <td>{item['LOAN-DETAILS']['DISBURSED-DATE']}</td>
                                                                                <td>{item['LOAN-DETAILS']['MATCHED-TYPE']}</td>
                                                                                <td>{item['LOAN-DETAILS']['CLOSED-DATE']}</td>
                                                                                <td>{item['LOAN-DETAILS']['ORIGINAL-TERM']}</td>
                                                                                <td>{item['LOAN-DETAILS']['ACCT-NUMBER']}</td>
                                                                                <td style={{ width: '50px' }}>
                                                                                    {splitTransactions !== '' ? splitTransactions.map((transaction, transactionIndex) => {
                                                                                        const keywords = ['/SMA', '/SUB', '/STD', '/LSS', '/DBT'];
                                                                                        const shouldHighlight = keywords.some(keyword => transaction.includes(keyword));
                                                                                        return (
                                                                                            <div key={transactionIndex}>
                                                                                                {shouldHighlight ? <mark style={{ background: '#fa3c3c', color: '#fff' }}>{transaction.replace(/\|/g, ' ')}</mark> : transaction.replace(/\|/g, ' ')}
                                                                                            </div>
                                                                                        );
                                                                                    }) : ""}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }) : ""}



                                                                </tbody>
                                                            </table>

                                                        </div>
                                                        : ""}
                                                </div>
                                                <div className='credit-second-box-small' style={{ fontSize: '1px', overflowY: 'auto' }}>
                                                    <h5><b>Inquiries (reported for past 24 months)</b></h5>
                                                    <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                    {parseData &&
                                                        parseData['INDV-REPORT-FILE'] &&
                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY'] &&
                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'] ?
                                                        < div className='loan-table-crif' style={{ textAlign: 'left' }} >
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Credit Grantor</th>
                                                                        <th>Date Of Inquiry</th>
                                                                        <th>Purpose</th>
                                                                        <th>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {parseData &&
                                                                        parseData['INDV-REPORT-FILE'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'] ?
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].length > 0 ?
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>

                                                                                        <td>{item['MEMBER-NAME']}</td>
                                                                                        <td>{item['INQUIRY-DATE']}</td>
                                                                                        <td>{item.PURPOSE}</td>

                                                                                        <td>{item.AMOUNT}</td>

                                                                                    </tr>
                                                                                )

                                                                            })
                                                                            : <tr >
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].AMOUNT}</td>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].PURPOSE}</td>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY']['MEMBER-NAME']}</td>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY']['INQUIRY-DATE']}</td>

                                                                            </tr>

                                                                        : ""}
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                        : ""}
                                                </div>
                                            </>
                                            : ""}

                                    </div>
                                    :''}
                        </div>
                        :''}
                    </div>
                </div>
                : ""}
            {notesPopup ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                        <div className="row from-group">
                            <div className="col-xs-12" style={{ padding: '10px' }}>
                                <h5 className="" style={{ marginBottom: '10px', marginTop: '10px', fontWeight: '700' }}><i style={{ fontWeight: '700' }} className='fa fa-commenting-o'></i>&nbsp;&nbsp; Comment</h5>
                            </div>
                        </div>
                        {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?

                            <div style={{ background: '#F1F7FF', border: '1px solid #DBE9FF', padding: '10px', borderRadius: '12px' }}>

                                <textarea className='form-control' placeholder='type here...' onChange={(e) => commentChangeHandler(e, notesType)} />
                                <div className='text-right'>
                                    <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                </div>
                            </div>
                            : ""}
                        {newNotes !== '' && newNotes.length > 0 ? <h5 style={{ fontWeight: '700' }}> Previous Comments</h5> : ""}
                        {newNotes !== '' && newNotes.length > 0 ?
                            <div style={{
                                borderRadius: '10px',
                                border: '1px solid #D1D9E2',
                                background: '#fff',
                                maxHeight: '200px',
                                overflowY: 'scroll'
                            }}>

                                {newNotes.map((data, i) => {
                                    return (
                                        <div className='container-fluid'>
                                            <div className='row' style={{ borderBottom: '1px solid #D1D9E2', padding: '10px' }} key={i}>
                                                <div className='col-sm-6 col-xs-6'>
                                                    {data.notes}<br />
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.empId}</span>
                                                </div>
                                                <div className='col-sm-6 col-xs-6 text-right'>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                                </div>

                                            </div>
                                            {/* <hr /> */}
                                        </div>
                                    )
                                })}
                            </div>
                            : <h4 className='text-center' style={{ marginTop: '10px' }}>No already saved Comments Found</h4>}

                    </div>
                </div>
                : ""}
            <div className='container-fluid'>
                {crifTab === 'crifreport' ?
                    <div className='col-xs-12  ' style={{ marginTop: '20px', marginBottom: '20px' }}>
                        {crifButtonFromApi === true && admin.rolelist.indexOf(ROLE.CRIF_RE_GENERATE) >= 0 && crifLoaderData === false && regenerateButton === true && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                            <button className='btn btn-primary' onClick={e => regenerateCrif()}>
                                Regenerate Crif
                            </button>
                            : ""}
                        {crifTab === 'crifreport' ?
                            <div style={{ float: 'inline-end', marginTop: '-15px' }} >
                                <span onClick={() => openCommentPopup('crifDecision')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>
                                {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? <>
                                    <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'aadhaar')} >
                                        <option value={''}>Select Option</option>
                                        <option value={'Approved'}>Approved</option>
                                        <option value={'Reject'}>Reject</option>

                                    </select>
                                    <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter()}>Save</button>
                                </> : ""}
                            </div>
                            : ""}
                    </div>
                    : ""}

                {analysisData != null &&
                    analysisData.rejectNote != '' &&
                    analysisData.rejectNote != null &&
                    analysisData.rejectNote != undefined ? (
                    <h4 className='text-center'>
                        <b>

                            <u> Notes</u>
                        </b>
                    </h4>
                ) : (
                    ''
                )}
                {analysisData != null &&
                    analysisData != undefined &&
                    analysisData != '' &&
                    analysisData.rejectNote != null &&
                    analysisData.rejectNote != '' &&
                    analysisData.rejectNote.length > 0
                    ? analysisData.rejectNote.map((data, i) => {
                        return (
                            <div key={i}>
                                <h5 className='reject-note'>{data}</h5>
                            </div>
                        )
                    })
                    : ''}


                {/* {analysisData.finalDecision != null && crifTab === 'crifreport' ?
                    <p className='text-center'>
                        <h2>Automated Decision:-</h2>

                        {analysisData.finalDecision == 'Rejected' ?
                            <h1 className='blinknew text-center text-danger'>{analysisData.finalDecision}</h1>
                            : <h1 className='blinknew text-center' style={{ color: 'green' }}>{analysisData.finalDecision}</h1>}
                    </p>
                    : ""} */}

                {crifLoaderData === false ?
                    <div className=''>
                        {crifTab === 'crifreport' ?
                            <div className='row ' style={{ marginBottom: '20px' }}>
                                <div className='text-right' style={{ marginBottom: '10px' }}>
                                    <button className='btn btn-primary' onClick={() => zoomPage('zoom')}>
                                        See Full Report&nbsp; <i style={{ cursor: 'pointer', fontSize: '20px' }} className='fa fa-expand'></i>
                                    </button>
                                </div>
                                <div className='row'>
                                    {analysisData.finalDecision != null && crifTab === 'crifreport' ?
                                        <div className='col-sm-6 col-xs-6'>
                                            <div style={{ borderRadius: '6px', border: analysisData.finalDecision === 'Rejected' ? '1px solid  #FF4444' : '1px solid var(--success, #00C851)', background: analysisData.finalDecision === 'Rejected' ? '#FEECEB' : 'var(--light-green, ##FEECEB)', padding: '10px' }}>
                                                <div className='row'>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        <p style={{ marginTop: '15px' }}>Automated Decision</p>
                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <h4><b style={{ color: analysisData.finalDecision === 'Rejected' ? 'red' : analysisData.finalDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finalDecision}</b></h4>
                                                        {analysisData.fetchDate ? <> Verified on - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                    {analysisData.score != '' && analysisData.score != null && crifTab === 'crifreport' ?
                                        <div className='col-sm-6 col-xs-6'>
                                            <div style={{ borderRadius: '6px', border: '1px solid #267DFF', background: '#F1F7FF', padding: '10px' }}>
                                                <div className='row'>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        <p style={{ marginTop: '15px' }}>Cibil Score</p>

                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <h4><b style={{ color: analysisData.score === 'Rejected' ? 'red' : analysisData.score === 'Approved' ? 'green' : '#31311f' }}>{analysisData.score}</b></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}

                                </div>

                                {analysisData != '' && analysisData != null && crifTab === 'crifreport' ?
                                    <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '50px' }}>


                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Dual Pan&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>{analysisData.dualPan == true ?
                                                    <img src={VerifyImage} width="22px" />
                                                    : <img src={NotVerified} width="20px" />} </h5>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Mobile Matched&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>{analysisData.mobileMatched == true ?
                                                    <img src={VerifyImage} width="22px" />
                                                    : <img src={NotVerified} width="20px" />
                                                }</h5>

                                            </div>
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Current Address Pincode Matched&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>
                                                    {analysisData.currentAddressPincodeMatched == true ?
                                                        <img src={VerifyImage} width="22px" />
                                                        : <img src={NotVerified} width="20px" />
                                                    }                     </h5>

                                            </div>

                                        </div>



                                    </div>
                                    : ""}


                                {parseData !== '' ?
                                    <>
                                        {parseData &&
                                            parseData['INDV-REPORT-FILE'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER'] ?

                                            <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                <h5 ><b>Enquiry By</b></h5>
                                                <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                <div className="row">
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Date of Request&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['DATE-OF-REQUEST']} </h5>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Batch ID&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['BATCH-ID']}</h5>

                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Prepared For&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>
                                                            {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['PREPARED-FOR']}                       </h5>

                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Prepared For Id&nbsp;</b>
                                                        <br />
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['PREPARED-FOR-ID']}</h5>

                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Report ID&nbsp;</b>
                                                        <br />
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['REPORT-ID']}</h5>

                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Date of Issue&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['DATE-OF-ISSUE']} </h5>

                                                    </div>
                                                </div>



                                            </div>
                                            : ""}
                                        {/* {parseData &&
                                            parseData['INDV-REPORT-FILE'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST'] ?

                                            <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                <h5 ><b>Inquiry Input Information</b></h5>
                                                <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                <div className="row">
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Name&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['NAME']} </h5>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>DOB/Age&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['DOB']}/{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['AGE']}</h5>

                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">

                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Phone Number&nbsp;</b>
                                                        <br />
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['PHONES']['PHONE']}</h5>

                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Phone Number&nbsp;</b>
                                                        <br />
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['PHONES']['PHONE']}</h5>

                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>ID(s)&nbsp;</b>
                                                        <br />
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['IDS']['ID']['VALUE']}</h5>

                                                    </div>

                                                </div>
                                                <div className='row'>

                                                    <div className="col-sm-12 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Current Address&nbsp;</b>
                                                        <br />
                                                        <h5 style={{ fontWeight: '700' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['REQUEST']['ADDRESSES']['ADDRESS']}</h5>

                                                    </div>

                                                </div>
                                            </div>
                                            : ""}
                                        {parseData &&
                                            parseData['INDV-REPORT-FILE'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] ?
                                            <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '200px' }}>
                                                <h5 ><b>CRIF HM Score(S)</b></h5>
                                                <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

                                                <div className="row">
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORE NAME	&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-TYPE']
                                                                ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-TYPE']
                                                                : 'Score Type Not Available'}
                                                        </h5>                </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORE&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-VALUE']
                                                                ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-VALUE']
                                                                : 'Score Value Not Available'}
                                                        </h5>

                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 form-group">
                                                        <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>SCORING FACTORS&nbsp;</b>
                                                        <h5 style={{ fontWeight: '700' }}>
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-FACTORS']
                                                                ? parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['SCORES']['SCORE']['SCORE-FACTORS']
                                                                : 'Score Factors Not Available'}
                                                        </h5>

                                                    </div>
                                                </div>
                                            </div>
                                            : ""}
                                        {parseData &&
                                            parseData['INDV-REPORT-FILE'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']
                                            ?
                                            <div className='credit-second-box-small' style={{ fontSize: '1px', overflowY: 'auto' }}>
                                                <h5 ><b>Personal Information - Variations</b></h5>
                                                <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                <div className=''>
                                                    <div className='col-sm-8 col-xs-12'>
                                                        <div className='variation-table'>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name Variations	</th>
                                                                        <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {parseData['INDV-REPORT-FILE'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'] &&
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'].length > 0 ? (
                                                                        parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>{data.VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <tr>
                                                                            <td>
                                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION']?.VALUE}
                                                                            </td>
                                                                            <td style={{ textAlign: 'right' }}>
                                                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['NAME-VARIATIONS']['VARIATION']?.['REPORTED-DATE']}
                                                                            </td>
                                                                        </tr>
                                                                    )}



                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {parseData &&
                                                            parseData['INDV-REPORT-FILE'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION']
                                                            ?
                                                            <div className='variation-table'>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Adress Variations	</th>
                                                                            <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].length > 0 ?
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                    </tr>
                                                                                )
                                                                            }) : <tr>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['ADDRESS-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                            </tr>}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : ""}

                                                    </div>

                                                    <div className='col-sm-4 col-xs-12'>
                                                        {parseData &&
                                                            parseData['INDV-REPORT-FILE'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION']
                                                            ?
                                                            <div className='variation-table'>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>DOB Variations	</th>
                                                                            <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].length > 0 ?
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                    </tr>
                                                                                )
                                                                            }) : <tr>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DATE-OF-BIRTH-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                            </tr>}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : ""}
                                                        {parseData['INDV-REPORT-FILE'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']
                                                            ? (
                                                                <div className='variation-table'>

                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Phone Number Variations</th>
                                                                                <th style={{ textAlign: 'right' }}>Reported On</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Array.isArray(parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']) &&
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].length > 0 ? (
                                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION'].map((data, i) => (
                                                                                    <tr key={i}>
                                                                                        <td>{typeof data.VALUE === 'object' ? 'Invalid Value' : data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>
                                                                                    </tr>
                                                                                ))
                                                                            ) : (
                                                                                <tr>
                                                                                    <td>{typeof parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.VALUE === 'object' ? 'Invalid Value' : parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.VALUE}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PHONE-NUMBER-VARIATIONS']['VARIATION']?.['REPORTED-DATE']}</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>


                                                                    </table>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                      
                                                        {parseData &&
                                                            parseData['INDV-REPORT-FILE'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'] ?
                                                            <div className='variation-table'>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Eamil-Id Variations	</th>
                                                                            <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].length > 0 ?
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                    </tr>
                                                                                )
                                                                            }) : <tr>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['EMAIL-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                            </tr>}


                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            : ""}
                                                        {parseData &&
                                                            parseData['INDV-REPORT-FILE'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'] ?
                                                            <div className='variation-table'>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>PAN Variations	</th>
                                                                            <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].length > 0 ?
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            <tr>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PAN-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                            </tr>}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : ""}
                                                        {parseData &&
                                                            parseData['INDV-REPORT-FILE'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'] ?
                                                            <div className='variation-table'>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Passport Variations	</th>
                                                                            <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].length > 0 ?
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            <tr>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['PASSPORT-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                            </tr>}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : ""}
                                                        {parseData &&
                                                            parseData['INDV-REPORT-FILE'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'] ?
                                                            <div className='variation-table'>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Driving Licence Variations	</th>
                                                                            <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].length > 0 ?
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            <tr>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['DRIVING-LICENSE-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                            </tr>}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : ""}
                                                        {parseData &&
                                                            parseData['INDV-REPORT-FILE'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS'] &&
                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'] ?
                                                            <div className='variation-table'>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>VoterId Variations	</th>
                                                                            <th style={{ textAlign: 'right' }}>Reported On	</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].length > 0 ?
                                                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].map((data, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{data.VALUE}</td>
                                                                                        <td style={{ textAlign: 'right' }}>{data['REPORTED-DATE']}</td>

                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            <tr>
                                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION'].VALUE}</td>
                                                                                <td style={{ textAlign: 'right' }}>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['PERSONAL-INFO-VARIATION']['VOTER-ID-VARIATIONS']['VARIATION']['REPORTED-DATE']}</td>

                                                                            </tr>}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : ""}


                                                    </div>

                                                </div>
                                            </div>
                                            : ""}
                                        {parseData &&
                                            parseData['INDV-REPORT-FILE'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                            parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY'] ?
                                            <div className='credit-second-box-small' style={{ fontSize: '1px', minHeight: '100px', overflowY: 'auto' }}>
                                                <h5 ><b>Account Summary</b></h5>
                                                <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                                <div className='variation-table'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Type	</th>
                                                                <th>Number of Account(s)		</th>
                                                                <th>Active Account(s)	</th>
                                                                <th>Overdue Account(s)	</th>
                                                                <th>Current Balance	</th>
                                                                <th>Amt Disbd/ High Credit	</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                       
                                                                <td>Primary Match</td>
                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-NUMBER-OF-ACCOUNTS']}</td>
                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS']}</td>
                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-OVERDUE-NUMBER-OF-ACCOUNTS']}</td>
                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-CURRENT-BALANCE']}</td>
                                                                <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['PRIMARY-ACCOUNTS-SUMMARY']['PRIMARY-DISBURSED-AMOUNT']}</td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES'] ?
                                                    <div className=''>
                                                        <div className='col-sm-4 col-xs-12' style={{ padding: '10px' }}>
                                                            <b>NEW ACCOUNTS IN LAST 6 MONTHS :{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES']['NEW-ACCOUNTS-IN-LAST-SIX-MONTHS']}</b>
                                                        </div>
                                                        <div className='col-sm-4 col-xs-12' style={{ padding: '10px' }}>
                                                            <b>NEW DELINQUENT ACCOUNT IN LAST 6 MONTHS :{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['ACCOUNTS-SUMMARY']['DERIVED-ATTRIBUTES']['NEW-DELINQ-ACCOUNT-IN-LAST-SIX-MONTHS']}</b>

                                                        </div>
                                                        <div className='col-sm-4 col-xs-12'></div>

                                                    </div>
                                                    : ""}
                                            </div>
                                            : ""}
                                        <div className='credit-second-box-small' style={{ fontSize: '14px', overflow: 'auto', textAlign: 'left' }}>
                                            <h5 ><b>Account Information</b></h5>
                                            <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                            {parseData && parseData['INDV-REPORT-FILE'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] && parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] ?
                                                <div className='loan-table-crif' style={{ textAlign: 'center' }} >

                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Account Type</th>
                                                                <th>Ownership Ind</th>
                                                                <th>Security Status</th>
                                                                <th>Disbursed Amount</th>
                                                                <th>Installment Amount</th>
                                                                <th>Credit Guarantor</th>
                                                                <th>Last Payment Date</th>
                                                                <th>Write Off Amount</th>
                                                                <th>Date Reported</th>
                                                                <th>Account Status</th>
                                                                <th>Overdue Amount</th>
                                                                <th>Current Balance</th>
                                                                <th>Actual Payment</th>
                                                                <th>Disbursed Date</th>
                                                                <th>Matched Type</th>
                                                                <th>Closed Date</th>
                                                                <th>Original Term</th>
                                                                <th>Account Number</th>
                                                                <th>Combined Payment History</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {sortedData ? sortedData.map((item, index) => {
                                                                const combinedPaymentHistory = item['LOAN-DETAILS']['COMBINED-PAYMENT-HISTORY'];
                                                                let splitTransactions = ''

                                                                if (typeof combinedPaymentHistory === 'string') {
                                                                    splitTransactions = combinedPaymentHistory.split('|');
                                                                } else {
                                                                    console.error('combinedPaymentHistory is not a string:', combinedPaymentHistory);
                                                                }
                                                                return (
                                                                    <tr key={index} style={{ background: item['LOAN-DETAILS']['ACCOUNT-STATUS'] === 'Closed' ? '#d2ecd2' : '#fff' }}>
                                                                        <td>{item['LOAN-DETAILS']['ACCT-TYPE']}</td>
                                                                        <td>{item['LOAN-DETAILS']['OWNERSHIP-IND']}</td>
                                                                        <td>{item['LOAN-DETAILS']['SECURITY-STATUS']}</td>
                                                                        <td>{item['LOAN-DETAILS']['DISBURSED-AMT']}</td>
                                                                        <td>{item['LOAN-DETAILS']['INSTALLMENT-AMT']}</td>
                                                                        <td>{item['LOAN-DETAILS']['CREDIT-GUARANTOR']}</td>
                                                                        <td>{item['LOAN-DETAILS']['LAST-PAYMENT-DATE']}</td>
                                                                        <td>{item['LOAN-DETAILS']['WRITE-OFF-AMT']}</td>
                                                                        <td>{item['LOAN-DETAILS']['DATE-REPORTED']}</td>
                                                                        <td>{item['LOAN-DETAILS']['ACCOUNT-STATUS']}</td>
                                                                        <td>{item['LOAN-DETAILS']['OVERDUE-AMT']}</td>
                                                                        <td>{item['LOAN-DETAILS']['CURRENT-BAL']}</td>
                                                                        <td>{item['LOAN-DETAILS']['ACTUAL-PAYMENT']}</td>
                                                                        <td>{item['LOAN-DETAILS']['DISBURSED-DATE']}</td>
                                                                        <td>{item['LOAN-DETAILS']['MATCHED-TYPE']}</td>
                                                                        <td>{item['LOAN-DETAILS']['CLOSED-DATE']}</td>
                                                                        <td>{item['LOAN-DETAILS']['ORIGINAL-TERM']}</td>
                                                                        <td>{item['LOAN-DETAILS']['ACCT-NUMBER']}</td>
                                                                        <td style={{ width: '50px' }}>
                                                                            {splitTransactions !== '' ? splitTransactions.map((transaction, transactionIndex) => {
                                                                                const keywords = ['/SMA', '/SUB', '/STD', '/LSS', '/DBT'];
                                                                                const shouldHighlight = keywords.some(keyword => transaction.includes(keyword));
                                                                                return (
                                                                                    <div key={transactionIndex}>
                                                                                        {shouldHighlight ? <mark style={{ background: '#fa3c3c', color: '#fff' }}>{transaction.replace(/\|/g, ' ')}</mark> : transaction.replace(/\|/g, ' ')}
                                                                                    </div>
                                                                                );
                                                                            }) : ""}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }) : ""}



                                                        </tbody>
                                                    </table>

                                                </div>
                                                : ""}
                                        </div>
                                        <div className='credit-second-box-small' style={{ fontSize: '1px', overflowY: 'auto' }}>
                                            <h5><b>Inquiries (reported for past 24 months)</b></h5>
                                            <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                                            {parseData &&
                                                parseData['INDV-REPORT-FILE'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY'] &&
                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'] ?
                                                < div className='loan-table-crif' style={{ textAlign: 'left' }} >
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Credit Grantor</th>
                                                                <th>Date Of Inquiry</th>
                                                                <th>Purpose</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {parseData &&
                                                                parseData['INDV-REPORT-FILE'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY'] &&
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'] ?
                                                                parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].length > 0 ?
                                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>

                                                                                <td>{item['MEMBER-NAME']}</td>
                                                                                <td>{item['INQUIRY-DATE']}</td>
                                                                                <td>{item.PURPOSE}</td>

                                                                                <td>{item.AMOUNT}</td>

                                                                            </tr>
                                                                        )

                                                                    })
                                                                    : <tr >
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].AMOUNT}</td>
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY'].PURPOSE}</td>
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY']['MEMBER-NAME']}</td>
                                                                        <td>{parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['INQUIRY-HISTORY']['HISTORY']['INQUIRY-DATE']}</td>

                                                                    </tr>

                                                                : ""}
                                                        </tbody>
                                                    </table>

                                                </div>
                                                : ""}
                                        </div> */}
                                    </>
                                    : ""}

                            </div>
                            : ""}

                        {crifTab === 'clearrejection' ?
                            <div className='' >

                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.rejected != null &&
                                    analysisData.rejected != '' &&
                                    analysisData.rejected != undefined && analysisData.finalDecision == 'Rejected' ? (
                                    <div className='red-table-new phocket-table-new'>
                                        <table>
                                            <thead>
                                                <tr className='thead-class'>
                                                    <th>Account Type</th>
                                                    <th>OverDue Amount</th>
                                                    <th>Disburse Amount</th>
                                                    <th>Disburse Date</th>
                                                    <th>Account Status</th>
                                                    <th>Current Balance</th>
                                                    <th>Status</th>
                                                    <th>Matched Condition</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analysisData != null &&
                                                    analysisData != undefined &&
                                                    analysisData != ''
                                                    ? analysisData.rejected.map((data, i) => {
                                                        const messageType = data.disburseAmount;
                                                        const amount = data.status;

                                                        const isEmiIncluded = jsondata['redFlag'] && jsondata['redFlag'].some(item => (item.disburseAmount === messageType) && (item.status === amount));

                                                        return (
                                                            <tr key={i} style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }} onClick={() => crfJsonData('redFlag', data)}>
                                                                <td>{data.accountType}</td>
                                                                <td>{data.overDueAmount}</td>
                                                                <td>{data.disburseAmount}</td>
                                                                <td>{data.disburseDate}</td>
                                                                <td>{data.accountStatus}</td>
                                                                <td>{data.currentBalance}</td>
                                                                <td>{data.status}</td>
                                                                {/* <td>{data.matchedCondition}</td> */}
                                                                <td>{data.matchedCondition == 'Case 1' ? 'Suit-Filed/Willful/DBT/LSS/SMA & no sanctioning in last 3 Years' : data.matchedCondition == 'Case 2' ? 'Write-off/Settled, DBT, LSS & SMA with amount > INR 5000 in last 2 years' : data.matchedCondition == 'Case 3' ? 'Max DPD >= 90 in last 24 months with Balance > INR 5,000' : data.matchedCondition == 'Case 4' ? 'DPD >= 5 in last 6 months with Balance/Overdue amount >INR 5,000' : data.matchedCondition == 'Case 5' ? 'Current Sanctioning Exception Case & previous loan overdue' : ""}</td>

                                                            </tr>
                                                        )
                                                    })
                                                    : ""}

                                            </tbody>
                                        </table>
                                    </div>

                                ) : (
                                    ''
                                )}
                            </div>
                            : ""}
                        {crifTab === 'greenflag' ?
                            <div className='' >
                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.approve != null &&
                                    analysisData.approve != '' &&
                                    analysisData.approve != undefined && analysisData.finalDecision != 'Rejected' ? (
                                    <div className='green-table phocket-table-new'>
                                        <table className='text-center '>
                                            <thead>
                                                <tr>
                                                    <th>Account Type</th>
                                                    <th>OverDue Amount</th>
                                                    <th>Disburse Amount</th>
                                                    <th>Disburse Date</th>
                                                    <th>Account Status</th>
                                                    <th>Current Balance</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analysisData != null &&
                                                    analysisData != undefined &&
                                                    analysisData != ''
                                                    ? analysisData.approve.map((data, i) => {
                                                        const messageType = data.disburseAmount;
                                                        const amount = data.status;

                                                        const isEmiIncluded = jsondata['greenFlag'] && jsondata['greenFlag'].some(item => (item.disburseAmount === messageType) && (item.status === amount));

                                                        return (
                                                            <tr key={i} style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }} onClick={() => crfJsonData('greenFlag', data)}>
                                                                <td>{data.accountType}</td>
                                                                <td>{data.overDueAmount}</td>
                                                                <td>{data.disburseAmount}</td>
                                                                <td>{data.disburseDate}</td>
                                                                <td>{data.accountStatus}</td>
                                                                <td>{data.currentBalance}</td>
                                                                <td>{data.status}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            : ""}
                        {crifTab === 'exceptional' ?
                            <div className='' >

                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.exceptionCase != null &&
                                    analysisData.exceptionCase != '' &&
                                    analysisData.exceptionCase != undefined ? (
                                    <div className='yellow-table phocket-table-new'>
                                        <table className='text-center '>
                                            <thead>
                                                <tr>
                                                    <th>Account Type</th>
                                                    <th>OverDue Amount</th>
                                                    <th>Disburse Amount</th>
                                                    <th>Disburse Date</th>
                                                    <th>Account Status</th>
                                                    <th>Current Balance</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analysisData != null &&
                                                    analysisData != undefined &&
                                                    analysisData != ''
                                                    ? analysisData.exceptionCase.map((data, i) => {
                                                        const messageType = data.disburseAmount;
                                                        const amount = data.status;

                                                        const isEmiIncluded = jsondata['yellowFlag'] && jsondata['yellowFlag'].some(item => (item.disburseAmount === messageType) && (item.status === amount));

                                                        return (
                                                            <tr key={i} style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }} onClick={() => crfJsonData('yellowFlag', data)}>
                                                                <td>{data.accountType}</td>
                                                                <td>{data.overDueAmount}</td>
                                                                <td>{data.disburseAmount}</td>
                                                                <td>{data.disburseDate}</td>
                                                                <td>{data.accountStatus}</td>
                                                                <td>{data.currentBalance}</td>
                                                                <td>{data.status}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            : ""}
                        {crifTab === 'obligation' && crifData.obligationData && crifData.obligationData.loanReportAnalysed ?
                            <div className='' >

                                {crifData != null &&
                                    crifData != undefined &&
                                    crifData != '' &&
                                    crifData.obligationData.loanReportAnalysed != null &&
                                    crifData.obligationData.loanReportAnalysed != '' &&
                                    crifData.obligationData.loanReportAnalysed != undefined ? (
                                    <div className='phocket-table-new'>
                                        <table className='text-center '>
                                            <thead>
                                                <tr>
                                                    <th>Account Type</th>
                                                    <th>OverDue Amount</th>
                                                    <th>Disburse Amount</th>
                                                    <th>Disburse Date</th>
                                                    <th>Account Status</th>
                                                    <th>Current Balance</th>
                                                    <th style={{ width: '50px' }}>Combined Payment History</th>
                                                    <th>Ownership</th>
                                                    <th>Security Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {crifData.obligationData != null &&
                                                    crifData.obligationData != undefined &&
                                                    crifData.obligationData != ''
                                                    ? crifData.obligationData.loanReportAnalysed.map((data, i) => {
                                                        var updatedData = data.combinedPaymentHistory.replace(/\|/g, ' ');
                                                        const messageType = data.disbursedAmt;
                                                        const amount = data.status;

                                                        const isEmiIncluded = jsondata['obligation'] && jsondata['obligation'].some(item => (item.disbursedAmt === messageType) && (item.status === amount));

                                                        return (
                                                            <tr key={i} style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }} onClick={() => crfJsonData('obligation', data)}>
                                                                <td>{data.accType}</td>
                                                                <td>{data.overDueAmt}</td>
                                                                <td>{data.disbursedAmt}</td>
                                                                <td>{data.disburseDate}</td>
                                                                <td>{data.accountStatus}</td>
                                                                <td>{data.currentBal}</td>
                                                                <td style={{ width: '50px' }}>{updatedData}</td>
                                                                <td>{data.ownershipInd}</td>
                                                                <td>{data.securityStatus}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            : ""}
                    </div>
                    : ""}
                {crifLoaderData ?
                    <Skeleton height={50} count={10} />
                    : ""}
                {crifdatapopup === true ?
                    userCrifData !== undefined && userCrifData !== '' ?
                        <div>
                            <div className="background-blur"></div>
                            < div className="modal" style={{ display: 'block', overflow: 'auto' }}>
                                <div className="modal-dialog" style={{ width: '1200px', margin: '10px auto' }}>
                                    <div className="modal-content" style={{ padding: '10px' }}>
                                        <div className="modalheader" style={{ float: 'right', marginBottom: '10px' }}>
                                            <button type="button" onClick={() => crifpopup()} style={{ position: 'absolute', zIndex: '100', marginLeft: '-1%' }}>
                                                <i className="fa fa-times fs-1"></i>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div dangerouslySetInnerHTML={{ __html: userCrifData }} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                       ''
                    : ''}
                {
                    crifprevdata && crifLoaderData == false ?
                        <>
                            <hr style={{ borderTop: '3px solid #eee' }} />
                            <div ref={containerRef} dangerouslySetInnerHTML={{ __html: crifprevdata }} style={{ overflowY: 'scroll', maxHeight: '400px', margin: '0px -25px' }} />
                        </>
                        : <h3 class="blue-text text-center" style={{marginTop:'10px'}}>Unable to fetch Data</h3> 
                }
            </div>
        </>
    )
}
export default CrifNew