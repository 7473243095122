import React from 'react'
import DatePicker from 'react-datepicker';
import { TYPE, STRING } from '../../../../utils/Constant'

const NewAmbassador = ({ ambRegDetail, ambRegDetailChange, ambRegDetailHandler, ambDOB, registerBtnState }) => (
    <div className="container-fluid">
        <h3 className="text-center blue-text">Basic Detais</h3>
        <div className="row form-group">
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Full Name</label>
                <input type="text" className="form-control" placeholder="Enter Name" value={ambRegDetail.ambNameState} onChange={e => ambRegDetailChange(e, TYPE.FULL_NAME)} />
            </div>
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">D.O.B</label>
                <DatePicker
                    value={ambRegDetail.ambDOB}
                    selected={ambDOB}
                    onChange={(e) => ambRegDetailChange(e, TYPE.DOB)}
                    className="form-control"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    dateFormat="DD/MM/YYYY"
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    placeholderText="Enter D.O.B"
                />
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Gender</label>
                <select className="form-control" placeholder="Select Gender" value={ambRegDetail.ambGenderState} onChange={(e) => ambRegDetailChange(e, TYPE.GENDER)}>
                    <option value=""></option>
                    <option value={STRING.MALE}>{STRING.MALE}</option>
                    <option value={STRING.FEMALE}>{STRING.FEMALE}</option>
                    <option value={STRING.OTHERS}>{STRING.OTHERS}</option>
                </select>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Contact No.</label>
                <input type="number" className="form-control" placeholder="Enter Contact No." value={ambRegDetail.ambMobileState} onChange={e => ambRegDetailChange(e, TYPE.MOBILE_NO)} />
            </div>
            <div className="col-xs-12 col-sm-4">
                <label className="font-12px">Email Id</label>
                <input readOnly type="email" className="form-control" placeholder="Enter Email" value={ambRegDetail.ambEmailState} onChange={e => ambRegDetailChange(e, TYPE.EMAIL_ID)} />
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                <button className="btn center-block profile-save-btn" onClick={e => ambRegDetailHandler('basic')}>Save</button>
            </div>
        </div>
        <h3 className="text-center blue-text">Residence / Qualification Details</h3>
        <div className="row">
            <div className="col-sm-4 col-xs-12">
                <h4>Address</h4>
                <input type="text" placeholder="Enter Address" className="form-control" value={ambRegDetail.ambAddressState} onChange={(e) => ambRegDetailChange(e, TYPE.CURRENT_ADDRESS)} />
            </div>
            <div className="col-sm-4 col-xs-12">
                <h4>Pincode</h4>
                <input type="number" placeholder="Enter Pincode" className="form-control" value={ambRegDetail.ambPinCodeState} onChange={(e) => ambRegDetailChange(e, TYPE.CURRENT_PIN_CODE)} />
            </div>
            <div className="col-sm-4 col-xs-12">
                <h4>State</h4>
                <input readOnly type="text" className="form-control" id="userCurrentState" value={ambRegDetail.ambStateState} onChange={(e) => ambRegDetailChange(e, TYPE.CURRENT_STATE)} />
            </div>
        </div>
        <div className="row">
            <div className="col-sm-4 col-xs-12">
                <h4>City</h4>
                <input readOnly type="text" className="form-control" id="userCurrentCity" value={ambRegDetail.ambCityState} onChange={(e) => ambRegDetailChange(e, TYPE.CURRENT_CITY)} />
            </div>
            <div className="col-sm-4 col-xs-12">
                <h4>Highest Qualification</h4>
                <select className="form-control" value={ambRegDetail.ambHighQualificationState} onChange={(e) => ambRegDetailChange(e, TYPE.HIGHEST_QUALIFICATION)}>
                    <option value=""></option>
                    <option value={STRING.GRADUATE}>{STRING.GRADUATE}</option>
                    <option value={STRING.POST_GRADUATE}>{STRING.POST_GRADUATE}</option>
                    <option value={STRING.OTHERS}>{STRING.OTHERS}</option>
                </select>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                <button className="btn center-block profile-save-btn" onClick={e => ambRegDetailHandler('regAndOcc')}>Save</button>
            </div>
        </div>
        <h3 className="text-center blue-text">Documents</h3>
        <div className="row form-group">
            <div className="upload-docs col-sm-6 col-xs-12">
                <div>
                    <h4>ID Proof</h4>
                </div>
                <div>
                    <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="identityProof" onChange={(e) => ambRegDetailChange(e, TYPE.ID_PROOF)} />
                    <label htmlFor="identityProof">Choose New File</label>
                </div>
                <div>
                    {ambRegDetail.ambIdProofState.split("type=")[1] == "img" ?
                        <a target="_blank" href={ambRegDetail.ambIdProofState}>
                            <img className="" src={ambRegDetail.ambIdProofState} />
                        </a>
                        : ""}
                    {ambRegDetail.ambIdProofState.split("type=")[1] == "pdf" ?
                        <a target="_blank" href={ambRegDetail.ambIdProofState}>
                            <object data={ambRegDetail.ambIdProofState} type="application/pdf"></object>
                        </a>
                        : ""}
                </div>
            </div>
            <div className="upload-docs col-sm-6 col-xs-12">
                <div>
                    <h4>Pan Card</h4>
                </div>
                <div>
                    <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="panProof" onChange={(e) => ambRegDetailChange(e, TYPE.PAN_CARD)} />
                    <label htmlFor="panProof">Choose New File</label>
                </div>
                <div>
                    {ambRegDetail.ambPanState.split("type=")[1] == "img" ?
                        <a target="_blank" href={ambRegDetail.ambPanState}>
                            <img className="" src={ambRegDetail.ambPanState} />
                        </a>
                        : ""}
                    {ambRegDetail.ambPanState.split("type=")[1] == "pdf" ?
                        <a target="_blank" href={ambRegDetail.ambPanState}>
                            <object data={ambRegDetail.ambPanState} type="application/pdf"></object>
                        </a>
                        : ""}
                </div>
            </div>
        </div>
        <div className="row form-group">
            <div className="upload-docs col-sm-6 col-xs-12">
                <div>
                    <h4>Address Proof</h4>
                </div>
                <div>
                    <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="addressProof" onChange={(e) => ambRegDetailChange(e, TYPE.ADDRESS)} />
                    <label htmlFor="addressProof">Choose New File</label>
                </div>
                <div>
                    {ambRegDetail.ambAddressProof.split("type=")[1] == "img" ?
                        <a target="_blank" href={ambRegDetail.ambAddressProof}>
                            <img className="" src={ambRegDetail.ambAddressProof} />
                        </a>
                        : ""}
                    {ambRegDetail.ambAddressProof.split("type=")[1] == "pdf" ?
                        <a target="_blank" href={ambRegDetail.ambAddressProof}>
                            <object data={ambRegDetail.ambAddressProof} type="application/pdf"></object>
                        </a>
                        : ""}
                </div>
            </div>
        </div>
        {registerBtnState == true ?
            <div className="row form-group">
                <div className="col-xs-12">
                    <button className="btn center-block profile-save-btn" onClick={e => ambRegDetailHandler('registerAmb')}>Register</button>
                </div>
            </div>
            : null}
    </div>
)

export default NewAmbassador;