import React from 'react'
import DatePicker from 'react-datepicker'
import ReactTable from 'react-table'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import NoDataImage from '../../../../images/nodata.png'
import { Button } from 'bootstrap'
const IncomeCollected = ({
  handleChange,
  submitHandler,
  startDate,
  endDate,
  incomeData,
  GenerateHandler
}) => (
  <div className='container-fluid income'>
    <h3 className='text-center blue-text'>Income Breakup </h3>
    <div className='col-xs-12'>
      {/* <div className='row '> */}
      <div className='col-xs-12 col-sm-4 form-group'></div>
      <div className='col-xs-12 col-sm-4 form-group'>
        <p>Select Start Date</p>
        <DatePicker
          value={startDate}
          onChange={e => handleChange(e, 'start')}
          className='date-picker form-control'
          dateFormat='DD-MM-YYYY'
          placeholderText='Select Start Date'
        />
      </div>
      <div className='col-xs-12 col-sm-4 form-group'></div>
    </div>
    <div className='col-xs-12'>
      <div className='col-xs-12 col-sm-4 form-group'></div>
      <div className='col-xs-12 col-sm-4 form-group'>
        <p>Select End Date</p>
        <DatePicker
          value={endDate}
          onChange={e => handleChange(e, 'end')}
          className='date-picker form-control'
          dateFormat='DD-MM-YYYY'
          placeholderText='Select End Date'
        />
      </div>
      <div className='col-xs-12 col-sm-4 form-group'></div>
    </div>
    <div className='col-xs-12'>
      <div className='col-xs-12 col-sm-4 form-group'></div>
      <div className='col-xs-12 col-sm-4 text-center'>
        <button
          className='profile-save-btn'
          id=''
          onClick={e => submitHandler(e)}
        >
          <span className='fa fa-search'></span>&nbsp;&nbsp;Search
        </button>
        &nbsp;
        <button className='profile-save-btn' onClick={GenerateHandler}> <span className='fa fa-download'></span>&nbsp;&nbsp;Generate Report </button>
      </div>
      <div className='col-xs-12 col-sm-4 '></div>
    </div>


    <div className='row'>
      <div className='col-xs-12'>
        <div className='col-xs-12 col-sm-4'></div>
        {incomeData != '' && incomeData != null ? (
          <div className='col-xs-12 col-sm-4'>
            <table className='income-table'>
              <thead>
                <tr>
                  <th>Cheque Bounce Sum:</th>
                  <td>{incomeData.checkBounceSum}</td>
                </tr>
                <tr>
                  <th>Interest Sum:</th>
                  <td>{incomeData.interestSum}</td>
                </tr>
                <tr>
                  <th>Nach Bounce Sum:</th>
                  <td>{incomeData.nachBounceSum}</td>
                </tr>
                <tr>
                  <th>Penalty Sum:</th>
                  <td>{incomeData.penaltySum}</td>
                </tr>
                <tr>
                  <th>Processing Fee :</th>
                  <td>{incomeData.processingFee}</td>
                </tr>
              </thead>
            </table>

          </div>
        ) : (
            ''
          )}
        <div className='col-xs-12 col-sm-4'></div>
      </div>
    </div>
  </div>
)
export default IncomeCollected
