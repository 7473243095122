import React from "react";

const LeavePopup = ({dateHandler,close,saveLeave}) => {
    return (
        <div className="details-new background-blur" style={{ background: 'rgb(5 5 5 / 20%)', color: '#000' }}>
            <div className="edit-box " style={{ width: '40%', minHeight: '200px' }}>
                <div className="text-right" style={{ color: 'red' }}>
                    <i className="fa fa-times" style={{cursor:'pointer'}} onClick={()=>close()}></i>
                </div>
                <h4><b><i className="fa fa-calendar"></i>&nbsp;&nbsp;Mark Leave</b></h4>
                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <label>From date</label>
                        <input type="date" className="form-control" onChange={(e)=>dateHandler(e,'start')} />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label>End date</label>
                        <input type="date" className="form-control"  onChange={(e)=>dateHandler(e,'end')} />
                    </div>
                </div>
                <div className="text-center">
                    <button className="btn btn-primary" onClick={()=>saveLeave()} style={{ width: '100px', marginTop: '20px' }}>Save</button>
                </div>
            </div>
        </div>
    )
}
export default LeavePopup