import React from 'react'

const DBR = ({ internalScoring, internalHandler, submit, validationMessages }) => (
    <div style={{ padding: '0px 12px' }}>
        <h5 ><b>Scorecard</b></h5>
        <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>

        <div className="row">
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Stability&nbsp;</b>
                <input type="text" className="form-control credit-input" value={internalScoring.stability} onChange={e => internalHandler(e, 'stability')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'stability' ? validationMessages.msg : ""}</span>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Overdue Msg&nbsp;</b>
                <input type="text" className="form-control credit-input" value={internalScoring.overDueMsgsTrend} onChange={e => internalHandler(e, 'overDueMsgsTrend')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'overDueMsgsTrend' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Bureau&nbsp;</b>
                <input type="text" className="form-control credit-input" value={internalScoring.bureau} onChange={e => internalHandler(e, 'bureau')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'bureau' ? validationMessages.msg : ""}</span>

            </div>

        </div>
        <div className='row'>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Residence&nbsp;</b>
                <br />
                <input type="text" className="form-control credit-input" value={internalScoring.residence} onChange={e => internalHandler(e, 'residence')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'residence' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Debts&nbsp;</b>
                <br />
                <input type="text" className="form-control credit-input" value={internalScoring.debts} onChange={e => internalHandler(e, 'debts')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'debts' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>NTC&nbsp;</b>
                <input type="text" className="form-control credit-input" value={internalScoring.ntc} onChange={e => internalHandler(e, 'ntc')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'ntc' ? validationMessages.msg : ""}</span>

            </div>
        </div>
        <div className='row'>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Corporate&nbsp;</b>
                <br />
                <input type="text" className="form-control credit-input" value={internalScoring.corporate} onChange={e => internalHandler(e, 'corporate')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'corporate' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Access&nbsp;</b>
                <br />
                <input type="text" className="form-control credit-input" value={internalScoring.access} onChange={e => internalHandler(e, 'access')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'access' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>kyc&nbsp;</b>
                <br />
                <input type="text" className="form-control credit-input" value={internalScoring.kyc} onChange={e => internalHandler(e, 'kyc')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'kyc' ? validationMessages.msg : ""}</span>

            </div>
        </div>
        <div className='row'>
            <div className="col-sm-6 col-xs-12 form-group">
                <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Multiple Pay day&nbsp;</b>
                <input type="text" className="form-control credit-input" value={internalScoring.multiplePayday} onChange={e => internalHandler(e, 'multiplePayday')}></input>
                <span style={{ fontSize: '10px', color: 'red' }}>{validationMessages && validationMessages.field === 'multiplePayday' ? validationMessages.msg : ""}</span>


            </div>

        </div>
        <div className='row' style={{ marginTop: '21px', marginLeft: '7px', marginBottom: '10px' }}>
            <button className='btn btn-primary' onClick={() => submit()}>Save</button>
        </div>
    </div>
)

export default DBR