import React from 'react'

const CouponListBtn = ({ buttonActionHandler, pageBtnType }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <div className="btn-group">
                    <button className={pageBtnType == 'AddMerchantDetails' ? 'active': ''} onClick={e => buttonActionHandler("AddMerchantDetails")}>Merchant Details</button>
                    <button className={pageBtnType == 'AddMerchantImage' ? 'active': ''} onClick={e => buttonActionHandler("AddMerchantImage")}>Merchant Images</button>
                    <button className={pageBtnType == 'AddLocation' ? 'active': ''} onClick={e => buttonActionHandler("AddLocation")}>City</button>
                    <button className={pageBtnType == 'AddSubLocation' ? 'active': ''} onClick={e => buttonActionHandler("AddSubLocation")}>Outlet Address</button>
                    <button className={pageBtnType == 'AddCategory' ? 'active': ''} onClick={e => buttonActionHandler("AddCategory")}>Category</button>
                    <button className={pageBtnType == 'AddSubCategory' ? 'active': ''} onClick={e => buttonActionHandler("AddSubCategory")}>Sub-Category</button>
                    <button className={pageBtnType == 'addDiscountDetails' ? 'active': ''} onClick={e => buttonActionHandler("addDiscountDetails")}>Coupon</button>
                    <button className={pageBtnType == 'addOutlet' ? 'active': ''} onClick={e => buttonActionHandler("addOutlet")}>Map Outlet</button>
                    <button className={pageBtnType == 'mapStatus' ? 'active': ''} onClick={e => buttonActionHandler("mapStatus")}>Map Status</button>
                    <button className={pageBtnType == 'couponListing' ? 'active': ''} onClick={e => buttonActionHandler("couponListing")}>Offers</button>
                    <button className={pageBtnType == 'customerReport' ? 'active': ''} onClick={e => buttonActionHandler("customerReport")}>Customer Report</button>
                    <button className={pageBtnType == 'addCouponCode' ? 'active': ''} onClick={e => buttonActionHandler("addCouponCode")}>Add Code</button>
                </div>
            </div>
        </div>
    </div>
)

export default CouponListBtn;