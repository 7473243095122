import React, { useState } from 'react'
import accountreconciliaitonimg from './images/accountreconciliaiton.png';
import MandateReportimg from './images/MandateReport.png';
import AccountReconciliaitonPage from './AccountReconciliaitonPage';
import MandateReport from './MandateReport';
export default function ARMainTab({admin,allStatus,newDataBackground}) {
    const [tab, setTab] = useState('accountreconciliation');
    
    const handlepagechange =(type)=>{
        setTab(type)
    }
  return (
    <>
    <div className="" style={{marginTop:'-12px'}}>
        <div className="row text-center">

            <div className='' style={{ background: '#1D3557', height: '100%', width: '5%', minWidth: '100px', position: 'fixed' }}>

               
                <div className="aggDetailsTab" style={{ color: '#fff',marginTop:'15px',cursor:'pointer' }} >
                    <a
                        className="tabText"
                        style={{ color: '#fff' ,fontSize: '10px' }}
                        onClick={() => handlepagechange('accountreconciliation')}
                    >
                        <div className={tab === 'accountreconciliation' ? 'active-box' : 'inactive-box'}>
                            {/* <i class="fa fa-users" aria-hidden="true"></i> */}
                            <img src={accountreconciliaitonimg} width={'100%'} alt="" />
                        </div>
                       Account Reconciliation
                    </a>
                </div>
                {/* <div className="aggDetailsTab" style={{ color: '#fff',marginTop:'15px',cursor:'pointer' }} >
                    <a
                        className="tabText"
                        style={{ color: '#fff' ,fontSize: '10px' }}
                        onClick={() => handlepagechange('MandateReport')}
                    >
                        <div className={tab === 'MandateReport' ? 'active-box' : 'inactive-box'}>
                            <img src={MandateReportimg} width={'100%'} alt="" />
                        </div>
                       
                        Mandate<br/> Report
                    </a>
                </div> */}
                
            </div>
        </div>
        <div style={{ width: 'calc(95%-100px)', marginLeft: '100px' }}>

            {tab === 'accountreconciliation' ?
                <AccountReconciliaitonPage />
                : ""}
            {tab === 'MandateReport' ?
                <MandateReport />
                : ""}
            
        </div>
    </div>
</>
  )
}
