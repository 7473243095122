import React, { Component } from 'react'
import { TYPE } from '../../../../../utils/Constant'
import { APIS } from '../../../../../utils/api-factory'
import DisburseDataPopup from '../../../../presentationals/Popup/DisburseData.component'
import { _formatDateInDash, _preFormatDate } from '../../../../../utils/validation'
import moment from 'moment'
import SidePopup from '../../../../presentationals/Popup/Popup.component'

class DisburseData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disburseData: {
        disburseDate: _formatDateInDash(new Date()),
        disburseDateToShow: '',
        code: '',
        pin: '',
        insuranceAmount: ''
        // expiryDate: '',
        // expiryDateShow: ''
      },
      errorMsg: '',
      disabled: false,
      expiryDate: '',
      popupState: false,
      popupStatus: ''
    }
  }

  componentDidMount() {
    var oneYearFromNow = new Date()
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
    let datetes = moment(oneYearFromNow).format('DD-MM-YYYY')

    this.setState({ expiryDate: datetes })
  }

  render() {
    const { userDetails } = this.props
    return (
      <div>
        {this.state.popupState ?
          <SidePopup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
          : ""}
        <DisburseDataPopup
          expiryDate={this.state.expiryDate}
          userDetails={userDetails}
          closeDisbursePopup={this.closeDisbursePopup.bind(this)}
          disburseDataChange={this.disburseDataChange.bind(this)}
          disburseData={this.state.disburseData}
          errorMsg={this.state.errorMsg}
          disabled={this.state.disabled}
          disburseDataHandler={this.disburseDataHandler.bind(this)}
        />
      </div>

    )
  }

  closeDisbursePopup(res) {
    this.props.closeDisbursePopup(res)
  }
  closePopup() {
    this.setState({ popupState: false })
  }
  removePopup() {
    setTimeout(function () {
      this.setState({ popupState: false });
    }.bind(this), 5000)
  }
  disburseDataChange(e, dataType) {
    const { allDetail } = this.props

    let disburseData = Object.assign({}, this.state.disburseData)
    if (dataType == TYPE.LOAN_APPROVE_DATE) {
      //   console.log(moment(allDetail.installmentDate))
      this.getNextYearDate(e)
      let dateDiff = moment(allDetail.installmentDate, 'DD-MM-YYYY').diff(
        e,
        'days'
      )
      disburseData.disburseDate = _formatDateInDash(e)
      disburseData.disburseDateToShow = e
      if (dateDiff < 7) {
        this.setState({
          errorMsg: 'Days Validation Error!'
        })
      } else {
        this.setState({
          errorMsg: ''
        })
      }
    } else if (dataType == 'code') {
      disburseData.code = e.target.value
    } else if (dataType == 'pin') {
      disburseData.pin = e.target.value
    } else if (dataType == 'expiryDate') {
      //   disburseData.expiryDate = _formatDateInDash(e)
      //     disburseData.expiryDateShow = e
      //   this.setState({expiryDate:_formatDateInDash(e)})
    } else if (dataType == 'sku') {
      disburseData.sku = e.target.value
    } else if (dataType == 'insuranceAmount') {
      disburseData.insuranceAmount = e.target.value
    }
    this.setState({
      disburseData
    })
  }
  getNextYearDate(e) {
    var oneYearFromNow = new Date(e)
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

    var datetest = moment(oneYearFromNow).format('DD-MM-YYYY')

    this.setState({ expiryDate: datetest })
  }
  disburseDataHandler() {
    const { allDetail, admin, mailState, userDetails } = this.props
    this.setState({ disabled: true })
    if (userDetails.loanFrom === 'vreedhi') {
      if (this.state.disburseData.insuranceAmount !== '') {
        fetch(APIS.DISBERSE_API + userDetails.loanId + '&date=' + this.state.disburseData.disburseDate + '&empId=' + admin.emailId + '&mail=' + mailState + '&nbfcName=' + (userDetails.loanFrom !== "vreedhi" ? admin.nbfcName : 'vreedhi') + '&insuranceAmount=' + this.state.disburseData.insuranceAmount)
          .then(json => json.text())
          .then(res => {
            if (res == 'success') {
              this.closeDisbursePopup(res)
              this.setState({ disabled: false })
            }
          })
      } else {
        this.setState({ popupStatus: 'Please enter Insurance Amount First!', popupState: true, disabled: false }, () => this.removePopup())

      }
    }
    if (userDetails.typeOfProduct == 'Loan' && userDetails.loanFrom !== 'vreedhi') {
      fetch(APIS.DISBERSE_API + userDetails.loanId + '&date=' + this.state.disburseData.disburseDate + '&empId=' + admin.emailId + '&mail=' + mailState + '&nbfcName=' + (userDetails.loanFrom === "vreedhi" ? 'vreedhi' : userDetails.loanFrom === 'bazarBussiness' ? 'bazarBussiness' : admin.nbfcName) + '&insuranceAmount=' + this.state.disburseData.insuranceAmount)
        .then(json => json.text())
        .then(res => {
          if (res == 'success') {
            this.closeDisbursePopup(res)
            this.setState({ disabled: false })
          }
        })
    }
    //  else if (
    //   userDetails.typeOfProduct == 'Gift Card' &&
    //   userDetails.giftCardType == 'Amazon'
    // ) {
    //   console.log( APIS.DISBURSE_GIFT_CARD_API +
    //     userDetails.giftCardId +
    //     '&date=' +
    //     this.state.disburseData.disburseDate +
    //     '&empId=' +
    //     admin.emailId +
    //     '&mail=' +
    //     mailState +
    //     '&nbfcName=' +
    //     admin.nbfcName +
    //     '&voucherCode=' +
    //     this.state.disburseData.code +
    //     '&voucherPin=' +
    //     this.state.disburseData.pin +
    //     '&expiryDate=' +
    //     this.state.expiryDate +
    //     '&sku='+ this.state.disburseData.sku
    //     )
    //   fetch(
    //     APIS.DISBURSE_GIFT_CARD_API +
    //       userDetails.giftCardId +
    //       '&date=' +
    //       this.state.disburseData.disburseDate +
    //       '&empId=' +
    //       admin.emailId +
    //       '&mail=' +
    //       mailState +
    //       '&nbfcName=' +
    //       admin.nbfcName +
    //       '&voucherCode=' +
    //       this.state.disburseData.code +
    //       '&voucherPin=' +
    //       this.state.disburseData.pin +
    //       '&expiryDate=' +
    //       this.state.expiryDate+
    //       this.state.expiryDate +
    //       '&sku='+ this.state.disburseData.sku
    //   )
    //     .then(json => json.text())
    //     .then(res => {
    //       console.log(res)

    //       if (res == 'success') {
    //         this.closeDisbursePopup(res)
    //         this.setState({ disabled: false })
    //       }else{ 
    //           this.closeDisbursePopup(res)
    //       }
    //     })
    // }
    else if (
      userDetails.typeOfProduct == 'Gift Card'
      // &&
      // userDetails.giftCardType == 'Flipkart'
    ) {

      fetch(
        APIS.DISBURSE_GIFT_CARD_API +
        userDetails.giftCardId +
        '&date=' +
        this.state.disburseData.disburseDate +
        '&empId=' +
        admin.emailId +
        '&mail=' +
        mailState +
        '&nbfcName=' +
        admin.nbfcName +
        '&voucherCode=' +
        '' +
        '&voucherPin=' +
        '' +
        '&expiryDate=' +
        ''
      )
        .then(json => json.text())
        .then(res => {
          if (res == 'success') {
            this.closeDisbursePopup(res)
            this.setState({ disabled: false })
          }
        })
    }
  }
}

export default DisburseData
