import React, { Component } from 'react'
import CustomSearch from '../../../presentationals/Admin/DashboardAndReports/CustomSearchReport.component'
import { _preFormatDate } from '../../../../utils/validation'
import { STRING } from '../../../../utils/Constant'
import { filteredDataExcel } from '../AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/Loader.component'

class CusSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportApplyDate: '',
            exportApproveDate: '',
            exportDisburseDate: '',
            exportPaidDate: '',
            loaderState: false,
            exportData: {
                genderState: '',
                cityState: '',
                pincodeState: '',
                resTypeState: '',
                orgNameState: '',
                designationState: '',
                salaryState: '',
                amountState: '',
                applyDateState: '',
                tenureState: '',
                loanStatusState: STRING.SELECT_ALL,
                approveDateState: '',
                disburseDateState: '',
                paidDateState: '',
                deviceState: '',
                addressTypeState: ''
            }
        }
    }

    render() {
        const { allStatus } = this.props
        return (
            <div className="full-height">
                {this.state.loaderState == true ?
                    <Loader />
                    : ""}
                <CustomSearch
                    allStatus={allStatus}
                    customExport={this.customExport.bind(this)}
                    customExportHandler={this.customExportHandler.bind(this)}
                    exportApplyDate={this.state.exportApplyDate}
                    exportApproveDate={this.state.exportApproveDate}
                    exportDisburseDate={this.state.exportDisburseDate}
                    exportPaidDate={this.state.exportPaidDate}
                    exportData={this.state.exportData}
                />
            </div>
        )
    }

    customExport(e, searchType) {
        let exportData = Object.assign({}, this.state.exportData);
        if (searchType == 'gender') {
            exportData.genderState = e.target.value
        } else if (searchType == 'city') {
            exportData.cityState = e.target.value
        } else if (searchType == 'pincode') {
            exportData.pincodeState = e.target.value
        } else if (searchType == 'resType') {
            exportData.resTypeState = e.target.value
        } else if (searchType == 'orgName') {
            exportData.orgNameState = e.target.value
        } else if (searchType == 'designation') {
            exportData.designationState = e.target.value
        } else if (searchType == 'salary') {
            exportData.salaryState = e.target.value
        } else if (searchType == 'amount') {
            exportData.amountState = e.target.value
        } else if (searchType == 'applyDate') {
            exportData.applyDateState = _preFormatDate(e)
            this.setState({ exportApplyDate: e })
        } else if (searchType == 'tenure') {
            exportData.tenureState = e.target.value
        } else if (searchType == 'loanStatus') {
            exportData.loanStatusState = e.target.value
        } else if (searchType == 'approveDate') {
            exportData.approveDateState = _preFormatDate(e)
            this.setState({ exportApproveDate: e })
        } else if (searchType == 'disburseDate') {
            exportData.disburseDateState = _preFormatDate(e)
            this.setState({ exportDisburseDate: e })
        } else if (searchType == 'paidDate') {
            exportData.paidDateState = _preFormatDate(e)
            this.setState({ exportPaidDate: e })
        } else if (searchType == 'device') {
            exportData.deviceState = e.target.value
        } else if (searchType == 'addressType') {
            exportData.addressTypeState = e.target.value
        }
        this.setState({ exportData })
    }
    customExportHandler() {
        this.setState({ loaderState: true })
        filteredDataExcel(this.state.exportData,
            (callBack) => {
                this.setState({ loaderState: false })
            })
    }
}

export default CusSearch;