import React from "react";
import { checkMobileNumberExistsOrNot, fetchUserBasicDetail, saveLoanId } from "../../AdminActionCreator.component";
import { _formatDate, basicFormValidation } from "../../../../../utils/validation";
import PopUp from "../../../../presentationals/Popup/Popup.component";
import ReactDatePicker from "react-datepicker";
import { FORM_STATUS, ROLE, STRING, TYPE } from "../../../../../utils/Constant";

class PersonalEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetail: {},
            popupState: false,
            popupStatus: '',
            basicValidationMsg: {},
            BasicDOB: '',
            duplicateMobileError: ''
        }
    }
    onChangeHandlerLoanId(e, type) {
        let userDetail = Object.assign({}, this.state.userDetail)
        if (type == TYPE.APP_LOAN_ID) {
            userDetail.appLoanId = e.target.value
            this.setState({ userDetail: userDetail })
        }
    }
    loanIdHandlerSave() {
        const { userdata } = this.props

        if (this.state.userDetail.appLoanId != '') {
            saveLoanId(
                this.state.userDetail.appLoanId,
                userdata.userId,
                callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Loan Id save successfully',
                            disburseLoanId: ''
                        })
                        this.removePopup()
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Loan Id not save'
                        })
                        this.removePopup()
                    }
                }
            )
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please enter loan id',
                disburseLoanId: ''
            })
            this.removePopup()
        }
    }
    componentDidMount() {
        const { userdata } = this.props
        console.log(userdata.applicationLoanId)
        let userDetail = Object.assign({}, this.state.userDetail)

        if (
            userdata.firstName != null &&
            userdata.firstName != ''
        ) {
            userDetail.firstNameState = userdata.firstName
        }
        if (
            userdata.middleName != null &&
            userdata.middleName != ''
        ) {
            userDetail.MiddleNameState = userdata.middleName
        }
        if (
            userdata.lastName != null &&
            userdata.lastName != ''
        ) {
            userDetail.lastNameState = userdata.lastName
        }
        if (
            userdata.dateOfBirth != null &&
            userdata.dateOfBirth != ''
        ) {
            userDetail.BasicDOB = userdata.dateOfBirth
        }
        if (
            userdata.gender != null &&
            userdata.gender != ''
        ) {
            userDetail.GenderState = userdata.gender
        }
        if (
            userdata.emailId != null &&
            userdata.emailId != ''
        ) {
            userDetail.EmailIDState = userdata.emailId
        }
        if (
            userdata.mobileNumber != null &&
            userdata.mobileNumber != ''
        ) {
            userDetail.MobileNumberState = userdata.mobileNumber
        }
        if (
            userdata.alternativeNo != null &&
            userdata.alternativeNo != ''
        ) {
            userDetail.alternateMobileState = userdata.alternativeNo
        }
        if (
            userdata.userId != null &&
            userdata.userId != ''
        ) {
            userDetail.userId = userdata.userId
        }
        if (
            userdata.loanId != null &&
            userdata.loanId != ''
        ) {
            userDetail.loanId = userdata.loanId
        }
        if (
            userdata.applicationLoanId != null &&
            userdata.applicationLoanId != ''
        ) {
            userDetail.appLoanId = userdata.applicationLoanId
        }
        userDetail.formStatus = FORM_STATUS.UPDATE
        this.setState({ userDetail: userDetail })
    }
    onSubmitHandler() {
        const { admin } = this.props
        let BasicValidation = basicFormValidation(this.state.userDetail)
        console.log(BasicValidation.status, this.state.duplicateMobileError)
        if (BasicValidation.status && this.state.duplicateMobileError === '') {
            this.setState({
                basicValidationMsg: BasicValidation
            })

            fetchUserBasicDetail(
                this.state.userDetail,
                this.state.userDetail,
                admin,
                callBack => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Basic Details Changed Successfully',
                        // dataChange:'yes'
                    })
                    // this.props.userDetailHandler(this.state.userDetail)
                    this.removePopup()
                }
            )
        } else {
            this.setState({
                basicValidationMsg: BasicValidation
            })
        }
    }
    removePopup() {
        setTimeout(
            function () {
                this.setState({ popupState: false })
            }.bind(this),
            5000
        )
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    onChangeHandler(e, type) {
        const { admin } = this.props
        let userDetail = Object.assign({}, this.state.userDetail)
        if (type == TYPE.FIRST_NAME) {
            userDetail.firstNameState = e.target.value
        } else if (type == TYPE.MIDDLE_NAME) {
            userDetail.MiddleNameState = e.target.value
        } else if (type == TYPE.LAST_NAME) {
            userDetail.lastNameState = e.target.value
        } else if (type == TYPE.DOB) {
            if (e < new Date()) {
                userDetail.BasicDOB = _formatDate(e)
                this.setState({
                    BasicDOB: e
                })
            } else {
                userDetail.BasicDOB = ''
                this.setState({
                    BasicDOB: ''
                })
            }
        } else if (type == TYPE.GENDER) {
            userDetail.GenderState = e.target.value
        }
        else if (type == TYPE.MOBILE_NO) {
            userDetail.MobileNumberState = e.target.value
            if (e.target.value.length === 10) {
                this.checkMobile(e.target.value)
            }
        }
        else if (type == TYPE.EMAIL_ID) {
            userDetail.EmailIDState = e.target.value
        }
        else if (type == TYPE.ALTERNATE_MOBILE) {
            userDetail.alternateMobileState = e.target.value
        }
        this.setState({ userDetail: userDetail })

    }
    checkMobile(mobileNumber) {
        const { admin, userdata } = this.props
        checkMobileNumberExistsOrNot(mobileNumber, userdata.userId, callBack => {
            console.log(callBack.status)
            if (callBack.status === 'success') {
                this.setState({ duplicateMobileError: '' })

            }
            if (callBack.status !== 'success') {
                this.setState({ duplicateMobileError: callBack.data[0] })
            }

        })
    }
    render() {
        const { admin, userdata } = this.props

        return (
            <div className="container details-new">
                {this.state.popupState == true ? (
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
                <div className="details-new background-blur" style={{ background: 'rgb(5 5 5 / 20%)', color: '#000' }}>
                    <div className="edit-box ">
                        <div className="text-right" style={{ color: 'red' }}><i onClick={() => this.props.editHandler('')} style={{ fontSize: '20px', cursor: 'pointer' }} className="fa fa-times"></i></div>

                        <h4><b>Edit Personal Details</b></h4>

                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 col-xs-12">
                                <label>First Name</label>
                                <input type="text" className="form-control" value={this.state.userDetail.firstNameState} onChange={(e) => this.onChangeHandler(e, TYPE.FIRST_NAME)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.basicValidationMsg.field == "firstName" ? this.state.basicValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Middle Name</label>
                                <input type="text" className="form-control" value={this.state.userDetail.MiddleNameState} onChange={(e) => this.onChangeHandler(e, TYPE.MIDDLE_NAME)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.basicValidationMsg.field == "middleName" ? this.state.basicValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Last Name</label>
                                <input type="text" className="form-control" value={this.state.userDetail.lastNameState} onChange={(e) => this.onChangeHandler(e, TYPE.LAST_NAME)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.basicValidationMsg.field == "lastName" ? this.state.basicValidationMsg.msg : ""}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <label>D.O.B</label>
                                <ReactDatePicker
                                    value={this.state.userDetail.BasicDOB}
                                    selected={this.state.BasicDOB}
                                    onChange={(e) => this.onChangeHandler(e, TYPE.DOB)}
                                    className="form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    dateFormat="DD/MM/YYYY"
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={50}
                                />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.basicValidationMsg.field == "BasicDOB" ? this.state.basicValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Gender</label>
                                <select className="form-control" value={this.state.userDetail.GenderState} onChange={(e) => this.onChangeHandler(e, TYPE.GENDER)}>
                                    <option value="" disabled>Select Gender</option>
                                    <option value={STRING.MALE}>{STRING.MALE}</option>
                                    <option value={STRING.FEMALE}>{STRING.FEMALE}</option>
                                    <option value={STRING.OTHERS}>{STRING.OTHERS}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.basicValidationMsg.field == "Gender" ? this.state.basicValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Email Id</label>
                                <input type="email" readOnly={admin != null && admin.role != null && admin.role == 'superuser' ? false : true} className="form-control" value={this.state.userDetail.EmailIDState} onChange={(e) => this.onChangeHandler(e, TYPE.EMAIL_ID)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.basicValidationMsg.field == "emailId" ? this.state.basicValidationMsg.msg : ""}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <label>Alternate Mobile Number</label>
                                <input type="number" className="form-control" value={this.state.userDetail.alternateMobileState} onChange={(e) => this.onChangeHandler(e, TYPE.ALTERNATE_MOBILE)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.basicValidationMsg.field == "alternateMobile" ? this.state.basicValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Mobile Number</label>

                                <input readOnly={admin != null && admin.rolelist.indexOf(ROLE.MOBILE_NO_EDIT) >= 0 ? false : true} type="number" className="form-control" value={this.state.userDetail.MobileNumberState} onChange={(e) => this.onChangeHandler(e, TYPE.MOBILE_NO)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.duplicateMobileError !== '' && this.state.duplicateMobileError ? 'Mobile Number Already Exist with Email :' + this.state.duplicateMobileError + 'Id' : ""}
                                </div>
                            </div>
                        </div>

                        <div className="row " style={{ marginTop: '20px' }}>
                            <div className="col-xs-12">
                                <button className="btn btn-primary" id="" disabled={admin.emailId !== 'SuperUser' ? (userdata.statusId === '101' || userdata.statusId === '102' || userdata.statusId === '103' || userdata.statusId === '105' || userdata.statusId === '113' || userdata.statusId === '117' || userdata.statusId === '110' || userdata.statusId === '109') ? false : true : false} onClick={(e) => this.onSubmitHandler(e)}>
                                    Update Personal Details
                                </button>
                            </div>
                        </div>

                        {admin != null && admin.rolelist.indexOf(ROLE.ADD_LOAN_ID) >= 0 ?
                            <>
                                <label>Loan Id</label>
                                <div className="display-flex" style={{ width: '300px' }}>
                                    <input className="" type="text" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }} onChange={(e) => this.onChangeHandlerLoanId(e, TYPE.APP_LOAN_ID)} value={this.state.userDetail.appLoanId} />
                                    <button className="" style={{ backgroundColor: '#267DFF', border: '1px solid #2e6da4', color: '#fff', borderTopRightRadius: 10, borderBottomRightRadius: 10, width: '50px' }} onClick={(e) => this.loanIdHandlerSave(e, 'LoanId')}><span className="fa fa-save" /></button>
                                </div>
                            </>
                            : ""}



                    </div>

                </div>
            </div >
        )
    }
}
export default PersonalEdit