import React, { useEffect, useState } from "react";
import { getAllEmployees } from "./targetactioncreator";

const EmpList = ({ editHandler }) => {
    const [employeeData, setEmpData] = useState({})
    // const employeeData = {
    //     "employeeName": "Deepanshu",
    //     "employeeType": "Full-Time",
    //     "employeeDepartment": "Engineering",
    //     "joiningDate": "2023-08-22",
    //     "salary": 75000.00,
    //     "variable": 5000.00,
    //     "otherComponent": 2000.00,
    //     "isActive": true,
    //     "inHandAmount": 65000.00,
    //     "taxDeducted": 10000.00,
    //     "id":1,
    //     "employeeID":1
    // };

    const handleToggle = () => { };
    const handleRestart = () => { };
    const handlebtnclick = () => { };
    const view = () => { };
    const millisecondsToMinutes = () => { };
    useEffect(() => {
        getAllEmployees(callback => {
            console.log(callback)
            setEmpData(callback.data)
        })
    }, [])
    return (
        <div className="container Executor">
            <div className='' style={{ background: '#f2f2f2', padding: '10px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px', overflow: 'auto', marginTop: '50px' }}>
                <div className="">
                    <table className='table-logs' style={{ marginBottom: '10px' }}>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>Employee Type</th>
                                <th>Department</th>
                                <th>Joining Date</th>
                                <th>Salary</th>
                                <th>Variable</th>
                                <th>Other Components</th>
                                <th>Active</th>
                                <th>In-Hand Amount</th>
                                <th>Tax Deducted</th>

                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                <td className='text-center'  >{employeeData.employeeName}</td>
                                <td>{employeeData.employeeType}</td>
                                <td>{employeeData.employeeDepartment}</td>
                                <td>{employeeData.joiningDate}</td>
                                <td>{employeeData.salary}</td>
                                <td>{employeeData.variable}</td>
                                <td>{employeeData.otherComponent}</td>
                                <td style={{ width: '80px' }}>
                                    <input readOnly type='checkbox' checked={employeeData.isActive} />
                                </td>
                                <td>{employeeData.inHandAmount}</td>
                                <td>{employeeData.taxDeducted}</td>

                                <td>
                                    <i className="fa fa-pencil-square-o fa-lg" aria-hidden="true" onClick={() => editHandler(employeeData)}></i>
                                </td>
                                <td>
                                    <i className="fa fa-trash-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('delete', employeeData)}></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EmpList;
