import React, { useEffect, useState } from "react";
import { getAllBusinessReturn } from "../../targetactioncreator";

const CategoryList = ({ editHandler,ActiveMonthFilter }) => {
    // const expenseData = {
    //     "categoryType": 'test',
    //     "categoryDescription": "test",
    //     "id":'1'

    // };
    const [expenseData, setexpenseData] = useState({})

    const handleDelete = () => {
    };
    useEffect(() => {
        getAllBusinessReturn(callback=>{
            setexpenseData(callback.data)
        })
    }, [ActiveMonthFilter])
    return (
        <div className="container Executor">
            <div
                className=""
                style={{
                    background: '#f2f2f2',
                    padding: '10px',
                    margin: '50px auto 0',
                    borderRadius: '5px',
                    overflow: 'auto',
                    maxWidth: '1000px'
                }}
            >
                <div className="">
                    <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Target Return</th>
                                <th>Current Return</th>
                                <th>Target Profit</th>
                                <th>Current Profit</th>
                                <th>Target Expenses</th>
                                <th>Current Expenses</th>
                                <th>Target Collection</th>
                                <th>Current Collection</th>

                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                <td >{expenseData.targetReturn}</td>
                                <td>{expenseData.currentReturn}</td>
                                <td>{expenseData.targetProfit}</td>
                                <td>{expenseData.currentProfit}</td>
                                <td>{expenseData.targetExpenses}</td>
                                <td>{expenseData.currentExpenses}</td>
                                <td>{expenseData.targetCollection}</td>
                                <td>{expenseData.currentCollection}</td>
                                <td>
                                    <i
                                        className="fa fa-pencil-square-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => editHandler(expenseData)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Edit Expense"
                                        title="Edit Expense"
                                    ></i>
                                </td>
                                <td>
                                    <i
                                        className="fa fa-trash-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => handleDelete(expenseData)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Delete Expense"
                                        title="Delete Expense"
                                    ></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CategoryList;
