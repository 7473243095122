import React from 'react'
import ReactHtmlParser from 'react-html-parser';

const userStatus = ({ statusActivityData,loaderState }) => (
    <div className="container-fluid crm-user-cibil">
        {loaderState != true?
        <div>
        <div className="row form-group">
            <h3 className="text-center blue-text">Status Activity</h3>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                <table className="text-center phocket-table" >
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Date &amp; Time</th>
                            <th>Comment</th>
                            <th>Emp Id</th>
                            <th>Time Difference(Minutes)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statusActivityData != '' & statusActivityData.length > 0 ?
                            statusActivityData.map((status, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{status.status}</td>
                                        <td className="nowrap">{status.date}</td>
                                        <td style={{whiteSpace:'normal'}}>{ReactHtmlParser(status.comment)}</td>
                                        <td>{status.empId}</td>
                                        <td>{status.diffMinutes}</td>
                                    </tr>
                                )
                            }) : null}
                    </tbody>
                </table>
            </div>
        </div>
        </div>
        :""}
    </div>
)

export default userStatus;
