import React from 'react'

const WhatsappPopup = ({ messages, closeWhatsappPopup }) => (
    <div className="">
        {messages != null && messages.messages != null && messages.messages.length > 0 ?
            <div className="whatsapp-popup">
                <div className="background-blur" onClick={e => closeWhatsappPopup('')}></div>
                <div className="popup-main small-popup container-fluid max-width-500px overflow-auto">
                    {messages.messages.map((msg, i) => {
                        return (
                            <p key={i}>
                                {msg.body.substring(0, 4) === 'http' ?
                                    <a target="_blank" href={msg.body}>
                                        {msg.body.includes('.pdf?alt') ?
                                            <object className="area-200px" data={msg.body} type="application/pdf"></object>
                                            : <img className="area-200px" src={msg.body} />
                                        }
                                    </a>
                                    : <span>{msg.body}</span>
                                }
                            </p>
                        )
                    })}
                </div>
            </div>
            : null}
    </div>
)

export default WhatsappPopup;