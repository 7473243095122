import React from 'react'
import ProductConfigUi from '../../../presentationals/Admin/NbfcManagementNew/EnachConfig.component';
import PopUp from '../../../presentationals/Popup/Popup.component';
import { saveEnachConfigNbfc } from '../AdminActionCreator.component';
import { nbfcEnachConfigurationValidation } from '../../../../utils/validation';
import Loader from '../../../presentationals/Loader/Loader.component'
import moment from 'moment';

class ProductConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productConfig: {
                id: '',
                nbfcId: '',
                apiKey: '',
                apiSecret: ''


            },
            validationMsg: '',
            popupState: false,
            popupStatus: '',
            loaderState: false

        }
    }
    componentDidMount() {
        if (this.props && this.props.nbfcId !== null && this.props.nbfcId !== undefined) {
            let changeData = Object.assign({}, this.state.productConfig)
            changeData.nbfcId = this.props.nbfcId
            this.setState({ productConfig: changeData })
        }
        if (this.props && this.props.nbfcData !== undefined && this.props.nbfcData !== '') {
            let changeData = Object.assign({}, this.state.productConfig)
        
            changeData.apiKey = this.props.nbfcData.apiKey
            changeData.apiSecret = this.props.nbfcData.apiSecret
            changeData.nbfcId = this.props.nbfcData.nbfcId
            changeData.id = this.props.nbfcData.id
            this.setState({ productConfig: changeData })
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    changeHandler(e, type) {
        let changeData = Object.assign({}, this.state.productConfig)

        if (type === 'apiSecret') {
            changeData.apiSecret = e.target.value
        }
        if (type === 'apiKey') {
            changeData.apiKey = e.target.value
        }
       

        this.setState({ productConfig: changeData })
    }
    submit() {
        let validation = nbfcEnachConfigurationValidation(this.state.productConfig)
        if (validation.status) {
            this.setState({ loaderState: true })
            saveEnachConfigNbfc(this.state.productConfig, callback => {
                if (callback === 'success') {
                    this.setState({ loaderState: false, popupStatus: 'Successfully Saved !', popupState: true }, () => this.removePopup())
                } else {
                    this.setState({ loaderState: false, popupStatus: 'Please try again later !', popupState: true }, () => this.removePopup())

                }
            })
        } else {
            this.setState({ validationMsg: validation })
        }
    }
    render() {
        return (
            <div>
                <ProductConfigUi
                    validationMsg={this.state.validationMsg}
                    submit={this.submit.bind(this)}
                    productConfig={this.state.productConfig}
                    changeHandler={this.changeHandler.bind(this)}
                />
                {this.state.popupState == true ?
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
            </div>
        )
    }
}
export default ProductConfig