import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import IncimingPayment from '../../../presentationals/Admin/Recovery/newIncommingPayment.component';
import RecoveryCommunication from './RecoveryCommunication.container'
import {
    getistByNumber, getlistByEmail, getlistByApplication, getIncomingPaymentByWeek,
    getIncomingPaymentByDate, getUserDetailByLoanId, saveCHequeNoticeApi,
    deductUserPaymentApi, getSourceOfEnach, deductUserPaymentByTechProcess
} from '../AdminActionCreator.component';
import { APIS } from '../../../../utils/api-factory';
import { STRING } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import Popup from '../../../presentationals/Popup/Popup.component';
import AmountDeductionPopup from '../../../presentationals/Admin/Recovery/GetAmountForDeduction.popup'
import ChequeNoticePopup from '../../../presentationals/Admin/Recovery/NoticeAndCheckDate.component'
import SourceOfEnachUi from '../../../presentationals/Admin/Recovery/SourceEnach.component'
let paginationNumber=10
class IncomingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recoveryPageState: true,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.SELECT_ALL,
            forwordPageState: 1,
            loadarState: false,
            viewDetailPopupState: false,
            viewPopupDetail: '',
            logsUserId: '',
            dateToShow: '',
            dateToSend: '',
            actionButtonState: false,
            TXNConfirmationPopup: false,
            confirmationText: '',
            loanIdForTXN: '',
            popupState: false,
            popupStatus: '',
            loadarState: false,
            FilteredData: [],
            pageIndexToShow: 0,
            dataForCommunication: '',
            chequeNoticePopup: false,
            chequeNoticeData: '',
            chequeNoticeDateToShow: '',
            chequeNoticeDate: '',
            chequeNoticeType: '',
            searchByAppNo: '',
            searchByemail: '',
            searchBymobile: '',
            collectionType: 'disburse',
            dataForDeduction: '',
            amountDeductionPopupState: false,
            amountForDeduction: '',
            loanIdForDeduction: '',
            disabled: false,
            userIdForSource: '',
            newData: '',
            SourceOfEnachUiState: false,
            source: '',
            startDate: '',
            // dateForDeduction:'',
            dateTechProcess: '',
            nachType: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    getLeads() {
        this.setState({ loadarState: true })
        this.props.getIncomingPaymentByWeek(this.state.forwordPageState, this.state.nachType,
            (callBack) => {
                this.setState({ loadarState: false })
            })
    }
    nachTypeHandler(e) {
        this.setState({ nachType: e.target.value }, () => this.getLeads())
    }

    componentDidMount() {
        this.getLeads()
    }

    render() {
        const { incomingPayment, admin } = this.props;
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.recoveryPageState == true ?
                    <IncimingPayment
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}

                        nachTypeHandler={this.nachTypeHandler.bind(this)}
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        allUserDetail={incomingPayment}
                        forwordpage={this.forwordpage.bind(this)}
                        backpage={this.backpage.bind(this)}
                        dataFilter={this.dataFilter.bind(this)}
                        userViewHandler={this.userViewHandler.bind(this)}
                        admin={admin}
                        recoveryComunicateHandler={this.recoveryComunicateHandler.bind(this)}
                        chequeNoticeShowHandler={this.chequeNoticeShowHandler.bind(this)}
                        loadarState={this.state.loadarState}
                        refreshpage={this.refreshpage.bind(this)}
                        dateToShow={this.state.dateToShow}
                        loanVarificationHandler={this.loanVarificationHandler.bind(this)}
                        loanTXNHandler={this.loanTXNHandler.bind(this)}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        searchByAppNo={this.state.searchByAppNo}
                        searchByemail={this.state.searchByemail}
                        searchBymobile={this.state.searchBymobile}
                        dateToSend={this.state.dateToSend}
                        paymentDeductHandler={this.paymentDeductHandler.bind(this)}
                    /> : ""}
                {this.state.communicationPageState == true ?
                    <RecoveryCommunication
                        dataForCommunication={this.state.dataForCommunication}
                        recoveryBackHandler={this.recoveryBackHandler.bind(this)}
                        admin={admin}
                        collectionType={this.state.collectionType}
                    />
                    : ""}
                {this.state.chequeNoticePopup == true ?
                    <ChequeNoticePopup
                        chequeNotice={this.chequeNotice.bind(this)}
                        chequeNoticeHandler={this.chequeNoticeHandler.bind(this)}
                        chequeNoticeDateToShow={this.state.chequeNoticeDateToShow}
                        chequeNoticeDate={this.state.chequeNoticeDate}
                        closeNoticePopup={this.closeNoticePopup.bind(this)} />
                    : ""}
                {this.state.amountDeductionPopupState ?
                    <AmountDeductionPopup
                        source={this.state.source}
                        dateHandler={this.dateHandler.bind(this)}
                        dateTechProcess={this.state.dateTechProcess}
                        startDate={this.state.startDate}
                        amountForDeduction={this.state.amountForDeduction}
                        deductionAmountChange={this.deductionAmountChange.bind(this)}
                        deductionAmountHandler={this.deductionAmountHandler.bind(this)}
                        disabled={this.state.disabled}
                    />
                    : null}
                {this.state.SourceOfEnachUiState == true ?
                    <SourceOfEnachUi
                        Sourcedata={this.state.Sourcedata}
                        close={this.close.bind(this)}
                        handler={this.handler.bind(this)}
                        sourceHandler={this.sourceHandler.bind(this)}
                    />
                    : ""}
            </div>
        )
    }
    dateHandler(e) {
        this.setState({
            startDate: e,
            dateTechProcess: _preFormatDate(e)
        })
    }
    close() {
        this.setState({
            SourceOfEnachUiState: false
        })
    }
    handler(e) {
        this.setState({
            source: e.target.value
        })
    }
    sourceHandler(e) {
        this.setState({
            SourceOfEnachUiState: false,
            amountDeductionPopupState: true,

        })

    }
    deductionAmountChange(e) {
        this.setState({
            amountForDeduction: e.target.value
        })
    }

    deductionAmountHandler(type) {
        if (type == 'yes') {
            this.setState({
                disabled: true
            })
            if (this.state.source != 'techProcess') {
                deductUserPaymentApi(
                    this.state.loanIdForDeduction,
                    this.state.amountForDeduction,
                    '',
                    callBack => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'success',
                                amountDeductionPopupState: false,
                                disabled: false
                            })
                            this.removePopup()
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again',
                                amountDeductionPopupState: false,
                                disabled: false
                            })
                            this.removePopup()
                        }
                    }
                )
            } else {
                deductUserPaymentByTechProcess(
                    this.state.loanIdForDeduction,
                    this.state.amountForDeduction,
                    this.state.dateTechProcess,
                    callBack => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'success',
                                amountDeductionPopupState: false,
                                disabled: false
                            })
                            this.removePopup()
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again',
                                amountDeductionPopupState: false,
                                disabled: false
                            })
                            this.removePopup()
                        }
                    }
                )
            }
        } else if (type == 'no') {
            this.setState({ amountDeductionPopupState: false })
        }
    }
    paymentDeductHandler(data) {
        // this.setState({
        //     amountDeductionPopupState: true,
        //     loanIdForDeduction: data.loanId,
        //     amountForDeduction: data.loanPaybackAmount
        // })
        getSourceOfEnach(data.userId, callBack => {
            if (
                callBack != '' &&
                callBack != null &&
                callBack != '' &&
                Object.keys(callBack).length > 0 &&
                callBack != 'no data'
            ) {
                this.setState({
                    SourceOfEnachUiState: true,
                    // amountDeductionPopupState: true,
                    userIdForSource: data.userId,
                    loanIdForDeduction: data.loanId,
                    amountForDeduction: data.loanPaybackAmount
                })
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'No Mandate Registered'
                })
                this.removePopup()
            }
            this.setState({
                Sourcedata: callBack
            })
        })
    }

    recoveryComunicateHandler(data) {
        this.setState({
            recoveryPageState: false,
            communicationPageState: true,
            dataForCommunication: data
        })
    }

    chequeNoticeShowHandler(data) {
        this.setState({
            chequeNoticePopup: true,
            chequeNoticeData: data
        })
    }

    chequeNotice(e, inpType) {
        if (inpType == 'chequeNotice') {
            this.setState({
                chequeNoticeType: e.target.value,
            })
        } else if (inpType == 'chequeNoticeDate') {
            this.setState({
                chequeNoticeDateToShow: e,
                chequeNoticeDate: _preFormatDate(e)
            })
        }
    }

    chequeNoticeHandler() {
        const { admin } = this.props;
        if (this.state.chequeNoticeType != '' && this.state.chequeNoticeDate != '') {
            saveCHequeNoticeApi(this.state.chequeNoticeData.loanId, this.state.chequeNoticeType, this.state.chequeNoticeDate, admin, this.state.chequeNoticeData.emiId,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            chequeNoticePopup: false,
                            chequeNoticeType: '',
                            chequeNoticeDate: '',
                            chequeNoticeDateToShow: '',
                            popupState: true,
                            popupStatus: 'Data saved successfully',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select TYPE & DATE',
            })
            this.removePopup();
        }
    }

    closeNoticePopup() {
        this.setState({
            chequeNoticePopup: false,
        })
    }


    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    loanVarificationHandler(loanId) {
        fetch(APIS.LOAN_VARIFICATION + loanId)
            .then(res => res.text())
            .then(text => {
                this.setState({
                    popupState: true,
                    popupStatus: text,
                })
                this.removePopup();
            })
    }

    loanTXNHandler(loanId) {
        this.setState({
            TXNConfirmationPopup: true,
            confirmationText: 'Are you sure for this TRANSACTION',
            loanIdForTXN: loanId
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    refreshpage() {
        this.getLeads()
    }
    // downloadCSV() {

    // }
    dataFilter(e, type) {
        const { admin } = this.props
        if (type == "applicationNo") {
            this.setState({ searchByAppNo: e.target.value })
            if (e.target.value.length > 4) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByApplication(e.target.value, 1, '', admin,true,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "email") {
            this.setState({ searchByemail: e.target.value })
            if (e.target.value.length > 2) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByEmail(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "mobileNo") {
            this.setState({ searchBymobile: e.target.value })
            if (e.target.value.length > 9) {
                this.setState({
                    loadarState: true
                })
                this.props.getistByNumber(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "Date") {
            this.setState({
                dateToShow: e,
                dateToSend: _preFormatDate(e)
            })
            this.props.getIncomingPaymentByDate(_preFormatDate(e), 1, this.state.nachType,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        }
        if (type != "Date" && e.target.value == "") {
            this.getLeads()
        }
    }
    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1,
            pageIndexToShow: 0
        }, () => this.getLeads())

    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                pageIndexToShow: 0
            }, () => this.getLeads())
        }
    }
    componentDidUpdate() {
        const { incomingPayment, admin } = this.props;
        if (incomingPayment.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }
    }

    recoveryBackHandler() {
        const { admin } = this.props
        this.setState({
            recoveryPageState: true,
            communicationPageState: false
        })
        if (this.state.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.searchByAppNo, 1, '', admin,true,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchByemail != '') {
            this.props.getlistByEmail(this.state.searchByemail, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchBymobile != '') {
            this.props.getistByNumber(this.state.searchBymobile, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.dateToSend != '') {
            this.props.getIncomingPaymentByDate(this.state.dateToSend, 1,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else {
            this.getLeads()
        }
    }
    userDetailHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    allDetail: callBack,
                    userDetail: allDetail
                })
            })
    }
    userViewHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    viewDetailPopupState: true,
                    viewPopupDetail: callBack
                })
            })
    }
    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

}

const mapStateToProps = ({ incomingPayment }) => {
    return {
        incomingPayment: incomingPayment,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getIncomingPaymentByWeek,
        getIncomingPaymentByDate
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(IncomingContainer);