import { APIS } from '../../../../utils/api-factory';
import { POST_HEADER } from '../../../../utils/apiHeaders'

export const saveCategory = (merchantData, admin, callBack) => {
    let allmerchantData = {
        category: merchantData.promoCatName,
        status: merchantData.promoCatStatus,
        categoryid: merchantData.categoryId,
        createdby: admin.emailId,
    }
    fetch(APIS.SAVE_OFFER_CATEGORY, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text);
        }).catch(error => console.error('Error:', error));
}

export const saveLocation = (merchantData, callBack) => {
    let allmerchantData = {
        location: merchantData.location,
        locationid: merchantData.locationId
    }
    fetch(APIS.SAVE_OFFR_LOCATION, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text);
        }).catch(error => console.error('Error:', error));
}

export const saveMerchant = (merchantData, admin, callBack) => {
    let allmerchantData = {
        merchantname: merchantData.merchantName,
        merchanthphone: Number(merchantData.merchantMobile),
        createdby: admin.emailId,
        merchantid: merchantData.merchantId,
        contactperson: merchantData.contactPersonName,
        contactpersonemail: merchantData.merchantEmail,
        companywebsite: merchantData.companyWebsite,
        companyregisteredaddress: merchantData.companyAddress,
        status: merchantData.SelectMerchentStatus,
        authorizedpersoncontactnumber: Number(merchantData.merchantMobile),
        merchant_userid: '',
        password: merchantData.password,
        priority: Number(merchantData.discountPriority)
    }
    fetch(APIS.SAVE_OFFER_MERCHANT, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text);
        }).catch(error => console.error('Error:', error));
}

export const saveOutLet = (merchantData, callBack) => {
    let allmerchantData = {
        outletid: Number(merchantData.outletId),
        couponid: Number(merchantData.couponId),
        id: merchantData.id
    }
    fetch(APIS.SAVE_MARCHANT_OUT_LET, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const saveSubCategory = (merchantData, admin, callBack) => {
    let allmerchantData = {
        createdby: admin.emailId,
        subcategoryid: merchantData.subCatId,
        subcategory_name: merchantData.subCatName,
        status: merchantData.selectSubCatStatus,
        categoryid: merchantData.categoryId
    }
    fetch(APIS.SAVE_OFFER_SUBCATEGORY, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text);
        }).catch(error => console.error('Error:', error));
}

export const getMerchantOutLets = (details, callBack) => {
    let allmerchantData = {
        merchantid: Number(details)
    }
    fetch(APIS.GET_MARCHANT_OUT_LET, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.json())
        .then(json => {
            callBack(json)
        }).catch(error => console.error('Error:', error));

}

export const getMappedOutletList = (details, callBack) => {
    let allmerchantData = {
        merchantid: Number(details)
    }
    fetch(APIS.GET_COUPON_ON_OUTLET, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.json())
        .then(json => {
            callBack(json)
        }).catch(error => console.error('Error:', error));

}

export const getCouponDataForMerchants = (details, callBack) => {
    let allmerchantData = {
        merchantid: Number(details)
    }
    fetch(APIS.GET_COUPON_LIST_FOR_MERCHANTS, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.json())
        .then(json => {
            callBack(json)
        }).catch(error => console.error('Error:', error));

}

export const AddmechantCoupon = (merchantData, couponTLC, couponDescription, couponTiming,details, admin, callBack) => {
    var allmerchantData = {
        createdby: admin.emailId,
        subCategoryId: merchantData.subCatId,
        title: merchantData.discountTitle,
        description: couponDescription,
        termscondition: couponTLC,
        timing: couponTiming,
        details: details,
        expireon: merchantData.selectDate,
        discount: merchantData.discountAmount,
        imageurl: '',
        merchantid: merchantData.merchantId,
        locationid: merchantData.locationId,
        zipcode: '',
        couponid: merchantData.couponid,
        status: merchantData.discountStatus,
        couponcode: merchantData.couponCode
    }
    fetch(APIS.MARCHANT_OFFER_SAVE, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const getListOfferDetail = (callBack) => {
    fetch(APIS.GET_MARCHANT_OFFER)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        }).catch(error => console.error('Error:', error));
}

export const getOfferDetail = (callBack) => {
    return dispatch => {
        fetch(APIS.GET_MARCHANT_OFFER_LISTING)
            .then(res => res.json())
            .then(json => {
                callBack(json)
            }).catch(error => console.error('Error:', error));
    }
}

export const saveOutletAddressAPI = (details, callBack) => {
    let allmerchantData = {
        merchantid: details.merchantId,
        locationid: details.locationId,
        address: details.subLocationName,
        outletid: details.subLocationId
    }
    fetch(APIS.SAVE_MARCHANT_OUT_LET_ADDRESS, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));

}

export const saveMerchantImages = (imageFile, merchantId, fileName, callBack) => {
    return new Promise((resolve, reject) => {
        let imageFormData = new FormData();
        for (let i = 0; i < imageFile.length; i++) {
            imageFormData.append('uploadfile', imageFile[i]);
        }
        // imageFormData.append('uploadfile', imageFile);
        imageFormData.append('userId', merchantId);
        imageFormData.append('type', 'img');
        imageFormData.append('fileName', fileName);
        var xhr = new XMLHttpRequest();
        xhr.open('post', APIS.MERCHANT_DOCUMENT_UPLOAD, true);
        xhr.onload = function () {
            if (this.status == 200) {
                resolve(this.response);
                callBack(this.response);
            } else {
                reject(this.statusText);
                callBack(this.response);
            }
        };
        xhr.send(imageFormData);
    });

}

export const saveCouponImages = (imageFile, couponId, fileName, callBack) => {
    return new Promise((resolve, reject) => {
        let imageFormData = new FormData();
        for (let i = 0; i < imageFile.length; i++) {
            imageFormData.append('uploadfile', imageFile[i]);
        }
        // imageFormData.append('uploadfile', imageFile);
        imageFormData.append('userId', couponId);
        imageFormData.append('type', 'img');
        imageFormData.append('fileName', fileName);
        var xhr = new XMLHttpRequest();
        xhr.open('post', APIS.COUPON_DOCUMENT_UPLOAD, true);
        xhr.onload = function () {
            if (this.status == 200) {
                resolve(this.response);
                callBack(this.response);
            } else {
                reject(this.statusText);
                callBack(this.response);
            }
        };
        xhr.send(imageFormData);
    });

}

export const getMerchantImages = (merchantId, callBack) => {
    let allmerchantData = {
        merchantid: merchantId,
    }
    fetch(APIS.GET_MERCHANT_IMAGES, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        }).catch(error => console.error('Error:', error));

}

export const getCouponImages = (couponId, callBack) => {
    let allcouponData = {
        couponid: couponId,
    }
    fetch(APIS.GET_COUPON_IMAGE, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allcouponData)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        }).catch(error => console.error('Error:', error));

}

export const saveMerchantAgreement = (imageFile, merchantId, fileName, callBack) => {
    return new Promise((resolve, reject) => {
        let imageFormData = new FormData();
        for (let i = 0; i < imageFile.length; i++) {
            imageFormData.append('uploadfile', imageFile[i]);
        }
        // imageFormData.append('uploadfile', imageFile);
        imageFormData.append('userId', merchantId);
        imageFormData.append('type', 'img');
        imageFormData.append('fileName', fileName);
        var xhr = new XMLHttpRequest();
        xhr.open('post', APIS.MERCHANT_AGREEMENT_UPLOAD, true);
        xhr.onload = function () {
            if (this.status == 200) {
                resolve(this.response);
                callBack(this.response);
            } else {
                reject(this.statusText);
                callBack(this.response);
            }
        };
        xhr.send(imageFormData);
    });

}

export const getCouponLoanStatus = (merchantData, callBack) => {
    let allmerchantData = {
        couponid: Number(merchantData.couponId),
        loanstatus: Number(merchantData.loanStatus),
    }
    fetch(APIS.MAP_COUPON_LOANSTATUS, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const deleteMerchant = (id, callBack) => {
    let allmerchantData = {
        merchantid: Number(id),
    }
    fetch(APIS.DELETE_MERCHANT, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const deleteCity = (id, callBack) => {
    let allmerchantData = {
        locationid: Number(id),
    }
    fetch(APIS.DELETE_CITY, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const deleteCategory = (id, callBack) => {
    let allmerchantData = {
        categoryid: Number(id),
    }
    fetch(APIS.DELETE_CATEGORY, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const deleteSubCategory = (id, callBack) => {
    let allmerchantData = {
        subcategoryid: Number(id),
    }
    fetch(APIS.DELETE_SUBCATEGORY, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const deleteCoupon = (id, callBack) => {
    let allmerchantData = {
        couponid: Number(id),
    }
    fetch(APIS.DELETE_COUPON, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const deleteOutlet = (id, callBack) => {
    let allmerchantData = {
        outletid: Number(id),
    }
    fetch(APIS.DELETE_MERCHANT_OUTLET, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const deleteMappedCoupon = (data, callBack) => {
    let allmerchantData = {
        outletid: Number(data.outletId),
        couponid: Number(data.couponId)
    }
    fetch(APIS.DELETE_COUPON_ON_OUTLET, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const deleteMerchantImage = (merchantid, callBack) => {
    let allmerchantData = {
        merchantid: Number(merchantid)
        // couponid: Number(data.couponId)
    }
    fetch(APIS.DELETE_MERCHANT_IMAGE, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const getUsedCouponData = (couponId, callBack) => {
    let allmerchantData = {
        couponid: Number(couponId)
        // couponid: Number()
    }
    fetch(APIS.GET_USERS_USED_COUPONS, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.json())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}

export const saveCouponCodeApi = (couponData, callBack) => {
    let allmerchantData = {
        couponid: Number(couponData.couponId),
        couponCode: couponData.couponCode
    }
    fetch(APIS.SAVE_COUPON_CODE, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(allmerchantData)
    })
        .then(res => res.text())
        .then(text => {
            callBack(text)
        }).catch(error => console.error('Error:', error));
}
