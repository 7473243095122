import React from 'react';
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import DotLoader from '../../Loader/layerLoader.component'
import { STRING, ROLE, STATE_COLOR } from '../../../../utils/Constant'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const PhocketCustomerDetails = ({ userDetailHandler, userViewHandler, dataFilter,
    forwordpage, backpage, allUserDetail, admin, refreshpage, loadarState,
    getPageValue, pageIndexToShow, getFilterValue, FilteredData,
    searchByAppNo, searchByemail, searchBymobile, searchByDate, statusHandler, followUpStatus, allStatus,
    searchByDateShow }) => (
    <div className="container-fluid">
        {/* <div className="row">
                <div className="col-xs-12">
                    <h3 className="text-center blue-text">Important Leads</h3>
                </div>
            </div> */}
        <div className="row">
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Application No.</label>
                <input type="number" className="form-control" value={searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Email ID</label>
                <input type="email" className="form-control" value={searchByemail} onChange={e => dataFilter(e, "email")} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" /> Mobile No.</label>
                <input type="number" value={searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Date</label>
                {/* <input type="number" className="form-control" value={searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} /> */}
                <DatePicker
                    value={searchByDate}
                    selected={searchByDateShow}
                    onChange={e => dataFilter(e, "date")}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                />
            </div>

            {/* {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                <div className="col-sm-2 col-xs-12">
                    <label className="font-12px"><span className="" /> Select Status</label>

                    <select className="form-control " value={followUpStatus} onChange={(e) => statusHandler(e)}>
                        <optgroup className="display-none">
                            <option value="">Select Status</option>
                        </optgroup>
                        <optgroup label="Status">
                            {allStatus != null && allStatus != undefined && allStatus != '' ?
                                allStatus.map((status, i) => {
                                    return (
                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                    );
                                }) : ""}
                        </optgroup>
                    </select>
                </div>
                : null} */}
        </div>
        <div className="row">
            <div className="col-xs-12 text-right">
                <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh fa-spin" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button>
                {/* <p>1-100</p> */}
            </div>
        </div>

        <div className="row text-center">
            <div className="col-xs-12 overflow-x-auto no-padding">
                {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                    <ReactTable
                        // getTrProps={(state, rowInfo, column) => {
                        //     return {
                        //         style: {
                        //             background: rowInfo != undefined ?
                        //                 rowInfo.row.loginDevice != '' && rowInfo.row.loginDevice != null ?
                        //                     rowInfo.row.state == 'Rajasthan' ? STATE_COLOR.RAJASTHAN
                        //                         : rowInfo.row.state == 'West Bengal' ? STATE_COLOR.WEST_BENGAL
                        //                             : rowInfo.row.state == 'Maharashtra' ? STATE_COLOR.MAHARASHTRA
                        //                                 : ""
                        //                     : STATE_COLOR.LOAN_ADDA
                        //                 : "",
                        //         }
                        //     };
                        // }}
                        data={allUserDetail}
                        columns={[{
                            Header: 'View',
                            accessor: 'loanId',
                            filterable: false,
                            className: 'width-30px',
                            headerClassName: 'width-30px content-none',
                            Cell: row => (
                                <div>
                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?
                                        <button onClick={e => userViewHandler(row.original)}><span className="fa fa-eye" /></button>
                                        : "" : ""}
                                </div>
                            )
                        }, {
                            Header: 'Important',
                            accessor: 'loanId',
                            filterable: false,
                            className: 'width-30px',
                            headerClassName: 'width-30px content-none',
                            Cell: row => (
                                <div>
                                    <span className="fa fa-star orange-text" />
                                </div>
                            )
                        }, {
                            Header: 'Details',
                            accessor: 'userId',
                            filterable: false,
                            headerClassName: 'content-none',
                            Cell: row => (
                                <div>
                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                        <button onClick={e => userDetailHandler(row.original)}><span className="fa fa-info" /></button>
                                        : "" : ""}
                                </div>
                            )
                        }, {
                            Header: 'Application Id',
                            accessor: 'applicationId'
                        }, {
                            Header: 'State',
                            accessor: 'state'
                        }, {
                            Header: 'Name',
                            accessor: 'userName'
                        }, {
                            Header: 'Mobile No.',
                            accessor: 'mobileNumber'
                        }, {
                            Header: 'Apply Date',
                            accessor: 'loanApplyDate'
                        }, {
                            Header: 'Amount',
                            accessor: 'loanAmount'
                        }, {
                            Header: 'Installment',
                            accessor: 'loanEmi'
                        }
                            // , {
                            //     Header: 'Tenure',
                            //     accessor: 'loanDuration',
                            //     className: 'width-30px',
                            //     headerClassName: 'width-30px content-none',
                            // }
                            // , {
                            //     Header: 'Reason',
                            //     accessor: 'loanReason'
                            // }
                            ,
                        {
                            Header: 'Status',
                            accessor: 'applicationStatus'
                        },
                        {
                            Header: 'Device',
                            accessor: 'loginDevice'
                        },

                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight phocketAdmin_table"
                        filterable={true}
                        defaultFilterMethod={filterCaseInsensitive}
                        onFilteredChange={(filtered) => getFilterValue(filtered)}
                        defaultFiltered={FilteredData}
                        onPageChange={(pageIndex) => getPageValue(pageIndex)}
                        page={pageIndexToShow}
                        previousText={<span className="fa fa-chevron-left" />}
                        nextText={<span className="fa fa-chevron-right" />}
                    /> : ""}
            </div>
            {loadarState == true ?
                <DotLoader />
                : ""}
        </div>
    </div>
)

export default PhocketCustomerDetails;
