import React from 'react'
import { APIS } from '../../../../utils/api-factory';
import Loader from '../../../presentationals/Loader/Loader.component'
class Flipkart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            balance: '',
            loaderstate: true
        }
    }
    componentDidMount() {
        fetch(APIS.GET_FLIPKART_BALANCE)
            .then(res => res.text())
            .then(res => {
                this.setState({ balance: res, loaderstate: false })
            })
    }
    render() {
        return (
            <div>
                {this.state.loaderstate === false ?
                    <h1 className='text-center' style={{marginTop:'50px'}}>Balance in Flipkart : &nbsp;<b style={{color:'green'}}>{this.state.balance}</b></h1>

                    : ""}
                {this.state.loaderstate ?
                    <Loader />
                    : ""}
            </div>
        )
    }
}
export default Flipkart