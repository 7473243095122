import React from 'react';



const Scoring = ({ openConfirmation, scoreChange, scoreHandler, scoreDetail, dbrValue ,flagData}) => (
    <div className="container-fluid">
        {/* <br /> */}
        {/* <div className="row">
            <div className="col-sm-4 col-xs-12">
                <div className="">
                    <p className="word-break"><b>Profile comment&nbsp;</b>
                        <textarea type="text" className="form-control credit-input" ></textarea>
                    </p>
                </div>


            </div>
            <div className="col-sm-4 col-xs-12">
                <div className="">
                    <p className="word-break"><b>Status Comment&nbsp;</b>
                        <textarea type="text" className="form-control credit-input" ></textarea>
                    </p>
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 ">
                <button className="profile-save-btn">Save</button>
            </div>
        </div>
        <br/> */}

        <div className="row">
            <div className=" col-xs-12">
                <div className="">
                    <h5 ><b>Cibil Scoring</b></h5>
                 <div style={{borderBottom:'1.5px solid #7D6FD9',marginBottom:'10px'}}></div>
                    <div className="row">
                        <div className="col-sm-12 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>No. of overdue accounts/Total accounts&nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilOverdueAccount} </h5>
                        </div>

                    </div>
                    <div className='row'>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>CIBIl score&nbsp;</b>
                            <br />
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilScore} </h5>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Income&nbsp;</b>
                            <br />
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilincome} </h5>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Obligations&nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilObligations} </h5>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Surplus &nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilSurplus} </h5>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>CC/STL &nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.shortTermCreditCard} </h5>
                        </div>
                        <div className='col-sm-4 col-xs-12 form-group'>
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>DBR &nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{dbrValue} </h5>

                        </div>
                    </div>
                    {/* <div className="col-xs-12 form-group">
                        <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>MAX LIMIT &nbsp;</b>
                        <input type="text" className="form-control credit-input" ></h5>
                    </div> */}
                    <div className="row">

                        <div className="col-sm-12 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Remarks&nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilRemarks} </h5>
                        </div>

                    </div>
                  
                </div>
            </div>
        </div>
        {/* <div className="col-sm-4 col-xs-12">
                <div className="profile-details-box">
                    <div className="crm-user-head">CREDIT</div>
                        <div className="word-break"><b>Stay in CA more than 1 years&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div>
                        <div className="word-break"><b>Current Address=Aadhar Address&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div>
                        <div className="word-break"><b>Current Address=Permanent Address&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div>
                        <div className="word-break"><b>Current Address=owned&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div>
                        <div className="word-break"><b>Current Company Work exp > 1 year&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div>
                        <div className="word-break"><b>Company Category&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="profile-save-btn text-center">Save</button>
                    </div>
                </div>
            </div>
            <div className="col-sm-4 col-xs-12">
                <div className="profile-details-box">
                    <div className="crm-user-head">Expenses</div>
                        <div className="word-break"><b>Salary on time&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div><div className="">
                        <div className="word-break"><b>EMI / Income Ratio&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div><div className="">
                        <div className="word-break"><b>Presence of existing loan&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div><div className="">
                        <div className="word-break"><b>No emi defaults evident&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div><div className="">
                        <div className="word-break"><b>Ability to save/Opening and closing balance&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div>
                        <div className="word-break"><b>Presence of high value transaction&nbsp;</b>
                            <input type="text" className="form-control credit-input" ></h5>
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="profile-save-btn text-center">Save</button>
                    </div>
                </div>
            </div> */}
    </div>
)

export default Scoring;
