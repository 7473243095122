import React from 'react'
import DatePicker from 'react-datepicker';

const ReportLogs = ({ url, datehandler, startDate, endDate, downloadFile, End, Start, selectHandler, typeData }) => (
    <div className="container-fluid">
        <h3 className="text-center blue-text">Generate Buddyloan Report </h3>
        
        <div className='row' style={{ marginTop: '45px' }}>
            <div className='col-sm-4 col-xs-12'>
                <label className="font-12px">Select Type</label>

                <select className='form-control' value={typeData} onChange={(e) => selectHandler(e)}>
                    <option>Select Type</option>
                    <option value='complete'>Complete</option>
                    <option value='applied'>Applied</option>
                </select>
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label className="font-12px">Date From</label>
                <DatePicker
                    value={startDate}
                    selected={Start}
                    onChange={(e) => datehandler(e, 'startDate')}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                />
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label className="font-12px">Date To</label>
                <DatePicker
                    value={endDate}
                    selected={End}
                    onChange={(e) => datehandler(e, 'endDate')}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                />
            </div>
        </div>
        <div className='row text-center' style={{ marginTop: '45px' }}>
            <div className='col-sm-12 col-xs-12'>
                <a > <button className='btn btn-primary' onClick={downloadFile}>Download</button></a>
            </div>
        </div>
    </div >
)
export default ReportLogs;