import { APIS } from "../../../../utils/api-factory"

export const getDataForReconciliation = (startDate, endDate, callBack) => {
  fetch(APIS.DATA_FOR_RECONCILAIATION + startDate + '&endDate=' + endDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const uploadRazorpayReport = (imageFile, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    imageFormData.append('file', imageFile)



    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_RAZORPAY_REPORT, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const getCountsApi = (imageFile, paymentList, callBack) => {
  console.log(paymentList)
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    imageFormData.append('file', imageFile)
    imageFormData.append('paymentsList', JSON.stringify(paymentList))


    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.GET_COUNT_RECONCILAIATION, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const getTypeDataApi = (imageFile, paymentList, type, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    imageFormData.append('file', imageFile)

    imageFormData.append('type', type)
    imageFormData.append('paymentsList', JSON.stringify(paymentList))



    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.GET_DATA_BY_TYPE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}


export const getEnachDataLeads = (pageNumber, type, date, callBack) => {
  fetch(APIS.ENACH_DATA_LEADS + pageNumber + '&type=' + type + '&date=' + date)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}