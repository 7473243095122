import React from 'react'
import { TYPE } from '../../../../utils/Constant'

const CRMChangePass = ({ changePass, ChangePasswordHandler, viewPassHandler,
    chngPasswordValidationMsg }) => (
        <div className="container-fluid Phocket-crm-change-pass">
            <div className="row form-group">
                <h3 className="text-center blue-text">Change Password</h3>
            </div>
            <div className="row form-group">
                <div className="col-xs-9">
                    <h4>Enter Old Password</h4>
                    <input id="oldPassword" type="password" className="form-control" onChange={(e) => changePass(e, TYPE.OLD_PASS)} />
                </div>
                <div className="col-xs-3">
                    <h4><br /></h4>
                    <a onClick={(e) => viewPassHandler(e, 'viewPrevPass')}><span className="fa fa-eye"></span></a>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-xs-9">
                    <h4>Enter New Password</h4>
                    <input type="password" id="newPassword" className="form-control" onChange={(e) => changePass(e, TYPE.NEW_PASS)} />
                </div>
                <div className="col-xs-3">
                    <h4><br /></h4>
                    <a onClick={(e) => viewPassHandler(e, 'viewNewPass')}><span className="fa fa-eye"></span></a>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-9">
                    <h4>Confirm New Password</h4>
                    <input type="password" id="confirmPassword" className="form-control" onChange={(e) => changePass(e, TYPE.CONFIRM_PASS)} />
                </div>
                <div className="col-xs-3">
                    <h4><br /></h4>
                    <a onClick={(e) => viewPassHandler(e, 'viewConfirmPass')}><span className="fa fa-eye"></span></a>
                </div>
            </div>
            <div className="row">
                <div className="font-12px margin-left-25px height-25px validation-color">
                    {chngPasswordValidationMsg.field == "newPassword" ? chngPasswordValidationMsg.msg : ""}
                </div>
            </div>
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="submitDetail" onClick={e => ChangePasswordHandler()}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
        </div>
    )

export default CRMChangePass;