import React from 'react'

const ConfirmationPopup = ({ cancelLoanConfirm, confirmationText, macthedPandata }) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">{confirmationText}</h4>
                </div>
            </div>
            <div className="row from-group">
                <div className="col-xs-12 text-center">
                    {/* {console.log(macthedPandata.oldProfile.map(data,i)=)} */}

                    Matched Email: &nbsp; <b style={{ color: 'black' }}> {macthedPandata && macthedPandata.oldProfile ? macthedPandata.oldProfile.emailId : ""}</b>

                </div>
            </div>
            {/* <div className="row from-group text-center">
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('yes')}>YES</button>
                </div>
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('no')}>NO</button>
                </div>
            </div> */}
             <div className="row from-group text-center" style={{marginTop:'20px'}}>
                    <button className="btn btn-success" onClick={(e) => cancelLoanConfirm('yes')}>YES</button>&nbsp;&nbsp;
                    <button className="btn btn-danger" onClick={(e) => cancelLoanConfirm('no')}>NO</button>

            </div>

        </div>
    </div>
)

export default ConfirmationPopup;