import React from 'react'

// import GoogleMapReact from 'google-map-react'
// import useSupercluster from 'use-supercluster'
import Moment from 'react-moment'
import 'moment-timezone'
import Loader from '../../../presentationals/Loader/DotLoader.component'
// import MarkerImage from '../../../../images/marker.png'
import Nodata from '../../../../images/nodata.png'


// const Marker = ({ children }) => children

export default function App({ loadarState, finalData, FilteredData, location, tableMaxPagination, tableMinPagination, tablePagination }) {
  // const mapRef = useRef()
  // const [bounds, setBounds] = useState(null)
  // const [zoom, setZoom] = useState(10)


  // console.log("finalData",finalData)
  // const crimes = finalData  ? finalData.slice(0, 2000) : []
  // const points = crimes.map(crime => ({
  //   type: 'Feature',
  //   properties: { cluster: false, crimeId: crime.id, category: crime.category },
  //   geometry: {
  //     type: 'Point',
  //     coordinates: [
  //       parseFloat(crime.location.longitude),
  //       parseFloat(crime.location.latitude)
  //     ]
  //   }
  // }))

  // const { clusters, supercluster } = useSupercluster({
  //   points,
  //   bounds,
  //   zoom,
  //   options: { radius: 75, maxZoom: 20 }
  // })

  return (
    <div className='container-fluid details-new'>
      {/* {console.log(FilteredData)} */}
      {/* <h3 className="heading-analysis"  >Location Analysis</h3> */}
      {location !== null && location!=='' && location.length>0   ?
        <div className=' phocket-table-new'>
          <table className=''>
            <thead>
              <tr>
                <th >Date{FilteredData}</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {location.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                return (
                  <tr key={i}>
                    {/* <td><Moment tz="Asia/Kolkata" format="DD-MM-YYYY HH:mm:ss" parse="DD/MM/YYYY HH:mm:ss">{data.date != null ? data.date.split(" ")[0] : null}</Moment></td> */}
                    <td>
                      <Moment
                        tz='Asia/Kolkata'
                        format='DD-MM-YYYY HH:mm:ss'
                        parse='DD/MM/YYYY HH:mm:ss'
                      >
                        {' '}
                        {data.date}{' '}
                      </Moment>
                    </td>
                    <td>
                      {data.location != null && data.location != '' && data.location != undefined ? data.location.includes('Address[addressLines=[0:"')
                        ? data.location
                          .split('Address[addressLines=[0:"')[1]
                          .split('"]')[0]
                        : data.location : ""}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="margin-bottom-6perc text-center">
            {/* <div className="col-sm-4 col-xs-12"></div> */}
            <div className=" col-xs-12  tbl-count" ><button type="button" className="pagination-btn blue-text " onClick={e => tablePagination(location, 'back')}>Previous</button>{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + location.length}<button type="button" className="pagination-btn tbl-count blue-text " onClick={e => tablePagination(location, 'forward')}>Next</button></div>
            {/* <div className="col-sm-4 col-xs-12"></div> */}
          </div>
        </div>
        : loadarState === false?<div className='text-center'><img width="380px" src={Nodata}/></div>:""}

      {/* <div style={{ height: '100vh', width: '80%',marginLeft:'auto',marginRight:'auto',marginTop:'20px' }}> */}
      {/* <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
          defaultCenter={{ lat: 33.7782, lng: 78.9629 }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map
          }}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom)
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat
            ])
          }}
        >
          {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className='cluster-marker'
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      )
                      mapRef.current.setZoom(expansionZoom)
                      mapRef.current.panTo({ lat: latitude, lng: longitude })
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              )
            }

            return (
              <Marker
                key={`crime-${cluster.properties.crimeId}`}
                lat={latitude}
                lng={longitude}
              >
                <button className='crime-marker'>
                  <img src={MarkerImage} alt="no data" />
                </button>
              </Marker>
            )
          })}
        </GoogleMapReact> */}
      {/* </div> */}
    </div>
  )
}
