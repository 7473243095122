import React from "react";
import ReactDatePicker from "react-datepicker";
import Logo from '../../../images/rewampimages/Vector.png'
const Installment = ({ saveInstallHandler, installmentDetail, editInstallHandler, installDateState,close }) => (
    <>
        <div className="background-blur details-new">

            <div className="popup-main small-popup container-fluid max-width-500px dbl-border" style={{marginTop:'60px'}}>
                <div style={{textAlign:'right',cursor:'pointer'}}>
                <i className="fa fa-times" onClick={()=>close()}></i>
                </div>
                <h4 style={{color:'#000',marginTop:'-15px'}}><img src={Logo} style={{width:'27px'}}/>&nbsp;&nbsp;Intallment Details</h4>
                <div className="row " style={{marginTop:'30px'}}>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">INSTALLMENT AMOUNT</label>
                        <input
                            type="number"
                            value={installmentDetail.installAmountState}
                            className="form-control"
                            placeholder="ENTER AMOUNT"
                            onChange={(e) => editInstallHandler(e, 'installAmount')}
                            style={{background:'#DBE9FF',border:'none',borderRadius:'6px'}}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">INSTALLMENT DATE</label>
                        <ReactDatePicker
                            value={installmentDetail.installDateState}
                            selected={installDateState}
                            onChange={(e) => editInstallHandler(e, 'installDate')}
                            className="date-picker-new form-control"
                            dateFormat="DD-MM-YYYY"
                            placeholderText="ENTER INSTALMENT DATE"
                            style={{background:'#DBE9FF',border:'none',borderRadius:'6px'}}

                        />
                    </div>
                </div>

                <div className="text-center" style={{ marginTop: '20px' }}>
                    <button className="btn btn-primary" id="transSubmitBtn" onClick={e => saveInstallHandler()}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </>
)
export default Installment