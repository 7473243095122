import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoanApply from '../../../presentationals/Admin/ApplicationLeads/LoanAddaLeads.component';
import {
    getLoanAddaLead, getistByNumber, getlistByEmail, getlistByApplication,
    pushNotification, getRoleAssignView, getUserDetailByLoanId, saveImportantLoans,
    saveLoanId, saveNachDataApi
} from '../AdminActionCreator.component';
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { APIS } from '../../../../utils/api-factory';
import { STRING, ROLE } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import MainDetailsPopup from '../CustomerPages/MainDetailPopup.container'
import localStorageUtil from '../../../../utils/localStorageUtil';
import Popup from '../../../presentationals/Popup/Popup.component';
import UsersForAssignUi from '../../../presentationals/Popup/AssignUserPopup.component'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'

let columnHide = true;
let isDeleteUser = false
let nachCheckbox = false
let markImportant = false
let commentColumn = false
let paginationNumber = 10;
// let appNo

class LoanApplyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userState: true,
            viewState: false,
            editState: false,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.SELECT_ALL,
            forwordPageState: 1,
            loadarState: false,
            viewDetailPopupState: false,
            viewPopupDetail: '',
            logsUserId: '',
            todaysApplicationNo: '',
            actionButtonState: true,
            allAdminList: '',
            searchByAppNo: '',
            searchByemail: '',
            searchBymobile: '',
            confirmationPopup: false,
            confirmationText: '',
            userIdForDelete: '',
            searchByDate: '',
            searchByDateShow: '',
            disburseLoanId: '',
            popupState: false,
            popupStatus: '',
            assignPopupState: false,
            loanIdForAssign: '',
            emailIdForAssign: '',
            commentState: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }
    }

    componentWillMount() {
        fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate)
            .then(res => res.json())
            .then(json => {
                this.setState({ todaysApplicationNo: json })
            })
        getRoleAssignView(localStorageUtil.getFromLocalStorage("AdminemailId"), (callBack) => {
            this.setState({ allAdminList: callBack })
        })
        this.getLeads()
    }

    getLeads() {
        let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
        this.setState({
            loadarState: true
        })
        this.props.getLoanAddaLead(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate,
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            });
    }

    nashHandler1(e, incomingData) {
        const { admin } = this.props;
        saveNachDataApi(e.target.checked, incomingData.userId, admin,
            (callBack) => {
                this.backHandler()
            })
    }

    render() {
        const { loanAddaLeadData, admin, allStatus, newDataBackground } = this.props;
        if (admin != null) {
            if (admin.role != undefined && (admin.role == 'member' || admin.role == '' || admin.role == null)) {
                columnHide = false;
            }
            if (admin.role != null && admin.role == 'superuser') {
                isDeleteUser = true
            }
        }
        if (admin.rolelist != undefined) {
            if (admin.rolelist.indexOf(ROLE.NACH_CHANGE) >= 0) {
                nachCheckbox = true
            }
            if (admin.rolelist.indexOf(ROLE.MARK_AS_IMPORTANT) >= 0) {
                markImportant = true
            }
            if (admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0) {
                commentColumn = true
            }
        }
        return (
            <div className="full-height">
                {this.state.userState == true ?
                    <LoanApply
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        allUserDetail={loanAddaLeadData}
                        statusHandler={this.statusHandler.bind(this)}
                        dataFilter={this.dataFilter.bind(this)}
                        editUserDetailHandler={this.editUserDetailHandler.bind(this)}
                        userViewHandler={this.userViewHandler.bind(this)}
                        admin={admin}
                        loadarState={this.state.loadarState}
                        allStatus={allStatus}
                        newDataBackground={newDataBackground}
                        todaysApplicationNo={this.state.todaysApplicationNo}
                        columnHide={columnHide}
                        isDeleteUser={isDeleteUser}
                        markAsImportant={this.markAsImportant.bind(this)}
                        showRepeateApplications={this.showRepeateApplications.bind(this)}
                        followUpStatus={this.state.followUpStatus}
                        searchByAppNo={this.state.searchByAppNo}
                        searchByemail={this.state.searchByemail}
                        searchBymobile={this.state.searchBymobile}
                        deleteUserHandler={this.deleteUserHandler.bind(this)}
                        searchByDate={this.state.searchByDate}
                        searchByDateShow={this.state.searchByDateShow}
                        disburseLoanId={this.disburseLoanId.bind(this)}
                        saveDisburseLoanId={this.saveDisburseLoanId.bind(this)}
                        checkBoxHandler={this.nashHandler1.bind(this)}
                        nachCheckbox={nachCheckbox}
                        markImportant={markImportant}
                        commentColumn={commentColumn}
                        nashHandler={this.nashHandler.bind(this)}
                        userAssignPopup={this.userAssignPopup.bind(this)}
                        userCommentHandler={this.userCommentHandler.bind(this)}
                        userCommentChange={this.userCommentChange.bind(this)}
                        paginationHandler={this.paginationHandler.bind(this)}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                    /> : ""}
                {this.state.viewState == true ?
                    <UserDetail
                        allDetail={this.state.allDetail}
                        userDetail={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                    />
                    : ""}
                {this.state.viewDetailPopupState == true ?
                    <MainDetailsPopup
                        getviewDetailPopupState={this.getviewDetailPopupState.bind(this)}
                        admin={admin}
                        allDetail={this.state.viewPopupDetail}
                    />
                    : ""}
                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.assignPopupState ?
                    <UsersForAssignUi
                        closeTaskAssignPopup={this.closeTaskAssignPopup.bind(this)}
                        taskAssignHandler={this.taskAssignHandler.bind(this)}
                        emailIdForAssign={this.state.emailIdForAssign}
                        allAdminList={this.state.allAdminList} />
                    : null}
            </div>
        )
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    paginationHandler(type) {
        if (type == 'refresh') {
            this.getLeads()
        } else if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                }, () => this.getLeads())
            }
        } else if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
            }, () => this.getLeads())
        }
    }

    userCommentChange(e) {
        this.setState({ commentState: e.target.value })
    }

    userCommentHandler(data) {
        const { admin } = this.props
        pushNotification(data, this.state.commentState, '', admin.emailId, "comment", '', '', '', '','',
            (callBack) => {
                this.setState({
                    popupState: true,
                    popupStatus: 'Comment Saved Successfully',
                    commentState: ''
                })
                this.removePopup();
            })
    }

    userAssignPopup(loanId, emailId) {
        this.setState({
            assignPopupState: true,
            loanIdForAssign: loanId,
            emailIdForAssign: emailId
        })
    }

    nashHandler(e, incomingData) {
        const { admin } = this.props;
        saveNachDataApi(e.target.checked, incomingData.userId, admin,
            (callBack) => {
                this.backHandler()
            })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    deleteUserHandler(userId) {
        this.setState({
            userIdForDelete: userId,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete this user'
        })
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            fetch(APIS.DELETE_COMPLETE_USER + this.state.userIdForDelete)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        this.setState({ confirmationPopup: false })
                        this.getLeads()
                    }
                })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    showRepeateApplications() {
        this.setState({
            loadarState: true,
            followUpStatus: 'repeate'
        }, () => this.getLeads())
    }

    markAsImportant(allDetail, isImportant) {
        const { admin } = this.props
        saveImportantLoans(allDetail, isImportant, admin.emailId,
            (callBack) => {
                this.backHandler()
            })
    }

    taskAssignHandler(e) {
        this.setState({ emailIdForAssign: e.target.value })
        fetch(APIS.ASSIGN_LOAN + this.state.loanIdForAssign + "&empId=" + e.target.value)
            .then(res => res.text())
            .then(text => {
                if (text == 'success') {
                    this.getLeads()
                }
            })
        this.setState({ assignPopupState: false })
    }

    closeTaskAssignPopup() {
        this.setState({ assignPopupState: false })
    }

    dataFilter(e, type) {
        const { admin } = this.props
        if (type == "applicationNo") {
            this.setState({ searchByAppNo: e.target.value })
            // appNo = e.target.value
            if (e.target.value.length > 4) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByApplication(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "email") {
            this.setState({ searchByemail: e.target.value })
            // let email = e.target.value
            if (e.target.value.length > 2) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByEmail(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "mobileNo") {
            this.setState({ searchBymobile: e.target.value })
            // let mobile = e.target.value
            if (e.target.value.length > 9) {
                this.setState({
                    loadarState: true
                })
                this.props.getistByNumber(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "date") {
            this.setState({
                searchByDate: _preFormatDate(e),
                searchByDateShow: e,
            }, () => this.getLeads())
            fetch(APIS.TODAYS_LOAN_COUNT + _preFormatDate(e))
                .then(res => res.json())
                .then(json => {
                    this.setState({ todaysApplicationNo: json })
                })
        }
        if (type != "date" && e.target.value == "") {
            this.getLeads()
        }
    }
    statusHandler(e) {
        this.setState({
            loadarState: true,
            followUpStatus: e.target.value
        }, () => this.getLeads())
    }

    componentDidUpdate() {
        const { loanAddaLeadData } = this.props;
        if (loanAddaLeadData.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }

    }

    backHandler() {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false,
            editState: false
        })
        if (this.state.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.searchByAppNo, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchByemail != '') {
            this.props.getlistByEmail(this.state.searchByemail, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchBymobile != '') {
            this.props.getistByNumber(this.state.searchBymobile, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else {
            this.getLeads()
        }
    }
    editUserDetailHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    editState: true,
                    userState: false,
                    viewState: false,
                    allDetail: callBack,
                    userDetail: allDetail,
                })
            })
    }
    userDetailHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    editState: false,
                    allDetail: callBack,
                    userDetail: allDetail
                })
            })
    }
    userViewHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    viewDetailPopupState: true,
                    viewPopupDetail: callBack,
                    userDetail: allDetail
                })
            })
    }
    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

    disburseLoanId(e) {
        this.setState({ disburseLoanId: e.target.value })
    }

    saveDisburseLoanId(data) {
        if (this.state.disburseLoanId != '') {
            saveLoanId(this.state.disburseLoanId, data.userId,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: "Loan Id save successfully",
                            disburseLoanId: ''
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: "Loan Id not save",
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: "Please enter loan id",
                disburseLoanId: ''
            })
            this.removePopup();
        }
    }


    componentWillUnmount() {

    }

}

const mapStateToProps = ({ allUserDetail, loanAddaLeadData }) => {
    return {
        allUserDetail: allUserDetail,
        loanAddaLeadData: loanAddaLeadData,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getLoanAddaLead

    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(LoanApplyContainer);