import React from "react";

const FinanceAnalysis = ({financialTab,openFinbitPage,finbitData ,openBankObligation,viewPage}) => (
    <>
        <div className="row"  >
            <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div>
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}>

                </div>
                </div>
            </div>

            <div className="col-sm-5" style={{ fontSize: '12px', cursor: 'not-allowed', color: '#000' }}>
                Analysis Result</div>
            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'not-allowed' }} >
                {finbitData && finbitData.decision === 'Approved' ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 8px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Approved</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Rejected</span>}
                {/* <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i> */}
            </div>
        </div>
        <div className="row"  >
            <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div>
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}>

                </div>
                </div>
            </div>

            <div className="col-sm-5" onClick={finbitData && finbitData.bankObligation && finbitData.bankObligation?()=>openBankObligation():''} style={{ fontSize: '12px', cursor: 'pointer', color: viewPage==='bankObligation'?'#2B78FF':'#000',fontWeight: viewPage==='bankObligation' ? '700' : '500' }}>
                Obligations</div>
            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} >
            {finbitData && finbitData.bankObligation && finbitData.bankObligation ?
                    <div onClick={()=>openBankObligation()} style={{ color: '#2B78FF', fontWeight: '700' }}> INR {finbitData ? Math.round(finbitData.bankObligation) : 0} &nbsp;&nbsp;&nbsp;&nbsp;  <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div> : <div className=" text-blue text-right" ><span >N/A </span>&nbsp;&nbsp; &nbsp;&nbsp; <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>}
            </div>
        </div>

        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '80px', marginTop: '-20px' }}></div>
                </div>
            </div>

            <div className="col-sm-6" style={{ fontSize: '12px', cursor: 'pointer', color: viewPage==='finbit' ? '#2B78FF' : '#000', fontWeight: viewPage==='finbit' ? '700' : '500' }} onClick={() => openFinbitPage('')}>

                Detailed View</div>

            <div className="col-sm-4 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => openFinbitPage('')}>

                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
    </>
)
export default FinanceAnalysis