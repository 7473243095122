import React, { Component } from 'react'
import AddOutletsUi from '../../../presentationals/Admin/AdminCoupons/AddCouponCode.component'
import { getOfferDetail, saveCouponCodeApi } from './AddMerchentActionCreator.Component'
import Popup from '../../../presentationals/Popup/Popup.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class AddStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            listingOfCategory: '',
            outLetAddressList: '',
            couponList: '',
            couponid: '',
            merchatIdForCoupon: '',
            merchantData: {
                couponId: '',
                couponCode: ''
            }
        }
    }

    componentWillMount() {
        this.props.getOfferDetail((callback) => {
            this.setState({ couponList: callback })
        })
    }

    render() {
        return (
            <div>
                {this.state.popupState ?
                    <Popup
                        popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)}
                    />
                    : ""}
                <AddOutletsUi
                    getDataHandler={this.getDataHandler.bind(this)}
                    postDataHandler={this.postDataHandler.bind(this)}
                    couponList={this.state.couponList}
                    merchatIdForCoupon={this.state.merchatIdForCoupon}
                    merchantData={this.state.merchantData}
                />
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    getDataHandler(e, type) {
        let merchantData = Object.assign({}, this.state.merchantData);
        if (type == 'couponsDetail') {
            merchantData.couponId = e.target.value
        } else if (type == 'merchantDetail') {
            this.setState({ merchatIdForCoupon: e.target.value })
        } else if (type == 'couponsCodes') {
            merchantData.couponCode = e.target.value
        }
        this.setState({ merchantData })
    }

    postDataHandler() {
        if (this.state.merchantData.couponId != '' && this.state.merchantData.couponCode != '') {
            saveCouponCodeApi(this.state.merchantData, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Coupon code Save Successfully',
                    })
                    this.removePopup()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup()
                }
            })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select coupon and write coupon code',
            })
            this.removePopup()
        }
    }
}

const mapStateToProps = ({ allStatus }) => {
    return {
        allStatus: allStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getOfferDetail
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStatus);
