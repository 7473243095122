import React from 'react'

const MessagePage = ({ sendHandler, messageHandler, msgBody, msgTempleteClick }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-5 col-xs-12">
                    <h4 className=" text-center blue-text"><strong>Templates</strong></h4>
                <ol>
                    {/* <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('WithNach')} /> &nbsp;&nbsp;
                        <span><strong>With Nach</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('WithoutNach')} /> &nbsp;&nbsp;
                        <span><strong>Without Nach</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('EmiWithNach')} /> &nbsp;&nbsp;
                        <span><strong>EMI With Nach</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('EmiWithoutNach')} /> &nbsp;&nbsp;
                        <span><strong>EMI Without Nach</strong></span>
                    </li> */}
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('ExecutiveContact')} /> &nbsp;&nbsp;
                        <span><strong>Executive contact</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('DocsUpload')} /> &nbsp;&nbsp;
                        <span><strong>Docs upload</strong></span>
                    </li>
                    <li>
                        <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('razorpay')} /> &nbsp;&nbsp;
                        <span><strong>Bank not live with Razorpay's e-mandate</strong></span>
                    </li>
                
                </ol>
            </div>
            <div className="col-sm-7 col-xs-12">
                <div className="col-xs-12">
                    <h4 className="text-center blue-text"><strong>Send Message</strong> {msgBody.length > 0 ? msgBody.length : ""}</h4>
                </div>
                <div className="col-xs-12 form-group">
                    <textarea readOnly className="form-control white-bg-transparent" rows="7" placeholder="Message..." value={msgBody} onChange={e => messageHandler(e)} />
                </div>
                <div className="col-xs-12 text-center">
                    <button className="profile-save-btn margin-10px" id="submitDetail" onClick={e => sendHandler()}>
                        <span className="fa fa-send-o"></span>&nbsp;&nbsp;Send
                    </button>
                </div>
            </div>
        </div>
    </div>
)

export default MessagePage;