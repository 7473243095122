import React, { useState } from "react";
import { Pagination } from "@mui/material";
import moment from "moment";

function AppliedLeadListTable({ appliedAllLeads }) {
  const [page, setPage] = useState(1);
  return (
    <>
      <div className='credit-table'>
        <table>
          <thead style={{ background: '#b9d8f9', color: '#000' }}>
            <tr className="thead-class">

              <th className="">Email Id</th>
              <th className="">Mobile Number</th>
              <th className="">Application Id</th>
              <th className="">Loan Apply Date</th>
              <th className="">Admin Status</th>
              <th className="">Loan Approve Amount</th>
              <th className="">Loan Approve Date</th>
              <th className="">User Id</th>
              <th className="">Created Date</th>
              <th className="">Disbursed Date</th>
            </tr>
          </thead>
          <tbody>
            {appliedAllLeads && appliedAllLeads.map((data, i) => {
              return (
                <tr style={{ borderBottom: "none" }}>

                  <td scope="col">
                    <span id="comment">{data.emailId}</span>
                  </td>
                  <td scope="col">
                    <span id="comment">{data.mobileNumber}</span>
                  </td>
                  <td scope="col">
                    <span id="comment">{data.applicationId}</span>
                  </td>
                  <td scope="col">
                    <span id="comment">{moment(data.loanApplyDate).format('DD-MM-YYYY')}</span>
                  </td>
                  <td scope="col">
                    <span id="comment">{data.adminStatus}</span>
                  </td>
                  <td scope="col">
                    <span id="comment"> {data.loanApproveAmount}</span>
                  </td>
                  <td scope="col">
                    <span id="comment">{data.loanApproveDate ? moment(data.loanApproveDate).format('DD-MM-YYYY') : ""}</span>
                  </td>
                  <td scope="col">
                    <span id="comment">{data.userId}</span>
                  </td>
                  <td scope="col">
                    <span id="comment">{moment(data.createdDate).format('DD-MM-YYYY')}</span>
                  </td>
                  <td scope="col">
                    <span id="comment">{data.disberseDate ? moment(data.disberseDate).format('DD-MM-YYYY') : ""}</span>
                  </td>

                </tr>
              )
            })}

          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="" style={{float:'right',marginTop:'20px'}}>
          <div className="">
            <Pagination
              style={{ alignSelf: "center" }}
              count={10}
              color="primary"
              shape="circle"
              onChange={(event, value) => {
                console.log(value);
                setPage(value);
              }}
              defaultValue={5}
              page={page}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AppliedLeadListTable;
