import React, { Component } from 'react'
import CommentsUi from '../../../presentationals/Admin/Ambassador/Comments.components'
import Popup from '../../../presentationals/Popup/Popup.component';
import { SaveAmbCommentsApi } from '../AdminActionCreator.component'
import { APIS } from '../../../../utils/api-factory'

class MessageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            comments: '',
            ambData: this.props.ambassadorAllData,
            commentDataState: ''
        }
    }

    componentWillMount() {
        fetch(APIS.GET_AMBASSADOR_COMMENTS + this.state.ambData.ambassadorId)
            .then(res => res.json())
            .then(json => {
                this.setState({ commentDataState: json })
            })
    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <CommentsUi
                    sendHandler={this.sendHandler.bind(this)}
                    commentHandler={this.commentHandler.bind(this)}
                    commentDataState={this.state.commentDataState}
                />
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    commentHandler(e) {
        this.setState({ comments: e.target.value });
    }

    sendHandler() {
        const { admin } = this.props
        if (this.state.comments != '') {
            SaveAmbCommentsApi(this.state.ambData, this.state.comments, admin,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Comment Save Successfully',
                        })
                        this.removePopup()
                        fetch(APIS.GET_AMBASSADOR_COMMENTS + this.state.ambData.ambassadorId)
                            .then(res => res.json())
                            .then(json => {
                                this.setState({ commentDataState: json })
                            })
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Something went wrong, please try again!',
                        })
                        this.removePopup()
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please enter comments',
            })
            this.removePopup()
        }
    }

}

export default MessageContainer;