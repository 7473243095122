import React from 'react'

const ProductConfigUi = ({ changeHandler, submit, productConfig, validationMsg, editHadler }) => (
    <div className='container'>

        <h3 className='text-center' style={{ marginTop: '20px', marginBottom: '20px' }}><b>E-Nach Configuration</b></h3>
        <div className='row'>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Api Key</label>
                <input type="text" placeholder='Api Key' value={productConfig.apiKey} className="form-control" onChange={e => changeHandler(e, 'apiKey')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'apiKey' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Api Secret</label>
                <input type="text" placeholder='Api Secret' value={productConfig.apiSecret} className="form-control" onChange={e => changeHandler(e, 'apiSecret')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'apiSecret' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
            </div>
        </div>


        <div className='row text-center'>
            <button className='profile-save-btn' onClick={() => submit()}>Save</button>
        </div>


    </div>
)

export default ProductConfigUi