import React from "react";
import { FORM_STATUS, TYPE } from "../../../../../utils/Constant";
import { ResidenceFormValidation } from "../../../../../utils/validation";
import { fetchAddressDetail, zipAndBranchDetail } from "../../AdminActionCreator.component";
import PopUp from "../../../../presentationals/Popup/Popup.component";
import { APIS } from "../../../../../utils/api-factory";

class AddressEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetail: {
                CurrentResidenceTypeValue: '',
                PermanentResidenceTypeValue: '',
                CurrentPinCodeState: '',
                CurrentDurationState: '',
                CurrentCityState: '',
                CurrentStateState: '',
                CurrentAddressState: '',
                PermanentPinCodeState: '',
                PermanentDurationState: '',
                PermanentCityState: '',
                PermanentStateState: '',
                PermanentAddressState: '',

            },
            AddressCheckValue: '',
            popupState: false,
            popupStatus: '',
            residenceValidationMsg: {}

        }
    }
 
    checkPincode() {
        fetch(APIS.GET_LOAN_PAGE_MSG)
          .then(res => res.json())
          .then(json => {
            this.setState({
              loanMsges: json
            })
          })
      }
    componentDidMount() {
            console.log(this.props,'this.propsthis.props')
        
        this.checkPincode()

        const { userdata } = this.props
        let userDetail = Object.assign({}, this.state.userDetail);
        userDetail.CurrentAddressState = userdata.addressDetailsPOList.currentAddress
        userDetail.CurrentPinCodeState = userdata.addressDetailsPOList.currentPincode.toString()
        userDetail.CurrentStateState = userdata.addressDetailsPOList.currentState
        userDetail.CurrentCityState = userdata.addressDetailsPOList.currentCity
        userDetail.CurrentResidenceTypeValue = userdata.addressDetailsPOList.currentResidenceType
        userDetail.CurrentDurationState = userdata.addressDetailsPOList.currentResidenceValue
        userDetail.PermanentAddressState = userdata.addressDetailsPOList.permanentAddress
        userDetail.PermanentPinCodeState = userdata.addressDetailsPOList.permanentPincode.toString()
        userDetail.PermanentCityState = userdata.addressDetailsPOList.permanentCity
        userDetail.PermanentStateState = userdata.addressDetailsPOList.permanentState
        userDetail.PermanentResidenceTypeValue = userdata.addressDetailsPOList.permanentResidenceType
        userDetail.PermanentDurationState = userdata.addressDetailsPOList.permanentResidenceValue
        userDetail.userId = userdata.userId
        userDetail.loanId = userdata.loanId
        userDetail.formStatus = FORM_STATUS.UPDATE

        this.setState({ userDetail: userDetail })

    }
    closePopup() {
        this.setState({ popupState: false })
    }
    removePopup() {
        setTimeout(
            function () {
                this.setState({ popupState: false })
            }.bind(this),
            5000
        )
    }
    AddressCheckHendler(e) {
        let userDetail = Object.assign({}, this.state.userDetail);
        let AddressCheckValue = e.target.checked
        let durationID = document.getElementById("phocketPermanentDuration");
        if (AddressCheckValue) {
            userDetail.PermanentAddressState = this.state.userDetail.CurrentAddressState;
            userDetail.PermanentPinCodeState = this.state.userDetail.CurrentPinCodeState;
            userDetail.PermanentStateState = this.state.userDetail.CurrentStateState;
            userDetail.PermanentCityState = this.state.userDetail.CurrentCityState;
            userDetail.PermanentDurationState = this.state.userDetail.CurrentDurationState;
            userDetail.PermanentResidenceTypeValue = this.state.userDetail.CurrentResidenceTypeValue
            this.setState({
                PermanentResidenceTypeValue: this.state.CurrentResidenceTypeValue,
                AddressCheckValue: true,
                userDetail
            })

            if (durationID != null) {
                document.getElementById("phocketPermanentDuration").value = this.state.userDetail.CurrentDurationState;
            }
        } else {

            userDetail.PermanentAddressState = ""
            userDetail.PermanentPinCodeState = ""
            userDetail.PermanentStateState = ""
            userDetail.PermanentCityState = ""
            userDetail.PermanentDurationState = ""
            userDetail.PermanentResidenceTypeValue = ""

            this.setState({
                PermanentResidenceTypeValue: "",
                AddressCheckValue: false,
                userDetail
            })
        }
    }
    onSubmitHandler(e) {
        const { admin } = this.props
        let ResidanceValidation = ResidenceFormValidation(this.state.userDetail)
        if (ResidanceValidation.status) {
            this.setState({
                residenceValidationMsg: ResidanceValidation
            })
            fetchAddressDetail(
                this.state.userDetail,
                this.state.userDetail,
                admin,
                callBack => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Residence Details Changed Successfully'
                    }, () => this.props.userDetailHandler(this.state.userDetail))
                    this.removePopup()
                }
            )
        } else {
            this.setState({
                residenceValidationMsg: ResidanceValidation
            })
        }
    }
    onChangeHandler(e, type) {
        const { admin } = this.props
        let userDetail = Object.assign({}, this.state.userDetail);

        if (type == TYPE.CURRENT_ADDRESS) {
            userDetail.CurrentAddressState = e.target.value
        } else if (type == TYPE.CURRENT_PIN_CODE) {
            if (admin.role == 'superuser') {
                let pincode = e.target.value
                if (pincode.length == 6) {
                    fetch(APIS.GET_STATE_CITY + pincode)
                        .then(res => res.json())
                        .then(json => {
                            userDetail.CurrentStateState = json.state
                            userDetail.CurrentCityState = json.district
                            document.getElementById('userCurrentState').value = json.state
                            document.getElementById('userCurrentCity').value = json.district
                        })
                } else {
                    userDetail.CurrentStateState = ''
                    userDetail.CurrentCityState = ''
                    document.getElementById('userCurrentState').value = ''
                    document.getElementById('userCurrentCity').value = ''
                }
                userDetail.CurrentPinCodeState = pincode
            } else {
                let pinCodeCurrent = e.target.value
                if (pinCodeCurrent.length == 6) {
                    let json = this.state.loanMsges.pincodes.filter(
                        pindata => pindata.pincode == pinCodeCurrent
                    )
                    if (json.length == 0) {
                        userDetail.CurrentPinCodeState = ''
                        this.setState({
                            popupState: true,
                            popupStatus: 'Not serviceable at your pincode'
                        })

                        this.removePopup()
                    } else if (this.state.loanMsges.pincodes != undefined) {
                        userDetail.CurrentStateState = json[0].state
                        userDetail.CurrentCityState = json[0].district
                        document.getElementById('userCurrentState').value = json[0].state
                        document.getElementById('userCurrentCity').value = json[0].district
                    }
                } else {
                    userDetail.currentPincode = ''
                    userDetail.CurrentStateState = ''
                    userDetail.CurrentCityState = ''
                    document.getElementById('userCurrentState').value = ''
                    document.getElementById('userCurrentCity').value = ''
                }
                userDetail.CurrentPinCodeState = pinCodeCurrent
            }
        } else if (type == TYPE.CURRENT_RESIDANCE_TYPE) {
            userDetail.CurrentResidenceTypeValue = e.target.value
        } else if (type == TYPE.CURRENT_DURATION) {
            userDetail.CurrentDurationState = e.target.value
        } else if (type == TYPE.CURRENT_STATE) {
            userDetail.CurrentStateState = e.target.value
        } else if (type == TYPE.CURRENT_CITY) {
            userDetail.CurrentCityState = e.target.value
        } else if (type == TYPE.PERMANENT_ADDRESS) {
            userDetail.PermanentAddressState = e.target.value
        } else if (type == TYPE.PERMANENT_PIN_CODE) {
            userDetail.PermanentPinCodeState = e.target.value
            zipAndBranchDetail(e.target.value, 'zip', res => {
                if (res.status === 'success') {
                    userDetail.PermanentCityState = res.destrict
                    userDetail.PermanentStateState = res.state
                    this.setState({ userDetail })
                }
            })
        } else if (type == TYPE.PERMANENT_RESIDANCE_TYPE) {
            userDetail.PermanentResidenceTypeValue = e.target.value
        } else if (type == TYPE.PERMANENT_DURATION) {
            userDetail.PermanentDurationState = e.target.value
        } else if (type == TYPE.PERMANENT_STATE) {
            userDetail.PermanentStateState = e.target.value
        } else if (type == TYPE.PERMANENT_CITY) {
            userDetail.PermanentCityState = e.target.value
        }
        this.setState({ userDetail: userDetail })
    }
    render() {
        return (
            <>
                {this.state.popupState == true ? (
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
                <div className="details-new background-blur" style={{ background: 'rgb(5 5 5 / 20%)', color: '#000' }}>
                    <div className="edit-box ">
                        <div className="text-right" style={{ color: 'red' }}><i onClick={() => this.props.editHandler('')} style={{ fontSize: '20px', cursor: 'pointer' }} className="fa fa-times"></i></div>

                        <h4><b>Edit Residence  Details</b></h4>

                        <div className="row">
                            <h5 style={{ marginLeft: '10px' }}><b>Current Address</b></h5>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <label>Address</label>
                                <input type="text" className="form-control" value={this.state.userDetail.CurrentAddressState} onChange={(e) => this.onChangeHandler(e, TYPE.CURRENT_ADDRESS)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.residenceValidationMsg.field == "CurrentAddress" ? this.state.residenceValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Pincode</label>
                                <input type="text" className="form-control" value={this.state.userDetail.CurrentPinCodeState} onChange={(e) => this.onChangeHandler(e, TYPE.CURRENT_PIN_CODE)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.residenceValidationMsg.field == "CurrentPin" ? this.state.residenceValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>State</label>
                                <input readOnly type="text" className="form-control" id="userCurrentState" value={this.state.userDetail.CurrentStateState} onChange={(e) => this.onChangeHandler(e, TYPE.CURRENT_STATE)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <label>City</label>
                                <input readOnly type="text" className="form-control" id="userCurrentCity" value={this.state.userDetail.CurrentCityState} onChange={(e) => this.onChangeHandler(e, TYPE.CURRENT_CITY)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <h5 style={{ marginLeft: '10px' }}><b>Permanent Address</b></h5>

                            <div className="col-sm-6 col-xs-12 ">

                            </div>
                            <div className="col-sm-6 col-xs-12 check-box-div " style={{ textAlign: 'end' }}>
                                {/* &nbsp;&nbsp; */}
                                <div className="checkbox-label" style={{ float: 'right' }}>
                                    <input className="phocket-checkbox" type="checkbox" id="phocketSameResidentialadmin" onChange={(e) => this.AddressCheckHendler(e)} />
                                    <label htmlFor="phocketSameResidentialadmin"><span></span></label>
                                </div>
                                <label htmlFor="phocketSameResidentialadmin" className="">&nbsp;Same as current Address</label>&nbsp;&nbsp;
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <label>Address</label>
                                <input type="text" className="form-control" value={this.state.userDetail.PermanentAddressState} onChange={(e) => this.onChangeHandler(e, TYPE.PERMANENT_ADDRESS)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.residenceValidationMsg.field == "PermanentAddress" ? this.state.residenceValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Pincode</label>
                                <input type="text" className="form-control" value={this.state.userDetail.PermanentPinCodeState} onChange={(e) => this.onChangeHandler(e, TYPE.PERMANENT_PIN_CODE)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.residenceValidationMsg.field == "PermanentPin" ? this.state.residenceValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>State</label>
                                <input readOnly type="text" className="form-control" id="userPermanentState" value={this.state.userDetail.PermanentStateState} onChange={(e) => this.onChangeHandler(e, TYPE.PERMANENT_STATE)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <label>City</label>
                                <input readOnly type="text" className="form-control" id="userPermanentCity" value={this.state.userDetail.PermanentCityState} onChange={(e) => this.onChangeHandler(e, TYPE.PERMANENT_CITY)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                </div>
                            </div>

                        </div>
                        <div className="row ">
                            <div className="col-xs-12">
                                <button className="btn btn-primary" id="" onClick={(e) => this.onSubmitHandler(e)}>
                                    Save Residence Details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AddressEdit