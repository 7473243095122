import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

const ExecutiveContact = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>Hope this mail finds you in best of health and spirits.</div>
        <br />
        <div>Our executive tried calling you, Kindly revert for further processing of your loan application.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default ExecutiveContact