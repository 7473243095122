import React, { Component } from 'react'
import ImportantCompanyRuleUi from '../../../presentationals/Admin/Settings/ImportantCompany.component'

class ImportantCompanyRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {

        return (
            <div className="">
                <ImportantCompanyRuleUi
                    
                />
            </div>
        )
    }

}

export default ImportantCompanyRule;