import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const MsgAnalysisNew = ({ loadarState, tablePaginationNew, tableMaxPagination2, tableMinPagination2, tablePagination, tableMaxPagination, tableMinPagination, phocketTablePagination, msgData, bankNamesHandler, selectedBankName, msgDataTypeHandler,
    msgDataType, getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid transaction-msg">
        {/* <h3 className="heading-analysis">Transactions Messages Analysis</h3> */}

        {msgData != '' && msgData != null && msgData.error == undefined ?
            <div>
                {/* {console.log(msgData)} */}
                <div className="row text-center">
                    {msgData != null && msgData != '' ? Object.keys(msgData).map((type, i) => {
                        return (
                            <div key={i} className="isEmpty list-group list-group-horizontal message-header">
                                {type && Array.isArray([type]) ?
                                    <button className="list-group-item message-analysis-btn" id={"message" + type} onClick={(e) => msgDataTypeHandler(e, type)}>{type.replace('_', ' ')}</button>
                                    : null}

                            </div>
                        )
                    })
                        : null}
                </div>
                {msgDataType == 'Bank' ?
                    <div className="row">
                        <h3 className="underline text-center">Bank</h3>
                        <div className="col-xs-12 col-sm-1"></div>
                        <div className="col-xs-12 col-sm-2">
                            <ul className="admin-msg-bank-list">
                                {msgDataType != '' && msgData != '' ? Object.keys(msgData[msgDataType]).map((bankNames, i) => {

                                    return (
                                        <React.Fragment>

                                            <li key={i} id={"bank" + bankNames} onClick={(e) => bankNamesHandler(e, bankNames)}>
                                                {bankNames.replace('_', ' ')}
                                            </li>
                                        </React.Fragment>
                                    )
                                })
                                    : null}
                            </ul>
                        </div>
                        <div className="col-xs-12 col-sm-9">
                            <div className='credit-table'>
                                <table className='text-center  '>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Acc. No</th>
                                            <th>Acc. Type</th>
                                            <th>Amt 1</th>
                                            <th>Amt 2</th>
                                            <th>Source</th>
                                            <th>Message</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {selectedBankName != '' && msgDataType != null && msgDataType != '' && msgDataType.length > 0 && msgData[msgDataType][selectedBankName] != null && msgData[msgDataType][selectedBankName].length > 0 ? Object.keys(msgData[msgDataType][selectedBankName]).slice(tableMinPagination, tableMaxPagination).map((bankNames, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{msgData[msgDataType][selectedBankName][bankNames].MessageTimestamp}</td>
                                                    <td>{msgData[msgDataType][selectedBankName][bankNames].AccountNo}</td>
                                                    <td>{msgData[msgDataType][selectedBankName][bankNames].AccountType}</td>
                                                    <td>{msgData[msgDataType][selectedBankName][bankNames].Amt_1}</td>
                                                    <td>{msgData[msgDataType][selectedBankName][bankNames].Amt_2}</td>
                                                    <td>{msgData[msgDataType][selectedBankName][bankNames].BankName}</td>
                                                    <td>{msgData[msgDataType][selectedBankName][bankNames].Message}</td>
                                                </tr>
                                            )


                                        }) : ""}
                                    </tbody>

                                </table>
                            </div>
                            {msgData[msgDataType][selectedBankName] != undefined ?
                                <div className="margin-bottom-6perc">
                                    <div className=" col-xs-12  tbl-count text-center " ><button type="button" className="pagination-btn " onClick={e => tablePagination(msgData[msgDataType][selectedBankName], 'back')}>Previous</button>{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + msgData[msgDataType][selectedBankName].length}<button type="button" className="pagination-btn tbl-count blue-text " onClick={e => tablePagination(msgData[msgDataType][selectedBankName], 'forward')}>Next</button></div>
                                </div>
                                : ""}

                        </div>
                    </div>
                    :
                    <div className="row">


                        {msgDataType != '' ?
                            <div className='credit-table'>

                                <table className='text-center  '>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Message</th>
                                            <th>Account No.</th>
                                            <th>Account Type</th>
                                            <th>Amt 1</th>
                                            <th>Amt 2</th>
                                            <th>BankName</th>
                                            <th>Message Source</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {msgData&&msgData[msgDataType] && msgData[msgDataType].length > 0 && msgData[msgDataType].slice(tableMinPagination2, tableMaxPagination2).map((data, i) => {
                                            return (
                                                <tr>
                                                    <td>{data.MessageTimestamp}</td>
                                                    <td>{data.Message}</td>
                                                    <td>{data.AccountNo}</td>
                                                    <td>{data.AccountType}</td>
                                                    <td>{data.Amt_1}</td>
                                                    <td>{data.Amt_2}</td>
                                                    <td>{data.BankName}</td>
                                                    <td>{data.MessageSource}</td>
                                                </tr>
                                            )
                                        })}




                                    </tbody>

                                </table>
                            </div>
                            : ""}
                        {msgDataType != ''  && msgData[msgDataType].length > 0 ? <div className="margin-bottom-6perc">
                            <div className=" col-xs-12  tbl-count text-center " ><button type="button" className="pagination-btn " onClick={e => tablePaginationNew(msgData[msgDataType], 'back')}>Previous</button>{(tableMinPagination2 + 1) + ' - ' + (tableMaxPagination2) + ' of ' + msgData[msgDataType].length}<button type="button" className="pagination-btn tbl-count blue-text " onClick={e => tablePaginationNew(msgData[msgDataType], 'forward')}>Next</button></div>
                        </div> : ""}
                    </div>
                }
            </div>
            : <h3 className="blue-text text-center">{msgData.error}</h3>}
        {loadarState ?

            <div className='credit-table-finbit' >
                <table className='text-center  ' style={{ marginTop: '0px' }}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Acc. No</th>
                            <th>Acc. Type</th>
                            <th>Amt 1</th>
                            <th>Amt 2</th>
                            <th>Source</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>

                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                        </tr>

                    </tbody>
                </table>
            </div>
            : ""}
    </div>
)

export default MsgAnalysisNew;