import React, { useEffect, useState } from "react";
import { getPaymentApi, getTxnBrreakDownApi } from "../AdminActionCreator.component";
import Pagination from '@mui/material/Pagination';
import Loader from '../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../images/nodata.png';
import moment from "moment";
import EmiBreakUp from "./breakdownpopup";
import PopUp from "../../../presentationals/Popup/Popup.component";

const Allpayments = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [applicationId, setApplicationId] = useState('');
    const [processType, setProcessType] = useState('');
    const [mode, setMode] = useState('');
    const [objectData, setObjectData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [loaderState, setLoader] = useState(false);
    const [payData, setpayData] = useState('');
    const [showData, setshowData] = useState('');
    const [leadData, setLeadData] = useState('');
    const [popupStatus, setpopupStatus] = useState('No Data Found!');
    const [popupState, setpopupState] = useState(false);

    const detailspayment = (data) => {
        console.log(data)
        setLeadData(data)
        setLoader(true)
        getTxnBrreakDownApi(data, callback => {
            if (callback) {
                console.log(callback)
                setpayData(callback)
                setshowData(true)
                setLoader(false)
            } else {
                setpopupState(true)
                setLoader(false)
                removePopup()

            }
        })
    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
        }, 5000);
    }
    const closePopup = () => {
        setshowData(false)
        setpopupState(false)
    }
    useEffect(() => {
        setLoader(true);
        getPaymentApi(startDate !== '' ? moment(startDate).format('DD-MM-YYYY') : '', endDate !== '' ? moment(endDate).format('DD-MM-YYYY') : "", callback => {
            setObjectData(callback);
            setFilteredData(callback);
            setLoader(false);
        });
    }, [startDate, endDate]);

    useEffect(() => {
        const data = objectData.filter(payment =>
            (applicationId ? payment.applicationId && payment.applicationId.includes(applicationId) : true) &&
            (processType ? payment.addedBy && payment.addedBy.includes(processType) : true) &&
            (mode ? payment.mode && payment.mode.includes(mode) : true)
        );
        setFilteredData(data);
        setCurrentPage(1);
    }, [applicationId, processType, mode, objectData]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const uniqueStatuses = objectData !== '' ? Array.from(new Set(objectData.map(status => status.mode))) : ""
    const processUnique = objectData !== '' ? Array.from(new Set(objectData.map(status => status.addedBy))) : ""


    return (
        <div className="container-fluid">
            {showData ?
                <EmiBreakUp leadData={leadData} payData={payData} close={closePopup} />
                : ""}
            {popupState ?
                <PopUp closePopup={closePopup} popupStatus={popupStatus} /> : ""}
            {console.log(processType)}
            {loaderState ? <Loader /> : ""}
            <h3><b>All Payments</b></h3>

            <div className="row">
                <div className="col-sm-2 col-xs-12">
                    <label>Start Date </label>
                    <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>End Date: </label>
                    <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>Application Id</label>
                    <input
                        type="text"
                        className="form-control"
                        value={applicationId}
                        onChange={(e) => setApplicationId(e.target.value)}
                    />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>Channel </label>
                    {/* <input
                        type="text"
                        className="form-control"
                        value={processType}
                        onChange={(e) => setProcessType(e.target.value)}
                    /> */}
                    <select value={processType}
                        onChange={(e) => setProcessType(e.target.value)} className="form-control" >
                        <option value=''>All</option>
                        {processUnique.map((process, i) => (
                            <option key={i} value={process}>{process}</option>
                        ))}
                    </select>
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>Mode</label>
                    <select value={mode}
                        onChange={(e) => setMode(e.target.value)} className="form-control" >
                        <option value=''>All</option>
                        {uniqueStatuses.map((mode, i) => (
                            <option key={i} value={mode}>{mode}</option>
                        ))}
                    </select>
                    {/* <input
                        type="text"
                        className="form-control"
                        value={mode}
                        onChange={(e) => setMode(e.target.value)}
                    /> */}

                </div>
            </div>

            {filteredData.length > 0 ? (
                <div className="phocket-table-new">
                    <table>
                        <thead>
                            <tr>
                                <th>Details</th>
                                <th>Application Id</th>
                                <th>Transaction Id</th>
                                <th>Amount</th>
                                <th>Mode</th>
                                <th>Channel</th>
                                <th>Process Type</th>
                                <th>Added On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((data, index) => (
                                <tr key={index}>
                                    <td><i onClick={() => detailspayment(data)} className="fa fa-info" style={{ cursor: 'pointer' }}></i></td>
                                    <td>{data.applicationId}</td>
                                    <td>{data.transactionId}</td>
                                    <td>{data.amount}</td>

                                    <td>{data.mode}</td>
                                    <td>{data.addedBy}</td>
                                    <td>{data.processType}</td>
                                    <td>{moment(data.added_on).format('DD-MM-YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ float: 'right' }}>
                        <Pagination
                            count={Math.ceil(filteredData.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                </div>
            ) : (
                <p style={{ marginTop: '100px' }} className="text-center">
                    <img src={NodataImage} style={{ width: '30%' }} />
                </p>
            )}
        </div>
    );
};

export default Allpayments;
