import React, { Component } from 'react'
import SoftApprovePopupUi from '../../../presentationals/Popup/SelectNbfc.popup';
import { APIS } from '../../../../utils/api-factory'

class SoftApprovePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nbfcState: '',
            disabled: false,
            nbfcList: []
        }
    }

    componentDidMount() {
        this.getNbfcList()
    }

    getNbfcList(){
        fetch(APIS.GET_LIST_OF_NBFC)
        .then(res => res.json())
        .then(res => {
            this.setState({
                nbfcList: res
            })
        })
    }

    render() {
        return (
            <SoftApprovePopupUi
                closePopup={this.closePopup.bind(this)}
                dataChange={this.dataChange.bind(this)}
                dataHandler={this.dataHandler.bind(this)}
                nbfcState={this.state.nbfcState}
                disabled={this.state.disabled}
                nbfcList={this.state.nbfcList}
            />
        )
    }

    closePopup(res, nbfcState) {
        this.props.closeNbfcPopup(res, nbfcState)
    }

    dataChange(e) {
        this.setState({
            nbfcState: e.target.value
        })
    }

    dataHandler() {
        const { allDetail } = this.props
        this.setState({ disabled: true })
        this.closePopup('success', this.state.nbfcState)
        // fetch(APIS.SOFT_APPROVAL_TO_NBFC + allDetail.loanId + '&nbfc=' + this.state.nbfcState)
        // .then(res => res.text())
        // .then(res => {
        //     this.closePopup(res, this.state.nbfcState)
        // })
    }
}

export default SoftApprovePopup;