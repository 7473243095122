import React, { useState } from 'react';
import CSVSAMPLE from '../../../../images/bulkComm.csv';
import { sendOtpBulk, uploadCsvSmsApi } from '../AdminActionCreator.component';
import Loader from '../../../presentationals/Loader/Loader.component'
const BulkOverDueSms = () => {
    const [file, setFile] = useState('');
    const [messages, setMessages] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [loaderState, setLoader] = useState(false);

    const onChangeHandler = (e) => {
        setFile(e.target.files[0]);
    };

    const submit = () => {
        setLoader(true)
        uploadCsvSmsApi(file, callback => {
            const parsedCallback = JSON.parse(callback);
            setMessages(parsedCallback);
            setLoader(false)
        });
    };

    const downloadsample = () => {
        fetch(CSVSAMPLE)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = 'Sample.csv';
                link.click();
            })
            .catch(console.error);
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            const allIds = Object.keys(messages);
            setSelectedIds(allIds);
        } else {
            setSelectedIds([]);
        }
    };

    const handleSelect = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };
    const sendsms = () => {
        setLoader(true)
        sendOtpBulk(file, selectedIds, callback => {
            setLoader(false)
        })
    }

    return (
        <div className='container-fluid'>
            {loaderState?<Loader/>:""}
            <h3><b>Send Bulk Overdue SMS</b></h3>
            <div className='row'>
                <div className='col-sm-3'></div>
                <div className='col-sm-6 col-xs-12'>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <input
                            style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', width: '40%' }}
                            accept=".csv"
                            type="file"
                            className="form-control"
                            onChange={e => onChangeHandler(e)}
                        />
                        <button
                            style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', width: '20%' }}
                            className='btn btn-primary'
                            onClick={() => submit()}
                        >
                            Submit
                        </button>
                        &nbsp;
                        <button
                            className='btn btn-success'
                            style={{ width: '20%' }}
                            onClick={() => downloadsample()}
                        >
                            Download Sample
                        </button>
                    </div>
                </div>
            </div>
            {messages !== '' ?

                <div className='container-fluid' style={{ marginTop: '20px' }}>
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                        /> Select All
                        &nbsp;&nbsp;<button className='btn btn-primary' onClick={() => sendsms()}>send</button>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Application ID</th>
                                <th>Message</th>
                                <th>Mobile Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(messages).map(([applicationId, data]) => (
                                <>
                                    {applicationId !== "error" ? (
                                        <tr key={applicationId}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedIds.includes(applicationId)}
                                                    onChange={() => handleSelect(applicationId)}
                                                />
                                            </td>
                                            <td>{applicationId}</td>
                                            <td style={{ whiteSpace: 'pre-wrap' }}>{data.smsMsg}</td>
                                            <td>{data.mobileNo}</td>
                                        </tr>
                                    ) : (
                                        <tr key={applicationId} style={{ color: 'red' }}>
                                            <td colSpan="4">{data}</td>
                                        </tr>
                                    )}
                                </>
                            ))}

                        </tbody>

                    </table>

                    {/* {messages !== '' ? Object.entries(messages)
                        .map(applicationId => (
                           
                                <>
                                    <h4>Error</h4>
                                    <div style={{ color: 'red' }} key={applicationId}>
                                        {applicationId[1]}
                                    </div>
                                </>
                          
                        )) : ""} */}
                    <div>
                        {/* {selectedIds.length > 0 ?
                            <>
                                <h5>Selected Application IDs</h5>
                                <ul>
                                    {selectedIds.map(id => (
                                        <li key={id}>{id}</li>
                                    ))}
                                </ul>
                            </>
                            : ""} */}
                    </div>
                </div>
                : ""}
        </div>
    );
}

export default BulkOverDueSms;
