import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

const ReferenceMail = ({ allDetail,link,approveAmount }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>Your loan is approved for INR {approveAmount}. Kindly <a href={link}>Click Here</a> to share 3 contact numbers for reference purposes to process your disbursement.
        </div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
    </div>
)

export default ReferenceMail