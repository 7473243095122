import React, { useEffect, useState } from "react";
import List from "../maketingimages/list.png";
import { formatDate, monthStartDate, reverseFormat, subtractOneDay, todayDate } from "../maketingComponents/utils/utils";
import { getDeviationReport } from "../maketingComponents/actioncreator";
import Filter from "../maketingimages/filter.png";
import Skeleton from "react-loading-skeleton";

const DeviationReport = () => {
    const months = [
        "january", "february", "march", "april",
        "may", "june", "july", "august",
        "september", "october", "november", "december"
    ];
    const [loader, setloader] = useState(false)

    const [objectData, setObjectData] = useState('')
    const [pageNo, setPage] = useState(1)
    const [noOfEntry, setnoOfEntry] = useState(10)
    const [today, settoday] = useState(todayDate())

    const [payload, setPayload] = useState({
        fromDate: monthStartDate(),
        toDate: todayDate(),
    });
    const getData = () => {
        setloader(true)
        getDeviationReport(payload, pageNo, noOfEntry, callback => {
            setObjectData(callback)
            setloader(false)
        })
    }
    const handleChange = (e) => {
        setPayload({
            ...payload,
            [e.target.name]:
                e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
        });
    };
    const submitFilter = () => {
        getData()
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className="col-sm-12 col-xs-12 shadow" style={{ marginBottom: '20px', border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: '10px', minHeight: '600px' }}>
            <div className="bg-white p-4 " style={{ padding: '15px' }}>

                <h5 style={{ color: '#1d3557', fontWeight: '700' }}> <strong style={{ paddingTop: '10px' }}>Deviation Chart</strong></h5>
                <div className="row" style={{ marginTop: '20px' }}>
                    {/* <div className="col-sm-2 col-xs-12">
                    <img src={Filter} width={20} height={20} alt="" />
                    <span className="ms-3 filterHeaders">Filter</span>
                    <select
                        name=""
                        id=""
                        // value={payload.filter}
                        className="form-select"
                        style={{ width: '100%', padding: '7px' }}
                    >
                        <option value="daily">Daily</option>
                    </select>
                </div> */}
                    {/* <div style={{ position: 'absolute', marginLeft: '16.5%', marginTop: '30px' }}>::</div> */}
                    <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                        From
                        <input
                            type="date"
                            id="startDate"
                            placeholder="DD/MM/YY"
                            name="fromDate"
                            value={reverseFormat(payload.fromDate)}
                            onChange={handleChange}
                            className=" form-control"
                            style={{
                                border: "1px solid gray",
                                borderRadius: "6px",
                                padding: "7px",
                            }}
                        />

                    </div>
                    {/* <div style={{ position: 'absolute', marginLeft: '33%', marginTop: '30px' }}>-</div> */}

                    <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                        <div className="filterHeaders" >to</div>
                        <input
                            type="date"
                            id="endDate"
                            name="toDate"
                            max={reverseFormat(subtractOneDay(today))}
                            value={reverseFormat(subtractOneDay(payload.toDate))}
                            onChange={handleChange}
                            placeholder="DD/MM/YY"
                            className=" form-control"
                            style={{
                                border: "1px solid gray",
                                borderRadius: "6px",
                                padding: "10px",
                            }}
                        />
                    </div>
                    <div className="col-sm-2 col-xs-12">
                        <button
                            onClick={() => submitFilter()}
                            className="btn btn-primary"
                            style={{
                                color: "#2B78FF",
                                borderColor: "#2B78FF",
                                backgroundColor: "white",
                                marginTop: '27px',
                                padding: '10px',
                                borderRadius: '5px'

                            }}
                        >
                            Apply Filter
                        </button>
                    </div>

                </div>
                <hr />
                <div
                    className="mt-2"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {/* <div className="d-inline-flex" style={{ display: 'inline-flex' }}>
                    <img src={List} alt="" width={18} height={18} style={{ marginTop: '10px' }} />{" "}
                    <h4 className="ms-3 leadBreakDown_Header">
                        &nbsp;&nbsp;Monthly stats of Budget Planned & Spent on each month
                    </h4>
                </div> */}

                </div>
                <div className='credit-table'>
                    <table>
                        <thead style={{ background: '#b9d8f9', color: '#000' }}>
                            <tr className="thead-class">
                                <th>Aggregator</th>
                                <th>Budget Divided</th>
                                {months.map(month => (
                                    <th key={month} style={{ textTransform: 'uppercase' }}>{month}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {objectData !== '' ? objectData.map(item => (

                                <tr key={item.partnerId}>

                                    <td>{item.aggregatorName}</td>
                                    <td style={{ padding: '0px' }} ><div style={{ background: '#4CC78C', padding: '10px', color: '#fff', fontWeight: '700' }}>Spent</div> <div style={{ background: '#2B78FF', padding: '10px', color: '#fff', fontWeight: '700' }}>Planned </div></td>
                                    {months.map(month => (
                                        <td key={month}>
                                            {item.data.find(d => d.month.trim() === month)['spentBudget']}
                                            <br />
                                            <br />
                                            {item.data.find(d => d.month.trim() === month)['plannedBudget']}
                                        </td>
                                    ))}
                                </tr>
                            )) : ""}
                            {loader ?
                                <>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>

                                    </tr>

                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>

                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                </>
                                : ""}
                        </tbody>
                    </table>
                </div>
            </div>

        </div >

    );
}

export default DeviationReport;
