import React, { useState, useEffect } from 'react'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter, ComposedChart, Line,
} from 'recharts';

const APIDatashow3month = {
    'APPLICATIONS  (New + Repeate)': [
        {
            date: 'June 2024',
            value: '20700'
        },
        {
            date: 'July 2024',
            value: '24774'
        },
        {
            date: 'August 2024',
            value: '17245'
        },
    ],
    'APPLICATIONS  (New)': [
        {
            date: 'June 2024',
            value: '16892'
        },
        {
            date: 'July 2024',
            value: '20389'
        },
        {
            date: 'August 2024',
            value: '13548'
        },
    ],
    'APPLICATIONS  (Repeat)': [
        {
            date: 'June 2024',
            value: '3794'
        },
        {
            date: 'July 2024',
            value: '3842'
        },
        {
            date: 'August 2024',
            value: '3920'
        },
    ],
    'SOFT APPROVED (New + Repeat)': [
        {
            date: 'June 2024',
            value: '2050'
        },
        {
            date: 'July 2024',
            value: '2282'
        },
        {
            date: 'August 2024',
            value: '2370'
        },
    ],
    'SOFT APPROVED (New)': [
        {
            date: 'June 2024',
            value: '578'
        },
        {
            date: 'July 2024',
            value: '560'
        },
        {
            date: 'August 2024',
            value: '607'
        },
    ],
    'SOFT APPROVED (Repeat)': [
        {
            date: 'June 2024',
            value: '1639'
        },
        {
            date: 'July 2024',
            value: '1684'
        },
        {
            date: 'August 2024',
            value: '1763'
        },
    ],
    'Confirmed APPROVED (New + Repeat)': [
        {
            date: 'June 2024',
            value: '1813'
        },
        {
            date: 'July 2024',
            value: '1971'
        },
        {
            date: 'August 2024',
            value: '1793'
        },
    ],
    'Confirmed APPROVED (New Loan)': [
        {
            date: 'June 2024',
            value: '1204'
        },
        {
            date: 'July 2024',
            value: '1403'
        },
        {
            date: 'August 2024',
            value: '1305'
        },
    ],
    'Confirmed APPROVED (Repeat Loan)': [
        {
            date: 'June 2024',
            value: '606'
        },
        {
            date: 'July 2024',
            value: '538'
        },
        {
            date: 'August 2024',
            value: '461'
        },
    ],
    'DISBURSED (NEW + Existing)': [
        {
            date: 'June 2024',
            value: '1450'
        },
        {
            date: 'July 2024',
            value: '1204'
        },
        {
            date: 'August 2024',
            value: '1457'
        },
    ],
    'DISBURSED (NEW)': [
        {
            date: 'June 2024',
            value: '1204'
        },
        {
            date: 'July 2024',
            value: '903'
        },
        {
            date: 'August 2024',
            value: '1042'
        },
    ],
    'DISBURSED  (Existing)': [
        {
            date: 'June 2024',
            value: '246'
        },
        {
            date: 'July 2024',
            value: '301'
        },
        {
            date: 'August 2024',
            value: '413'
        },
    ],
    'Non Disbursed (NEW)': [
        {
            date: 'June 2024',
            value: '120'
        },
        {
            date: 'July 2024',
            value: '102'
        },
        {
            date: 'August 2024',
            value: '134'
        },
    ],
    'Non Disbursed (NEW %)': [
        {
            date: 'June 2024',
            value: '23.01'
        },
        {
            date: 'July 2024',
            value: '12.93'
        },
        {
            date: 'August 2024',
            value: '25.42'
        },
    ],
    'Non Disbursed (Existing)': [
        {
            date: 'June 2024',
            value: '304'
        },
        {
            date: 'July 2024',
            value: '271'
        },
        {
            date: 'August 2024',
            value: '308'
        },
    ],
    'Non Disbursed (Existing %)': [
        {
            date: 'June 2024',
            value: '22.2'
        },
        {
            date: 'July 2024',
            value: '15.38'
        },
        {
            date: 'August 2024',
            value: '18.39'
        },
    ],
    'DISBURSED % of Application': [
        {
            date: 'June 2024',
            value: '93.78'
        },
        {
            date: 'July 2024',
            value: '89.03'
        },
        {
            date: 'August 2024',
            value: '11.05'
        },
    ],
    'O/s Principal Loan Amount as on': [
        { date: 'June 2024', value: '93939506' },
        { date: 'July 2024', value: '103920306' },
        { date: 'August 2024', value: '133920206'},
    ],
    'Growth of Loan O/s amt in %': [
        { date: 'June 2024', value: '7.39'},
        { date: 'July 2024', value: '9.59' },
        { date: 'August 2024', value: '9.42' },
    ],
    'O/s Principal Loan Amount after 13th Nov as on': [
        { date: 'June 2024', value: '98928822' },
        { date: 'July 2024', value: '108956822' },
        { date: 'August 2024', value: '133920206'},
    ],
    'AMOUNT DISBURSED': [
        { date: 'June 2024', value: '26649000'},
        { date: 'July 2024', value: '28939393' },
        { date: 'August 2024', value: '36653000'},
    ],
    'AMOUNT DISBURSED WITH INTEREST': [
        { date: 'June 2024', value: '36266000' },
        { date: 'July 2024', value: '4215000' },
        { date: 'August 2024', value: '40616478' },
    ],
    'INTEREST INCOME ON DISBURSED LOAN': [
        { date: 'June 2024', value: '4057728' },
        { date: 'July 2024', value: '4759729' },
        { date: 'August 2024', value: '3964478' },
    ],
    'W.A. RATE OF INTEREST': [
        { date: 'June 2024', value: '11.24' },
        { date: 'July 2024', value: '11.04' },
        { date: 'August 2024', value: '10.82'},
    ],
    'PRE PAYMENT NO. LOAN CLOSED': [
        { date: 'June 2024', value: '954' },
        { date: 'July 2024', value: '932' },
        { date: 'August 2024', value: '1311' },
    ],
    'PRE PAYMENT INTEREST WAIVED': [
        { date: 'June 2024', value: '316018'},
        { date: 'July 2024', value: '297292' },
        { date: 'August 2024', value: '260947'},
    ],
    'Loan Disbursement-6month nos': [
        { date: 'June 2024', value: '271'},
        { date: 'July 2024', value: '328' },
        { date: 'August 2024', value2: '190'},
    ],
    'EC (NO)': [
        { date: 'June 2024', value: '253' },
        { date: 'July 2024', value: '312' },
        { date: 'August 2024', value: '207' },
    ],
    'EC (AMT)': [
        { date: 'June 2024', value: '9467000' },
        { date: 'July 2024', value: '12576000' },
        { date: 'August 2024', value: '8065000'},
    ],
    '6 month Total Amt': [
        { date: 'June 2024', value: '9476000' },
        { date: 'July 2024', value: '13171000'},
        { date: 'August 2024', value: '8392000' },
    ],
    '6 month %': [
        { date: 'June 2024', value: '26.10' },
        { date: 'July 2024', value: '31.25' },
        { date: 'August 2024', value: '22.90' },
    ],
    'Avg Ticket for 6 month': [
        { date: 'June 2024', value: '34934' },
        { date: 'July 2024', value: '40155' },
        { date: 'August 2024', value: '38673' },
    ],
    'Less than 6nos': [
        { date: 'June 2024', value: '1635' },
        { date: 'July 2024', value: '1816' },
        { date: 'August 2024', value: '1690' },
    ],
    'Less 6 month Amt': [
        { date: 'June 2024', value: '26799000' },
        { date: 'July 2024', value: '28979000' },
        { date: 'August 2024', value: '28260000' },
    ],
};
const APIDatashow6month = {
    'APPLICATIONS  (New + Repeate)': [
        { date: 'March 2024', value: '18543', value2: '16000' },
        { date: 'April 2024', value: '19210', value2: '17000' },
        { date: 'May 2024', value: '19929', value2: '18000' },
        { date: 'June 2024', value: '20700', value2: '19000' },
        { date: 'July 2024', value: '24774', value2: '22000' },
        { date: 'August 2024', value: '17245', value2: '15000' }
    ],
    'APPLICATIONS (New)': [
        { date: 'March 2024', value: '12511', value2: '12000' },
        { date: 'April 2024', value: '13078', value2: '12500' },
        { date: 'May 2024', value: '13716', value2: '13000' },
        { date: 'June 2024', value: '13548', value2: '13000' },
        { date: 'July 2024', value: '20389', value2: '19000' },
        { date: 'August 2024', value: '13548', value2: '13000' }
    ],

    'APPLICATIONS (Repeat)': [
        { date: 'March 2024', value: '2733', value2: '2500' },
        { date: 'April 2024', value: '2832', value2: '2600' },
        { date: 'May 2024', value: '2938', value2: '2700' },
        { date: 'June 2024', value: '3920', value2: '3600' },
        { date: 'July 2024', value: '3842', value2: '3500' },
        { date: 'August 2024', value: '3920', value2: '3600' }
    ],

    'SOFT APPROVED (New + Repeat)': [
        { date: 'March 2024', value: '2283', value2: '2100' },
        { date: 'April 2024', value: '2363', value2: '2200' },
        { date: 'May 2024', value: '2447', value2: '2300' },
        { date: 'June 2024', value: '2370', value2: '2200' },
        { date: 'July 2024', value: '2282', value2: '2100' },
        { date: 'August 2024', value: '2370', value2: '2200' }
    ],

    'SOFT APPROVED (New)': [
        { date: 'March 2024', value: '585', value2: '550' },
        { date: 'April 2024', value: '606', value2: '580' },
        { date: 'May 2024', value: '628', value2: '600' },
        { date: 'June 2024', value: '578', value2: '550' },
        { date: 'July 2024', value: '560', value2: '530' },
        { date: 'August 2024', value: '607', value2: '580' }
    ],

    'SOFT APPROVED (Repeat)': [
        { date: 'March 2024', value: '1764', value2: '1600' },
        { date: 'April 2024', value: '1825', value2: '1700' },
        { date: 'May 2024', value: '1890', value2: '1750' },
        { date: 'June 2024', value: '1639', value2: '1500' },
        { date: 'July 2024', value: '1684', value2: '1550' },
        { date: 'August 2024', value: '1763', value2: '1600' }
    ],
    'Confirmed APPROVED (New + Repeat)': [
        { date: 'March 2024', value: '1747', value2: '1600' },
        { date: 'April 2024', value: '1805', value2: '1700' },
        { date: 'May 2024', value: '1866', value2: '1750' },
        { date: 'June 2024', value: '1813', value2: '1700' },
        { date: 'July 2024', value: '1971', value2: '1800' },
        { date: 'August 2024', value: '1793', value2: '1650' }
    ],

    'Confirmed APPROVED (New Loan)': [
        { date: 'March 2024', value: '1168', value2: '1100' },
        { date: 'April 2024', value: '1200', value2: '1150' },
        { date: 'May 2024', value: '1234', value2: '1150' },
        { date: 'June 2024', value: '1204', value2: '1100' },
        { date: 'July 2024', value: '1403', value2: '1300' },
        { date: 'August 2024', value: '1305', value2: '1200' }
    ],

    'Confirmed APPROVED (Repeat Loan)': [
        { date: 'March 2024', value: '465', value2: '430' },
        { date: 'April 2024', value: '481', value2: '450' },
        { date: 'May 2024', value: '497', value2: '470' },
        { date: 'June 2024', value: '606', value2: '570' },
        { date: 'July 2024', value: '538', value2: '500' },
        { date: 'August 2024', value: '461', value2: '430' }
    ],

    'DISBURSED (NEW + Existing)': [
        { date: 'March 2024', value: '1402', value2: '1300' },
        { date: 'April 2024', value: '1450', value2: '1350' },
        { date: 'May 2024', value: '1500', value2: '1400' },
        { date: 'June 2024', value: '1450', value2: '1350' },
        { date: 'July 2024', value: '1204', value2: '1100' },
        { date: 'August 2024', value: '1457', value2: '1300' }
    ],

    'DISBURSED (NEW)': [
        { date: 'March 2024', value: '1161', value2: '1100' },
        { date: 'April 2024', value: '1204', value2: '1100' },
        { date: 'May 2024', value: '1250', value2: '1150' },
        { date: 'June 2024', value: '1204', value2: '1100' },
        { date: 'July 2024', value: '903', value2: '850' },
        { date: 'August 2024', value: '1042', value2: '950' }
    ],

    'DISBURSED (Existing)': [
        { date: 'March 2024', value: '237', value2: '220' },
        { date: 'April 2024', value: '246', value2: '230' },
        { date: 'May 2024', value: '256', value2: '240' },
        { date: 'June 2024', value: '246', value2: '230' },
        { date: 'July 2024', value: '301', value2: '280' },
        { date: 'August 2024', value: '413', value2: '380' }
    ],

    'Non Disbursed (NEW)': [
        { date: 'March 2024', value: '116', value2: '100' },
        { date: 'April 2024', value: '120', value2: '110' },
        { date: 'May 2024', value: '125', value2: '115' },
        { date: 'June 2024', value2: '110' },
        { date: 'July 2024', value: '102', value2: '90' },
        { date: 'August 2024', value: '134', value2: '120' }
    ],

    'Non Disbursed (NEW %)': [
        { date: 'March 2024', value: '23.09', value2: '22.0' },
        { date: 'April 2024', value: '23.01', value2: '22.0' },
        { date: 'May 2024', value: '22.92', value2: '22.0' },
        { date: 'June 2024', value: '23.01', value2: '22.0' },
        { date: 'July 2024', value: '22.34', value2: '21.0' },
        { date: 'August 2024', value: '21.64', value2: '21.0' }
    ],

    'Non Disbursed (Existing)': [
        { date: 'March 2024', value: '295', value2: '280' },
        { date: 'April 2024', value: '308', value2: '290' },
        { date: 'May 2024', value: '321', value2: '300' },
        { date: 'June 2024', value: '304', value2: '290' },
        { date: 'July 2024', value: '271', value2: '250' },
        { date: 'August 2024', value: '308', value2: '290' }
    ],

    'Non Disbursed (Existing %)': [
        { date: 'March 2024', value: '21.43', value2: '20.0' },
        { date: 'April 2024', value: '22.15', value2: '21.0' },
        { date: 'May 2024', value: '22.89', value2: '22.0' },
        { date: 'June 2024', value: '22.20', value2: '21.0' },
        { date: 'July 2024', value: '15.38', value2: '14.0' },
        { date: 'August 2024', value: '18.39', value2: '17.0' }
    ],

    'DISBURSED % of Application': [
        { date: 'March 2024', value: '90.44', value2: '88.0' },
        { date: 'April 2024', value: '91.72', value2: '90.0' },
        { date: 'May 2024', value: '93.04', value2: '91.0' },
        { date: 'June 2024', value2: '92.0' },
        { date: 'July 2024', value: '89.03', value2: '87.0' },
        { date: 'August 2024', value: '91.05', value2: '89.0' }
    ],

    'O/s Principal Loan Amount as on': [
        { date: 'March 2024', value: '85786626', value2: '82000000' },
        { date: 'April 2024', value: '90453653', value2: '87000000' },
        { date: 'May 2024', value: '95370864', value2: '92000000' },
        { date: 'June 2024', value: '93939506', value2: '90000000' },
        { date: 'July 2024', value: '103920306', value2: '100000000' },
        { date: 'August 2024', value: '133920206', value2: '130000000' }
    ],

    'Growth of Loan O/s amt in %': [
        { date: 'March 2024', value: '6.43', value2: '6.0' },
        { date: 'April 2024', value: '7.39', value2: '7.0' },
        { date: 'May 2024', value: '8.39', value2: '8.0' },
        { date: 'June 2024', value: '7.39', value2: '7.0' },
        { date: 'July 2024', value: '9.59', value2: '9.0' },
        { date: 'August 2024', value: '9.42', value2: '9.0' }
    ],

    'O/s Principal Loan Amount after 13th Nov as on': [
        { date: 'March 2024', value: '94936150', value2: '91000000' },
        { date: 'April 2024', value: '99325247', value2: '96000000' },
        { date: 'May 2024', value: '103569237', value2: '100000000' },
        { date: 'June 2024', value2: '98000000' },
        { date: 'July 2024', value: '108956822', value2: '105000000' },
        { date: 'August 2024', value: '133920206', value2: '130000000' }
    ],

    'AMOUNT DISBURSED': [
        { date: 'March 2024', value: '25764140', value2: '25000000' },
        { date: 'April 2024', value: '26782320', value2: '26000000' },
        { date: 'May 2024', value: '27859448', value2: '27000000' },
        { date: 'June 2024', value: '26649000', value2: '26000000' },
        { date: 'July 2024', value: '28939393', value2: '28000000' },
        { date: 'August 2024', value: '36653000', value2: '35000000' }
    ],

    'AMOUNT DISBURSED WITH INTEREST': [
        { date: 'March 2024', value: '37349734', value2: '36000000' },
        { date: 'April 2024', value: '38440829', value2: '37000000' },
        { date: 'May 2024', value: '39572957', value2: '38000000' },
        { date: 'June 2024', value: '36266000', value2: '35000000' },
        { date: 'July 2024', value: '42150000', value2: '41000000' },
        { date: 'August 2024', value: '40616478', value2: '39000000' }
    ],

    'INTEREST INCOME ON DISBURSED LOAN': [
        { date: 'March 2024', value: '4402646', value2: '4300000' },
        { date: 'April 2024', value: '4566584', value2: '4450000' },
        { date: 'May 2024', value: '4732360', value2: '4600000' },
        { date: 'June 2024', value: '4057728', value2: '3950000' },
        { date: 'July 2024', value: '4759729', value2: '4650000' },
        { date: 'August 2024', value: '3964478', value2: '3850000' }
    ],

    'W.A. RATE OF INTEREST': [
        { date: 'March 2024', value: '11.46', value2: '11.00' },
        { date: 'April 2024', value: '11.35', value2: '11.00' },
        { date: 'May 2024', value: '11.24', value2: '11.00' },
        { date: 'June 2024', value: '11.24', value2: '11.00' },
        { date: 'July 2024', value: '11.04', value2: '10.90' },
        { date: 'August 2024', value: '10.82', value2: '10.70' }
    ],

    'PRE PAYMENT NO. LOAN CLOSED': [
        { date: 'March 2024', value: '922', value2: '900' },
        { date: 'April 2024', value: '890', value2: '870' },
        { date: 'May 2024', value: '858', value2: '840' },
        { date: 'June 2024', value: '954', value2: '930' },
        { date: 'July 2024', value: '932', value2: '910' },
        { date: 'August 2024', value: '1311', value2: '1250' }
    ],

    'PRE PAYMENT INTEREST WAIVED': [
        { date: 'March 2024', value: '321762', value2: '310000' },
        { date: 'April 2024', value: '305305', value2: '295000' },
        { date: 'May 2024', value: '289628', value2: '280000' },
        { date: 'June 2024', value: '316018', value2: '305000' },
        { date: 'July 2024', value: '297292', value2: '285000' },
        { date: 'August 2024', value: '260947', value2: '250000' }
    ],

    'Loan Disbursement-6month nos': [
        { date: 'March 2024', value: '290', value2: '270' },
        { date: 'April 2024', value: '309', value2: '290' },
        { date: 'May 2024', value: '329', value2: '310' },
        { date: 'June 2024', value: '271', value2: '250' },
        { date: 'July 2024', value: '328', value2: '310' },
        { date: 'August 2024', value: '217', value2: '200' }
    ],

    'EC (NO)': [
        { date: 'March 2024', value: '242', value2: '230' },
        { date: 'April 2024', value: '272', value2: '260' },
        { date: 'May 2024', value: '304', value2: '290' },
        { date: 'June 2024', value: '253', value2: '240' },
        { date: 'July 2024', value: '312', value2: '300' },
        { date: 'August 2024', value: '207', value2: '200' }
    ],

    'EC (AMT)': [
        { date: 'March 2024', value: '9160526', value2: '9000000' },
        { date: 'April 2024', value: '10374600', value2: '10000000' },
        { date: 'May 2024', value: '11582300', value2: '11000000' },
        { date: 'June 2024', value: '9467000', value2: '9200000' },
        { date: 'July 2024', value: '12576000', value2: '12000000' },
        { date: 'August 2024', value: '8065000', value2: '7800000' }
    ],

    '6 month Total Amt': [
        { date: 'March 2024', value: '9102000', value2: '8900000' },
        { date: 'April 2024', value: '12290000', value2: '12000000' },
        { date: 'May 2024', value: '14827000', value2: '14500000' },
        { date: 'June 2024', value: '9476000', value2: '9200000' },
        { date: 'July 2024', value: '13171000', value2: '12900000' },
        { date: 'August 2024', value: '8392000', value2: '8200000' }
    ],

    '6 month %': [
        { date: 'March 2024', value: '25.50', value2: '24.50' },
        { date: 'April 2024', value: '27.40', value2: '26.50' },
        { date: 'May 2024', value: '28.30', value2: '27.40' },
        { date: 'June 2024', value: '27.05', value2: '26.15' },
        { date: 'July 2024', value: '29.30', value2: '28.40' },
        { date: 'August 2024', value: '28.45', value2: '27.50' }
    ],

    'Avg Ticket for 6 month': [
        { date: 'March 2024', value: '38578', value2: '37500' },
        { date: 'April 2024', value: '32048', value2: '31000' },
        { date: 'May 2024', value: '36844', value2: '36000' },
        { date: 'June 2024', value: '34934', value2: '34000' },
        { date: 'July 2024', value: '40155', value2: '39000' },
        { date: 'August 2024', value: '38673', value2: '37500' }
    ],

    'Less than 6nos': [
        { date: 'March 2024', value: '1849', value2: '1750' },
        { date: 'April 2024', value: '1684', value2: '1600' },
        { date: 'May 2024', value: '1793', value2: '1700' },
        { date: 'June 2024', value: '1635', value2: '1550' },
        { date: 'July 2024', value: '1816', value2: '1720' },
        { date: 'August 2024', value: '1690', value2: '1600' }
    ],

    'Less 6 month Amt': [
        { date: 'March 2024', value: '20483000', value2: '20000000' },
        { date: 'April 2024', value: '22849000', value2: '22000000' },
        { date: 'May 2024', value: '24733000', value2: '24000000' },
        { date: 'June 2024', value: '26799000', value2: '26000000' },
        { date: 'July 2024', value: '28979000', value2: '28000000' },
        { date: 'August 2024', value: '28260000', value2: '27000000' }
    ],

};

const APIDatashow1year = {
    'APPLICATIONS  (New + Repeate)': [
        { date: 'September 2023', value: '25123', value2: '24000' },
        { date: 'October 2023', value: '24861', value2: '23500' },
        { date: 'November 2023', value: '24478', value2: '23000' },
        { date: 'December 2023', value: '24075', value2: '22500' },
        { date: 'January 2024', value: '23727', value2: '22000' },
        { date: 'February 2024', value: '23410', value2: '21500' },
        { date: 'March 2024', value: '23078', value2: '21000' },
        { date: 'April 2024', value: '22754', value2: '20500' },
        { date: 'May 2024', value: '22428', value2: '20000' },
        { date: 'June 2024', value: '20700', value2: '19000' },
        { date: 'July 2024', value: '24774', value2: '23000' },
        { date: 'August 2024', value: '17245', value2: '16000' }
    ],
    'APPLICATIONS (New)': [
        { date: 'September 2023', value: '20234', value2: '19000' },
        { date: 'October 2023', value: '19940', value2: '18500' },
        { date: 'November 2023', value: '19532', value2: '18000' },
        { date: 'December 2023', value: '19125', value2: '17500' },
        { date: 'January 2024', value: '18718', value2: '17000' },
        { date: 'February 2024', value: '18320', value2: '16500' },
        { date: 'March 2024', value: '17925', value2: '16000' },
        { date: 'April 2024', value: '17533', value2: '15500' },
        { date: 'May 2024', value: '17139', value2: '15000' },
        { date: 'June 2024', value: '16892', value2: '14500' },
        { date: 'July 2024', value: '20389', value2: '18000' },
        { date: 'August 2024', value: '13548', value2: '13000' }
    ],
    'APPLICATIONS (Repeat)': [
        { date: 'September 2023', value: '4863', value2: '4500' },
        { date: 'October 2023', value: '4797', value2: '4400' },
        { date: 'November 2023', value: '4734', value2: '4300' },
        { date: 'December 2023', value: '4674', value2: '4200' },
        { date: 'January 2024', value: '4617', value2: '4100' },
        { date: 'February 2024', value: '4563', value2: '4000' },
        { date: 'March 2024', value: '4512', value2: '3900' },
        { date: 'April 2024', value: '4464', value2: '3800' },
        { date: 'May 2024', value: '4418', value2: '3700' },
        { date: 'June 2024', value: '3794', value2: '3500' },
        { date: 'July 2024', value: '3842', value2: '3600' },
        { date: 'August 2024', value: '3920', value2: '3700' }
    ],
    'SOFT APPROVED (New + Repeat)': [
        { date: 'September 2023', value: '2140', value2: '2000' },
        { date: 'October 2023', value: '2108', value2: '1950' },
        { date: 'November 2023', value: '2076', value2: '1900' },
        { date: 'December 2023', value: '2044', value2: '1850' },
        { date: 'January 2024', value: '2014', value2: '1800' },
        { date: 'February 2024', value: '1984', value2: '1750' },
        { date: 'March 2024', value: '1954', value2: '1700' },
        { date: 'April 2024', value: '1925', value2: '1650' },
        { date: 'May 2024', value: '1896', value2: '1600' },
        { date: 'June 2024', value: '2050', value2: '1700' },
        { date: 'July 2024', value: '2282', value2: '1900' },
        { date: 'August 2024', value: '2370', value2: '2000' }
    ],
    'SOFT APPROVED (New)': [
        { date: 'September 2023', value: '591', value2: '550' },
        { date: 'October 2023', value: '581', value2: '540' },
        { date: 'November 2023', value: '572', value2: '530' },
        { date: 'December 2023', value: '563', value2: '520' },
        { date: 'January 2024', value: '554', value2: '510' },
        { date: 'February 2024', value: '546', value2: '500' },
        { date: 'March 2024', value: '537', value2: '490' },
        { date: 'April 2024', value: '529', value2: '480' },
        { date: 'May 2024', value: '521', value2: '470' },
        { date: 'June 2024', value: '578', value2: '520' },
        { date: 'July 2024', value: '560', value2: '500' },
        { date: 'August 2024', value: '607', value2: '540' }
    ],
    'SOFT APPROVED (Repeat)': [
        { date: 'September 2023', value: '1549', value2: '1400' },
        { date: 'October 2023', value: '1515', value2: '1350' },
        { date: 'November 2023', value: '1481', value2: '1300' },
        { date: 'December 2023', value: '1449', value2: '1250' },
        { date: 'January 2024', value: '1418', value2: '1200' },
        { date: 'February 2024', value: '1388', value2: '1150' },
        { date: 'March 2024', value: '1359', value2: '1100' },
        { date: 'April 2024', value: '1331', value2: '1050' },
        { date: 'May 2024', value: '1304', value2: '1000' },
        { date: 'June 2024', value: '1639', value2: '1200' },
        { date: 'July 2024', value: '1684', value2: '1250' },
        { date: 'August 2024', value: '1763', value2: '1300' }
    ],
    'Confirmed APPROVED (New + Repeat)': [
        { date: 'September 2023', value: '1882', value2: '1750' },
        { date: 'October 2023', value: '1853', value2: '1700' },
        { date: 'November 2023', value: '1825', value2: '1650' },
        { date: 'December 2023', value: '1798', value2: '1600' },
        { date: 'January 2024', value: '1772', value2: '1550' },
        { date: 'February 2024', value: '1747', value2: '1500' },
        { date: 'March 2024', value: '1723', value2: '1450' },
        { date: 'April 2024', value: '1699', value2: '1400' },
        { date: 'May 2024', value: '1676', value2: '1350' },
        { date: 'June 2024', value: '1813', value2: '1500' },
        { date: 'July 2024', value: '1971', value2: '1600' },
        { date: 'August 2024', value: '1793', value2: '1550' }
    ],
    'Confirmed APPROVED (New Loan)': [
        { date: 'September 2023', value: '1260', value2: '1150' },
        { date: 'October 2023', value: '1246', value2: '1130' },
        { date: 'November 2023', value: '1232', value2: '1110' },
        { date: 'December 2023', value: '1219', value2: '1090' },
        { date: 'January 2024', value: '1206', value2: '1070' },
        { date: 'February 2024', value: '1193', value2: '1050' },
        { date: 'March 2024', value: '1181', value2: '1030' },
        { date: 'April 2024', value: '1169', value2: '1010' },
        { date: 'May 2024', value: '1157', value2: '990' },
        { date: 'June 2024', value: '1204', value2: '1020' },
        { date: 'July 2024', value: '1403', value2: '1200' },
        { date: 'August 2024', value: '1305', value2: '1100' }
    ],
    'Confirmed APPROVED (Repeat Loan)': [
        { date: 'September 2023', value: '621', value2: '600' },
        { date: 'October 2023', value: '611', value2: '590' },
        { date: 'November 2023', value: '601', value2: '580' },
        { date: 'December 2023', value: '591', value2: '570' },
        { date: 'January 2024', value: '582', value2: '560' },
        { date: 'February 2024', value: '572', value2: '550' },
        { date: 'March 2024', value: '563', value2: '540' },
        { date: 'April 2024', value: '554', value2: '530' },
        { date: 'May 2024', value: '545', value2: '520' },
        { date: 'June 2024', value: '606', value2: '550' },
        { date: 'July 2024', value: '538', value2: '500' },
        { date: 'August 2024', value: '461', value2: '450' }
    ],
    'DISBURSED (NEW + Existing)': [
        { date: 'September 2023', value: '14991', value2: '14000' },
        { date: 'October 2023', value: '14792', value2: '13800' },
        { date: 'November 2023', value: '14598', value2: '13600' },
        { date: 'December 2023', value: '14409', value2: '13400' },
        { date: 'January 2024', value: '14224', value2: '13200' },
        { date: 'February 2024', value: '14042', value2: '13000' },
        { date: 'March 2024', value: '13863', value2: '12800' },
        { date: 'April 2024', value: '13687', value2: '12600' },
        { date: 'May 2024', value: '13514', value2: '12400' },
        { date: 'June 2024', value: '1450', value2: '1200' },
        { date: 'July 2024', value: '1204', value2: '1100' },
        { date: 'August 2024', value: '1457', value2: '1250' }
    ],
    'DISBURSED (NEW)': [
        { date: 'September 2023', value: '1230', value2: '1100' },
        { date: 'October 2023', value: '1216', value2: '1080' },
        { date: 'November 2023', value: '1202', value2: '1060' },
        { date: 'December 2023', value: '1189', value2: '1040' },
        { date: 'January 2024', value: '1176', value2: '1020' },
        { date: 'February 2024', value: '1163', value2: '1000' },
        { date: 'March 2024', value: '1151', value2: '980' },
        { date: 'April 2024', value: '1139', value2: '960' },
        { date: 'May 2024', value: '1128', value2: '940' },
        { date: 'June 2024', value: '1204', value2: '1000' },
        { date: 'July 2024', value: '903', value2: '800' },
        { date: 'August 2024', value: '1042', value2: '850' }
    ],
    'DISBURSED (Existing)': [
        { date: 'September 2023', value: '2690', value2: '2500' },
        { date: 'October 2023', value: '2645', value2: '2400' },
        { date: 'November 2023', value: '2600', value2: '2300' },
        { date: 'December 2023', value: '2557', value2: '2200' },
        { date: 'January 2024', value: '2516', value2: '2100' },
        { date: 'February 2024', value: '2477', value2: '2000' },
        { date: 'March 2024', value: '2440', value2: '1900' },
        { date: 'April 2024', value: '2404', value2: '1800' },
        { date: 'May 2024', value: '2369', value2: '1700' },
        { date: 'June 2024', value: '246', value2: '200' },
        { date: 'July 2024', value: '301', value2: '250' },
        { date: 'August 2024', value: '413', value2: '300' }
    ],
    'Non Disbursed (NEW)': [
        { date: 'September 2023', value: '121', value2: '100' },
        { date: 'October 2023', value: '119', value2: '95' },
        { date: 'November 2023', value: '117', value2: '90' },
        { date: 'December 2023', value: '115', value2: '85' },
        { date: 'January 2024', value: '113', value2: '80' },
        { date: 'February 2024', value: '111', value2: '75' },
        { date: 'March 2024', value: '109', value2: '70' },
        { date: 'April 2024', value: '107', value2: '65' },
        { date: 'May 2024', value: '105', value2: '60' },
        { date: 'June 2024', value: '120', value2: '75' },
        { date: 'July 2024', value: '102', value2: '65' },
        { date: 'August 2024', value: '134', value2: '85' }
    ],
    'Non Disbursed (NEW %)': [
        { date: 'September 2023', value: '23.51', value2: '22.50' },
        { date: 'October 2023', value: '23.19', value2: '22.20' },
        { date: 'November 2023', value: '22.88', value2: '21.90' },
        { date: 'December 2023', value: '22.56', value2: '21.60' },
        { date: 'January 2024', value: '22.25', value2: '21.30' },
        { date: 'February 2024', value: '21.94', value2: '21.00' },
        { date: 'March 2024', value: '21.64', value2: '20.70' },
        { date: 'April 2024', value: '21.34', value2: '20.40' },
        { date: 'May 2024', value: '21.05', value2: '20.10' },
        { date: 'June 2024', value: '23.01', value2: '22.00' },
        { date: 'July 2024', value: '12.93', value2: '12.00' },
        { date: 'August 2024', value: '25.42', value2: '24.00' }
    ],
    'Non Disbursed (Existing)': [
        { date: 'September 2023', value: '310', value2: '295' },
        { date: 'October 2023', value: '306', value2: '290' },
        { date: 'November 2023', value: '302', value2: '285' },
        { date: 'December 2023', value: '298', value2: '280' },
        { date: 'January 2024', value: '294', value2: '275' },
        { date: 'February 2024', value: '290', value2: '270' },
        { date: 'March 2024', value: '286', value2: '265' },
        { date: 'April 2024', value: '282', value2: '260' },
        { date: 'May 2024', value: '278', value2: '255' },
        { date: 'June 2024', value: '304', value2: '280' },
        { date: 'July 2024', value: '271', value2: '250' },
        { date: 'August 2024', value: '308', value2: '290' }
    ],
    'Non Disbursed (Existing %)': [
        { date: 'September 2023', value: '22.73', value2: '21.50' },
        { date: 'October 2023', value: '22.51', value2: '21.40' },
        { date: 'November 2023', value: '22.29', value2: '21.30' },
        { date: 'December 2023', value: '22.07', value2: '21.20' },
        { date: 'January 2024', value: '21.85', value2: '21.10' },
        { date: 'February 2024', value: '21.64', value2: '21.00' },
        { date: 'March 2024', value: '21.43', value2: '20.90' },
        { date: 'April 2024', value: '21.22', value2: '20.80' },
        { date: 'May 2024', value: '21.02', value2: '20.70' },
        { date: 'June 2024', value: '22.2', value2: '21.80' },
        { date: 'July 2024', value: '15.38', value2: '14.50' },
        { date: 'August 2024', value: '18.39', value2: '17.50' }
    ],
    'DISBURSED % of Application': [
        { date: 'September 2023', value: '94.04', value2: '93.00' },
        { date: 'October 2023', value: '93.02', value2: '92.00' },
        { date: 'November 2023', value: '92.00', value2: '91.00' },
        { date: 'December 2023', value: '90.99', value2: '89.00' },
        { date: 'January 2024', value: '89.97', value2: '88.00' },
        { date: 'February 2024', value: '88.95', value2: '87.00' },
        { date: 'March 2024', value: '87.94', value2: '86.00' },
        { date: 'April 2024', value: '86.92', value2: '85.00' },
        { date: 'May 2024', value: '85.90', value2: '84.00' },
        { date: 'June 2024', value: '93.78', value2: '92.00' },
        { date: 'July 2024', value: '89.03', value2: '87.00' },
        { date: 'August 2024', value: '11.05', value2: '10.00' }
    ],
    'O/s Principal Loan Amount as on': [
        { date: 'September 2023', value: '92564127', value2: '91500000' },
        { date: 'October 2023', value: '91522964', value2: '90500000' },
        { date: 'November 2023', value: '90491914', value2: '89500000' },
        { date: 'December 2023', value: '89471279', value2: '88500000' },
        { date: 'January 2024', value: '88461149', value2: '87500000' },
        { date: 'February 2024', value: '87461533', value2: '86500000' },
        { date: 'March 2024', value: '86472431', value2: '85500000' },
        { date: 'April 2024', value: '85493843', value2: '84500000' },
        { date: 'May 2024', value: '84525769', value2: '83500000' },
        { date: 'June 2024', value: '93939506', value2: '92000000' },
        { date: 'July 2024', value: '103920306', value2: '102000000' },
        { date: 'August 2024', value: '133920206', value2: '130000000' }
    ],
    'Growth of Loan O/s amt in %': [
        { date: 'September 2023', value: '7.24', value2: '7.00' },
        { date: 'October 2023', value: '7.36', value2: '7.10' },
        { date: 'November 2023', value: '7.48', value2: '7.20' },
        { date: 'December 2023', value: '7.60', value2: '7.30' },
        { date: 'January 2024', value: '7.73', value2: '7.40' },
        { date: 'February 2024', value: '7.86', value2: '7.50' },
        { date: 'March 2024', value: '7.99', value2: '7.60' },
        { date: 'April 2024', value: '8.12', value2: '7.70' },
        { date: 'May 2024', value: '8.25', value2: '7.80' },
        { date: 'June 2024', value: '8.04', value2: '7.90' },
        { date: 'July 2024', value: '8.67', value2: '8.00' },
        { date: 'August 2024', value: '8.47', value2: '8.10' }
    ],
    'O/s Principal Loan Amount after 13th Nov as on': [
        { date: 'September 2023', value: '136261251', value2: '135000000' },
        { date: 'October 2023', value: '127697587', value2: '126500000' },
        { date: 'November 2023', value: '120051271', value2: '119000000' },
        { date: 'December 2023', value: '113251940', value2: '112000000' },
        { date: 'January 2024', value: '106354518', value2: '105000000' },
        { date: 'February 2024', value: '99452765', value2: '98000000' },
        { date: 'March 2024', value: '92363916', value2: '91000000' },
        { date: 'April 2024', value: '85577360', value2: '84000000' },
        { date: 'May 2024', value: '79087751', value2: '78000000' },
        { date: 'June 2024', value: '98928822', value2: '97000000' },
        { date: 'July 2024', value: '108956822', value2: '107000000' },
        { date: 'August 2024', value: '133920206', value2: '130000000' }
    ],
    'AMOUNT DISBURSED': [
        { date: 'September 2023', value: '37189000', value2: '36500000' },
        { date: 'October 2023', value: '36552218', value2: '36000000' },
        { date: 'November 2023', value: '35920024', value2: '35500000' },
        { date: 'December 2023', value: '35286210', value2: '35000000' },
        { date: 'January 2024', value: '34658329', value2: '34000000' },
        { date: 'February 2024', value: '34028767', value2: '33500000' },
        { date: 'March 2024', value: '33405663', value2: '33000000' },
        { date: 'April 2024', value: '32782776', value2: '32500000' },
        { date: 'May 2024', value: '32164363', value2: '32000000' },
        { date: 'June 2024', value: '26649000', value2: '26000000' },
        { date: 'July 2024', value: '28939393', value2: '28000000' },
        { date: 'August 2024', value: '36653000', value2: '36000000' }
    ],
    'AMOUNT DISBURSED WITH INTEREST': [
        { date: 'September 2023', value: '40266400', value2: '39500000' },
        { date: 'October 2023', value: '39447200', value2: '38800000' },
        { date: 'November 2023', value: '38634800', value2: '38000000' },
        { date: 'December 2023', value: '37828240', value2: '37000000' },
        { date: 'January 2024', value: '37028370', value2: '36500000' },
        { date: 'February 2024', value: '36235246', value2: '35500000' },
        { date: 'March 2024', value: '35448741', value2: '35000000' },
        { date: 'April 2024', value: '34668786', value2: '34000000' },
        { date: 'May 2024', value: '33895343', value2: '33500000' },
        { date: 'June 2024', value: '36266000', value2: '36000000' },
        { date: 'July 2024', value: '4215000', value2: '4200000' },
        { date: 'August 2024', value: '40616478', value2: '40000000' }
    ],
    'INTEREST INCOME ON DISBURSED LOAN': [
        { date: 'September 2023', value: '4119280', value2: '4000000' },
        { date: 'October 2023', value: '4077240', value2: '3950000' },
        { date: 'November 2023', value: '4035611', value2: '3900000' },
        { date: 'December 2023', value: '3994400', value2: '3850000' },
        { date: 'January 2024', value: '3953615', value2: '3800000' },
        { date: 'February 2024', value: '3913254', value2: '3750000' },
        { date: 'March 2024', value: '3873317', value2: '3700000' },
        { date: 'April 2024', value: '3833798', value2: '3650000' },
        { date: 'May 2024', value: '3794698', value2: '3600000' },
        { date: 'June 2024', value: '4057728', value2: '4000000' },
        { date: 'July 2024', value: '4759729', value2: '4700000' },
        { date: 'August 2024', value: '3964478', value2: '3900000' }
    ],
    'W.A. RATE OF INTEREST': [
        { date: 'September 2023', value: '11.39', value2: '11.30' },
        { date: 'October 2023', value: '11.20', value2: '11.10' },
        { date: 'November 2023', value: '11.02', value2: '10.90' },
        { date: 'December 2023', value: '10.84', value2: '10.70' },
        { date: 'January 2024', value: '10.66', value2: '10.50' },
        { date: 'February 2024', value: '10.48', value2: '10.30' },
        { date: 'March 2024', value: '10.31', value2: '10.10' },
        { date: 'April 2024', value: '10.13', value2: '9.90' },
        { date: 'May 2024', value: '9.96', value2: '9.70' },
        { date: 'June 2024', value: '11.24', value2: '11.00' },
        { date: 'July 2024', value: '11.04', value2: '10.80' },
        { date: 'August 2024', value: '10.82', value2: '10.60' }
    ],
    'PRE PAYMENT NO. LOAN CLOSED': [
        { date: 'September 2023', value: '968', value2: '950' },
        { date: 'October 2023', value: '952', value2: '930' },
        { date: 'November 2023', value: '936', value2: '920' },
        { date: 'December 2023', value: '921', value2: '910' },
        { date: 'January 2024', value: '906', value2: '900' },
        { date: 'February 2024', value: '891', value2: '880' },
        { date: 'March 2024', value: '877', value2: '870' },
        { date: 'April 2024', value: '864', value2: '850' },
        { date: 'May 2024', value: '851', value2: '840' },
        { date: 'June 2024', value: '954', value2: '940' },
        { date: 'July 2024', value: '932', value2: '920' },
        { date: 'August 2024', value: '1311', value2: '1300' }
    ],
    'PRE PAYMENT INTEREST WAIVED': [
        { date: 'September 2023', value: '322168', value2: '320000' },
        { date: 'October 2023', value: '318081', value2: '315000' },
        { date: 'November 2023', value: '313952', value2: '310000' },
        { date: 'December 2023', value: '309861', value2: '305000' },
        { date: 'January 2024', value: '305815', value2: '300000' },
        { date: 'February 2024', value: '301814', value2: '300000' },
        { date: 'March 2024', value: '297857', value2: '295000' },
        { date: 'April 2024', value: '293944', value2: '290000' },
        { date: 'May 2024', value: '290074', value2: '285000' },
        { date: 'June 2024', value: '316018', value2: '310000' },
        { date: 'July 2024', value: '297292', value2: '295000' },
        { date: 'August 2024', value: '260947', value2: '260000' }
    ],
    'Loan Disbursement-6month nos': [
        { date: 'September 2023', value: '276', value2: '270' },
        { date: 'October 2023', value: '279', value2: '275' },
        { date: 'November 2023', value: '283', value2: '280' },
        { date: 'December 2023', value: '287', value2: '285' },
        { date: 'January 2024', value: '291', value2: '290' },
        { date: 'February 2024', value: '295', value2: '290' },
        { date: 'March 2024', value: '299', value2: '295' },
        { date: 'April 2024', value: '304', value2: '300' },
        { date: 'May 2024', value: '308', value2: '305' },
        { date: 'June 2024', value: '271', value2: '270' },
        { date: 'July 2024', value: '328', value2: '320' },
        { date: 'August 2024', value: '217', value2: '210' }
    ],
    'EC (NO)': [
        { date: 'September 2023', value: '258', value2: '255' },
        { date: 'October 2023', value: '261', value2: '260' },
        { date: 'November 2023', value: '265', value2: '262' },
        { date: 'December 2023', value: '269', value2: '265' },
        { date: 'January 2024', value: '273', value2: '270' },
        { date: 'February 2024', value: '278', value2: '275' },
        { date: 'March 2024', value: '283', value2: '280' },
        { date: 'April 2024', value: '288', value2: '285' },
        { date: 'May 2024', value: '293', value2: '290' },
        { date: 'June 2024', value: '253', value2: '250' },
        { date: 'July 2024', value: '312', value2: '310' },
        { date: 'August 2024', value: '207', value2: '200' }
    ],
    'EC (AMT)': [
        { date: 'September 2023', value: '9300000', value2: '9200000' },
        { date: 'October 2023', value: '9420000', value2: '9300000' },
        { date: 'November 2023', value: '9540000', value2: '9400000' },
        { date: 'December 2023', value: '9660000', value2: '9550000' },
        { date: 'January 2024', value: '9780000', value2: '9650000' },
        { date: 'February 2024', value: '9900000', value2: '9750000' },
        { date: 'March 2024', value: '10020000', value2: '9850000' },
        { date: 'April 2024', value: '10140000', value2: '9950000' },
        { date: 'May 2024', value: '10260000', value2: '10000000' },
        { date: 'June 2024', value: '9467000', value2: '9400000' },
        { date: 'July 2024', value: '12576000', value2: '12500000' },
        { date: 'August 2024', value: '8065000', value2: '8000000' }
    ],
    '6 month Total Amt': [
        { date: 'September 2023', value: '9350000', value2: '9300000' },
        { date: 'October 2023', value: '9425000', value2: '9400000' },
        { date: 'November 2023', value: '9500000', value2: '9450000' },
        { date: 'December 2023', value: '9575000', value2: '9550000' },
        { date: 'January 2024', value: '9650000', value2: '9600000' },
        { date: 'February 2024', value: '9725000', value2: '9700000' },
        { date: 'March 2024', value: '9800000', value2: '9750000' },
        { date: 'April 2024', value: '9875000', value2: '9850000' },
        { date: 'May 2024', value: '9950000', value2: '9900000' },
        { date: 'June 2024', value: '9476000', value2: '9450000' },
        { date: 'July 2024', value: '13171000', value2: '13000000' },
        { date: 'August 2024', value: '8392000', value2: '8300000' }
    ],
    '6 month %': [
        { date: 'September 2023', value: '25.74', value2: '25.50' },
        { date: 'October 2023', value: '25.84', value2: '25.60' },
        { date: 'November 2023', value: '25.95', value2: '25.80' },
        { date: 'December 2023', value: '26.06', value2: '25.90' },
        { date: 'January 2024', value: '26.17', value2: '26.00' },
        { date: 'February 2024', value: '26.28', value2: '26.10' },
        { date: 'March 2024', value: '26.39', value2: '26.20' },
        { date: 'April 2024', value: '26.51', value2: '26.30' },
        { date: 'May 2024', value: '26.63', value2: '26.40' },
        { date: 'June 2024', value: '26.10', value2: '26.00' },
        { date: 'July 2024', value: '31.25', value2: '31.00' },
        { date: 'August 2024', value: '22.90', value2: '22.70' }
    ],
    'Avg Ticket for 6 month': [
        { date: 'September 2023', value: '34994', value2: '34800' },
        { date: 'October 2023', value: '35000', value2: '34900' },
        { date: 'November 2023', value: '35078', value2: '35000' },
        { date: 'December 2023', value: '35158', value2: '35100' },
        { date: 'January 2024', value: '35239', value2: '35200' },
        { date: 'February 2024', value: '35323', value2: '35300' },
        { date: 'March 2024', value: '35408', value2: '35400' },
        { date: 'April 2024', value: '35495', value2: '35500' },
        { date: 'May 2024', value: '35584', value2: '35500' },
        { date: 'June 2024', value: '34934', value2: '34800' },
        { date: 'July 2024', value: '40155', value2: '40000' },
        { date: 'August 2024', value: '38673', value2: '38500' }
    ],
    'Less than 6nos': [
        { date: 'September 2023', value: '1630', value2: '1600' },
        { date: 'October 2023', value: '1625', value2: '1600' },
        { date: 'November 2023', value: '1621', value2: '1600' },
        { date: 'December 2023', value: '1616', value2: '1600' },
        { date: 'January 2024', value: '1612', value2: '1600' },
        { date: 'February 2024', value: '1608', value2: '1600' },
        { date: 'March 2024', value: '1604', value2: '1600' },
        { date: 'April 2024', value: '1600', value2: '1600' },
        { date: 'May 2024', value: '1596', value2: '1600' },
        { date: 'June 2024', value: '1635', value2: '1600' },
        { date: 'July 2024', value: '1816', value2: '1800' },
        { date: 'August 2024', value: '1690', value2: '1700' }
    ],
    'Less 6 month Amt': [
        { date: 'September 2023', value: '27290000', value2: '27000000' },
        { date: 'October 2023', value: '27250000', value2: '27000000' },
        { date: 'November 2023', value: '27200000', value2: '27000000' },
        { date: 'December 2023', value: '27150000', value2: '27000000' },
        { date: 'January 2024', value: '27100000', value2: '27000000' },
        { date: 'February 2024', value: '27050000', value2: '27000000' },
        { date: 'March 2024', value: '27000000', value2: '27000000' },
        { date: 'April 2024', value: '26950000', value2: '27000000' },
        { date: 'May 2024', value: '26900000', value2: '27000000' },
        { date: 'June 2024', value: '26799000', value2: '27000000' },
        { date: 'July 2024', value: '28979000', value2: '29000000' },
        { date: 'August 2024', value: '28260000', value2: '28000000' }
    ]

};




export default function ApprovedLoans({ fromDate, toDate, applyFilter, handleApplyFilter, clearFilter, handleclearFilter, edit, handleEdit, ActiveMonthFilter }) {

    const [selectedData, setSelectedData] = useState([]);
    const [selectedDataIndex, setSelectedDataIndex] = useState(-1);
    const [selectedDataHeading, setSelectedDataHeading] = useState('')
    const [APIData, setAPIData] = useState(APIDatashow3month)
    useEffect(() => {
        if (applyFilter == true) {
            console.log("apply", fromDate, toDate)
            // api call for applying filter here 
            setTimeout(() => {
                handleApplyFilter()
            }, 500)
        }
        if (clearFilter == true) {
            setAPIData(APIDatashow3month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

            console.log("clear", fromDate, toDate)
            // api call for clear filter here 
            setTimeout(() => {
                handleclearFilter()
            }, 500)
        }
        if (edit == true) {
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
            setSelectedData([])
        }
        if (ActiveMonthFilter === '3month') {
            setAPIData(APIDatashow3month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        } else if (ActiveMonthFilter === '6month') {
            setAPIData(APIDatashow6month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        } else if (ActiveMonthFilter === '1year') {
            setAPIData(APIDatashow1year)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }
    }, [applyFilter, clearFilter, edit, ActiveMonthFilter])




    const handleSelectedData = (index, datakey, data) => {
        setSelectedData([])
        if (index === selectedDataIndex) {
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

        } else {
            setSelectedDataHeading(datakey)
            setSelectedDataIndex(index)
            setTimeout(() => {
                setSelectedData(data)
            }, 300)

        }

    }

    const handleEditValue = (e) => {
        const { name, value } = e.target;
    }

    const handleSavebtn = () => {
        handleEdit();
    }

    return (
        <div className="Dashboardsmis">
            {
                !edit ?
                    <div className="row" style={{ position: 'relative' }}>
                        <div className={`${selectedDataIndex == '-1' ? 'col-xs-12' : "col-md-6 col-xs-12"} transition-col`}>
                            <div className="table" style={{ marginTop: '20px' }}>
                                <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                    <table>
                                        <thead style={{ position: 'sticky', top: 0 }}>
                                            <tr>
                                                <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Particulars</th>
                                                {
                                                    APIData['APPLICATIONS  (New + Repeate)'].map((item, index) => (
                                                        <th key={index}>{item.date}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                                <tr className={selectedDataIndex === index ? 'activeTr' : ''} key={index} style={{ cursor: 'pointer' }} onClick={() => handleSelectedData(index, datakey, datavalue)}>
                                                    <td className='leftsticky'><b>{datakey}</b></td>
                                                    {
                                                        datavalue?.map((value, index) => {
                                                            return (
                                                                <td key={index}  style={{width: ActiveMonthFilter === '3month' ? '100px':'auto'}}>{value?.value}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {
                            selectedData?.length > 0 ?
                                <div className="col-md-6 col-xs-12" style={{ position: 'sticky', top: '10%' }}>
                                    <div className="charts" style={{ marginTop: '20px' }}>
                                        <h3>{selectedDataHeading}</h3>
                                        <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '730px' : '460px' }}>
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    width={500}
                                                    height={300}
                                                    data={selectedData}
                                                    barSize={ActiveMonthFilter === '3month' ? 40: ActiveMonthFilter === '6month' ? 30 : 20}
                                                    margin={{
                                                        top: 20,
                                                        right: 0,
                                                        left: 30,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid stroke='none' />
                                                    <XAxis dataKey="date" />
                                                    <YAxis padding={{ top: 20 }}/>
                                                    <Tooltip />
                                                    <Bar dataKey="value" fill="#8884d8" />
                                                    <Line type="monotone" dataKey="value" stroke="#223D64" strokeWidth={2} dot={null} />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                    </div>
                    :
                    <div className="row" >
                        <div className='col-xs-12'>
                            <div className="table" style={{ marginTop: '20px' }}>
                                <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                    <table>
                                        <thead style={{ position: 'sticky', top: 0 }}>
                                            <tr>
                                                <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Particulars</th>
                                                {
                                                    APIData['APPLICATIONS  (New + Repeate)'].map((item, index) => (
                                                        <th key={index}>{item.date}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                               <tr key={index}>
                                                <td className='leftsticky'><b>{datakey}</b></td>
                                                    {
                                                        datavalue?.map((value, index) => {
                                                            return (
                                                                <td key={index}><input type="number" name={datakey + '-' + value?.date} value={value.value} className='Editinputtag' onChange={(e) => handleEditValue(e)} /></td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="Filterations" style={{ marginTop: '40px', textAlign: 'center' }}>
                                <button className='filterbtn' style={{ width: '8%' }} onClick={() => handleSavebtn()}>Save</button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

