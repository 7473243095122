import React, { Component } from 'react'
import Scoring from '../../../presentationals/Admin/CustomerPages/Scoring.component'
import { fetchCibilDetail, saveDeviationPoint, getConcludeDataApiForScoring } from '../AdminActionCreator.component'
import { APIS } from '../../../../utils/api-factory'
import Popup from '../../../presentationals/Popup/Popup.component';
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'
class Score extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDetail: this.props.allDetail,
            popupState: false,
            popupStatus: '',
            scoreDetail: {
                cibilOverdueAccount: '',
                cibilScore: '',
                cibilincome: '',
                cibilObligations: '',
                cibilSurplus: '',
                cibilRemarks: '',
                shortTermCreditCard: '',
                confirmationPopup: false,
                confirmationText: '',
                dbrValue: ''
            },
            deviationValues: '',
            inputRemarkField: false,
            newData: ''
        }
    }
    cancelLoanConfirm(type) {

            if (type == 'no') {
                this.setState({ confirmationPopup: false })
            } if (type == 'yes') {
                this.scoreHandler()
            }
        
    }
    openConfirmation() {
        if (this.state.scoreDetail.cibilRemarks !== '') {
            this.setState({ confirmationPopup: true, confirmationText: 'Are you sure you want to change the Score?' })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'please select remarks ',
                confirmationPopup: false
            })
            this.removePopup();
        }
    }
    inputRemark = (e) => {
        this.setState({ cibilRemarks: e.target.value })


    }


    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <Scoring

                    inputRemark={this.inputRemark.bind(this)}
                    inputRemarkField={this.state.inputRemarkField}
                    deviationValues={this.state.deviationValues}
                    flagData={this.props.flagData}
                    dbrValue={this.state.dbrValue}
                    scoreChange={this.scoreChange.bind(this)}
                    scoreHandler={this.scoreHandler.bind(this)}
                    scoreDetail={this.state.scoreDetail}
                    openConfirmation={this.openConfirmation.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )

    }

    closePopup() {
        this.setState({ popupState: false });
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    componentDidMount() {
        let scoreDetail = Object.assign({}, this.state.scoreDetail)
        fetch(APIS.GET_DEVIATION_VALUES)
            .then(res => res.json())
            .then(res => {
                this.setState({ deviationValues: res })
            })
            .catch((e) => {
                console.log(e)
            })

        getConcludeDataApiForScoring(this.state.allDetail.userId,this.state.allDetail.loanId, callback => {
            if (callback) {
                if (callback.crifScore != null) {
                    scoreDetail.cibilScore = callback.crifScore
                }
                if (callback.salaryConsidered != null) {
                    scoreDetail.cibilincome = callback.salaryConsidered
                }
                if (callback.surplus != null) {
                    scoreDetail.cibilSurplus = callback.surplus
                }
                if (callback.obligation != null) {
                    scoreDetail.cibilObligations = callback.obligation
                }
                this.setState({ scoreDetail, dbrValue: callback.dbr, newData: scoreDetail })
            }



        })

        fetch(APIS.GET_CIBIL_SCORE_BY_USERID + this.state.allDetail.userId +'&loanId='+this.state.allDetail.loanId)
            .then(res => res.json())
            .then(res => {
                if (res.responseCode == 0) {

                } else {
                    if (res.overDueAccount != null) {
                        scoreDetail.cibilOverdueAccount = res.overDueAccount
                    }

                    if (res.cibilScore != null) {
                        scoreDetail.cibilScore = (res.cibilScore)
                    }
                    if (res.income != null) {
                        scoreDetail.cibilincome = res.income
                    }
                    if (res.obligations != null) {
                        scoreDetail.cibilObligations = res.obligations
                    }
                    if (res.surplus != null) {
                        scoreDetail.cibilSurplus = res.surplus
                    }
                    if (res.remarks != null) {
                        scoreDetail.cibilRemarks = res.remarks
                    }
                    if (res.shortTermCreditCard != null) {
                        scoreDetail.shortTermCreditCard = res.shortTermCreditCard
                    }
                    if (res.income && res.obligations) {
                        this.setState({ dbrValue: (res.obligations / res.income) * 100 })

                    }
                    this.setState({
                        scoreDetail
                    })
                }
            })
    }

    scoreChange(e, type) {
        let scoreDetail = Object.assign({}, this.state.scoreDetail)
        if (type == 'cibilOverdueAccount') {
            scoreDetail.cibilOverdueAccount = e.target.value;
        } else if (type == 'cibilScore') {
            if (e.target.value.includes('-') == false) {
                scoreDetail.cibilScore = e.target.value;
            } else {
                this.setState({ popupState: true, popupStatus: 'Please Enter Only Positive Value' })
            } this.removePopup();
        } else if (type == 'cibilincome') {
            scoreDetail.cibilincome = e.target.value;
            scoreDetail.cibilSurplus = e.target.value - this.state.scoreDetail.cibilObligations
        } else if (type == 'cibilObligations') {
            scoreDetail.cibilObligations = e.target.value;
            scoreDetail.cibilSurplus = this.state.scoreDetail.cibilincome - e.target.value
        } else if (type == 'cibilSurplus') {
            scoreDetail.cibilSurplus = e.target.value;
        } else if (type == 'cibilRemarks') {

            scoreDetail.cibilRemarks = e.target.value;


        } else if (type == 'shortTermCreditCard') {
            scoreDetail.shortTermCreditCard = e.target.value;
        }

        this.setState({
            scoreDetail
        });
        if (this.state.scoreDetail.cibilincome && this.state.scoreDetail.cibilObligations) {
            this.setState({ dbrValue: (this.state.scoreDetail.cibilObligations / this.state.scoreDetail.cibilincome) * 100 })
        }
    }

    scoreHandler(type) {
        const { admin } = this.props;
        // if (type == 'cibil')

            if (this.state.scoreDetail.cibilRemarks !== 'Others') {
                fetchCibilDetail(this.state.scoreDetail, this.state.allDetail, admin,
                    (callBack) => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Cibil Details Saved Successfully',
                                confirmationPopup: false
                            }, () => this.props.getData('yes'))
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again',
                                confirmationPopup: false
                            })
                            this.removePopup();
                        }
                    }
                )
            }
            if (this.state.scoreDetail.cibilRemarks === 'Others') {
                saveDeviationPoint(this.state.cibilRemarks, callBack => {
                }, () => {
                    fetch(APIS.GET_DEVIATION_VALUES)
                        .then(res => res.json())
                        .then(res => {
                            this.setState({ deviationValues: res })
                        })
                })
                let changeData = Object.assign({}, this.state.scoreDetail)
                changeData.cibilRemarks = this.state.cibilRemarks
                fetchCibilDetail(changeData, this.state.allDetail, admin,
                    (callBack) => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Cibil Details Saved Successfully',
                                confirmationPopup: false
                            }, () => this.props.getData('yes'))
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again',
                                confirmationPopup: false
                            })
                            this.removePopup();
                        }
                    }
                )
            }
     
    }
}


export default Score;