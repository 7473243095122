import React, { useRef, useEffect } from "react";
import "../../compo.scss";
import List from "../../../maketingimages/list.png";
function CommunicationModal({ onClose }) {
  const popupRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div
    className="marketing-main-css"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "99.8vh",
        }}
      >
        <div
          ref={popupRef}
          className="commModalBox"
          onClick={() => {
            console.log("ji");
          }}
        >
          <div
            className="mt-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="d-inline-flex">
              <img src={List} alt="" width={18} height={18} />{" "}
              <h4 className="ms-3 leadBreakDown_Header">
                Communication Mode List
              </h4>
            </div>
            <h6 className="disbursedHeader">
              Modes : <span className="disbursedAmountHeader">3</span>
            </h6>
          </div>
          <div className="collection-table mt-4 table-other">
            <table className="table table-hover align-middle text-center">
              <thead>
                <tr className="align-middle">
                  <th className="">Type</th>
                  <th className="">Content</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderBottom: "none" }}>
                  <td scope="col">
                    <span id="comment">
                      <b>Email</b>
                    </span>
                  </td>
                  <td scope="col" className="d-flex-column-start">
                    <h6 className="disbursedHeader">
                      Subject :{" "}
                      <span className="disbursedAmountHeader">
                        Customize Template
                      </span>
                    </h6>
                    <h6 className="disbursedHeader">
                      Body :{" "}
                      <span className="disbursedAmountHeader">
                        {
                          '"Congratulations! Your loan has been approved. Please review and proceed."'
                        }
                      </span>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunicationModal;
