import React from 'react'

const Product = ({ productData, paginationHandler, buttonDisable, editHadler, count }) => (
    <div className='container-fluid' style={{ marginTop: '40px' }}>
        {productData !== '' && productData !== undefined ?
            <div>
                <div className='overflow-auto'>
                    <table className='phocket-table'>
                        <thead>
                            <tr>
                                <th>Effecting From</th>
                                <th>GST Included</th>
                                <th>MAX Loan Amount</th>
                                <th>MIN Loan Amount</th>
                                <th>Old Effected</th>
                                <th>Processing Fee</th>
                                <th> Processing Type</th>
                                
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productData.map((nbfc, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{nbfc.effectingFrom}</td>
                                        <td>{nbfc.gstIncluded === false ? 'No' : nbfc.gstIncluded === true ? 'Yes' : ""}</td>
                                        <td>{nbfc.maxLoanAmount}</td>
                                        <td>{nbfc.minLoanAmount}</td>
                                        <td>{nbfc.oldEffected===false?'No':nbfc.oldEffected===true?'Yes':""}</td>
                                        <td>{nbfc.processingFee}</td>
                                        <td>{nbfc.processingType}</td>
                                      
                                        <td><i className='fa fa-edit' style={{ cursor: 'pointer' }} onClick={() => editHadler(nbfc, 'edit')}></i></td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-5 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                        <div className="col-sm-2 col-xs-12  phoc text-center" >Total: {productData.length}&nbsp; of &nbsp; {count}</div>
                        <div className="col-sm-5 col-xs-12 no-padding"> <button type="button" disabled={buttonDisable} className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
                </div>
            </div>
            : ""}
    </div>
)
export default Product