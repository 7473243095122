import React from 'react';

const SelectSearch = ({ typeInput,myRef, admin, filterHandler, dataFilter, searchByAppNo, searchByemail, searchBymobile }) => (
    <>
        {/* <div className="row">
            <div className="col-xs-12"> */}
                <span className="main-search" >
                    <select className="form-control " style={{ background: '#DBE9FF', fontWeight: '700',width:'150px' }} onChange={e => { filterHandler(e) }}>
                        <option value="applicationNo">Application no</option>
                        {admin && admin.name !== undefined && !(admin.name).includes('Tea') ?
                            <option value="email">Email</option>
                            : ""}
                        {admin && admin.name !== undefined && !(admin.name).includes('Tea') ?
                            <>
                                <option value="mobileNo">Mobile No.</option>

                            </>
                            : ""}
                    </select>
                    <span className="main-search">
                    <input type={typeInput!=='date'?"text":'date'} style={{width:'250px',background:'#fff'}}  ref={myRef} className="form-control" onChange={e => dataFilter(e)} placeholder="Search.." />
                </span>
                </span>
              
            {/* </div>
        </div> */}
    </>
)
export default SelectSearch;