import React, { Component } from 'react'
import PincodeUi from '../../../presentationals/Admin/Utility/BulkNachUpdate.component'
import { csvReader } from '../../../../utils/commonUtility'
import { updateBulkNachStatusAPI } from '../AdminActionCreator.component'
import WarningPopup from '../../../presentationals/Admin/Utility/PincodeWarning.popup'
import Popup from '../../../presentationals/Popup/Popup.component';
import { APIS } from '../../../../utils/api-factory'

class Pincode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvDataArray: [],
            warningPopupState: false,
            popupState: false,
            popupStatus: '',
            FilteredData: [],
            pageIndexToShow: 0,
            csvViewData: []
        }
    }

    componentWillMount() {
        this.getPincode()
    }

    getPincode() {
        // fetch(APIS.GET_PINCODE_DATA)
        //     .then(res => res.json())
        //     .then(res => {
        //         this.setState({
        //             csvViewData: res
        //         })
        //     })
    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : null}
                <PincodeUi
                    handleForce={this.handleForce.bind(this)}
                    saveCsvData={this.saveCsvData.bind(this)}
                    csvViewData={this.state.csvViewData}
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                />
                {this.state.warningPopupState ?
                    <WarningPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)} />
                    : null}
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    handleForce(data) {
        this.setState({
            csvDataArray: csvReader(data)
        })
    };

    saveCsvData() {
        this.setState({ warningPopupState: true })
    }

    cancelLoanConfirm(type) {
        if (type == 'yes') {
            updateBulkNachStatusAPI(this.state.csvDataArray,
                callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            warningPopupState: false,
                            popupState: true,
                            popupStatus: 'CSV uploaded successfully',
                            csvDataArray: []
                        })
                        this.removePopup();
                        this.getPincode();
                    } else {
                        this.setState({
                            warningPopupState: false,
                            popupState: true,
                            popupStatus: 'CSV not uploaded',
                        })
                        this.removePopup();
                    }
                })
        } else if (type == 'no') {
            this.setState({ warningPopupState: false })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
}

export default Pincode;