import React, { Component } from 'react'
import { TYPE } from '../../../../../utils/Constant'
import { APIS } from '../../../../../utils/api-factory';
import PaidDataPopup from '../../../../presentationals/Popup/paidData.component';
import { _formatDateInDash, _preFormatDate } from '../../../../../utils/validation';
import moment from 'moment'
import { paidLoanApi, loanbreakupapi ,giftCardEmiBreakUp,paidGiftCardApi} from '../../AdminActionCreator.component'

class PaidData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PaidData: {
                paidDate: _formatDateInDash(new Date()),
                paidDateToShow: new Date(),
                interestAmount: '',
                loanAmount: '',
                loanTenure: '',
                paidAmount: '',
                penaltyAmount: '',
                processingFees: '',
                finalPaybleAmount: '',
                nachBounceAmount: '',
                chequeBounceAmount: '',
                loanInstallment: '',
                loanApproveAmount: '',
                conversionCharge: '',
                endDate: '',
                flag: false,
                stampDuty: '',
                startDate: '',
                status: '',
                loanEmi: ''
            },
            disabled: false
        }
    }

    componentWillMount() {
        this.getBreakData(this.state.PaidData.paidDate)
    }

    getBreakData(date) {
        const { allDetail, userDetail } = this.props;
        if(userDetail.typeOfProduct == 'Loan'){
        let PaidData = Object.assign({}, this.state.PaidData)
        loanbreakupapi(userDetail, date, (callBack) => {
            if (callBack != 'no data') {
                PaidData.paidDate = date
                PaidData.interestAmount = callBack.interestAmount
                PaidData.loanAmount = callBack.loanAmount
                PaidData.loanTenure = callBack.numberOfDays
                PaidData.paidAmount = callBack.totalAmountPayable
                PaidData.penaltyAmount = callBack.penaltyAmount
                PaidData.processingFees = callBack.processingFees
                PaidData.finalPaybleAmount = callBack.totalAmountPayable
                PaidData.nachBounceAmount = callBack.enachBounceAmount != null ? callBack.enachBounceAmount : ""
                PaidData.chequeBounceAmount = callBack.chequeBounceAmount != null ? callBack.chequeBounceAmount : ""
                PaidData.loanInstallment = callBack.loanInstallment
                PaidData.loanApproveAmount = callBack.loanApproveAmount
                PaidData.conversionCharge = callBack.conversionCharge
                PaidData.endDate = callBack.endDate
                PaidData.flag = callBack.flag
                PaidData.stampDuty = callBack.stampDuty
                PaidData.startDate = callBack.startDate
                PaidData.status = callBack.status
                PaidData.loanEmi = allDetail.loanEmi
            } else {


            }
            this.setState({
                PaidData
            })
        })
    }else if(userDetail.typeOfProduct =='Gift Card'){
        let PaidData = Object.assign({}, this.state.PaidData)
        giftCardEmiBreakUp(userDetail, date, (callBack) => {
            if (callBack != 'no data') {
                PaidData.paidDate = date
                PaidData.interestAmount = callBack.interestAmount
                PaidData.loanAmount = callBack.loanAmount
                PaidData.loanTenure = callBack.numberOfDays
                PaidData.paidAmount = callBack.totalAmountPayable
                PaidData.penaltyAmount = callBack.penaltyAmount
                PaidData.processingFees = callBack.processingFees
                PaidData.finalPaybleAmount = callBack.totalAmountPayable
                PaidData.nachBounceAmount = callBack.enachBounceAmount != null ? callBack.enachBounceAmount : ""
                PaidData.chequeBounceAmount = callBack.chequeBounceAmount != null ? callBack.chequeBounceAmount : ""
                PaidData.loanInstallment = callBack.loanInstallment
                PaidData.loanApproveAmount = callBack.loanApproveAmount
                PaidData.conversionCharge = callBack.conversionCharge
                PaidData.endDate = callBack.endDate
                PaidData.flag = callBack.flag
                PaidData.stampDuty = callBack.stampDuty
                PaidData.startDate = callBack.startDate
                PaidData.status = callBack.status
                PaidData.loanEmi = allDetail.loanEmi
            } else {


            }
            this.setState({
                PaidData
            })
        })
    }
    }

    render() {
        const { automationState, automationData } = this.props
        return (
            <div>
                <div className="background-blur"></div>
                <div className="popup-main container-fluid small-popup dbl-border overflow-auto">
                    <div className="row">
                        {automationState ?
                            automationData != undefined ?
                                <div className="col-sm-4 col-xs-12">
                                    <h4 className="text-center">CSV data</h4>
                                    {Object.keys(automationData).length > 0 ?
                                        Object.keys(automationData).map((head, i) => {
                                            return (
                                                <p key={i}><b>{head}</b>&nbsp;:&nbsp;{automationData[head]}</p>
                                            )
                                        })
                                        : null}
                                </div>
                                : null
                            : null}
                        <div className={(automationState ? "col-sm-8" : "col-sm-12") + " col-xs-12"}>
                            <PaidDataPopup
                                PaidData={this.state.PaidData}
                                closePaidPopup={this.closePaidPopup.bind(this)}
                                paidDataHandler={this.paidDataHandler.bind(this)}
                                paidDataChange={this.paidDataChange.bind(this)}
                                disabled={this.state.disabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    paidDataChange(e, dataType) {
        let PaidData = Object.assign({}, this.state.PaidData)
        if (dataType == TYPE.LOAN_APPROVE_DATE) {
            PaidData.paidDate = _formatDateInDash(e)
            PaidData.paidDateToShow = e
            this.getBreakData(_formatDateInDash(e))
        } else if (dataType == TYPE.INTEREST_AMOUNT) {
            PaidData.interestAmount = e.target.value
        } else if (dataType == TYPE.LOAN_AMOUNT) {
            PaidData.loanAmount = e.target.value
        } else if (dataType == TYPE.LOAN_DAYS) {
            PaidData.loanTenure = e.target.value
        } else if (dataType == TYPE.PAID_AMOUNT) {
            PaidData.paidAmount = e.target.value
        } else if (dataType == TYPE.PENALTY_AMOUNT) {
            PaidData.penaltyAmount = e.target.value
        } else if (dataType == TYPE.PROCESSING_FEES_AMOUNT) {
            PaidData.processingFees = e.target.value
        } else if (dataType == TYPE.FINAL_PAYBLE_AMOUNT) {
            PaidData.finalPaybleAmount = e.target.value
        } else if (dataType == TYPE.NACH_BOUNCE_AMOUNT) {
            PaidData.nachBounceAmount = e.target.value
        } else if (dataType == TYPE.CHEQUE_BOUNCE_AMOUNT) {
            PaidData.chequeBounceAmount = e.target.value
        }
        this.setState({
            PaidData
        })
    }

    paidDataHandler() {
        const { allDetail, admin, userDetail } = this.props;
        this.setState({ disabled: true })
        // if(this.state.paidData != '' && this.state.paidData != undefined){
            if(userDetail.typeOfProduct == 'Loan'){
        paidLoanApi(this.state.PaidData, userDetail, admin,
            callBack => {
                if (callBack != '') {
                    this.closePaidPopup(callBack)
                    this.setState({ disabled: false })
                }
            })
        }else if(userDetail.typeOfProduct == 'Gift Card'){
            paidGiftCardApi(this.state.PaidData, userDetail, admin,
                callBack => {
                    if (callBack != '') {
                        this.closePaidPopup(callBack)
                        this.setState({ disabled: false })
                    }
                })
        }
        // }else{
        //     console.log(this.state.paidData)
        // }
        // fetch(APIS.PAID_STATUS + allDetail.loanId + '&date=' + this.state.PaidData.paidDate + '&empId=' + admin.emailId + '&paidAmount=' + this.state.PaidData.paidAmount)
        //     .then(json => json.text())
        //     .then(res => {
        //         this.closePaidPopup(res)
        //         this.setState({ disabled: false })
        //     })
    }

    closePaidPopup(res) {
        this.props.closePaidPopup(res)
    }
}

export default PaidData;