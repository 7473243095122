import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

function Piechart1({ leadValue, chartNum }) {
  const [data, setData] = useState([]);
  const [chart, setChart] = useState(0);
  const COLORS = [
    "#9747FF",
    "#EEEAFF",
    "#FF9777",
    "#FFEAE4",
    "#FFD54F",
    "#FFFADB",
  ];
  const valve = [40, 0];
  useEffect(() => {
    if (leadValue !== '' && leadValue !== undefined) {
      setData([
        { name: "Group A", value: leadValue },
        { name: "Group B", value: 100 - leadValue },
      ]);
      setChart(chartNum);
    }
   
  }, []);
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (

      <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
        <tspan fontSize="16" fontWeight={600}>{(leadValue.toFixed(2))}</tspan>
      </text>
    );
  };
  return (
    <>
      {leadValue !== '' && leadValue !== 0 ?
        <ResponsiveContainer width='100%' height={150}>
          <PieChart >
            <Pie
              data={data}
              // cx={85}
              // cy={70}
              innerRadius={45}
              outerRadius={60}
              fill="#8884d8"
              paddingAngle={-2}
              dataKey="value"
              label={renderCustomizedLabel}
            >
              {data.map(
                (entry, index) => (

                  (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[chart + index]}
                      cornerRadius={valve[index % valve.length]}
                    />
                  )
                )
              )}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        : ""}
    </>
  );
}

export default Piechart1;
// export default class Example extends PureComponent {
//   constructor(props) {
//     super(props);
//     const { leadValue, chart } = this.props;
//     this.state = {
//       data: [
//         { name: "Group A", value: leadValue },
//         { name: "Group B", value: 100 - leadValue },
//       ],
//       chart: chart,
//     };
//     console.log(this.state);
//     console.log(leadValue);
//     console.log(100 - leadValue);
//   }

//   render() {
//     // console.log(COLORS[chart + 1]);
//     return (

//     );
//   }
// }
