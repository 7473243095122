import React from 'react';

const SelectSearch = ({ myRef, admin, filterHandler, dataFilter, searchByAppNo, searchByemail, searchBymobile }) => (
    <>
        {/* <div className="row">
            <div className="col-xs-12"> */}
        <span className="main-search" >
            <select className="form-control " style={{ background: '#DBE9FF', fontWeight: '700' }} onChange={e => { filterHandler(e) }}>
                <option value="applicationNo">Application no</option>
                {admin && admin.name !== undefined && !(admin.name).includes('Tea') ?
                    <option value="email">Email</option>
                    : ""}
                {admin && admin.name !== undefined && !(admin.name).includes('Tea') ?
                    <>
                        <option value="mobileNo">Mobile No.</option>
                        <option value="name">Name</option>
                        <option value="state">State</option>
                        <option value="device">Device</option>
                    </>
                    : ""}
            </select>
            <span className="main-search">
                <input type="text" ref={myRef} className="form-control" onChange={e => dataFilter(e)} placeholder="Search.." />
            </span>
        </span>

        {/* </div>
        </div> */}
    </>
)
export default SelectSearch;