import React from 'react'
import DatePicker from 'react-datepicker';

const DocsCollection = ({ calcUtility, calcUtilityHandler, disburseDateState,
    repayDateState, paymentRecievedDate, calcUtilityFinalData, calcUtilityData }) => (
        <div className="container-fluid">
            <div className="max-width-500px">
                <div className="row">
                    <h3 className="text-center blue-text">CALCULATOR UTILITY</h3>
                </div>

                <div className="row form-group">
                    <div className="col-xs-12">
                        <label className="font-12px">Amount</label>
                        <input type="number" className="form-control" onChange={(e) => calcUtility(e, 'amount')} />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12">
                        <label className="font-12px">Disburse Date</label>
                        <DatePicker
                            value={calcUtilityData.disburseDateState}
                            // selected={disburseDateState}
                            onChange={(e) => calcUtility(e, 'paidDate')}
                            className="form-control"
                            dateFormat="DD/MM/YYYY"
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12">
                        <label className="font-12px">Repay Date</label>
                        <DatePicker
                            value={calcUtilityData.repayDateState}
                            // selected={repayDateState}
                            onChange={(e) => calcUtility(e, 'repayDate')}
                            className="form-control"
                            dateFormat="DD/MM/YYYY"
                        />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-xs-12">
                        <label className="font-12px">Payment Recieved Date</label>
                        <DatePicker
                            value={calcUtilityData.paymentRecievedDate}
                            // selected={paymentRecievedDate}
                            onChange={(e) => calcUtility(e, 'recievedDate')}
                            className="form-control"
                            dateFormat="DD/MM/YYYY"
                        />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-xs-6">
                        <label className="font-12px block">Cheque Bounce</label>
                        <input className="phocket-checkbox" type="checkbox" id="chequeStatusID" onChange={(e) => calcUtility(e, 'chequeStatus')} />
                        <label htmlFor="chequeStatusID"><span></span></label>&nbsp;&nbsp;
                <label htmlFor="phocketSameResidential" className="">Bounce</label>
                    </div>
                    <div className="col-xs-6">
                        <label className="font-12px block">Stamp Duty</label>
                        <input className="phocket-checkbox" type="checkbox" id="stampStatusID" onChange={(e) => calcUtility(e, 'stampStatus')} />
                        <label htmlFor="stampStatusID"><span></span></label>&nbsp;&nbsp;
                <label htmlFor="phocketSameResidential" className="">YES</label>
                    </div>
                </div>

                <div className="row text-center form-group">
                    <button className="profile-save-btn" id="transSubmitBtn" onClick={e => calcUtilityHandler()}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;GET
            </button>
                </div>
            </div>

            {calcUtilityFinalData != '' && calcUtilityFinalData != null ?
                <div className="row blue-text">
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">chequeBounceAmount</label>
                        {calcUtilityFinalData.chequeBounceAmount != null ? calcUtilityFinalData.chequeBounceAmount : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">enachBounceAmount</label>
                        {calcUtilityFinalData.enachBounceAmount != null ? calcUtilityFinalData.enachBounceAmount : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">endDate</label>
                        {calcUtilityFinalData.endDate != null ? calcUtilityFinalData.endDate : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">interestAmount</label>
                        {calcUtilityFinalData.interestAmount != null ? calcUtilityFinalData.interestAmount : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">loanAmount</label>
                        {calcUtilityFinalData.loanAmount != null ? calcUtilityFinalData.loanAmount : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">numberOfDays</label>
                        {calcUtilityFinalData.numberOfDays != null ? calcUtilityFinalData.numberOfDays : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">penaltyAmount</label>
                        {calcUtilityFinalData.penaltyAmount != null ? calcUtilityFinalData.penaltyAmount : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">processingFees</label>
                        {calcUtilityFinalData.processingFees != null ? calcUtilityFinalData.processingFees : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">stampDuty</label>
                        {calcUtilityFinalData.stampDuty != null ? calcUtilityFinalData.stampDuty : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">startDate</label>
                        {calcUtilityFinalData.startDate != null ? calcUtilityFinalData.startDate : ""}</div>
                    <div className="col-sm-3 col-xs-12 form-group">
                        <label className="block">totalAmountPayable</label>
                        {calcUtilityFinalData.totalAmountPayable != null ? calcUtilityFinalData.totalAmountPayable : ""}</div>
                </div>
                : ""}
        </div>
    )

export default DocsCollection;