import React, { Component } from 'react';
import { PieChart, Pie, Legend, Tooltip, Sector, Cell, } from 'recharts';
import DatePicker from 'react-datepicker';
import Workbook from 'react-excel-workbook'
import Dotloader from '../Loader/DotLoader.component'

const data01 = [
  { name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 },
];
const data02 = [
  { name: 'A1', value: 1 },
  { name: 'A2', value: 1 },
  { name: 'B1', value: 1 },

];
function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


const AppDownload = ({ generateReportState, graphdata, refreshpage, errorMsg, filterDataChange, loadarState, allCampiainName, filterDataHandler, getallState, getexceldata,
  leadDashFilterData }) => (
    <div className="container-fluid">
      <h2 className="blue-text text-center">Tracked Records</h2>
      <div className="row form-group">
        <div className="col-xs-12 col-sm-2">
          <div className="font-12px height-25px validation-color">
            {errorMsg != "" ? errorMsg : ""}
          </div>
          {generateReportState?
          <button className="profile-save-btn margin-0" onClick={e => getexceldata()}><span className="fa fa-download"></span>&nbsp;&nbsp;Excel</button>
     :""}
          </div>
        <div className="col-xs-12 col-sm-2">
          <label className="font-12px">Select State</label>
          <select className="form-control" onChange={e => filterDataChange(e, "statewise")}>
            <optgroup className="display-none">
              <option value="">Select State</option>
            </optgroup>
            <optgroup label="State">
              {getallState != null && getallState != '' ? getallState.map((state, i) => {
                return (
                  <option key={i} value={state}>{state}</option>
                )
              })
                : null}
            </optgroup>
          </select>
        </div>
        <div className="col-xs-12 col-sm-2">
          <label className="font-12px">Select Company</label>
          <select className="form-control" onChange={e => filterDataChange(e, "campainUrl")} >
            <optgroup className="display-none">
              <option value="">Select Company</option>
            </optgroup>
            <optgroup label="Company">
              {allCampiainName != null && allCampiainName != "" ? allCampiainName.map(data => {
                return (
                  <option key={data.id} value={data.campaignUrl}>{data.campaignUrl}</option>
                )
              }) : ""}
            </optgroup>
          </select>

          <div className="col-xs-12 text-center">
            <button className="profile-save-btn" id="" onClick={e => filterDataHandler()} >
              <span className="fa fa-filter"></span>&nbsp;&nbsp;FILTER
                    </button>

            <button className="admin-pagination-btn" onClick={e => refreshpage()} ><span className="fa fa-refresh fa-spin"></span></button>
            {loadarState ? <Dotloader /> : ""}
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 padding-0">
          <div className="col-xs-12 col-sm-6">
            <label className="font-12px">Start date</label>
            <DatePicker
              className="form-control"
              dateFormat="DD-MM-YYYY"
              selected={leadDashFilterData.startDateToShow}
              value={leadDashFilterData.startDate}
              onChange={(e) => filterDataChange(e, "fromDate")}
              placeholderText="Select Start Date"
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="font-12px">End date</label>
            <DatePicker
              className="form-control"
              dateFormat="DD-MM-YYYY"
              selected={leadDashFilterData.endDateToShow}
              value={leadDashFilterData.endDate}
              onChange={(e) => filterDataChange(e, "tillDate")}
              placeholderText="Select End Date"
            />
          </div>

        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 col-xs-12">
          {graphdata.length > 0 ? graphdata.map((data, i) => {
            return (
              <div className="col-xs-6" key={i}>
                <div className="track-box">
                  <h3>{data.value}</h3>
                  <h5>{data.name}</h5>
                </div>

              </div>
            )
          }) : ""}



        </div>
        <div className="col-sm-6 col-xs-12">
          {graphdata.length > 0 ?
            <PieChart width={400} height={400}>
              <Pie data={graphdata} dataKey="value" cx={200} cy={200} outerRadius={70} fill="#8884d8" />
              <Pie data={graphdata} dataKey="value" cx={200} cy={200} innerRadius={80} outerRadius={100} fill={getRandomColor()} label>
                {
                  graphdata.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>


            : ""}
        </div>
      </div>
    </div >

  )
export default AppDownload;

