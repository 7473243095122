import React from 'react';
import DotLoader from '../../Loader/layerLoader.component'
import MainLoader from '../../Loader/Loader.component'
import DatePicker from 'react-datepicker';

const PhocketCustomerDetails = ({ dataFilter, tableMaxPagination, tableMinPagination,
    forwordpage, backpage, allUserDetail, loadarState, refreshpage, dateToShow,
    dateToSend, phocketTablePagination, nachType, sendNachMail, mainLoaderState, CommentChange, CommentHandler, finterChangeHandler,
    mendateVerification, searchHandler }) => (
    <div className="container-fluid">
        <div className="row">
            <h3 className="text-center blue-text">Nach Registration Report Billdesk</h3>
        </div>
        <div className="row form-group">
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Date</label>
                <DatePicker
                    value={dateToSend}
                    selected={dateToShow}
                    onChange={e => dataFilter(e, "date")}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                    // isClearable={true}
                    maxDate={new Date()}
                />
            </div>
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px">Search By Email Id</label>
                <input placeholder='Enter Email Id' className='form-control' onChange={(e) => searchHandler(e)} />
            </div>
            {/* <div className="col-sm-3 col-xs-12">
                <h3 className="blue-text text-center text-capitalize">{nachType}</h3>
            </div> */}
            <div className="col-sm-3 col-xs-12 text-right">
                <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh fa-spin" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button>
            </div>
        </div>

        <div className="row text-center">
            <div className="col-xs-12 overflow-x-auto no-padding">
                {loadarState == true ?
                    <DotLoader />
                    : ""}
                {loadarState === false && Array.isArray(allUserDetail) ? allUserDetail.length > 0 ?
                    <div>
                        <div className="overflow-x-auto">
                            <table className="phocket-table">
                                <thead>
                                    <tr>
                                        <th>Mandate Status</th>
                                        <th>Message</th>
                                        {nachType == 'failure' ?
                                            <th>Send Mail</th>
                                            : null}
                                        {nachType == 'ongoing' ?
                                            <th>Verification</th>
                                            : null}
                                        {/* <th>Application Id</th> */}
                                        {/* <th>Loan Id</th> */}
                                        {nachType == 'failure' ?
                                            <th>Payment Id</th>
                                            : null}
                                        <th>Email ID</th>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                        {/* <th>Status</th>
                                        <th>PayBack Date</th> */}
                                        {/* {nachType == 'failure' ?
                                            <th>Error</th>
                                            : null} */}
                                        <th>E-Nach Mode</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ color: data.billDeskMandateStatus === 'active' ? 'green' : 'red' }}>{data.billDeskMandateStatus}</td>
                                                <td style={{ color: data.errorMessage === 'Mandate Successful' ? 'green' : 'red' }}>{data.errorMessage}</td>
                                                {/* {nachType == 'failure' ?
                                                    <td>
                                                        {data.modeOfENach == 'RazorPay' ?
                                                            <button className="btn profile-save-btn margin-0" onClick={e => sendNachMail(data, 'razorPay')}>
                                                                <span className="fa fa-share" />
                                                            </button> :
                                                            <button className="btn profile-save-btn margin-0" onClick={e => sendNachMail(data, 'techProcess')}>
                                                                <span className="fa fa-share" />
                                                            </button>}
                                                    </td>
                                                    : null}
                                                {nachType == 'ongoing' ?
                                                    <td>
                                                        {data.modeOfENach == 'RazorPay' ?
                                                            <button className="btn profile-save-btn margin-0" onClick={e => mendateVerification(data, 'rajzorPay')}>Verify</button>
                                                            : <button className="btn profile-save-btn margin-0" onClick={e => mendateVerification(data, 'techprocess')}>Verify</button>}
                                                    </td>
                                                    : null} */}
                                                {/* <td>{data.applicationId}</td> */}
                                                {/* <td>{data.applicationLoanId}</td> */}
                                                {/* {nachType == 'failure' ?
                                                    <td>
                                                        {data.modeOfENach == 'RazorPay' ?
                                                            <div className="display-flex">
                                                                <input type="text" className="app-comment-box" onChange={e => CommentChange(e)} placeholder="type here..." />
                                                                <span className="fa fa-save loan-id-save-btn" onClick={e => CommentHandler(data.userId)} />
                                                            </div>
                                                            : null}
                                                    </td>
                                                    : null} */}
                                                <td>{data.emailId}</td>
                                                <td style={{ whiteSpace: 'break-spaces' }}>{data.userName}</td>
                                                <td>{data.mobileNumber}</td>
                                                {/* <td>{data.applicationStatus}</td> */}
                                                {/* <td>{data.loanPaybackDate != null ? data.loanPaybackDate.split(' ')[0] : null}</td> */}
                                                {/* {nachType == 'failure' ?
                                                    <td>{data.errorMessage}</td>
                                                    : null} */}
                                                <td>{data.modeOfENach}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block grey  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                                <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                                <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block grey" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                            </div>
                        </div>
                    </div>
                    : null : loadarState === false ? <h3 className="blue-text text-center">No data found</h3> : ""}

                {mainLoaderState ?
                    <MainLoader />
                    : null}
            </div>
        </div>
    </div>
)

export default PhocketCustomerDetails;
