import React from 'react';



const Scoring = ({ remarks, openConfirmation, scoreChange, scoreHandler, scoreDetail, dbrValue, flagData }) => (
    <div className="" style={{ padding: '10px' }}>
        <div style={{ background: '#DBE9FF', padding: '10px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}><b>Cibil Scoring</b></div>

        <table className="scoring-table">
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>  No. of Overdue Accounts</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{scoreDetail.cibilOverdueAccount}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Income</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{scoreDetail.cibilincome}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Obligations</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{scoreDetail.cibilObligations}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>Surplus</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{scoreDetail.cibilSurplus}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>KCC/STL</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{scoreDetail.shortTermCreditCard}</td>
            </tr>
            <tr>
                <td style={{ borderTop: 'none', borderRight: 'none' }}>DBR</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{dbrValue}</td>
            </tr>
            {scoreDetail.cibilRemarks && Object.keys(scoreDetail.cibilRemarks).length > 0 ? (
                Object.entries(scoreDetail.cibilRemarks)
                    .slice(0, 5)
                    .map((data, i) => (
                        <tr key={i}>
                            <td style={{ borderTop: 'none', borderRight: 'none' }}>
                                Remark {i + 1}
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none',textAlign:'right' }}>
                                {data[1]}
                            </td>
                        </tr>
                    ))
            ) : (
                <tr>
                    <td style={{ borderTop: 'none', borderRight: 'none' }}>
                        <span>Remarks</span>
                    </td>
                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>
                        {remarks !== '' ? remarks : ""}
                    </td>
                </tr>
            )}

            <tr>
                <td style={{ borderTop: 'none', borderBottomLeftRadius: '10px', borderRight: 'none' }}>Cibil Score</td>
                <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right', borderBottomRightRadius: '10px' }}>{scoreDetail.cibilScore}</td>
            </tr>
        </table>

        {/* <div className="row">
            <div className=" col-xs-12">
                <div className="">
                    <h5 ><b>Cibil Scoring</b></h5>
                 <div style={{borderBottom:'1.5px solid #7D6FD9',marginBottom:'10px'}}></div>
                    <div className="row">
                        <div className="col-sm-12 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>No. of overdue accounts/Total accounts&nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilOverdueAccount} </h5>
                        </div>

                    </div>
                    <div className='row'>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>CIBIl score&nbsp;</b>
                            <br />
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilScore} </h5>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Income&nbsp;</b>
                            <br />
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilincome} </h5>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Obligations&nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilObligations} </h5>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Surplus &nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilSurplus} </h5>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>CC/STL &nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.shortTermCreditCard} </h5>
                        </div>
                        <div className='col-sm-4 col-xs-12 form-group'>
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>DBR &nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{dbrValue} </h5>

                        </div>
                    </div>
                   
                    <div className="row">

                        <div className="col-sm-12 col-xs-12 form-group">
                            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Remarks&nbsp;</b>
                            <h5 style={{fontWeight:'700'}}>{scoreDetail.cibilRemarks} </h5>
                        </div>

                    </div>
                  
                </div>
            </div>
        </div> */}
    </div>
)

export default Scoring;
