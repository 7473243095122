import React, { Component } from 'react'
import EmployeeActivity from '../../../presentationals/Admin/Settings/EmployeeActivity.component'
import FilterBody from '../Settings/EmployeeOverallFilter.container'

class ActivityEmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterState: false,

        }
    }

    render() {
        return (
            <div className="">
                <EmployeeActivity
                    filterHandler={this.filterHandler.bind(this)}
                />
                {this.state.filterState == true ?
                    <FilterBody filterHandler={this.filterHandler.bind(this)} />
                    : ""}
            </div>
        )
    }
    filterHandler() {
        this.setState({
            filterState: !this.state.filterState,
        })
    }



}

export default ActivityEmp;