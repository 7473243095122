import React from 'react'
import Ui from '../../../presentationals/Admin/Settings/AssignViaCsv.component'
import { bulkAssignViaCSV } from '../AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import Popup from '../../../presentationals/Popup/Popup.component';


class AsiignViaCsv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            nameOfFile: '',
            loaderState: false,
            popupState:false,
            popupStatus:''
            
        }
    }
    closePopup(){
        this.setState({popupState:false})
    }
    removePopup(){
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    changeHandler(e) {
        this.setState({ file: e.target.files[0], nameOfFile: e.target.files[0].name })
    }
    submitHandler() {
        if (this.state.file !== '' && this.state.file !== null && this.state.file !== undefined) {
            this.setState({ loaderState: true })
            bulkAssignViaCSV(this.state.file, callback => {
                // console.log(callback)
                Loader
                if (callback === 'success') {
                    this.setState({ loaderState: false,popupState:true,popupStatus:'Assigned Successfully!',nameOfFile:'',file:'' },()=>this.removePopup())
                }else{
                    this.setState({ loaderState: false,popupState:true,popupStatus:'please try again later!' },()=>this.removePopup())

                }
            })
        }else{
            this.setState({ popupState:true,popupStatus:'please select file!' },()=>this.removePopup())

        }
    }
    render() {
        return (
            <div>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <Ui
                    changeHandler={this.changeHandler.bind(this)}
                    submitHandler={this.submitHandler.bind(this)}
                    nameOfFile={this.state.nameOfFile} />
                    {this.state.popupState?
                    <Popup closePopup={this.closePopup.bind(this)} popupStatus={this.state.popupStatus}/>
                :""}
            </div>
        )
    }
}
export default AsiignViaCsv