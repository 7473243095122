import React from 'react'

const Dataclass = ({ printDiv, csvData, csvDataNew }) => (

    <div className='income' >
        <button onClick={() => printDiv()}>PRINT</button>
        <div className='container-fluid' id='pdf-tea'>
            {csvDataNew !== undefined && csvDataNew !== null && csvDataNew !== '' ? Object.entries(csvDataNew.data).map((data, i) => {
                let gv = data[0].split(',')
                let header = gv[2]
                let header1 = gv[0]
                let dateToShow = gv[1]

                return (
                    <div key={i} style={{ pageBreakAfter: 'always' }}>
                        <div className='row' style={{ fontSize: '10px' }}>
                            <div className='col-sm-12 col-xs-12' >
                                <div>
                                    <div style={{ textAlign: 'center' }}><b style={{ fontWeight: '700', color: '#000' }}>KHONGEA TEA ESTATE</b></div>
                                    <div style={{ textAlign: 'center' }}><b style={{ fontWeight: '700', color: '#000' }}>{dateToShow}</b></div>
                                    <div style={{ textAlign: 'center' }}><b style={{ fontWeight: '700', color: '#000' }}>{header1}</b></div>

                                </div>
                                <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Labour Line<br />
                                        Gender<br />
                                        ManId<br />
                                        <span style={{ display: 'flex', position: 'absolute' }}> {header}</span>
                                        <br />
                                        <b>{Object.keys(data[1]).includes('Male') ?

                                            <div style={{ position: 'absolute' }}>Male Side</div>
                                            : Object.keys(data[1]).includes('Female') ?
                                                <div style={{ position: 'absolute' }}>Female Side</div> : ""}
                                        </b>
                                        <br />
                                        <b>{Object.keys(data[1])}</b></div>
                                    <div style={{ width: '600px', borderTop: '3px solid grey', marginRight: '5px' }}>Labour Name</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>P.F. No.</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Pay Day</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Wages<br />
                                        MA Earn<br />
                                        SI Earn</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>E.L.P.<br />
                                        <br />
                                        Oth. Ern</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Incentive<br />Allowance</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Tot. Ern </div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Pro. Fund<br />
                                        Food Conc<br />
                                        Subs.
                                    </div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>PFAdvance<br />PFAdv Int.<br />L.W.F./Puja</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Medical Adv.<br />General Adv<br />Electric</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Ration<br />Tot. Ded</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Note B/F<br />Coin B/F</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Note C/F<br />Coin C/F</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Net Amt.<br />Sign.</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>EMI<br /> AMOUNT</div>
                                    <div style={{ width: '110px', borderTop: '3px solid grey', marginRight: '5px' }}>Net <br />PAYABLE<br />AMOUNT</div>

                                </div>
                            </div>
                            <div className='col-sm-12 col-xs-12' style={{ borderTop: '3px solid grey', fontSize: '12px', fontFamily: 'cursive' }}>
                                <div >

                                    {Object.entries(data).map((uju, kj) => {
                                        return (
                                            typeof (uju) === 'object' ?
                                                <div >

                                                    {Object.entries(uju).map((hj, lk) => {
                                                        // console.log(hj[1].slice(0,10))
                                                        return (
                                                            Object.entries(hj[1]).map((cv, bb) => {

                                                                return (
                                                                    cv !== null ? cv.map((nj, vb) => {
                                                                        return (

                                                                            Object.entries(nj).map((nj, bn) => {

                                                                                return (
                                                                                    <div >
                                                                                        {typeof (nj[1]) === 'object' ?

                                                                                            <div style={{ borderBottom: '1px dotted grey' }}>
                                                                                                <div className='' ><b>{nj[0]}</b>

                                                                                                    {nj[1].map((gb, cx) => {

                                                                                                        return (
                                                                                                            <div style={{ marginLeft: '30px' }} className=''>
                                                                                                                {cx === 0 ?

                                                                                                                    <div style={{ display: 'flex' }}> <div style={{ width: '220px', fontWeight: '700', whiteSpace: 'nowrap' }}><div style={{ fontSize: '12px' }}>{gb[0]}</div></div> <div style={{ width: '50px', marginLeft: '180px', fontWeight: '700' }}>{gb[1]}</div> <div style={{ fontSize: '10px', marginLeft: '2%', width: '20px' }}>{gb[2]}</div> <div style={{ fontSize: '10px', marginLeft: '3%', width: '62px' }}>{gb[3]}</div> <div style={{ fontSize: '10px', marginLeft: '3%', width: '50px' }}>{gb[4]}</div> <div style={{ fontSize: '10px', marginLeft: '1%', width: '50px' }}>{gb[5]}</div> <div style={{ fontSize: '10px', marginLeft: '1%', width: '50px' }}>{gb[6]}</div> <div style={{ fontSize: '10px', marginLeft: '1%' }}>{gb[7]}</div> <div style={{ fontSize: '10px', marginLeft: '3%', width: '50px' }}>{gb[8]}</div> <div style={{ fontSize: '10px', marginLeft: '3%' }}>{gb[9]}</div> <div style={{ fontSize: '10px', marginLeft: '4%', width: '50px' }}>{gb[10]}</div> <div style={{ fontSize: '10px', marginLeft: '1%', width: '50px' }}>{gb[11]}</div> <div style={{ fontSize: '10px', marginLeft: '1%', width: '50px' }}>{gb[12]}</div> <div style={{ fontSize: '10px', marginLeft: '3%', width: '85px' }}>{gb[13]}</div> <div style={{ fontSize: '10px', marginLeft: '1%', width: '50px' }}><b>{gb[14]}</b></div> <div style={{ fontSize: '10px', marginLeft: '1%', width: '50px' }}><b>{gb[15]}</b></div></div>
                                                                                                                    :
                                                                                                                    cx === 1 ?

                                                                                                                        <div style={{ display: 'flex' }}> <div style={{ width: '220px', fontWeight: '700', whiteSpace: 'nowrap', fontSize: '10px' }}>{gb[0]}</div> <div style={{ width: '50px', marginLeft: '180px', fontWeight: '700' }}>{gb[1]}</div> <div style={{ marginLeft: '2%', width: '20px' }}>{gb[2]}</div> <div style={{ marginLeft: '3%', width: '62px' }}>{gb[3]}</div> <div style={{ marginLeft: '3%', width: '50px' }}>{gb[4]}</div> <div style={{ marginLeft: '1%', width: '50px' }}>{gb[5]}</div> <div style={{ marginLeft: '1%', width: '50px' }}>{gb[6]}</div> <div style={{ marginLeft: '1%' }}>{gb[7]}</div> <div style={{ marginLeft: '3%', width: '50px' }}>{gb[8]}</div> <div style={{ marginLeft: '3%' }}>{gb[9]}</div> <div style={{ marginLeft: '4%', width: '50px' }}>{gb[10]}</div> <div style={{ marginLeft: '1%', width: '50px' }}>{gb[11]}</div> <div style={{ marginLeft: '1%', width: '50px' }}>{gb[12]}</div> <div style={{ marginLeft: '3%', width: '85px' }}>{gb[13]}</div> <div style={{ marginLeft: '1%', width: '50px' }}><b>{gb[14]}</b></div> <div style={{ marginLeft: '1%', width: '50px' }}><b>{gb[15]}</b></div></div>
                                                                                                                        :
                                                                                                                        cx === 2 ?
                                                                                                                            <div>
                                                                                                                                <div style={{ display: 'flex' }}> <div style={{ width: '225px', whiteSpace: 'nowrap', fontSize: '10px' }}>{gb[0]}</div>  <div style={{ width: '245px', textAlign: 'right' }}>{gb[3]}</div> <div style={{ marginLeft: '222px' }}>{gb[7]}</div><div style={{ marginLeft: '55px' }}>{gb[8]}</div><div style={{ marginLeft: '68px' }}>{gb[9]}</div> </div>
                                                                                                                            </div>
                                                                                                                            : ""}

                                                                                                            </div>

                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                            : ""}
                                                                                    </div>
                                                                                )

                                                                            })

                                                                        )
                                                                    }) : ""
                                                                )
                                                            })
                                                        )
                                                    })}

                                                </div>
                                                : ""
                                        )

                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={{}}>
                            {Object.entries(csvDataNew.summary).map((lk, ss) => {
                                return (
                                    <React.Fragment >

                                        {data[0] === (lk[0]) ? Object.entries(lk[1]).map((xc, sc) => {
                                            return (
                                                Object.entries(xc).map((ax, za) => {
                                                    return (
                                                        Object.entries(ax[1]).map((nb, vb) => {

                                                            return (
                                                                <React.Fragment >
                                                                    {nb[0] === 'ATTENDANCE' || nb[0] === 'EARNINGS' || nb[0] === 'DEDUCTIONS' ?
                                                                        <React.Fragment>

                                                                            {nb[0] === 'ATTENDANCE' ?


                                                                                <div style={{ marginBottom: '5px', fontSize: '12px', pageBreakBefore: 'always' }}>
                                                                                    <div>
                                                                                        <br />
                                                                                        <br />
                                                                                        <div style={{}}><b> {nb[0]}</b></div>
                                                                                    </div>
                                                                                    {Object.entries(nb[1]).map((bn, vc) => {
                                                                                        return (
                                                                                            <div style={{ display: 'flex', marginLeft: '20px' }}>
                                                                                                <div style={{ marginBottom: '-1px' }}><b>{(bn[0])} &nbsp;:-</b></div><div style={{ marginLeft: bn[0] === 'Hajira (Haj.))' ? '131px' : '60px', marginTop: '10px' }}>{bn[1]}</div>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                                : ""}

                                                                        </React.Fragment>

                                                                        : ""}
                                                                </React.Fragment>
                                                            )

                                                        })
                                                    )
                                                })
                                            )
                                        }) : ""}
                                    </React.Fragment>
                                )
                            })}
                            <div style={{ display: 'flex', fontSize: '12px' }}>
                                {Object.entries(csvDataNew.summary).map((lk, ss) => {
                                    return (
                                        <React.Fragment>

                                            {data[0] === (lk[0]) ? Object.entries(lk[1]).map((xc, sc) => {
                                                return (
                                                    Object.entries(xc).map((ax, za) => {
                                                        return (
                                                            Object.entries(ax[1]).map((nb, vb) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        {nb[0] === 'EARNINGS' || nb[0] === 'DEDUCTIONS' ?
                                                                            <React.Fragment>


                                                                                {(nb[0] === 'EARNINGS' || nb[0] === 'DEDUCTIONS') && nb[0] !== '' ?
                                                                                    <div className='' >
                                                                                        <div
                                                                                        >
                                                                                            {nb[0] === 'EARNINGS' ?
                                                                                                <div>
                                                                                                    <div>
                                                                                                        <div style={{}}><b> {nb[0]}</b></div>
                                                                                                    </div>
                                                                                                    {Object.entries(nb[1]).map((bn, vc) => {
                                                                                                        return (
                                                                                                            <div style={{ display: 'flex', marginLeft: '20px' }}>
                                                                                                                <div style={{ width: '250px', }}><b>{(bn[0])} &nbsp;:-</b></div><div style={{ marginTop: '10px', marginLeft: '150px' }} >{bn[1]}</div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                                : ""}
                                                                                            <div >

                                                                                                {nb[0] === 'DEDUCTIONS' ?
                                                                                                    <div style={{ marginLeft: '50px' }}>
                                                                                                        <div>
                                                                                                            <div style={{}}><b> {nb[0]}</b></div>
                                                                                                        </div>
                                                                                                        {Object.entries(nb[1]).map((bn, vc) => {
                                                                                                            return (
                                                                                                                <div style={{ display: 'flex', marginLeft: '20px' }}>
                                                                                                                    <div style={{ width: '250px', }}><b style={{ borderTop: bn[0] === 'NET PAYABLE' ? '1px solid black' : "", borderBottom: bn[0] === 'NET PAYABLE' ? '1px solid black' : "", marginTop: bn[0] === 'NET PAYABLE' ? '10px' : "", marginBottom: '10px' }}>{(bn[0])} &nbsp;:-</b></div><div style={{ marginLeft: '150px' }} ><span style={{ borderTop: bn[0] === 'NET PAYABLE' ? '2px solid black' : "", borderBottom: bn[0] === 'NET PAYABLE' ? '2px solid black' : "", marginTop: bn[0] === 'NET PAYABLE' ? '10' : "", marginBottom: bn[0] === 'NET PAYABLE' ? '10' : "" }}>{bn[1]}</span></div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}
                                                                                                    </div>
                                                                                                    : ""}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    : ""}
                                                                            </React.Fragment>

                                                                            : ""}
                                                                    </React.Fragment>
                                                                )

                                                            })
                                                        )
                                                    })
                                                )
                                            }) : ""}
                                        </React.Fragment>

                                    )
                                })}
                            </div>
                            <div style={{ width: '300px', marginTop: '30px', fontSize: '12px', pageBreakInside: 'always' }}>
                                {Object.entries(csvDataNew.summary).map((lk, ss) => {
                                    return (
                                        <React.Fragment>

                                            {data[0] === (lk[0]) ? Object.entries(lk[1]).map((xc, sc) => {
                                                return (
                                                    Object.entries(xc).map((ax, za) => {
                                                        return (
                                                            Object.entries(ax[1]).map((nb, vb) => {

                                                                return (
                                                                    <React.Fragment>
                                                                        {nb[0] === 'Notes of Rs.' ?
                                                                            <React.Fragment>

                                                                                {nb[0] === 'Notes of Rs.' ?
                                                                                    <div style={{ fontSize: '12px' }}>

                                                                                        <div style={{ fontSize: '12px' }}>
                                                                                            <table className='' style={{ width: '100%' }}>
                                                                                                <thead>
                                                                                                    {Object.entries(nb[1]).map((bn, vc) => {

                                                                                                        return (

                                                                                                            <tr key={vc} style={{ fontSize: '12px' }} >
                                                                                                                <th style={{ fontSize: '12px', width: '150px', border: '1px dashed grey ', borderRight: 'none', borderBottom: bn[0] === 'TOTAL' ? '1px dashed grey' : 'none' }}>{bn[0]}</th>
                                                                                                                <td style={{ fontSize: '12px', width: '150px', border: '1px dashed grey', borderTop: bn[1] === 'Rs.' ? '1px dashed grey' : 'none' }}>{bn[1]}</td>
                                                                                                            </tr>

                                                                                                        )
                                                                                                    })}
                                                                                                </thead>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>

                                                                                    : ""}

                                                                            </React.Fragment>

                                                                            : ""}

                                                                    </React.Fragment>
                                                                )

                                                            })
                                                        )
                                                    })
                                                )
                                            }) : ""}
                                        </React.Fragment>
                                    )
                                })}


                            </div>
                            <div >
                            </div>
                        </div>
                    </div>
                )
            }) : ""}

        </div>
    </div >
)

export default Dataclass