import React, { useState } from 'react'
import Emailsample from '../../../../maketingimages/Screenshot 2024-04-05 141645.png'
// import Emailsample from '../../../../maketingimages/Screenshot 2023-09-11 133602.png'
import CSVSAMPLE from '../../../../maketingimages/Sample CSV.csv'
import MobileCsv from '../../../../maketingimages/mobile.csv'

import {
    bulkCommTabNames as tabNames,
    aggregatorDetailsTabName,
} from "../../../constans";
import { HeaderType, TemplateId, MSG_STRING } from '../../../../../../presentationals/Admin/BulkActions/MessageTemplates.component';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { bulkCommunicateByCsvMObileApi, notificationImageUploadApi } from '../../../../../Admin/AdminActionCreator.component';
import { bulkCommunicateByCsvApiNew } from '../../../actioncreator';
import Loader from '../../../../../../presentationals/Loader/Loader.component'
import SidePopup from '../../modals/sidepopup';
import MobileSample from '../../../../maketingimages/Screenshot 2023-09-13 114951.png'
import { getSaveEmailTemplates } from '../../../../../Admin/Utility/communicationTemplate/apiCom';
const BycsvMainpage = ({ admin }) => {
    const [tab, setTab] = useState("sms");
    const [msgBody, setMsgBody] = useState('')
    const [mailBody, setmailBody] = useState('')
    const [subject, setsubject] = useState('')
    const [notificationBody, setNotificationBody] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [notificationSubject, setNotoficationSubject] = useState('')
    const [popupState, setpopupState] = useState('')
    const [popupType, setpopupType] = useState('')
    const [msg, setmsg] = useState('')

    const [templateId, settemplateIdTosend] = useState('')
    const [msgTypeHeader, setmessageType] = useState('')
    const [uploadedCSV, setuploadedCSV] = useState('')
    const [csvFile, setcsvFile] = useState('')
    const [typeOfFile, settypeOfFile] = useState('')
    const [notificationImage, setNotificationImage] = useState('')
    const [nameOfFile, setNameOfFile] = useState('')
    const [type, setType] = useState([])
    const [loaderState, setLoaderState] = useState(false)
    const [emailTemplates, setEmailTemplates] = useState('')

    const [data, setData] = useState({
        template: "",
        body: "",
        subject: "",
    });
    const [tabType, settabType] = useState('email')
    const tabHandler = (type) => {
        settabType(type)
    }
    const downloadsample = () => {

        fetch(tabType === 'email' ? CSVSAMPLE : MobileCsv)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = 'Sample';
                link.click();
            })
            .catch(console.error);

    }
    const sendNotification = () => {
        if (csvFile !== '' && csvFile !== undefined) {
            setLoaderState(true)
            bulkCommunicateByCsvApiNew(csvFile, ['notification'], 0, notificationImage, notificationBody, notificationSubject, admin, '', '', callBack => {
                if (callBack === 'success') {
                    setpopupState(true)
                    setpopupType('success')
                    setmsg('Successfully sent !')
                    removePopup()
                    setLoaderState(false)
                }
            })
        } else {
            setpopupState(true)
            setpopupType('error')
            setmsg('Select Csv File !')
            removePopup()

        }
    }
    const notificationHandler = (e, type) => {
        if (type === 'url') {
            settypeOfFile(e.target.files[0].type)
            setNameOfFile(e.target.files[0].name)
            let val = e.target.value.split(/[\\\/]/)[2];
            setNotificationImage(val)
            let File = e.target.files[0];
            let docType = val.split(".");
            let fileType = docType[docType.length - 1];
            if (fileType == "jpeg" || fileType == "jpg" || fileType == "png") {
                setLoaderState(true)
                notificationImageUploadApi(File, val, e.target.files[0].type,
                    (callBack) => {
                        setNotificationImage(callBack)
                        setLoaderState(false)
                        // this.setState({
                        //     notificationImage: callBack
                        // })
                    });
            }
        }
        if (type === 'subject') {
            setNotoficationSubject(e.target.value)
        }
        if (type === 'body') {
            setNotificationBody(e)
        }

    }
    const sendHandlerMsg = () => {
        if (csvFile !== '' && csvFile !== undefined) {
            setLoaderState(true)
            // bulkCommunicateByCsvApiNew(csvFile, ['Message'], 0, '', msgBody, '', admin, templateId, msgTypeHeader, callBack => {
            //     if (callBack === 'success') {
            //         setpopupState(true)
            //         setpopupType('success')
            //         setmsg('Successfully sent !')
            //         removePopup()
            //         setLoaderState(false)
            //     }
            // })
            bulkCommunicateByCsvMObileApi(
                csvFile,
                0,
                msgBody,
                admin,
                templateId,
                msgTypeHeader, callBack => {
                    if (callBack === 'success') {
                        setpopupState(true)
                        setpopupType('success')
                        setmsg('Successfully sent !')
                        removePopup()
                        setLoaderState(false)
                    }

                })
            //    messageType === 'Promotional' ? 'Promotional' : 'Phocket',

        } else {
            setpopupState(true)
            setpopupType('error')
            setmsg('Select Csv File !')
            removePopup()

        }
    }
    const smsTemplateHandle = (e) => {
        if (e.target.value === '') {
            setMsgBody('')
            settemplateIdTosend('')
            setmessageType('')
        }
        if (e.target.value == 'REVIEW_ON_PLAYSTORE') {
            setMsgBody(MSG_STRING.REVIEW_ON_PLAYSTORE)
            settemplateIdTosend(TemplateId.REVIEW_ON_PLAYSTORE)
            setmessageType(HeaderType.REVIEW_ON_PLAYSTORE)
        }

        if (e.target.value == 'MONTH_END_SAL_ADVANCE') {
            setMsgBody(MSG_STRING.MONTH_END_SAL_ADVANCE)
            settemplateIdTosend(TemplateId.MONTH_END_SAL_ADVANCE)
            setmessageType(HeaderType.MONTH_END_SAL_ADVANCE)
        }
        if (e.target.value == 'BILL_PAY_SAL_ADVANCE') {
            setMsgBody(MSG_STRING.BILL_PAY_SAL_ADVANCE)
            settemplateIdTosend(TemplateId.BILL_PAY_SAL_ADVANCE)
            setmessageType(HeaderType.BILL_PAY_SAL_ADVANCE)
        }
        if (e.target.value == 'BORROWBUDDY_APP_TRY_KARO') {
            setMsgBody(MSG_STRING.BORROWBUDDY_APP_TRY_KARO)
            settemplateIdTosend(TemplateId.BORROWBUDDY_APP_TRY_KARO)
            setmessageType(HeaderType.BORROWBUDDY_APP_TRY_KARO)
        }
        if (e.target.value == 'JHATPAT_LOAN') {
            setMsgBody(MSG_STRING.JHATPAT_LOAN)
            settemplateIdTosend(TemplateId.JHATPAT_LOAN)
            setmessageType(HeaderType.JHATPAT_LOAN)
        }
        if (e.target.value == 'FESTIVE_SHOPPING_LOAN') {
            setMsgBody(MSG_STRING.FESTIVE_SHOPPING_LOAN)
            settemplateIdTosend(TemplateId.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC)
            setmessageType(HeaderType.FESTIVE_SHOPPING_LOAN)
        }
        if (e.target.value == 'FESTIVE_SHOPPING_LOAN') {
            setMsgBody(MSG_STRING.FESTIVE_SHOPPING_LOAN)
            settemplateIdTosend(TemplateId.FESTIVE_SHOPPING_LOAN)
            setmessageType(HeaderType.FESTIVE_SHOPPING_LOAN)
        }
        if (e.target.value == 'LOAN_WITHIN_FEW_MINUTES') {
            setMsgBody(MSG_STRING.LOAN_WITHIN_FEW_MINUTES)
            settemplateIdTosend(TemplateId.LOAN_WITHIN_FEW_MINUTES)
            setmessageType(HeaderType.LOAN_WITHIN_FEW_MINUTES)
        }
        if (e.target.value == 'LAST_CHANCE_LOAN') {
            setMsgBody(MSG_STRING.LAST_CHANCE_LOAN)
            settemplateIdTosend(TemplateId.LAST_CHANCE_LOAN)
            setmessageType(HeaderType.LAST_CHANCE_LOAN)
        }
        if (e.target.value == 'UPTO_200000_LOAN') {
            setMsgBody(MSG_STRING.UPTO_200000_LOAN)
            settemplateIdTosend(TemplateId.UPTO_200000_LOAN)
            setmessageType(HeaderType.UPTO_200000_LOAN)
        }
        if (e.target.value == 'LOAN_WAITING') {
            setMsgBody(MSG_STRING.LOAN_WAITING)
            settemplateIdTosend(TemplateId.LOAN_WAITING)
            setmessageType(HeaderType.LOAN_WAITING)
        }
        if (e.target.value == 'LOAN_AT_LOW_INTEREST') {
            setMsgBody(MSG_STRING.LOAN_AT_LOW_INTEREST)
            settemplateIdTosend(TemplateId.LOAN_AT_LOW_INTEREST)
            setmessageType(HeaderType.LOAN_AT_LOW_INTEREST)

        }
        if (e.target.value == 'MONEY_AT_SPEED_OF_ROCKET') {
            setMsgBody(MSG_STRING.MONEY_AT_SPEED_OF_ROCKET)
            settemplateIdTosend(TemplateId.MONEY_AT_SPEED_OF_ROCKET)
            setmessageType(HeaderType.MONEY_AT_SPEED_OF_ROCKET)
        }
        if (e.target.value == 'ADVANCE_SALARY_VIA_BANKBAZAAR') {
            setMsgBody(MSG_STRING.ADVANCE_SALARY_VIA_BANKBAZAAR)
            settemplateIdTosend(TemplateId.ADVANCE_SALARY_VIA_BANKBAZAAR)
            setmessageType(HeaderType.ADVANCE_SALARY_VIA_BANKBAZAAR)
        }
        if (e.target.value == 'URGENT_CASH') {
            setMsgBody(MSG_STRING.URGENT_CASH)
            settemplateIdTosend(TemplateId.URGENT_CASH)
            setmessageType(HeaderType.URGENT_CASH)
        }
        if (e.target.value == 'LOAN_WITHIN_5_MINUTES') {
            setMsgBody(MSG_STRING.LOAN_WITHIN_5_MINUTES)
            settemplateIdTosend(TemplateId.LOAN_WITHIN_5_MINUTES)
            setmessageType(HeaderType.LOAN_WITHIN_5_MINUTES)
        }
        if (e.target.value == 'BANKBAZAAR') {
            setMsgBody(MSG_STRING.BANKBAZAAR)
            settemplateIdTosend(TemplateId.BANKBAZAAR)
            setmessageType(HeaderType.BANKBAZAAR)
        } if (e.target.value == 'SALARY_12_MONTH') {
            setMsgBody(MSG_STRING.SALARY_12_MONTH)
            settemplateIdTosend(TemplateId.SALARY_12_MONTH)
            setmessageType(HeaderType.SALARY_12_MONTH)
        }
        if (e.target.value == 'GET_A_BORROWBUDDY_PL') {
            setMsgBody(MSG_STRING.GET_A_BORROWBUDDY_PL)
            settemplateIdTosend(TemplateId.GET_A_BORROWBUDDY_PL)
            setmessageType(HeaderType.GET_A_BORROWBUDDY_PL)
        }
        if (e.target.value == 'ADVANCE_SAL_BY_BUDDYLOAN') {
            setMsgBody(MSG_STRING.ADVANCE_SAL_BY_BUDDYLOAN)
            settemplateIdTosend(TemplateId.ADVANCE_SAL_BY_BUDDYLOAN)
            setmessageType(HeaderType.ADVANCE_SAL_BY_BUDDYLOAN)
        }
        if (e.target.value == 'PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY') {
            setMsgBody(MSG_STRING.PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY)
            settemplateIdTosend(TemplateId.PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY)
            setmessageType(HeaderType.PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY)
        }
        if (e.target.value == 'BORROWBUDDY_BANKBAZAAR') {
            setMsgBody(MSG_STRING.BORROWBUDDY_BANKBAZAAR)
            settemplateIdTosend(TemplateId.BORROWBUDDY_BANKBAZAAR)
            setmessageType(HeaderType.BORROWBUDDY_BANKBAZAAR)
        }
        if (e.target.value == 'LOAN_FOR_15000_SALARY') {
            setMsgBody(MSG_STRING.LOAN_FOR_15000_SALARY)
            settemplateIdTosend(TemplateId.LOAN_FOR_15000_SALARY)
            setmessageType(HeaderType.LOAN_FOR_15000_SALARY)
        }
        if (e.target.value == 'BorrowBuddy_App_partnered_with_Anjraj_Talent') {
            setMsgBody(MSG_STRING.BorrowBuddy_App_partnered_with_Anjraj_Talent)
            settemplateIdTosend(TemplateId.BorrowBuddy_App_partnered_with_Anjraj_Talent)
            setmessageType(HeaderType.BorrowBuddy_App_partnered_with_Anjraj_Talent)
        }
        if (e.target.value == 'Dear_Customer_Refer_your_friends') {
            setMsgBody(MSG_STRING.Dear_Customer_Refer_your_friends)
            settemplateIdTosend(TemplateId.Dear_Customer_Refer_your_friends)
            setmessageType(HeaderType.Dear_Customer_Refer_your_friends)
        }
        if (e.target.value == 'BorrowBuddy_App_partnered_with_Genesis') {
            setMsgBody(MSG_STRING.BorrowBuddy_App_partnered_with_Genesis)
            settemplateIdTosend(TemplateId.BorrowBuddy_App_partnered_with_Genesis)
            setmessageType(HeaderType.BorrowBuddy_App_partnered_with_Genesis)
        }
        if (e.target.value == 'BorrowBuddy_App_partnered_with_Hyrexpert') {
            setMsgBody(MSG_STRING.BorrowBuddy_App_partnered_with_Hyrexpert)
            settemplateIdTosend(TemplateId.BorrowBuddy_App_partnered_with_Hyrexpert)
            setmessageType(HeaderType.BorrowBuddy_App_partnered_with_Hyrexpert)
        }
        if (e.target.value == 'BorrowBuddy_App_partnered_with_Karyarth') {
            setMsgBody(MSG_STRING.BorrowBuddy_App_partnered_with_Karyarth)
            settemplateIdTosend(TemplateId.BorrowBuddy_App_partnered_with_Karyarth)
            setmessageType(HeaderType.BorrowBuddy_App_partnered_with_Karyarth)
        }
        if (e.target.value == 'Borrowbuddy_App_partnered_with_SAM_Career') {
            setMsgBody(MSG_STRING.Borrowbuddy_App_partnered_with_SAM_Career)
            settemplateIdTosend(TemplateId.Borrowbuddy_App_partnered_with_SAM_Career)
            setmessageType(HeaderType.Borrowbuddy_App_partnered_with_SAM_Career)
        }
        if (e.target.value == 'PHONEPARLOAN') {
            setMsgBody(MSG_STRING.PHONEPARLOAN)
            settemplateIdTosend(TemplateId.PHONEPARLOAN)
            setmessageType(HeaderType.PHONEPARLOAN)
        }
        if (e.target.value == 'REPEAT_CAMPAIGN') {
            setMsgBody(MSG_STRING.REPEAT_CAMPAIGN)
            settemplateIdTosend(TemplateId.REPEAT_CAMPAIGN)
            setmessageType(HeaderType.REPEAT_CAMPAIGN)
        }
        if (e.target.value == 'TWICE_APPROVED_AMOUNT') {
            setMsgBody(MSG_STRING.TWICE_APPROVED_AMOUNT)
            settemplateIdTosend(TemplateId.TWICE_APPROVED_AMOUNT)
            setmessageType(HeaderType.TWICE_APPROVED_AMOUNT)
        }
        if (e.target.value == 'MAXIMIZE_APPROVED_AMOUNT') {
            setMsgBody(MSG_STRING.MAXIMIZE_APPROVED_AMOUNT)
            settemplateIdTosend(TemplateId.MAXIMIZE_APPROVED_AMOUNT)
            setmessageType(HeaderType.MAXIMIZE_APPROVED_AMOUNT)
        }
    }
    const sendButtonMail = () => {
        if (csvFile !== '' && csvFile !== undefined) {
            setLoaderState(true)
            bulkCommunicateByCsvApiNew(csvFile, type, 0, '', mailBody, subject, admin, '', '', callBack => {
                if (callBack === 'success') {
                    setpopupState(true)
                    setpopupType('success')
                    setmsg('Successfully sent !')
                    removePopup()
                    setLoaderState(false)
                }
            })
        } else {
            setpopupState(true)
            setpopupType('error')
            setmsg('Select Csv File !')
            removePopup()

        }

    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
        }, 10000);
    }
    const mailHandler = (e, type) => {
        if (type === 'subject') {
            setsubject(e.target.value)
        }
        if (type === 'body') {
            setmailBody(e)
        }
        setType(['mail'])

    }
    const changeTab = (value) => {
        setData({
            template: "",
            body: "",
            subject: "",
        });
        setTab(value);



    };
    const handleChange = () => {

    }
    const uploadCsv = (e) => {
        let val = e.target.value.split(/[\\\/]/)[2];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (fileType == 'csv') {
            setuploadedCSV(val)
            setcsvFile(e.target.files[0])
        }
        let File = e.target.files[0];
    }
    const templateHandler = (e) => {
        if (e.target.value !== '') {
            const searchTerm = e.target.value.toLowerCase();

            const filteredArray = emailTemplates.filter(item =>
                item.id.toString().toLowerCase().includes(searchTerm) &&
                item.type && item.type.includes('Bulk Communication')
            );

            setsubject(filteredArray[0].subject)
            setmailBody(filteredArray[0].body)

        }
    };
    useEffect(() => {
        getSaveEmailTemplates(callback => {
            setEmailTemplates(callback)
        })
    }, [])
    return (
        <div className='container-fluid marketing-css marketing-main-css'>
            <div className="" style={{ marginTop: '30px' }}>
                <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
                    Bulk Communicate Via
                </div>
                <div onClick={() => tabHandler('email')} className={tabType === 'email' ? "col-sm-2 col-xs-4 communication-tab-active-left" : "col-sm-2 col-xs-4 communication-tab-left"}>Email Id</div>
                <div onClick={() => tabHandler('mobile')} className={tabType === 'mobile' ? "col-sm-2 col-xs-4 communication-tab-active-right" : "col-sm-2 col-xs-4 communication-tab-right"}>Mobile Number</div>
            </div>

            <div className='row' style={{ marginTop: '80px' }}>
                <div className='col-sm-6 col-xs-12'>
                    {/* <div className='cards-shadow ' style={{ padding: '15px', marginTop: '43px' }}>
                        <div className="upload-main" style={{ background: 'rgba(219, 233, 255, 0.70)' }}>
                            <div className="image-upload-div" >
                                <input
                                    style={{ padding: '20px' }}
                                    accept={['.png', '.jpeg', '.jpg']}
                                    className="file-upload-input" type='file' />
                                <div className="drag-text-new" style={{ marginTop: '10px', color: 'rgba(0, 0, 0, 0.40)' }}>
                                    <h5 className="upload-icon" style={{ backgroundPosition: '30%' }}> Click here to Upload Logo </h5>
                                </div>
                            </div>
                        </div>
                        <button
                            title="SEND"
                            type="button"
                            className="bulkCommSendBtn"
                            style={{ width: '100px', marginTop: '20px' }}
                        >
                            Send
                        </button>
                    </div> */}
                    <div className="bulkCommTabsContainer">
                        <div
                            className="bulkCommTabs"
                            style={{
                                backgroundColor: tab === tabNames.email ? "#1D3557" : "#F2F2FA",
                                borderRadius: "12px 0px 0px 12px",
                                color: tab === tabNames.email ? "#FFFFFF" : "#000000",
                            }}
                            onClick={() => changeTab(tabNames.email)}
                        >
                            Email
                        </div>
                        <div
                            className="bulkCommTabs"
                            style={{
                                backgroundColor: tab === tabNames.sms ? "#1D3557" : "#F2F2FA",
                                color: tab === tabNames.sms ? "#FFFFFF" : "#000000",
                            }}
                            onClick={() => changeTab(tabNames.sms)}
                        >
                            SMS
                        </div>
                        <div
                            style={{
                                backgroundColor:
                                    tab === tabNames.notification ? "#1D3557" : "#F2F2FA",
                                borderRadius: "0px 12px 12px 0px",
                                color: tab === tabNames.notification ? "#FFFFFF" : "#000000",
                            }}
                            className="bulkCommTabs"
                            onClick={() => changeTab(tabNames.notification)}
                        >
                            Notification
                        </div>
                    </div>

                    {tab === tabNames.email ? (
                        <div className="mt-4">
                            <div>
                                <div className="inputTitle">Select Email Template</div>
                                <select
                                    name="template"
                                    onChange={(e) => templateHandler(e)}
                                    id=""
                                    className="form-select"
                                    style={{
                                        minWidth: "165px",
                                        backgroundColor: "rgba(219, 233, 255, 0.70)",
                                        borderRadius: "6px",
                                    }}
                                >
                                    <option value="SMS Template">Customize Email Template</option>
                                    {emailTemplates !== '' ? emailTemplates.map((data, i) => {
                                        if ((data.type).includes('Bulk Communication')) {
                                            return (
                                                <option value={data.id}>{data.templateName}</option>
                                            )
                                        }
                                    })
                                        : ""}
                                </select>
                            </div>

                            <div style={{ width: "100%", marginTop: "1rem" }}>
                                <div className="inputTitle" style={{ marginBottom: "0.3rem" }}>
                                    Mail Box
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        margin: "auto",
                                        backgroundColor: "rgba(219, 233, 255, 0.70)",
                                        borderRadius: "6px",
                                        border: "none",
                                        padding: "20px",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            margin: "auto",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        <div
                                            className="inputTitle"
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            Subject
                                        </div>
                                        <input
                                            type="text"
                                            id="subject"
                                            onChange={(e) => mailHandler(e, 'subject')}
                                            value={subject}
                                            name="subject"
                                            placeholder="Enter Subject"

                                            style={{
                                                width: "100%",
                                                border: "#FFFFFF",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                backgroundColor: "#FFFFFF",
                                                marginTop: "0rem",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            margin: "auto",
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <div
                                            className="inputTitle"
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            Mail Body
                                        </div>
                                        <ReactQuill
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                                marginTop: "0rem",
                                            }}
                                            name="body"
                                            theme="snow"
                                            value={mailBody}
                                            // onChange={(value) => handleBody(value)}
                                            onChange={(e) => mailHandler(e, 'body')}
                                        // value={subje
                                        />
                                    </div>
                                </div>
                            </div>

                            <button
                                title="SEND"
                                onClick={() => sendButtonMail()}
                                type="button"
                                className="bulkCommSendBtn"
                            >
                                Send Mail
                            </button>
                        </div>
                    ) : tab === tabNames.sms ? (
                        <div className="mt-4">
                            <div>
                                <div className="inputTitle">Select SMS Template</div>
                                <select
                                    name="template"
                                    onChange={(e) => smsTemplateHandle(e)}
                                    id=""
                                    className="form-select"
                                    style={{
                                        minWidth: "165px",
                                        backgroundColor: "rgba(219, 233, 255, 0.70)",
                                        borderRadius: "6px",
                                    }}
                                >
                                    <option value="">SMS Template</option>
                                    {Object.entries(TemplateId).map((data, i) => {
                                        return (
                                            <option value={data[0]}>{data[0]}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {msgBody ?
                                <div style={{ width: "100%", marginTop: "1rem" }}>
                                    <div className="inputTitle" style={{ marginBottom: "0.3rem" }}>
                                        SMS Body
                                    </div>

                                    <textarea
                                        value={msgBody}
                                        rows="6"
                                        readOnly={true}
                                        style={{
                                            width: "100%",
                                            backgroundColor: "rgba(219, 233, 255, 0.70)",
                                            borderRadius: "6px",
                                            border: "none",

                                        }}
                                    ></textarea>
                                </div>
                                : ""}
                            {msgBody ?
                                <button
                                    title="SEND"
                                    onClick={() => sendHandlerMsg()}
                                    type="button"
                                    className="bulkCommSendBtn"
                                    style={{ width: '140px' }}
                                >
                                    Send Message
                                </button>
                                : ""}
                        </div>
                    ) : (
                        <div className="mt-4">
                            <div>
                                <div className="inputTitle">Select Notification Template</div>
                                <select
                                    name="template"
                                    id=""
                                    className="form-select"
                                    style={{
                                        minWidth: "165px",
                                        backgroundColor: "rgba(219, 233, 255, 0.70)",
                                        borderRadius: "6px",
                                    }}
                                >
                                    <option value="SMS Template">
                                        Customize Notification Template
                                    </option>
                                </select>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    margin: "auto",
                                    marginBottom: "1rem",
                                    marginTop: '10px'
                                }}
                            >
                                <div
                                    className="inputTitle"
                                    style={{ marginBottom: "0.5rem" }}
                                >
                                    Subject
                                </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="subject"
                                    onChange={(e) => notificationHandler(e, 'subject')}
                                    value={notificationSubject}
                                    name="subject"
                                    placeholder="Enter Subject"


                                />
                            </div>
                            <div style={{ width: "100%", marginTop: "1rem" }}>
                                <div className="inputTitle" style={{ marginBottom: "0.3rem" }}>
                                    Notification Body
                                </div>
                                <div>
                                    <ReactQuill
                                        style={{
                                            backgroundColor: "#FFFFFF",
                                            marginTop: "0rem",

                                        }}
                                        name="body"
                                        theme="snow"
                                        value={notificationBody}
                                        onChange={(value) => notificationHandler(value, 'body')}
                                    />
                                </div>

                            </div>
                            <div style={{ width: "100%", marginTop: "1rem" }}>

                                <div className="image-upload-div" style={{ borderRadius: '5px' }} >
                                    <input
                                        onChange={(value) => notificationHandler(value, 'url')}
                                        accept={['.png', '.jpeg', '.jpg']}
                                        className="file-upload-input" type='file' />
                                    <div className="drag-text-new">
                                        <h5 className="upload-icon">{nameOfFile !== '' ? nameOfFile : 'Click here to Upload Image'} </h5>
                                    </div>
                                </div>
                            </div>
                            <button
                                title="SEND"
                                style={{ width: '150px' }}
                                onClick={() => sendNotification()}
                                type="button"
                                className="bulkCommSendBtn"
                            >
                                Send Notification
                            </button>
                        </div>
                    )}
                </div>





                <div className='col-sm-6 col-xs-12'>
                    <div style={{ borderRadius: '12px', border: '1px solid rgba(0, 0, 0, 0.10)', background: '#FAFAFA', padding: '10px' }}>
                        <div className=' ' style={{ padding: '15px', marginTop: '10px' }}>
                            <div className="upload-main" style={{ background: 'rgba(219, 233, 255, 0.70)' }}>
                                <div className="image-upload-div" >
                                    <input
                                        onChange={(e) => uploadCsv(e)}
                                        style={{ padding: '20px' }}
                                        accept={['.csv']}
                                        className="file-upload-input" type='file' />
                                    <div className="drag-text-new" style={{ marginTop: '10px', color: 'rgba(0, 0, 0, 0.40)' }}>
                                        <h5 className="upload-icon" style={{ backgroundPosition: '30%' }}> upload CSV </h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <h4 style={{ marginLeft: '35px' }}><strong>CSV Sample File </strong></h4>
                        <img src={tabType === 'email' ? Emailsample : MobileSample} style={{ width: '100%' }} />
                        <div style={{ color: '#FF772B', background: '#FFF2EA', padding: '10px', borderRadius: '5px', marginTop: '10px', marginBottom: '20px', width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>Please ensure that the CSV file with data of Employee should be in this format.</div>
                        <div className='text-center'>
                            <button onClick={() => downloadsample()} className='btn btn-primary'><i class="fa fa-download" aria-hidden="true"></i>&nbsp;&nbsp;Download CSV Sample File </button></div>
                    </div>
                </div>
            </div>
            {popupState ?

                <SidePopup msg={msg}
                    popupType={popupType} />
                : ""}
            {loaderState ?
                <Loader />
                : ""}
        </div>


    )
}

export default BycsvMainpage