import React, { Component } from 'react'
import PromotionalDataPage from '../../../presentationals/Admin/Promotions/PromotionalData.component'
import { getPromotionalData, getFilterData, SendPromotional } from '../AdminActionCreator.component';
let emailData = []
class PromotionalData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            EmailSendId: '',
            data: '',
            FilterData: {
                city: "null",
                experience: "null",
                salary: "null"
            },
            getFilterData: ''
        }
    }
    componentWillMount() {
        getFilterData((res => {
            { res.splice(0, 0, "City") }
            this.setState({ getFilterData: res })
        }));
    }

    NextHandler() {
        //this.state.pageNumber=this.state.pageNumber+1;

        this.setState({ pageNumber: this.state.pageNumber + 1 })
        getPromotionalData(this.state.FilterData, this.state.pageNumber, (res => {
            this.setState({ data: res })
        }));
    }
    PreviousHandler() {
        if (this.state.pageNumber >= 2) {

            this.setState({ pageNumber: this.state.pageNumber - 1 })
            getPromotionalData(this.state.FilterData, this.state.pageNumber, (res => {
                this.setState({ data: res })
            }));
        }
    }

    getPromotionalData(Info, e) {
        if (Info === "City") {
            this.state.FilterData.city = e.target.value

        } else if (Info === "Salary") {
            this.state.FilterData.salary = e.target.value
        } else {
            this.state.FilterData.experience = e.target.value
        }

    }


    /* emailSend(e,data){
        if(e.target.checked){
            emailData.push(data.email)
        }else{
            var index = emailData.indexof(data.email)
            emailData.splice(index,1)
        }
    }

    emailSendAll(e,data){
        if(e.target.checked){
            data.map((data,i)=>{
                emailData.push(data.email)
                if(document.getElementById(i)!=null){
                    document.getElementById(i).checked=true
                }
            })
        }else{
            emailData = []
            data.map(i=>{
                if(document.getElementById(i)!=null){
                    document.getElementById(i).checked=false
                }
            })
            
            
        }

        


    } */

    SendOnSubmit(Type, e) {
        SendPromotional(this.state.FilterData, Type);
    }

    render() {
        return (
            <PromotionalDataPage
                getFilterData={this.state.getFilterData}
                data={this.state.data}
                NextHandler={this.NextHandler.bind(this)}
                PreviousHandler={this.PreviousHandler.bind(this)}
                getPromotionalData={this.getPromotionalData.bind(this)}
                getDataByFilter={this.NextHandler.bind(this)}
                SendOnSubmit={this.SendOnSubmit.bind(this)}
            />
        )
    }
}

export default PromotionalData;