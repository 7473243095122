import React from 'react'

const RecoveryNotification = ({ recoveryNotificationChange, recoveryNotificationHandler }) => (
    <div className="container-fluid  max-width-500px">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Notification</h3>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                <input type="text" className="form-control" placeholder="Subject" onChange={e => recoveryNotificationChange(e, 'subject')}></input>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12">
                <div className="richText" contentEditable="true" id="recoveryNotificationBody"></div>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12">
                <button className="profile-save-btn center-block" onClick={e => recoveryNotificationHandler()}>SEND</button>
            </div>
        </div>
    </div>
)

export default RecoveryNotification;