import React from 'react'

const CategoryAdd = ({ merchantData, postDataHandler, ulCategoryData, listingOfCategory,
    getDataHandler, MerchantValidationMsg, deleteCategory }) => (
        <div className="container-fluid max-width-750px">
            <div className="row form-group">
                <h3 className="text-center">Add Category Details</h3>
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Promo Category Name</label>
                    <input type="text" className="form-control" placeholder="Enter Promo Category Name" id="promoCatName" value={merchantData.promoCatName} onChange={e => getDataHandler(e, "promoCatName")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "promoCatName" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Promo Category Status</label>
                    <select className="form-control" id="promoCatStatus" onChange={e => getDataHandler(e, "promoCatStatus")} value={merchantData.promoCatStatus ? 'Active' : 'Inactive'}>
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "promoCatStatus" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row">
                <button className="profile-save-btn center-block" id="promoCategoryAdd" onClick={e => postDataHandler()}>
                    <span className="fa fa-plus"></span>&nbsp;&nbsp;ADD
            </button>
            </div>
            <div className="row">
                {listingOfCategory != null && listingOfCategory != '' && listingOfCategory.length>0?
                    <table className="text-center loan-detail-table max-width-500px">
                        <thead>
                            <tr className="background-blue text-white">
                                <th>ID</th>
                                <th>CATEGORY</th>
                                <th>status</th>
                                <th>delete</th>
                            </tr>
                        </thead>
                        {listingOfCategory.map((listing,i) => {
                            return (
                                <tbody key={i}>
                                    {listing.Category != null && listing.Category.length > 0 ? listing.Category.map((data, i) => {
                                        return (
                                            <tr className="pointer text-black" onClick={e => ulCategoryData(e, data)} key={i}>
                                                <td>{data.categoryid}</td>
                                                <td>{data.category}</td>
                                                <td>{data.status == true ? 'Active' : 'Inactive'}</td>
                                                <td><span className="fa fa-trash" onClick={e => deleteCategory(data)} /></td>
                                            </tr>
                                        )
                                    }) : ""}
                                </tbody>
                            )
                        })}
                    </table>
                    : ""}
            </div>
        </div>
    )

export default CategoryAdd;