import React from 'react'
import BulkUi from '../../../presentationals/Admin/BulkActions/BulkNachBounceByCsv.component'
import { bulkNachBounceByCsv } from '../../Admin/AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component';
let File = ''

class BulkUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadedCSV: '',
            csvFile: '',
            validationMsg: '',
            sidePopUp: false,
            popupStatus:'',
            fileName:''
        }
    }
    uploadcsvHandler(e) {
        
        let val = e.target.value.split(/[\\\/]/)[2];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (fileType == 'csv') {
            this.setState({
                uploadedCSV: val,
                csvFile: e.target.files[0],
                validationMsg:''
            })

        }else{
            this.setState({ validationMsg: 'Please Select only CSV file only' })
        }
        File = e.target.files[0];
    }

    render() {
        return (
            <div>
                <BulkUi
                fileName={this.state.fileName}
                    uploadcsvHandler={this.uploadcsvHandler.bind(this)}
                    uploadedCSV={this.state.uploadedCSV}
                    saveCsvNach={this.saveCsvNach.bind(this)}
                    validationMsg={this.state.validationMsg}
                />
                {this.state.sidePopUp ?
                    <Popup
                    popupStatus={this.state.popupStatus}
                    closePopup={this.closePopup.bind(this)}
                    />
                    : ""}
            </div>
        )
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ sidePopUp: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ sidePopUp: false });
    }
    saveCsvNach() {
        if (this.state.csvFile != '') {
            this.setState({ validationMsg: '' })
            bulkNachBounceByCsv(this.state.csvFile, callback => {
                if(callback == 'success'){
                this.setState({sidePopUp:true,popupStatus:'Successfully Done!',uploadedCSV:''},()=>this.removePopup())
                }else{
                    this.setState({popupStatus:'Please Try Again Later!',sidePopUp:true,uploadedCSV:''},()=>this.removePopup())
                }
            })
        } else {
            this.setState({ validationMsg: 'Select Csv File First' })

        }
    }
}
export default BulkUpdate