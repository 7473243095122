import React from 'react';
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const AllCreditScore = ({ allCreditScoreData, creditViewHandler,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Credit Score</h3>
            </div>

        </div>

        <div className="row text-center">
            <div className="col-xs-12 overflow-x-auto no-padding">
        
                {allCreditScoreData != null && allCreditScoreData != '' && allCreditScoreData.length > 0 ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <td></td>
                                    <td>Application ID</td>
                                    <td>Name</td>
                                    <td>{'CA > 1 YR'}</td>
                                    <td>CA Owned</td>
                                    <td>CA = Adhar</td>
                                    <td>CA = PA</td>
                                    <td>Company Catagory</td>
                                    <td>{'EXP > 1 YR'}</td>
                                    <td>{'EXP > 3 YR'}</td>
                                    <td>SALARY TIME</td>
                                    <td>EMI</td>
                                    <td>EXISTING LOAN</td>
                                    <td>EMI DEFAULTS</td>
                                    <td>BALANCE</td>
                                    <td>TRANSACTIONS</td>
                                    <td>Total</td>
                                </tr>

                            </thead>
                            <tbody>
                                {allCreditScoreData && allCreditScoreData.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td> <button onClick={e => creditViewHandler(data.loanId)}><span className="fa fa-window-restore" /></button></td>
                                            <td> {data.applicationId}</td>
                                            <td>{data.customerName}</td>
                                            <td>{data.caMorethanOneYear}</td>
                                            <td> {data.caOwned}</td>
                                            <td>{data.caSameAsAdhaarAddress}</td>
                                            <td>{data.caSameAsPa}</td>
                                            <td>{data.company_Category}</td>
                                            <td>{data.currentCompanyWorkExpMorethanOneyear}</td>
                                            <td>{data.workExpMorethanthreeyear}</td>
                                            <td>{data.salaryOnTime}</td>
                                            <td>{data.emiratio}</td>
                                            <td>{data.existingLoan}</td>
                                            <td>{data.defaultEvident}</td>
                                            <td>{data.openingClosingBalance}</td>
                                            <td>{data.highValueTransaction}</td>
                                            <td>{data.total_rating}</td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : ""}
            </div>
        </div>
    </div>
)

export default AllCreditScore;
