import React, { useState } from 'react'
import LosLogo from '../../../../images/rewampimages/LOS.svg'
import LmsLogo from '../../../../images/rewampimages/LMS.svg'
import FinanceLogo from '../../../../images/rewampimages/finance.svg'
import MarketingLogo from '../../../../images/rewampimages/marketing.svg'
import CollectionLogo from '../../../../images/rewampimages/Collection.svg'
import AccessmanagementLogo from '../../../../images/rewampimages/accessmanagement.svg'
import DashboardLogo from '../../../../images/rewampimages/dashboardreport.svg'
import ToolsLogo from '../../../../images/rewampimages/tools.svg'
import NbfcLogo from '../../../../images/rewampimages/nbfc.svg'
import { ROLE } from '../../../../utils/Constant'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CircleIcon from '@mui/icons-material/Circle';
// const Landingpage = ({ pageViewHandler, admin }) => {
//     const [lmsSubTab, setlmsTab] = useState(false)
//     const subHandleTab = (first, second) => {

//         if (second === 'lms' && first === 'bulkOps') {
//             setlmsTab(!lmsSubTab)
//         }
//     }
class Landingpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lmsSubTab: false,
            generateReportState: true,
            nbfcTabs: false,
            productTab: false,
            policyTab: false,
            policyTabTech: false,
            lendingProduct: false,
            targetTab: false
        }
    }
    componentDidMount() {
        const { admin } = this.props
        if (admin.emailId !== undefined && admin.role != "superuser" && admin.emailId !== 'vishnu.pandey@phocket.in' && admin.emailId !== 'sunil.kumar@phocket.in' && admin.emailId !== 'ravinder.sharma@phocket.in' && admin.emailId !== 'mohit.bansal@phocket.in' && admin.emailId !== 'priyanka.kumari@phocket.in' && admin.emailId !== 'piyush.jain@phocket.in' && admin.emailId !== 'rakesh.rawat@phocket.in') {
            var today = new Date().getHours();
            if (today > 19) {
                console.log(today, 'Hour')
            }
            if (today < 9) {
                console.log(today, 'Hours')
            }
            if (today > 9 && today < 19) {
                console.log(today)
                this.setState({ generateReportState: false })
            }
        }
    }





    subHandleTab(e, first, second) {
        e.stopPropagation()
        if (second === 'lms' && first === 'bulkOps') {
            // setlmsTab(!lmsSubTab)
            this.setState({ lmsSubTab: !this.state.lmsSubTab })
        }
    }
    subHandleTabproduct(e, first, second) {
        e.stopPropagation()

        this.setState({ productTab: !this.state.productTab })

    }
    nbfcTabsHandle() {
        this.setState({ nbfcTabs: !this.state.nbfcTabs })


    }
    subHandleTabPolicy(e) {
        e.stopPropagation()

        this.setState({ policyTab: !this.state.policyTab })
    }
    subHandleTech(e) {

        e.stopPropagation()

        this.setState({ policyTabTech: !this.state.policyTabTech })
    }
    subHandleTechLending(e) {

        e.stopPropagation()

        this.setState({ lendingProduct: !this.state.lendingProduct })
    }
    subHandleTabTarget(e) {
        e.stopPropagation()

        this.setState({ targetTab: !this.state.targetTab })
    }
    render() {
        const { admin, pageViewHandler } = this.props
        return (
            <>
                {admin && admin.rolelist !== undefined ?
                    <div className='container-fluid blue-scroll'>
                        <div className='row'>
                            {console.log(window.innerWidth)}
                            {admin.rolelist != undefined && (admin.rolelist.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0 || admin.rolelist.indexOf(ROLE.APPLICATION_STATUS) >= 0 || admin.rolelist.indexOf(ROLE.LEAD_MANAGEMENT) >= 0 || admin.rolelist.indexOf(ROLE.Gift_Card_Leads) >= 0 || admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0) ?
                                <div className='col-sm-3 col-xs-12'>
                                    <div className='landing-cards' onClick={() => this.props.mainMenuHandler('LOS')}>
                                        <div className='landing-head' style={{ background: '#e8ffea', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                            <img style={{ width: '24px' }} src={LosLogo} />&nbsp;LOS
                                        </div>
                                        
                                        <ul className='landing-inner' style={{ listStyle: 'none' }}>
                                            <>
                                                {admin.rolelist.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0 && (
                                                    <li
                                                        style={{ marginTop: '15px' }}
                                                        id='leadMainPage'
                                                        onClick={(e) => pageViewHandler(e, 'leadMainPage')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;  Loan Applications
                                                    </li>
                                                )}
                                                {admin.rolelist.indexOf(ROLE.APPLICATION_STATUS) >= 0 && (
                                                    <li
                                                        style={{ marginTop: '15px' }}
                                                        id='AppStatus'
                                                        onClick={(e) => pageViewHandler(e, 'AppStatus')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; All Application
                                                    </li>
                                                )}
                                                {admin.rolelist.indexOf(ROLE.LEAD_MANAGEMENT) >= 0 && (
                                                    <li
                                                        style={{ marginTop: '15px' }}
                                                        id='leadManagement'
                                                        onClick={(e) => pageViewHandler(e, 'leadManagement')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Lead Management
                                                    </li>
                                                )}
                                                {/* {admin.rolelist.indexOf(ROLE.Gift_Card_Leads) >= 0 && (
                                                    <li
                                                        style={{ marginTop: '15px' }}
                                                        id='giftCardLeads'
                                                        onClick={(e) => pageViewHandler(e, 'giftCardLeads')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Gift Card Leads
                                                    </li>
                                                )} */}
                                                {admin.rolelist.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0 && (
                                                    <li
                                                        style={{ marginTop: '15px' }}
                                                        id='kycPendingLeads'
                                                        onClick={(e) => pageViewHandler(e, 'kycPendingLeads')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Kyc Pending Leads
                                                    </li>
                                                )}
                                                {/* {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                  admin.rolelist.indexOf(ROLE.Vreedhi_Leads) >= 0 ?
                                                      <li style={{ marginTop: '15px' }}
                                                          id='vreedhiLeads'
                                                          onClick={(e) => pageViewHandler(e,'vreedhiLeads')}
                                                      >
                                                         <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Vreedhi Leads
                                                      </li>
                                                      : "" : ""}
                                              {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                  admin.rolelist.indexOf(ROLE.Bada_Business_Leads) >= 0 ?
                                                      <li style={{ marginTop: '15px' }}
                                                          id='BadaBuisnessLeads'
                                                          onClick={(e) => pageViewHandler(e,'BadaBuisnessLeads')}
                                                      >
                                                         <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Bada Buisness Leads
                                                      </li>
                                                      : "" : ""}
                                              {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                  admin.rolelist.indexOf(ROLE.Tea_Garden) >= 0 ?
                                                      <li style={{ marginTop: '15px' }}
                                                          id='garden'
                                                          onClick={(e) => pageViewHandler(e,'teaGardenLeads')}
                                                      >
                                                         <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Tea Garden Leads
                                                      </li>
                                                      : "" : ""} */}
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    (admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0) ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                            onClick={e => pageViewHandler(e, 'performanceTracker')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Performance Tracker
                                                        </div>
                                                        : "" : ""}
                                            </>

                                        </ul>
                                    </div>
                                </div>

                                : ""}
                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                (admin.rolelist.indexOf(ROLE.Disbursed_Leads) >= 0 || admin.rolelist.indexOf(ROLE.Incoming_Leads) >= 0 || admin.rolelist.indexOf(ROLE.Nach_Registration) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Nach_Bounce) >= 0 || admin.rolelist.indexOf(ROLE.Initiate_Bulk_Payment) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Paid) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Nach_Status_Update) >= 0) ?
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='landing-cards' onClick={() => this.props.mainMenuHandler('LMS')}>
                                            <div className='landing-head' style={{ background: '#fffcce', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                <img style={{ width: '24px' }} src={LmsLogo} />&nbsp;LMS
                                            </div>
                                            <ul className='landing-inner' style={{ listStyle: 'none' }}>
                                                <>
                                                    {admin.rolelist.indexOf(ROLE.Disbursed_Leads) >= 0 && (
                                                        <li style={{ marginTop: '15px' }} onClick={(e) => pageViewHandler(e, 'recoveryDisburse')}>
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Disbursed Leads
                                                        </li>
                                                    )}
                                                    {admin.rolelist.indexOf(ROLE.Incoming_Leads) >= 0 && (
                                                        <li style={{ marginTop: '15px' }} onClick={(e) => pageViewHandler(e, 'recoveryIncoming')}>
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Incoming List
                                                        </li>
                                                    )}
                                                    {admin.rolelist.indexOf(ROLE.Nach_Registration) >= 0 && (
                                                        <li style={{ marginTop: '15px' }} onClick={(e) => pageViewHandler(e, 'nachRegistrationReport')}>
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; NACH Registration
                                                        </li>
                                                    )}

                                                    {(admin.rolelist.indexOf(ROLE.Bulk_Nach_Bounce) >= 0 ||
                                                        admin.rolelist.indexOf(ROLE.Initiate_Bulk_Payment) >= 0 ||
                                                        admin.rolelist.indexOf(ROLE.Bulk_Paid) >= 0 ||
                                                        admin.rolelist.indexOf(ROLE.Bulk_Nach_Status_Update) >= 0 ||
                                                        admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0) && (
                                                            <>
                                                                <li className='mail-list' style={{ marginTop: '15px' }} onClick={(e) => this.subHandleTab(e, 'bulkOps', 'lms')}>
                                                                   <AccountTreeIcon/> &nbsp; Bulk Operations
                                                                    {this.state.lmsSubTab ?
                                                                        <i className="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>
                                                                        : <i className="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>}
                                                                </li>
                                                                {this.state.lmsSubTab && (
                                                                    <>
                                                                        {admin.rolelist.indexOf(ROLE.Bulk_Nach_Bounce) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'bulkNachBounceByCsv')}>
                                                                              <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}>Bulk Nach bounce By CSV</span>  
                                                                            </li>
                                                                        )}
                                                                        {admin.rolelist.indexOf(ROLE.Initiate_Bulk_Payment) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'bulkPayByRazorPay')}>
                                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Initiate Bulk Payment By CSV</span>
                                                                            </li>
                                                                        )}
                                                                        {admin.rolelist.indexOf(ROLE.Bulk_Paid) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'covertToPaid')}>
                                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Convert to Paid</span>
                                                                            </li>
                                                                        )}
                                                                        {admin.rolelist.indexOf(ROLE.Bulk_Nach_Status_Update) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'addNachStatus')}>
                                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Update Nach Status By CSV</span>
                                                                            </li>
                                                                        )}
                                                                        {admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'ApprovedLeads')}>
                                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Approved Leads</span>
                                                                            </li>
                                                                        )}
                                                                        {admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'icici')}>
                                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Disburse By ICICI</span>
                                                                            </li>
                                                                        )}
                                                                        {admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'approveToDisburse')}>
                                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Approved to Disburse By CSV</span>
                                                                            </li>
                                                                        )}
                                                                        {admin.rolelist.indexOf(ROLE.DEACTIVATE_NACH) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'bulkDeactivateDrp')}>
                                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Deactivate Bulk Nach (Superline)</span>
                                                                            </li>
                                                                        )}
                                                                        {admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0 && (
                                                                            <li style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'billDeskMandateHit')}>
                                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Billdesk Mandate Hit</span>
                                                                            </li>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    {
                                                        admin.role == "superuser" ?
                                                            <div
                                                                style={{ marginTop: '15px' }}
                                                                onClick={(e) => pageViewHandler(e, 'loanDashboardInsightApproval')}
                                                            >
                                                               <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Loan Dashboard
                                                            </div>
                                                            : ''}
                                                </>

                                            </ul>
                                        </div>
                                    </div>

                                    : "" : ""}
                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                (admin.rolelist.indexOf(ROLE.Asset_Quality) >= 0 || admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 || admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0 || admin.rolelist.indexOf(ROLE.FINANCE) >= 0) ?
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='landing-cards' onClick={() => this.props.mainMenuHandler('FINANCE')}>
                                            <div className='landing-head' style={{ background: '#e7f9ff', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}><img style={{ width: '24px' }} src={FinanceLogo} />&nbsp;Finance</div>
                                            <div className='landing-inner'>
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.Asset_Quality) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={(e) => pageViewHandler(e, 'assetQuality')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Assets Quality
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={(e) => pageViewHandler(e, 'razorpay')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Razorpay Report
                                                        </div>
                                                        : "" : ""}

                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={(e) => pageViewHandler(e, 'incomeCollected')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;    Income Breakup
                                                        </div>
                                                        : "" : ""}
                                                {admin.emailId == "SuperUser" || admin.emailId == "piyush.jain@phocket.in" || admin.emailId == "tapan.kumar@phocket.in" ?
                                                    <div
                                                        style={{ marginTop: '15px' }}
                                                        onClick={(e) => pageViewHandler(e, 'razorpayTransfer')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;   Razorpay Transfer
                                                    </div>
                                                    : ""}
                                                {
                                                    admin.role == "superuser" ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={(e) => pageViewHandler(e, 'NPADashboard')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; NPA Dashboard
                                                        </div>
                                                        : ''}
                                                {admin.rolelist.indexOf(ROLE.STAMP_DUTY_TRACKER) >= 0 ?
                                                    <div style={{ marginTop: '15px' }}
                                                        onClick={(e) => pageViewHandler(e, 'stampTracker')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;Stamp Duty Tracker
                                                    </div>
                                                    : ""
                                                }

                                                {admin !== undefined && admin.role === 'superuser' ?
                                                    <>
                                                        <div className='mail-list' style={{ marginTop: '15px' }} onClick={(e) => this.subHandleTabTarget(e)}>
                                                           <AccountTreeIcon/> &nbsp; Expense Management
                                                            {this.state.targetTab ?
                                                                <i className="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>
                                                                : <i className="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>}
                                                        </div>
                                                        {this.state.targetTab && (
                                                            <>
                                                                <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'employees')}>
                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Employees List</span>
                                                                </div>
                                                                <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'expenses')}>
                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}>Expenses Config</span> 
                                                                </div>
                                                                <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'businessManagement')}>
                                                                <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Business</span>
                                                                </div>


                                                            </>
                                                        )}

                                                    </>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                    : "" : ""}
                            {admin.rolelist.indexOf(ROLE.MARKETING_MAIN) >= 0 ?
                                <div className='col-sm-3 col-xs-12'>
                                    <div className='landing-cards' onClick={() => this.props.mainMenuHandler('BUSINESS')}>
                                        <div className='landing-head ' style={{ background: '#fff6ef' }}><img style={{ width: '24px' }} src={MarketingLogo} />&nbsp;Business </div>
                                        <div className='landing-inner'>
                                            <div style={{ marginTop: '15px' }}
                                                onClick={(e) => pageViewHandler(e, 'maketingDashboard')}
                                            >
                                               <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Dashboard
                                            </div>

                                            <div style={{ marginTop: '15px' }}
                                                onClick={(e) => pageViewHandler(e, 'maketingList')}
                                            >
                                               <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Aggregators
                                            </div>

                                            <div style={{ marginTop: '15px' }}
                                                onClick={(e) => pageViewHandler(e, 'runCampaign')}
                                            >
                                               <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Run Campaign
                                            </div>
                                            <div style={{ marginTop: '15px' }}
                                                onClick={(e) => pageViewHandler(e, 'deviation')}
                                            >
                                               <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Deviation Report
                                            </div>
                                            <div style={{ marginTop: '15px' }}
                                                onClick={(e) => pageViewHandler(e, 'buisnessAggregator')}
                                            >
                                               <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Business Aggregator
                                            </div>
                                            {admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 || admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ?

                                                <div style={{ marginTop: '15px' }}
                                                    onClick={(e) => pageViewHandler(e, 'combineBranchBuddyLoanReport')}
                                                >
                                                   <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Generate Branch & Buddy Loan Report
                                                </div>
                                                : ""}
                                            {/* {admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ?

                                                <div style={{ marginTop: '15px' }}
                                                    onClick={(e) => pageViewHandler(e, 'branchData')}
                                                >
                                                   <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Generate Branch Report
                                                </div>
                                                : ""}
                                            {admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ?
                                                <div style={{ marginTop: '15px' }}
                                                    onClick={(e) => pageViewHandler(e, 'buddyLoanReport')}
                                                >
                                                   <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Generate Buddyloan Report
                                                </div>
                                                : ""} */}
                                            {/* {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.MARKETING_TOOL) >= 0 ?
                                                    <div style={{ marginTop: '15px' }}
                                                        onClick={(e) => pageViewHandler(e, 'BIPage')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;   Bi
                                                    </div>
                                                    : "" : ""} */}


                                        </div>
                                    </div>
                                </div>
                                : ""}

                            {admin.rolelist != undefined && admin.rolelist.length > 0 && (admin.rolelist.indexOf(ROLE.ROLE_ASSIGN) >= 0 || admin.rolelist.indexOf(ROLE.LM_Assignment_Rule) >= 0 || admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 || admin.rolelist.indexOf(ROLE.NBFC_CONFIGURATION) >= 0 || admin.rolelist.indexOf(ROLE.EXECUTOR_ROLE) >= 0 || admin.rolelist.indexOf(ROLE.ALL_PRODUCTS) >= 0 || admin.rolelist.indexOf(ROLE.API_TRACKER) >= 0 || admin.rolelist.indexOf(ROLE.ALL_POLICY) >= 0) ?

                                <div className='col-sm-3 col-xs-12'>
                                    <div className='landing-cards' style={{ marginBottom: '30px' }}>
                                        <div className='landing-head' style={{ background: '#fff6ee', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}><img style={{ width: '24px' }} src={AccessmanagementLogo} />&nbsp;Access Management</div>
                                        <div className='landing-inner'>

                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ? admin.rolelist.indexOf(ROLE.ROLE_ASSIGN) >= 0 ?
                                                <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                    onClick={(e) => pageViewHandler(e, 'Roleassign')}
                                                >
                                                   <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;  Admin Roles
                                                </div>
                                                : "" : ""}
                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                admin.rolelist.indexOf(ROLE.LM_Assignment_Rule) >= 0 || admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={(e) => pageViewHandler(e, 'Bulkassign')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;  Application Assignment
                                                    </div>
                                                    : "" : ""}

                                            {admin.rolelist.indexOf(ROLE.NBFC_CONFIGURATION) >= 0 ?
                                                <>
                                                    <div className='mail-list'>
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={() => this.nbfcTabsHandle()}>
                                                           <AccountTreeIcon/> &nbsp; NBFC {this.state.nbfcTabs ? <i className="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i> : <i className="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>}
                                                        </div>
                                                    </div>
                                                    {this.state.nbfcTabs ?
                                                        <>
                                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                admin.rolelist.indexOf(ROLE.Add_Nbfc) >= 0 ?
                                                                    <div style={{ marginTop: '10px', marginLeft: '15px' }}
                                                                        onClick={(e) => pageViewHandler(e, 'addNbfcNew')}
                                                                    >
                                                                        <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}>Add NBFC</span> 
                                                                    </div>
                                                                    : "" : ""}
                                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                admin.rolelist.indexOf(ROLE.Add_Nbfc) >= 0 ?
                                                                    <div style={{ marginTop: '10px', marginLeft: '15px' }}
                                                                        onClick={(e) => pageViewHandler(e, 'nbfcConfig')}
                                                                    >
                                                                       <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}>  NBFC Config</span>
                                                                    </div>
                                                                    : "" : ""}
                                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                admin.rolelist.indexOf(ROLE.Add_Nbfc) >= 0 ?
                                                                    <div style={{ marginTop: '10px', marginLeft: '15px' }}
                                                                        onClick={(e) => pageViewHandler(e, 'allnbfclead')}
                                                                    >
                                                                        <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> All NBFC</span>
                                                                    </div>
                                                                    : "" : ""}

                                                        </>
                                                        : ""}
                                                </>
                                                : ""}

                                            {admin.rolelist != undefined ?
                                                (admin.rolelist.indexOf(ROLE.EXECUTOR_ROLE) >= 0) ?
                                                    <>
                                                        <div className='mail-list' style={{ marginTop: '15px' }} onClick={(e) => this.subHandleTech(e)}>
                                                           <AccountTreeIcon/> &nbsp; Tech & Products
                                                            {this.state.policyTabTech ?
                                                                <i className="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>
                                                                : <i className="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>}
                                                        </div>
                                                        {this.state.policyTabTech && (
                                                            <>
                                                                {admin.rolelist != undefined ?
                                                                    admin.rolelist.indexOf(ROLE.EXECUTOR_ROLE) >= 0 ?
                                                                        <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'Executor')}>
                                                                           <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}>Executor Configuration</span>  
                                                                        </div>
                                                                        : "" : ""}
                                                                {admin.rolelist.indexOf(ROLE.API_TRACKER) >= 0 ?
                                                                    <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'apiconfig')}>
                                                                        <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> API Tracker</span>
                                                                    </div>
                                                                    : ""}

                                                            </>
                                                        )}
                                                    </>
                                                    : "" : ""}
                                            {admin.rolelist != undefined ?
                                                (admin.rolelist.indexOf(ROLE.API_TRACKER) >= 0 || admin.rolelist.indexOf(ROLE.ALL_PRODUCTS) >= 0) ?
                                                    <>
                                                        <div className='mail-list' style={{ marginTop: '15px' }} onClick={(e) => this.subHandleTechLending(e)}>
                                                           <AccountTreeIcon/> &nbsp; Lending Products
                                                            {this.state.lendingProduct ?
                                                                <i className="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>
                                                                : <i className="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>}
                                                        </div>
                                                        {this.state.lendingProduct && (
                                                            <>



                                                                {admin.emailId && admin.emailId === 'SuperUser' ?
                                                                    <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'kycHandle')}>
                                                                        <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Kyc Start Stop</span>
                                                                    </div>

                                                                    : ""}
                                                                {admin.emailId && admin.emailId === 'SuperUser' ?
                                                                    <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'merchants')}>
                                                                        <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> All Merchants</span>
                                                                    </div>
                                                                    : ""}
                                                                {admin.rolelist != undefined ?
                                                                    admin.rolelist.indexOf(ROLE.ALL_PRODUCTS) >= 0 ?
                                                                        <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'allProduct')}>
                                                                            <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}> Subvention</span>
                                                                        </div>
                                                                        : "" : ""}
                                                                {admin.rolelist != undefined ?
                                                                    admin.rolelist.indexOf(ROLE.ALL_PRODUCTS) >= 0 ?
                                                                        < div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'LoanUtility')}>
                                                                           <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}>  Personal Loan</span>
                                                                        </div>
                                                                        : "" : ""}
                                                            </>
                                                        )}
                                                    </>
                                                    : "" : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.ALL_POLICY) >= 0 ?
                                                    <>
                                                        <div className='mail-list' style={{ marginTop: '15px' }} onClick={(e) => this.subHandleTabPolicy(e)}>
                                                           <AccountTreeIcon/> &nbsp; Policy
                                                            {this.state.policyTab ?
                                                                <i className="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>
                                                                : <i className="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>}
                                                        </div>
                                                        {this.state.policyTab && (
                                                            <>
                                                                {admin.rolelist != undefined ?
                                                                    admin.rolelist.indexOf(ROLE.ALL_POLICY) >= 0 ?
                                                                        <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'allPolicy')}>
                                                                          <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}>  Credit Policy</span> 
                                                                        </div>
                                                                        : "" : ""}
                                                                {admin.rolelist != undefined ?
                                                                    admin.rolelist.indexOf(ROLE.ALL_POLICY) >= 0 ?
                                                                        <div style={{ marginTop: '10px', marginLeft: '15px' }} onClick={(e) => pageViewHandler(e, 'scoringConfig')}>
                                                                          <CircleIcon style={{color:'grey',width:'10px',marginBottom:'-1px'}}/><span style={{color:'grey'}}>  Scoring Config</span> 
                                                                        </div>
                                                                        : "" : ""}



                                                            </>
                                                        )}
                                                    </>
                                                    : "" : ""}





                                        </div>

                                    </div>
                                </div>
                                : ""}
                            {admin.rolelist != undefined ? (admin.rolelist.indexOf(ROLE.Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.Incoming_Payment_Report) >= 0 || admin.rolelist.indexOf(ROLE.DASHBOARD_REPORT_NEW) >= 0 || admin.rolelist.indexOf(ROLE.Generate_Report_Logs) >= 0 || admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 || admin.rolelist.indexOf(ROLE.Check_FlipKart_Balance) >= 0) ?
                                <div className='col-sm-3 col-xs-12'>
                                    <div className='landing-cards' style={{ marginBottom: '30px' }}>
                                        <div className='landing-head' style={{ background: '#f0e7ff', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}><img style={{ width: '24px' }} src={DashboardLogo} />&nbsp;Dashboard & Reports</div>
                                        <div className='landing-inner'>
                                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 ?
                                                <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                    onClick={e => pageViewHandler(e, 'completeApplicationTracker')}
                                                >
                                                   <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Complete Application Tracker
                                                </div>
                                                : "" : ""}
                                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Dashboard) >= 0 ?
                                                <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                    onClick={e => pageViewHandler(e, 'dashboard')}
                                                >
                                                   <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Dashboard
                                                </div>
                                                : "" : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.Incoming_Payment_Report) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                        onClick={e => pageViewHandler(e, 'IncomingPaymentReport')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;  Incoming Payment Report
                                                    </div>
                                                    : "" : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.DASHBOARD_REPORT_NEW) >= 0 ?
                                                    this.state.generateReportState == true ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'reportGenerateNew')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;  Generate Report
                                                        </div>
                                                        : ""
                                                    : "" : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.Generate_Report_Logs) >= 0 ?

                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler(e, 'GenReportLogs')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;  Generate Report Logs
                                                    </div>
                                                    : "" : ""}

                                            {/* {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.Check_FlipKart_Balance) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler(e, 'flipkartBalance')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;  Check Flipkart Balance
                                                    </div>
                                                    : "" : ""} */}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.API_TRACKER) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler(e, 'creditleads')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Credit Analysis Leads
                                                    </div>
                                                    : "" : ""}

                                            <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                onClick={e => pageViewHandler(e, 'automationLogs')}
                                            >
                                               <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Automation Logs
                                            </div>
                                            {/* {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                (admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0) ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler(e, 'performanceTracker')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Performance Tracker
                                                    </div>
                                                    : "" : ""} */}

                                        </div>

                                    </div>
                                </div>
                                : "" : ""}
                            {admin.rolelist != undefined ?
                                (admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 || admin.rolelist.indexOf(ROLE.Update_Web_Content) >= 0 || admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 || admin.rolelist.indexOf(ROLE.Add_Priority_Company) >= 0 || admin.rolelist.indexOf(ROLE.Add_Pincode) >= 0 || admin.rolelist.indexOf(ROLE.Update_User_Bank_Details) >= 0 || admin.rolelist.indexOf(ROLE.Update_Cibil_Info) >= 0 || admin.rolelist.indexOf(ROLE.UPLOAD_STAMP_DUTY) >= 0 || admin.rolelist.indexOf(ROLE.BULK_STATUS_CHANGE) >= 0 || admin.rolelist.indexOf(ROLE.EMAIL_SMS_CONFIG) >= 0) ?
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='landing-cards' style={{ marginBottom: '30px' }}>
                                            <div className='landing-head' ><img style={{ width: '24px' }} src={ToolsLogo} />&nbsp;Tools & Utilities</div>
                                            <div className='landing-inner'>
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.EMAIL_SMS_CONFIG) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'comTemplate')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;Communication Template
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'calcUtility')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;Calculator
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Update_Web_Content) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'webContentUtility')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Web Content
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'emiCalculator')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;EMI Calculator
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Add_Priority_Company) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'priorityCompany')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Add Priority Company
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Add_Pincode) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'addPincode')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Add Pincode
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Update_User_Bank_Details) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'updateAccInfo')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;Update Account Info
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Update_Cibil_Info) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'updateCibilInfo')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Update Cibil Info
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.UPLOAD_STAMP_DUTY) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'uploadStamDuty')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Upload Stamp Duty
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.BULK_STATUS_CHANGE) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'bulkStatusChange')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Bulk Status Update
                                                        </div>
                                                        : "" : ""}

                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Update_User_Bank_Details) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler(e, 'addLoanId')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;Add Loan ID's
                                                        </div>
                                                        : "" : ""}




                                            </div>

                                        </div>
                                    </div>
                                    : "" : ""
                            }
                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                (admin.rolelist.indexOf(ROLE.COLLECTION_SECTION) >= 0) ?
                                    <div className='col-sm-3 col-xs-12' >
                                        <div className='landing-cards' style={{ marginBottom: '30px' }}>
                                            <div className='landing-head' style={{ background: '#ffe7e8', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}><img style={{ width: '24px' }} src={FinanceLogo} />&nbsp;Collection & Payments</div>
                                            <div className='landing-inner'>
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.COLLECTION_SECTION) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={(e) => pageViewHandler(e, 'recoveryLogs')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; Recovery List
                                                        </div>
                                                        : "" : ""}


                                                {
                                                    admin.role == "superuser" ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={(e) => pageViewHandler(e, 'misDashboard')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp; MIS Dashboard
                                                        </div>
                                                        : ''}
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0 ?
                                                        <div style={{ marginTop: '15px' }}
                                                            onClick={(e) => pageViewHandler(e, 'accountReconciliation')}
                                                        >
                                                           <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;Account Reconciliation
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0 ?
                                                    <div style={{ marginTop: '15px' }}
                                                        onClick={(e) => pageViewHandler(e, 'allPayments')}
                                                    >
                                                       <ViewStreamIcon style={{width:'13px',marginBottom:'-1px'}}/> &nbsp;Payments
                                                    </div>
                                                    : ""
                                                }

                                            </div>
                                        </div>

                                    </div>
                                    : "" : ""}
                        </div>



                    </div >
                    : ""
                }
            </>
        )
    }
}
const mapStateToProps = ({ admin }) => {
    return {

        // admin: admin,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Landingpage);