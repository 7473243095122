import React from 'react'
 
const CrifConfirmationPopup = ({ ConfirmCrifDataGenerate, confirmationText }) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">Are You Sure To Generate Crif Report?</h4>
                </div>
            </div>
            <div className="row from-group text-center">
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => ConfirmCrifDataGenerate('yes')}>YES</button>
                </div>
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => ConfirmCrifDataGenerate('no')}>NO</button>
                </div>
            </div>
        </div>
    </div>
)

export default CrifConfirmationPopup;