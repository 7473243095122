import React, { useState, useRef, useEffect } from "react";
import "../../compo.scss";
import List from "../../../maketingimages/list.png";
import downloadFile from "../../../maketingimages/downloadFile.png";

function SentToPopup({ onClose }) {
  const popupRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div
    className="marketing-main-css"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "99.8vh",
        }}
      >
        <div
          ref={popupRef}
          className="commModalBox"
          onClick={() => {
            console.log("ji");
          }}
        >
          <div
            className="mt-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="d-inline-flex">
              <img src={List} alt="" width={18} height={18} />{" "}
              <h4 className="ms-3 leadBreakDown_Header">Sent To List</h4>
            </div>
            <div className="d-inline-flex">
              <h6 className="disbursedHeader mx-4">
                Leads : <span className="disbursedAmountHeader">750</span>
              </h6>
              <h6 className="downloadReportText">
                <span style={{ marginRight: "0.4rem" }}>
                  <img src={downloadFile} width={16} height={16} />
                </span>
                Download Report
              </h6>
            </div>
          </div>
          <div className="collection-table mt-4 table-other">
            <table className="table table-hover align-middle text-center">
              <thead>
                <tr className="align-middle">
                  <th className="">Email IDs</th>
                  <th className="">Mobile Number</th>
                  <th className="">Loan Status</th>
                  <th className="">Form Status</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderBottom: "none" }}>
                  <td scope="col">
                    <span id="comment">
                      <b>name@Gmail.com</b>
                    </span>
                  </td>
                  <td scope="col">
                    <span id="comment">
                      <b>9876543212</b>
                    </span>
                  </td>
                  <td scope="col">
                    <span id="comment">
                      <b>Basic</b>
                    </span>
                  </td>
                  <td scope="col">
                    <span id="comment">
                      <b>Logging Event</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SentToPopup;
