import React, { useState } from 'react'
import LoanApproval from './LoanApproval';
import LoanInsights from './LoanInsights';

export default function Main() {
    const [tab, setTab] = useState('loanInsights');

    const handleTabs =(type) =>{
        setTab(type)
    }
    return (
        <>
            <div className="LoanDashboardInsightsApproval">
                <div className="tabs">
                            <div className={`subtabss ${tab === 'loanInsights' ? 'active' :''}`} onClick={()=>handleTabs('loanInsights')}>
                                <h5>Loan Insights</h5>
                            </div>
                            <div className={`subtabss ${tab === 'loanApproval' ? 'active' :''}`} onClick={()=>handleTabs('loanApproval')}>
                                <h5>Loan Approval</h5>
                            </div>
                </div>
                <div className="section" style={{padding:'100px 20px 50px 20px'}}>

                {
                    tab === 'loanInsights' ? 
                    <LoanInsights />
                    :''}
                {
                    tab === 'loanApproval' ? 
                    <LoanApproval />
                    :''}
                    </div>
            </div>
        </>
    )
}
