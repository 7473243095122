import React from 'react'
import CibilSection from './Scoring.container'
import DBRsection from './DBR.container'
import { getallDBRdataApi } from '../../Admin/AdminActionCreator.component'
class ScoringSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flagData: {

            }
        }
    }
    getData() {

    }
    componentDidMount() {
        this.getFlagData()
    }
    getFlagData() {
        const { allDetail } = this.props
        let flagData = Object.assign({}, this.state.flagData)

        getallDBRdataApi(allDetail.userId, callback => {
            // console.log(callback)
            if (callback !== '' && callback !== null && callback.decision !== '' && callback.decision !== undefined) {
                this.setState({ flagData: callback })

            }
        })


    }
    render() {
        const { allDetail, getCreditPopupState, creditScoringPopup, admin } = this.props
        return (
            <div>
                <div className='container-fluid' style={{marginBottom:'10px'}}>
                    <div className='row' >
                        <div className='col-sm-6 col-xs-12'>
                            <CibilSection
                                getData={this.getData.bind(this)}
                                allDetail={allDetail}
                                getCreditPopupState={getCreditPopupState}
                                creditScoringPopup={creditScoringPopup}
                                admin={admin}
                                flagData={this.state.flagData}
                            />
                        </div>
                        <div className='col-sm-6 col-xs-12'>
                            <DBRsection allDetail={allDetail} getFlagData={this.getFlagData.bind(this)} />

                        </div>
                        {/* <hr/> */}
                    </div>
                </div>
                {this.state.flagData && this.state.flagData.decision !== '' && this.state.flagData.decision !== undefined ?
                    <div className='container-fluid' style={{marginBottom:'10px'}}>
                        <div className='positive-box-dbr' style={{ backgroundColor: (this.state.flagData.decision == 'For Quality Check' || this.state.flagData.decision == 'For Quality Check') ? '#9d8420' : this.state.flagData.decision == 'Rejected' ? '#fee5e3' : this.state.flagData.decision == 'Approved' ? '#e2fee2' : "", paddingBottom: '20px' }}>
                            <div className='col-sm-12 col-xs-12'>
                                <div className='row header-dbr'>
                                    <div className='col-sm-6 col-xs-12'>
                                        <h4> DBR %: &nbsp;&nbsp;{this.state.flagData.dbrpercentage}%</h4>
                                    </div>
                                    <div className='col-sm-6 col-xs-12'>
                                        <h4> Scorecard: &nbsp;&nbsp;{this.state.flagData.scoring}</h4>
                                    </div>

                                </div>
                            </div>
                            <div className='text-center' >
                                <h4 style={{ marginTop: '20px' }}>Our Decision: &nbsp;&nbsp;<b className='blinknew'>{this.state.flagData.decision}</b></h4>
                            </div>
                            {this.state.flagData.decision === 'Approved' ?
                                <div className='text-center' style={{ marginBottom: '15px' }}>
                                    <h4>Can be approve for <b>{this.state.flagData.emi}</b> EMI's</h4>
                                </div>
                                : ""}
                            {/* <br/> */}
                            {this.state.flagData.decision === 'Approved' && this.state.flagData.approvedAmount
                                ?
                                <div className='text-center' style={{ marginBottom: '15px' }}>
                                    <h4><b>{this.state.flagData.approvedAmount}</b> </h4>

                                </div>
                                : ""}
                            {this.state.flagData.message ?
                                <h6 className='text-center'><b>{this.state.flagData.message}</b> </h6>

                                : ""}
                        </div>
                    </div>
                    : ""}
            </div>
        )
    }
}

export default ScoringSection