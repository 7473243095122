const POST_HEADER = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

const GET_HEADER = {
    
}

export {
    POST_HEADER,
    GET_HEADER
}