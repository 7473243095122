import React from "react";
import { Totalcount } from "../../../Data/Totalcount";
import { CustomTooltip } from "../../../customtooltip";

import {
  ResponsiveContainer,
  YAxis,
  XAxis,
  CartesianGrid,
  AreaChart,
  Area,
  Bar,
  BarChart,
  Tooltip,
} from "recharts";
function CountLeads({ data }) {
  const barData = [
    {
      "Loan Stage": "New lead",
      Approved: 40000,
      Rejected: 43000,
      Total: () => this.Approved + this.Rejected,
    },
  ];
  return (
    <>
      <div className="bg-white p-4 shadow totalcount" style={{ padding: '10px' }}>
        <div className="d-flex flex-column justify-content-around">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex align-item-center">
              <h5 className="totalcount1 card-heading">
                <b>Count of Leads on Each Loan Stage </b>
              </h5>
            </div>
          </div>

          <div className="totalcount5">
            <ResponsiveContainer>
              <BarChart width={730} height={320} data={data}>
                {/* <defs>
                  <linearGradient id="barlower" x1="1" y1="1" x2="0" y2="0">
                    <stop offset="0" stopColor="#8061DB" stopOpacity={1} />
                    <stop offset="1" stopColor="#8061DB" stopOpacity={1} />
                  </linearGradient>
                </defs> */}
                <defs>
                  <linearGradient id="barlower" x1="0" y1="1.2" x2="0" y2="0">
                    <stop offset="50%" stopColor="#8061DB" stopOpacity={1} />
                    <stop offset="95%" stopColor="#F6F4FE" stopOpacity={1} />
                  </linearGradient>

                </defs>
                <CartesianGrid vertical={false} strokeDasharray="8 12" />
                <XAxis
                  dataKey="loan_status"
                  axisLine={false}
                  tickLine={false}
                  interval={"preserveStartEnd"}
                />
                <YAxis
                  dataKey="count"
                  axisLine={false}
                  tickLine={false}
                  interval={"preserveStartEnd"}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{ fill: "transparent" }}
                  shared={true}
                />
                <Bar
                  dataKey="count"
                  fill="url(#barlower)"
                  radius={4}
                  stackId="a"
                  barSize={25}
                />
                {/* <Bar
                  dataKey="Rejected"
                  fill="#F6F4FE"
                  radius={4}
                  stackId="a"
                  barSize={25}
                /> */}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default CountLeads;
