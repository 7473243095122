import React, { useEffect } from "react";
import { getAllOrganisation } from "./actionCreator";

const AllMerchnats = () => {
    useEffect(()=>{
        getAllOrganisation(callback=>{
            console.log(callback)
        })
    },[])
    return (
        <div className="container-fluid">
            
            <div className="phocket-table-new">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default AllMerchnats