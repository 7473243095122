import React, { Component } from 'react';
import ReferralUi from '../../../presentationals/Admin/Promotions/Referral.component'
import { companyReferralApi } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component'

class Referral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            referData: {
                companyName: '',
                referralCode: '',
            },
        }
    }

    componentWillMount() {
        
    }

    render() {
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <ReferralUi
                    referData={this.state.referData}
                    referChange={this.referChange.bind(this)}
                    referHandler={this.referHandler.bind(this)}
                />
            </div>
        )
    }

    componentDidMount() {

    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    referChange(e, info) {
        let referData = Object.assign({}, this.state.referData);
        if (info == "companyName") {
            referData.companyName = e.target.value
        } else if (info == "referralCode") {
            referData.referralCode = e.target.value
        }
        this.setState({
            referData
        })
    }

    referHandler() {
        const { admin } = this.props
        if (this.state.referData.companyName != '' && this.state.referData.referralCode != '' ) {
            companyReferralApi(this.state.referData, admin,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Data Saved Successfully',
                            referData: {
                                companyName: '',
                                referralCode: ''
                            }
                        })
                        this.removePopup()
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again'
                        })
                        this.removePopup()
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please enter companyName and Referral code'
            })
            this.removePopup()
        }
    }

}

export default Referral;