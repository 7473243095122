import React from 'react'
import { APIS } from '../../../../utils/api-factory';
import AllNbfc from '../../../presentationals/Admin/NbfcManagementNew/allnbfc.component';
import NbfcDetails from './NbfcDetails.container';
import Loader from '../../../presentationals/Loader/Loader.component'
class Ui extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allNbfcList: '',
            loaderState: false,
            viewState: 'main',
            nbfcData: ''
        }
    }
    getNbfcList(data) {
        this.setState({ loaderState: true })
        fetch(APIS.GET_LIST_OF_NBFC + data)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allNbfcList: res,
                    loaderState: false
                })
            })
    }
    componentDidMount() {
        this.getNbfcList('')
    }
    searchHandler(e) {
        this.getNbfcList(e.target.value)
    }
    editHandler(data) {
        // this.setState({})
    }
    detailHandler(data) {
        this.setState({ nbfcData: data, viewState: 'details' })
    }
    backHandler(data) {
        this.setState({ viewState: data })
    }
    render() {
        return (
            <div>
                {this.state.viewState === 'details' ?
                    <i className='fa fa-arrow-left ' style={{ fontSize: '20px', marginLeft: '20px', marginTop: '20px' }} onClick={() => this.backHandler('main')}></i>
                    : ""}
                {this.state.viewState === 'main' ?
                    <AllNbfc
                        allNbfcList={this.state.allNbfcList}
                        searchHandler={this.searchHandler.bind(this)}
                        editHandler={this.editHandler.bind(this)}
                        detailHandler={this.detailHandler.bind(this)}
                    />
                    : ""}
                {this.state.viewState === 'details' ?
                    <NbfcDetails

                        nbfcData={this.state.nbfcData}

                    />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
            </div>
        )
    }
}
export default Ui