import React from "react";
import { passwordCheckNew, uploadFileNewImage, uploadPdfNew } from "../../AdminActionCreator.component";
import { TYPE } from "../../../../../utils/Constant";
import { getRendomNumber } from "../../../../../utils/commonUtility";
import BankPasswordPopup from '../../../../presentationals/Popup/BankPasswordPopup.component';
import SalaryPasswordPopup from '../../../../presentationals/Popup/SalaryPasswordPopup.component'
import Loader from '../../../../presentationals/Loader/Loader.component'

let File = []

class DocumentEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetail: {
                dashPhotoState: 'No File Chosen',
                dashIdProofState: 'No File Chosen',
                dashPanState: 'No File Chosen',
                dashBankStatmentState: 'No File Chosen',
                dashSalProofState: 'No File Chosen',
                dashItrState: 'No File Chosen',
                dashCibilState: 'No File Chosen',
                dashMiscellaneousState: 'No File Chosen',
                currentAddresState: 'No File Chosen',
            },
            BankPasswordState: false,
            SalaryPasswordState: false,
            passwordErrorMsg: '',
            docPass: '',
            photo: 'Choose New File',
            aadhaarCard: 'Choose New File',
            panCard: 'Choose New File',
            bankStatement: 'Choose New File',
            salarySlip: 'Choose New File',
            cibil: 'Choose New File',
            miscellaneous: 'Choose New File',
            currentAddress: 'Choose New File',
            loaderState: false

        }
    }
    onChangeHandler(e, type) {
        const { admin, userdata } = this.props
        let userDetail = Object.assign({}, this.state.userDetail)
        let val = e.target.value.split(/[\\\/]/)[2]
        let docType = val.split('.')
        let fileType = docType[docType.length - 1]
        File = e.target.files[0]

        if (type == TYPE.PHOTO) {
            userDetail.dashPhotoState = val
            // this.setState({ progressState: true })
            // this.progressHandler()
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                uploadPdfNew(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    '',
                    userdata.userId,
                    callBack => {
                        userDetail.dashPhotoState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashPhotoState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ photo: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.dashPhotoState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashPhotoState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ photo: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            }
        } else if (type == TYPE.ADHAR_CARD) {
            userDetail.dashIdProofState = val
            this.setState({ progressState: true })
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                uploadPdfNew(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    '',
                    userdata.userId,
                    callBack => {
                        userDetail.dashIdProofState = callBack
                        this.setState({ progressState: false })
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ aadhaarCard: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.dashIdProofState = callBack
                        this.setState({ progressState: false })
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ aadhaarCard: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            }
        } else if (type == TYPE.PAN_CARD) {
            userDetail.dashPanState = val
            this.setState({ progressState: true })
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                uploadPdfNew(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    '',
                    userdata.userId,
                    callBack => {
                        userDetail.dashPanState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashPanState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ panCard: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.dashPanState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashPanState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ panCard: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            }
        } else if (type == TYPE.BANK_STATMENT) {
            userDetail.dashBankStatmentState = val
            this.setState({ progressState: true })
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                passwordCheckNew(File, callBack => {
                    userDetail.dashBankStatmentState = callBack
                    this.setState({ progressState: false })
                    if (callBack == 'success') {
                        this.setState({
                            BankPasswordState: true,
                            loaderState: false

                        })
                    } else {
                        this.setState({ loaderState: true })
                        uploadPdfNew(
                            File,
                            type + '@' + getRendomNumber(3),
                            val,
                            '',
                            userdata.userId,
                            callBack => {
                                userDetail.dashBankStatmentState = callBack
                                this.setState({ progressState: false })
                                userDetail.dashBankStatmentState = callBack
                                this.setState({
                                    userDetail
                                })
                                if (callBack) {
                                    this.setState({ bankStatement: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                                }
                            }
                        )
                    }
                })
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.dashBankStatmentState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashBankStatmentState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ bankStatement: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            }
        } else if (type == TYPE.SALARY_PROOF) {
            userDetail.dashSalProofState = val
            this.setState({ progressState: true })
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                passwordCheckNew(File, callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            SalaryPasswordState: true,
                            loaderState: false
                        })
                    } else {
                        uploadPdfNew(
                            File,
                            type + '@' + getRendomNumber(3),
                            val,
                            '',
                            userdata.userId,
                            callBack => {
                                userDetail.dashSalProofState = callBack
                                this.setState({ progressState: false })
                                userDetail.dashSalProofState = callBack
                                this.setState({
                                    userDetail
                                })
                                if (callBack) {
                                    this.setState({ salarySlip: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                                }
                            }
                        )
                    }
                })
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.dashSalProofState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashSalProofState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ salarySlip: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            }
        } else if (type == TYPE.ITR_STATEMENT) {
            userDetail.dashItrState = val
            this.setState({ progressState: true })
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                uploadPdfNew(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    '',
                    userdata.userId,
                    callBack => {
                        userDetail.dashItrState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashItrState = callBack
                        this.setState({
                            userDetail
                        })
                    }
                )
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.dashItrState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashItrState = callBack
                        this.setState({
                            userDetail
                        })

                    }
                )
            }
        } else if (type == TYPE.CIBIL_REPORT) {
            userDetail.dashCibilState = val
            this.setState({ progressState: true })
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                uploadPdfNew(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    '',
                    userdata.userId,
                    callBack => {
                        userDetail.dashCibilState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashCibilState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ cibil: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.dashCibilState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashCibilState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ cibil: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            }
        } else if (type == TYPE.MISCELLANEOUS) {
            userDetail.dashMiscellaneousState = val
            this.setState({ progressState: true })
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                uploadPdfNew(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    '',
                    userdata.userId,
                    callBack => {
                        userDetail.dashMiscellaneousState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ miscellaneous: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type + '@' + getRendomNumber(3),
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.dashMiscellaneousState = callBack
                        this.setState({ progressState: false })
                        userDetail.dashMiscellaneousState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ miscellaneous: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            }
        } else if (type == TYPE.CURRENT_ADD_PROOF) {
            userDetail.currentAddresState = val
            this.setState({ progressState: true })
            if (fileType == 'pdf') {
                this.setState({ loaderState: true })
                uploadPdfNew(
                    File,
                    type,
                    val,
                    '',
                    userdata.userId,
                    callBack => {
                        userDetail.currentAddresState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ currentAddress: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            } else {
                this.setState({ loaderState: true })

                uploadFileNewImage(
                    File,
                    type,
                    val,
                    userdata.userId,
                    callBack => {
                        userDetail.currentAddresState = callBack
                        this.setState({ progressState: false })
                        userDetail.currentAddresState = callBack
                        this.setState({
                            userDetail
                        })
                        if (callBack) {
                            this.setState({ currentAddress: 'Successfully uploaded!', loaderState: false },()=>this.props.getDocs())
                        }
                    }
                )
            }
        }
    }
    docPassword(e) {
        this.setState({
            docPass: e.target.value,
            passwordErrorMsg: ''
        })
    }
    docPasswordHandler(e, type) {
        const { admin, userdata } = this.props

        let userDetail = Object.assign({}, this.state.userDetail)
        if (type == 'bank') {
            this.setState({ loaderState: true })
            uploadPdfNew(
                File,
                TYPE.BANK_STATMENT +
                '@' +
                getRendomNumber(3) +
                '-' +
                this.state.docPass,
                'val',
                this.state.docPass,
                userdata.userId,
                callBack => {
                    if (callBack != null && callBack != '') {
                        userDetail.dashBankStatmentState = callBack
                        this.setState({
                            BankPasswordState: false,
                            userDetail,
                            passwordErrorMsg: '',
                            loaderState: false,
                            bankStatement: 'Successfully uploaded!'

                        })
                    } else {
                        this.setState({
                            loaderState: false,
                            passwordErrorMsg: 'Wrong Password'
                        })
                    }
                }
            )
        } else if (type == 'salary') {
            this.setState({ loaderState: true })
            uploadPdfNew(
                File,
                TYPE.SALARY_PROOF + '@' + getRendomNumber(3) + '-' + this.state.docPass,
                'val',
                this.state.docPass,
                userdata.userId,
                callBack => {
                    if (callBack != null && callBack != '') {
                        userDetail.dashSalProofState = callBack
                        this.setState({
                            SalaryPasswordState: false,
                            userDetail,
                            loaderState: false,
                            salarySlip: 'Successfully uploaded!'
                        })
                    } else {
                        this.setState({
                            loaderState: false,
                            passwordErrorMsg: 'Wrong Password'
                        })
                    }
                }
            )
        }
    }
    render() {
        const { documentsData } = this.props
        return (
            <>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <div className="details-new background-blur" style={{ background: 'rgb(5 5 5 / 20%)', color: '#000' }}>
                    {this.state.BankPasswordState == true ?

                        <BankPasswordPopup
                            docPassword={this.docPassword.bind(this)}
                            docPasswordHandler={this.docPasswordHandler.bind(this)}
                            passwordErrorMsg={this.state.passwordErrorMsg}

                        />
                        : ""}
                    {this.state.SalaryPasswordState == true ?
                        <SalaryPasswordPopup
                            docPassword={this.docPassword.bind(this)}
                            docPasswordHandler={this.docPasswordHandler.bind(this)}
                            passwordErrorMsg={this.state.passwordErrorMsg}
                        />
                        : ""}
                    <div className="edit-box " style={{ width: '650px' }}>
                        <div className="text-right" style={{ color: 'red' }}><i onClick={() => this.props.editHandler('')} style={{ fontSize: '20px', cursor: 'pointer' }} className="fa fa-times"></i></div>

                        <h4><b>Edit Document </b></h4>


                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-4 col-xs-4" style={{ marginTop: '10px' }}>Photograph</div>
                            <div className="col-sm-8 col-xs-8">
                                <label htmlFor="photograph" className="upload-file" style={{ color: this.state.photo === 'Successfully uploaded!' ? 'green' : '#000' }}><i className="fa fa-cloud-upload" style={{ color: '#267DFF' }}></i>&nbsp;&nbsp;{this.state.photo} </label>
                                <input className="display-none" id="photograph" type="file" aria-label="File browser example" accept="image/png , image/jpeg , application/pdf" onChange={(e) => this.onChangeHandler(e, TYPE.PHOTO)} />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-4 col-xs-4" style={{ marginTop: '10px' }}>Aadhaar Card</div>
                            <div className="col-sm-8 col-xs-8">
                                <label htmlFor="identityProof" className="upload-file" style={{ color: this.state.aadhaarCard === 'Successfully uploaded!' ? 'green' : '#000' }}><i className="fa fa-cloud-upload" style={{ color: '#267DFF' }}></i>&nbsp;&nbsp;{this.state.aadhaarCard} </label>

                                <input type="file" className="display-none" accept="image/png , image/jpeg , application/pdf" id="identityProof" onChange={(e) => this.onChangeHandler(e, TYPE.ADHAR_CARD)} />

                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-4 col-xs-4" style={{ marginTop: '10px' }}>Pan Card</div>
                            <div className="col-sm-8 col-xs-8">
                                <label htmlFor="panProof" className="upload-file" style={{ color: this.state.panCard === 'Successfully uploaded!' ? 'green' : '#000' }}><i className="fa fa-cloud-upload" style={{ color: '#267DFF' }}></i>&nbsp;&nbsp;{this.state.panCard} </label>

                                <input className="display-none" accept="image/png , image/jpeg , application/pdf" type="file" id="panProof" onChange={(e) => this.onChangeHandler(e, TYPE.PAN_CARD)} />

                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-4 col-xs-4" style={{ marginTop: '10px' }}>Bank Statement</div>
                            <div className="col-sm-8 col-xs-8">
                                <label htmlFor="bankStatementProof" className="upload-file" style={{ color: this.state.bankStatement === 'Successfully uploaded!' ? 'green' : '#000' }}><i className="fa fa-cloud-upload" style={{ color: '#267DFF' }}></i>&nbsp;&nbsp;{this.state.bankStatement} </label>

                                <input className="display-none" multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" id="bankStatementProof" onChange={(e) => this.onChangeHandler(e, TYPE.BANK_STATMENT)} />

                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-4 col-xs-4" style={{ marginTop: '10px' }}>Salary Slip</div>
                            <div className="col-sm-8 col-xs-8">
                                <label htmlFor="salarySlip" className="upload-file" style={{ color: this.state.salarySlip === 'Successfully uploaded!' ? 'green' : '#000' }}><i className="fa fa-cloud-upload" style={{ color: '#267DFF' }}></i>&nbsp;&nbsp;{this.state.salarySlip} </label>

                                <input className="display-none" multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" id="salarySlip" onChange={(e) => this.onChangeHandler(e, TYPE.SALARY_PROOF)} />

                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-4 col-xs-4" style={{ marginTop: '10px' }}>CIBIL</div>
                            <div className="col-sm-8 col-xs-8">
                                <label htmlFor="cibilReport" className="upload-file" style={{ color: this.state.cibil === 'Successfully uploaded!' ? 'green' : '#000' }}><i className="fa fa-cloud-upload" style={{ color: '#267DFF' }}></i>&nbsp;&nbsp;{this.state.cibil} </label>

                                <input className="display-none" multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" id="cibilReport" onChange={(e) => this.onChangeHandler(e, TYPE.CIBIL_REPORT)} />

                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-4 col-xs-4" style={{ marginTop: '10px' }}>Miscellaneous</div>
                            <div className="col-sm-8 col-xs-8">
                                <label htmlFor="miscellaneousReport" className="upload-file" style={{ color: this.state.miscellaneous === 'Successfully uploaded!' ? 'green' : '#000' }}><i className="fa fa-cloud-upload" style={{ color: '#267DFF' }}></i>&nbsp;&nbsp;{this.state.miscellaneous} </label>

                                <input className="display-none" multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" id="miscellaneousReport" onChange={(e) => this.onChangeHandler(e, TYPE.MISCELLANEOUS)} />

                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                            <div className="col-sm-4 col-xs-4" style={{ marginTop: '10px' }}>Current Address Proof</div>
                            <div className="col-sm-8 col-xs-8">
                                <label htmlFor="currentAddressProof" className="upload-file" style={{ color: this.state.currentAddress === 'Successfully uploaded!' ? 'green' : '#000' }}><i className="fa fa-cloud-upload" style={{ color: '#267DFF' }}></i>&nbsp;&nbsp;{this.state.currentAddress} </label>

                                <input className="display-none" multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" id="currentAddressProof" onChange={(e) => this.onChangeHandler(e, TYPE.CURRENT_ADD_PROOF)} />

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default DocumentEdit