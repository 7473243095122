import React from 'react'
import moment from 'moment'

const MessagePage = ({ userDetail, whatsappmsg, whatsappmsgHandler, errorMsg, writeWhatsappMsg,
    allWhatsappMsg, msgTempleteClick, loaderState, tickStatus, whatsappMsgChange, approveAmount, selectedTemplate }) => (
    <div className="details-new container-fluid">
        <div className='sms-box'>
            <label style={{ fontSize: '10px', fontWeight: '500' }}>Select Template</label>
            <select

                style={{ width: '100%', background: '#DBE9FF', color: '#000' ,outline:'none'}}
                name="communicationMsg"
                value={selectedTemplate}
                onChange={(e) => msgTempleteClick(e.target.value)}
                className='form-control'
            >
                <option value="">Select a template</option>
                <option value="ExecutiveContact">Executive contact</option>
                <option value="DocsUpload">Docs upload</option>
                <option value="eMandateRepeate">Repeat E - Mandate Razorpay</option>
                {userDetail.typeOfProduct === 'Loan' && (
                    <option value="eMandateRepeateTechp">Repeat E - Mandate Techprocess</option>
                )}
                <option value="eMandate">E - Mandate Razorpay</option>
                {userDetail.typeOfProduct === 'Loan' && (
                    <option value="eMandatetechP">E - Mandate Techprocess</option>
                )}
                <option value="eSign">E - Sign</option>
                <option value="eSignEmandate">E - Sign &amp; E - Mandate Razorpay</option>
                {userDetail.typeOfProduct === 'Loan' && (
                    <option value="techprocessEmandate">E - Sign &amp; E - Mandate Techprocess</option>
                )}
                <option value="razorpay">Bank not live with Razorpay's e-mandate</option>
                <option value="googleRating">Google Play Store Rating</option>
                <option value="reference">Three Persons Reference</option>
                <option value="billDeskNach">E-MANDATE WITH BILLDESK</option>
            </select>

            <label style={{ fontSize: '10px', fontWeight: '500', marginTop: '10px' }}>Chat</label>

            <div className="relative_position" style={{}}>
                <div className="whatsapp-msg" id="whatsappMsg" style={{borderBottomLeftRadius:0,borderBottomRightRadius:0,borderBottom:'none',paddingBottom:window.innerHeight<650?'200px':'20px', overflow: 'auto',maxHeight:window.innerHeight<650?'400px':'600px'}}>
                    {loaderState == true ?
                        <div className="whatsapp-loader">
                            <div className="fa fa-spinner fa-spin fa-lg" />
                        </div>
                        : ""}
                    {allWhatsappMsg != null && allWhatsappMsg != '' && allWhatsappMsg != undefined ?
                        allWhatsappMsg.map((whatsappData, i) => {
                            return (
                                <div key={i} className="margin-0 row">
                                    {whatsappData.fromMe == true ?
                                        <div className="whatsappmsg msg-left">
                                            <div>
                                                {whatsappData.body.includes('https://') ?
                                                    whatsappData.body.includes('.pdf?alt') ?
                                                        <object className="area-200px" data={whatsappData.body} type="application/pdf"></object>
                                                        : <img className="area-200px" src={whatsappData.body} />
                                                    : whatsappData.body}
                                            </div>
                                            <div className="msgDate" style={{color:'#000'}}>{moment(new Date(whatsappData.date)).format('DD-MM-YYYY HH:mm')}</div>
                                            <span className="tail-container" />
                                        </div>
                                        : whatsappData.fromMe == false ?
                                            <div className="whatsappmsg msg-right">
                                                <div>
                                                    {whatsappData.body.split("&type=")[1] == "pdf" ?
                                                        <object className="area-200px" data={whatsappData.body} type="application/pdf"></object>
                                                        : whatsappData.body.split("&type=")[1] == "img" ?
                                                            <img className="area-200px" src={whatsappData.body} />
                                                            : whatsappData.body}
                                                </div>
                                                <div className="msgDate">
                                                    {moment(new Date(whatsappData.date)).format('DD-MM-YYYY HH:mm')}&nbsp;&nbsp;
                                                    {tickStatus == 0 ?
                                                        <span className="font-12px">&#10003;</span>
                                                        : tickStatus == 1 ?
                                                            <span className="whatsapp-doubble-tick font-12px">&#10003;&#10003;</span>
                                                            : tickStatus == 2 ?
                                                                <span className="whatsapp-doubble-tick font-12px blue-tick">&#10003;&#10003;</span>
                                                                : null}
                                                </div>
                                                <span className="tail-container" />
                                            </div>
                                            : ""}
                                </div>
                            )
                        })
                        : ""}
                </div>
            </div>
            <div style={{
                borderRadius: '0px 0px 6px 6px', borderTop: ' 1px solid rgba(0, 0, 0, 0.15)', background: 'var(--light-grey-1, #E9ECF5)',padding:'5px'
            }}>
                <div className='row'>
                    <div className='col-sm-1 col-xs-1'>
                    <label htmlFor="whatsappFiles" className="">
                            <span className="fa fa-paperclip" style={{fontSize:'25px',marginTop:'10px'}}></span>
                        </label>
                        <input accept="image/png, image/jpeg, application/pdf" type="file" className="form-control display-none" id="whatsappFiles" onChange={e => whatsappmsg(e, 'file')} />
                    </div>
                    <div className='col-sm-9 col-xs-9'>
                    <textarea className=" " style={{background:'#E9ECF5',color:'#000',width:'100%',height:'auto',marginTop:'10px'}} placeholder='Type Message Here......' onChange={e => whatsappMsgChange(e)} value={writeWhatsappMsg}></textarea>
                    </div>
                    <div className='col-sm-2 col-xs-2 text-center'>
                    <span className="fa fa-paper-plane" style={{marginTop:'10px'}} onClick={e => whatsappmsgHandler(e, 'click')} />
                    </div>
                </div>
            {/* <div className="display-flex" onKeyDown={e => whatsappmsgHandler(e, 'enter')}>
                <textarea className="whatsapp-input-container form-control" onChange={e => whatsappMsgChange(e)} value={writeWhatsappMsg}></textarea>
                <div className="white-bg">
                    <button className="profile-save-btn margin-0" onClick={e => whatsappmsgHandler(e, 'click')}><span className="fa fa-paper-plane" /></button>
                    <div className="text-center margin-top-20px">
                        <label htmlFor="whatsappFiles" className="input-field-label-new margin-0-auto">
                            <span className="fa fa-paperclip  margin-0"></span>
                        </label>
                        <input accept="image/png, image/jpeg, application/pdf" type="file" className="form-control display-none" id="whatsappFiles" onChange={e => whatsappmsg(e, 'file')} />
                    </div>
                </div>
            </div> */}
            </div>
        </div>
        <p className="text-center height-20px margin-0 red-text">{errorMsg}</p>

    </div>
)

export default MessagePage;